import { APP } from '../../app/AppInstance';
import { DisplayMST } from '../DisplayModel';
import { DisplayModelKeys } from '../DisplayModelKeys';

export const SettingsMST = DisplayMST.named('SettingsMST')
  .props({
    sounds: false,
    music: false,
    notifications: false
  })
  .actions(self => ({
    toggleSoundsOptionVisibility() {
      self.sounds = !self.sounds;
      APP.Settings.enableSounds(self.sounds);
    },
    toggleMusicOptionVisibility() {
      self.music = !self.music;
      APP.Settings.enableMusic(self.music);
      if (self.music) {
        APP.SoundPlayer.playBackgroundMusic();
      } else {
        APP.SoundPlayer.stopBackgroundMusic();
      }
    },
    toggleNotificationsOptionVisibility() {
      self.notifications = !self.notifications;
      // TODO
      // APP.Settings.enableNotifications;
    },
    setMusic(music: boolean) {
      self.music = music;
    },
    setSounds(sounds: boolean) {
      self.sounds = sounds;
    }
  }))
  .views(self => ({}));

type SettingsModelType = typeof SettingsMST.Type;
export interface SettingsModel extends SettingsModelType {}

export const createSettingsModel = () =>
  SettingsMST.create({
    key: DisplayModelKeys.SettingsDialog
  });
