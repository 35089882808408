import { BaseMST } from 'common/src/models/BaseModel';
import { ButtonMST } from 'common/src/models/ButtonModel';
import { Instance, types } from 'mobx-state-tree';

export const CollaborationStatusMST = BaseMST.named('CollaborationStatusMST')
  .props({
    camMicError: types.boolean,
    retryButton: ButtonMST
  })
  .views(self => ({
    isVisible(): boolean {
      return self.camMicError;
    },
    isCamMicError(): boolean {
      return self.camMicError;
    }
  }))
  .actions(self => ({
    setCamMicError(error: boolean) {
      self.camMicError = error;
    },
    setRetryButton(action: Function) {
      self.retryButton.action.setAction(action);
    }
  }));

type CollaborationStatusType = typeof CollaborationStatusMST.Type;
export interface CollaborationStatusModel extends Instance<typeof CollaborationStatusMST> {}

export const createCollaborationStatusModel = () =>
  CollaborationStatusMST.create({
    camMicError: false,
    retryButton: {}
  });
