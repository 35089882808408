import { DisplayMST } from '../../DisplayModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const TokensInfoMST = DisplayMST.named('TokensInfoMST')
  .props({})
  .actions(self => ({}))
  .views(self => ({}));

type TokensInfoModelType = typeof TokensInfoMST.Type;
export interface TokensInfoModel extends TokensInfoModelType {}

export const createTokensInfoModel = () =>
  TokensInfoMST.create({
    key: DisplayModelKeys.TokensInfoDialog
  });
