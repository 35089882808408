import { PlayerAction } from '../../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.player.message.SignIn';

const TypeFacebook = 0; // fieldType: int
const TypeGuest = 1; // fieldType: int
const TypeApple = 4;
const PlatformDesktop = 0; // fieldType: int
const PlatformAndroid = 1; // fieldType: int
const PlatformIOS = 2;
const SourceAndroid = 10; // fieldType: int
const SourceIOS = 20;

export class SignIn extends PlayerAction {
  userType: number = 0;

  // fieldType: int
  platformType: number = 0;

  // fieldType: int
  playerFacebookId?: string = undefined;

  // fieldType: String
  guestId?: string = undefined;

  appleId?: string = undefined;

  // fieldType: String
  name?: string = undefined;

  // fieldType: String
  nameShort?: string = undefined;

  // fieldType: String
  thirdPartyId?: string = undefined;

  // fieldType: String
  timeZone: number = 0;

  // fieldType: int
  mail?: string = undefined;

  // fieldType: String
  mcc?: string = undefined;

  // fieldType: String
  mnc?: string = undefined;

  // fieldType: String
  clientVersion: number = 0;

  // fieldType: int
  totalFriendsCount: number = -1;

  // fieldType: int
  playingFriendsCount: number = -1;

  // fieldType: int
  gender: number = -1;

  // fieldType: int
  clientIP?: string = undefined;

  // fieldType: String
  machineId?: string = undefined;

  // fieldType: String
  country?: string = undefined;

  // fieldType: String
  accessKey?: string = undefined;

  // fieldType: String
  source: number = 0;

  // fieldType: int
  referral?: string = undefined;

  // fieldType: String
  b2bId?: string = undefined;

  // fieldType: String
  b2bName?: string = undefined;

  // fieldType: String
  b2bTournament?: string = undefined;

  // fieldType: String
  b2bOrganization?: string = undefined;

  // fieldType: String
  b2bTeam?: string = undefined;

  // fieldType: String
  b2bMd5?: string = undefined;

  // fieldType: String
  b2bAdmin?: string = undefined;

  // fieldType: String
  b2bSuperAdmin?: string = undefined;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get TypeFacebook() {
    return TypeFacebook;
  }

  static get TypeGuest() {
    return TypeGuest;
  }

  static get TypeApple() {
    return TypeApple;
  }

  static get PlatformDesktop() {
    return PlatformDesktop;
  }

  static get PlatformAndroid() {
    return PlatformAndroid;
  }

  static get PlatformIOS() {
    return PlatformIOS;
  }

  static get SourceAndroid() {
    return SourceAndroid;
  }
}
