import { Local } from 'common/src/lang/Local';
import { DisplayModel } from 'common/src/models/DisplayModel';
import { AssociationColumnID } from 'common/src/models/game/content/association/AssociationModel';
import { AssociationResultModel, BLUE_RESOLVED, RED_RESOLVED } from 'common/src/models/game/content/association/AssociationResultModel';
import { ModelFactory } from 'common/src/models/ModelFactory';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { buttonsClassMap, hiddenClass } from '../../GameButton';

@observer
export default class AssociationResultButton extends Component<{
  model?: AssociationResultModel;
  baseClass: string;
  baseImgClass?: string;
}> {
  buttonRef: HTMLDivElement | null = null;

  boxRef?: HTMLDivElement | null = null;

  characterInfoDialog: DisplayModel | undefined = undefined;

  componentDidMount() {}

  getCurrentClass() {
    let activeClass;
    if (this.props.model!.focused) {
      activeClass = 'game-button-focused';
    } else {
      activeClass = this.props.model!.isEnabled() ? buttonsClassMap[this.props.model!.type].active : buttonsClassMap[this.props.model!.type].disabled;
    }
    return this.props.model!.visible ? activeClass : hiddenClass;
  }

  getBoxClassName() {
    if (this.props.model!.id === AssociationColumnID.ColumnA) return 'association__result_button_box association__result_button_box--a';
    if (this.props.model!.id === AssociationColumnID.ColumnB) return 'association__result_button_box association__result_button_box--b';
    if (this.props.model!.id === AssociationColumnID.ColumnC || this.props.model!.id === AssociationColumnID.ColumnD) return 'association__result_button_box';
    if (this.props.model!.id === AssociationColumnID.Final) return 'association__result_button_box--final';
    return '';
  }

  handleFocus = () => {
    if (this.props.model!.getText() === '?') this.props.model!.setText('');
    this.props.model!.setFocused(true);
  };

  onBlur = () => {
    if (this.props.model!.getText().length === 0) this.props.model!.setText('?');
    this.props.model!.setFocused(false);
  };

  onInputChange = (event: any) => {
    let code = event.target.value;

    if (code.length > 0) {
      code = code.toLocaleUpperCase();
      if (!this.isValidCharacter(code[code.length - 1])) {
        if (this.isCyrilicCharacter(code[code.length - 1])) {
          if (!this.characterInfoDialog) {
            this.characterInfoDialog = ModelFactory.createServerInfo(Local.getString('cyrilic.info'));
          }
          this.characterInfoDialog.show();
        }
        return;
      }
    }

    this.props.model!.setText(code);
  };

  onKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      this.props.model!.setFocused(false);
      this.props.model!.setEnabled(false);
      this.props.model!.confirmAction.performAction();
    }
  };

  isValidCharacter(code: any): boolean {
    // return !/[^0-9a-zA-ZšđčćžŠĐČĆŽ ]/.test(code);
    return !/[^0-9A-ZŠĐČĆŽ ]/.test(code);
  }

  isCyrilicCharacter(code: any): boolean {
    return !/[^АБВГДЂЕЖЗИЈКЛЉМНЊОПРСТЋУФХЦЧЏШ]/.test(code);
  }

  isBlueOrRedResolved() {
    return this.props.model!.resolvedStatus === RED_RESOLVED || this.props.model!.resolvedStatus === BLUE_RESOLVED;
  }

  render() {
    const { model, baseClass, baseImgClass } = this.props;
    if (!model) {
      return null;
    }
    return (
      <div
        className={this.getBoxClassName()}
        ref={boxRef => {
          this.boxRef = boxRef;
        }}
      >
        <div
          onClick={() => {
            model!.action.performAction();
          }}
          className={[baseClass, this.getCurrentClass()].join(' ')}
          ref={buttRef => {
            this.buttonRef = buttRef;
          }}
        >
          <input
            className={`association__result-input${model.id === AssociationColumnID.Final ? ' association__result-input-final' : ''} association__result-input--disabled-${this.isBlueOrRedResolved() ? 'white' : 'navy'}`}
            type="text"
            value={model.getText()}
            onKeyDown={(event: any) => {
              this.onKeyDown(event);
            }}
            onChange={() => {}}
            onInput={(event: any) => {
              this.onInputChange(event);
            }}
            onFocus={() => {
              this.handleFocus();
            }}
            onBlur={() => {
              this.onBlur();
            }}
            disabled={!model!.isEnabled()}
            spellCheck={false}
          />
          <div className={baseImgClass} />
        </div>
      </div>
    );
  }
}
