import { types } from 'mobx-state-tree';
import { ButtonModel, ButtonMST, createConfirmPuzzleButton } from '../../../ButtonModel';
import { GameRiddleContentMST } from '../GameRiddleContent';
import { CombinationsHolderModel, CombinationsHolderMST, createCombinationsHolderModel } from './CombinationHolderModel';
import { CombinationsSign } from './CombinationSign';
import { createResultHolderModel, ResultHolderMST, ResultHolderModel } from './ResultHolderModel';
import { createSignButtonModel, SignButtonModel, SignButtonMST } from './SignButtonModel';

export const CombinationsMST = GameRiddleContentMST.named('CombinationsMST')
  .props({
    combinationHolders: types.array(CombinationsHolderMST),
    correctCombinationHolder: CombinationsHolderMST,
    resultHolders: types.array(ResultHolderMST),
    signButtons: types.array(SignButtonMST),
    confirmBtns: types.array(ButtonMST),
    currentIndex: -1,
    pointsWon: 0
  })
  .views(self => ({
    isValidCombination(): boolean {
      if (self.currentIndex >= 0 && self.combinationHolders[self.currentIndex]) return self.combinationHolders[self.currentIndex].isCombinationValid();

      return false;
    },
    isConfirmShow(index: number) {
      return this.isValidCombination() && index === self.currentIndex;
    }
  }))
  .actions(self => ({
    setConfirmBtnActions(onConfirmButton: Function) {
      self.confirmBtns.forEach((confirmBtn: ButtonModel) => {
        confirmBtn.action.setAction(onConfirmButton);
      });
      this.setSignButtonsAction();
    },
    setSignButtonsAction() {
      self.signButtons.forEach((signBtn: SignButtonModel) => {
        signBtn.action.setAction(() => {
          if (self.currentIndex < 0 || self.currentIndex > self.combinationHolders.length - 1) return;
          this.setSignValue(signBtn.getSignType());
        });
      });

      self.combinationHolders.forEach((holder: CombinationsHolderModel) => {
        holder.signButtons.forEach((btn: SignButtonModel) => {
          btn.action.setAction(() => {
            btn.setType(CombinationsSign.None);
          });
          btn.action.setEnabled(false);
        });
      });
      self.correctCombinationHolder.setEnabled(false);
    },
    setSignButtonsEnabled(enabled: boolean) {
      self.signButtons.forEach((btn: SignButtonModel) => {
        btn.setEnabled(enabled);
      });
    },
    setSignValue(signValue: number) {
      if (self.currentIndex < 0 || self.combinationHolders[self.currentIndex].isCombinationSet()) return;

      self.combinationHolders[self.currentIndex].setSignToFirstEmptyHolder(signValue);
      self.confirmBtns[self.currentIndex].setEnabled(self.isValidCombination());
    },
    setActiveCombination(activeNum: number) {
      self.currentIndex = activeNum;
      if (activeNum >= 0 && activeNum <= 6) self.combinationHolders[activeNum].setEnabled(true);
      for (let i = 0; i < activeNum; i++) self.combinationHolders[i].setEnabled(false);
    },
    getActiveCombination(): CombinationsHolderModel {
      return self.combinationHolders[self.currentIndex];
    },
    setCombinationResult(index: number, hitsOnRightPlaceCnt: number, hitsWrongPlaceCount: number) {
      // if (index < 6) {
      //   self.resultHolders[index].setResult(hitsOnRightPlaceCnt, hitsWrongPlaceCount);
      //   self.resultHolders[index].setVisible(true);
      // } else {
      self.resultHolders[index].setResult(hitsOnRightPlaceCnt, hitsWrongPlaceCount);
      self.resultHolders[index].setVisible(true);
      // }
    },
    setSolutionCombination(solution: Array<number> | undefined) {
      if (!solution) return;

      self.correctCombinationHolder.setCombination(solution);
      self.correctCombinationHolder.setVisible(true);
    },
    setCombination(index: number, combination: Array<number> | undefined) {
      if (!combination) return;

      self.combinationHolders[index].setCombination(combination);
    },
    setPointsWon(pointsWon: number) {
      self.pointsWon = pointsWon;
    },
    resetModel() {
      self.combinationHolders.forEach((holder: CombinationsHolderModel) => {
        holder.reset();
      });
      self.resultHolders.forEach((resultHolder: ResultHolderModel) => {
        resultHolder.reset();
      });
      self.correctCombinationHolder.reset();
      self.currentIndex = -1;
      self.pointsWon = 0;
    }
  }));

type CombinationsModelType = typeof CombinationsMST.Type;
export interface CombinationsModel extends CombinationsModelType {}

const dummy = {
  name: 'Combinations',
  visible: false,
  combinationHolders: [createCombinationsHolderModel(), createCombinationsHolderModel(), createCombinationsHolderModel(), createCombinationsHolderModel(), createCombinationsHolderModel(), createCombinationsHolderModel(), createCombinationsHolderModel()],
  correctCombinationHolder: createCombinationsHolderModel(),
  resultHolders: [createResultHolderModel(), createResultHolderModel(), createResultHolderModel(), createResultHolderModel(), createResultHolderModel(), createResultHolderModel(), createResultHolderModel()],
  signButtons: [createSignButtonModel(CombinationsSign.Owl), createSignButtonModel(CombinationsSign.Club), createSignButtonModel(CombinationsSign.Spade), createSignButtonModel(CombinationsSign.Heart), createSignButtonModel(CombinationsSign.Diamond), createSignButtonModel(CombinationsSign.Star)],
  confirmBtns: [createConfirmPuzzleButton(), createConfirmPuzzleButton(), createConfirmPuzzleButton(), createConfirmPuzzleButton(), createConfirmPuzzleButton(), createConfirmPuzzleButton(), createConfirmPuzzleButton()],
  currentIndex: -1,
  pointsWon: 0
};

export const createCombinationsModel = () => CombinationsMST.create(dummy);
