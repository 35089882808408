import { ServerEvent } from '../../communication/message/ServerEvent';
import { News } from '../News';
import { Notification } from '../Notification';

const BINDING_NAME = 'rs.slagalica.player.message.NewsResponse';

export class NewsResponse extends ServerEvent {
  allNews?: Array<News> = undefined;

  // fieldType: ArrayList
  allNotifications?: Array<Notification> = undefined; // fieldType: ArrayList

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
