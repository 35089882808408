import { Local } from 'common/src/lang/Local';
import { QuizModel } from 'common/src/models/game/content/quiz/QuizModel';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { GameContent, GameContentProps } from '../../GameContent';
import { GameStatus } from '../../GameStatus';
import OptionQuestionButton from './OptionQuestionButton';
import Question from './Question';

interface QuizProps extends GameContentProps {
  quiz?: QuizModel;
}
@inject('quiz')
@observer
export default class Quiz extends GameContent<QuizProps, {}> {
  createAnimations() {
    this.props.quiz!.enteringAnimation.set(this.createEnteringAnimation);
    this.props.quiz!.exitingAnimation.set(this.createExitingAnimation);
    this.props.quiz!.preparingAnimation.set(this.createPreparingAnimation);
  }

  destroyAnimations() {
    this.props.quiz!.enteringAnimation.dispose();
    this.props.quiz!.exitingAnimation.dispose();
    this.props.quiz!.preparingAnimation.dispose();
  }

  componentDidMount() {
    this.createAnimations();
  }

  isVisible(): boolean {
    if (this.props.quiz) return this.props.quiz.isVisible();
    return false;
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  renderVisible(): React.ReactNode {
    const { quiz } = this.props;
    const t = (message: string) => Local.getString(message);
    if (!quiz) {
      return null;
    }

    return (
      <div
        ref={contentRef => {
          this.contentRef = contentRef;
        }}
      >
        <GameStatus />
        <div className="quiz">
          <div className="quiz__question-progress" />
          <div className="quiz__question">{quiz!.getQuestionText()}</div>
          <div className="quiz__question--progress">
            {quiz!.questions.map(question => (
              <Question question={question} currentQuestion={quiz!.currentQuestion} key={question.id} />
            ))}
          </div>
          <div className="quiz__answers-box">
            {quiz!.answers.map(answer => (
              <OptionQuestionButton answerOption={answer} key={answer.id} />
            ))}
          </div>
          {/* NO MORE SKIPPING WHEN PLAYING B2B (to bi considered) */}
          {/* <SkipButton baseClass="quiz__skip-button" model={quiz.skipButton} /> */}
        </div>
      </div>
    );
  }
}
