import { ServerEvent } from '../../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.games.mynumber.message.StartSolving';

export class StartSolving extends ServerEvent {
  targetNumber: number = 0;

  offer1: number = 0;

  offer2: number = 0;

  digit1: number = 0;

  digit2: number = 0;

  digit3: number = 0;

  digit4: number = 0;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
