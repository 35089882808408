import { PlayerAction } from './PlayerAction';

const BINDING_NAME = 'rs.slagalica.communication.message.GameFinished';

export class GameFinished extends PlayerAction {
  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
