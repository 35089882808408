import { PlayerAction } from '../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.b2b.message.B2bAdminCommandAction';

export class B2bAdminCommandAction extends PlayerAction {
  adminId?: string;

  constructor(adminId: string) {
    super();
    this.adminId = adminId;
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
