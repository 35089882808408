import { APP } from 'common/src/app/AppInstance';
import { SignIn } from 'common/src/messages/player/message/SignIn';
import { AppSettings } from 'common/src/settings/AppSettings';
import uuid from 'uuid-random';

const USE_FACEBOOK = false;
const DEBUG = true;
const GA_CODE: String = 'UA-32184471-4';
const PUBLISH_ENABLED = true;
const FRIENDS_ENABLED = true;
const IN_GAME_PROMOTION = true; /** if true, system will show in game promotion dialog to users (e.g. You are running out of helpkit...) */
const FACEBOOK_PROMOTION = true;

const DEFAULT_PROMOTION_PERIOD = 7; // days

// setting keys saved to localStorage
const MUSIC_KEY = 'music';
const SOUNDS_KEY = 'sound';
const MID_KEY = 'mid';
const LAST_BOOSTER_PROMOTION_DATE_KEY = 'boosterpromotion';
const LAST_HELPKIT_PROMOTION_DATE_KEY = 'helpkitpromotion';
const GAME_HARDER_KEY = 'gameharder';
const STORAGE_AVAILABILITY_TEST = 'STORAGE_AVAILABILITY_TEST';
const NEW_APP_INFO_DIALOG_SHOWN = 'newappinfodialog';
const NEW_PAYMENT_INFO_DIALOG_SHOWN = 'newpaymentinfodialog';
const CURRENT_ACCOUNT_TYPE = 'currentaccounttype';

const B2B_TOURNAMENT = 'b2btournament';
const B2B_TEAM = 'b2bteam';
const B2B_ID = 'b2bid';
const B2B_NAME = 'b2bname';
const B2B_AUTOLOGIN = 'b2bautologin';

const STORAGE_ERROR = 'Storage Error';

function storageAvailable() {
  const sat = STORAGE_AVAILABILITY_TEST;
  try {
    localStorage.setItem(sat, sat);
    localStorage.removeItem(sat);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      localStorage &&
      localStorage.length !== 0
    );
  }
}

function getStringProp(key: string, def: string): string {
  if (!storageAvailable()) return def;

  const value = localStorage.getItem(key);
  if (value) return value;

  return def;
}

function getBooleanProp(key: string, def: boolean): boolean {
  if (!storageAvailable()) return def;

  const value = localStorage.getItem(key);
  if (value) return value === 'true';

  return def;
}

function diffInDays(firstDate: Date, secondDate: Date): number {
  return Math.round((secondDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24));
}

export class WebSettings extends AppSettings {
  private serverUrl: string;

  constructor(serverUrl: string) {
    super();
    this.serverUrl = serverUrl;
  }

  public getMachineId(): string {
    const savedMid = getStringProp(MID_KEY, '');
    if (savedMid === '') {
      const generatedMid = uuid();
      this.setLocalStorageItem(MID_KEY, generatedMid);
      return generatedMid;
    }

    return savedMid;
  }

  public isMusicOn(): boolean {
    return getBooleanProp(MUSIC_KEY, true);
  }

  public enableMusic(enable: Boolean): void {
    this.setLocalStorageItem(MUSIC_KEY, enable.toString());
  }

  isSoundsOn(): boolean {
    return getBooleanProp(SOUNDS_KEY, true);
  }

  enableSounds(enable: Boolean): void {
    this.setLocalStorageItem(SOUNDS_KEY, enable.toString());
  }

  public isItRightTimeToSellSomeBoosters(): boolean {
    const last = localStorage.getItem(LAST_BOOSTER_PROMOTION_DATE_KEY);

    if (last == null) return Math.random() > 0.9; // return true with probability of 10%

    return diffInDays(new Date(last), new Date()) > DEFAULT_PROMOTION_PERIOD;
  }

  public isItRightTimeToSellSomeHelpKits(): boolean {
    const last = localStorage.getItem(LAST_HELPKIT_PROMOTION_DATE_KEY);

    if (last == null) return Math.random() > 0.9; // return true with probability of 10%

    return diffInDays(new Date(last), new Date()) > DEFAULT_PROMOTION_PERIOD;
  }

  public updateBoosterPromotionDate(date: Date): void {
    this.setLocalStorageItem(LAST_BOOSTER_PROMOTION_DATE_KEY, date.toString());
  }

  public updateHelpkitPromotionDate(date: Date): void {
    this.setLocalStorageItem(LAST_HELPKIT_PROMOTION_DATE_KEY, date.toString());
  }

  public resetGameGetsHarderInfo(): void {
    this.setLocalStorageItem(GAME_HARDER_KEY, false.toString());
  }

  public showGameGetsHarderInfo(): boolean {
    return getBooleanProp(GAME_HARDER_KEY, false);
  }

  public getGameUrl() {
    // FIXME should be https ???
    return 'http://apps.facebook.com/slagalica/';
  }

  public friendsEnabled() {
    return FRIENDS_ENABLED;
  }

  public getServerUrl(): string {
    return this.serverUrl;
  }

  private setLocalStorageItem(key: string, value: string) {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      APP.Tracker.trackError(STORAGE_ERROR, error.message ? error.message : error);
    }
  }

  public isNewAppInfoDialogShown(): boolean {
    return getBooleanProp(NEW_APP_INFO_DIALOG_SHOWN, false);
  }

  public setNewAppInfoDialogShown(): void {
    this.setLocalStorageItem(NEW_APP_INFO_DIALOG_SHOWN, 'true');
  }

  public isNewPaymentInfoDialogShown(): boolean {
    return getBooleanProp(NEW_PAYMENT_INFO_DIALOG_SHOWN, false);
  }

  public setNewPaymentInfoDialogShown(): void {
    this.setLocalStorageItem(NEW_PAYMENT_INFO_DIALOG_SHOWN, 'true');
  }

  public getCurrentAccountType(): number {
    return SignIn.TypeFacebook;
  }

  public setCurrentAccountType(type: number): void {
    this.setLocalStorageItem(CURRENT_ACCOUNT_TYPE, SignIn.TypeFacebook.toString());
  }

  public isStorageAvailable(): boolean {
    return true;
  }

  public getPlatformType(): number {
    return SignIn.PlatformDesktop;
  }

  public getB2bTournament(): string {
    return getStringProp(B2B_TOURNAMENT, '');
  }

  public getB2bTeam(): string {
    return getStringProp(B2B_TEAM, '');
  }

  public getB2bName(): string {
    return getStringProp(B2B_NAME, '');
  }

  public getB2bId(): string {
    return getStringProp(B2B_ID, '');
  }

  public getB2bAutologin(): boolean {
    return getBooleanProp(B2B_AUTOLOGIN, false);
  }

  public setB2bTournament(b: string): void {
    this.setLocalStorageItem(B2B_TOURNAMENT, b);
  }

  public setB2bTeam(b: string): void {
    this.setLocalStorageItem(B2B_TEAM, b);
  }

  public setB2bId(b: string): void {
    this.setLocalStorageItem(B2B_ID, b);
  }

  public setB2bName(b: string): void {
    this.setLocalStorageItem(B2B_NAME, b);
  }

  public setB2bAutologin(b: Boolean): void {
    this.setLocalStorageItem(B2B_AUTOLOGIN, b.toString());
  }
}
