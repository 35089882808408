import { types } from 'mobx-state-tree';
import { ActionMST } from './ActionModel';
import { VisibleMST } from './VisableModel';

// create clone element
// https://codepen.io/0skar/pen/MyoWrb

export const ButtonMST = VisibleMST.named('ButtonMST')
  .props({
    text: '',
    action: types.optional(ActionMST, {})
  })
  .actions(self => ({
    setEnabled(value: boolean) {
      self.action.setEnabled(value);
    },
    setText(text: string) {
      self.text = text;
    }
  }))
  .views(self => ({
    isEnabled() {
      return self.action.enabled;
    },
    getText(): string {
      return self.text;
    }
  }));

type ButtonModelType = typeof ButtonMST.Type;
export interface ButtonModel extends ButtonModelType {}

export const createConfirmPuzzleButton = () =>
  ButtonMST.create({
    visible: false,
    text: 'POTVRDI',
    action: { enabled: false }
  });

export const createStopPuzzleButton = () =>
  ButtonMST.create({
    visible: false,
    text: 'ZAUSTAVI',
    action: { enabled: false }
  });

export const createSkipQuizButton = () =>
  ButtonMST.create({
    visible: true,
    text: 'SKIP',
    action: { enabled: false }
  });

export const createClaimCaptainButton = () =>
  ButtonMST.create({
    visible: true,
    text: 'Da',
    action: { enabled: false }
  });

export const createDeclineCaptainButton = () =>
  ButtonMST.create({
    visible: true,
    text: 'Ne',
    action: { enabled: false }
  });
