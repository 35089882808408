import { types } from 'mobx-state-tree';
import { Local } from '../../../lang/Local';
import { BaseMST } from '../../BaseModel';
import { createStatisticsPercentageModel, StatisticsPercentageModelMST } from './StatisticsPercentageModel';

const PUZZLE = 0;
const MY_NUMBER = 1;
const COMBINATION = 2;
const QUIZ = 3;
const MATCHING = 4;
const ASSOCIATION = 5;
const PAIR = 6;

export class GameName {
  static get Puzzle() {
    return PUZZLE;
  }

  static get MyNumber() {
    return MY_NUMBER;
  }

  static get Combination() {
    return COMBINATION;
  }

  static get Quiz() {
    return QUIZ;
  }

  static get Matching() {
    return MATCHING;
  }

  static get Association() {
    return ASSOCIATION;
  }

  static get Pair() {
    return PAIR;
  }
}

export const StatisticsGameMST = BaseMST.named('StatisticsGameModel')
  .props({
    type: 0,
    name: '',
    title: '',
    gameMark: 0,
    percentagesGame: types.array(StatisticsPercentageModelMST)
  })
  .views(self => ({}))
  .actions(self => ({
    setPercentagesGame(percentages: Array<number>) {
      for (let i = 0; i < percentages.length; i++) {
        if (i > self.percentagesGame.length) return;
        self.percentagesGame[i].number = percentages[i];
      }
    },
    setGameMark(mark: number) {
      self.gameMark = mark;
    }
  }));

type StatisticsGameModelType = typeof StatisticsGameMST.Type;
export interface StatisticGameModel extends StatisticsGameModelType {}

export const createPuzzleGameModel = () =>
  StatisticsGameMST.create({
    type: GameName.Puzzle,
    name: Local.getString('statistics.puzzle_name'),
    title: Local.getString('statistics.puzzle_title'),
    gameMark: 0,
    percentagesGame: [
      createStatisticsPercentageModel(0, Local.getString('statistics.puzzle_percent_1')),
      createStatisticsPercentageModel(0, Local.getString('statistics.puzzle_percent_2')),
      createStatisticsPercentageModel(0, Local.getString('statistics.puzzle_percent_3')),
      createStatisticsPercentageModel(0, Local.getString('statistics.puzzle_percent_4')),
      createStatisticsPercentageModel(0, Local.getString('statistics.puzzle_percent_5')),
      createStatisticsPercentageModel(0, Local.getString('statistics.puzzle_percent_6')),
      createStatisticsPercentageModel(0, Local.getString('statistics.puzzle_percent_7'))
    ]
  });

export const createMyNumberGameModel = () =>
  StatisticsGameMST.create({
    type: GameName.MyNumber,
    name: Local.getString('statistics.my-number_name'),
    title: Local.getString('statistics.my-number_title'),
    gameMark: 0,
    percentagesGame: [
      createStatisticsPercentageModel(0, Local.getString('statistics.my-number_percent_1')),
      createStatisticsPercentageModel(0, Local.getString('statistics.my-number_percent_2')),
      createStatisticsPercentageModel(0, Local.getString('statistics.my-number_percent_3')),
      createStatisticsPercentageModel(0, Local.getString('statistics.my-number_percent_4')),
      createStatisticsPercentageModel(0, Local.getString('statistics.my-number_percent_5')),
      createStatisticsPercentageModel(0, Local.getString('statistics.my-number_percent_6')),
      createStatisticsPercentageModel(0, Local.getString('statistics.my-number_percent_7'))
    ]
  });

export const createCombinationsGameModel = () =>
  StatisticsGameMST.create({
    type: GameName.Combination,
    name: Local.getString('statistics.combinations_name'),
    title: Local.getString('statistics.combinations_title'),
    gameMark: 0,
    percentagesGame: [
      createStatisticsPercentageModel(0, Local.getString('statistics.combinations_percent_1')),
      createStatisticsPercentageModel(0, Local.getString('statistics.combinations_percent_2')),
      createStatisticsPercentageModel(0, Local.getString('statistics.combinations_percent_3')),
      createStatisticsPercentageModel(0, Local.getString('statistics.combinations_percent_4'))
    ]
  });

export const createQuizGameModel = () =>
  StatisticsGameMST.create({
    type: GameName.Quiz,
    name: Local.getString('statistics.quiz_name'),
    title: Local.getString('statistics.quiz_title'),
    gameMark: 0,
    percentagesGame: [createStatisticsPercentageModel(0, Local.getString('statistics.quiz_percent_1')), createStatisticsPercentageModel(0, Local.getString('statistics.quiz_percent_2')), createStatisticsPercentageModel(0, Local.getString('statistics.quiz_percent_3'))]
  });

export const createPairGameModel = () =>
  StatisticsGameMST.create({
    type: GameName.Pair,
    name: Local.getString('statistics.pair_name'),
    title: Local.getString('statistics.pair_title'),
    gameMark: 0,
    percentagesGame: [createStatisticsPercentageModel(0, Local.getString('statistics.pair_percent_1')), createStatisticsPercentageModel(0, Local.getString('statistics.pair_percent_2'))]
  });

export const createAssoctioantGameModel = () =>
  StatisticsGameMST.create({
    type: GameName.Association,
    name: Local.getString('statistics.association_name'),
    title: Local.getString('statistics.association_title'),
    gameMark: 0,
    percentagesGame: [
      createStatisticsPercentageModel(0, Local.getString('statistics.association_percent_1')),
      createStatisticsPercentageModel(0, Local.getString('statistics.association_percent_2')),
      createStatisticsPercentageModel(0, Local.getString('statistics.association_percent_3')),
      createStatisticsPercentageModel(0, Local.getString('statistics.association_percent_4')),
      createStatisticsPercentageModel(0, Local.getString('statistics.association_percent_5')),
      createStatisticsPercentageModel(0, Local.getString('statistics.association_percent_6'))
    ]
  });

export const createMatchingGameModel = () =>
  StatisticsGameMST.create({
    type: GameName.Pair,
    name: Local.getString('statistics.matching_name'),
    title: Local.getString('statistics.matching_title'),
    gameMark: 0,
    percentagesGame: [createStatisticsPercentageModel(0, Local.getString('statistics.pair_percent_1')), createStatisticsPercentageModel(0, Local.getString('statistics.pair_percent_2'))]
  });
