import { ServerEvent } from '../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.b2b.game.message.quiz.B2bQuizAnswer';

export class B2bQuizAnswer extends ServerEvent {
  index?: number;

  timeElapsed?: number;

  b2bPlayerAnswered?: number;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
