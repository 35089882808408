import { Animation, Power1, TimelineLite, TimelineMax, TweenMax, TweenLite } from 'gsap';
import GSAPAnimation from '../base/GSAPAnimation';

function createZoomIn(target: HTMLElement | null, duration: number = 0.2): Animation {
  const timeline = new TimelineLite({ paused: true });

  if (target)
    timeline.fromTo(
      target,
      duration,
      {
        scaleX: 0,
        scaleY: 0,
        opacity: 0,
        transformOrigin: 'center',
        ease: Power1.easeIn
      },
      { scaleX: 1, scaleY: 1, opacity: 1, transformOrigin: 'center' }
    );

  return timeline;
}
export class ZoomIn extends GSAPAnimation {
  constructor(target: HTMLElement | null, duration: number = 0.2) {
    super(createZoomIn(target, duration));
  }
}
