import { Local } from 'common/src/lang/Local';
import { StartGameResponse } from 'common/src/messages/player/message/StartGameResponse';
import { SearchOpponentModel } from 'common/src/models/dialogs/searchopponent/SearchOpponentModel';
import { HatsModel } from 'common/src/models/game/HatsModel';
import { Elastic, TimelineLite, TimelineMax } from 'gsap';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { HATS } from 'web/src/resources/HatResorces';
import GSAPAnimation from '../../../animations/base/GSAPAnimation';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';
import SearchOpponentCard from './SearchOpponentCard';

const ARROW = 1;
const VS = 2;
const MATCHING_STATUS = 3;
const OPPONENT_WAIT = 4;
const CURRENT_OPPONENT = 5;
const CURRENT_BOX = 6;

interface SearchOpponentProps extends DialogProps {
  searchOpponent?: SearchOpponentModel;
  hats?: HatsModel;
}
@inject('hats')
@observer
export default class SearchOpponent extends BaseDialog<SearchOpponentProps, {}> {
  divs: Array<HTMLDivElement | null> = [];

  opponentCard: SearchOpponentCard | null = null;

  isVisible(): boolean {
    if (this.props.searchOpponent) return this.props.searchOpponent.isVisible();
    return false;
  }

  componentDidMount() {
    this.createAnimations();
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  createAnimations() {
    this.props.searchOpponent!.opponentSearchAnimation.set(() => {
      const animationSearch = new TimelineLite({ paused: true });
      if (this.opponentCard && this.opponentCard!.divs[OPPONENT_WAIT]) {
        const divOpponentWait: any = this.opponentCard!.divs[OPPONENT_WAIT];
        const divArrow: any = this.divs[ARROW];

        animationSearch
          .add(
            new TimelineMax({ repeat: -1 }).to(divArrow, 0.4, {
              rotation: '-=360deg'
            })
          )
          .add(new TimelineMax({ repeat: -1 }).to(divOpponentWait, 0, { 'margin-top': '+=0rem' }).to(divOpponentWait, 0.5, { 'margin-top': '+=41rem' }), 0);
      }
      return new GSAPAnimation(animationSearch);
    });

    this.props.searchOpponent!.opponentSelectAnimation.set(() => {
      const opponentWaitAnimation = new TimelineLite({ paused: true });

      if (this.opponentCard && this.opponentCard!.divs[OPPONENT_WAIT]) {
        const divOpponentWait: any = this.opponentCard!.divs[OPPONENT_WAIT];
        const divCurrentOpponent: any = this.opponentCard!.divs[CURRENT_OPPONENT];
        const divCurrentBox: any = this.opponentCard!.divs[CURRENT_BOX];
        const divStatus: any = this.divs[MATCHING_STATUS];
        const divArrow: any = this.divs[ARROW];
        const divVS: any = this.divs[VS];

        opponentWaitAnimation
          .to(
            divOpponentWait,
            0.5,
            {
              display: 'none'
            },
            0
          )
          .to(divCurrentOpponent, 0.5, { top: '6%' }, 0.5)
          .to([divArrow, divStatus, divVS], 0.5, { opacity: 0 }, 0.5)
          .to(divCurrentOpponent, 0.5, { scale: '1.1', ease: Elastic.easeOut }, 1)
          .to(divCurrentBox, 0.5, { 'box-shadow': '0 0 3rem #e6cd17' }, 1)
          .to(divCurrentBox, 0.5, { 'box-shadow': 'none' }, 1.5)
          .to(divCurrentOpponent, 0.5, { scale: '1' }, 1.5);
      }

      return new GSAPAnimation(opponentWaitAnimation);
    });
  }

  destroyAnimations() {
    this.props.searchOpponent!.opponentSelectAnimation.dispose();
    this.props.searchOpponent!.opponentSearchAnimation.dispose();
  }

  getStatusText(): string {
    if (this.props.searchOpponent!.playerResponse === StartGameResponse.ACCEPTED) {
      if (this.props.searchOpponent!.opponentResponse === StartGameResponse.ACCEPTED) {
        return Local.getString('start.game_dlg_game_start');
      }
      if (this.props.searchOpponent!.opponentResponse === StartGameResponse.DECLINED) {
        return Local.getString('start.game_dlg_opp_reject');
      }
    }

    if (this.props.searchOpponent!.playerResponse === StartGameResponse.NOT_COMFIRMED) {
      if (this.props.searchOpponent!.opponentResponse === StartGameResponse.ACCEPTED) {
        return Local.getString('start.game_dlg_opp_accept');
      }
      if (this.props.searchOpponent!.opponentResponse === StartGameResponse.DECLINED) {
        return Local.getString('start.game_dlg_opp_reject');
      }
    }

    return Local.getString('start.game_dlg_wait');
  }

  renderVisible() {
    const { searchOpponent } = this.props;
    const t = (message: string) => Local.getString(message);
    return (
      <div className="search-opponent">
        <div className="search-opponent__inner">
          <DialogHeader
            onClose={() => {
              if (!searchOpponent!.friendlyGame) searchOpponent!.cancelMatching.performAction();
            }}
          >
            {t('search_opponent.header')}
          </DialogHeader>
          <div
            className="search-opponent__searching"
            ref={div => {
              this.divs[MATCHING_STATUS] = div;
            }}
            style={{
              visibility: searchOpponent!.gameAcceptanceHolder.isVisible() || searchOpponent!.friendlyGame ? 'hidden' : 'visible'
            }}
          >
            {t('search_opponent.serching_for_opponent')}
          </div>

          <div className="search-opponent__content">
            <SearchOpponentCard name={searchOpponent!.playerName} position="left" rating={searchOpponent!.playerRating} rank={searchOpponent!.playerRank} searchOpponent={searchOpponent!} div={this.divs} isMe />
            <div className="search-opponent__central-content">
              <div
                className="search-opponent__matching-arrows"
                ref={div => {
                  this.divs[ARROW] = div;
                }}
                style={{
                  visibility: searchOpponent!.gameAcceptanceHolder.isVisible() || searchOpponent!.friendlyGame ? 'hidden' : 'visible'
                }}
              />
              <div
                className="search-opponent__matching-vs"
                ref={div => {
                  this.divs[VS] = div;
                }}
                style={{
                  visibility: searchOpponent!.gameAcceptanceHolder.isVisible() || searchOpponent!.friendlyGame ? 'hidden' : 'visible'
                }}
              />
              <div
                className="search-opponent__timer"
                style={{
                  visibility: !searchOpponent!.gameAcceptanceHolder.isVisible() || searchOpponent!.friendlyGame ? 'hidden' : 'visible'
                }}
              >
                {searchOpponent!.timer}
              </div>
            </div>
            <SearchOpponentCard
              name={searchOpponent!.opponentName}
              position="right"
              rating={searchOpponent!.opponentRating}
              rank={searchOpponent!.opponentRank}
              searchOpponent={searchOpponent!}
              div={this.divs}
              ref={opponent => {
                this.opponentCard = opponent;
              }}
              isMe={false}
            />
            {this.props.hats && this.props.hats.hasHat() && <img className="search-opponent__my_hat" src={HATS.getHatPreviewImg(this.props.hats.getHatId())} />}
            {this.props.hats && this.props.hats.hasOpponentHat() && <img className="search-opponent__opponents_hat" src={HATS.getHatPreviewImg(this.props.hats.getOpponentHatId())} />}
          </div>
          <div className="search-opponent__acceptance_holder" style={{ visibility: this.props.searchOpponent!.gameAcceptanceHolder.visible ? 'visible' : 'hidden' }}>
            <div className="search-opponent__acceptance_holder__waiting-text">{this.getStatusText()}</div>
            <button
              className={`search-opponent__acceptance_holder__btn search-opponent__acceptance_holder__btn--accept  ${this.props.searchOpponent!.gameAcceptanceHolder.acceptAction.enabled ? '' : 'search-opponent__acceptance_holder__btn--accept--disabled'}`}
              onClick={() => {
                this.props.searchOpponent!.gameAcceptanceHolder.acceptAction.performAction();
              }}
            >
              {t('search_opponent.accept')}
            </button>
            <button
              className={`search-opponent__acceptance_holder__btn search-opponent__acceptance_holder__btn--decline ${this.props.searchOpponent!.gameAcceptanceHolder.declineAction.enabled ? '' : 'search-opponent__acceptance_holder__btn--decline--disabled'}`}
              onClick={() => {
                this.props.searchOpponent!.gameAcceptanceHolder.declineAction.performAction();
              }}
            >
              {t('search_opponent.decline')}
            </button>
            <div className="search-opponent__acceptance_holder__decline-text">{t('search_opponent.decline_text')}</div>
          </div>
        </div>
      </div>
    );
  }
}
