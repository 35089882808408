import { Local } from 'common/src/lang/Local';
import { Reward } from 'common/src/messages/player/reward/message/Reward';
import { HappyBirthdayModel } from 'common/src/models/dialogs/happybirthday/HappyBirthday';
import Images from 'web/src/resources/ImgResources';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { REWARDS } from 'web/src/resources/RewardResources';
import { Countdown } from '../../../animations/samples/Countdown';
import { Fade } from '../../../animations/samples/Fade';
import { GSAPPause } from '../../../animations/base/GSAPPause';
import { Rotate } from '../../../animations/samples/Rotate';
import { CurveTranslateTo } from '../../../animations/samples/Translate';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';
import GSAPSequenceAnimation from '../../../animations/base/GSAPSequenceAnimator';
import GSAPParallelAnimation from '../../../animations/base/GSAPParallelAnimation';

interface HappyBirthdayProps extends DialogProps {
  happyBirthdayDialog?: HappyBirthdayModel;
}

@inject('happyBirthdayDialog')
@observer
export default class HappyBirthday extends BaseDialog<HappyBirthdayProps, {}> {
  rewardsImageDivs: Map<Number, HTMLImageElement | null> = new Map<Number, HTMLImageElement | null>();

  rewardsImageFlyDivs: Map<Number, HTMLImageElement | null> = new Map<Number, HTMLImageElement | null>();

  rewardsPointsDivs: Map<Number, HTMLDivElement | null> = new Map<Number, HTMLDivElement | null>();

  createAnimations() {
    const { happyBirthdayDialog } = this.props;
    happyBirthdayDialog!.claimRewardAnimation.set(() => {
      const sequenceGlobal: GSAPSequenceAnimation = new GSAPSequenceAnimation();
      const parallel: GSAPParallelAnimation = new GSAPParallelAnimation();
      const sequenceFly: GSAPSequenceAnimation = new GSAPSequenceAnimation();
      const parallelFade: GSAPParallelAnimation = new GSAPParallelAnimation();
      const globalParallel: GSAPParallelAnimation = new GSAPParallelAnimation();

      this.rewardsImageDivs.forEach(image => {
        globalParallel.add(new Rotate(image, 0.5, 3));
      });
      this.rewardsImageFlyDivs.forEach(image => {
        globalParallel.add(new Rotate(image, 0.5, 2));
      });

      sequenceFly.add(new GSAPPause(1));

      let positionDistance = 0;
      this.rewardsImageFlyDivs.forEach((image, i) => {
        if (i === Reward.TYPE_TOKENS) {
          parallel.add(new CurveTranslateTo(image, 0.7, -300 - positionDistance, -418, 2));
        }
        positionDistance += 40;
      });
      const pointDiv = this.rewardsPointsDivs.get(happyBirthdayDialog!.reward.id) ? this.rewardsPointsDivs.get(happyBirthdayDialog!.reward.id) : null;
      if (pointDiv != null) parallel.add(new Countdown(pointDiv!, happyBirthdayDialog!.reward.count, 0));

      this.rewardsImageFlyDivs.forEach(image => {
        parallelFade.add(new Fade(image, 0.1, 1, 0));
      });
      sequenceFly.add(parallel);

      globalParallel.add(sequenceFly);
      sequenceGlobal.add(globalParallel);

      sequenceGlobal.add(parallelFade);
      return sequenceGlobal;
    });
  }

  destroyAnimations() {
    this.props.happyBirthdayDialog!.claimRewardAnimation.dispose();
  }

  componentDidMount() {
    this.createAnimations();
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  isVisible(): boolean {
    if (this.props.happyBirthdayDialog) return this.props.happyBirthdayDialog.isVisible();
    return false;
  }

  renderVisible() {
    const { happyBirthdayDialog } = this.props;
    return (
      <div className="happy-birthday">
        <div className="happy-birthday__inner" style={{ marginTop: `${40}px` }}>
          <DialogHeader onClose={() => happyBirthdayDialog!.hide()}>{Local.getString('happy_birthday.title')}</DialogHeader>
          <img src={Images.owlGift} className="happy-birthday__icon" />
          <div className="happy-birthday__description">{Local.getString('happy_birthday.desc')}</div>
          <div className="happy-birthday__rewarded">
            <div className="happy-birthday__rewarded--open-gift-box" />
            <div className="happy-birthday__rewarded--award-content">
              <div className="happy-birthday__rewarded--award-icon-count">
                <img
                  className="happy-birthday__rewarded--award-tooltip-icon"
                  src={REWARDS.getImg(happyBirthdayDialog!.reward!.id)}
                  ref={img => {
                    this.rewardsImageDivs.set(happyBirthdayDialog!.reward!.id, img);
                  }}
                />
                <img
                  className="happy-birthday__rewarded--award-tooltip-icon happy-birthday__rewarded--award-tooltip-icon--fly"
                  src={REWARDS.getImg(happyBirthdayDialog!.reward!.id)}
                  ref={img => {
                    this.rewardsImageFlyDivs.set(happyBirthdayDialog!.reward!.id, img);
                  }}
                />

                <div
                  className="happy-birthday__rewarded--award-tooltip-count"
                  ref={div => {
                    this.rewardsPointsDivs.set(happyBirthdayDialog!.reward!.id, div);
                  }}
                >
                  {happyBirthdayDialog!.reward!.count}
                </div>
              </div>
            </div>
          </div>
          <button className="happy-birthday__buy-now" onClick={happyBirthdayDialog!.claimButton.action.performAction}>
            {happyBirthdayDialog!.claimButton.getText()}
          </button>
        </div>
      </div>
    );
  }
}
