import { types } from 'mobx-state-tree';
import { AnimationBuilderMST } from '../../../AnimationBuilder';
import { VisibleMST } from '../../../VisableModel';

export const OpponentsSolutionMST = VisibleMST.named('OpponentsSolutionMST')
  .props({
    number: 0,
    solution: '',
    points: 0,
    pointsAnimation: types.optional(AnimationBuilderMST, {}),
    resultBlinkAnimation: types.optional(AnimationBuilderMST, {}),
    isSolving: true,
    opponentProgressShown: false
  })
  .actions(self => ({
    setSolving(solving: boolean) {
      self.isSolving = solving;
    },
    setOpponentProgressShown() {
      self.opponentProgressShown = true;
    },
    setResultNumber(result: number) {
      self.number = result;
    },
    setPoints(points: number) {
      self.points = points;
    },
    reset() {
      self.setVisible(false);
      self.number = 0;
      self.solution = '';
      self.points = 0;
      self.isSolving = true;
      self.opponentProgressShown = false;
    }
  }))
  .views(self => ({}));

type OpponentsSolutionType = typeof OpponentsSolutionMST.Type;
export interface OpponentsSolutionModel extends OpponentsSolutionType {}

export const createOpponentsSolutionModel = () =>
  OpponentsSolutionMST.create({
    number: 0,
    solution: '',
    points: 0,
    pointsAnimation: {},
    isSolving: true,
    opponentProgressShown: false
  });
