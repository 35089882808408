import { Local } from 'common/src/lang/Local';
import { ImportantInfoModel } from 'common/src/models/dialogs/info/ImportantInfoModel';
import { observer } from 'mobx-react';
import React from 'react';
import Img from 'react-image';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';

const IMAGE_URL = 'https://fb.slagalica-online.com/resources/sbl/info.png';
const URL = 'https://docs.google.com/forms/d/e/1FAIpQLSeKWrh4CsVoejMuu2cWiv2YhY367mzGlWZf7DbaEwuCfXUFHw/viewform';

interface NewAppInfoProps extends DialogProps {
  newAppInfoDialog: ImportantInfoModel;
}

@observer
export default class NewAppInfoDialog extends BaseDialog<NewAppInfoProps, {}> {
  isVisible(): boolean {
    if (this.props.newAppInfoDialog) return this.props.newAppInfoDialog.isVisible();
    return false;
  }

  renderVisible() {
    return (
      <div className="new-app-info">
        <div className="new-app-info__inner">
          <DialogHeader onClose={this.props.newAppInfoDialog.hide}>{Local.getString('common.information')}</DialogHeader>
          <div className="new-app-info__image-container">
            <Img src={`${IMAGE_URL}?${new Date()}`} className="new-app-info__image" />
          </div>
          <button
            className="new-app-info__confirm"
            onClick={() => {
              window.open(URL, '_blank');
            }}
          >
            Popunite anketu
          </button>
        </div>
      </div>
    );
  }
}
