import { PlayerAction } from '../../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.player.message.SendCredits';

export class SendCredits extends PlayerAction {
  playerIdToSend: number = 0;

  // fieldType: long
  amount: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
