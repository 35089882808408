import { types } from 'mobx-state-tree';
import { ActionMST } from '../../ActionModel';
import { AnimationBuilderMST } from '../../AnimationBuilder';
import { DisplayMST } from '../../DisplayModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const TOKENS_REWARD_COUNT = [50, 60, 70, 80, 100];

export const DailyRewardsMST = DisplayMST.named('DailyRewardsModel')
  .props({
    todayIndex: 0,
    acceptRewards: types.optional(ActionMST, {}),
    glowRewardAnimation: AnimationBuilderMST,
    acceptRewardsAnimation: AnimationBuilderMST
  })
  .views(self => ({}))
  .actions(self => ({
    setTodaysIndex(todayIndex: number) {
      self.todayIndex = todayIndex;
    }
  }));

type DailyRewardsModelType = typeof DailyRewardsMST.Type;
export interface DailyRewardsModel extends DailyRewardsModelType {}
export const createDailyRewardsModel = () =>
  DailyRewardsMST.create({
    key: DisplayModelKeys.DailyReward,
    todayIndex: 1,
    glowRewardAnimation: {},
    acceptRewardsAnimation: {}
  });
