import { Local } from 'common/src/lang/Local';
import Images from 'web/src/resources/ImgResources';

export class AchievementDescriptor {
  private name: string;

  private descriptionRes: string;

  private image: string;

  private row: number;

  private column: number;

  constructor(name: string, description: string, image: string, row: number, column: number) {
    this.name = Local.getString(name);
    this.descriptionRes = description;
    this.image = image;
    this.row = row;
    this.column = column;
  }

  public getName(): string {
    return this.name;
  }

  public getDescription(): string {
    return this.descriptionRes;
  }

  public getImage(): string {
    return this.image;
  }

  public getRow(): number {
    return this.row;
  }

  public getColumn(): number {
    return this.column;
  }
}

class AchievementResources {
  private achievements: Array<AchievementDescriptor> = [];

  private defaultTargetValues: Array<number> = [];

  constructor() {
    this.achievements[0] = new AchievementDescriptor('ach.name0-0', 'ach.desc0-0', Images.pilecePero, 0, 0);
    this.achievements[1] = new AchievementDescriptor('ach.name0-1', 'ach.desc0-1', Images.petlovoPero, 0, 1);
    this.achievements[2] = new AchievementDescriptor('ach.name0-2', 'ach.desc0-2', Images.sokolovoPero, 0, 2);
    this.achievements[3] = new AchievementDescriptor('ach.name0-3', 'ach.desc0-3', Images.orlovoPero, 0, 3);
    this.achievements[4] = new AchievementDescriptor('ach.name0-4', 'ach.desc0-4', Images.paunovoPero, 0, 4);
    this.achievements[10] = new AchievementDescriptor('ach.name1-0', 'ach.desc1-0', Images.kameniPehar, 1, 0);
    this.achievements[11] = new AchievementDescriptor('ach.name1-1', 'ach.desc1-1', Images.drveniPehar, 1, 1);
    this.achievements[12] = new AchievementDescriptor('ach.name1-2', 'ach.desc1-2', Images.bronzaniPehar, 1, 2);
    this.achievements[13] = new AchievementDescriptor('ach.name1-3', 'ach.desc1-3', Images.srebrniPehar, 1, 3);
    this.achievements[14] = new AchievementDescriptor('ach.name1-4', 'ach.desc1-4', Images.zlatniPehar, 1, 4);
    this.achievements[20] = new AchievementDescriptor('ach.name2-0', 'ach.desc2-0', Images.zlatnoSlovo, 2, 0);
    this.achievements[21] = new AchievementDescriptor('ach.name2-1', 'ach.desc2-1', Images.zlatniAs, 2, 1);
    this.achievements[22] = new AchievementDescriptor('ach.name2-2', 'ach.desc2-2', Images.zlatnaSova, 2, 2);
    this.achievements[23] = new AchievementDescriptor('ach.name2-3', 'ach.desc2-3', Images.sijalicaZaNocno, 2, 3);
    // this.achievements[724] = new AchievementDescriptor('ach.name2-4', 'ach.desc2-4', Images.zlatnaSpojnica);
    this.achievements[24] = new AchievementDescriptor('ach.name2-5', 'ach.desc2-5', Images.zlatnoSito, 2, 4);
    this.achievements[30] = new AchievementDescriptor('ach.name3-0', 'ach.desc3-0', Images.dediniPehari, 3, 0);
    this.achievements[31] = new AchievementDescriptor('ach.name3-1', 'ach.desc3-1', Images.pradediniPehari, 3, 1);
    this.achievements[32] = new AchievementDescriptor('ach.name3-2', 'ach.desc3-2', Images.cukundediniPehari, 3, 2);
    this.achievements[33] = new AchievementDescriptor('ach.name3-3', 'ach.desc3-3', Images.navrdediniPehari, 3, 3);
    this.achievements[34] = new AchievementDescriptor('ach.name3-4', 'ach.desc3-4', Images.askurdjeloviPehari, 3, 4);
    this.achievements[40] = new AchievementDescriptor('ach.name4-0', 'ach.desc4-0', Images.kamenaZnacka, 4, 0);
    this.achievements[41] = new AchievementDescriptor('ach.name4-1', 'ach.desc4-1', Images.drvenaZnacka, 4, 1);
    this.achievements[42] = new AchievementDescriptor('ach.name4-2', 'ach.desc4-2', Images.bronzanaZnacka, 4, 2);
    this.achievements[43] = new AchievementDescriptor('ach.name4-3', 'ach.desc4-3', Images.srebrnaZnacka, 4, 3);
    this.achievements[44] = new AchievementDescriptor('ach.name4-4', 'ach.desc4-4', Images.zlatnaZnacka, 4, 4);

    this.defaultTargetValues[0] = 150;
    this.defaultTargetValues[1] = 200;
    this.defaultTargetValues[2] = 300;
    this.defaultTargetValues[3] = 450;
    this.defaultTargetValues[4] = 600;
    this.defaultTargetValues[10] = 30;
    this.defaultTargetValues[11] = 60;
    this.defaultTargetValues[12] = 90;
    this.defaultTargetValues[13] = 120;
    this.defaultTargetValues[14] = 150;
    this.defaultTargetValues[20] = 0;
    this.defaultTargetValues[21] = 0;
    this.defaultTargetValues[22] = 0;
    this.defaultTargetValues[23] = 0;
    this.defaultTargetValues[24] = 0;
    this.defaultTargetValues[30] = 2;
    this.defaultTargetValues[31] = 3;
    this.defaultTargetValues[32] = 4;
    this.defaultTargetValues[33] = 5;
    this.defaultTargetValues[34] = 7;
    this.defaultTargetValues[40] = 110;
    this.defaultTargetValues[41] = 130;
    this.defaultTargetValues[42] = 150;
    this.defaultTargetValues[43] = 170;
    this.defaultTargetValues[44] = 190;
  }

  public getName(id: number): string {
    if (this.achievements[id] === undefined) return 'Unknown';

    return this.achievements[id].getName();
  }

  public getDesc(id: number, reqValue: number): string {
    if (this.achievements[id] === undefined) return 'Unknown';

    return Local.getString(this.achievements[id].getDescription(), reqValue !== 0 ? reqValue : this.getDefaultTargetValue(id));
  }

  public getImg(id: number): string {
    if (this.achievements[id] === undefined) return 'Unknown';

    return this.achievements[id].getImage();
  }

  public getAchievements() {
    return this.achievements;
  }

  private getDefaultTargetValue(id: number): number {
    if (!this.defaultTargetValues[id]) return 0;
    return this.defaultTargetValues[id];
  }
}

export const ACHIEVEMENTS = new AchievementResources();
