const HAT_GROUP = 1;
const CHAT_GROUP = 2;

export class ShopGroups {
  static get HAT_GROUP() {
    return HAT_GROUP;
  }

  static get CHAT_GROUP() {
    return CHAT_GROUP;
  }

  static getGroup(name: string) {
    switch (name) {
      case 'hat':
        return HAT_GROUP;
      case 'chat':
        return CHAT_GROUP;
      default:
        return HAT_GROUP;
    }
  }
}
