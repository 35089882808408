import { ServerEvent } from './ServerEvent';
import { GameSequenceStarted } from '../../player/message/GameSequenceStarted';

const BINDING_NAME = 'rs.slagalica.communication.message.GameAvailable';

export class GameAvailable extends ServerEvent {
  gameInfo?: GameSequenceStarted = undefined;

  // fieldType: GameSequenceStarted
  estimatedFinish: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
