import { inject, observer } from 'mobx-react';
import React from 'react';
import { DisplayModel } from 'common/src/models/DisplayModel';
import { Local } from 'common/src/lang/Local';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';

interface EqualScoreProps extends DialogProps {
  equalScoreDialog?: DisplayModel;
}
@observer
export default class EqualScore extends BaseDialog<EqualScoreProps, {}> {
  isVisible(): boolean {
    if (this.props.equalScoreDialog) return this.props.equalScoreDialog.isVisible();
    return false;
  }

  renderVisible() {
    const { equalScoreDialog } = this.props;

    return (
      <div className="equal-score">
        <div className="equal-score__inner">
          <DialogHeader onClose={() => equalScoreDialog!.hide()}>{Local.getString('equal_score_dlg.title')}</DialogHeader>
          <div className="equal-score__image" />

          <div className="equal-score__description">{Local.getString('equal_score_dlg.msg')}</div>
          <button className="equal-score__close" onClick={() => equalScoreDialog!.hide()}>
            {Local.getString('common.close')}
          </button>
        </div>
      </div>
    );
  }
}
