import { APP } from '../../../app/AppInstance';
import { Local } from '../../../lang/Local';
import { ServerEvent } from '../../../messages/communication/message/ServerEvent';
import { LoadFriends } from '../../../messages/player/message/LoadFriends';
import { LoadRangList } from '../../../messages/player/message/LoadRangList';
import { PlayerInfo } from '../../../messages/player/message/PlayerInfo';
import { PlayerInfoBase } from '../../../messages/player/message/PlayerInfoBase';
import { RangList } from '../../../messages/player/message/RangList';
import { Reward } from '../../../messages/player/reward/message/Reward';
import { DisplayModel } from '../../../models/DisplayModel';
import { ModelFactory } from '../../../models/ModelFactory';
import { TournamentsModel } from '../../../models/tournament/TorunamentsModel';
import { UserModel } from '../../../models/user/UserModel';
import { PlayerController } from '../PlayerController';
import { PlayerSubController } from '../PlayerSubController';
import { FriendsStatus } from '../../../messages/player/message/FriendsStatus';
import { ListPositionMaximum } from '../../../messages/player/league/message/ListPositionMaximum';

export class TournamentController extends PlayerSubController {
  private tournaments: TournamentsModel;

  private helpDialog: DisplayModel;

  private userModel: UserModel;

  constructor(controller: PlayerController, tournaments: TournamentsModel, user: UserModel) {
    super(controller);
    this.tournaments = tournaments;
    this.userModel = user;
    this.helpDialog = ModelFactory.createTournamentHelp();

    this.tournaments.friends.onHelp.setAction(this.helpDialog.schedule);
    this.tournaments.titles.onHelp.setAction(this.helpDialog.schedule);
  }

  public onMessage(message: ServerEvent) {
    switch (message.getClass()) {
      case RangList.ClassName:
        this.onRangList(message as RangList);
        break;
      case FriendsStatus.ClassName:
        this.onFriendsStatus(message as FriendsStatus);
        break;
      default:
        break;
    }
  }

  private onRangList(message: RangList) {
    if (!this.tournaments) {
      return;
    }

    this.tournaments.titles!.setRank(message.leagueInfo!.rank);
    this.tournaments.titles.removeAllPlayers();

    let position = 1;
    message.list.forEach((element: PlayerInfoBase) => {
      if (position < 10 || (position === 10 && message.myPosition <= 10)) {
        let rewards: Array<Reward> = [];
        if (message!.leagueInfo!.positionRewards && position - 1 < message!.leagueInfo!.positionRewards!.length && message!.leagueInfo!.positionRewards![position - 1].rewards) {
          rewards = message!.leagueInfo!.positionRewards![position - 1].rewards!;
        }
        this.tournaments!.titles.addTournamentPlayer(
          position,
          element.name,
          element.rankListTotalPoints,
          element.rankingPoints,
          element.rank,
          position === message.myPosition,
          rewards,
          element.id === this.userModel.info.id ? this.getDailyHighScores(this.userModel.info) : [],
          element.stat,
          element.maxPrevRank,
          true
        );

        position += 1;
      }
    });

    if (message.myPosition > 10) {
      const me: PlayerInfo = this.userModel.info;
      this.tournaments!.titles.addTournamentPlayer(message.myPosition, me.name, me.rankListTotalPoints, me.rankingPoints, me.rank, true, [], this.getDailyHighScores(me), me.stat, me.maxPrevRank, true);
    }
  }

  private onFriendsStatus(message: FriendsStatus) {
    let friends = message.friends!.slice(0, 9);
    friends.push(this.userModel.info);
    friends = friends.sort((a: PlayerInfoBase, b: PlayerInfoBase) => b.rankListTotalPoints - a.rankListTotalPoints);
    this.tournaments!.friends.removeAllPlayers();
    let position = 1;
    friends.forEach((element: PlayerInfoBase) => {
      this.tournaments!.friends.addTournamentPlayer(
        position,
        element.name,
        element.rankListTotalPoints,
        element.rankingPoints,
        element.rank,
        element.id === this.userModel.info.id,
        [],
        element.id === this.userModel.info.id ? this.getDailyHighScores(this.userModel.info) : [],
        element.stat,
        element.maxPrevRank,
        false
      );
      position += 1;
    });
  }

  private getDailyHighScores(info: PlayerInfo): Array<number> {
    const dailyHighScores: Array<number> = [0, 0, 0, 0, 0, 0, 0];

    if (info.rankListPoints && info.rankListPoints.maximums) {
      info.rankListPoints.maximums.forEach((listPositionMaximum: ListPositionMaximum) => {
        dailyHighScores[listPositionMaximum.dayOfWeek > 0 ? listPositionMaximum.dayOfWeek - 1 : 0] = listPositionMaximum.maximum;
      });
    }

    return dailyHighScores;
  }

  public requestLoadRangList() {
    this.sendMessageToServer(new LoadRangList());
  }

  public requestLoadFriends(fbId: string | undefined) {
    if (fbId) {
      const loadFriends = new LoadFriends();
      loadFriends.facebookIds = APP.FacebookAPI.getFriends(fbId);
      this.sendMessageToServer(loadFriends);
    }
  }
}
