import { types } from 'mobx-state-tree';
import { Reward } from '../../messages/player/reward/message/Reward';
import { BaseMST } from '../BaseModel';
import { createRewardItemModel, RewardItemMST } from '../common/RewardItemModel';
import { StatisticsMST, createStatisticsModel } from '../dialogs/statistics/StatisticsModel';
import { UserStats } from '../../messages/player/stats/UserStats';

export const TournamentItemMST = BaseMST.named('TournamentItemModel')
  .props({
    position: 1,
    name: 'unknown',
    rating: 0,
    rank: 0,
    expandable: false,
    rewards: types.array(RewardItemMST),
    dailyHighscores: types.array(types.number),
    feathers: 0,
    maxPrevRank: 0,
    stats: StatisticsMST,
    isRatingList: false
  })
  .views(self => ({}))
  .actions(self => ({
    init(position: number, name: string, feathers: number, rating: number, rank: number, expandable: boolean, rewards: Array<Reward>, dailyHighscores: Array<number>, stats: UserStats | undefined, maxPrevRank: number, isRatingList: boolean) {
      self.position = position;
      self.name = name;
      self.rating = rating;
      self.rank = rank;
      self.expandable = expandable;
      Array.from(rewards).forEach((reward: Reward) => {
        self.rewards.push(createRewardItemModel(reward.type, reward.count));
      });
      self.dailyHighscores.replace(dailyHighscores);
      self.feathers = feathers;
      self.stats.setStatistics(stats);
      self.maxPrevRank = maxPrevRank;
      self.isRatingList = isRatingList;
    }
  }));

type TournamentItemModelType = typeof TournamentItemMST.Type;
export interface TournamentItem extends TournamentItemModelType {}
export const createTournamentItemModel = () => TournamentItemMST.create({ dailyHighscores: [0, 0, 0, 0, 0, 0, 0, 0], stats: createStatisticsModel() });
