import { Local } from 'common/src/lang/Local';
import { AssociationModel } from 'common/src/models/game/content/association/AssociationModel';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Glow } from '../../../../animations/samples/Glow';
import { PointsAnimation } from '../../../../animations/samples/PointsAnimation';
import { GameContent, GameContentProps } from '../../GameContent';
import { GameStatus } from '../../GameStatus';
import Point from '../../Point';
import SkipButton from '../../SkipButton';
import AssociationColumn from './AssociationColumn';
import AssociationResultButton from './AssociationResultButton';
import GSAPSequenceAnimation from '../../../../animations/base/GSAPSequenceAnimator';
import { Glow3D } from '../../../../animations/samples/Glow3D';

interface AssociationProps extends GameContentProps {
  association?: AssociationModel;
}
@inject('association')
@observer
export default class Association extends GameContent<AssociationProps, {}> {
  columnFinal: AssociationResultButton | null = null;

  pointFinal: Point | null = null;

  createPointAnimation = (args: any[]) => {
    const isBlue: boolean = args[0];
    if (this.pointFinal) return PointsAnimation.create(isBlue, this.pointFinal);
    return new GSAPSequenceAnimation();
  };

  createOpenColumnAnimation = () => {
    const seq = new GSAPSequenceAnimation();
    seq.add(new Glow3D(this.columnFinal!.boxRef!, 0.1, 1));
    return seq;
  };

  createAnimations() {
    this.props.association!.resultFinal!.openAnimation.set(this.createOpenColumnAnimation);
    this.props.association!.resultFinal!.pointsAnimation.set(this.createPointAnimation);
    this.props.association!.enteringAnimation.set(this.createEnteringAnimation);
    this.props.association!.exitingAnimation.set(this.createExitingAnimation);
    this.props.association!.preparingAnimation.set(this.createPreparingAnimation);
  }

  destroyAnimations() {
    this.props.association!.resultFinal!.openAnimation.dispose();
    this.props.association!.resultFinal!.pointsAnimation.dispose();
    this.props.association!.enteringAnimation.dispose();
    this.props.association!.exitingAnimation.dispose();
    this.props.association!.preparingAnimation.dispose();
  }

  componentDidMount() {
    this.createAnimations();
  }

  isVisible(): boolean {
    if (this.props.association) return this.props.association.isVisible();
    return false;
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  renderVisible(): React.ReactNode {
    const { association } = this.props;
    const t = (message: string) => Local.getString(message);
    if (!association) {
      return null;
    }

    return (
      <div
        ref={contentRef => {
          this.contentRef = contentRef;
        }}
      >
        <GameStatus />
        <div className="association">
          <AssociationColumn model={association!.resultA} hint={association!.hintsA} typeColumn="a" reverse />
          <AssociationColumn model={association!.resultB} hint={association!.hintsB} typeColumn="b" reverse />
          <AssociationColumn model={association!.resultC} hint={association!.hintsC} typeColumn="c" reverse={false} />
          <AssociationColumn model={association!.resultD} hint={association!.hintsD} typeColumn="d" reverse={false} />
          <AssociationResultButton
            key={association!.resultFinal.id}
            model={association!.resultFinal}
            baseClass="association__final-result"
            ref={game => {
              this.columnFinal = game;
            }}
          />
          <Point
            baseClass="association__point association__point--final"
            value={association.resultFinal ? association.resultFinal.points : 0}
            ref={point => {
              this.pointFinal = point;
            }}
          />
          <SkipButton baseClass={association!.skipButton.action.enabled ? 'association__skip-button' : 'association__skip-button association__skip-button--disabled'} model={association!.skipButton} />
        </div>
      </div>
    );
  }
}
