import { inject, observer } from 'mobx-react';
import React from 'react';
import { DisplayModel } from 'common/src/models/DisplayModel';
import { Local } from 'common/src/lang/Local';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';

interface CongratulationProps extends DialogProps {
  congratulationDialog?: DisplayModel;
}
@observer
export default class Congratulation extends BaseDialog<CongratulationProps, {}> {
  isVisible(): boolean {
    if (this.props.congratulationDialog) return this.props.congratulationDialog.isVisible();
    return false;
  }

  renderVisible() {
    const { congratulationDialog } = this.props;

    return (
      <div className="congratulation">
        <div className="congratulation__inner">
          <DialogHeader onClose={() => congratulationDialog!.hide()}>{Local.getString('hard_game_mode.title_1')}</DialogHeader>
          <div className="congratulation__image" />

          <div className="congratulation__description">{Local.getString('hard_game_mode.text_1')}</div>
          <button className="congratulation__accept" onClick={() => congratulationDialog!.hide()}>
            {Local.getString('common.accept')}
          </button>
        </div>
      </div>
    );
  }
}
