import { types } from 'mobx-state-tree';
import { OpenedFieldContent } from '../../../../messages/games/association/message/OpenedFieldContent';
import { ButtonMST } from '../../../ButtonModel';
import { GameRiddleContentMST } from '../GameRiddleContent';
import { AssociationFieldModel, AssociationFieldMST, createHintField } from './AssociationFieldModel';
import { AssociationResultModel, AssociationResultMST, createResultModel } from './AssociationResultModel';
import { ActionMST } from '../../../ActionModel';

const NO_RESULT = -2;
const FINAL = -1;
const A_COLUMN = 0;
const B_COLUMN = 1;
const C_COLUMN = 2;
const D_COLUMN = 3;

// const OPENED_BY_BLUE = 1;
// const OPENED_BY_RED = 2;
// const OPENED_BY_ENGINE = 3;

export class AssociationColumnID {
  static get NoResult() {
    return NO_RESULT;
  }

  static get Final() {
    return FINAL;
  }

  static get ColumnA() {
    return A_COLUMN;
  }

  static get ColumnB() {
    return B_COLUMN;
  }

  static get ColumnC() {
    return C_COLUMN;
  }

  static get ColumnD() {
    return D_COLUMN;
  }

  // static get OpenedByBlue() {
  //   return OPENED_BY_BLUE;
  // }

  // static get OpenedByRed() {
  //   return OPENED_BY_RED;
  // }

  // static get OpenedByEngine() {
  //   return OPENED_BY_ENGINE;
  // }
}

export const AssociationMST = GameRiddleContentMST.named('AssociationMST')
  .props({
    resultFinal: types.optional(AssociationResultMST, () => createResultModel(AssociationColumnID.Final)),
    resultA: types.optional(AssociationResultMST, () => createResultModel(AssociationColumnID.ColumnA)),
    resultB: types.optional(AssociationResultMST, () => createResultModel(AssociationColumnID.ColumnB)),
    resultC: types.optional(AssociationResultMST, () => createResultModel(AssociationColumnID.ColumnC)),
    resultD: types.optional(AssociationResultMST, () => createResultModel(AssociationColumnID.ColumnD)),
    hintsA: types.optional(types.array(AssociationFieldMST), [createHintField('A', 3), createHintField('A', 2), createHintField('A', 1), createHintField('A', 0)]),
    hintsB: types.optional(types.array(AssociationFieldMST), [createHintField('B', 3), createHintField('B', 2), createHintField('B', 1), createHintField('B', 0)]),
    hintsC: types.optional(types.array(AssociationFieldMST), [createHintField('C', 3), createHintField('C', 2), createHintField('C', 1), createHintField('C', 0)]),
    hintsD: types.optional(types.array(AssociationFieldMST), [createHintField('D', 3), createHintField('D', 2), createHintField('D', 1), createHintField('D', 0)]),
    selectedColumn: AssociationColumnID.ColumnA,
    skipButton: types.optional(ButtonMST, {}),
    onSolutionTimeoutAction: types.optional(ActionMST, {})
  })
  .views(self => ({
    getHintFieldFromColumn(column: Array<AssociationFieldModel>, position: number): AssociationFieldModel {
      let hintField: AssociationFieldModel = column[0];
      column.forEach((element: AssociationFieldModel) => {
        if (element.id === position) {
          hintField = element;
        }
      });

      return hintField;
    },
    getHintField(column: number, position: number): AssociationFieldModel {
      switch (column) {
        case A_COLUMN:
          return this.getHintFieldFromColumn(self.hintsA, position);
        case B_COLUMN:
          return this.getHintFieldFromColumn(self.hintsB, position);
        case C_COLUMN:
          return this.getHintFieldFromColumn(self.hintsC, position);
        default:
          return this.getHintFieldFromColumn(self.hintsD, position);
      }
    },
    getResultField(column: number): AssociationResultModel {
      switch (column) {
        case A_COLUMN:
          return self.resultA;
        case B_COLUMN:
          return self.resultB;
        case C_COLUMN:
          return self.resultC;
        case D_COLUMN:
          return self.resultD;
        default:
          return self.resultFinal;
      }
    },
    isAllowedToOpen(position: number) {
      switch (position) {
        case A_COLUMN:
          return self.hintsA[0].isOpened || self.hintsA[1].isOpened || self.hintsA[2].isOpened || self.hintsA[3].isOpened;
        case B_COLUMN:
          return self.hintsB[0].isOpened || self.hintsB[1].isOpened || self.hintsB[2].isOpened || self.hintsB[3].isOpened;
        case C_COLUMN:
          return self.hintsC[0].isOpened || self.hintsC[1].isOpened || self.hintsC[2].isOpened || self.hintsC[3].isOpened;
        case D_COLUMN:
          return self.hintsD[0].isOpened || self.hintsD[1].isOpened || self.hintsD[2].isOpened || self.hintsD[3].isOpened;
        default:
          return self.resultA.isResolved() || self.resultB.isResolved() || self.resultC.isResolved() || self.resultD.isResolved();
      }
    },
    getPrecalculatedPoints(position: number): number {
      if (this.getResultField(position).isResolved()) return 0;
      let points = 2;
      if (!this.getHintField(position, 0).isOpened) points += 1;
      if (!this.getHintField(position, 1).isOpened) points += 1;
      if (!this.getHintField(position, 2).isOpened) points += 1;
      if (!this.getHintField(position, 3).isOpened) points += 1;
      return points;
    }
  }))
  .actions(self => ({
    setHintFieldAction(hintAction: Function) {
      self.hintsA.forEach((hint: AssociationFieldModel) => {
        hint.action.setAction(() => {
          hintAction(A_COLUMN, hint.id);
        });
      });
      self.hintsB.forEach((hint: AssociationFieldModel) => {
        hint.action.setAction(() => {
          hintAction(B_COLUMN, hint.id);
        });
      });
      self.hintsC.forEach((hint: AssociationFieldModel) => {
        hint.action.setAction(() => {
          hintAction(C_COLUMN, hint.id);
        });
      });
      self.hintsD.forEach((hint: AssociationFieldModel) => {
        hint.action.setAction(() => {
          hintAction(D_COLUMN, hint.id);
        });
      });
    },
    setSkipAction(action: Function) {
      self.skipButton.action.setAction(action);
    },
    setResultFields(clickAction: Function, sendWordAction: Function) {
      self.resultA.initActions(
        () => {
          clickAction(AssociationColumnID.ColumnA);
        },
        () => {
          sendWordAction(AssociationColumnID.ColumnA, self.resultA.getWordFromLetters());
        }
      );
      self.resultB.initActions(
        () => {
          clickAction(AssociationColumnID.ColumnB);
        },
        () => {
          sendWordAction(AssociationColumnID.ColumnB, self.resultB.getWordFromLetters());
        }
      );
      self.resultC.initActions(
        () => {
          clickAction(AssociationColumnID.ColumnC);
        },
        () => {
          sendWordAction(AssociationColumnID.ColumnC, self.resultC.getWordFromLetters());
        }
      );
      self.resultD.initActions(
        () => {
          clickAction(AssociationColumnID.ColumnD);
        },
        () => {
          sendWordAction(AssociationColumnID.ColumnD, self.resultD.getWordFromLetters());
        }
      );
      self.resultFinal.initActions(
        () => {
          clickAction(AssociationColumnID.Final);
        },
        () => {
          sendWordAction(AssociationColumnID.Final, self.resultFinal.getWordFromLetters());
        }
      );
    },
    setSelectedColumn(selectedColumn: number) {
      self.selectedColumn = selectedColumn;
    },
    setHintFieldsEnabled(enabled: boolean) {
      self.hintsA.forEach((hint: AssociationFieldModel) => {
        hint.setEnabled(enabled);
      });
      self.hintsB.forEach((hint: AssociationFieldModel) => {
        hint.setEnabled(enabled);
      });
      self.hintsC.forEach((hint: AssociationFieldModel) => {
        hint.setEnabled(enabled);
      });
      self.hintsD.forEach((hint: AssociationFieldModel) => {
        hint.setEnabled(enabled);
      });
    },
    setResultFieldsEnabled(enabled: boolean) {
      if (enabled) {
        if (self.isAllowedToOpen(A_COLUMN)) self.resultA.reset();
        if (self.isAllowedToOpen(B_COLUMN)) self.resultB.reset();
        if (self.isAllowedToOpen(C_COLUMN)) self.resultC.reset();
        if (self.isAllowedToOpen(D_COLUMN)) self.resultD.reset();
        if (self.isAllowedToOpen(FINAL)) self.resultFinal.reset();
      } else {
        self.resultA.reset(false);
        self.resultB.reset(false);
        self.resultC.reset(false);
        self.resultD.reset(false);
        self.resultFinal.reset(false);
      }
    },
    markFieldAsEngineResolved(position: number, answer: string | undefined) {
      const answerText = answer ? answer.toLocaleUpperCase() : '';
      self.getResultField(position).markAsEngineAnswered(answerText);
    },
    onFieldOpened(hintOpened: OpenedFieldContent) {
      self.getHintField(hintOpened.column, hintOpened.field).openHint(hintOpened.fieldContent ? hintOpened.fieldContent : '');
    },
    calculatePoints(position: number) {
      let points = 2;
      if (!self.getHintField(position, 0).isOpened) points += 1;
      if (!self.getHintField(position, 1).isOpened) points += 1;
      if (!self.getHintField(position, 2).isOpened) points += 1;
      if (!self.getHintField(position, 3).isOpened) points += 1;
      self.getResultField(position).setPoints(points);
    },
    openColumnByBlue(position: number, answer: string, res: string, f1: string, f2: string, f3: string, f4: string) {
      if (!self.getResultField(position).isResolved()) {
        this.calculatePoints(position);

        self.getResultField(position).setBlueAnswered(res);
        self.getHintField(position, 0).markAsBlueAnswered(f1);
        self.getHintField(position, 1).markAsBlueAnswered(f2);
        self.getHintField(position, 2).markAsBlueAnswered(f3);
        self.getHintField(position, 3).markAsBlueAnswered(f4);
      }
    },
    openColumnByRed(position: number, answer: string, res: string, f1: string, f2: string, f3: string, f4: string) {
      if (!self.getResultField(position).isResolved()) {
        this.calculatePoints(position);

        self.getResultField(position).setRedAnswered(res);
        self.getHintField(position, 0).markAsRedAnswered(f1);
        self.getHintField(position, 1).markAsRedAnswered(f2);
        self.getHintField(position, 2).markAsRedAnswered(f3);
        self.getHintField(position, 3).markAsRedAnswered(f4);
      }
    },
    openColumnByEngine(position: number, answer: string, res: string, f1: string, f2: string, f3: string, f4: string) {
      if (!self.getResultField(position).isResolved()) {
        self.getResultField(position).setEngineAnswered(res);
        self.getHintField(position, 0).markAsEngineAnswered(f1);
        self.getHintField(position, 1).markAsEngineAnswered(f2);
        self.getHintField(position, 2).markAsEngineAnswered(f3);
        self.getHintField(position, 3).markAsEngineAnswered(f4);
      }
    },
    unMarkResultFields() {
      self.resultA.reset(false);
      self.resultB.reset(false);
      self.resultC.reset(false);
      self.resultD.reset(false);
      self.resultFinal.reset(false);
    },
    resetModel() {
      let index = 4;
      self.hintsA.forEach((hint: AssociationFieldModel) => {
        hint.resetModel(`A${index}`);
        index--;
      });
      index = 4;
      self.hintsB.forEach((hint: AssociationFieldModel) => {
        hint.resetModel(`B${index}`);
        index--;
      });
      index = 4;
      self.hintsC.forEach((hint: AssociationFieldModel) => {
        hint.resetModel(`C${index}`);
        index--;
      });
      index = 4;
      self.hintsD.forEach((hint: AssociationFieldModel) => {
        hint.resetModel(`D${index}`);
        index--;
      });
      self.resultA.resetResultModel();
      self.resultB.resetResultModel();
      self.resultC.resetResultModel();
      self.resultD.resetResultModel();
      self.resultFinal.resetResultModel();
    }
  }));

type AssociationModelType = typeof AssociationMST.Type;
export interface AssociationModel extends AssociationModelType {}

const defaultModel = {
  name: 'Association',
  visible: false
};

export const createAssociationModel = () => AssociationMST.create(defaultModel);
