import { TrackingAction } from './TrackingAction';

export class InstallFlow extends TrackingAction {
  /** tracking events in FB Analitics
		 //************************************************************************* */

  public static EV_LOAD_FB_DATA: string = 'load_fb_data';

  public static EV_LOAD_FB_DATA_FAIL: string = 'load_fb_data_fail';

  public static EV_CONNECT_TO_SERVER: string = 'connect_to_server';

  public static EV_CONNECT_TO_SERVER_FAIL: string = 'connect_to_server_fail';

  public static EV_SIGN_IN_TO_SERVER: string = 'sign_in_to_server';

  public static EV_SIGN_IN_TO_SERVER_FAIL: string = 'sign_in_to_server_fail';

  public static EV_SIGN_IN_COMPLETED: string = 'sign_in_completed';

  public static EV_DAILY_REWARD_DISPLAYED: string = 'daily_reward_displayed';

  public static EV_DAILY_REWARD_ACCEPTED: string = 'daily_reward_accepted';

  /** *********************************************************************** */

  constructor(name: string) {
    super('install_flow', name);
  }

  public /* override */ logToFBAnalytics(): boolean {
    return true;
  }
}
