import { ServerEvent } from './ServerEvent';

const BINDING_NAME = 'rs.slagalica.communication.message.HelloMessage';

export class HelloMessage extends ServerEvent {
  msg: string = 'Zdravo'; // fieldType: String

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
