import { UserModel } from 'common/src/models/user/UserModel';
import { CollaborationModel } from './model/CollaborationModel';
import { CollaborationStatusModel } from './model/CollaborationStatusModel';

export class CollaborationController {
  private collaborationModel: CollaborationModel;
  private user: UserModel;

  private collaborationStatusModel: CollaborationStatusModel;

  // private callObject = DailyIframe.createCallObject();

  constructor(collaborationModel: CollaborationModel, collaborationStatusModel: CollaborationStatusModel, user: UserModel) {
    this.collaborationModel = collaborationModel;
    this.collaborationStatusModel = collaborationStatusModel;
    this.user = user;
    this.collaborationModel.onRoomReceived.setAction(() => {
      this.startJoiningCall();
    });
    this.collaborationModel.onLeaveRoom.setAction(() => {
      this.leaveCall();
    });
    this.collaborationStatusModel.retryButton.action.setAction(() => {
      this.retry();
    });

    this.collaborationModel.onDeclareCaptain.setAction(() => {
      const captainFlag: string = this.collaborationModel.captain ? '(C)' : '';
      this.collaborationModel.callObject.setUserName(this.user.info.b2bName! + captainFlag, {});
    });

    this.retry = this.retry.bind(this);
    // setInterval(this.retry, 10000);
  }

  private startJoiningCall() {
    const url: string = this.collaborationModel.url;

    this.collaborationModel.callObject.join({ url }).then(
      () => {
        this.collaborationModel.callObject.getInputDevices().then(response => {
          console.log(response);
          try {
            if (response.camera.hasOwnProperty('deviceId')) this.collaborationModel.setCamera(response.camera);
            if (response.mic.hasOwnProperty('deviceId')) this.collaborationModel.setMic(response.mic);
            if (response.speaker.hasOwnProperty('deviceId')) this.collaborationModel.setSpeaker(response.speaker);
          } catch (error) {
            console.log('cannot retrieve devices');
            console.log(error);
          }
        });

        this.collaborationModel.callObject.enumerateDevices().then(response => {
          this.collaborationModel.setAllDevices(response.devices);
          console.log(response);
          console.log(this.collaborationModel.allDevices);
        });

        this.collaborationModel.callObject.setBandwidth({
          kbs: 100,
          trackConstraints: { width: 640, height: 480, frameRate: 15 }
        });

        this.collaborationModel.callObject.setUserName(this.user.info.b2bName!, {});
        console.log('this.collaborationModel.callObject.setUserName');
      },
      reason => {
        console.error(reason); // Error!
      }
    );
    console.log(this.user.info.b2bName!);
  }

  private leaveCall() {
    if (!this.collaborationModel.callObject) return;
    this.collaborationModel.callObject.leave();
  }

  private retry() {
    if (this.collaborationModel && this.collaborationModel.isInRoom() && this.collaborationStatusModel && this.collaborationStatusModel.isCamMicError()) {
      this.leaveCall();
      this.startJoiningCall();
      // this.collaborationModel.callObject.cycleCamera();
    }
  }
}
