import { B2bGameModel } from 'common/src/models/b2b/B2bGameModel';
import { B2bTeamModel } from 'common/src/models/b2b/B2bTeamModel';
import React from 'react';
import Img from 'react-image';
import dayjs from 'dayjs';
import Images from '../../resources/ImgResources';
import B2bTeamTooltip from './B2bTeamTooltip';

interface B2bGameProps {
  b2bGame?: B2bGameModel;
  scrollTransitX: number;
  transitionActive: boolean;
  blueTeam?: B2bTeamModel;
  redTeam?: B2bTeamModel;
  isMyGame: boolean;
  isAll: boolean;
  toolTipPosition: number;
}

export default function B2bGamePanel({ b2bGame, blueTeam, redTeam, scrollTransitX, transitionActive, isMyGame, isAll, toolTipPosition }: B2bGameProps) {
  const getTime = (date: Date) => date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit', hour12: false });

  if (!isAll && !isMyGame) {
    return null;
  }

  return (
    <div className="b2b-game" style={{ right: `${scrollTransitX}%`, transition: transitionActive ? 'all 0.5s' : '' }}>
      {b2bGame!.bluePoints === 0 && b2bGame!.redPoints === 0 && (
        <div className="b2b-game__time">
          <div className="b2b-game__time-clock" />
          <div className="b2b-game__time-box">{getTime(b2bGame!.startTime!)}</div>
        </div>
      )}
      <div className="b2b-team">
        <div className="b2b-team__header">{dayjs(b2bGame!.startTime).format('DD.MM.YYYY.')}</div>
        <div className="b2b-team__name">{blueTeam!.name}</div>
        <div className="b2b-team__image--box">
          <Img src={blueTeam!.logo} loader={<img src={Images.avatar} className="b2b-team__image" />} unloader={<img src={Images.avatar} className="b2b-team__image" />} className="b2b-team__image" />
          {/* {this.getToolTip(blueTeam!, toolTipPosition)} */}
          <B2bTeamTooltip team={blueTeam} toolTipPositionX={toolTipPosition} />
        </div>
        {(b2bGame!.bluePoints > 0 || b2bGame!.redPoints > 0) && (
          <div className="b2b-team__result">
            <div className="b2b-team__result-box">{b2bGame!.bluePoints!}</div>
            <div className="b2b-team__result-box b2b-team__result-box--dot">:</div>
          </div>
        )}
      </div>

      <div className={`b2b-team b2b-team--${'red'}`}>
        <div className="b2b-team__header" />
        <div className={`b2b-team__name b2b-team__name--${'red'}`}>{redTeam!.name}</div>
        <div className="b2b-team__image--box">
          <Img src={redTeam!.logo} loader={<img src={Images.avatar} className="b2b-team__image" />} unloader={<img src={Images.avatar} className="b2b-team__image" />} className="b2b-team__image" />
          {/* {this.getToolTip(redTeam!, 0)} */}
          <B2bTeamTooltip team={redTeam} toolTipPositionX={toolTipPosition} />
        </div>
        {(b2bGame!.bluePoints > 0 || b2bGame!.redPoints > 0) && (
          <div className="b2b-team__result">
            <div className="b2b-team__result-box">{b2bGame!.redPoints!}</div>
          </div>
        )}
      </div>
    </div>
  );
}
