import { types } from 'mobx-state-tree';
import { Reward } from '../../messages/player/reward/message/Reward';
import { BaseMST } from '../BaseModel';

export const RewardItemMST = BaseMST.named('AchievementItemRewardModel')
  .props({
    id: types.number,
    count: types.number
  })
  .views(self => ({
    getPosition() {
      switch (self.id) {
        case Reward.TYPE_TOKENS:
          return 0;
        case Reward.TYPE_RATING_POINTS:
          return 1;
        default:
          return 2;
      }
    }
  }))
  .actions(self => ({
    init(id: number, count: number) {
      self.id = id;
      self.count = count;
    }
  }));

type RewardItemModelType = typeof RewardItemMST.Type;
export interface RewardItemModel extends RewardItemModelType {}

export const createRewardItemModel = (id: number, count: number) => RewardItemMST.create({ id, count });
