import { types } from 'mobx-state-tree';
import { BaseMST } from '../BaseModel';
import { FriendModel, FriendMST } from './FriendModel';

export const FriendsMST = BaseMST.named('FriendsMST')
  .props({
    friends: types.array(FriendMST)
  })
  .actions(self => ({
    addFriend(friend: FriendModel) {
      self.friends.push(friend);
    },
    updateFriend(id: number) {},
    switchToFirst(friend: FriendModel, position: number) {
      self.friends.splice(position, 1);
      self.friends.unshift(friend);
    },
    sortFriends() {
      self.friends.replace(
        self.friends.slice().sort((a: FriendModel, b: FriendModel) => {
          if (-a.getRating() === -b.getRating()) return 0;
          if (-a.getRating() > -b.getRating()) return 1;
          return -1;
        })
      );
    }
  }))
  .views(self => ({}));

type FriendsModelType = typeof FriendsMST.Type;
export interface FriendsModel extends FriendsModelType {}
export const createFriendsModel = () =>
  FriendsMST.create({
    friends: []
  });
