import { types } from 'mobx-state-tree';
import BaseAnimation from '../../animations/BaseAnimation';
import { APP } from '../../app/AppInstance';
import { Reward } from '../../messages/player/reward/message/Reward';
import { AnimationBuilderMST } from '../AnimationBuilder';
import { BaseMST } from '../BaseModel';

export const ToolbarMST = BaseMST.named('ToolbarMST')
  .props({
    tokens: 0, // UserModel ili TokensModel
    tokensSilver: 0, // UserModel ili TokensModel
    rank: 0,
    rankingPoints: 0,
    rankingPointsTarget: 0,
    visible: false,
    levelUpAnimation: types.optional(AnimationBuilderMST, {}),
    ratingAnimation: types.optional(AnimationBuilderMST, {}),
    tokenGlowAnimation: types.optional(AnimationBuilderMST, {}),
    toolbarPushBackAnimation: types.optional(AnimationBuilderMST, {}),
    toolbarPopUpAnimation: types.optional(AnimationBuilderMST, {}),
    tokenCountUpAnimation: types.optional(AnimationBuilderMST, {})
  })
  .views(self => ({
    getProgress() {
      return (self.rankingPoints / self.rankingPointsTarget) * 100;
    }
  }))
  .actions(self => ({
    // toggleSoundsOptionVisibility() {
    //   self.sounds = !self.sounds;
    //   APP.Settings.enableSounds(self.sounds);
    // },
    // toggleMusicOptionVisibility() {
    //   self.music = !self.music;
    //   APP.Settings.enableMusic(self.music);
    //   if (self.music) {
    //     APP.SoundPlayer.playBackgroundMusic();
    //   } else {
    //     APP.SoundPlayer.stopBackgroundMusic();
    //   }
    // },
    // setMusic(music: boolean) {
    //   self.music = music;
    // },
    // setSounds(sounds: boolean) {
    //   self.sounds = sounds;
    // },
    setVisible(visible: boolean) {
      self.visible = visible;
    },
    setTokensSilver(tokensSilver: number) {
      self.tokensSilver = tokensSilver;
    },
    setTokens(tokens: number) {
      self.tokens = tokens;
    },
    setPoints(points: number) {
      self.rankingPoints = points;
    },
    setNextRank(rank: number, targetPoints: number) {
      self.rank = rank;
      self.rankingPointsTarget = targetPoints;
    },
    setRankingInfo(rank: number, points: number, targetPoints: number) {
      self.rank = rank;
      self.rankingPoints = points;
      self.rankingPointsTarget = targetPoints;
    },
    setRankingPoints(points: number) {
      self.rankingPoints = Math.floor(points);
    },
    getItemCountUpAnimation(id: number, count: number): BaseAnimation | undefined {
      switch (id) {
        case Reward.TYPE_RATING_POINTS:
          return self.ratingAnimation.build(self.rankingPoints, count);
        case Reward.TYPE_TOKENS:
          return self.tokenCountUpAnimation.build(self.tokens, count);
        default:
          return APP.AnimationAPI.createPause(0.1);
      }
    }
  }));

type ToolbarModelType = typeof ToolbarMST.Type;
export interface ToolbarModel extends ToolbarModelType {}
export const createToolbarModel = () =>
  ToolbarMST.create({
    tokens: 0,
    tokensSilver: 0,
    rank: 0,
    rankingPoints: 0,
    rankingPointsTarget: 0,
    visible: true
  });
