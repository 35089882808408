import { types } from 'mobx-state-tree';
import { APP } from '../../../../app/AppInstance';
import { SendTheQuestion } from '../../../../messages/games/quiz/message/SendTheQuestion';
import { ButtonMST } from '../../../ButtonModel';
import { GameRiddleContentMST } from '../GameRiddleContent';
import { AnswerOptionMST, createAnswerOption } from './AnswerOptionModel';
import { createQuestion, QuestionMST } from './QuestionModel';

export const QuizMST = GameRiddleContentMST.named('QuizMST')
  .props({
    answers: types.array(AnswerOptionMST),
    questions: types.array(QuestionMST),
    currentQuestion: -1,
    rightAnswerIndex: -1,
    answerCorrect: false,
    opponetAnswerIndex: -1,
    winner: false,
    skipButton: types.optional(ButtonMST, {}),
    removedAnswers: types.array(types.number)
  })
  .views(self => ({
    getQuestionText() {
      return self.questions !== undefined && self.questions[self.currentQuestion] !== undefined && self.currentQuestion >= 0 ? self.questions[self.currentQuestion].text : '';
    }
  }))
  .actions(self => ({
    setSkipButton(skip: Function) {
      self.skipButton.action.setAction(skip);
    },
    setQuestion(question: SendTheQuestion) {
      self.currentQuestion += 1;
      self.questions[self.currentQuestion].setText(question.question ? question.question : '');
      for (let i = 0; i < 4; i++) {
        self.answers[i].setAnswer(question.answers[i]);
        self.answers[i].setEnabled(true);
      }
    },
    disableUnusedButtons(buttonNum: number) {
      for (let i = 0; i < 4; i++) {
        if (i !== buttonNum) self.answers[i].setEnabled(false);
      }
      self.skipButton.setEnabled(false);
    },
    clearRemovedAnswers() {
      self.removedAnswers[0] = -1;
      self.removedAnswers[1] = -1;
    },
    restoreRemovedAnswers() {
      const restoreHidden = APP.AnimationAPI.createParallel();
      if (self.removedAnswers[0] >= 0) {
        restoreHidden.add(self.answers[self.removedAnswers[0]].appearAnimation.build());
      }
      if (self.removedAnswers[1] >= 0) {
        restoreHidden.add(self.answers[self.removedAnswers[1]].appearAnimation.build());
      }
      restoreHidden.addEndListener(() => {
        this.clearRemovedAnswers();
      });

      restoreHidden.play();
    },
    selectRightAnswer(result: any) {},
    removeWrongAnswers(removeIndex1: number, removeIndex2: number) {
      self.removedAnswers[0] = removeIndex1;
      self.removedAnswers[1] = removeIndex2;
      const hideWrong = APP.AnimationAPI.createParallel();
      hideWrong.add(self.answers[removeIndex1].disappearAnimation.build());
      hideWrong.add(self.answers[removeIndex2].disappearAnimation.build());
      hideWrong.play();
    },
    setAnsweredByBoth(blueAnswerIndex: number, blueTime: number, redTime: number, bluePlayerName: string, redPlayerName: string) {
      self.answers[blueAnswerIndex].setAnsweredByBoth(blueTime, redTime, bluePlayerName, redPlayerName);
    },
    setAnsweredByBlue(blueAnswerIndex: number, blueTime: number, bluePlayerName: string) {
      self.answers[blueAnswerIndex].setAnsweredByBlue(blueTime, bluePlayerName);
    },
    setAnsweredByRed(redAnswerIndex: number, redTime: number, redPlayerName: string) {
      self.answers[redAnswerIndex].setAnsweredByRed(redTime, redPlayerName);
    },
    setAnsweredByEngine(answerIndex: number) {
      self.answers[answerIndex].setAnsweredByEngine();
    },
    resetModel() {
      for (let i = 0; i < 4; i++) self.answers[i].setRegular();
      for (let i = 0; i < 10; i++) self.questions[i] = createQuestion(i);
      self.currentQuestion = -1;
      self.rightAnswerIndex = -1;
      self.answerCorrect = false;
      self.winner = false;
    }
  }));

type QuizModelType = typeof QuizMST.Type;
export interface QuizModel extends QuizModelType {}

export const createQuizModel = () =>
  QuizMST.create({
    name: 'Quiz',
    visible: false,
    currentQuestion: -1,
    answers: [createAnswerOption(0), createAnswerOption(1), createAnswerOption(2), createAnswerOption(3)],
    questions: [createQuestion(0), createQuestion(1), createQuestion(2), createQuestion(3), createQuestion(4), createQuestion(5), createQuestion(6), createQuestion(7), createQuestion(8), createQuestion(9)],
    skipButton: {},
    removedAnswers: [-1, -1]
  });
