import { ImageUtils } from 'common/src/util/ImageUtils';
import React, { Component } from 'react';
import Images from '../../resources/ImgResources';

const Img = require('react-image');

interface ProfilePictureProps {
  fbId?: string;
  b2bUrl?: string;
  css: string;
  size: number;
}

export default class ProfilePicture extends Component<ProfilePictureProps, {}> {
  private getImgSrc(): string {
    const { fbId, b2bUrl, size } = this.props;
    if (b2bUrl && b2bUrl != '') return b2bUrl;
    if (fbId && fbId != '') return ImageUtils.getFacebookImg(fbId, size, Images.avatar);
    return Images.avatar;
  }

  render() {
    const { css } = this.props;
    return <Img src={this.getImgSrc()} loader={<img src={Images.avatar} className={css} />} unloader={<img src={Images.avatar} className={css} />} className={css} />;
  }
}
