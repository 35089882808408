import { Local } from 'common/src/lang/Local';
import { B2bNoCaptainModel } from 'common/src/models/dialogs/b2bgame/B2bNoCaptainModel';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { GameContent, GameContentProps } from '../../GameContent';
import { GameStatus } from '../../GameStatus';

interface B2bNoCaptainProps extends GameContentProps {
  noCaptain?: B2bNoCaptainModel;
}
@inject('noCaptain')
@observer
export default class B2bNoCaptain extends GameContent<B2bNoCaptainProps, {}> {
  createAnimations() {
    const { noCaptain } = this.props;
    noCaptain!.enteringAnimation.set(this.createEnteringAnimation);
    noCaptain!.exitingAnimation.set(this.createExitingAnimation);
    noCaptain!.preparingAnimation.set(this.createPreparingAnimation);
  }

  destroyAnimations() {
    const { noCaptain } = this.props;
    noCaptain!.enteringAnimation.dispose();
    noCaptain!.exitingAnimation.dispose();
    noCaptain!.preparingAnimation.dispose();
  }

  componentDidMount() {
    this.createAnimations();
  }

  isVisible(): boolean {
    const { noCaptain } = this.props;
    return noCaptain ? noCaptain.isVisible() : false;
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  renderVisible(): React.ReactNode {
    const { noCaptain } = this.props;
    const t = (message: string) => Local.getString(message);

    if (!noCaptain) {
      return null;
    }

    return (
      <div
        ref={contentRef => {
          this.contentRef = contentRef;
        }}
      >
        <GameStatus />
        <div className="b2b-captain__background">
          <div className="b2b-captain__description">{t('b2b-no-captain.captain_game') + noCaptain!.gameName}</div>
          <div className="b2b-captain__description">{t('b2b-no-captain.description-1')}</div>
          <div className="b2b-captain__description">{t('b2b-no-captain.description-2')}</div>
          <div className="b2b-captain__description">{t('b2b-no-captain.description-3')}</div>
        </div>
      </div>
    );
  }
}
