import { PlayerAction } from '../../../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.player.achievements.message.ApplyAchievementReward';

export class ApplyAchievementReward extends PlayerAction {
  achievementId: number = 0;

  // fieldType: int
  level: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
