import { SignIn } from '../messages/player/message/SignIn';

export abstract class LoginAccount {
  onLoginSuccess: Function | undefined;

  onLoginFailed: Function | undefined;

  onLogout: Function | undefined;

  abstract login(): void;

  abstract logout(): void;
}
