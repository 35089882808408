import { types } from 'mobx-state-tree';
import { ActionMST } from '../ActionModel';
import { DisplayMST } from '../DisplayModel';
import { DisplayModelKeys } from '../DisplayModelKeys';

export const LoaderMST = DisplayMST.named('LoaderMST')
  .props({
    loadingProgress: 0,
    onAssetsLoaded: types.optional(ActionMST, {}),
    loginAction: types.optional(ActionMST, {}),
    loggedInType: -1
  })
  .actions(self => ({
    setLoadingProgress(loadingProgress: number) {
      self.loadingProgress = loadingProgress >= 100 ? 100 : loadingProgress;
    },

    finish() {
      self.loadingProgress = 100;
    },
    setLoginType(type: number) {
      self.loggedInType = type;
    }
  }))
  .views(self => ({
    isAssetLoaded(): boolean {
      return self.loadingProgress >= 100;
    },
    isLoggedin(): boolean {
      return self.loggedInType >= 0;
    }
  }));

type LoaderModelType = typeof LoaderMST.Type;
export interface LoaderModel extends LoaderModelType {}

export const createLoaderModel = () =>
  LoaderMST.create({
    key: DisplayModelKeys.Loader,
    visible: false,
    loadingProgress: 0
  });
