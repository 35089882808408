import { GameButtonMST, GameButtonType } from '../GameButtonModel';
import { CombinationsSign } from './CombinationSign';

export const SignButtonMST = GameButtonMST.named('SignButtonMST')
  .props({
    text: '',
    imgType: CombinationsSign.None
  })
  .views(self => ({
    getSignType() {
      return self.imgType;
    }
  }))
  .actions(self => ({
    setType(type: number) {
      self.imgType = type;
    }
  }));

type SignButtonType = typeof SignButtonMST.Type;
export interface SignButtonModel extends SignButtonType {}

export const createSignButtonModel = (aType: number) =>
  SignButtonMST.create({
    type: GameButtonType.Regular,
    imgType: aType
  });
