import { Instance } from 'mobx-state-tree';
import { DisplayMST } from '../../DisplayModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const B2bMyTeamMST = DisplayMST.named('B2bMyTeamMST')
  .props({})
  .views(self => ({}))
  .actions(self => ({}));

export interface B2bMyTeamModel extends Instance<typeof B2bMyTeamMST> {}

export const createB2bMyTeamModel = () =>
  B2bMyTeamMST.create({
    key: DisplayModelKeys.B2bMyTeamDialog
  });
