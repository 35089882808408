/* eslint-disable no-named-as-default */
import { BaseMST } from 'common/src/models/BaseModel';
import { Instance, types } from 'mobx-state-tree';

export const DeviceMST = BaseMST.named('DeviceMST')
  .props({
    deviceId: types.string,
    groupId: types.string,
    kind: types.string,
    label: types.string
  })
  .views(self => ({}))
  .actions(self => ({
    setDevice(deviceId: string, groupId: string, kind: string, label: string) {
      self.deviceId = deviceId;
      self.groupId = groupId;
      self.kind = kind;
      self.label = label;
    }
  }));

type DeviceType = typeof DeviceMST.Type;
export interface DeviceModel extends Instance<typeof DeviceMST> {}

export const createDeviceModel = (d: string, g: string, k: string, l: string) =>
  DeviceMST.create({
    deviceId: d,
    groupId: g,
    kind: k,
    label: l
  });
