import { ServerEvent } from './ServerEvent';

const BINDING_NAME = 'rs.slagalica.communication.message.StartControllerEvent';

export class StartControllerEvent extends ServerEvent {
  controllerName?: string = undefined;

  // fieldType: String
  firstPlaying?: boolean = undefined; // fieldType: Boolean

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
