import { observer } from 'mobx-react';
import React from 'react';
import { Local } from 'common/src/lang/Local';
import { DisplayModel } from 'common/src/models/DisplayModel';
import { AppealOpponentModel } from 'common/src/models/dialogs/appealopponent/AppealOpponentModel';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';

interface AppealOpponentProps extends DialogProps {
  appealOpponentDialog?: AppealOpponentModel;
}
@observer
export default class AppealOpponent extends BaseDialog<AppealOpponentProps, {}> {
  isVisible(): boolean {
    const { appealOpponentDialog } = this.props;
    return appealOpponentDialog ? appealOpponentDialog.isVisible() : false;
  }

  renderVisible() {
    const { appealOpponentDialog } = this.props;
    return (
      <div className="appeal-opponent">
        <div className="appeal-opponent__inner">
          <DialogHeader onClose={() => appealOpponentDialog!.hide()}>{Local.getString('appeal_opponent.header')}</DialogHeader>
          <div className="appeal-opponent__image" />

          <div className="appeal-opponent__description">{Local.getString('appeal_opponent.description')}</div>
          <div className="appeal-opponent__button-box">
            <button className="appeal-opponent__button" onClick={() => appealOpponentDialog!.confirmAction.performAction()}>
              {Local.getString('appeal_opponent.report')}
            </button>
            <button className="appeal-opponent__button" onClick={() => appealOpponentDialog!.hide()}>
              {Local.getString('appeal_opponent.close')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
