export const EMPTY = -1;
export const MESSAGE = 0;
export const EMOTIONS = 1;
export const STICKER = 2;
export const SOUND = 3;

export class ChatTypes {
  static get EMPTY() {
    return EMPTY;
  }

  static get MESSAGE() {
    return MESSAGE;
  }

  static get EMOTIONS() {
    return EMOTIONS;
  }

  static get STICKER() {
    return STICKER;
  }

  static get SOUND() {
    return SOUND;
  }
}
