import { types } from 'mobx-state-tree';
import { AnimationBuilderMST } from '../../../AnimationBuilder';
import { VisibleMST } from '../../../VisableModel';

export const OpponentWordMST = VisibleMST.named('OpponentWordMST')
  .props({
    letters: types.array(types.number),
    solving: true,
    points: 0,
    bonusPoints: 0,
    bonusAnimation: types.optional(AnimationBuilderMST, {}),
    extraBonusPoints: 0,
    extraBonusAnimation: types.optional(AnimationBuilderMST, {}),
    pointsAnimation: types.optional(AnimationBuilderMST, {}),
    opponentProgressShown: false,
    hasAppealed: false
  })
  .views(self => ({
    isCorrect(): boolean {
      return self.letters.length > 0;
    }
  }))
  .actions(self => ({
    setPoints(points: number) {
      self.points = points;
    },
    setBonus(bonus: number) {
      self.bonusPoints = bonus;
    },
    setExtraBonusPoints(extraBonus: number) {
      self.extraBonusPoints = extraBonus;
    },
    setOpponentProgressShown(shown: boolean) {
      self.opponentProgressShown = shown;
    },
    setAppealed() {
      self.hasAppealed = true;
    },
    reset() {
      self.visible = false;
      self.solving = true;
      self.letters.clear();
      self.points = 0;
      self.bonusPoints = 0;
      self.extraBonusPoints = 0;
      self.opponentProgressShown = false;
      self.hasAppealed = false;
    }
  }));

type OpponentWordType = typeof OpponentWordMST.Type;
export interface OpponentWordModel extends OpponentWordType {}
export const createOpponentWordModel = () =>
  OpponentWordMST.create({
    visible: false,
    letters: [],
    points: 0,
    bonusPoints: 0,
    opponentProgressShown: false,
    solving: true
  });
