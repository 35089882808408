import { Local } from 'common/src/lang/Local';
import { UserModel } from 'common/src/models/user/UserModel';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Fragment } from 'react';
import { ShopTokensModel } from 'common/src/models/dialogs/shoptokens/ShopTokensModel';
import { FAQModel } from 'common/src/models/dialogs/faq/FAQModel';
import { DisplayModel } from 'common/src/models/DisplayModel';
import { ShopItemModel } from 'common/src/models/dialogs/shop/ShopItemModel';
import { VoucherModel } from 'common/src/models/dialogs/voucher/VoucherModel';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';
import Card from '../shop/Card';
import TokensInfo from '../info/TokensInfo';

interface ShopTokensProps extends DialogProps {
  user?: UserModel;
  shop?: ShopTokensModel;
  faq?: FAQModel;
  tokensInfo?: DisplayModel;
  voucher?: VoucherModel;
}
@inject('user', 'faq', 'tokensInfo', 'voucher')
@observer
export default class ShopNew extends BaseDialog<ShopTokensProps, {}> {
  TAB_1 = 0;

  TAB_2 = 1;

  TAB_3 = 2;

  @observable selectedTab = this.TAB_1;

  @observable code = '';

  constructor(props: any) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  isVisible(): boolean {
    const { shop } = this.props;
    return shop ? shop.isVisible() : false;
  }

  handleChange(event: any) {
    this.code = event.target.value;
    this.code = this.code.toLocaleUpperCase();
  }

  selectTab(tab: number) {
    this.selectedTab = tab;
  }

  isProperCountryForQuickSMS(): boolean {
    if (!this.props.user!.info.country) return false;
    return this.props.user!.info.country!.trim() === 'RS' || this.props.user!.info.country!.trim() === 'BA';
  }

  hasOfferInfo(): boolean {
    return this.props.shop!.offerSMSPhoneNum !== '' && this.props.shop!.offerSMSShortKey !== '';
  }

  otherBuy() {
    const left = window.innerWidth / 2 - 250;
    const top = window.innerHeight / 2 - 325;
    window.open(`https://pay.fortumo.com/mobile_payments/ccff52145a50b28a92859f6c8f7ac1f5/${this.props.user!.info.facebookId}`, '', `width=500, height=650, left=${left}, top=${top}`);
  }

  hasProvider(): boolean {
    return this.props.shop!.provider !== 0;
  }

  areTabsShown(): boolean {
    return this.hasProvider() || this.props.shop!.voucherShown;
  }

  getTabClass(): string {
    let numOfTabs = 1;
    if (this.hasProvider()) numOfTabs++;
    if (this.props.shop!.voucherShown) numOfTabs++;

    if (numOfTabs === 2) {
      return 'shop-tokens__tab-background  shop-tokens__tab-background--double';
    }

    return 'shop-tokens__tab-background';
  }

  renderVisible() {
    const { shop, user, voucher } = this.props;

    return (
      <div className="shop-tokens">
        <div className="shop-tokens__inner">
          <DialogHeader
            onClose={() => {
              shop!.hide();
              shop!.tokenItems.forEach((shopItem: ShopItemModel) => {
                shopItem.setBuyInProgress(false);
              });
            }}
          >
            {Local.getString('shop-tokens.title')}
          </DialogHeader>
          {this.areTabsShown() && (
            <div>
              <div className={`${this.getTabClass()} ${this.selectedTab === this.TAB_1 ? `${this.getTabClass()}--selected` : ''}`} onClick={() => this.selectTab(this.TAB_1)}>
                {Local.getString('shop-tokens.facebook')}
              </div>
              {this.hasProvider() && (
                <div
                  className={`${this.getTabClass()} ${this.selectedTab === this.TAB_2 ? `${this.getTabClass()}--selected` : ''}`}
                  onClick={() => {
                    if (this.isProperCountryForQuickSMS() && this.hasOfferInfo()) {
                      this.selectTab(this.TAB_2);
                    } else {
                      this.otherBuy();
                    }
                  }}
                >
                  {Local.getString('shop-tokens.sms')}
                </div>
              )}

              {this.props.shop!.voucherShown && (
                <div className={`${this.getTabClass()} ${this.selectedTab === this.TAB_3 ? `${this.getTabClass()}--selected` : ''}`} onClick={() => this.selectTab(this.TAB_3)}>
                  {Local.getString('shop-tokens.voucher')}
                </div>
              )}
            </div>
          )}
          <div className={`shop-tokens__tab-content-background${this.areTabsShown() ? '' : ' shop-tokens__tab-content-background--notab'}`}>
            {this.selectedTab === this.TAB_1 && (
              <Fragment>
                <div className="shop-tokens__tab-description">{Local.getString('shop-tokens.facebook-title')}</div>
                <div className="shop-tokens__tab-content">
                  <div className="shop-tokens__tab-cards">
                    {shop!.tokenItems.map(c => (
                      <Card item={c} selectedGroup={0} selectedTab={0} forceGroup={0} />
                    ))}
                  </div>
                </div>
              </Fragment>
            )}
            {this.selectedTab === this.TAB_2 && this.hasProvider() && (
              <div>
                <div className="shop-tokens__sms-token">
                  <div className="shop-tokens__sms-token__text">{Local.getString('shop-tokens.sms-desc1')}</div>
                </div>
                <div className="shop-tokens__sms-token__text shop-tokens__sms-token__text--send">
                  <span>{Local.getString('shop-tokens.sms-desc2-1')}</span>
                  <span style={{ color: '#00254e' }}>{shop!.offerSMSShortKey}</span>
                  <span>{Local.getString('shop-tokens.sms-desc2-2')}</span>
                  <span style={{ color: '#00254e' }}>{shop!.offerSMSPhoneNum}</span>
                  <br />
                  <span>{Local.getString('shop-tokens.sms-desc2-3')}</span>
                </div>
                <div className="shop-tokens__sms-token__text shop-tokens__sms-token__text--price">{Local.getString('shop-tokens.sms-desc3')}</div>
                <div
                  className="shop-tokens__sms-token__text shop-tokens__sms-token__text--other-buy"
                  onClick={() => {
                    this.otherBuy();
                  }}
                >
                  {Local.getString('shop-tokens.sms-desc4')}
                </div>
              </div>
            )}
            {this.selectedTab === this.TAB_3 && this.props.shop!.voucherShown && (
              <div>
                <div className="shop-tokens__vaucer-text">{Local.getString('shop-tokens.voucher-title')}</div>
                <input className="shop-tokens__vaucer-input" type="text" onChange={this.handleChange} />
                <button
                  className="shop-tokens__close"
                  onClick={() => {
                    voucher!.voucherAction.getAction()(this.code);
                  }}
                >
                  {Local.getString('common.confirm')}
                </button>
                <div className="shop-tokens__voucher-result">{voucher!.voucherMessage}</div>
              </div>
            )}
          </div>
          <div
            className="shop-tokens__footer-text  shop-tokens__footer-text--info"
            onClick={() => {
              this.props.tokensInfo!.show();
            }}
          >
            {Local.getString('shop-tokens.q-tokens')}
          </div>
          <div className="shop-tokens__footer-text shop-tokens__footer-text--security">{Local.getString('shop-tokens.security')}</div>
          <div className="shop-tokens__security-lock" />
          <div
            className="shop-tokens__footer-text shop-tokens__footer-text--question"
            onClick={() => {
              this.props.faq!.show();
            }}
          >
            {Local.getString('shop-tokens.faq')}
          </div>
        </div>
      </div>
    );
  }
}
