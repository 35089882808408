import React from 'react';
import './Settings.css';


export default function Settings(props) {

    function isActiveCamera(id){
        return props.camera.deviceId === id;
    }

    function isActiveAudioInput(id){
        return props.mic.deviceId === id;
    }

    function isActiveAudioOutput(id){
        return props.speaker.deviceId === id;
    }

    return (props.onClickDisplay && props.devices) ? (
        <div className="settings">
            {
                props.devices &&
                (
                    <div>
                        <div className="settings-device">
                            <p>kamere:  </p>
                            <select className="settings-select" onChange={props.onCamChanged}>
                                {props.devices.filter(device => device.kind === 'videoinput')
                                .filter((v,i,a)=>a.findIndex(t=>(t.deviceId === v.deviceId))===i)
                                .map((device) => 
                                <option 
                                    key={device.deviceId} 
                                    value={device.deviceId}
                                    selected={isActiveCamera(device.deviceId)}
                                    >
                                    {device.label}
                                </option>)}
                            </select>
                        </div>

                        <div className="settings-device">
                            <p>mikrofoni:  </p>
                            <select className="settings-select" onChange={props.onAudioInputChanged}>
                                {props.devices.filter(device => device.kind === 'audioinput')
                                .filter((v,i,a)=>a.findIndex(t=>(t.deviceId === v.deviceId))===i)
                                .map((device) => 
                                <option 
                                    key={device.deviceId} 
                                    value={device.deviceId}
                                    selected={isActiveAudioInput(device.deviceId)}
                                    >
                                    {device.label}
                                </option>)}
                            </select>
                        </div>

                        <div className="settings-device">
                            <p>zvučnici:  </p>
                            <select className="settings-select" onChange={props.onAudioOutputChanged}>
                                {props.devices.filter(device => device.kind === 'audiooutput')
                                .filter((v,i,a)=>a.findIndex(t=>(t.deviceId === v.deviceId))===i)
                                .map((device) => 
                                <option 
                                    key={device.deviceId} 
                                    value={device.deviceId}
                                    selected={isActiveAudioOutput(device.deviceId)}
                                    >
                                    {device.label}
                                </option>)}
                            </select>
                        </div>
                    </div>
                )
            }

        </div>
    ) : null;

}