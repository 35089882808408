import { PlayerAction } from '../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.b2b.message.B2bChangeTeamImage';

export class B2bChangeTeamImage extends PlayerAction {
  teamId?: string;

  url?: string;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
