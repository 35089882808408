import { types } from 'mobx-state-tree';
import { Reward } from '../../messages/player/reward/message/Reward';
import { ActionMST } from '../ActionModel';
import { BaseMST } from '../BaseModel';
import { createTournamentItemModel, TournamentItemMST } from './TournamentItemModel';
import { createStatisticsModel } from '../dialogs/statistics/StatisticsModel';
import { UserStats } from '../../messages/player/stats/UserStats';

export const TournamentMST = BaseMST.named('TournamentMST')
  .props({
    rank: types.number,
    tournament: types.array(TournamentItemMST),
    onHelp: types.optional(ActionMST, {})
  })
  .actions(self => ({
    addTournamentPlayer(position: number, name: string, feathers: number, rating: number, rank: number, isMe: boolean, rewards: Array<Reward>, dailyHighscores: Array<number>, stats: UserStats | undefined, maxPrevRank: number, isRatingList: boolean) {
      const tournamentItemModel = createTournamentItemModel();
      tournamentItemModel.init(position, name, feathers, rating, rank, isMe, rewards, dailyHighscores, stats, maxPrevRank, isRatingList);
      self.tournament!.push(tournamentItemModel);
    },
    setRank(rank: number) {
      self.rank = rank;
    },
    removeAllPlayers() {
      if (self.tournament) self.tournament.clear();
    }
  }))
  .views(self => ({}));

type TournamentModelTType = typeof TournamentMST.Type;
export interface TournamentModel extends TournamentModelTType {}

export const createTournamentFriendsModel = () =>
  TournamentMST.create({
    rank: 0,
    tournament: []
  });

//     {
//       position: 1,
//       name: 'Cristiano Ronaldo',
//       rating: 0,
//       rank: 0,
//       expandable: false,
//       rewards: [
//         {
//           id: 1,
//           count: 5
//         },
//         {
//           id: 2,
//           count: 10
//         }
//       ],
//       dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
//       feathers: 0,
//       stats: createStatisticsModel()
//     },
//     {
//       position: 2,
//       name: 'Dwyane Wade',
//       rating: 0,
//       rank: 0,
//       expandable: false,
//       rewards: [
//         {
//           id: 3,
//           count: 5
//         },
//         {
//           id: 5,
//           count: 10
//         },
//         {
//           id: 5,
//           count: 10
//         },
//         {
//           id: 5,
//           count: 10
//         }
//       ],
//       dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
//       feathers: 0,
//       stats: createStatisticsModel()
//     },
//     {
//       position: 3,
//       name: 'Cristiano Ronaldo3',
//       rating: 0,
//       rank: 0,
//       expandable: false,
//       rewards: [
//         {
//           id: 1,
//           count: 5
//         },
//         {
//           id: 2,
//           count: 10
//         }
//       ],
//       dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
//       feathers: 0,
//       stats: createStatisticsModel()
//     },
//     {
//       position: 4,
//       name: 'Cristiano Ronaldo4',
//       rating: 0,
//       rank: 0,
//       expandable: false,
//       rewards: [
//         {
//           id: 1,
//           count: 5
//         },
//         {
//           id: 2,
//           count: 10
//         }
//       ],
//       dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
//       feathers: 0,
//       stats: createStatisticsModel()
//     },
//     {
//       position: 5,
//       name: 'Cristiano Ronaldo5',
//       rating: 0,
//       rank: 0,
//       expandable: false,
//       rewards: [
//         {
//           id: 1,
//           count: 5
//         },
//         {
//           id: 2,
//           count: 10
//         }
//       ],
//       dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
//       feathers: 0,
//       stats: createStatisticsModel()
//     },
//     {
//       position: 6,
//       name: 'Cristiano Ronaldo6',
//       rating: 0,
//       rank: 0,
//       expandable: false,
//       rewards: [
//         {
//           id: 1,
//           count: 5
//         },
//         {
//           id: 2,
//           count: 10
//         }
//       ],
//       dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
//       feathers: 0,
//       stats: createStatisticsModel()
//     },
//     {
//       position: 7,
//       name: 'Cristiano Ronaldo7',
//       rating: 0,
//       rank: 0,
//       expandable: false,
//       rewards: [
//         {
//           id: 1,
//           count: 5
//         },
//         {
//           id: 2,
//           count: 10
//         }
//       ],
//       dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
//       feathers: 0,
//       stats: createStatisticsModel()
//     },
//     {
//       position: 8,
//       name: 'Cristiano Ronaldo8',
//       rating: 0,
//       rank: 0,
//       expandable: false,
//       rewards: [
//         {
//           id: 1,
//           count: 5
//         },
//         {
//           id: 2,
//           count: 10
//         }
//       ],
//       dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
//       feathers: 0,
//       stats: createStatisticsModel()
//     },
//     {
//       position: 9,
//       name: 'Cristiano Ronaldo9',
//       rating: 0,
//       rank: 0,
//       expandable: false,
//       rewards: [
//         {
//           id: 1,
//           count: 5
//         },
//         {
//           id: 2,
//           count: 10
//         }
//       ],
//       dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
//       feathers: 0,
//       stats: createStatisticsModel()
//     },
//     {
//       position: 10,
//       name: 'Cristiano Ronaldo10',
//       rating: 0,
//       rank: 0,
//       expandable: false,
//       rewards: [
//         {
//           id: 1,
//           count: 5
//         },
//         {
//           id: 2,
//           count: 10
//         }
//       ],
//       dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
//       feathers: 0,
//       stats: createStatisticsModel()
//     }
//   ]
// });

export const createTournamentTitlesModel = () =>
  TournamentMST.create({
    rank: 0,
    tournament: []

    //       {
    //         position: 1,
    //         name: 'Dwyane Wade',
    //         rating: 0,
    //         rank: 0,
    //         expandable: false,
    //         rewards: [
    //           {
    //             id: 1,
    //             count: 5
    //           },
    //           {
    //             id: 2,
    //             count: 10
    //           }
    //         ],
    //         dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
    //         feathers: 0,
    //         stats: createStatisticsModel()
    //       },
    //       {
    //         position: 2,
    //         name: 'Ronaldo',
    //         rating: 0,
    //         rank: 0,
    //         expandable: true,
    //         rewards: [
    //           {
    //             id: 3,
    //             count: 5
    //           },
    //           {
    //             id: 5,
    //             count: 10
    //           },
    //           {
    //             id: 5,
    //             count: 10
    //           },
    //           {
    //             id: 5,
    //             count: 10
    //           }
    //         ],
    //         dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
    //         feathers: 0,
    //         stats: createStatisticsModel()
    //       },
    //       {
    //         position: 3,
    //         name: 'Dwyane Wade3',
    //         rating: 0,
    //         rank: 0,
    //         expandable: false,
    //         rewards: [
    //           {
    //             id: 1,
    //             count: 5
    //           },
    //           {
    //             id: 2,
    //             count: 10
    //           }
    //         ],
    //         dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
    //         feathers: 0,
    //         stats: createStatisticsModel()
    //       },
    //       {
    //         position: 4,
    //         name: 'Dwyane Wade4',
    //         rating: 0,
    //         rank: 0,
    //         expandable: false,
    //         rewards: [
    //           {
    //             id: 1,
    //             count: 5
    //           },
    //           {
    //             id: 2,
    //             count: 10
    //           }
    //         ],
    //         dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
    //         feathers: 0,
    //         stats: createStatisticsModel()
    //       },
    //       {
    //         position: 5,
    //         name: 'Dwyane Wade5',
    //         rating: 0,
    //         rank: 0,
    //         expandable: false,
    //         rewards: [
    //           {
    //             id: 1,
    //             count: 5
    //           },
    //           {
    //             id: 2,
    //             count: 10
    //           }
    //         ],
    //         dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
    //         feathers: 0,
    //         stats: createStatisticsModel()
    //       },
    //       {
    //         position: 6,
    //         name: 'Cristiano Ronaldo6',
    //         rating: 0,
    //         rank: 0,
    //         expandable: false,
    //         rewards: [
    //           {
    //             id: 1,
    //             count: 5
    //           },
    //           {
    //             id: 2,
    //             count: 10
    //           }
    //         ],
    //         dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
    //         feathers: 0,
    //         stats: createStatisticsModel()
    //       },
    //       {
    //         position: 7,
    //         name: 'Cristiano Ronaldo7',
    //         rating: 0,
    //         rank: 0,
    //         expandable: false,
    //         rewards: [
    //           {
    //             id: 1,
    //             count: 5
    //           },
    //           {
    //             id: 2,
    //             count: 10
    //           }
    //         ],
    //         dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
    //         feathers: 0,
    //         stats: createStatisticsModel()
    //       },
    //       {
    //         position: 8,
    //         name: 'Cristiano Ronaldo8',
    //         rating: 0,
    //         rank: 0,
    //         expandable: false,
    //         rewards: [
    //           {
    //             id: 1,
    //             count: 5
    //           },
    //           {
    //             id: 2,
    //             count: 10
    //           }
    //         ],
    //         dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
    //         feathers: 0,
    //         stats: createStatisticsModel()
    //       },
    //       {
    //         position: 9,
    //         name: 'Cristiano Ronaldo9',
    //         rating: 0,
    //         rank: 0,
    //         expandable: false,
    //         rewards: [
    //           {
    //             id: 1,
    //             count: 5
    //           },
    //           {
    //             id: 2,
    //             count: 10
    //           }
    //         ],
    //         dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
    //         feathers: 0,
    //         stats: createStatisticsModel()
    //       },
    //       {
    //         position: 10,
    //         name: 'Cristiano Ronaldo10',
    //         rating: 0,
    //         rank: 0,
    //         expandable: false,
    //         rewards: [
    //           {
    //             id: 1,
    //             count: 5
    //           },
    //           {
    //             id: 2,
    //             count: 10
    //           }
    //         ],
    //         dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
    //         feathers: 0,
    //         stats: createStatisticsModel()
    //       },
    //       {
    //         position: 0,
    //         name: 'Cristiano Ronaldo10',
    //         rating: 0,
    //         rank: 0,
    //         expandable: false,
    //         rewards: [
    //           {
    //             id: 1,
    //             count: 5
    //           },
    //           {
    //             id: 2,
    //             count: 10
    //           }
    //         ],
    //         dailyHighscores: [15, 16, 17, 18, 19, 20, 96],
    //         feathers: 0,
    //         stats: createStatisticsModel()
    //       }
    //     ]
  });
