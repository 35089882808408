import { CollaborationModel } from 'web/src/collaboration/model/CollaborationModel';
import AnimationExecutor from '../../animations/AnimationExecutor';
import { APP } from '../../app/AppInstance';
import { Local } from '../../lang/Local';
import { B2bCaptainRequest } from '../../messages/b2b/B2bCaptainRequest';
import { B2bCaptainResponse } from '../../messages/b2b/B2bCaptainResponse';
import { B2bDeclareCaptain } from '../../messages/b2b/B2bDeclareCaptain';
import { B2bGameReady } from '../../messages/b2b/B2bGameReady';
import { B2bNoCaptain } from '../../messages/b2b/B2bNoCaptain';
import { FinishGame } from '../../messages/communication/message/FinishGame';
import { GameFinished } from '../../messages/communication/message/GameFinished';
import { Score } from '../../messages/communication/message/Score';
import { ServerEvent } from '../../messages/communication/message/ServerEvent';
import { StartControllerEvent } from '../../messages/communication/message/StartControllerEvent';
import { OpponentLeftGame } from '../../messages/player/message/OpponentLeftGame';
import { OpponentResumedGame } from '../../messages/player/message/OpponentResumedGame';
import { B2bTeamModel } from '../../models/b2b/B2bTeamModel';
import { B2bTournamentModel } from '../../models/b2b/B2bTournamentModel';
import { B2bCaptainModel } from '../../models/dialogs/b2bgame/B2bCaptainModel';
import { B2bNoCaptainModel } from '../../models/dialogs/b2bgame/B2bNoCaptainModel';
import { ShopModel } from '../../models/dialogs/shop/ShopModel';
import { GameRiddleContentModel } from '../../models/game/content/GameRiddleContent';
import { GameInfoModel } from '../../models/game/GameInfoModel';
import { GameModel } from '../../models/game/GameScreenModel';
import { BLUE_PLAYER, RED_PLAYER } from '../../models/game/PlayerType';
import { ToolbarModel } from '../../models/toolbar/ToolbarModel';
import { UserModel } from '../../models/user/UserModel';
import { ConnectionAdapter } from '../../sockets/ConnectionAdapter';
import { GameFlowTracking } from '../../tracking/actions/GameFlowTracking';
import { Controller } from './Controller';

const ME = 0;
const OPPONENT = 1;
const BOTH_PLAYERS = 2;
const NO_PLAYER = 3;

export abstract class GameController extends Controller {
  protected gameInfo: GameInfoModel;

  protected animationExecutor: AnimationExecutor;

  protected statusAnimationExecutor: AnimationExecutor;

  protected gameScreenModel: GameModel;

  protected userModel: UserModel;

  protected shopModel: ShopModel;

  protected toolbarModel: ToolbarModel;

  protected myGame: boolean | undefined = false;

  protected amICaptain: boolean | undefined = false;

  protected b2bCaptainModel: B2bCaptainModel;

  protected b2bNoCaptainModel: B2bNoCaptainModel;

  protected b2bTournamentModel: B2bTournamentModel;

  protected collaborationModel: CollaborationModel;

  constructor(
    amfSocket: ConnectionAdapter,
    name: string,
    gameInfo: GameInfoModel,
    gameScreenModel: GameModel,
    userModel: UserModel,
    shopModel: ShopModel,
    toolbarModel: ToolbarModel,
    b2bTournamentModel: B2bTournamentModel,
    b2bCaptainModel: B2bCaptainModel,
    b2bNoCaptainModel: B2bNoCaptainModel,
    collaborationModel: CollaborationModel
  ) {
    super(amfSocket, name);
    this.gameInfo = gameInfo;
    this.gameScreenModel = gameScreenModel;
    this.userModel = userModel;
    this.shopModel = shopModel;
    this.toolbarModel = toolbarModel;
    this.animationExecutor = new AnimationExecutor();
    this.statusAnimationExecutor = new AnimationExecutor();
    this.b2bTournamentModel = b2bTournamentModel;
    this.b2bCaptainModel = b2bCaptainModel;
    this.b2bNoCaptainModel = b2bNoCaptainModel;
    this.toolbarModel.setVisible(false);
    this.collaborationModel = collaborationModel;
  }

  protected abstract onStartGame(): void;

  protected abstract getGameModel(): GameRiddleContentModel;

  static get ME() {
    return ME;
  }

  static get OPPONENT() {
    return OPPONENT;
  }

  static get BOTH_PLAYERS() {
    return BOTH_PLAYERS;
  }

  static get NO_PLAYER() {
    return NO_PLAYER;
  }

  public onControllerMessage(message: ServerEvent) {
    switch (message.getClass()) {
      case StartControllerEvent.ClassName:
        this.onStartControllerEvent(message as StartControllerEvent);
        break;
      case B2bCaptainRequest.ClassName:
        this.onB2bCaptainRequest(message as B2bCaptainRequest);
        break;
      case B2bDeclareCaptain.ClassName:
        this.onB2bDeclareCaptain(message as B2bDeclareCaptain);
        break;
      case B2bNoCaptain.ClassName:
        this.onB2bNoCaptain(message as B2bNoCaptain);
        break;
      case B2bGameReady.ClassName:
        this.onB2bGameReady(message as B2bGameReady);
        break;
      case FinishGame.ClassName:
        this.onFinishGame(message as FinishGame);
        break;

      case Score.ClassName:
        this.onScore(message as Score);
        break;

      case OpponentLeftGame.ClassName:
        this.onOpponentLeftGame();
        break;

      case OpponentResumedGame.ClassName:
        this.onOpponentResumedGame();
        break;

      default:
        break;
    }
  }

  private onFinishGame(message: FinishGame) {
    // FocusMonitor.getInstance().reset();
    // FocusMonitor.getInstance().removeEventListener(FocusMonitor.PLAYER_BANNED_OUT_FOCUS, playerBanned);

    this.setStatusSpectacle(
      Local.getString(message.lastGameInSequence ? 'game.finished' : 'game.next_start'),
      this.calculateClientTimeout(message),
      () => {
        this.sendFinishGameMessage();
        this.gameInfo.stopTimer(false);
      },
      NO_PLAYER,
      false
    );

    this.showGameResult();
  }

  private showGameResult() {}

  // FIXME
  // private onPointsStatus(message: PointsStatus) {
  //   if (this.isForMe(message)) {
  //     this.gameInfo.bluePlayer.setPoints(message.points);
  //     this.gameInfo.redPlayer.setPoints(message.opponentPoints);
  //   } else {
  //     this.gameInfo.bluePlayer.setPoints(message.opponentPoints);
  //     this.gameInfo.redPlayer.setPoints(message.points);
  //   }
  // }

  private onStartControllerEvent(message: StartControllerEvent) {
    this.myGame = message.firstPlaying;
    this.onStartGame();
  }

  protected hideCaptainDialog() {
    // this.b2bCaptainModel.setVisible(false);
    // this.b2bNoCaptainModel.setVisible(false);
  }

  private onB2bCaptainRequest(message: B2bCaptainRequest) {
    this.gameInfo.enableInteraction();
    this.b2bCaptainModel.setCaptain('');
    this.b2bCaptainModel.setCaptainName('');
    this.b2bCaptainModel.setGameName(this.getGameName());
    this.b2bCaptainModel.setBtnActions(
      () => {
        this.claimCaptain();
      },
      () => {
        this.declineCaptain();
      }
    );
    this.b2bCaptainModel.setWaitForCaptain(true);
    // this.gameModel = this.gameScreenModel.getCurrentContent();
    this.gameScreenModel.showGameContent(this.b2bCaptainModel);

    this.setStatusSpectacle(Local.getString('b2b-captain.title'), this.calculateClientTimeout(message), () => {}, GameController.ME, false);

    this.b2bCaptainModel.setVisible(true);
  }

  declineCaptain() {
    this.b2bCaptainModel.setEnabled(false);
  }

  claimCaptain() {
    this.sendMessage(new B2bCaptainResponse(true));
    this.b2bCaptainModel.setEnabled(false);
  }

  private onB2bNoCaptain(message: B2bDeclareCaptain) {
    this.gameInfo.disableInteraction();
    this.b2bNoCaptainModel.setVisible(true);
    this.b2bNoCaptainModel.setGameName(this.getGameName());
    this.gameScreenModel.showGameContent(this.b2bNoCaptainModel);

    this.setStatusSpectacle(Local.getString('b2b-captain.title-no-captain'), this.calculateClientTimeout(message), () => {}, GameController.ME, false);
    this.collaborationModel.declareCaptain(true);
  }

  private onB2bGameReady(message: B2bGameReady) {
    this.gameScreenModel.showGameContent(this.getGameModel());
  }

  private onB2bDeclareCaptain(message: B2bDeclareCaptain) {
    if (this.isMyTeamMember(message.captainId!)) {
      this.evaluateMyCaptain(message);
    } else this.evaluateOpponentsCaptain(message);
  }

  protected getMyTeam(): B2bTeamModel {
    return this.b2bTournamentModel.getTeam(this.gameInfo.myTeam);
  }

  protected getOpponentTeam(): B2bTeamModel {
    return this.b2bTournamentModel.getTeam(this.gameInfo.opponentTeam);
  }

  private isMyTeamMember(playerId: string): boolean {
    return this.getMyTeam().getTeamMember(playerId) != null;
  }

  private isMyTeamMemberByPlayerId(playerId: number): boolean {
    return this.getMyTeam().getTeamMemberByPlayerId(playerId) != null;
  }

  protected getB2bMyPlayerName(playerId: number): string {
    const u: UserModel = this.getMyTeam().getTeamMemberByPlayerId(playerId);
    return u ? u.info.b2bName || '' : '';
  }

  protected getB2bOpponentPlayerName(playerId: number): string {
    const u: UserModel = this.getOpponentTeam().getTeamMemberByPlayerId(playerId);
    return u ? u.info.b2bName || '' : '';
  }

  private evaluateMyCaptain(message: B2bDeclareCaptain) {
    this.gameInfo.disableInteraction();
    this.b2bCaptainModel.setCaptain(message.captainId!);
    this.b2bCaptainModel.setCaptainName(message.captainName!);
    this.amICaptain = message.captainId === this.userModel.info.b2bId;
    this.animationExecutor.schedule(this.b2bCaptainModel!.signUpCaptainAnimation.build());
    this.setNewStatus(Local.getString('b2b-captain.title-chosen'));
    this.collaborationModel.declareCaptain(this.amICaptain!);
  }

  private evaluateOpponentsCaptain(message: B2bDeclareCaptain) {
    this.b2bCaptainModel.setOpponentsCaptain(message.captainId!);
  }

  private onScore(message: Score) {
    this.gameInfo.getMe().setPoints(message.myPoints);
    this.gameInfo.getOpponent().setPoints(message.opponentPoints);
  }

  private onOpponentLeftGame() {
    this.gameInfo.getOpponent().setOffline(true);
  }

  private onOpponentResumedGame() {
    this.gameInfo.getOpponent().setOffline(false);
  }

  private sendFinishGameMessage() {
    this.sendMessage(new GameFinished());
  }

  protected isForMe(message: ServerEvent): boolean {
    return message.sourcePlayerId === message.playerId;
  }

  protected isForMyTeam(message: ServerEvent): boolean {
    return this.isMyTeamMemberByPlayerId(message.sourcePlayerId!);
  }

  protected isMyGame(): boolean {
    // TODO
    return this.myGame || false;
  }

  public getMyPosition(): number {
    return this.gameInfo.isPlayingAsBlue ? BLUE_PLAYER : RED_PLAYER;
  }

  public getOpponentPosition(): number {
    return !this.gameInfo.isPlayingAsBlue ? BLUE_PLAYER : RED_PLAYER;
  }

  protected setStatusSpectacle(status: string, timeout: number, callback: Function, activePlayer: number, playSound: boolean = true) {
    if (timeout > 0) {
      this.gameInfo.startTimer(callback, timeout, activePlayer, playSound);
    } else {
      callback();
    }

    this.setNewStatus(status);

    if (activePlayer === OPPONENT) this.gameInfo.disableInteraction();
  }

  protected setNewStatus(status: string) {
    this.statusAnimationExecutor.schedule(this.gameInfo.getNewStatusAnimator(status.toLocaleUpperCase()));
  }

  protected calculateClientTimeout(request: ServerEvent): number {
    return request.timeoutForResponse ? Math.floor(request.timeoutForResponse / 1000) : 0;
  }

  protected getGameName(): string {
    return '';
  }

  protected finalizeController(): void {
    APP.Tracker.trackEvent(new GameFlowTracking(`${this.getGameName()}_finished`));
    // 	removeAllEventMessageListener(FinishGame);
    // 	removeAllEventMessageListener(PointsUpdate);
  }

  public onBurstMessigingStart(): void {
    super.onBurstMessigingStart();
    this.animationExecutor.setBurstMode(true);
    this.statusAnimationExecutor.setBurstMode(true);
  }

  public onBurstMessigingStop(): void {
    super.onBurstMessigingStop();
    this.animationExecutor.setBurstMode(false);
    this.statusAnimationExecutor.setBurstMode(false);
  }

  public onConnectionError(message: string, reconnecting: boolean): void {
    super.onConnectionError(message, reconnecting);
    this.animationExecutor.cancel();
    this.statusAnimationExecutor.cancel();
    this.gameInfo.stopTimer(false);
  }
}
