import { PlayerAction } from '../../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.player.message.ChallengeResponse';

const Accept = 1; // fieldType: int
const Decline = 2; // fieldType: int

export class ChallengeResponse extends PlayerAction {
  challengerId: number = 0;

  // fieldType: long
  response: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get Accept() {
    return Accept;
  }

  static get Decline() {
    return Decline;
  }
}
