import { ServerEvent } from '../communication/message/ServerEvent';
import { B2bTournamentInfo } from './B2bTournamentInfo';

const BINDING_NAME = 'rs.slagalica.b2b.message.B2bTournamentEvent';

export class B2bTournamentEvent extends ServerEvent {
  tournamentinfo?: B2bTournamentInfo = undefined;

  showResult?: boolean = false;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
