import { PlayerAction } from '../../../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.player.store.message.BuyItem';

export class BuyItem extends PlayerAction {
  groupId: number = 0;

  // fieldType: int
  itemId: number = 0;

  // fieldType: int
  offerId: number = 0;

  // fieldType: int
  activateNow: boolean = false; // fieldType: boolean

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
