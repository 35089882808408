import { types } from 'mobx-state-tree';
import { ActionMST } from '../ActionModel';
import { AnimationBuilderMST } from '../AnimationBuilder';
import { DisplayMST } from '../DisplayModel';
import { DisplayModelKeys } from '../DisplayModelKeys';

export const LobbyMST = DisplayMST.named('LobbyMST')
  .props({
    onlinePlayers: 100,
    todaysHighscore: 0,
    startGame: types.optional(ActionMST, {}),
    openMyTeam: types.optional(ActionMST, {}),
    closeAnimation: types.optional(AnimationBuilderMST, {}),
    openAnimation: types.optional(AnimationBuilderMST, {}),
    openAchievements: types.optional(AnimationBuilderMST, {}),
    closeAchievements: types.optional(AnimationBuilderMST, {})
  })
  .actions(self => ({
    setTodaysHigscore(highscore: number) {
      self.todaysHighscore = highscore;
    },
    setPlayersOnline(playersCount: number) {
      self.onlinePlayers = playersCount;
    }
  }));

type LobbyModelType = typeof LobbyMST.Type;
export interface LobbyModel extends LobbyModelType {}
export const createLobbyModel = () =>
  LobbyMST.create({
    key: DisplayModelKeys.Lobby,
    visible: false,
    closeAnimation: {},
    openAnimation: {}
  });
