import { APP } from '../../app/AppInstance';
import { LogoutFromChat } from '../../messages/chat/message/LogoutFromChat';
import { MessageFromClient } from '../../messages/chat/message/MessageFromClient';
import { MessageFromServer } from '../../messages/chat/message/MessageFromServer';
import { ServerEvent } from '../../messages/communication/message/ServerEvent';
import { OpponentLeftGame } from '../../messages/player/message/OpponentLeftGame';
import { ChatModel } from '../../models/game/chat/ChatModel';
import { ConnectionAdapter } from '../../sockets/ConnectionAdapter';
import { GameFlowTracking } from '../../tracking/actions/GameFlowTracking';
import { Controller } from '../base/Controller';

const PREFIX_LENGTH = 4;
const USER_MESSAGE_CODE_PREFIX = '1000';
const SIMPLE_EMOTION_CODE_PREFIX = '2000';
const SOUND_EMOTION_CODE_PREFIX = '3000';
const STICKER_CODE_PREFIX = '4000';
export class ChatController extends Controller {
  private firstMessageSent: boolean = false;

  private chatModel: ChatModel;

  constructor(amfSocket: ConnectionAdapter, chatModel: ChatModel) {
    super(amfSocket, 'ChatController');
    this.chatModel = chatModel;
    this.chatModel.init(() => {
      this.sendChatMessageToServer();
    });
  }

  public onControllerMessage(message: ServerEvent) {
    switch (message.getClass()) {
      case MessageFromServer.ClassName:
        this.onMessageFromServer(message as MessageFromServer);
        break;

      case OpponentLeftGame.ClassName:
        this.onOpponentLeftGame();
        break;

      default:
        break;
    }
  }

  private onMessageFromServer(message: MessageFromServer) {
    const decodedMsg: number[] = this.decode(message.message ? message.message : '');
    this.chatModel.onMessageReceived(decodedMsg[0], decodedMsg[1]);
  }

  private onOpponentLeftGame() {
    this.chatModel.onMessageReceived(0, 0);
  }

  public sendChatMessageToServer(): void {
    const message = this.getCodedMessage(this.chatModel.selectedMessage!.id, this.chatModel.selectedMessage!.type);

    if (!this.firstMessageSent) APP.Tracker.trackEvent(new GameFlowTracking('use_chat'));

    this.firstMessageSent = true;
    const messageFromClient = new MessageFromClient();
    messageFromClient.message = message;
    this.sendMessage(messageFromClient);
  }

  private getCodedMessage(id: number, type: number): string {
    let message: string;

    switch (type) {
      case 0:
        message = this.getUserMessageCode(id);
        break;
      case 1:
        message = this.getSimpleEmotionCode(id);
        break;
      case 2:
        message = this.getStickerCode(id);
        break;
      case 3:
        message = this.getSoundEmotionCode(id);
        break;
      default:
        message = '';
        break;
    }

    return message;
  }

  private getUserMessageCode(messageId: number): string {
    return this.getMessageCode(USER_MESSAGE_CODE_PREFIX, messageId);
  }

  private getSimpleEmotionCode(messageId: number): string {
    return this.getMessageCode(SIMPLE_EMOTION_CODE_PREFIX, messageId);
  }

  private getStickerCode(messageId: number): string {
    return this.getMessageCode(STICKER_CODE_PREFIX, messageId);
  }

  private getSoundEmotionCode(messageId: number): string {
    return this.getMessageCode(SOUND_EMOTION_CODE_PREFIX, messageId);
  }

  private getMessageCode(messagePrefix: string, messageId: number): string {
    return messagePrefix + messageId;
  }

  private decode(message: string): number[] {
    if (message != null && message.length > PREFIX_LENGTH) {
      const prefix: string = message.substr(0, PREFIX_LENGTH);
      const id: number = Number(message.substr(PREFIX_LENGTH));

      // FIXME
      // if (!isNaN(id)) {
      switch (prefix) {
        case USER_MESSAGE_CODE_PREFIX:
          return [0, id];
        case SIMPLE_EMOTION_CODE_PREFIX:
          return [1, id];
        case STICKER_CODE_PREFIX:
          return [2, id];
        case SOUND_EMOTION_CODE_PREFIX:
          return [3, id];
        default:
        // trace('Unknown message type');
        // }
      }
    }
    return [];
  }

  public logout(): void {
    this.sendMessage(new LogoutFromChat());
  }

  protected finalizeController(): void {}

  public enterBurstMessageMode(): void {}

  public exitBurstMessageMode(): void {}
}
