import { DisplayMST } from '../../DisplayModel';
import { ButtonMST } from '../../ButtonModel';
import { AnimationBuilderMST } from '../../AnimationBuilder';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const ImportantInfoMST = DisplayMST.named('ImportantInfoMST')
  .props({
    okButton: ButtonMST,
    showButtonAnimation: AnimationBuilderMST
  })
  .views(() => ({}))
  .actions(() => ({}));

type ImportantInfoModelType = typeof ImportantInfoMST.Type;
export interface ImportantInfoModel extends ImportantInfoModelType {}

export const createNewAppInfoModel = () =>
  ImportantInfoMST.create({
    key: DisplayModelKeys.NewAppInfoDialog,
    okButton: {},
    showButtonAnimation: {}
  });

export const createNewPaymentInfoModel = () =>
  ImportantInfoMST.create({
    key: DisplayModelKeys.NewPaymentInfoDialog,
    okButton: {},
    showButtonAnimation: {}
  });
