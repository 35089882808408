import { Local } from 'common/src/lang/Local';
import { ChatModel } from 'common/src/models/game/chat/ChatModel';
import { GameInfoModel } from 'common/src/models/game/GameInfoModel';
import { GameModel } from 'common/src/models/game/GameScreenModel';
import { HatsModel } from 'common/src/models/game/HatsModel';
import { SettingsModel } from 'common/src/models/settings/SettingsModel';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { BaseScreen, BaseScreenProps } from '../BaseScreen';
import B2bSound from '../toolbar/B2bSound';
import Toolbar from '../toolbar/Toolbar';
import Association from './content/association/Association';
import B2bCaptain from './content/captain/B2bCaptain';
import B2bNoCaptain from './content/captain/B2bNoCaptain';
import Combinations from './content/combinations/Combinations';
import Matching from './content/matching/Matching';
import MyNumber from './content/mynumber/MyNumber';
import Pairs from './content/pairs/Pairs';
import Puzzle from './content/puzzle/Puzzle';
import Quiz from './content/quiz/Quiz';
import FriendGameResult from './content/result/FriendGameResult';
import GameResult from './content/result/GameResult';
import PageVisibilityObserver from './PageVisibilityObserver';
import Semaphore from './semaphore/Semaphore';

interface GameScreenProps extends BaseScreenProps {
  game?: GameModel;
  gameInfo?: GameInfoModel;
  chat?: ChatModel;
  hats?: HatsModel;
  settings?: SettingsModel;
}

@inject('game', 'gameInfo', 'chat', 'hats', 'settings')
@observer
export default class GameScreen extends BaseScreen<GameScreenProps, {}> {
  componentDidMount() {}

  isVisible(): boolean {
    if (this.props.game) return this.props.game.isVisible();
    return false;
  }

  getInteractionDiv(): string {
    if (this.props.gameInfo!.interactionDisabled) {
      return this.props.gameInfo!.blurBackground ? 'disabled-blurred-div' : 'disabled-div';
    }

    return 'enabled-div';
  }

  getToolTip(text: string, bootom: string) {
    return (
      <div className={`nav__tooltip--title${bootom}`}>
        <div className="nav__tooltip--title__text">{text}</div>
      </div>
    );
  }

  renderVisible(): React.ReactNode {
    const t = (message: string) => Local.getString(message);
    return (
      <div className="game">
        <Toolbar />
        <B2bSound />
        <Semaphore gameInfo={this.props.gameInfo} hats={this.props.hats} />

        <div className="game-content">
          <div className={this.getInteractionDiv()}>
            <Quiz />
            <Matching />
            <Association />
            <Puzzle />
            <GameResult />
            <FriendGameResult />
            <MyNumber />
            <Pairs />
            <Combinations />
            <B2bCaptain />
            <B2bNoCaptain />
            <PageVisibilityObserver />
          </div>
        </div>
      </div>
    );
  }
}
