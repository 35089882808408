import { DisplayModel } from '../../models/DisplayModel';

export class ScreenManager {
  private current: DisplayModel | undefined = undefined;

  onShow(screen: DisplayModel): void {
    if (this.current) this.current.hide();

    this.current = screen;
  }

  onSchedule(screen: DisplayModel): void {
    // not supported yet, just show
    this.onShow(screen);
  }

  onHide(screen: DisplayModel): void {
    if (screen === this.current) this.current = undefined;
  }
}
