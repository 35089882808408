import { Animation, TimelineLite, TimelineMax } from 'gsap';
import GSAPAnimation from '../base/GSAPAnimation';

function createRotate(target: HTMLElement | null, duration: number = 0.2, repeat: number): Animation {
  const timeline = new TimelineLite({ paused: true });

  if (target) timeline.add(new TimelineMax({ repeat }).to(target, duration, { rotationY: '+=360' }));

  return timeline;
}

export class Rotate extends GSAPAnimation {
  constructor(target: HTMLElement | null, duration: number = 0.2, repeat: number) {
    super(createRotate(target, duration, repeat));
  }
}
