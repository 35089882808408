import { TimelineLite, TweenLite, TimelineMax, Power1, Power2 } from 'gsap';
import GSAPAnimation from '../base/GSAPAnimation';
import { RemPixelRatio } from '../RemPixelRatio';

export class TransitionOut extends GSAPAnimation {
  constructor(target: {} | null, duration: number, fromX: number, fromY: number, toX: number, toY: number, repeat: number = 0) {
    const timeline = new TimelineLite({ paused: true });

    if (target) {
      timeline.add(
        new TimelineMax({ repeat }).add(
          TweenLite.fromTo(
            target,
            duration,
            {
              x: fromX * RemPixelRatio.getRatio(),
              y: fromY * RemPixelRatio.getRatio(),
              ease: Power2.easeOut
            },
            {
              x: toX * RemPixelRatio.getRatio(),
              y: toY * RemPixelRatio.getRatio(),
              ease: Power2.easeOut
            }
          )
        )
        // .add(
        //   TweenLite.fromTo(
        //     target,
        //     0.01,
        //     { x: toX, y: toY, opacity: 0 },
        //     { x: fromX, y: fromY, opacity: 0 }
        //   )
        // )
      );
    }

    super(timeline);
  }
}
