import { SurveyDialogMST } from '../survey/SurveyDialogModel';
import { DisplayMST } from '../../DisplayModel';
import { ActionMST } from '../../ActionModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const DeclineGameMST = DisplayMST.named('DeclineGameMST')
  .props({
    confirmAction: ActionMST,
    cancelAction: ActionMST
  })
  .views(() => ({}))
  .actions(() => ({}));

type DeclineGameModelType = typeof DeclineGameMST.Type;
export interface DeclineGameModel extends DeclineGameModelType {}

export const createDeclineGameModel = () =>
  DeclineGameMST.create({
    key: DisplayModelKeys.DeclineGameDialog,
    confirmAction: {},
    cancelAction: {}
  });
