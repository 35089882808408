import { GameSequenceStarted } from '../player/message/GameSequenceStarted';
import { B2bTeam } from './B2bTeam';

const BINDING_NAME = 'rs.slagalica.b2b.game.message.B2bGameSequenceStarted';

export class B2bGameSequenceStarted extends GameSequenceStarted {
  myTeam?: B2bTeam;

  opponentTeam?: B2bTeam;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
