import { types } from 'mobx-state-tree';
import { VisibleMST } from '../../../VisableModel';
import { createSignButtonModel, SignButtonModel, SignButtonMST } from './SignButtonModel';
import { AnimationBuilderMST } from '../../../AnimationBuilder';
import { CombinationsSign } from './CombinationSign';

export const CombinationsHolderMST = VisibleMST.named('CombinationsHolderMST')
  .props({
    signButtons: types.array(SignButtonMST),
    hitGlowAnimation: types.optional(AnimationBuilderMST, {})
  })
  .actions(self => ({
    setCombination(combinations: Array<number>) {
      for (let i = 0; i < 4; i++) {
        self.signButtons[i].setType(combinations[i]);
      }
    },
    getCombination(): Array<number> {
      const combination: Array<number> = [];
      self.signButtons.forEach((signBtn: SignButtonModel) => {
        combination.push(signBtn.getSignType());
      });
      return combination;
    },
    setSignToFirstEmptyHolder(sign: number) {
      for (let i = 0; i < self.signButtons.length; i++) {
        if (self.signButtons[i].getSignType() === 0) {
          self.signButtons[i].setType(sign);
          return;
        }
      }
    },
    setEnabled(enabled: boolean) {
      self.signButtons.forEach((btn: SignButtonModel) => {
        btn.action.setEnabled(enabled);
      });
    },
    reset() {
      for (let i = 0; i < self.signButtons.length; i++) {
        self.signButtons[i].setType(CombinationsSign.None);
      }
    }
  }))
  .views(self => ({
    isCombinationValid(): boolean {
      for (let i = 0; i < self.signButtons.length; i++) {
        if (self.signButtons[i].getSignType() === CombinationsSign.None) return false;
      }
      return true;
    },
    isCombinationSet(): boolean {
      for (let i = 0; i < self.signButtons.length; i++) {
        if (self.signButtons[i].getSignType() === 0) {
          return false;
        }
      }
      return true;
    }
  }));

type CombinationsHolderType = typeof CombinationsHolderMST.Type;
export interface CombinationsHolderModel extends CombinationsHolderType {}

export const createCombinationsHolderModel = () =>
  CombinationsHolderMST.create({
    signButtons: [createSignButtonModel(CombinationsSign.None), createSignButtonModel(CombinationsSign.None), createSignButtonModel(CombinationsSign.None), createSignButtonModel(CombinationsSign.None)],
    hitGlowAnimation: {}
  });
