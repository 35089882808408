import { inject, observer } from 'mobx-react';
import React from 'react';
import { DisplayModel } from 'common/src/models/DisplayModel';
import { Local } from 'common/src/lang/Local';
import { DialogProps, BaseDialog } from '../BaseDialog';
import DialogHeader from '../DialogHeader';

interface NewDayProps extends DialogProps {
  newDayDialog?: DisplayModel;
}

@observer
export default class NewDay extends BaseDialog<NewDayProps, {}> {
  isVisible(): boolean {
    if (this.props.newDayDialog) return this.props.newDayDialog.isVisible();
    return false;
  }

  renderVisible() {
    const { newDayDialog } = this.props;

    return (
      <div className="new-day">
        <div className="new-day__inner">
          <DialogHeader onClose={() => newDayDialog!.hide()}>{Local.getString('new_day.dlg_title')}</DialogHeader>
          <div className="new-day__image" />

          <div className="new-day__description">{Local.getString('new_day.dlg_msg1')}</div>
          <button className="new-day__close" onClick={() => newDayDialog!.hide()}>
            {Local.getString('common.close')}
          </button>
        </div>
      </div>
    );
  }
}
