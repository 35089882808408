import { types } from 'mobx-state-tree';
import { AnimationBuilderMST } from '../../../AnimationBuilder';
import { VisibleMST } from '../../../VisableModel';
import { createResultHitNoneModel, ResultHitMST, ResultHitModel } from './ResultHitModel';

export const ResultHolderMST = VisibleMST.named('ResultHolderMST')
  .props({
    hits: types.array(ResultHitMST),
    pointsAnimation: types.optional(AnimationBuilderMST, {})
  })
  .views(self => ({}))
  .actions(self => ({
    setResult(positionValidCnt: number, positionInvalidCnt: number) {
      if (positionValidCnt < 0 || positionInvalidCnt < 0 || positionValidCnt + positionInvalidCnt > 4) return;

      let i: number;
      let j: number;
      for (i = 0; i < positionValidCnt; i++) {
        self.hits[i].setCorrectPlace();
      }

      for (j = i; j < positionInvalidCnt + positionValidCnt; j++) {
        self.hits[j].setCorrectSign();
      }
    },
    reset() {
      self.hits.forEach((resultHitModel: ResultHitModel) => {
        resultHitModel.reset();
      });
    }
  }));

type ResultHolderType = typeof ResultHolderMST.Type;
export interface ResultHolderModel extends ResultHolderType {}

export const createResultHolderModel = () =>
  ResultHolderMST.create({
    hits: [createResultHitNoneModel(), createResultHitNoneModel(), createResultHitNoneModel(), createResultHitNoneModel()],
    pointsAnimation: {}
  });
