import { DisplayModel } from '../../models/DisplayModel';
import { DisplayType, VIEW } from './DisplayType';

export abstract class DisplayConfig {
  private displayTypes: Map<string, DisplayType> = new Map<string, DisplayType>();

  public createDisplayConfigModel(key: string, type: DisplayType) {
    this.displayTypes.set(key, type);
  }

  public getType(display: DisplayModel) {
    let type = this.displayTypes.get(display.key);
    type = type === undefined ? VIEW : type;
    return type;
  }
}
