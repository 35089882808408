const soundsPrefix = 'assets/sounds/';
const soundHash = '?20';

const preloadedSounds: Array<string> = [];

function create(relativePath: string, preload: boolean = true) {
  const path = `${soundsPrefix}${relativePath}${soundHash}`;
  if (preload) preloadedSounds.push(path);
  return path;
}
export class Sounds {
  public static theme = create('theme1.mp3');

  public static intro = create('intro.mp3');

  public static cashbox = create('cashbox.mp3');

  public static clockring = create('clock-ring.mp3');

  public static correct = create('correct.mp3');

  public static message = create('message.mp3');

  public static zeitnot = create('tick-tock.mp3');

  public static wrong = create('wrong.mp3');

  public static winning = create('winning.mp3');

  public static loosing = create('loosing.mp3');

  public static inComingCall = create('notificationBell.mp3');

  public static startupGame = create('start1.mp3');

  public static startupGame2 = create('start2.mp3');

  public static scaleCrash = create('tuff.mp3');

  public static scaleDown = create('scaleDown.mp3');

  public static achShow = create('achShow.mp3');

  public static soundEffects = [
    create('emotions/1-whoo.mp3'),
    create('emotions/2-haha.mp3'),
    create('emotions/3-kiss.mp3'),
    create('emotions/4-yoo-hoo.mp3'),
    create('emotions/5-aplauz.mp3'),
    create('emotions/6-my-mistake.mp3'),
    create('emotions/7-dobro-vece.mp3'),
    create('emotions/8-ne-verujem.mp3')
  ];
}
