import { PlayerAction } from '../../../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.games.association.message.OpenField';

export class OpenField extends PlayerAction {
  column: number = 0;

  // fieldType: int
  field: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
