import { MessageSerializable } from '../MessageSerializable';

const BINDING_NAME = 'rs.slagalica.player.Notification';

const STATUS_READ = 0; // fieldType: int
const STATUS_NOT_READ = 1; // fieldType: int
const TYPE_GOLD_TOKENS = 1; // fieldType: int
const TYPE_SILVER_TOKENS = 2; // fieldType: int
const TYPE_RANK = 3; // fieldType: int
const TYPE_INFO = 4; // fieldType: int
const TYPE_APPEAL = 5; // fieldType: int
const TYPE_INVITE_FRIEND = 6; // fieldType: int
const TYPE_ACHIEVEMENT = 7; // fieldType: int
const TYPE_PREMIUM = 8; // fieldType: int
const TYPE_CHEATING = 9; // fieldType: int
const TYPE_TOURNAMENT_FINISHED = 10; // fieldType: int
const TYPE_TOURNAMENT_PAYMENT_SUCCESFULL = 11; // fieldType: int
const TYPE_TOURNAMENT_PAYMENT_UNSUCCESFULL = 12; // fieldType: int
const PRIORITY_NORMAL = 0; // fieldType: int
const PRIORITY_URGENT = 1; // fieldType: int

export class Notification extends MessageSerializable {
  fId: number = 0;

  // fieldType: long
  title?: string = undefined;

  // fieldType: String
  fText: string = '';

  // fieldType: String
  fStatus: number = 1;

  // fieldType: int
  fType: number = 4;

  // fieldType: int
  fPriority: number = 0;

  // fieldType: int
  fIssued: number = 0;

  // fieldType: long
  fLinkCaption: string = '';

  // fieldType: String
  fLinkURL: string = ''; // fieldType: String

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get STATUS_READ() {
    return STATUS_READ;
  }

  static get STATUS_NOT_READ() {
    return STATUS_NOT_READ;
  }

  static get TYPE_GOLD_TOKENS() {
    return TYPE_GOLD_TOKENS;
  }

  static get TYPE_SILVER_TOKENS() {
    return TYPE_SILVER_TOKENS;
  }

  static get TYPE_RANK() {
    return TYPE_RANK;
  }

  static get TYPE_INFO() {
    return TYPE_INFO;
  }

  static get TYPE_APPEAL() {
    return TYPE_APPEAL;
  }

  static get TYPE_INVITE_FRIEND() {
    return TYPE_INVITE_FRIEND;
  }

  static get TYPE_ACHIEVEMENT() {
    return TYPE_ACHIEVEMENT;
  }

  static get TYPE_PREMIUM() {
    return TYPE_PREMIUM;
  }

  static get TYPE_CHEATING() {
    return TYPE_CHEATING;
  }

  static get TYPE_TOURNAMENT_FINISHED() {
    return TYPE_TOURNAMENT_FINISHED;
  }

  static get TYPE_TOURNAMENT_PAYMENT_SUCCESFULL() {
    return TYPE_TOURNAMENT_PAYMENT_SUCCESFULL;
  }

  static get TYPE_TOURNAMENT_PAYMENT_UNSUCCESFULL() {
    return TYPE_TOURNAMENT_PAYMENT_UNSUCCESFULL;
  }

  static get PRIORITY_NORMAL() {
    return PRIORITY_NORMAL;
  }

  static get PRIORITY_URGENT() {
    return PRIORITY_URGENT;
  }
}
