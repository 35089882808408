import { AchievementWonModel, createAchievementWonModel } from './dialogs/achievements/AchievementWonModel';
import { AppealOpponentModel, createAppealOpponent } from './dialogs/appealopponent/AppealOpponentModel';
import { createDailyRewardsModel, DailyRewardsModel } from './dialogs/dailyreward/DailyRewardsModel';
import { createDeclineGameModel, DeclineGameModel } from './dialogs/declinegame/DeclineGameModel';
import { createHappyBirthdayModel, HappyBirthdayModel } from './dialogs/happybirthday/HappyBirthday';
import { createNewAppInfoModel } from './dialogs/info/ImportantInfoModel';
import { createConnectionLostModel, createServerInfoModel, ServerInfoModel } from './dialogs/info/ServerInfoModel';
import { createResumeGameModel, ResumeGameModel } from './dialogs/resumegame/ResumeGameModel';
import { createSearchOpponentModel, SearchOpponentModel } from './dialogs/searchopponent/SearchOpponentModel';
import { createTournamentResultModel, TournamentResultModel } from './dialogs/tournamentresult/TournamentResultModel';
import { createDisplayModel, DisplayModel } from './DisplayModel';
import { DisplayModelKeys } from './DisplayModelKeys';

export class ModelFactory {
  static createServerInfo(info: string): ServerInfoModel {
    return createServerInfoModel(info);
  }

  static createImportantInfo() {
    return createNewAppInfoModel();
  }

  static createTournamentResult(): TournamentResultModel {
    return createTournamentResultModel();
  }

  // DIALOGS
  static createDailyRewards(): DailyRewardsModel {
    return createDailyRewardsModel();
  }

  static createAchievementWon(): AchievementWonModel {
    return createAchievementWonModel();
  }

  static createSearchOpponent(): SearchOpponentModel {
    return createSearchOpponentModel();
  }

  static createCongratulationDialog(): DisplayModel {
    return createDisplayModel(DisplayModelKeys.CongratulationDialog);
  }

  static createFeatherBoosterDialog(): DisplayModel {
    return createDisplayModel(DisplayModelKeys.FeatherBoosterDialog);
  }

  static createAppealOpponentDialog(): AppealOpponentModel {
    return createAppealOpponent();
  }

  static createTokensInfoDialog(): DisplayModel {
    return createDisplayModel(DisplayModelKeys.TokensInfoDialog);
  }

  static createRangListHelpDialog(): DisplayModel {
    return createDisplayModel(DisplayModelKeys.RangListHelpDialog);
  }

  static createReconnectDialog(): DisplayModel {
    return createDisplayModel(DisplayModelKeys.ReconnectDialog);
  }

  static createConnectionLostDialog(): ServerInfoModel {
    return createConnectionLostModel();
  }

  static createEqualScoreDialog(): DisplayModel {
    return createDisplayModel(DisplayModelKeys.EqualScoreDialog);
  }

  static createResumeDialog(): ResumeGameModel {
    return createResumeGameModel();
  }

  static createTournamentHelp(): DisplayModel {
    return createDisplayModel(DisplayModelKeys.TournamentHelp);
  }

  static createNewDayDialog(): DisplayModel {
    return createDisplayModel(DisplayModelKeys.NewDayDialog);
  }

  static createHappyBirthdayDialog(): HappyBirthdayModel {
    return createHappyBirthdayModel();
  }

  static createDeclineGameDialog(): DeclineGameModel {
    return createDeclineGameModel();
  }

  static createB2bLoginDialog(): DisplayModel {
    return createDisplayModel(DisplayModelKeys.B2bLogin);
  }

  static createB2bCaptainModel(): DisplayModel {
    return createDisplayModel(DisplayModelKeys.B2bCaptainDialog);
  }

  static createB2bNoCaptainModel(): DisplayModel {
    return createDisplayModel(DisplayModelKeys.B2bNoCaptainDialog);
  }

  static createB2bMyTeamDialog(): DisplayModel {
    return createDisplayModel(DisplayModelKeys.B2bMyTeamDialog);
  }
}
