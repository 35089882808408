import { ServerEvent } from '../../../communication/message/ServerEvent';
import { ItemDesc } from '../ItemDesc';

const BINDING_NAME = 'rs.slagalica.player.store.message.StoreContent';

export class StoreContent extends ServerEvent {
  store: Array<ItemDesc> = []; // fieldType: ArrayList

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
