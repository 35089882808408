import { B2bAdminCommandAction } from './B2bAdminCommandAction';
import { B2bTournamentInfo } from './B2bTournamentInfo';

const BINDING_NAME = 'rs.slagalica.b2b.message.B2bUpdateTournamentAction';

export class B2bUpdateTournamentAction extends B2bAdminCommandAction {
  tournamentinfo?: B2bTournamentInfo = undefined;

  constructor(info: B2bTournamentInfo, adminId: string) {
    super(adminId);
    this.tournamentinfo = info;
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
