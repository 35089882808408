import { storage } from 'common/src/firebase';
import { Local } from 'common/src/lang/Local';
import { B2bTeamModel } from 'common/src/models/b2b/B2bTeamModel';
import { UserModel } from 'common/src/models/user/UserModel';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ProfilePicture from '../game/ProfilePicture';

interface B2bMyTeamProps {
  myTeam?: B2bTeamModel;
  myId?: string;
}
@observer
export default class B2bMyTeam extends Component<B2bMyTeamProps> {
  @observable uploading: boolean = false;

  uploadRef: HTMLInputElement | null = null;

  onUploadIconClick(ref: HTMLInputElement) {
    if (!this.uploading) {
      ref.click();
    }
  }

  async setImageUrl(event: any, myTeam: B2bTeamModel) {
    const imageAsFile = event.target.files[0];
    if (!imageAsFile) return;

    this.uploading = true;
    const imageName = `${imageAsFile.name}_${new Date().getTime()}`;
    const uploadTask = storage.ref(`/images/${imageName}`).put(imageAsFile);
    uploadTask.on(
      'state_changed',
      snapShot => {
        const progress = Math.round((snapShot.bytesTransferred / snapShot.totalBytes) * 100);
      },
      err => {
        this.uploading = false;
        console.log(err);
      },
      () => {
        this.uploading = false;
        storage
          .ref('images')
          .child(imageName)
          .getDownloadURL()
          .then(fireBaseUrl => {
            myTeam.setLogo(fireBaseUrl);
          });
      }
    );
  }

  isMe(member: UserModel): boolean {
    return member.info.b2bId == this.props.myId!;
  }

  render() {
    const { myTeam } = this.props;
    const { myId } = this.props;
    const teamMembers = myTeam ? myTeam.teamMembers.filter((member: UserModel) => member.info.b2bName) : [];
    const invalidMembers = 4 - teamMembers.length;

    return (
      <div className="b2b-my-team">
        <div className="b2b-my-team__header">{Local.getString('mainroom.b2b_my_team')}</div>
        {myTeam && (
          <div>
            <div className="b2b-my-team__team-name">{myTeam.name}</div>
            <ProfilePicture css="b2b-my-team__image" b2bUrl={myTeam.logo} size={100} />
            <div className="b2b-my-team__change">
              <div className={!this.uploading ? 'b2b-my-team__change-label' : 'b2b-my-team__wait-label'} onClick={() => this.onUploadIconClick(this.uploadRef!)}>
                {this.uploading ? 'čekam...' : 'promeni'}
              </div>
              <input
                style={{ visibility: 'hidden' }}
                ref={input => {
                  this.uploadRef = input;
                }}
                type="file"
                accept="image/*"
                onChange={e => this.setImageUrl(e, myTeam)}
              />
            </div>
            <div className="b2b-my-team__description">
              <div>
                {teamMembers.map((member: UserModel, i) => (
                  <div className={this.isMe(member) ? 'b2b-my-team__me' : ''} key={i}>{`${++i}. ${member.info.b2bName}`}</div>
                ))}
                {[...Array(invalidMembers)].map((e, i) => (
                  <div key={teamMembers.length}>{`${++teamMembers.length}. -`}</div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
