import { types } from 'mobx-state-tree';
import { ActionMST } from '../ActionModel';
import { BaseMST } from '../BaseModel';

export const TestItemMST = BaseMST.named('TestItemMST')
  .props({
    name: types.string,
    action: ActionMST
  })
  .views(self => ({}))
  .actions(self => ({
    setAction(action: Function) {
      self.action.setAction(action);
    }
  }));

type TestModelType = typeof TestItemMST.Type;
export interface TestItemModel extends TestModelType {}

export const createTestItemModel = (name: string) =>
  TestItemMST.create({
    name,
    action: {}
  });
