import { MessageSerializable } from '../../../MessageSerializable';

const BINDING_NAME = 'rs.slagalica.games.association.message.AssociationColumn';

export class AssociationColumn extends MessageSerializable {
  columnResult: string = '';

  field1: string = '';

  field2: string = '';

  field3: string = '';

  field4: string = '';

  columnPosition: number = 0;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
