import { ServerEvent } from '../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.b2b.game.message.B2bDeclareCaptain';

export class B2bDeclareCaptain extends ServerEvent {
  captainId?: string;

  captainName?: string;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
