import { CollaborationModel } from 'web/src/collaboration/model/CollaborationModel';
import GroupAnimation from '../../animations/GroupAnimation';
import { APP } from '../../app/AppInstance';
import { DEFAULT_VOL } from '../../app/sound/SoundPlayer';
import { Local } from '../../lang/Local';
import { ServerEvent } from '../../messages/communication/message/ServerEvent';
import { CombinationResult } from '../../messages/games/combinations/message/CombinationResult';
import { CombinationsStatus } from '../../messages/games/combinations/message/CombinationsStatus';
import { PlayerCombination } from '../../messages/games/combinations/message/PlayerCombination';
import { StartSolving } from '../../messages/games/combinations/message/StartSolving';
import { B2bTournamentModel } from '../../models/b2b/B2bTournamentModel';
import { B2bCaptainModel } from '../../models/dialogs/b2bgame/B2bCaptainModel';
import { B2bNoCaptainModel } from '../../models/dialogs/b2bgame/B2bNoCaptainModel';
import { ShopModel } from '../../models/dialogs/shop/ShopModel';
import { CombinationsModel } from '../../models/game/content/combinations/CombinationsModel';
import { GameRiddleContentModel } from '../../models/game/content/GameRiddleContent';
import { GameInfoModel } from '../../models/game/GameInfoModel';
import { GameModel } from '../../models/game/GameScreenModel';
import { ToolbarModel } from '../../models/toolbar/ToolbarModel';
import { UserModel } from '../../models/user/UserModel';
import { ConnectionAdapter } from '../../sockets/ConnectionAdapter';
import { GameController } from '../base/GameController';

export class CombinationsController extends GameController {
  private combinationsModel: CombinationsModel;

  constructor(
    amfSocket: ConnectionAdapter,
    combinationsModel: CombinationsModel,
    gameInfo: GameInfoModel,
    gameScreenModel: GameModel,
    userModel: UserModel,
    shopModel: ShopModel,
    toolbarModel: ToolbarModel,
    b2bTournamentModel: B2bTournamentModel,
    b2bCaptainModel: B2bCaptainModel,
    b2bNoCaptainModel: B2bNoCaptainModel,
    collaborationModel: CollaborationModel
  ) {
    super(amfSocket, 'CombinationsController', gameInfo, gameScreenModel, userModel, shopModel, toolbarModel, b2bTournamentModel, b2bCaptainModel, b2bNoCaptainModel, collaborationModel);
    this.combinationsModel = combinationsModel;
  }

  protected getGameModel(): GameRiddleContentModel {
    return this.combinationsModel;
  }

  protected onStartGame(): void {
    // this.animationExecutor.schedule(this.gameScreenModel.getShowGameContentAnimation(this.combinationsModel));
    this.combinationsModel.resetModel();

    this.combinationsModel.setConfirmBtnActions(() => {
      if (this.combinationsModel.getActiveCombination() != null && this.combinationsModel.getActiveCombination().isCombinationValid()) {
        this.combinationsModel.confirmBtns[this.combinationsModel.currentIndex].setEnabled(false);
        this.combinationsModel.confirmBtns[this.combinationsModel.currentIndex].setVisible(false);
        this.combinationsModel.combinationHolders[this.combinationsModel.currentIndex].setEnabled(false);

        const playerCombination = new PlayerCombination();
        playerCombination.answer = this.combinationsModel.getActiveCombination().getCombination();
        this.combinationsModel.setActiveCombination(-1);
        this.sendMessage(playerCombination);
      }
    });
  }

  public onControllerMessage(message: ServerEvent) {
    super.onControllerMessage(message);

    switch (message.getClass()) {
      case StartSolving.ClassName:
        this.onStartSolving(message as StartSolving);
        break;

      case CombinationResult.ClassName:
        this.onCombinationResult(message as CombinationResult);
        break;

      case CombinationsStatus.ClassName:
        this.onCombinationsStatus(message as CombinationsStatus);
        break;
      default:
        break;
    }
  }

  private onStartSolving(message: StartSolving) {
    this.hideCaptainDialog();
    this.gameInfo.stopTimer(false);

    if (this.isForMyTeam(message)) {
      const statusMessage: string = this.amICaptain ? Local.getString('combination.find') : Local.getString('combination.find_captain');
      this.setStatusSpectacle(
        statusMessage,
        this.calculateClientTimeout(message),
        () => {
          this.timerFinishedCalback();
        },
        CombinationsController.ME
      );

      if (this.amICaptain) {
        this.gameInfo.enableInteraction();
        this.combinationsModel.setSignButtonsEnabled(true);
      } else {
        this.combinationsModel.setSignButtonsEnabled(false);
        this.gameInfo.disableInteraction();
      }

      if (this.isMyGame()) {
        this.combinationsModel.setActiveCombination(0); // active player plays from begining
      } else {
        this.combinationsModel.setActiveCombination(6); // inactive player has one opportunity
      }
    } else {
      this.gameInfo.disableInteraction();
      this.combinationsModel.setSignButtonsEnabled(false);
      // getGui().combinationsHolder.enabled = true;
      this.setStatusSpectacle(Local.getString('combination.opp_move'), this.calculateClientTimeout(message), () => {}, CombinationsController.OPPONENT);
    }
  }

  private onCombinationResult(message: CombinationResult) {
    this.combinationsModel.setCombination(message.index, message.userCombination);
    this.combinationsModel.setCombinationResult(message.index, message.hitOnPlaceCount, message.hitCount - message.hitOnPlaceCount);
    if (message.isResolved()) {
      const pointsWon = Math.max(message.myLastPoints, message.opponentLastPoints);
      const blueWon = this.isForMyTeam(message) === this.gameInfo.isPlayingAsBlue;
      this.combinationsModel.setPointsWon(pointsWon);
      this.animateFinalSolution(message.index, blueWon, pointsWon);
    }
    // HISTORY
    // updateHistory(event);
    if (this.isForMyTeam(message)) {
      if (this.isMyGame() && message.index < 5) this.combinationsModel.setActiveCombination(message.index + 1);

      if (message.isResolved()) {
        this.gameInfo.stopTimer(true);
        APP.SoundPlayer.playCorrectSound(DEFAULT_VOL);
      } else APP.SoundPlayer.playWrongSound(DEFAULT_VOL);
    }

    if (message.isResolved() || !this.amICaptain) {
      this.gameInfo.disableInteraction();
    }
  }

  private onCombinationsStatus(message: CombinationsStatus) {
    // FIXME
    // cancelAllTimeouts();
    this.combinationsModel.setActiveCombination(-1);
    this.combinationsModel.setSolutionCombination(message.correctCombination);
    this.gameInfo.disableInteraction();
    // HISTORY
    // updateHistoryWithStatus(event);
  }

  private animateFinalSolution(index: number, blueWon: boolean, pointsWon: number) {
    const seq: GroupAnimation = APP.AnimationAPI.createSequence();
    seq.add(this.combinationsModel.combinationHolders[index].hitGlowAnimation.build());
    seq.add(this.combinationsModel.resultHolders[index].pointsAnimation.build(blueWon, index));
    seq.add(blueWon ? this.gameInfo.bluePlayer.addPoints(pointsWon) : this.gameInfo.redPlayer.addPoints(pointsWon));
    this.animationExecutor.schedule(seq);
  }

  private timerFinishedCalback(): void {
    this.gameInfo.disableInteraction(true);
  }

  public finalizeController(): void {
    super.finalizeController();
  }

  public getGameName(): string {
    return Local.getString('game.combination');
  }
}
