import { B2bAdminCommandAction } from './B2bAdminCommandAction';

const BINDING_NAME = 'rs.slagalica.b2b.message.B2bReadyEvent';

export class B2bReadyEvent extends B2bAdminCommandAction {
  type: number = 0; // fieldType: int
  organizationId: string = '';

  constructor(adminId: string, organizationId: string) {
    super(adminId);
    this.organizationId = organizationId;
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
