import { PlayerAction } from '../../../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.games.matching.message.MatchingResponse';

export class MatchingResponse extends PlayerAction {
  questionIndex: number = 0;

  answerIndex: number = 0;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
