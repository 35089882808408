import { createAppInstance } from 'common/src/app/AppInstance';
import i18n from 'common/src/lang/i18n';
import { createStore } from 'common/src/RootStore';
import { Provider } from 'mobx-react';
import React, { Component } from 'react';
import { I18nextProvider } from 'react-i18next';
import { B2bLocalApplication } from './app/B2bLocalApplication';
import { CollaborationController } from './collaboration/CollaborationController';
import CollaborationScreen from './collaboration/components/CollaborationScreen';
import Status from './collaboration/components/Status';
import RootComponent from './RootComponent';
import './sass/main.scss';

const rootStore = createStore();
const collaborationController = new CollaborationController(rootStore.collaboration, rootStore.collaborationStatus, rootStore.user);

createAppInstance(new B2bLocalApplication(process.env.REACT_APP_SERVER_URL!, rootStore));
class App extends Component {
  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <Provider {...rootStore}>
          <div>
            <Status />
            <div className="b2b-container">
              <RootComponent />
              <CollaborationScreen />
            </div>
          </div>
        </Provider>
      </I18nextProvider>
    );
  }
}

export default App;
