import { inject, observer } from 'mobx-react';
import React from 'react';
import i18n from 'common/src/lang/i18n';
import { DisplayModel } from 'common/src/models/DisplayModel';
import { Local } from 'common/src/lang/Local';
import DialogHeader from '../DialogHeader';
import { BaseDialog, DialogProps } from '../BaseDialog';

interface WeeklyTournamentHelpProps extends DialogProps {
  tournamentHelp?: DisplayModel;
}

@observer
export default class WeeklyTournamentHelp extends BaseDialog<WeeklyTournamentHelpProps, {}> {
  isVisible(): boolean {
    if (this.props.tournamentHelp) return this.props.tournamentHelp.isVisible();
    return false;
  }

  renderVisible() {
    const { tournamentHelp } = this.props;

    return (
      <div className="tournament-help">
        <div className="tournament-help__inner">
          <DialogHeader onClose={() => tournamentHelp!.hide()}>{Local.getString('tournament.hlp_dlg_title')}</DialogHeader>
          <div className="tournament-help__description">
            {Local.getString('tournament.hlp_dlg_msg_1')}
            <br />
            <br />
            {Local.getString('tournament.hlp_dlg_msg_2')}
            <br />
            <br />
            {Local.getString('tournament.hlp_dlg_msg_3')}
          </div>
          <button className="tournament-help__close" onClick={() => tournamentHelp!.hide()}>
            {Local.getString('common.close')}
          </button>
        </div>
      </div>
    );
  }
}
