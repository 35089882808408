import { ServerEvent } from '../../communication/message/ServerEvent';
import { PlayerInfoBase } from './PlayerInfoBase';
import { LeagueInfo } from '../league/message/LeagueInfo';

const BINDING_NAME = 'rs.slagalica.player.message.RangList';

export class RangList extends ServerEvent {
  // List - Array<PlayerInfoBase>
  list: Array<PlayerInfoBase> = [];

  // fieldType: ArrayList
  myPosition: number = 0;

  // fieldType: int
  leagueInfo?: LeagueInfo = undefined; // fieldType: LeagueInfo

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
