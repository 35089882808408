import { types } from 'mobx-state-tree';
import BaseAnimation from '../animations/BaseAnimation';
import { APP } from '../app/AppInstance';

export const AnimationBuilderMST = types.model('AnimationMST').actions(self => {
  // let animation: BaseAnimation = APP.AnimationAPI.createPause(0.1);
  let builder: ((...args: any[]) => BaseAnimation | undefined) | undefined;

  function createDefault(): BaseAnimation {
    return APP.AnimationAPI.createPause(0.1);
  }

  function set(animationBuilder: (...args: any[]) => BaseAnimation) {
    builder = animationBuilder;
  }

  function build(...args: any[]): BaseAnimation {
    let animation = createDefault();
    if (builder) {
      try {
        const anim = builder(args);
        if (anim) animation = anim;
      } catch (e) {
        console.log(`Error createing animatiom:${e}`);
      }
    }

    return animation;
  }

  function dispose() {
    builder = undefined;
  }
  return {
    set,
    build,
    dispose
  };
});

type AnimationModelType = typeof AnimationBuilderMST.Type;
export interface AnimationBuilder extends AnimationModelType {}
