import { PlayerAction } from '../../../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.games.combinations.message.PlayerCombination';

export class PlayerCombination extends PlayerAction {
  answer?: Array<number>;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
