import { BaseMST } from './BaseModel';

export const VisibleMST = BaseMST.named('ShowableMST')
  .props({
    visible: true
  })
  .actions(self => ({
    setVisible(visible: boolean) {
      self.visible = visible;
    }
  }))
  .views(self => ({
    isVisible() {
      return self.visible;
    }
  }));

type VisibleModelType = typeof VisibleMST.Type;
export interface VisibleModel extends VisibleModelType {}
