import { PlayerAction } from '../../../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.player.store.message.SendSound';

export class SendSound extends PlayerAction {
  groupId: number = 0;

  // fieldType: int
  itemId: number = 0;

  // fieldType: int
  playerToSend: number = 0; // fieldType: long

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
