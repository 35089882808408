import { Animation, TimelineLite, TimelineMax } from 'gsap';
import GSAPAnimation from '../base/GSAPAnimation';

function createCountUp(target: HTMLElement | null, start: number, add: number, update: Function, decimalPlaces: number, prefix: string): Animation {
  const timeline = new TimelineLite({ paused: true });
  const counter = { var: start };
  const end = start + add;

  if (target)
    timeline.add(
      new TimelineMax().to(counter, 1, {
        var: end,
        onUpdate() {
          target.innerHTML = `${prefix} ${decimalPlaces > 0 ? counter.var.toFixed(decimalPlaces) : Math.ceil(counter.var)}`;
          update(counter.var);
        }
      }),
      0
    );

  return timeline;
}
export class CountUp extends GSAPAnimation {
  constructor(target: HTMLElement | null, start: number, add: number, decimalPlaces: number = 0, prefix: string = '') {
    super(createCountUp(target, start, add, () => {}, decimalPlaces, prefix));
  }
}

export class CountUpWithCallback extends GSAPAnimation {
  constructor(target: HTMLElement | null, start: number, add: number, update: Function, decimalPlaces: number = 0, prefix: string = '') {
    super(createCountUp(target, start, add, update, decimalPlaces, prefix));
  }
}
