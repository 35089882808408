import { Local } from 'common/src/lang/Local';
import { createComputerLetter, createOpponentLetter } from 'common/src/models/game/content/puzzle/LetterModel';
import { PuzzleModel } from 'common/src/models/game/content/puzzle/PuzzleModel';
import { GameInfoModel } from 'common/src/models/game/GameInfoModel';
import { Animation, Power1, TimelineLite, TimelineMax } from 'gsap';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { PulseLoader } from 'react-spinners';
import Images from 'web/src/resources/ImgResources';
import GSAPAnimation from '../../../../animations/base/GSAPAnimation';
import GSAPParallelAnimation from '../../../../animations/base/GSAPParallelAnimation';
import { GSAPPause } from '../../../../animations/base/GSAPPause';
import GSAPSequenceAnimation from '../../../../animations/base/GSAPSequenceAnimator';
import { Glow3D } from '../../../../animations/samples/Glow3D';
import { PointsAnimation } from '../../../../animations/samples/PointsAnimation';
import { ZoomIn } from '../../../../animations/samples/ZoomIn';
import { ZoomOut } from '../../../../animations/samples/ZoomOut';
import GameButton from '../../GameButton';
import { GameContent, GameContentProps } from '../../GameContent';
import { GameStatus } from '../../GameStatus';
import Point from '../../Point';
import ProfilePicture from '../../ProfilePicture';
import WordChecker from './WordChecker';

const LETTERS_COUNT = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

function createBadgeFlyIn(target: {} | null, duration: number = 0.3, repeat: number = 0): Animation {
  const timeline = new TimelineLite({ paused: true });

  if (target)
    timeline.add(
      new TimelineMax({ repeat }).fromTo(
        target,
        duration,
        {
          scaleX: 5,
          scaleY: 5,
          opacity: 0.8,
          transformOrigin: 'center',
          ease: Power1.easeOut
        },
        { scaleX: 1, scaleY: 1, opacity: 1, transformOrigin: 'center' }
      )
    );

  return timeline;
}
interface PuzzleProps extends GameContentProps {
  puzzle?: PuzzleModel;
  gameInfo?: GameInfoModel;
}
@inject('puzzle', 'gameInfo')
@observer
export default class Puzzle extends GameContent<PuzzleProps, {}> {
  letterRefs: Array<GameButton | null> = [];

  eraseButtonRef: HTMLButtonElement | null = null;

  eraseAllButtonRef: HTMLButtonElement | null = null;

  confirmButtonRef: HTMLButtonElement | null = null;

  opponentWordRef: HTMLDivElement | null = null;

  myPoint: Point | null = null;

  opponentPoint: Point | null = null;

  bonusPoint: Point | null = null;

  extraBonusPoint: Point | null = null;

  opponentBonusPoint: Point | null = null;

  opponentExtraBonusPoint: Point | null = null;

  computerWordRef: HTMLDivElement | null = null;

  myBonusBadgeRef: HTMLDivElement | null = null;

  myExtraBonusBadgeRef: HTMLDivElement | null = null;

  opponentBonusBadgeRef: HTMLDivElement | null = null;

  opponentExtraBonusBadgeRef: HTMLDivElement | null = null;

  timeoutId: NodeJS.Timeout | undefined = undefined;

  @observable
  isShuffling = false;

  textValues = ['Z', 'A', 'M', 'E', 'B', 'C', 'G', 'H', 'D', 'J', 'H', 'L'];

  @observable
  counter = 0;

  createInitialLetterAnimation = () => {
    const seq = new GSAPSequenceAnimation();
    this.letterRefs.forEach(x => {
      seq.add(new Glow3D(x!.boxRef, 0.075, 1));
    });
    return seq;
  };

  createHideBtnsOnConfirmAnimation = () => {
    const parallel: GSAPParallelAnimation = new GSAPParallelAnimation();
    if (this.confirmButtonRef !== null) parallel.add(new ZoomOut(this.confirmButtonRef, 0.3));
    if (this.eraseButtonRef !== null) parallel.add(new ZoomOut(this.eraseButtonRef, 0.3));
    parallel.addEndListener(() => {
      this.props.puzzle!.confirmBtn.setVisible(false);
      this.props.puzzle!.eraseLetter.setVisible(false);
    });

    return parallel;
  };

  createShowOpponentWordAnimation = () => {
    if (this.opponentWordRef === null) return new GSAPPause(0.05);

    const zoomIn = new ZoomIn(this.opponentWordRef, 0.3);
    zoomIn.addStartListener(() => {
      this.props.puzzle!.opponentWord.setVisible(true);
    });
    zoomIn.addEndListener(() => {
      this.props.puzzle!.opponentWord.setOpponentProgressShown(true);
    });

    return zoomIn;
  };

  createPointsAnimation = () => PointsAnimation.create(this.props.gameInfo!.isPlayingAsBlue, this.myPoint);

  createBonusAnimation = () => {
    const flyIn = new GSAPAnimation(createBadgeFlyIn(this.myBonusBadgeRef));
    const seq = new GSAPSequenceAnimation();
    seq.add(flyIn);
    seq.add(PointsAnimation.create(this.props.gameInfo!.isPlayingAsBlue, this.bonusPoint));
    return seq;
  };

  createExtraBonusAnimation = () => {
    const flyIn = new GSAPAnimation(createBadgeFlyIn(this.myExtraBonusBadgeRef));
    const seq = new GSAPSequenceAnimation();
    seq.add(flyIn);
    seq.add(PointsAnimation.create(this.props.gameInfo!.isPlayingAsBlue, this.extraBonusPoint));
    return seq;
  };

  createOpponentPointsAnimation = () => PointsAnimation.create(!this.props.gameInfo!.isPlayingAsBlue, this.opponentPoint);

  createOpponentBonusAnimation = () => {
    const flyIn = new GSAPAnimation(createBadgeFlyIn(this.opponentBonusBadgeRef));
    const seq = new GSAPSequenceAnimation();
    seq.add(flyIn);
    seq.add(PointsAnimation.create(!this.props.gameInfo!.isPlayingAsBlue, this.opponentBonusPoint));
    return seq;
  };

  createOpponentExtraBonusAnimation = () => {
    const flyIn = new GSAPAnimation(createBadgeFlyIn(this.opponentExtraBonusBadgeRef));
    const seq = new GSAPSequenceAnimation();
    seq.add(flyIn);
    seq.add(PointsAnimation.create(!this.props.gameInfo!.isPlayingAsBlue, this.opponentExtraBonusPoint));
    return seq;
  };

  createComputerWordShowAnimation = () => {
    const zoomIn: ZoomIn = new ZoomIn(this.computerWordRef, 0.3);
    zoomIn.addStartListener(() => {
      this.props.puzzle!.computerWord.setVisible(true);
    });
    return zoomIn;
  };

  createShufflingAnimation = () => {
    const pause = new GSAPPause(10000);
    pause.addStartListener(() => {
      this.isShuffling = true;
      this.startShuffler();
    });
    pause.addEndListener(() => {
      this.isShuffling = false;
    });
    return pause;
  };

  startShuffler() {
    this.timeoutId = setTimeout(() => {
      if (this.isShuffling === false) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
      } else {
        this.counter = (this.counter + 1) % (this.textValues.length - 1);
        this.startShuffler();
      }
    }, 50);
  }

  getTextValue(index: number): string {
    return this.textValues[(index + this.counter) % this.textValues.length];
  }

  createAnimations() {
    this.props.puzzle!.initalLettersAnimation.set(this.createInitialLetterAnimation);
    this.props.puzzle!.hideBtnsOnConfirmAnimation.set(this.createHideBtnsOnConfirmAnimation);
    this.props.puzzle!.showOpponentWordAnimation.set(this.createShowOpponentWordAnimation);
    this.props.puzzle!.myWord.pointsAnimation.set(this.createPointsAnimation);
    this.props.puzzle!.myWord.bonusAnimation.set(this.createBonusAnimation);
    this.props.puzzle!.myWord.extraBonusAnimation.set(this.createExtraBonusAnimation);
    this.props.puzzle!.opponentWord.pointsAnimation.set(this.createOpponentPointsAnimation);
    this.props.puzzle!.opponentWord.bonusAnimation.set(this.createOpponentBonusAnimation);
    this.props.puzzle!.opponentWord.extraBonusAnimation.set(this.createOpponentExtraBonusAnimation);
    this.props.puzzle!.showComputerWordAnimation.set(this.createComputerWordShowAnimation);
    this.props.puzzle!.enteringAnimation.set(this.createEnteringAnimation);
    this.props.puzzle!.exitingAnimation.set(this.createExitingAnimation);
    this.props.puzzle!.preparingAnimation.set(this.createPreparingAnimation);
    this.props.puzzle!.shufflingAnimation.set(this.createShufflingAnimation);
  }

  destroyAnimations() {
    this.props.puzzle!.initalLettersAnimation.dispose();
    this.props.puzzle!.hideBtnsOnConfirmAnimation.dispose();
    this.props.puzzle!.showOpponentWordAnimation.dispose();
    this.props.puzzle!.myWord.pointsAnimation.dispose();
    this.props.puzzle!.myWord.bonusAnimation.dispose();
    this.props.puzzle!.myWord.extraBonusAnimation.dispose();
    this.props.puzzle!.opponentWord.pointsAnimation.dispose();
    this.props.puzzle!.opponentWord.bonusAnimation.dispose();
    this.props.puzzle!.opponentWord.extraBonusAnimation.dispose();
    this.props.puzzle!.showComputerWordAnimation.dispose();
    this.props.puzzle!.enteringAnimation.dispose();
    this.props.puzzle!.exitingAnimation.dispose();
    this.props.puzzle!.preparingAnimation.dispose();
    this.props.puzzle!.shufflingAnimation.dispose();
  }

  componentDidMount() {
    this.createAnimations();
  }

  isVisible(): boolean {
    if (this.props.puzzle) return this.props.puzzle.isVisible();
    return false;
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  getDiv() {}

  renderVisible(): React.ReactNode {
    const { puzzle } = this.props;
    const t = (message: string) => Local.getString(message);
    if (!puzzle) {
      return null;
    }

    return (
      <div
        ref={contentRef => {
          this.contentRef = contentRef;
        }}
      >
        <GameStatus />
        <div className="puzzle game-prepared">
          <div className="puzzle__bonus_point_holder" style={this.props.puzzle!.myWord.bonusPoints > 0 ? { visibility: 'visible' } : { visibility: 'hidden' }}>
            <Point
              baseClass="puzzle__point puzzle__point--bonus-point"
              value={this.props.puzzle!.myWord.bonusPoints}
              ref={point => {
                this.bonusPoint = point;
              }}
            />
          </div>
          <div className="puzzle__bonus_point_holder" style={this.props.puzzle!.opponentWord.bonusPoints > 0 ? { visibility: 'visible' } : { visibility: 'hidden' }}>
            <Point
              baseClass="puzzle__point puzzle__point--opponent-bonus-point"
              value={this.props.puzzle!.opponentWord.bonusPoints}
              ref={point => {
                this.opponentBonusPoint = point;
              }}
            />
          </div>
          <div className="puzzle__bonus_point_holder" style={this.props.puzzle!.myWord.extraBonusPoints > 0 ? { visibility: 'visible' } : { visibility: 'hidden' }}>
            <Point
              baseClass="puzzle__point puzzle__point--extra-bonus-point"
              value={this.props.puzzle!.myWord.extraBonusPoints}
              ref={point => {
                this.extraBonusPoint = point;
              }}
            />
          </div>
          <div className="puzzle__bonus_point_holder" style={this.props.puzzle!.opponentWord.extraBonusPoints > 0 ? { visibility: 'visible' } : { visibility: 'hidden' }}>
            <Point
              baseClass="puzzle__point puzzle__point--extra-opponent-bonus-point"
              value={this.props.puzzle!.opponentWord.extraBonusPoints}
              ref={point => {
                this.opponentExtraBonusPoint = point;
              }}
            />
          </div>
          <div className="puzzle__available_letters_container">
            {LETTERS_COUNT.map((x, index) =>
              !this.isShuffling ? (
                <GameButton
                  key={x + index}
                  model={puzzle.letters[index]}
                  baseClass="puzzle__letter"
                  ref={letter => {
                    this.letterRefs[index] = letter;
                  }}
                />
              ) : (
                <div style={{ borderRadius: '0.4rem', boxShadow: 'rgba(255, 255, 255, 0) 0px 0px 0px 0px' }}>
                  <div className="puzzle__letter game-button">{this.getTextValue(index)}</div>
                </div>
              )
            )}
          </div>
          <div
            className="puzzle__choosen_letters_container"
            onMouseOver={() => {
              if (puzzle.myWord.letters.length > 0) puzzle.eraseAllLetters.setVisible(true);
            }}
            onFocus={() => {
              if (puzzle.myWord.letters.length > 0) puzzle.eraseAllLetters.setVisible(true);
            }}
            onMouseLeave={() => {
              puzzle.eraseAllLetters.setVisible(false);
            }}
          >
            <ProfilePicture fbId={this.props.gameInfo!.getMe().fbId} b2bUrl={this.props.puzzle!.myUrl} css="puzzle__player_image" size={50} />
            <button
              className="puzzle__erase-all-button"
              disabled={!puzzle.eraseAllLetters.isEnabled()}
              hidden={!puzzle.eraseAllLetters.isVisible()}
              onClick={() => {
                puzzle.eraseAllLetters.action.performAction();
              }}
              ref={buttRef => {
                this.eraseAllButtonRef = buttRef;
              }}
            />
            <div
              className={puzzle.myWord.letters.length === puzzle.opponentWord.letters.length ? 'puzzle__bonus puzzle__bonus--my-game' : 'puzzle__bonus puzzle__bonus--longer-word'}
              ref={div => {
                this.myBonusBadgeRef = div;
              }}
              style={this.props.puzzle!.myWord.bonusPoints > 0 ? { visibility: 'visible' } : { visibility: 'hidden' }}
            />
            <div
              className={this.props.puzzle!.opponentWord.extraBonusPoints > 3 ? 'puzzle__extra-bonus puzzle__extra-bonus--comp_longer' : 'puzzle__extra-bonus puzzle__extra-bonus--comp_same'}
              ref={div => {
                this.myExtraBonusBadgeRef = div;
              }}
              style={this.props.puzzle!.myWord.extraBonusPoints > 0 ? { visibility: 'visible' } : { visibility: 'hidden' }}
            />
            {puzzle.myWord.letters.map((value, index) => (
              <GameButton key={index} model={value} baseClass="puzzle__choosen_letter" />
            ))}
            <div>
              <Point
                baseClass="puzzle__point puzzle__point--my-point"
                value={this.props.puzzle!.myWord.points}
                ref={point => {
                  this.myPoint = point;
                }}
              />
            </div>
          </div>
          <button
            className="puzzle__erase-button"
            disabled={!puzzle.eraseLetter.isEnabled()}
            hidden={!puzzle.eraseLetter.isVisible()}
            onClick={() => {
              puzzle.eraseLetter.action.performAction();
            }}
            ref={buttRef => {
              this.eraseButtonRef = buttRef;
            }}
          />

          <div className="puzzle__word-status">
            <WordChecker wordCheck={puzzle.checker} />
          </div>
          <div>
            <button
              className="puzzle__submit_word_button"
              onClick={puzzle.confirmBtn.action.performAction}
              disabled={!puzzle.confirmBtn.isEnabled()}
              style={{ visibility: !puzzle.confirmBtn.isVisible() ? 'hidden' : 'visible' }}
              ref={buttRef => {
                this.confirmButtonRef = buttRef;
              }}
            >
              {Local.getString('common.confirm')}
            </button>
          </div>
          <div
            className="puzzle__choosen_letters_container"
            ref={div => {
              this.opponentWordRef = div;
            }}
            style={puzzle.opponentWord.isVisible() ? { visibility: 'visible' } : { visibility: 'hidden' }}
          >
            <ProfilePicture fbId={this.props.gameInfo!.getOpponent().fbId} b2bUrl={this.props.puzzle!.opponentUrl} css="puzzle__player_image" size={50} />
            <div
              className={puzzle.myWord.letters.length === puzzle.opponentWord.letters.length ? 'puzzle__bonus puzzle__bonus--my-game' : 'puzzle__bonus puzzle__bonus--longer-word'}
              ref={div => {
                this.opponentBonusBadgeRef = div;
              }}
              style={this.props.puzzle!.opponentWord.bonusPoints > 0 ? { visibility: 'visible' } : { visibility: 'hidden' }}
            />
            <div
              className={this.props.puzzle!.opponentWord.extraBonusPoints > 3 ? 'puzzle__extra-bonus puzzle__extra-bonus--comp_longer' : 'puzzle__extra-bonus puzzle__extra-bonus--comp_same'}
              ref={div => {
                this.opponentExtraBonusBadgeRef = div;
              }}
              style={this.props.puzzle!.opponentWord.extraBonusPoints > 0 ? { visibility: 'visible' } : { visibility: 'hidden' }}
            />
            {puzzle.opponentWord.letters.map((x, index) => (
              <GameButton key={index} model={createOpponentLetter(x, this.props.gameInfo!.isPlayingAsBlue)} baseClass="puzzle__choosen_letter" />
            ))}
            <div className="puzzle__appeal_opponent_word" onClick={() => this.props.puzzle!.appealWord.performAction()} style={this.props.puzzle!.opponentWord.isCorrect() ? { visibility: 'visible' } : { visibility: 'hidden' }}>
              {Local.getString(this.props.puzzle!.opponentWord.hasAppealed ? 'puzzle.appeal_confirm' : 'puzzle.appeal_word')}
            </div>
            <div>
              <Point
                baseClass="puzzle__point puzzle__point--opponent-point"
                value={this.props.puzzle!.opponentWord.points}
                ref={opponentPoint => {
                  this.opponentPoint = opponentPoint;
                }}
              />
            </div>
          </div>

          <div
            className="puzzle__choosen_letters_container_computer"
            ref={div => {
              this.computerWordRef = div;
            }}
            style={puzzle.computerWord.isVisible() ? { visibility: 'visible' } : { visibility: 'hidden' }}
          >
            <img src={Images.maxWord} className="puzzle__player_image" />
            {puzzle.computerWord.letters.map((x, index) => (
              <GameButton key={index} model={createComputerLetter(x)} baseClass="puzzle__choosen_letter puzzle__choosen_letter-computer" />
            ))}
          </div>
        </div>
        <div>
          <button className="puzzle__stop_button" onClick={puzzle.stopBtn.action.performAction} disabled={!puzzle.stopBtn.isEnabled()} style={{ visibility: !puzzle.stopBtn.isVisible() ? 'hidden' : 'visible' }}>
            {Local.getString('common.stop')}
            <img className="puzzle__stop_button-icon" src={Images.handStop} />
          </button>
        </div>
        <div className="puzzle__opponent_word_solving_progress">
          {puzzle!.opponentWord.solving && puzzle.opponentWord.opponentProgressShown && (
            <div className="puzzle__opponent_word_solving_text">
              <PulseLoader
                color="#fede04"
                sizeUnit="em"
                size={0.6}
                css="
           padding-right: 0.8rem;
           padding-left: 10rem;
           padding-top: 0.2rem;
           display: inline-block;
           transform: scale(1, 1);
         "
              />
              {t('common.opp_solving')}
            </div>
          )}
        </div>
      </div>
    );
  }
}
