import { B2bTeamModel } from 'common/src/models/b2b/B2bTeamModel';
import { UserModel } from 'common/src/models/user/UserModel';
import React, { Component } from 'react';

interface B2bTeamTooltipProps {
  team?: B2bTeamModel;
  toolTipPositionX?: number;
  toolTipPositionY?: number;
}
export default class B2bTeamTooltip extends Component<B2bTeamTooltipProps> {
  render() {
    const { toolTipPositionX, toolTipPositionY, team } = this.props;

    return (
      <div className="b2b-team__tooltip" style={{ left: `${toolTipPositionX}%`, top: `${toolTipPositionY}%` }}>
        <h2 className="b2b-team__tooltip-name">{team!.name}</h2>
        {team!.teamMembers
          .filter((u: UserModel) => u.info!.playerId! > 0 && u.info!.b2bName && u.info!.b2bName !== '')
          .map((u: UserModel, index) => (
            <div key={index} className="b2b-team__players">
              {u.info.b2bName ? `${++index}. ${u.info.b2bName}` : ''}
            </div>
          ))}
      </div>
    );
  }
}
