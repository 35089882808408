import { ServerEvent } from '../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.player.message.PaymentGateway';

const ProviderNone = 0; // fieldType: int
const ProviderFortumo = 1; // fieldType: int
const ProviderEds = 2; // fieldType: int
const ProviderNTH = 3; // fieldType: int

export class PaymentGateway extends ServerEvent {
  bestOfferIndex: number = 0;

  // fieldType: int
  providerStart: number = 0;

  // fieldType: int
  keywords?: Array<string> = undefined;

  // fieldType: String[]
  numbers?: Array<string> = undefined;

  // fieldType: String[]
  tokens?: Array<string> = undefined;

  // fieldType: String[]
  prices?: Array<string> = undefined; // fieldType: String[]

  voucherEnabled: boolean = false;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get ProviderNone() {
    return ProviderNone;
  }

  static get ProviderFortumo() {
    return ProviderFortumo;
  }

  static get ProviderEds() {
    return ProviderEds;
  }

  static get ProviderNTH() {
    return ProviderNTH;
  }
}
