import { Power1, TimelineLite, TimelineMax } from 'gsap';
import GSAPAnimation from '../base/GSAPAnimation';

function createZoomInAndOut(target: {} | null, duration: number = 0.2, repeatCount: number = 0) {
  const timeline = new TimelineLite({ paused: true });

  if (target) {
    for (let i = 0; i <= repeatCount; i++) {
      timeline.add(
        new TimelineMax().fromTo(
          target,
          duration,
          {
            scaleX: 1,
            scaleY: 1,
            transformOrigin: 'center',
            ease: Power1.easeIn
          },
          { scaleX: 1.2, scaleY: 1.2, transformOrigin: 'center' }
        )
      );
      timeline.add(
        new TimelineMax().fromTo(
          target,
          duration,
          {
            scaleX: 1.2,
            scaleY: 1.2,
            transformOrigin: 'center',
            ease: Power1.easeIn
          },
          { scaleX: 1, scaleY: 1, transformOrigin: 'center' }
        )
      );
    }
  }

  return timeline;
}

export class ZoomInAndOut extends GSAPAnimation {
  constructor(target: {} | null, duration: number, repeatCount: number = 0) {
    super(createZoomInAndOut(target, duration, repeatCount));
  }
}
