import { MessageSerializable } from '../../MessageSerializable';
import { Offer } from './Offer';

const BINDING_NAME = 'rs.slagalica.player.store.ItemDesc';

const GoldenOnlyPurchase = 1; // fieldType: int
const SilverPriemptivePurchase = 0; // fieldType: int

export class ItemDesc extends MessageSerializable {
  groupId: number = 0;

  // fieldType: int
  itemId: number = 0;

  // fieldType: int
  countMax: number = 0;

  // fieldType: int
  cost: number = 0;

  // fieldType: int
  available: number = 0;

  // fieldType: int
  purchaseType: number = 0;

  // fieldType: int
  clusterId: number = 0;

  // fieldType: long
  offers?: Array<Offer> = undefined; // fieldType: ArrayList

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get GoldenOnlyPurchase() {
    return GoldenOnlyPurchase;
  }

  static get SilverPriemptivePurchase() {
    return SilverPriemptivePurchase;
  }
}
