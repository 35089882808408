import React, { Component } from 'react';
import { Local } from 'common/src/lang/Local';
import { ButtonModel } from 'common/src/models/ButtonModel';

interface SkipButtonProps {
  baseClass: string;
  model: ButtonModel;
}

export default class SkipButton extends Component<SkipButtonProps, {}> {
  render() {
    const { baseClass } = this.props;

    return (
      <div className={baseClass}>
        <div
          className="quiz__skip-text"
          onClick={() => {
            this.props.model.action.performAction();
          }}
        >
          {Local.getString('association.button_skip_turn')}
        </div>
      </div>
    );
  }
}
