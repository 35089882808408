import { types } from 'mobx-state-tree';
import { ActionMST } from '../../ActionModel';
import { VisibleMST } from '../../VisableModel';

export const GameAcceptanceHolderMST = VisibleMST.named('GameAcceptanceHolderMST')
  .props({
    acceptAction: types.optional(ActionMST, {}),
    declineAction: types.optional(ActionMST, {})
  })
  .views(self => ({}))
  .actions(self => ({}));

type GameAcceptanceHolderModelType = typeof GameAcceptanceHolderMST.Type;

export interface GameAcceptanceHolderModel extends GameAcceptanceHolderModelType {}
