import { MessageSerializable } from '../../../MessageSerializable';
import { Cycle } from './Cycle';

const BINDING_NAME = 'rs.slagalica.player.history.message.LeagueHistory';

export class LeagueHistory extends MessageSerializable {
  cycle?: Cycle = undefined;

  // fieldType: Cycle
  position1: number = 0;

  // fieldType: int
  position2: number = 0;

  // fieldType: int
  position3: number = 0;

  // fieldType: int
  positionCount?: Array<number> = undefined; // fieldType: ArrayList

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
