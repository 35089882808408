import { ServerEvent } from '../../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.games.combinations.message.CombinationsStatus';

export class CombinationsStatus extends ServerEvent {
  correctCombination?: Array<number>;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
