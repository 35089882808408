import { MessageSerializable } from '../../MessageSerializable';

const BINDING_NAME = 'rs.slagalica.player.message.SpecialOffer';

export const TypeTokens = 0;
export class SpecialOffer extends MessageSerializable {
  type: number = 0;

  // fieldType: int
  shortKey?: string = undefined;

  // fieldType: String
  phoneNumber?: string = undefined;

  // fieldType: String
  country?: string = undefined;

  // fieldType: String
  title?: string = undefined;

  // fieldType: String

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get TypeTokens() {
    return TypeTokens;
  }
}
