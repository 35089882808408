import { ServerEvent } from '../../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.games.matching.message.MatchingFinal';

export class MatchingFinal extends ServerEvent {
  mapping?: Array<number> = undefined;

  // TODO remove
  solved: number = 0;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
