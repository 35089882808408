import { ServerEvent } from '../../communication/message/ServerEvent';
import { SingleGameResult } from './SingleGameResult';

const BINDING_NAME = 'rs.slagalica.player.message.GameSequenceFinished';

export class GameSequenceFinished extends ServerEvent {
  gamesResults?: Array<SingleGameResult> = undefined;

  // fieldType: ArrayList
  winner: boolean = false;

  isWinner: boolean = false;

  // fieldType: boolean
  equalScore: boolean = false;

  // fieldType: boolean
  winPoints: number = 0;

  // fieldType: int
  winRanklistPoints: number = 0;

  // fieldType: int
  winRankingPoints: number = 0;

  // fieldType: int
  rank: number = 0;

  // fieldType: int
  totalRankingPoints: number = 0;

  // fieldType: int
  rankChanged: boolean = false;

  // fieldType: boolean
  wonSilverTokens: number = 0;

  // fieldType: int
  wonGoldTokens: number = 0;

  // fieldType: int
  ranklistPointsBonus: number = 0;

  // fieldType: int
  consecutiveWinPercente: number = 0;

  // fieldType: int
  consecutiveWins: number = 0;

  // fieldType: int
  dailyHighscore: number = 0;

  // fieldType: int
  boosterChange: number = 0.0;

  // fieldType: double
  boosterBase: number = 0.0;

  // fieldType: double
  friendltMatch: boolean = false; // fieldType: boolean

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
