import { types } from 'mobx-state-tree';
import { LeagueTopPositioned } from '../../../messages/player/league/message/LeagueTopPositioned';
import { PreviousLeagueInfo } from '../../../messages/player/league/message/PreviousLeagueInfo';
import { Reward } from '../../../messages/player/reward/message/Reward';
import { ActionMST } from '../../ActionModel';
import { AnimationBuilderMST } from '../../AnimationBuilder';
import { ButtonMST } from '../../ButtonModel';
import { createRewardItemModel, RewardItemModel, RewardItemMST } from '../../common/RewardItemModel';
import { DisplayMST } from '../../DisplayModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';
import { createTournamentResultItemModel, TournamentResultItemMST } from './TournamentResultItemModel';

export const TournamentResultMST = DisplayMST.named('TournamentResultMST')
  .props({
    rewards: types.array(RewardItemMST),
    tournamentResult: types.array(TournamentResultItemMST),
    claimRewardAnimation: AnimationBuilderMST,
    claimRewardAction: ActionMST,
    claimButton: ButtonMST,
    myPosition: 0
  })
  .actions(self => ({
    addTournamentPlayer(position: number, name: string, fbid: string, feathers: number) {
      self.tournamentResult.push(createTournamentResultItemModel(position, name, fbid, feathers));
    },
    setTournamentResult(previousLeagueInfo: PreviousLeagueInfo) {
      self.tournamentResult.clear();
      self.rewards.clear();
      if (previousLeagueInfo) {
        if (previousLeagueInfo.rewards) {
          previousLeagueInfo.rewards.forEach((reward: Reward) => {
            self.rewards.push(createRewardItemModel(reward.type, reward.count));
          });
          self.rewards.replace(self.rewards.slice().sort((rewardA: RewardItemModel, rewardB: RewardItemModel) => rewardA.getPosition() - rewardB.getPosition()));
        }

        if (previousLeagueInfo.leagueTopPositions) {
          let leaguePositions = previousLeagueInfo.leagueTopPositions!.slice().sort((topPositionedA: LeagueTopPositioned, topPositionedB: LeagueTopPositioned) => topPositionedA.position - topPositionedB.position);

          if (previousLeagueInfo.position > 4) {
            let myPosition;
            leaguePositions.forEach((leagueTopPositioned: LeagueTopPositioned) => {
              if (leagueTopPositioned.position === previousLeagueInfo.position) myPosition = leagueTopPositioned;
            });
            if (myPosition) leaguePositions[3] = myPosition;
          }
          leaguePositions = leaguePositions.slice(0, 4);
          leaguePositions.forEach((leagueTopPositioned: LeagueTopPositioned) => {
            this.addTournamentPlayer(leagueTopPositioned.position, leagueTopPositioned.playerInfo!.name, leagueTopPositioned.playerInfo!.facebookId ? leagueTopPositioned.playerInfo!.facebookId! : '', leagueTopPositioned.feathers);
          });
        }
      }
      self.myPosition = previousLeagueInfo.position;
    },
    setClaimAction(action: Function) {
      self.claimButton.action.setAction(action);
    },
    claimReward() {
      self.claimRewardAnimation.build().play();
    }
  }))
  .views(self => ({
    hasRewards() {
      return self.rewards.length > 0;
    }
  }));

type TournamentResultModelTType = typeof TournamentResultMST.Type;
export interface TournamentResultModel extends TournamentResultModelTType {}

export const createTournamentResultModel = () =>
  TournamentResultMST.create({
    key: DisplayModelKeys.TournamentResult,
    rewards: [
      {
        id: Reward.TYPE_RATING_POINTS,
        count: 100
      },
      {
        id: Reward.TYPE_TOKENS,
        count: 90
      }
    ],
    tournamentResult: [
      {
        position: 1,
        name: 'Filip',
        fbid: '67563683055',
        feathers: 5
      },
      {
        position: 2,
        name: 'Marko',
        fbid: '67563683055',
        feathers: 4
      },
      {
        position: 3,
        name: 'Marko',
        fbid: '67563683055',
        feathers: 3
      },
      {
        position: 4,
        name: 'Marko',
        fbid: '67563683055',
        feathers: 3
      }
    ],
    claimRewardAnimation: {},
    claimRewardAction: {},
    claimButton: {},
    myPosition: 0
  });
