import { B2bTeam } from '../../b2b/B2bTeam';
import { PlayerInfo } from './PlayerInfo';

const BINDING_NAME = 'rs.slagalica.player.message.GameSequenceStarted';

const FRIEND_GAME = 0; // fieldType: int
const RANDOM_GAME = 1; // fieldType: int
const GAMBLING_GAME = 2; // fieldType: int
const TOURNAMENT_GAME = 3; // fieldType: int
const B2B_GAME = 4; // fieldType: int

export class GameSequenceStarted extends PlayerInfo {
  isPlayingFirst?: boolean = undefined;

  myTeam?: B2bTeam;

  opponentTeam?: B2bTeam;

  // fieldType: Boolean
  gameType: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get FRIEND_GAME() {
    return FRIEND_GAME;
  }

  static get RANDOM_GAME() {
    return RANDOM_GAME;
  }

  static get GAMBLING_GAME() {
    return GAMBLING_GAME;
  }

  static get TOURNAMENT_GAME() {
    return TOURNAMENT_GAME;
  }

  static get B2B_GAME() {
    return B2B_GAME;
  }
}
