import { DisplayModel } from '../../models/DisplayModel';
import { DialogManager } from './DialogManager';
import { DisplayConfig } from './DisplayConfig';
import { DIALOG, DisplayType, SCREEN } from './DisplayType';
import { ScreenManager } from './ScreenManager';

export class DisplayManager {
  // dilaogs

  private dialogManager: DialogManager;

  private screenManager: ScreenManager;

  private displayConfig: DisplayConfig;

  constructor(displayConfig: DisplayConfig, dialogManager: DialogManager, screenManager: ScreenManager) {
    this.dialogManager = dialogManager;
    this.screenManager = screenManager;
    this.displayConfig = displayConfig;
  }

  private getDisplayType(displayModel: DisplayModel): DisplayType {
    return this.displayConfig.getType(displayModel);
  }

  onSchedule(displayModel: DisplayModel) {
    if (this.getDisplayType(displayModel) === DIALOG) this.dialogManager.onSchedule(displayModel);
    else if (this.getDisplayType(displayModel) === SCREEN) this.screenManager.onSchedule(displayModel);
  }

  onShow(displayModel: DisplayModel) {
    if (this.getDisplayType(displayModel) === DIALOG) this.dialogManager.onShow(displayModel);
    else if (this.getDisplayType(displayModel) === SCREEN) this.screenManager.onShow(displayModel);
  }

  onHide(displayModel: DisplayModel) {
    if (this.getDisplayType(displayModel) === DIALOG) this.dialogManager.onHide(displayModel);
    else if (this.getDisplayType(displayModel) === SCREEN) this.screenManager.onHide(displayModel);
  }
}
