import { ServerEvent } from '../../../communication/message/ServerEvent';
import { AchievementTargetValue } from './AchievementTargetValue';

const BINDING_NAME = 'rs.slagalica.player.achievements.message.AchievementTargetValuesCollection';

export class AchievementTargetValuesCollection extends ServerEvent {
  values?: Array<AchievementTargetValue> = undefined;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
