import { MessageSerializable } from '../../MessageSerializable';

const BINDING_NAME = 'rs.slagalica.player.message.RatingListPosition';

export class RatingListPosition extends MessageSerializable {
  position: number = 0;

  // fieldType: int
  raiting: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
