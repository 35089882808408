import { RootStore } from '../../RootStore';
import { DisplayMST } from '../DisplayModel';
import { DisplayModelKeys } from '../DisplayModelKeys';
import { GameContentModel } from './content/GameContentModel';

export const ScreenPuzzleBlue = 1;
export const ScreenPuzzleRed = 2;
export const ScreenMyNumberBlue = 3;
export const ScreenMyNumberRed = 4;
export const ScreenCombinationsBlue = 5;
export const ScreenCombinationsRed = 6;
export const ScreenQuiz = 7;
export const ScreenPairsBlue = 8;
export const ScreenPairsRed = 9;
export const ScreenAssociationBlue = 10;
export const ScreenAssociationRed = 11;

export const GameMST = DisplayMST.named('GameScreenMST')
  .props({})
  .actions(self => {
    let currentContent: GameContentModel | undefined;
    const gameContents: Array<GameContentModel> = [];

    function showGameContent(content: GameContentModel) {
      if (!self.visible) self.show();

      if (currentContent !== undefined) currentContent.setVisible(false);
      currentContent = content;
      currentContent.setVisible(true);
    }

    function setContents(rootStore: RootStore) {
      gameContents.push(rootStore.puzzle);
      gameContents.push(rootStore.myNumber);
      gameContents.push(rootStore.combinations);
      // gameContents.push(rootStore.matching);
      gameContents.push(rootStore.quiz);
      gameContents.push(rootStore.pairs);
      gameContents.push(rootStore.association);
      // gameContents.push(rootStore.captain);
      // gameContents.push(rootStore.noCaptain);
    }

    function resetGameModels() {
      gameContents.forEach((gameModel: GameContentModel) => {
        gameModel.resetModel();
      });
    }

    function getCurrentContent() {
      return currentContent;
    }

    return {
      showGameContent,
      setContents,
      resetGameModels,
      getCurrentContent
    };
  })
  .actions(self => ({
    // addGameContentModel(content: GameContentModel) {
    //   self.gameContents.push(content);
    // },
  }))
  .views(self => ({}));

type GameModelType = typeof GameMST.Type;
export interface GameModel extends GameModelType {}

export const createGameModel = () =>
  GameMST.create({
    key: DisplayModelKeys.Game,
    visible: false
  });
