/* eslint-disable no-named-as-default */
import { inject, observer } from 'mobx-react';
import React from 'react';
import CallObjectContext from '../../CallObjectContext';
import { BaseScreen, BaseScreenProps } from '../../components/BaseScreen';
import { CollaborationModel } from '../model/CollaborationModel';
import { CollaborationStatusModel } from '../model/CollaborationStatusModel';
import { DeviceModel } from '../model/DeviceModel';
import ArenaChat from './ArenaChat/ArenaChat';
import Call from './Call/Call';

interface CollaborationProps extends BaseScreenProps {
  collaboration?: CollaborationModel | undefined;
  collaborationStatus?: CollaborationStatusModel | undefined;
}

@inject('collaboration', 'collaborationStatus')
@observer
export default class CollaborationScreen<P> extends BaseScreen<CollaborationProps, {}> {
  isVisible(): boolean {
    return this.props.collaboration!.isVisible();
  }

  onMicCamError() {
    this.props.collaborationStatus!.setCamMicError(true);
  }

  onCameraOkEvent() {
    this.props.collaborationStatus!.setCamMicError(false);
  }

  onDevicesChanged(devices: any) {
    console.log(devices);
    this.props.collaboration!.setCamera(devices.camera);
    this.props.collaboration!.setMic(devices.mic);
    this.props.collaboration!.setSpeaker(devices.speaker);
  }

  isMyTeamActive(): boolean {
    return this.props.collaboration!.isMyTeamChatActive;
  }

  setMyTeamChat() {
    this.props.collaboration!.setMyTeamChatActive(true);
  }

  setGlobalChat() {
    this.props.collaboration!.setMyTeamChatActive(false);
  }

  getAllDevices() {
    const devices: any[] = [];
    this.props.collaboration!.allDevices.forEach((device: DeviceModel) => {
      const dev: any = { deviceId: device.deviceId, groupId: device.groupId, kind: device.kind, label: device.label };
      devices.push(dev);
    });
    return devices;
  }

  getDevice(device: DeviceModel) {
    console.log(device);
    return { deviceId: device.deviceId, groupId: device.deviceId, kind: device.kind, label: device.label };
  }

  renderVisible() {
    const { collaboration } = this.props;

    return (
      <CallObjectContext.Provider value={collaboration!.callObject}>
        <div className="collaboration">
          <Call
            devices={this.getAllDevices()}
            camera={this.getDevice(collaboration!.camera)}
            mic={this.getDevice(collaboration!.mic)}
            speaker={this.getDevice(collaboration!.speaker)}
            onCameraOkEvent={() => {
              this.onCameraOkEvent();
            }}
            onCamMicError={() => {
              this.onMicCamError();
            }}
            onDevicesChanged={(devices: any) => {
              this.onDevicesChanged(devices);
            }}
          />

          <div className="collaboration__chat-header">
            <div className="collaboration__hide-picture" />
            <div
              onClick={() => {
                this.setMyTeamChat();
              }}
              className={`collaboration__chat-header-btn${this.isMyTeamActive() ? ' collaboration__chat-header-btn--active' : ''}`}
            >
              MOJ TIM
            </div>
            <div
              onClick={() => {
                this.setGlobalChat();
              }}
              className={`collaboration__chat-header-btn${!this.isMyTeamActive() ? ' collaboration__chat-header-btn--active' : ''}`}
            >
              SVI
            </div>
          </div>
          <ArenaChat global />
        </div>
      </CallObjectContext.Provider>
    );
  }
}
