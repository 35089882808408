import { ServerEvent } from '../../../communication/message/ServerEvent';
import { Reward } from '../../reward/message/Reward';

const BINDING_NAME = 'rs.slagalica.player.achievements.message.AchievementReward';

export class AchievementReward extends ServerEvent {
  achievementId: number = 0;

  // fieldType: int
  level: number = 0;

  // fieldType: int
  awards?: Array<Reward> = undefined; // fieldType: ArrayList

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
