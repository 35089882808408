import { types } from 'mobx-state-tree';
import BaseAnimation from '../../animations/BaseAnimation';
import { UserStats } from '../../messages/player/stats/UserStats';
import { AnimationBuilderMST } from '../AnimationBuilder';
import { BaseMST } from '../BaseModel';
import { createStatisticsModel, StatisticsMST } from '../dialogs/statistics/StatisticsModel';

export const PlayerInfoSemaphoreMST = BaseMST.named('PlayerInfoSemaphoreMST')
  .props({
    name: 'Unknown',
    nameShort: 'Unknown',
    fbId: '0',
    points: 0,
    hatId: -1,
    rating: 0,
    rank: 0,
    feathers: 0,
    addPointsAnimation: types.optional(AnimationBuilderMST, {}),
    addPointsWithInitalAnimation: types.optional(AnimationBuilderMST, {}),
    isMe: false,
    stats: StatisticsMST,
    maxPrevRank: 0,
    offline: false,
    b2bUrl: ''
  })
  .views(self => ({}))
  .actions(self => ({
    init(name: string, nameShort: string, rank: number, fbId: string, isMe: boolean, rating: number, feathers: number, stats: UserStats | undefined, maxPrevRank: number) {
      self.name = name;
      self.nameShort = nameShort;
      self.rank = rank;
      self.fbId = fbId;
      self.isMe = isMe;
      self.rating = rating;
      self.feathers = feathers || 0;
      if (stats) self.stats.setStatistics(stats);
      self.maxPrevRank = maxPrevRank;
      self.offline = false;
    },
    setPoints(points: number) {
      self.points = points;
    },
    addPoints(points: number): BaseAnimation {
      const anim = self.addPointsAnimation.build(points);
      anim.addEndListener(() => {
        this.setPoints(self.points + points);
      });
      return anim;
    },
    addPointsWithInital(startPoints: number, points: number): BaseAnimation {
      const anim = self.addPointsWithInitalAnimation.build(startPoints, points);
      anim.addEndListener(() => {
        this.setPoints(startPoints + points);
      });
      return anim;
    },
    setOffline(isOffline: boolean) {
      self.offline = isOffline;
    },
    setName(n: string) {
      self.name = n;
    },
    setB2bUrl(b: string) {
      self.b2bUrl = b;
    }
  }));

type PlayerInfoSemaphorType = typeof PlayerInfoSemaphoreMST.Type;
export interface PlayerInfoSemaphorModel extends PlayerInfoSemaphorType {}
export const createPlayerInfoSemaphoreModel = () => types.optional(PlayerInfoSemaphoreMST, { name: 'Player Blue', fbId: '0', points: 0, feathers: 0, rating: 0, rank: 0, hatId: -1, stats: createStatisticsModel(), maxPrevRank: 0, offline: false });
