import { APP } from 'common/src/app/AppInstance';
import { LoginAccount } from 'common/src/login/LoginAccount';
import { LoginManager } from 'common/src/login/LoginManager';
import { B2bLoginModel } from 'common/src/models/dialogs/login/B2bLoginModel';

export class B2bLoginManager implements LoginManager {
  private currentAccount: LoginAccount;

  private b2bLoginModel: B2bLoginModel;

  constructor(b2bLoginModel: B2bLoginModel, account: LoginAccount) {
    this.b2bLoginModel = b2bLoginModel;
    this.currentAccount = account;
  }

  setLoginCallbacks(onLoginSuccess: Function, onLoginFailed: Function): void {
    if (this.currentAccount) {
      this.currentAccount.onLoginSuccess = onLoginSuccess;
      this.currentAccount.onLoginFailed = onLoginFailed;
    }
  }

  getCurrentAccount(): LoginAccount {
    return this.currentAccount;
  }

  tryAutoLogin(currentAccountType: number) {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('tournament')) this.b2bLoginModel.setTournament(urlParams.get('tournament') || '');
    if (urlParams.get('team')) this.b2bLoginModel.setTeam(urlParams.get('team') || '');
    if (urlParams.get('name')) this.b2bLoginModel.setName(urlParams.get('name') || '');
    if (urlParams.get('id')) this.b2bLoginModel.setId(urlParams.get('id') || '');
    this.b2bLoginModel.setMd5(urlParams.get('md5') || '');

    if (
      this.b2bLoginModel.tournament === APP.Settings.getB2bTournament() &&
      this.b2bLoginModel.team === APP.Settings.getB2bTeam() &&
      APP.Settings.getB2bId() !== '' &&
      APP.Settings.getB2bName() !== '' &&
      !urlParams.get('id') &&
      !urlParams.get('name') && // to make it easier for admins
      APP.Settings.getB2bAutologin()
    ) {
      this.b2bLoginModel.setName(APP.Settings.getB2bName());
      this.b2bLoginModel.setId(APP.Settings.getB2bId());
      this.currentAccount.login();
    } else {
      this.b2bLoginModel.submitAction.setAction(() => {
        if (this.currentAccount) {
          APP.Settings.setB2bTournament(this.b2bLoginModel.tournament);
          APP.Settings.setB2bTeam(this.b2bLoginModel.team);
          APP.Settings.setB2bId(this.b2bLoginModel.id);
          APP.Settings.setB2bName(this.b2bLoginModel.name);
          APP.Settings.setB2bAutologin(true);
          this.currentAccount.login();
          this.b2bLoginModel.hide();
        }
      });
      this.b2bLoginModel.show();
    }
  }

  setCurrentAccount(account: LoginAccount) {
    this.currentAccount = account;
  }

  loginUsingFacebook(): void {
    //
  }

  loginAsGuest(): void {
    //
  }

  loginUsingApple(): void {
    //
  }

  logOut(): void {
    // TODO
  }
}
