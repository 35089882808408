import { types } from 'mobx-state-tree';
import { Sounds } from 'web/src/resources/SoundResources';
import App from 'web/src/App';
import { ActionMST } from '../../ActionModel';
import { AnimationBuilderMST } from '../../AnimationBuilder';
import { VisibleMST } from '../../VisableModel';
import { ChatMessageModel, ChatMessageMST, createChatMessageModel } from './ChatMessageModel';
import { ShopConst } from '../../../controllers/player/subcontrollers/StorageController';
import { ChatTypes } from './ChatTypes';
import { APP } from '../../../app/AppInstance';

const TEXT_MESSAGE_PACKS = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [13, 14, 15, 16, 17],
  [17, 18, 19, 20, 21, 22, 23]
];

const STICKER_PACKS = [
  [0, 1, 2, 3, 4, 5],
  [6, 7, 8, 9, 10, 11]
];

export const ChatMST = VisibleMST.named('ChatMST')
  .props({
    typeEmpty: ChatMessageMST,
    typeMessage: types.array(ChatMessageMST),
    typeEmotions: types.array(ChatMessageMST),
    typeStickers: types.array(ChatMessageMST),
    typeSounds: types.array(ChatMessageMST),
    selectedTypeMy: true,
    currentMessage: types.maybeNull(ChatMessageMST),
    action: ActionMST,
    showChatTypeAnimation: AnimationBuilderMST,
    getMoreAction: ActionMST
  })
  .views(self => ({
    get selectedMessage() {
      return self.currentMessage !== null ? self.currentMessage : undefined;
    }
  }))
  .actions(self => ({
    init(action: Function) {
      self.action.setAction(action);
    },
    setShopAction(action: Function) {
      self.getMoreAction.setAction(action);
    },
    setChatItemAvailable(groupId: number, itemId: number) {
      if (groupId === ShopConst.TextMessages) {
        self.typeMessage.forEach((item: ChatMessageModel) => {
          if (TEXT_MESSAGE_PACKS[itemId].includes(item.id)) item.setAvailable(true);
        });
      } else if (groupId === ShopConst.Emoticons) {
        self.typeEmotions.forEach((item: ChatMessageModel) => {
          item.setAvailable(true);
        });
      } else if (groupId === ShopConst.Stickers) {
        self.typeStickers.forEach((item: ChatMessageModel) => {
          if (STICKER_PACKS[itemId].includes(item.id)) item.setAvailable(true);
        });
      } else if (groupId === ShopConst.Sounds) {
        self.typeSounds.forEach((item: ChatMessageModel) => {
          if (item.id === itemId) item.setAvailable(true);
        });
      }
    },
    onMessageSelected(chatMessage: ChatMessageModel) {
      if (!chatMessage.isAvailable()) return;

      self.currentMessage = createChatMessageModel(chatMessage.id, chatMessage.type);
      self.selectedTypeMy = true;

      const anim = self.showChatTypeAnimation.build();
      if (chatMessage.type === ChatTypes.SOUND) {
        APP.SoundPlayer.playEffectSound(chatMessage.id, 0.5);
      }
      anim.addEndListener(() => {
        if (self.currentMessage) self.currentMessage.clearType();
      });
      anim.play();
      self.action.performAction();
    },
    onMessageReceived(type: number, id: number) {
      self.currentMessage = createChatMessageModel(id, type);
      self.selectedTypeMy = false;

      const anim = self.showChatTypeAnimation.build();
      if (type === ChatTypes.SOUND) APP.SoundPlayer.playEffectSound(id, 0.5);
      else APP.SoundPlayer.playMessageSound(0.5);
      anim.addEndListener(() => {
        if (self.currentMessage) self.currentMessage.clearType();
      });
      anim.play();
    }
  }));

type ChatModelType = typeof ChatMST.Type;
export interface ChatModel extends ChatModelType {}

export const createChatModel = () =>
  ChatMST.create({
    // name: DialogNames.Chat,
    typeEmpty: createChatMessageModel(0, ChatTypes.EMPTY),
    typeMessage: [
      createChatMessageModel(0, ChatTypes.MESSAGE),
      createChatMessageModel(1, ChatTypes.MESSAGE),
      createChatMessageModel(2, ChatTypes.MESSAGE),
      createChatMessageModel(3, ChatTypes.MESSAGE),
      createChatMessageModel(4, ChatTypes.MESSAGE),
      createChatMessageModel(5, ChatTypes.MESSAGE),
      createChatMessageModel(6, ChatTypes.MESSAGE),
      createChatMessageModel(7, ChatTypes.MESSAGE),
      createChatMessageModel(8, ChatTypes.MESSAGE),
      createChatMessageModel(9, ChatTypes.MESSAGE),
      createChatMessageModel(10, ChatTypes.MESSAGE),
      createChatMessageModel(11, ChatTypes.MESSAGE),
      createChatMessageModel(12, ChatTypes.MESSAGE),
      createChatMessageModel(13, ChatTypes.MESSAGE),
      createChatMessageModel(14, ChatTypes.MESSAGE),
      createChatMessageModel(15, ChatTypes.MESSAGE),
      createChatMessageModel(16, ChatTypes.MESSAGE),
      createChatMessageModel(17, ChatTypes.MESSAGE),
      createChatMessageModel(18, ChatTypes.MESSAGE),
      createChatMessageModel(19, ChatTypes.MESSAGE),
      createChatMessageModel(20, ChatTypes.MESSAGE),
      createChatMessageModel(21, ChatTypes.MESSAGE),
      createChatMessageModel(22, ChatTypes.MESSAGE),
      createChatMessageModel(23, ChatTypes.MESSAGE)
    ],
    typeEmotions: [
      createChatMessageModel(0, ChatTypes.EMOTIONS),
      createChatMessageModel(1, ChatTypes.EMOTIONS),
      createChatMessageModel(2, ChatTypes.EMOTIONS),
      createChatMessageModel(3, ChatTypes.EMOTIONS),
      createChatMessageModel(4, ChatTypes.EMOTIONS),
      createChatMessageModel(5, ChatTypes.EMOTIONS),
      createChatMessageModel(6, ChatTypes.EMOTIONS),
      createChatMessageModel(7, ChatTypes.EMOTIONS),
      createChatMessageModel(8, ChatTypes.EMOTIONS),
      createChatMessageModel(9, ChatTypes.EMOTIONS),
      createChatMessageModel(10, ChatTypes.EMOTIONS),
      createChatMessageModel(11, ChatTypes.EMOTIONS)
    ],
    typeStickers: [
      createChatMessageModel(0, ChatTypes.STICKER),
      createChatMessageModel(1, ChatTypes.STICKER),
      createChatMessageModel(2, ChatTypes.STICKER),
      createChatMessageModel(3, ChatTypes.STICKER),
      createChatMessageModel(4, ChatTypes.STICKER),
      createChatMessageModel(5, ChatTypes.STICKER),
      createChatMessageModel(6, ChatTypes.STICKER),
      createChatMessageModel(7, ChatTypes.STICKER),
      createChatMessageModel(8, ChatTypes.STICKER),
      createChatMessageModel(9, ChatTypes.STICKER),
      createChatMessageModel(10, ChatTypes.STICKER),
      createChatMessageModel(11, ChatTypes.STICKER)
    ],
    typeSounds: [
      createChatMessageModel(0, ChatTypes.SOUND),
      createChatMessageModel(1, ChatTypes.SOUND),
      createChatMessageModel(2, ChatTypes.SOUND),
      createChatMessageModel(3, ChatTypes.SOUND),
      createChatMessageModel(4, ChatTypes.SOUND),
      createChatMessageModel(5, ChatTypes.SOUND),
      createChatMessageModel(6, ChatTypes.SOUND),
      createChatMessageModel(7, ChatTypes.SOUND)
    ],
    action: {},
    showChatTypeAnimation: {},
    getMoreAction: {}
  });
