import { types } from 'mobx-state-tree';
import { AnimationBuilderMST } from '../../AnimationBuilder';
import { ButtonMST } from '../../ButtonModel';

const REGULAR_BUTTON = 0;
const RED_BUTTON = 1;
const BLUE_BUTTON = 2;
const YELLOW_BUTTON = 3;
const DARK_BLUE_BUTTON = 4;
const BLUE_RED_BUTTON = 5;

export class GameButtonType {
  static get Regular() {
    return REGULAR_BUTTON;
  }

  static get Blue() {
    return BLUE_BUTTON;
  }

  static get Red() {
    return RED_BUTTON;
  }

  static get Yellow() {
    return YELLOW_BUTTON;
  }

  static get DarkBlue() {
    return DARK_BLUE_BUTTON;
  }

  static get BlueRed() {
    return BLUE_RED_BUTTON;
  }
}

export const GameButtonMST = ButtonMST.named('GameButtonMST')
  .props({
    type: BLUE_BUTTON,
    appearAnimation: types.optional(AnimationBuilderMST, {}),
    disappearAnimation: types.optional(AnimationBuilderMST, {})
  })
  .views(self => ({
    isBlue(): boolean {
      return self.type === BLUE_BUTTON;
    },
    isRed(): boolean {
      return self.type === RED_BUTTON;
    }
  }))
  .actions(self => ({
    init(type: boolean, enabled: boolean) {},
    setBlue() {
      self.type = BLUE_BUTTON;
    },
    setRed() {
      self.type = RED_BUTTON;
    },
    setYellow() {
      self.type = YELLOW_BUTTON;
    },
    setBlueRed() {
      self.type = BLUE_RED_BUTTON;
    },
    setRegular() {
      self.type = REGULAR_BUTTON;
    },
    setDarkBlue() {
      self.type = DARK_BLUE_BUTTON;
    }
  }));

type GameButtonModelType = typeof GameButtonMST.Type;
export interface GameButtonModel extends GameButtonModelType {}
export const createGameButtonModel = () => types.optional(GameButtonMST, {});

export const createDarkButton = () =>
  GameButtonMST.create({
    visible: true,
    type: DARK_BLUE_BUTTON,
    action: { enabled: false }
  });

export const createRegularButton = () =>
  GameButtonMST.create({
    visible: true,
    type: REGULAR_BUTTON,
    action: { enabled: false }
  });

export const createDarkButtonWithText = (btnText: string) =>
  GameButtonMST.create({
    visible: true,
    type: GameButtonType.DarkBlue,
    action: { enabled: false },
    text: btnText
  });
