import { PlayerAction } from '../../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.player.message.SurveyResponse';

const NoResponse = -1; // fieldType: int

export class SurveyResponse extends PlayerAction {
  surveyId: number = 0;

  // fieldType: long
  response: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get NoResponse() {
    return NoResponse;
  }
}
