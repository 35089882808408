import { Local } from '../lang/Local';

class RankValues {
  private rankNames: Array<string> = [];

  private leagueShortNames: Array<string> = [];

  private leagueNames: Array<string> = [];

  private shortRankNames: Array<string> = [];

  constructor() {
    let i;
    for (i = 0; i < 11; i++) {
      this.rankNames[3 * i] = Local.getString(`level.${i + 1}`);
      this.rankNames[3 * i + 1] = `${Local.getString(`level.${i + 1}`)} ${Local.getString('level.category_1')}`;
      this.rankNames[3 * i + 2] = `${Local.getString(`level.${i + 1}`)} ${Local.getString('level.category_2')}`;
      this.leagueNames[3 * i] = `${Local.getString(`league.${i}`)}`;
      this.leagueNames[3 * i + 1] = `${Local.getString(`league.${i}`)} ${Local.getString(`league.order_2`)}`;
      this.leagueNames[3 * i + 2] = `${Local.getString(`league.${i}`)} ${Local.getString(`league.order_3`)}`;
      this.leagueShortNames[3 * i] = `${Local.getString(`league_short.${i}`)}`;
      this.leagueShortNames[3 * i + 1] = `${Local.getString(`league_short.${i}`)} ${Local.getString(`league_short.order_2`)}`;
      this.leagueShortNames[3 * i + 2] = `${Local.getString(`league_short.${i}`)} ${Local.getString(`league_short.order_3`)}`;
    }

    for (i = 0; i < 33; i++) {
      this.shortRankNames[i] = `${Local.getString(`level.${Math.floor(i / 3)}`)} [${i + 1}]`;
    }
  }

  public getName(rank: number): string {
    const index = rank < this.rankNames.length ? rank : this.rankNames.length - 1;
    return this.rankNames[index];
  }

  public getLeagueName(rank: number): string {
    const index = rank < this.leagueNames.length ? rank : this.leagueNames.length - 1;
    return this.leagueNames[index];
  }

  public getShortName(rank: number): string {
    const index = rank < this.rankNames.length ? rank : this.rankNames.length - 1;
    return this.shortRankNames[index];
  }

  public getLeagueShortName(rank: number): string {
    const index = rank < this.rankNames.length ? rank : this.rankNames.length - 1;
    return this.leagueShortNames[index];
  }

  public getLevelValue(rank: number): number {
    let result = 0;

    for (let i = 0; i < rank; i++) {
      result += 100;
    }
    return result;
  }
}

export const RANK_VALUES = new RankValues();
