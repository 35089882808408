export class RemPixelRatio {
  private static CALCULATED_RATIO: number = 1;

  public static DEFAULT_WIDTH: number = 762;

  static calculateRatio(width: number) {
    RemPixelRatio.CALCULATED_RATIO = width / this.DEFAULT_WIDTH;
  }

  static getRatio(): number {
    return this.CALCULATED_RATIO;
  }
}
