import { APP } from '../../../app/AppInstance';
import { DiscountResponse } from '../../../messages/player/message/DiscountResponse';
import { ServerEvent } from '../../../messages/communication/message/ServerEvent';
import { Notification } from '../../../messages/player/Notification';
import { NotifDelete } from '../../../messages/player/message/NotifDelete';
import { NotifNew } from '../../../messages/player/message/NotifNew';
import { NotifRead } from '../../../messages/player/message/NotifRead';
import { NotifRequest } from '../../../messages/player/message/NotifRequest';
import { Reward } from '../../../messages/player/reward/message/Reward';
import { InboxModel } from '../../../models/dialogs/inbox/InboxModel';
import { PlayerController } from '../PlayerController';
import { PlayerSubController } from '../PlayerSubController';
import { HappyBirthday } from '../../../messages/player/message/HappyBirthday';
import { createHappyBirthdayModel, HappyBirthdayModel } from '../../../models/dialogs/happybirthday/HappyBirthday';

import { Local } from '../../../lang/Local';

import { ToolbarModel } from '../../../models/toolbar/ToolbarModel';
import GroupAnimation from '../../../animations/GroupAnimation';
import { PromotionResponse } from '../../../messages/player/message/PromotionResponse';
import { NewsResponse } from '../../../messages/player/message/NewsResponse';
import { News } from '../../../messages/player/News';
import { Promotion } from '../../../messages/player/message/Promotion';
import { UserPromotion } from '../../../messages/player/message/UserPromotion';

export class NotificationController extends PlayerSubController {
  private inbox: InboxModel;

  private giftTypeOffer: Reward | undefined;

  private askFriendBoosterReward: Reward | undefined;

  private ignoreIds: Array<String> = [];

  private news: Array<News> = [];

  private notifications: Array<Notification> = [];

  private allPromotions: Array<any> = []; // <Promotion>

  private toolbar: ToolbarModel;

  private singleSmartBanners: Array<any> = [];

  private frequentBanners: Array<any> = [];

  private friendsGiftList: Array<any> = [];

  private previousLoginDate: any;

  constructor(controller: PlayerController, inbox: InboxModel, toolbar: ToolbarModel) {
    super(controller);
    this.inbox = inbox;
    this.toolbar = toolbar;

    this.inbox.acceptAction.setAction(() => {
      this.inbox.hide();
      this.markAllAsRead();
    });
  }

  public onMessage(message: ServerEvent) {
    switch (message.getClass()) {
      case NotifNew.ClassName:
        this.onNotifNew(message as NotifNew);
        break;
      case HappyBirthday.ClassName:
        this.onHappyBirthday(message as HappyBirthday);
        break;
      // TODO FriendGiftList ?
      case PromotionResponse.ClassName:
        this.onPromotionResponse(message as PromotionResponse);
        break;
      case NewsResponse.ClassName:
        this.onNewsResponse(message as NewsResponse);
        break;
      default:
        break;
    }
  }

  private onNotifNew(message: NotifNew) {
    if (message && message.notification) {
      this.inbox.setHasUnreadMessages(true);
      this.notifications.unshift(message.notification);
      this.refreshInbox();
    }
  }

  private onHappyBirthday(message: HappyBirthday) {
    const happyBirthdayDialog: HappyBirthdayModel = createHappyBirthdayModel();
    const reward: Reward = new Reward();
    reward.count = message.tokens;
    reward.type = Reward.TYPE_TOKENS;
    happyBirthdayDialog.init(reward);

    happyBirthdayDialog.claimButton.setText(Local.getString('league.claim_rewards'));
    happyBirthdayDialog.setClaimAction(() => {
      happyBirthdayDialog.claimButton.setEnabled(false);
      happyBirthdayDialog.claimButton.setText(Local.getString('common.close'));
      happyBirthdayDialog.claimButton.action.setAction(() => {
        happyBirthdayDialog.hide();
      });

      const parallel: GroupAnimation = APP.AnimationAPI.createParallel();
      parallel.add(happyBirthdayDialog.claimRewardAnimation.build());

      const seqCount: GroupAnimation = APP.AnimationAPI.createSequence();
      const counter: GroupAnimation = APP.AnimationAPI.createParallel();
      const anim = this.toolbar.getItemCountUpAnimation(reward.type, reward.count);
      if (anim) counter.add(anim);
      seqCount.add(APP.AnimationAPI.createPause(1.7));
      seqCount.add(counter);
      parallel.add(seqCount);

      parallel.play();

      setTimeout(() => {
        happyBirthdayDialog.hide();
      }, 5000);
      happyBirthdayDialog.claimButton.setEnabled(true);
    });
    happyBirthdayDialog.show();
  }

  private showExternalPromotion(name: string): void {
    // TODO
    // foreach (var promotion: Promotion in allPromotions)
    // {
    //   if (promotion.name == name)
    //   {
    //     if (PromotionDialog.isShownExternally)
    //       return;
    //     var promotionDialog: PromotionDialog = new PromotionDialog();
    //     promotionDialog.create(promotion, this, false);
    //     PopUpMgr.scheduleModal(promotionDialog);
    //     return;
    //   }
    // }
  }

  private onPromotionResponse(message: PromotionResponse) {
    // TODO
    // APP.ActionResolver.showPromotion((name: string) => {
    //   this.showExternalPromotion(name);
    // });
    // if (!message.allUserPromotions) return;
    // message.allUserPromotions.forEach((userPromotion: UserPromotion) => {
    //   if (userPromotion.promotion && userPromotion.promotion.name.substr(0, 4) === 'part') {
    //     APP.ActionResolver.showPartPromotion(userPromotion.promotion.name);
    //   }
    //   if (!userPromotion.promotion) return;
    //   this.allPromotions.push(userPromotion.promotion);
    //   if (userPromotion.suitableToShow) {
    //     if (userPromotion.promotion!.type === Promotion.Banner) {
    //       this.showBanner(userPromotion.promotion);
    //       return;
    //     }
    //     if (userPromotion.promotion!.type === Promotion.SmartBanner) {
    //       this.singleSmartBanners.push(userPromotion.promotion);
    //       return;
    //     }
    //     if (userPromotion.promotion!.type <= Promotion.PromoteNew) {
    //       // let promotionDialog: PromotionDialog = new PromotionDialog();
    //       // promotionDialog.create(promotion, getMySelf());
    //       // PopUpMgr.scheduleModal(promotionDialog);
    //     }
    //   } else if (userPromotion.promotion!.type === Promotion.FrequentBanner) {
    //     this.frequentBanners.push(userPromotion.promotion);
    //     // return;
    //   }
    // });
    // TODO
    // askForVisitorsGift();
  }

  private onNewsResponse(message: NewsResponse) {
    this.notifications = [];
    if (message.allNotifications) {
      this.notifications = message.allNotifications;
    }

    this.news = [];
    if (message.allNews) {
      this.news = message.allNews;
    }

    this.refreshInbox();
    if (this.inbox.hasUnreadMessages) this.inbox.schedule();
  }

  private markAllAsRead() {
    this.notifications.forEach((notification: Notification) => {
      if (notification.fStatus === Notification.STATUS_NOT_READ) {
        const notifRead = new NotifRead();
        notifRead.notifId = notification.fId;
        notifRead.readMarker = true;
        this.sendMessageToServer(notifRead);
      }
      notification.fStatus = Notification.STATUS_READ;
    });

    this.news.forEach((news: News) => {
      news.shown = true;
    });

    this.inbox!.markAllAsRead();
  }

  private refreshInbox() {
    this.inbox!.init(this.notifications, this.news, this.previousLoginDate);
  }

  public markAsDeleted(notif: Notification) {
    const itemIndex: number = this.notifications.indexOf(notif);
    if (itemIndex === -1) return;

    this.notifications.splice(itemIndex);
    const notifDelete: NotifDelete = new NotifDelete();
    notifDelete.notifId = notif.fId;
    this.sendMessageToServer(notifDelete);
    this.refreshInbox();
  }

  public openInboxDialogIfHasNewMessages() {
    if (this.getNewNotifications().length > 0) {
      this.inbox!.schedule();
    }
  }

  private getNewNotifications(): Array<Notification> {
    const ret: Array<Notification> = [];
    let i: number;
    for (i = 0; i < this.notifications.length; i++) if ((this.notifications[i] as Notification).fStatus === Notification.STATUS_NOT_READ) ret.push(this.notifications[i]);
    return ret;
  }

  public getNotification(index: number): Notification | null {
    if (index < this.notifications.length) return this.notifications[index] as Notification;

    return null;
  }

  public getGiftMessage(): String | undefined {
    // return this.giftTypeOffer!.desc;
    return '';
  }

  public getCount(): number {
    return this.notifications.length;
  }

  public getUnReadCount(): number {
    let count = 0;
    let notif: Notification | null = null;
    let i: number;
    for (i = 0; i < this.notifications.length; i++) {
      notif = this.notifications[i] as Notification;
      if (notif.fStatus === Notification.STATUS_NOT_READ) count++;
    }
    return count;
  }

  private showBanner(promotion: Promotion | undefined) {}

  public openSendFreeGiftsDialog() {
    // TODO
    // var selector: MultiFriendSelector = new MultiFriendSelector();
    // selector.create(MultiFriendSelector.TYPE_SEND_GIFTS, getFilteredIds(FacebookManager.getInstance().getSortedGiftFriends()), this, giftTypeOffer);
    // PopUpMgr.displayModal(selector, 100, parent);
  }

  public openInviteFriendsDialog() {
    // TODO
    // var selector: MultiFriendSelector = new MultiFriendSelector();
    // selector.create(MultiFriendSelector.TYPE_INVITE_FRIENDS, getFilteredIds(FacebookManager.getInstance().getSortedInviteFriends()), this);
    // PopUpMgr.displayModal(selector, 100, parent);
  }

  public openAskForBoosterDialog() {
    // TODO
    // var selector: MultiFriendSelector = new MultiFriendSelector();
    // selector.create(MultiFriendSelector.TYPE_ASK_FOR_BOOSTER, getFilteredIds(FacebookManager.getInstance().getSortedGiftFriends()), this, askFriendBoosterReward);
    // PopUpMgr.displayModal(selector, 100, parent);
  }

  public getInbox() {
    return this.inbox;
  }

  public setPreviousLoginDate(date: any) {
    this.previousLoginDate = date;
  }

  public static getNotificationSourceText(notification: Notification): string {
    // FIXME
    // if (notification.senderIdString === Notification.SenderGame) return 'Slagalica:';

    // const fullName: string | undefined = APP.FacebookAPI.getFullFriendName(
    //   notification.senderIdString
    // );
    // return fullName || 'friend';
    return notification.title ? notification.title : 'Slagalica';
  }

  public static getNewsSourceText(news: News): string {
    return news.title ? news.title : 'Slagalica';
  }
}
