import { IObservableArray, observable } from 'mobx';
import { BaseMST } from '../BaseModel';
import { DisplayModel } from '../DisplayModel';

export const ActiveDialogsMST = BaseMST.named('ActiveDialogsMST')
  .props({})
  .actions(self => {
    const dialogs: IObservableArray<DisplayModel> = observable.array([]);

    function addDialog(dialog: DisplayModel) {
      dialogs.push(dialog);
    }

    function getDialogs() {
      return dialogs;
    }

    function clear() {
      dialogs.clear();
    }

    return {
      getDialogs,
      addDialog,
      clear
    };
  });

type DialogsType = typeof ActiveDialogsMST.Type;
export interface ActiveDialogsModel extends DialogsType {}

export const createActiveDialogsModel = () => ActiveDialogsMST.create({});
