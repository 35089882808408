import { CollaborationModel } from 'web/src/collaboration/model/CollaborationModel';
import { APP } from '../../app/AppInstance';
import { DEFAULT_VOL } from '../../app/sound/SoundPlayer';
import { Local } from '../../lang/Local';
import { ServerEvent } from '../../messages/communication/message/ServerEvent';
import { ChooseNumbers } from '../../messages/games/mynumber/message/ChooseNumbers';
import { MyNumberStatus } from '../../messages/games/mynumber/message/MyNumberStatus';
import { SolutionAck } from '../../messages/games/mynumber/message/SolutionAck';
import { SolutionProvided } from '../../messages/games/mynumber/message/SolutionProvided';
import { StartChoosingNumbers } from '../../messages/games/mynumber/message/StartChoosingNumbers';
import { StartSolving } from '../../messages/games/mynumber/message/StartSolving';
import { B2bTournamentModel } from '../../models/b2b/B2bTournamentModel';
import { B2bCaptainModel } from '../../models/dialogs/b2bgame/B2bCaptainModel';
import { B2bNoCaptainModel } from '../../models/dialogs/b2bgame/B2bNoCaptainModel';
import { ShopModel } from '../../models/dialogs/shop/ShopModel';
import { GameRiddleContentModel } from '../../models/game/content/GameRiddleContent';
import { MyNumberModel } from '../../models/game/content/mynumber/MyNumberModel';
import { GameInfoModel } from '../../models/game/GameInfoModel';
import { GameModel } from '../../models/game/GameScreenModel';
import { ToolbarModel } from '../../models/toolbar/ToolbarModel';
import { UserModel } from '../../models/user/UserModel';
import { ConnectionAdapter } from '../../sockets/ConnectionAdapter';
import { GameController } from '../base/GameController';

export class MyNumberController extends GameController {
  private myNumberModel: MyNumberModel;

  constructor(
    amfSocket: ConnectionAdapter,
    myNumberModel: MyNumberModel,
    gameInfo: GameInfoModel,
    gameScreenModel: GameModel,
    userModel: UserModel,
    shopModel: ShopModel,
    toolbarModel: ToolbarModel,
    b2bTournamentModel: B2bTournamentModel,
    b2bCaptainModel: B2bCaptainModel,
    b2bNoCaptainModel: B2bNoCaptainModel,
    collaborationModel: CollaborationModel
  ) {
    super(amfSocket, 'MyNumberController', gameInfo, gameScreenModel, userModel, shopModel, toolbarModel, b2bTournamentModel, b2bCaptainModel, b2bNoCaptainModel, collaborationModel);
    this.myNumberModel = myNumberModel;
  }

  protected getGameModel(): GameRiddleContentModel {
    return this.myNumberModel;
  }

  public onMessage(message: ServerEvent) {
    super.onMessage(message);
    switch (message.getClass()) {
      case StartSolving.ClassName:
        this.onStartSolving(message as StartSolving);
        break;
      case StartChoosingNumbers.ClassName:
        this.onStartChoosingNumbers(message as StartChoosingNumbers);
        break;
      case MyNumberStatus.ClassName:
        this.onMyNumberStatus(message as MyNumberStatus);
        break;
      case SolutionAck.ClassName:
        this.onSolutionAck(message as SolutionAck);
        break;
      default:
        break;
    }
  }

  private onStartSolving(message: StartSolving) {
    // TODO
    // FocusMonitor.getInstance().startMonitoring();
    this.myNumberModel.setNumbers(message.digit1, message.digit2, message.digit3, message.digit4, message.offer1, message.offer2);
    this.myNumberModel.setTargetNumber(message.targetNumber);

    let statusMessage = Local.getString('my_number.find');

    if (this.amICaptain) {
      this.gameInfo.enableInteraction();
      this.myNumberModel.enableBtns();
      this.myNumberModel.confirmBtn.setVisible(true);
      this.myNumberModel.stopBtn.setVisible(false);
    } else {
      this.gameInfo.disableInteraction();
      this.myNumberModel.disableBtns();
      this.myNumberModel.confirmBtn.setVisible(false);
      this.myNumberModel.stopBtn.setVisible(false);
      statusMessage = Local.getString('my_number.find_captain');
    }

    this.stopNumbersShuffling();

    this.setStatusSpectacle(
      statusMessage,
      this.calculateClientTimeout(message),
      () => {
        this.solvingFinishedCallback();
      },
      GameController.BOTH_PLAYERS
    );
  }

  private onStartChoosingNumbers(message: StartChoosingNumbers) {
    this.myNumberModel.setMyURL(this.getMyTeam().logo);
    this.myNumberModel.setOpponentURL(this.getOpponentTeam().logo);
    this.hideCaptainDialog();
    this.animationExecutor.schedule(this.myNumberModel.shufflingAnimation.build());
    this.myNumberModel.setBtnActions(
      (solution: string) => {
        this.submitSolution(solution);
      },
      () => {
        this.myNumberModel.stopBtn.setEnabled(false);
        this.sendMessage(new ChooseNumbers());
      }
    );

    if (this.isForMe(message)) {
      if (this.amICaptain) this.gameInfo.enableInteraction();
      this.setStatusSpectacle(
        Local.getString('my_number.choose'),
        this.calculateClientTimeout(message),
        () => {
          this.sendChooseNumbersSignal();
        },
        GameController.ME
      );
      this.myNumberModel.stopBtn.setEnabled(true);
      this.myNumberModel.stopBtn.setVisible(true);
    } else {
      this.gameInfo.disableInteraction();
      this.myNumberModel.stopBtn.setEnabled(false);
      this.myNumberModel.stopBtn.setVisible(false);
      this.setStatusSpectacle(Local.getString('my_number.opp_choose'), this.calculateClientTimeout(message), () => {}, GameController.OPPONENT);
    }
  }

  private onMyNumberStatus(message: MyNumberStatus) {
    this.myNumberModel.confirmBtn.setVisible(false);
    this.gameInfo.disableInteraction();
    this.playStatusSound(message);
    this.animateGameStatus(message);
  }

  private onSolutionAck(message: SolutionAck) {
    if (this.isForMyTeam(message)) {
      this.myNumberModel.confirmBtn.setVisible(false);
      this.myNumberModel.eraseBtn.setVisible(false);
      this.gameInfo.disableInteraction();
      this.gameInfo.stopTimer(true);

      if (!this.amICaptain) {
        this.myNumberModel.mySolution.reset();
        if (message.solution) this.myNumberModel.setMySolution(message.solution);

        const seq = APP.AnimationAPI.createSequence();
        seq.add(this.myNumberModel.showOpponentSolutionAnimation.build());
        seq.add(this.myNumberModel.hideBtnsAnimation.build());
        seq.play();
      }

      // getGui().setMySolution(event.solution);
      // if (!opponentFinished) getGui().animateWaitingForOpponet();
    } else {
      this.myNumberModel.setOpponentFinished();
    }
  }

  protected onStartGame(): void {
    // this.animationExecutor.schedule(this.gameScreenModel.getShowGameContentAnimation(this.myNumberModel));
    this.myNumberModel.resetModel();
  }

  public stopNumbersShuffling() {
    this.animationExecutor.cancel();
  }

  private sendChooseNumbersSignal() {
    this.sendMessage(new ChooseNumbers());
    this.gameInfo.setStatus('');
    this.myNumberModel.stopBtn.setVisible(false);
    this.myNumberModel.stopBtn.setEnabled(false);
  }

  public submitSolution(solution: string) {
    this.myNumberModel.confirmBtn.setVisible(false);
    this.myNumberModel.eraseBtn.setVisible(false);
    this.gameInfo.disableInteraction();
    this.gameInfo.stopTimer(true);

    const solutionProvided = new SolutionProvided();
    solutionProvided.solution = solution;
    this.sendMessage(solutionProvided);

    const seq = APP.AnimationAPI.createSequence();
    seq.add(this.myNumberModel.showOpponentSolutionAnimation.build());
    seq.add(this.myNumberModel.hideBtnsAnimation.build());
    seq.play();
    // FocusMonitor.getInstance().stopMonitoring();
  }

  private animateGameStatus(message: MyNumberStatus) {
    this.myNumberModel.setOpponentSolution(this.gameInfo.isPlayingAsBlue ? message.redAnswer : message.blueAnswer);

    // BlueNumberHolder.visible = RedNumberHolder.visible = true;
    // opponentGroup.visible = true;
    // waitingHolder.visible = false;

    const myResult = this.gameInfo.isPlayingAsBlue ? message.blueResult : message.redResult;
    const opponentsResult = !this.gameInfo.isPlayingAsBlue ? message.blueResult : message.redResult;

    if (myResult) this.myNumberModel.mySolution.setResultNumber(myResult);
    if (opponentsResult) this.myNumberModel.opponentsSolution.setResultNumber(opponentsResult);

    if (message.winResult === MyNumberStatus.NO_WINNERS) return;

    const winningSeq = APP.AnimationAPI.createSequence();
    if (message.winResult === MyNumberStatus.BLUE_WINS) {
      if (this.gameInfo.isPlayingAsBlue) winningSeq.add(this.myNumberModel.mySolution.resultBlinkAnimation.build());
      else winningSeq.add(this.myNumberModel.opponentsSolution.resultBlinkAnimation.build());
    } else if (message.winResult === MyNumberStatus.RED_WINS) {
      if (!this.gameInfo.isPlayingAsBlue) winningSeq.add(this.myNumberModel.mySolution.resultBlinkAnimation.build());
      else winningSeq.add(this.myNumberModel.opponentsSolution.resultBlinkAnimation.build());
    }

    winningSeq.addEndListener(() => {
      this.animateGamePoints(message);
    });
    this.animationExecutor.schedule(winningSeq);
  }

  private animateGamePoints(message: MyNumberStatus) {
    this.myNumberModel.mySolution.setPoints(message.myLastPoints);
    this.myNumberModel.opponentsSolution.setPoints(message.opponentLastPoints);

    const pointsSeq = APP.AnimationAPI.createSequence();
    if (message.winResult === MyNumberStatus.BLUE_WINS) {
      if (this.gameInfo.isPlayingAsBlue) pointsSeq.add(this.myNumberModel.mySolution.pointsAnimation.build());
      else pointsSeq.add(this.myNumberModel.opponentsSolution.pointsAnimation.build());
    } else if (message.winResult === MyNumberStatus.RED_WINS) {
      if (!this.gameInfo.isPlayingAsBlue) pointsSeq.add(this.myNumberModel.mySolution.pointsAnimation.build());
      else pointsSeq.add(this.myNumberModel.opponentsSolution.pointsAnimation.build());
    }
    pointsSeq.add(APP.AnimationAPI.createPause(0.4));

    if (message.myLastPoints > 0) pointsSeq.add(this.gameInfo.getMe().addPoints(message.myLastPoints));
    if (message.opponentLastPoints > 0) pointsSeq.add(this.gameInfo.getOpponent().addPoints(message.opponentLastPoints));

    this.animationExecutor.schedule(pointsSeq);
  }

  private solvingFinishedCallback() {
    this.submitSolution(this.myNumberModel.mySolution.solution);
  }

  private playStatusSound(message: MyNumberStatus) {
    if ((this.gameInfo.isPlayingAsBlue && message.winResult === MyNumberStatus.BLUE_WINS) || (!this.gameInfo.isPlayingAsBlue && message.winResult === MyNumberStatus.RED_WINS)) APP.SoundPlayer.playWinningSound(DEFAULT_VOL);
    else APP.SoundPlayer.playLoosingSound(DEFAULT_VOL);
  }

  public getGameName(): string {
    return Local.getString('game.my_number');
  }
}
