import { Local } from 'common/src/lang/Local';
import { B2bGameModel } from 'common/src/models/b2b/B2bGameModel';
import { B2bTeamModel, createB2bTeamModel } from 'common/src/models/b2b/B2bTeamModel';
import { B2bTournamentModel } from 'common/src/models/b2b/B2bTournamentModel';
import { UserModel } from 'common/src/models/user/UserModel';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import B2bGamePanel from './B2bGamePanel';

interface B2bScheduleProps {
  b2bTournament?: B2bTournamentModel;
  user?: UserModel | undefined;
}
@inject('b2bTournament', 'user')
@observer
export default class B2bSchedule extends Component<B2bScheduleProps> {
  SCROLL_MOVE = 24.7;

  ONE_ROW_SIZE = 4;

  MIN_FRIEND = 4;

  TOOLTIP_INDEX_MOVE = 5;

  ALL = 0;

  ONLINE = 1;

  @observable selectedTournament: number = this.ALL;

  @observable transitionActive: boolean = false;

  @observable transitX: number = 0;

  getTeam(b2bTournament: B2bTournamentModel, b2bGame: B2bGameModel, blue: boolean): B2bTeamModel {
    let t: B2bTeamModel = createB2bTeamModel();
    b2bTournament.teams.forEach((team: B2bTeamModel) => {
      if (team.id === (blue ? b2bGame.blueTeamId : b2bGame.redTeamId)) {
        t = team;
      }
    });
    return t;
  }

  getToolTipPosition(number: number) {
    const scrollTransitX = this.transitX !== 0 ? this.transitX / this.SCROLL_MOVE : 0;
    if (number - scrollTransitX > this.TOOLTIP_INDEX_MOVE && (number - scrollTransitX) % this.TOOLTIP_INDEX_MOVE > 0) {
      return 35 + (number % this.MIN_FRIEND) * 1.5;
    }
    return 15 + (number % this.MIN_FRIEND) * 1.5;
  }

  moveLeft(b2bTournament: B2bTournamentModel) {
    this.transitionActive = true;
    const scrollTransitX = this.transitX !== 0 ? this.transitX / this.SCROLL_MOVE : 0;
    if (b2bTournament!.games.length < this.MIN_FRIEND) {
      this.transitX += -this.SCROLL_MOVE;
    } else if (scrollTransitX >= this.ONE_ROW_SIZE) {
      this.transitX += this.ONE_ROW_SIZE * -this.SCROLL_MOVE;
    } else {
      this.transitX += scrollTransitX * -this.SCROLL_MOVE;
    }
  }

  moveRight(b2bTournament: B2bTournamentModel) {
    this.transitionActive = true;
    const scrollTransitX = this.transitX !== 0 ? this.transitX / this.SCROLL_MOVE : 0;
    const friendsLength = b2bTournament!.games.length;
    if (friendsLength < this.MIN_FRIEND) {
      this.transitX += this.SCROLL_MOVE;
    } else if (friendsLength - scrollTransitX - this.ONE_ROW_SIZE >= this.ONE_ROW_SIZE) {
      this.transitX += this.MIN_FRIEND * this.SCROLL_MOVE;
    } else {
      this.transitX += (friendsLength - scrollTransitX - this.ONE_ROW_SIZE) * this.SCROLL_MOVE;
    }
  }

  isMyGame(user: UserModel, b2bGame: B2bGameModel): boolean {
    return user.info.b2bTeam === b2bGame.blueTeamId || user.info.b2bTeam === b2bGame.redTeamId;
  }

  render() {
    const { b2bTournament, user } = this.props;
    const numberFriends = b2bTournament!.games.length < this.MIN_FRIEND ? this.MIN_FRIEND - b2bTournament!.games.length : 0;
    let numberFriendsScroll = 0;

    if (b2bTournament!.games.length < this.MIN_FRIEND) {
      numberFriendsScroll = this.SCROLL_MOVE;
    } else {
      numberFriendsScroll = (b2bTournament!.games!.length - this.ONE_ROW_SIZE) * this.SCROLL_MOVE;
    }

    return (
      <div className="friends">
        <div className="friends__title">{Local.getString('friend.b2b_title')}</div>
        <div
          className="friends__mode friends__mode--all"
          style={{ opacity: this.selectedTournament === this.ALL ? 1 : 0.5 }}
          onClick={() => {
            this.transitX = 0;
            this.transitionActive = false;
            this.selectedTournament = this.ALL;
          }}
        >
          {Local.getString('friend.b2b_all_teams')}
        </div>
        <div
          className="friends__mode friends__mode--online"
          style={{ opacity: this.selectedTournament === this.ONLINE ? 1 : 0.5 }}
          onClick={() => {
            this.transitX = 0;
            this.transitionActive = false;
            this.selectedTournament = this.ONLINE;
          }}
        >
          {Local.getString('friend.b2b_my_team')}
        </div>
        <div />
        {this.transitX !== 0 && <button className="friends__arrow-left" onClick={() => this.moveLeft(b2bTournament!)} />}
        {this.transitX !== numberFriendsScroll && b2bTournament!.games.length >= this.MIN_FRIEND && <button className="friends__arrow-right" onClick={() => this.moveRight(b2bTournament!)} />}

        <div className="friends__friends--background">
          {b2bTournament!.games.map((b2bGame, i) => (
            <B2bGamePanel
              key={i}
              isAll={this.selectedTournament === this.ALL}
              b2bGame={b2bGame}
              isMyGame={this.isMyGame(user!, b2bGame!)}
              scrollTransitX={this.transitX}
              toolTipPosition={this.getToolTipPosition(i++)}
              transitionActive
              blueTeam={this.getTeam(b2bTournament!, b2bGame, true)}
              redTeam={this.getTeam(b2bTournament!, b2bGame, false)}
            />
          ))}
        </div>
      </div>
    );
  }
}
