import { Local } from 'common/src/lang/Local';
import { PairsModel } from 'common/src/models/game/content/pairs/PairsModel';
import { inject, observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { Fade } from '../../../../animations/samples/Fade';
import { GSAPPause } from '../../../../animations/base/GSAPPause';
import { PointsAnimation } from '../../../../animations/samples/PointsAnimation';
import GameButton from '../../GameButton';
import { GameContent, GameContentProps } from '../../GameContent';
import { GameStatus } from '../../GameStatus';
import Point from '../../Point';
import GSAPSequenceAnimation from '../../../../animations/base/GSAPSequenceAnimator';

interface PairsProps extends GameContentProps {
  pairs?: PairsModel;
}

@inject('pairs')
@observer
export default class Pairs extends GameContent<PairsProps, {}> {
  pairsARefs: Array<GameButton | null> = [];

  pairsBRefs: Array<GameButton | null> = [];

  pairsADescriptionsRefs: Array<HTMLDivElement | null> = [];

  pairsBDescriptionsRefs: Array<HTMLDivElement | null> = [];

  aPoint: Array<Point | null> = [];

  bPoint: Array<Point | null> = [];

  createPointsAnimation = (args: any[]) => {
    const isBlue: boolean = args[0];
    const isAnswerA: boolean = args[1];

    const { pairs } = this.props;
    const seq = new GSAPSequenceAnimation();
    const divPoint = isAnswerA ? this.aPoint![pairs!.currentIndex] : this.bPoint![pairs!.currentIndex];
    if (divPoint) {
      seq.add(PointsAnimation.create(isBlue, divPoint));
    }
    return seq;
  };

  createTooltipAnimation = () => {
    const { pairs } = this.props;
    const seq = new GSAPSequenceAnimation();
    if (this.pairsADescriptionsRefs[pairs!.currentIndex] && this.pairsBDescriptionsRefs[pairs!.currentIndex]) {
      seq.add(new Fade([this.pairsADescriptionsRefs[pairs!.currentIndex], this.pairsBDescriptionsRefs[pairs!.currentIndex]], 0.1, 0, 0.95));
      seq.add(new GSAPPause(1));
      seq.add(new Fade([this.pairsADescriptionsRefs[pairs!.currentIndex], this.pairsBDescriptionsRefs[pairs!.currentIndex]], 0.1, 0.95, 0));
    }
    return seq;
  };

  createAnimations() {
    for (let i = 0; i < 8; i++) this.props.pairs!.pairsA[i].pointsAnimation.set(this.createPointsAnimation);
    for (let i = 0; i < 8; i++) this.props.pairs!.pairsB[i].pointsAnimation.set(this.createPointsAnimation);

    for (let i = 0; i < 8; i++) this.props.pairs!.tooltipAnimations[i].set(this.createTooltipAnimation);
  }

  destroyAnimations() {
    for (let i = 0; i < 8; i++) this.props.pairs!.pairsA[i].pointsAnimation.dispose();
    for (let i = 0; i < 8; i++) this.props.pairs!.pairsB[i].pointsAnimation.dispose();
  }

  componentDidMount() {
    this.createAnimations();
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  isVisible(): boolean {
    const { pairs } = this.props;
    return pairs ? pairs.isVisible() : false;
  }

  renderVisible(): ReactNode {
    const { pairs } = this.props;
    const t = (message: string) => Local.getString(message);
    if (!pairs) {
      return null;
    }

    return (
      <div
        ref={contentRef => {
          this.contentRef = contentRef;
        }}
      >
        <GameStatus />
        <div className="pairs">
          <div className="pairs__progress">
            <div className="pairs__progress__question">{pairs!.question}</div>
          </div>
          <div className="pairs__container">
            {pairs!.pairsA.map((x, index) => (
              <div key={x.id} style={{ width: '24.8rem' }}>
                <GameButton
                  key={x.text + index}
                  model={pairs!.pairsA[index]}
                  baseClass="pairs__container--pairs"
                  ref={number => {
                    this.pairsARefs[index] = number;
                  }}
                />
                <div
                  className="pairs__container--text"
                  style={{ marginTop: `${`${(index + 1) * 4.25}rem`}` }}
                  ref={description => {
                    this.pairsADescriptionsRefs[index] = description;
                  }}
                >
                  {x!.description}
                </div>
                <Point
                  ref={point => {
                    this.aPoint[index] = point;
                  }}
                  baseClass="pairs__container__point"
                  value={2}
                />
              </div>
            ))}
          </div>
          <div className="pairs__container pairs__container--opponent">
            {pairs!.pairsB.map((x, index) => (
              <div key={x.id} style={{ width: '24.8rem' }}>
                <GameButton
                  key={x.text + index}
                  model={pairs!.pairsB[index]}
                  baseClass="pairs__container--pairs"
                  ref={number => {
                    this.pairsBRefs[index] = number;
                  }}
                />
                <div
                  className="pairs__container--text"
                  style={{ marginTop: `${`${(index + 1) * 4.2}rem`}` }}
                  ref={description => {
                    this.pairsBDescriptionsRefs[index] = description;
                  }}
                >
                  {x!.description}
                </div>
                <Point
                  ref={point => {
                    this.bPoint[index] = point;
                  }}
                  baseClass="pairs__container__point"
                  value={2}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
