import { MessageSerializable } from '../../../MessageSerializable';
import { PlayerInfo } from '../../message/PlayerInfo';

const BINDING_NAME = 'rs.slagalica.player.league.message.LeagueTopPositioned';

export class LeagueTopPositioned extends MessageSerializable {
  playerInfo?: PlayerInfo = undefined;

  // fieldType: PlayerInfo
  position: number = 0;

  // fieldType: int
  feathers: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
