import { CollaborationModel } from 'web/src/collaboration/model/CollaborationModel';
import GroupAnimation from '../../animations/GroupAnimation';
import { APP } from '../../app/AppInstance';
import { DEFAULT_VOL } from '../../app/sound/SoundPlayer';
import { Local } from '../../lang/Local';
import { ServerEvent } from '../../messages/communication/message/ServerEvent';
import { MatchingContent } from '../../messages/games/matching/message/MatchingContent';
import { MatchingFinal } from '../../messages/games/matching/message/MatchingFinal';
import { MatchingResponse } from '../../messages/games/matching/message/MatchingResponse';
import { MatchingResult } from '../../messages/games/matching/message/MatchingResult';
import { MatchRequest } from '../../messages/games/matching/message/MatchRequest';
import { B2bTournamentModel } from '../../models/b2b/B2bTournamentModel';
import { B2bCaptainModel } from '../../models/dialogs/b2bgame/B2bCaptainModel';
import { B2bNoCaptainModel } from '../../models/dialogs/b2bgame/B2bNoCaptainModel';
import { ShopModel } from '../../models/dialogs/shop/ShopModel';
import { GameRiddleContentModel } from '../../models/game/content/GameRiddleContent';
import { MatchingModel } from '../../models/game/content/matching/MatchingModel';
import { GameInfoModel } from '../../models/game/GameInfoModel';
import { GameModel } from '../../models/game/GameScreenModel';
import { BLUE_PLAYER } from '../../models/game/PlayerType';
import { ToolbarModel } from '../../models/toolbar/ToolbarModel';
import { UserModel } from '../../models/user/UserModel';
import { ConnectionAdapter } from '../../sockets/ConnectionAdapter';
import { GameController } from '../base/GameController';

const NO_ANSWER: number = -1;

export class MatchingController extends GameController {
  private matchingModel: MatchingModel;

  constructor(
    amfSocket: ConnectionAdapter,
    matchingModel: MatchingModel,
    gameInfo: GameInfoModel,
    gameScreenModel: GameModel,
    userModel: UserModel,
    shopModel: ShopModel,
    toolbarModel: ToolbarModel,
    b2bTournamentModel: B2bTournamentModel,
    b2bCaptainModel: B2bCaptainModel,
    b2bNoCaptainModel: B2bNoCaptainModel,
    collaborationModel: CollaborationModel
  ) {
    super(amfSocket, 'MatchingController', gameInfo, gameScreenModel, userModel, shopModel, toolbarModel, b2bTournamentModel, b2bCaptainModel, b2bNoCaptainModel, collaborationModel);
    this.matchingModel = matchingModel;
  }

  protected getGameModel(): GameRiddleContentModel {
    return this.matchingModel;
  }

  public onControllerMessage(message: ServerEvent) {
    super.onControllerMessage(message);

    switch (message.getClass()) {
      case MatchingContent.ClassName:
        this.onMatchingContent(message as MatchingContent);
        break;
      case MatchRequest.ClassName:
        this.onMatchRequest(message as MatchRequest);
        break;
      case MatchingResult.ClassName:
        this.onMatchingResult(message as MatchingResult);
        break;
      case MatchingFinal.ClassName:
        this.onMatchingFinal(message as MatchingFinal);
        break;
      default:
        break;
    }
  }

  protected onStartGame() {
    // this.animationExecutor.schedule(this.gameScreenModel.getShowGameContentAnimation(this.matchingModel));
    this.matchingModel.resetModel();
    if (this.matchingModel.resetResultAnimation) this.matchingModel.resetResultAnimation.build().play();
    this.matchingModel.init((questionIndex: number, answerIndex: number) => {
      this.sendResponse(questionIndex, answerIndex);
    });
    this.gameScreenModel.showGameContent(this.matchingModel);
  }

  protected onMatchingContent(message: MatchingContent): void {
    for (let i = 0; i < message.columnA.length; i++) {
      this.matchingModel.columnA[i].setText(message.columnA[i]);
      this.matchingModel.columnB[i].setText(message.columnB[i]);
    }

    this.matchingModel.setQuestion(message.question);
    this.gameInfo.disableInteraction();
    this.setStatusSpectacle(Local.getString('matching.ready'), this.calculateClientTimeout(message), () => {}, MatchingController.NO_PLAYER, false);
  }

  protected onMatchRequest(message: MatchRequest): void {
    this.gameInfo.stopTimer(false);

    this.matchingModel.resetAllTemporarlyMarkedBs();
    // this.matchingModel.clearPreviousQuestion();

    this.matchingModel.setCurrentQuestion(message.questionIndex);

    if (this.isForMe(message)) {
      this.setStatusSpectacle(
        Local.getString('matching.find'),
        this.calculateClientTimeout(message),
        () => {
          this.onMatchRequestTimeout();
        },
        GameController.ME
      );
      this.gameInfo.enableInteraction();
    } else {
      this.setStatusSpectacle(Local.getString('matching.opp_move'), this.calculateClientTimeout(message), () => {}, GameController.OPPONENT);
      this.gameInfo.disableInteraction(true);
    }
  }

  protected onMatchingResult(message: MatchingResult): void {
    this.gameInfo.stopTimer(false);
    // updateHistory(event);

    const whoOpenned: number = this.isForMe(message) ? this.getMyPosition() : this.getOpponentPosition();

    this.matchingModel.setCurrentAnswer(message.answerIndex);
    if (message.correct) {
      const pointsWon: number = message.myLastPoints;

      if (this.isForMe(message)) APP.SoundPlayer.playCorrectSound(DEFAULT_VOL);

      if (whoOpenned === BLUE_PLAYER) {
        this.matchingModel.markAsBlueMatch(this.matchingModel.current, message.answerIndex);
        this.animationExecutor.schedule(this.getPointsAnimation(true, message.answerIndex, pointsWon));
      } else {
        this.matchingModel.markAsRedMatch(this.matchingModel.current, message.answerIndex);
        this.animationExecutor.schedule(this.getPointsAnimation(false, message.answerIndex, pointsWon));
      }
    } else if (message.answerIndex >= 0) {
      // if less then 0 (-1), means player did not answer, or timeout expired
      if (this.isForMe(message)) APP.SoundPlayer.playWrongSound(DEFAULT_VOL);
      this.matchingModel.markAsYellow(message.answerIndex);
    }
  }

  protected onMatchingFinal(message: MatchingFinal): void {
    // FocusMonitor.getInstance().stopMonitoring();

    this.gameInfo.stopTimer(false);
    this.gameInfo.disableInteraction();

    this.matchingModel.resetAllTemporarlyMarkedBs();
    this.matchingModel.setMappings(message.mapping!);

    this.animationExecutor.schedule(this.matchingModel.resultAnimation.build());
  }

  private getPointsAnimation(isBluePoint: boolean, answerIndex: number, points: number): GroupAnimation {
    const anim: GroupAnimation = APP.AnimationAPI.createParallel();
    anim.add(this.matchingModel.columnB[answerIndex].pointsAnimation.build(isBluePoint));

    const seq = APP.AnimationAPI.createSequence();
    seq.add(APP.AnimationAPI.createPause(0.8));
    seq.add(isBluePoint ? this.gameInfo.bluePlayer.addPoints(points) : this.gameInfo.redPlayer.addPoints(points));
    anim.add(seq);

    return anim;
  }

  private onMatchRequestTimeout(): void {
    this.gameInfo.disableInteraction();
    const matchingResponse = new MatchingResponse();
    matchingResponse.questionIndex = this.matchingModel.current;
    matchingResponse.answerIndex = NO_ANSWER;
    this.sendMessage(matchingResponse);
  }

  private sendResponse(questionIndex: number, answerIndex: number): void {
    this.gameInfo.disableInteraction();
    const matchingResponse = new MatchingResponse();
    matchingResponse.questionIndex = questionIndex;
    matchingResponse.answerIndex = answerIndex;
    this.sendMessage(matchingResponse);
    this.gameInfo.stopTimer(true);
    this.gameInfo.setStatus('');
  }

  protected getGameName(): string {
    return Local.getString('game.matching');
  }

  protected finalizeController() {
    super.finalizeController();
  }
}
