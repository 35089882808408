import { inject, observer } from 'mobx-react';
import React, { Fragment } from 'react';
import { PulseLoader } from 'react-spinners';
import { Local } from 'common/src/lang/Local';
import { MatchingModel } from 'common/src/models/game/content/matching/MatchingModel';

import { GameInfoModel } from 'common/src/models/game/GameInfoModel';
import { GameContent, GameContentProps } from '../../GameContent';
import { GameStatus } from '../../GameStatus';
import Point from '../../Point';
import GameButton from '../../GameButton';
import { GlowText } from '../../../../animations/samples/GlowText';
import { TranslateTo } from '../../../../animations/samples/Translate';
import { Fade } from '../../../../animations/samples/Fade';
import { ZoomOut } from '../../../../animations/samples/ZoomOut';
import { Blink } from '../../../../animations/samples/Blink';
import { ZoomIn } from '../../../../animations/samples/ZoomIn';
import { PointsAnimation } from '../../../../animations/samples/PointsAnimation';
import GSAPSequenceAnimation from '../../../../animations/base/GSAPSequenceAnimator';
import GSAPParallelAnimation from '../../../../animations/base/GSAPParallelAnimation';

interface MatchingProps extends GameContentProps {
  matching?: MatchingModel;
  gameInfo?: GameInfoModel;
}
@inject('matching', 'gameInfo')
@observer
export default class Matching extends GameContent<MatchingProps, {}> {
  answerPoint: Array<Point | null> = [];

  opponentSempahore: HTMLDivElement | null = null;

  opponentSolving: HTMLDivElement | null = null;

  opponentImage: HTMLDivElement | null = null;

  opponentCount: HTMLDivElement | null = null;

  gameButtons: Array<GameButton | null> = [];

  GAME_BUTTON_MOVE = 42;

  GAME_POINTS = 2;

  createPointsAnimation = (args: any[]) => {
    const isBlue: boolean = args[0];
    const { matching } = this.props;
    const seq = new GSAPSequenceAnimation();
    const divPoint = this.answerPoint![matching!.currentAnswer];
    if (divPoint) {
      seq.add(PointsAnimation.create(isBlue, divPoint));
    }
    return seq;
  };

  createResultAnimation = () => {
    const { matching } = this.props;
    const parallel = new GSAPParallelAnimation();

    const columnBArray = matching!.columnB.map(x => x.id);
    matching!.columnB.forEach(colB => {
      const gameButton = this.gameButtons[colB.id];
      if (gameButton) {
        const difference = matching!.mappings.indexOf(colB.id) - columnBArray.indexOf(colB.id);
        if (difference !== 0) {
          parallel.add(new TranslateTo(gameButton.boxRef, 1, 0, difference * this.GAME_BUTTON_MOVE, 0));
        }
      }
    });
    return parallel;
  };

  createAnimations() {
    const { matching } = this.props;
    for (let i = 0; i < 8; i++) matching!.columnB[i].pointsAnimation.set(this.createPointsAnimation);
    matching!.resultAnimation.set(this.createResultAnimation);
    matching!.enteringAnimation.set(this.createEnteringAnimation);
    matching!.exitingAnimation.set(this.createExitingAnimation);
    matching!.preparingAnimation.set(this.createPreparingAnimation);
  }

  destroyAnimations() {
    const { matching } = this.props;
    for (let i = 0; i < 8; i++) matching!.columnB[i].pointsAnimation.dispose();
    matching!.resultAnimation.dispose();
    matching!.enteringAnimation.dispose();
    matching!.exitingAnimation.dispose();
    matching!.preparingAnimation.dispose();
  }

  componentDidMount() {
    this.createAnimations();
  }

  isVisible(): boolean {
    if (this.props.matching) return this.props.matching.isVisible();
    return false;
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  renderVisible(): React.ReactNode {
    const { matching } = this.props;
    const t = (message: string) => Local.getString(message);
    if (!matching) {
      return null;
    }

    return (
      <div
        ref={contentRef => {
          this.contentRef = contentRef;
        }}
      >
        <GameStatus />
        <div className="matching">
          <div className="matching__progress">
            <div className="matching__progress__question">{matching!.question}</div>
          </div>
          <div className="matching__column">
            {matching!.columnA.map(colA => (
              <GameButton key={colA.id} model={colA} baseClass="matching__option-button" />
            ))}
          </div>
          <div className="matching__column matching__column--B">
            {matching!.columnB.map(colB => (
              <Fragment key={colB.id}>
                <GameButton
                  key={colB.id}
                  model={colB}
                  baseClass="matching__option-button"
                  ref={gameButton => {
                    this.gameButtons[colB.id] = gameButton;
                  }}
                />
                <Point
                  baseClass="matching__point"
                  value={this.GAME_POINTS}
                  ref={point => {
                    this.answerPoint[colB.id] = point;
                  }}
                />
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
