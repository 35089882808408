import { Instance, types } from 'mobx-state-tree';
import { ActionMST } from '../../ActionModel';
import { DisplayMST } from '../../DisplayModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const B2bLoginMST = DisplayMST.named('B2bLoginMST')
  .props({
    submitAction: ActionMST,
    tournament: types.string,
    team: types.string,
    md5: types.string,
    id: types.string,
    name: types.string,
    admin: types.string,
    superAdmin: types.string,
    error: types.string
  })
  .views(self => ({}))
  .actions(self => ({
    setTournament(org: string) {
      self.tournament = org;
    },
    setTeam(t: string) {
      self.team = t;
    },
    setMd5(m: string) {
      self.md5 = m;
    },
    setId(i: string) {
      self.id = i;
    },
    setName(n: string) {
      self.name = n;
    },
    setAdmin(a: string) {
      self.admin = a;
    },
    setSuperAdmin(s: string) {
      self.superAdmin = s;
    },
    setError(s: string) {
      self.error = s;
    },
    validate(): boolean {
      self.error = '';
      if (self.tournament === '' || self.team === '') {
        self.error = 'Neispravan dolazni link';
      } else if (self.name === '') {
        self.error = 'Ime ne može biti prazno';
      } else if (self.id === '') {
        self.error = 'Polje username ne može biti prazno';
      } else if (self.id === self.name) {
        self.error = 'Username i ime ne mogu biti isti';
      } else if (self.team === self.name) {
        self.error = 'Ime i tim ne mogu biti isti';
      } else if (self.team === self.id) {
        self.error = 'Username i tim ne mogu biti isti';
      } else if (self.tournament === self.id) {
        self.error = 'Username i turnir ne mogu biti isti';
      } else if (self.tournament === self.name) {
        self.error = 'Ime i turnir ne mogu biti isti';
      }
      return self.error === '';
    }
  }));

export interface B2bLoginModel extends Instance<typeof B2bLoginMST> {}

export const createB2bLoginModel = () =>
  B2bLoginMST.create({
    key: DisplayModelKeys.B2bLogin,
    submitAction: {},
    tournament: '',
    team: '',
    md5: '',
    id: '',
    name: '',
    admin: '',
    superAdmin: '',
    error: ''
  });
