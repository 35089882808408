import { types } from 'mobx-state-tree';
import { AnimationBuilderMST } from '../../../AnimationBuilder';
import { GameButtonType } from '../GameButtonModel';
import { AssociationFieldMST } from './AssociationFieldModel';
import { ActionMST } from '../../../ActionModel';

export const NO_RESOLVED = 1;
export const RED_RESOLVED = 2;
export const BLUE_RESOLVED = 3;
export const ENGINE_RESOLVED = 4;

export const AssociationResultMST = AssociationFieldMST.named('AssociationResultMST')
  .props({
    myAnswer: '',
    confirmAction: types.optional(ActionMST, {}),
    cancelAction: types.optional(ActionMST, {}),
    openAnimation: types.optional(AnimationBuilderMST, {}),
    pointsAnimation: types.optional(AnimationBuilderMST, {}),
    resolvedStatus: NO_RESOLVED,
    points: 0,
    focused: false
  })
  .actions(self => ({
    initActions(clickAction: Function, sendWord: Function) {
      self.confirmAction.setAction(sendWord);
      self.action.setAction(clickAction);
    },
    setId(id: number) {
      self.id = id;
    },
    getWordFromLetters(): string {
      return self.text;
    },
    reset(enabled: boolean = true) {
      if (self.resolvedStatus === BLUE_RESOLVED || self.resolvedStatus === RED_RESOLVED) return;
      self.resolvedStatus = NO_RESOLVED;
      self.setRegular();
      self.setEnabled(enabled);
      self.setText('?');
      self.focused = false;
    },
    setBlueAnswered(text: string) {
      self.focused = false;
      self.markAsBlueAnswered(text);
      self.resolvedStatus = BLUE_RESOLVED;
    },
    setRedAnswered(text: string) {
      self.focused = false;
      self.markAsRedAnswered(text);
      self.resolvedStatus = RED_RESOLVED;
    },
    setEngineAnswered(text: string) {
      self.focused = false;
      self.markAsEngineAnswered(text);
      self.resolvedStatus = ENGINE_RESOLVED;
    },
    isResolved(): boolean {
      return self.resolvedStatus !== NO_RESOLVED;
    },
    setPoints(points: number) {
      self.points = points;
    },
    resetResultModel() {
      self.setText('?');
      self.setRegular();
      self.resolvedStatus = NO_RESOLVED;
      self.isOpened = false;
      self.myAnswer = '';
    },
    addLetter(letter: string) {
      self.text = self.text.concat(letter);
    },
    setText(letters: string) {
      self.text = letters;
    },
    removeLastLetter() {
      self.text = self.text.substring(0, self.text.length - 1);
    },
    setFocused(isFocused: boolean) {
      self.focused = isFocused;
      if (isFocused) {
        self.text = self.text === '?' ? '' : self.text;
      }
    }
    // addLetter(letter: string) {
    //   self.myAnswer = self.myAnswer.concat(letter);
    //   self.text = self.myAnswer.concat('|');
    // },
    // removeLastLetter() {
    //   self.myAnswer = self.myAnswer.substring(0, self.myAnswer.length - 1);
    //   self.text = self.myAnswer.concat('|');
    // },
    // setFocused(isFocused: boolean) {
    //   self.focused = isFocused;
    //   if (isFocused) {
    //     self.text = self.text === '?' ? '|' : self.myAnswer.concat('|');
    //   }
    // }
  }));

type AssociationResultModelType = typeof AssociationResultMST.Type;
export interface AssociationResultModel extends AssociationResultModelType {}

export const createResultModel = (id: number) =>
  AssociationResultMST.create({
    id,
    visible: true,
    action: { enabled: false },
    text: '?',
    type: GameButtonType.Regular,
    confirmAction: {},
    cancelAction: {},
    focused: false
  });
