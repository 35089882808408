import { BaseMST } from '../../BaseModel';
import { APP } from '../../../app/AppInstance';

export const TournamentResultItemMST = BaseMST.named('TournamentResultItemModel')
  .props({
    position: 1,
    name: 'unknown',
    fbid: '0',
    feathers: 0
  })
  .views(self => ({}))
  .actions(self => ({
    init(position: number, name: string, fbid: string, feathers: number) {
      self.position = position;
      self.name = name;
      self.fbid = fbid;
      self.feathers = feathers;
    }
  }));

type TournamentResultItemModelType = typeof TournamentResultItemMST.Type;
export interface TournamentResultItem extends TournamentResultItemModelType {}
export const createTournamentResultItemModel = (position: number, name: string, fbid: string, feathers: number) =>
  TournamentResultItemMST.create({
    position,
    name,
    fbid,
    feathers
  });
