import { MessageSerializable } from '../../../MessageSerializable';

const BINDING_NAME = 'rs.slagalica.games.association.message.SimpleField';

export class SimpleField extends MessageSerializable {
  word: string = '';

  opened?: boolean = undefined;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
