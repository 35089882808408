import React, { Component } from 'react';
import { ShopModel } from 'common/src/models/dialogs/shop/ShopModel';
import { ShopGroups } from 'common/src/models/dialogs/shop/ShopGroups';

interface TokensProps {
  tokens: number;
  shop?: ShopModel;
  // onCoinsClick: Function;  //onCoinsClick={shop!.onCoinsClick}
}

export default class Tokens extends Component<TokensProps> {
  onCoinsClick = () => {
    // this.props.shop!.onGroupSelected(ShopGroups.TOKENS_GROUP);
    // FIXME maybe open tokens
  };

  render() {
    const { tokens, shop } = this.props;
    // const style = shop!.selectedGroup > 0 ? { cursor: 'pointer' } : {};
    return (
      <div className="shop__add-tokens-background-png" onClick={this.onCoinsClick}>
        {/* style={style}> */}
        <div className="shop__tokens">{tokens}</div>
        {/* {shop!.selectedGroup > 0 && <div className="shop__add-tokens-button" />} */}
      </div>
    );
  }
}
