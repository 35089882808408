import BaseAnimation from '../../animations/BaseAnimation';
import GroupAnimation from '../../animations/GroupAnimation';
import { APP } from '../../app/AppInstance';
import { DEFAULT_VOL } from '../../app/sound/SoundPlayer';
import { Local } from '../../lang/Local';
import { ServerEvent } from '../../messages/communication/message/ServerEvent';
import { CheckWord } from '../../messages/games/puzzle/message/CheckWord';
import { ChooseLetters } from '../../messages/games/puzzle/message/ChooseLetters';
import { PuzzleStatus } from '../../messages/games/puzzle/message/PuzzleStatus';
import { SolutionAck } from '../../messages/games/puzzle/message/SolutionAck';
import { SolutionProvided } from '../../messages/games/puzzle/message/SolutionProvided';
import { StartChoosingLetters } from '../../messages/games/puzzle/message/StartChoosingLetters';
import { StartSolving } from '../../messages/games/puzzle/message/StartSolving';
import { WordStatus } from '../../messages/games/puzzle/message/WordStatus';
import { B2bTournamentModel } from '../../models/b2b/B2bTournamentModel';
import { B2bCaptainModel } from '../../models/dialogs/b2bgame/B2bCaptainModel';
import { B2bNoCaptainModel } from '../../models/dialogs/b2bgame/B2bNoCaptainModel';
import { ShopModel } from '../../models/dialogs/shop/ShopModel';
import { GameRiddleContentModel } from '../../models/game/content/GameRiddleContent';
import { createMyLetter } from '../../models/game/content/puzzle/LetterModel';
import { PuzzleModel } from '../../models/game/content/puzzle/PuzzleModel';
import { GameInfoModel } from '../../models/game/GameInfoModel';
import { GameModel } from '../../models/game/GameScreenModel';
import { ToolbarModel } from '../../models/toolbar/ToolbarModel';
import { UserModel } from '../../models/user/UserModel';
import { ConnectionAdapter } from '../../sockets/ConnectionAdapter';
import { ALPHABET } from '../../util/Alphabet';
import { GameController } from '../base/GameController';

export class PuzzleController extends GameController {
  private puzzleModel: PuzzleModel;

  private checkWordTimerID: NodeJS.Timeout | undefined = undefined;

  constructor(
    amfSocket: ConnectionAdapter,
    puzzleModel: PuzzleModel,
    gameInfo: GameInfoModel,
    gameScreenModel: GameModel,
    userModel: UserModel,
    shopModel: ShopModel,
    toolbarModel: ToolbarModel,
    b2bTournamentModel: B2bTournamentModel,
    b2bCaptainModel: B2bCaptainModel,
    b2bNoCaptainModel: B2bNoCaptainModel,
    collaborationModel: CollaborationModel
  ) {
    super(amfSocket, 'PuzzleController', gameInfo, gameScreenModel, userModel, shopModel, toolbarModel, b2bTournamentModel, b2bCaptainModel, b2bNoCaptainModel, collaborationModel);
    this.puzzleModel = puzzleModel;
    this.puzzleModel.checker.checkWordAction.setAction(() => {
      if (this.checkWordTimerID) clearTimeout(this.checkWordTimerID);
      this.checkWordTimerID = setTimeout(() => {
        this.sendCheckWordRequest(this.puzzleModel.myWord.getCodedWord());
      }, 2000);
      this.puzzleModel.checker.setChecking();
    });
  }

  protected getGameModel(): GameRiddleContentModel {
    return this.puzzleModel;
  }

  public onControllerMessage(message: ServerEvent) {
    super.onControllerMessage(message);

    switch (message.getClass()) {
      case StartChoosingLetters.ClassName:
        this.hideCaptainDialog();
        if (this.puzzleModel) {
          this.onStartChoosingLetters(message as StartChoosingLetters);
        }
        break;
      case StartSolving.ClassName:
        this.onStartSolving(message as StartSolving);
        break;
      case WordStatus.ClassName:
        if (!this.isForMyTeam(message)) return;
        this.onWordStatus(message as WordStatus);
        break;
      case PuzzleStatus.ClassName:
        this.onPuzzleStatus(message as PuzzleStatus);
        break;
      case SolutionAck.ClassName:
        this.onSolutionAck(message as SolutionAck);
        break;
      default:
        break;
    }
  }

  private onStartChoosingLetters(message: StartChoosingLetters): void {
    this.puzzleModel.setMyURL(this.getMyTeam().logo);
    this.puzzleModel.setOpponentURL(this.getOpponentTeam().logo);

    this.animationExecutor.schedule(this.puzzleModel.shufflingAnimation.build());
    this.puzzleModel.setBtnActions(
      () => {
        this.submitSolution(this.puzzleModel.myWord.getCodedWord());
      },
      () => {
        this.sendStopScrambleMessage();
      },
      () => {
        this.puzzleModel.appealWord.setEnabled(false);
        const word = this.getDecodedWord(this.puzzleModel.opponentWord.letters);
        this.gameInfo.appealAction.getAction()(word);
        this.puzzleModel.opponentWord.setAppealed();
      }
    );

    if (this.isForMe(message)) {
      let statusMessage = Local.getString('puzzle.choose_letters');
      if (this.amICaptain) {
        statusMessage = Local.getString('puzzle.choose_letters');
        this.gameInfo.enableInteraction();
      } else {
        statusMessage = Local.getString('puzzle.choose_letters_captain');
        this.gameInfo.disableInteraction();
      }
      this.setStatusSpectacle(
        statusMessage,
        this.calculateClientTimeout(message),
        () => {
          this.sendStopScrambleMessage();
        },
        GameController.ME,
        false
      );
      this.puzzleModel.stopBtn.setVisible(true);
      this.puzzleModel.stopBtn.setEnabled(true);
    } else {
      this.gameInfo.disableInteraction();
      this.setStatusSpectacle(Local.getString('puzzle.opp_move'), this.calculateClientTimeout(message), () => {}, GameController.OPPONENT, false);
    }
  }

  private onStartSolving(message: StartSolving): void {
    if (!(message && message.letters)) return;
    this.puzzleModel.setLetters(message.letters, this.gameInfo.isPlayingAsBlue);
    //  TODO recheck this lines up and down
    this.puzzleModel.confirmBtn.setVisible(true);
    this.puzzleModel.stopBtn.setVisible(false);

    this.stopLettersShuffling();

    let statusMessage = Local.getString('puzzle.find_word');

    if (this.amICaptain) {
      this.gameInfo.enableInteraction();
      this.puzzleModel.enableSolvingButtons();
      statusMessage = Local.getString('puzzle.find_word');
    } else {
      this.gameInfo.disableInteraction();
      this.puzzleModel.disableSolvingButtons();
      statusMessage = Local.getString('puzzle.find_word_captain');
    }

    this.animationExecutor.schedule(this.puzzleModel.initalLettersAnimation.build());
    this.setStatusSpectacle(statusMessage, this.calculateClientTimeout(message), this.solvingFinishedTimeoutCallback(), GameController.BOTH_PLAYERS);
  }

  private onWordStatus(message: WordStatus): void {
    if (message.word === undefined || message.word === null || message.word.length < 0 || message.word.length > 12) {
      return;
    }

    if (!this.amICaptain) {
      this.showMyWord(message!.word!);
    }

    if (this.areWordsSame(message.word, this.puzzleModel.myWord.getCodedWord())) this.puzzleModel.setWordStatus(message.isCorrect);
  }

  private showMyWord(word: Array<number>) {
    this.puzzleModel.myWord.reset();
    word!.forEach(letter => {
      const addedLetter = createMyLetter(letter, 0, this.gameInfo.isPlayingAsBlue);
      addedLetter.setVisible(false);
      this.puzzleModel.addMyLetter(addedLetter);
      const appear = addedLetter.appearAnimation.build();
      appear.addStartListener(() => {
        addedLetter.setVisible(true);
      });
      appear.play();
    });
  }

  private areWordsSame(wordRecieved: Array<number>, wordSent: Array<number>): boolean {
    if (wordRecieved.length !== wordSent.length) return false;

    for (let i = 0; i < wordRecieved.length; i++) {
      if (wordRecieved[i] !== wordSent[i]) return false;
    }

    return true;
  }

  private onPuzzleStatus(message: PuzzleStatus): void {
    // this.gameInfo.disableInteraction();

    const myAnswer: Array<number> | undefined = this.gameInfo.isPlayingAsBlue ? message.blueAnswer : message.redAnswer;
    const opponentAnswer: Array<number> | undefined = !this.gameInfo.isPlayingAsBlue ? message.blueAnswer : message.redAnswer;
    const opponentPoints: number = !this.gameInfo.isPlayingAsBlue ? message.blueLengthPoints : message.redLengthPoints;

    if (!this.amICaptain) {
      this.puzzleModel.myWord.reset();
      if (myAnswer) this.showMyWord(myAnswer);
    }

    this.puzzleModel.disableSolvingButtons();
    this.puzzleModel.confirmBtn.setVisible(false);
    this.animateSolutionResult(message);
    this.playStatusSound(message);

    if (opponentAnswer && opponentPoints > 0) {
      this.setOpponentSolution(opponentAnswer);
    } else {
      this.setOpponentSolution([]);
    }

    this.setComputerSolution(message.targetWord ? message.targetWord : []);
    this.animateGameStatus(message);

    // getGui().checkSpelling.visible = false;
    // playStatusSound(event);
  }

  private onSolutionAck(message: SolutionAck): void {
    if (this.isForMyTeam(message)) {
      this.puzzleModel.confirmBtn.setVisible(false);
      this.puzzleModel.eraseLetter.setVisible(false);
      // getGui().myAnswerOverHolder.visible = false;
      this.gameInfo.stopTimer(true);
      // getGui().setMySolution(event.word, isPlayingAsBlue());
      // if (!opponentFinished) getGui().animateWaitingForOpponet();
      this.animationExecutor.schedule(this.puzzleModel.showOpponentWordAnimation.build());
    } else {
      // opponentFinished = true;
      this.puzzleModel.opponentWord.setOpponentProgressShown(false);
    }
  }

  private animateGameStatus(message: PuzzleStatus) {
    // const opponentAnswer = this.gameInfo.isPlayingAsBlue ? message.redAnswer : message.blueAnswer;
    // const opponentSolution: String = this.getDecodedWord(opponentAnswer || []);
    const opponentPoints = this.gameInfo.isPlayingAsBlue ? message.redLengthPoints : message.blueLengthPoints;
    const myBonusPoints = this.gameInfo.isPlayingAsBlue ? message.blueGameBonus : message.redGameBonus;
    const opponentBonusPoints = this.gameInfo.isPlayingAsBlue ? message.redGameBonus : message.blueGameBonus;
    const myExtraBonusPoints = this.gameInfo.isPlayingAsBlue ? message.blueExtraBonus : message.redExtraBonus;
    const opponentExtraBonusPoints = this.gameInfo.isPlayingAsBlue ? message.redExtraBonus : message.blueExtraBonus;

    this.puzzleModel.opponentWord.setPoints(opponentPoints);

    const seqOpp = APP.AnimationAPI.createSequence();
    seqOpp.add(APP.AnimationAPI.createPause(0.8));
    seqOpp.add(this.gameInfo.getOpponent().addPoints(opponentPoints));

    const anim: GroupAnimation = APP.AnimationAPI.createParallel();
    anim.add(this.puzzleModel.opponentWord.pointsAnimation.build());
    anim.add(seqOpp);
    this.animationExecutor.schedule(anim);

    const bonusSeq = APP.AnimationAPI.createSequence();
    if (myBonusPoints > 0 || opponentBonusPoints > 0) {
      if (myBonusPoints > 0) {
        bonusSeq.add(this.getBonusAnimation(true, myBonusPoints));
      } else if (opponentBonusPoints > 0) {
        bonusSeq.add(this.getBonusAnimation(false, opponentBonusPoints));
      }
    }
    bonusSeq.add(APP.AnimationAPI.createPause(0.3));
    bonusSeq.add(this.puzzleModel.showComputerWordAnimation.build());

    if (myExtraBonusPoints > 0 || opponentExtraBonusPoints > 0) {
      if (myExtraBonusPoints > 0) {
        bonusSeq.add(this.getExtraBonusAnimation(true, myExtraBonusPoints));
      } else if (opponentExtraBonusPoints > 0) {
        bonusSeq.add(this.getExtraBonusAnimation(false, opponentExtraBonusPoints));
      }
    }

    this.animationExecutor.schedule(bonusSeq);
  }

  private sendStopScrambleMessage(): void {
    this.puzzleModel.stopBtn.setEnabled(false);
    this.sendMessage(new ChooseLetters());
  }

  private solvingFinishedTimeoutCallback() {
    return () => {
      this.submitSolution(this.puzzleModel ? this.puzzleModel.myWord.getCodedWord() : []);
    };
  }

  private submitSolution(solution: Array<number>): void {
    // this.gameInfo.disableInteraction();
    this.puzzleModel.disableSolvingButtons();
    this.gameInfo.stopTimer(true);
    this.animateMyAnswerConfirm();

    const solutionProvided: SolutionProvided = new SolutionProvided();
    solutionProvided.word = solution;
    this.sendMessage(solutionProvided);

    // TODO re-check, here or on SolutionAck
    // const seq = APP.AnimationAPI.createSequence();
    // seq.add(this.myNumberModel.showOpponentSolutionAnimation.build());
    // seq.add(this.myNumberModel.hideBtnsAnimation.build());
    // seq.play();
  }

  private sendCheckWordRequest(solution: Array<number>): void {
    if (solution.length === 0) return;

    const checkWord: CheckWord = new CheckWord();
    checkWord.word = solution;
    this.sendMessage(checkWord);
  }

  private animateSolutionResult(message: PuzzleStatus): void {
    const myLengthPoints: number = this.gameInfo.isPlayingAsBlue ? message.blueLengthPoints : message.redLengthPoints;
    this.puzzleModel.checker.setStatus(myLengthPoints > 0);

    if (myLengthPoints > 0) {
      const anim: GroupAnimation = APP.AnimationAPI.createParallel();
      this.puzzleModel.myWord.setPoints(myLengthPoints);
      anim.add(this.puzzleModel.myWord.pointsAnimation.build());

      const mySeq = APP.AnimationAPI.createSequence();
      mySeq.add(APP.AnimationAPI.createPause(0.8));
      mySeq.add(this.gameInfo.getMe().addPoints(myLengthPoints));
      anim.add(mySeq);

      this.animationExecutor.schedule(anim);
    }

    // TODO setMySolution(message.word); (answer elements)
  }

  private setOpponentSolution(solution: Array<number>) {
    this.puzzleModel.setOpponentLetters(solution);
    this.puzzleModel.opponentWord.setVisible(true);
  }

  private setComputerSolution(solution: Array<number>) {
    this.puzzleModel.setComputerLetters(solution);
    // TODO animation for computer word?
  }

  private animateMyAnswerConfirm() {
    const hideAnim = this.puzzleModel.hideBtnsOnConfirmAnimation.build();
    hideAnim.play();
  }

  public stopLettersShuffling() {
    this.animationExecutor.cancel();
  }

  private playStatusSound(event: PuzzleStatus) {
    if ((this.gameInfo.isPlayingAsBlue && event.winResult === PuzzleStatus.BLUE_WINS) || (!this.gameInfo.isPlayingAsBlue && event.winResult === PuzzleStatus.RED_WINS)) APP.SoundPlayer.playWinningSound(DEFAULT_VOL);
    else APP.SoundPlayer.playLoosingSound(DEFAULT_VOL);
  }

  protected onStartGame() {
    // this.animationExecutor.schedule(this.gameScreenModel.getShowGameContentAnimation(this.puzzleModel));
    this.puzzleModel.resetModel();
  }

  protected finalizeController() {
    super.finalizeController();
  }

  protected getGameName(): string {
    return Local.getString('game.puzzle');
  }

  private getDecodedWord(codedWord: Array<number>): string {
    let decodedWord = '';
    codedWord.forEach((code: number) => {
      decodedWord += ALPHABET.getLetter(code);
    });
    return decodedWord;
  }

  private getBonusAnimation(isMe: boolean, bonus: number): BaseAnimation {
    const showBonus = isMe ? this.puzzleModel.myWord.bonusAnimation.build() : this.puzzleModel.opponentWord.bonusAnimation.build();
    showBonus.addStartListener(() => {
      if (isMe) {
        this.puzzleModel.myWord.setBonus(bonus);
      } else {
        this.puzzleModel.opponentWord.setBonus(bonus);
      }
    });

    const animBonus: GroupAnimation = APP.AnimationAPI.createParallel();
    animBonus.add(showBonus);

    const seqPoints = APP.AnimationAPI.createSequence();
    seqPoints.add(APP.AnimationAPI.createPause(0.8));
    const addPointsPause = APP.AnimationAPI.createPause(1);
    addPointsPause.addStartListener(() => {
      (isMe ? this.gameInfo.getMe() : this.gameInfo.getOpponent()).addPoints(bonus).play();
    });
    seqPoints.add(addPointsPause);
    animBonus.add(seqPoints);

    return animBonus;
  }

  private getExtraBonusAnimation(isMe: boolean, extraBonus: number): BaseAnimation {
    const showExtraBonus = isMe ? this.puzzleModel.myWord.extraBonusAnimation.build() : this.puzzleModel.opponentWord.extraBonusAnimation.build();
    showExtraBonus.addStartListener(() => {
      if (isMe) {
        this.puzzleModel.myWord.setExtraBonusPoints(extraBonus);
      } else {
        this.puzzleModel.opponentWord.setExtraBonusPoints(extraBonus);
      }
    });

    const animBonus: GroupAnimation = APP.AnimationAPI.createParallel();
    animBonus.add(showExtraBonus);

    const seqPoints = APP.AnimationAPI.createSequence();
    seqPoints.add(APP.AnimationAPI.createPause(0.8));
    const addPointsPause = APP.AnimationAPI.createPause(1);
    addPointsPause.addStartListener(() => {
      (isMe ? this.gameInfo.getMe() : this.gameInfo.getOpponent()).addPoints(extraBonus).play();
    });
    seqPoints.add(addPointsPause);
    animBonus.add(seqPoints);

    return animBonus;
  }
}
