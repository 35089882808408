import { types } from 'mobx-state-tree';
import { Achievement } from '../../../messages/player/achievements/message/Achievement';
import { AchievementReward } from '../../../messages/player/achievements/message/AchievementReward';
import { AchievementTargetValue } from '../../../messages/player/achievements/message/AchievementTargetValue';
import { Cycle } from '../../../messages/player/history/message/Cycle';
import { Romanize } from '../../../util/Romanize';
import { DisplayMST } from '../../DisplayModel';
import { AchievementItemModel, AchievementItemMST } from './AchievementItemModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const AchievementsMST = DisplayMST.named('AchievementsModel')
  .props({
    season: types.string,
    seasonDate: types.string,
    seasonDayExpired: types.number,
    rank: types.number,
    firstPlaceCount: types.number,
    secondPlaceCount: types.number,
    thirdPlaceCount: types.number,
    achievements: types.array(AchievementItemMST)
  })
  .views(self => ({
    getAchievement(id: number) {
      return self.achievements.filter((ach: AchievementItemModel) => ach.getId() === id);
    }
  }))
  .actions(self => ({
    init(rank: number, firstPlaceCount: number, secondPlaceCount: number, thirdPlaceCount: number) {
      self.rank = rank;
      self.firstPlaceCount = firstPlaceCount;
      self.secondPlaceCount = secondPlaceCount;
      self.thirdPlaceCount = thirdPlaceCount;
    },
    setPositions(first: number, second: number, third: number) {
      self.firstPlaceCount = first;
      self.secondPlaceCount = second;
      self.thirdPlaceCount = third;
    },
    addAchievement(achievementItemModel: AchievementItemModel) {
      self.achievements.push(achievementItemModel);
    },
    updateAchievement(achievement: Achievement) {
      self.achievements.forEach((achievementItem: AchievementItemModel) => {
        if (achievementItem.getId() === (achievement.id % 10) * 10 + achievement.level) {
          achievementItem.setCompleted(achievement.completed);
          achievementItem.setAwarded(achievement.awarded);
        }
      });
    },
    setCycle(cycle: Cycle | undefined) {
      if (!cycle) return;

      self.season = Romanize.toRoman(cycle.cycleId);
      // FIXME https://github.com/expo/expo/issues/6536
      try {
        self.seasonDate = `${new Intl.DateTimeFormat(['sr', 'en'], {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).format(cycle.dateStarted)} - ${new Intl.DateTimeFormat(['sr', 'en'], {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).format(cycle.expectedEnd)}`;
      } catch (exception) {
        console.log(`Cycle season format failed: ${exception}`);
      }
      self.seasonDayExpired = cycle.remainingDays;
    },
    setRank(rank: number) {
      self.rank = rank;
    },
    clearAchievements() {
      self.achievements.clear();
    },
    setRewards(achievementRewards: AchievementReward) {
      self.achievements.forEach((achievementItem: AchievementItemModel) => {
        if (achievementItem.getId() === (achievementRewards.achievementId % 10) * 10 + achievementRewards.level) {
          achievementItem.setRewards(achievementRewards.awards);
        }
      });
    },
    setTargetValues(achievementTargetValue: AchievementTargetValue) {
      self.achievements.forEach((achievementItem: AchievementItemModel) => {
        if (achievementItem.getId() === (achievementTargetValue.id % 10) * 10 + achievementTargetValue.level) {
          achievementItem.setRequiredValue(achievementTargetValue.value);
        }
      });
    },
    getTargetValue(id: number, level: number): number {
      let value = 0;
      self.achievements.forEach((achievementItem: AchievementItemModel) => {
        if (achievementItem.getId() === (id % 10) * 10 + level) {
          value = achievementItem.requiredValue;
        }
      });

      return value;
    }
  }));

type AchievementsModelType = typeof AchievementsMST.Type;
export interface AchievementsModel extends AchievementsModelType {}
export const createAchievementsModel = () =>
  AchievementsMST.create({
    key: DisplayModelKeys.Achievements,
    season: 'XIV',
    seasonDate: '31.12.2018 - 01.07.2019',
    seasonDayExpired: 0,
    rank: 0,
    firstPlaceCount: 0,
    secondPlaceCount: 0,
    thirdPlaceCount: 0,
    achievements: []
  });
