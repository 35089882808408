import { types } from 'mobx-state-tree';
import { Reward } from '../../../messages/player/reward/message/Reward';
import { ActionMST } from '../../ActionModel';
import { AnimationBuilderMST } from '../../AnimationBuilder';
import { ButtonMST } from '../../ButtonModel';
import { createRewardItemModel, RewardItemModel, RewardItemMST } from '../../common/RewardItemModel';
import { DisplayMST } from '../../DisplayModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const AchievementWonMST = DisplayMST.named('AchievementWonMST')
  .props({
    id: types.number,
    rewards: types.array(RewardItemMST),
    acceptReward: types.optional(ActionMST, {}),
    claimRewardAnimation: AnimationBuilderMST,
    claimButton: ButtonMST,
    position: types.number,
    reqValue: types.number
  })
  .views(self => ({}))
  .actions(self => ({
    init(id: number, rewards: Array<Reward> | undefined, position: number, reqValue: number) {
      self.id = id;
      self.position = position;
      if (rewards) {
        rewards.forEach((reward: Reward) => {
          self.rewards.push(createRewardItemModel(reward.type, reward.count));
        });
        self.rewards.replace(self.rewards.slice().sort((rewardA: RewardItemModel, rewardB: RewardItemModel) => rewardA.getPosition() - rewardB.getPosition()));
      }
      self.reqValue = reqValue;
    },
    setPosition(pos: number) {
      self.position = pos;
    },
    setClaimAction(action: Function) {
      self.claimButton.action.setAction(action);
    },
    claimReward() {
      self.claimRewardAnimation.build().play();
    }
  }));

type AAchievementWonModelType = typeof AchievementWonMST.Type;
export interface AchievementWonModel extends AAchievementWonModelType {}

export const createAchievementWonModel = () =>
  AchievementWonMST.create({
    key: DisplayModelKeys.AchievemntWonDialog,
    id: -1,
    rewards: [],
    claimRewardAnimation: {},
    claimButton: {},
    position: 0,
    reqValue: 0
  });
