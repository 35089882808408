import { Message } from './Message';

const BINDING_NAME = 'rs.slagalica.communication.message.ServerEvent';

export class ServerEvent extends Message {
  sourcePlayerId?: number = undefined;

  // fieldType: long
  timeoutForResponse: number = 0;

  // fieldType: int
  delay: number = 0;

  // fieldType: long
  messageId?: number = undefined; // fieldType: long

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
