import { types } from 'mobx-state-tree';
import { BaseMST } from '../BaseModel';
import { AnimationBuilderMST } from '../AnimationBuilder';
import { ActionMST } from '../ActionModel';

export const BoosterMST = BaseMST.named('BoosterMST')
  .props({
    booster: false,
    progressBooster: 0,
    boosterAction: types.optional(ActionMST, {}),
    boosterAnimation: types.optional(AnimationBuilderMST, {})
  })
  .actions(self => ({
    setPlayerBooster(booster: boolean) {
      self.booster = booster;
    },
    setProgressBooster(value: number) {
      self.progressBooster = value;
    }
  }))
  .views(self => ({}));

type BoosterModelType = typeof BoosterMST.Type;
export interface BoosterModel extends BoosterModelType {}

export const createBoosterModel = () => BoosterMST.create({});
