import { CollaborationModel } from 'web/src/collaboration/model/CollaborationModel';
import { APP } from '../../app/AppInstance';
import { DEFAULT_VOL } from '../../app/sound/SoundPlayer';
import { Local } from '../../lang/Local';
import { ServerEvent } from '../../messages/communication/message/ServerEvent';
import { PairsAnswer } from '../../messages/games/pairs/message/PairsAnswer';
import { PairsGameQuestion } from '../../messages/games/pairs/message/PairsGameQuestion';
import { PairsResult } from '../../messages/games/pairs/message/PairsResult';
import { SendPairsQuestion } from '../../messages/games/pairs/message/SendPairsQuestion';
import { B2bTournamentModel } from '../../models/b2b/B2bTournamentModel';
import { B2bCaptainModel } from '../../models/dialogs/b2bgame/B2bCaptainModel';
import { B2bNoCaptainModel } from '../../models/dialogs/b2bgame/B2bNoCaptainModel';
import { ShopModel } from '../../models/dialogs/shop/ShopModel';
import { GameRiddleContentModel } from '../../models/game/content/GameRiddleContent';
import { PairsModel } from '../../models/game/content/pairs/PairsModel';
import { GameInfoModel } from '../../models/game/GameInfoModel';
import { GameModel } from '../../models/game/GameScreenModel';
import { BLUE_PLAYER } from '../../models/game/PlayerType';
import { ToolbarModel } from '../../models/toolbar/ToolbarModel';
import { UserModel } from '../../models/user/UserModel';
import { ConnectionAdapter } from '../../sockets/ConnectionAdapter';
import { GameController } from '../base/GameController';

const NO_ANSWER: number = -1;
export class PairsController extends GameController {
  private pairsModel: PairsModel;

  constructor(
    amfSocket: ConnectionAdapter,
    pairsModel: PairsModel,
    gameInfo: GameInfoModel,
    gameScreenModel: GameModel,
    userModel: UserModel,
    shopModel: ShopModel,
    toolbarModel: ToolbarModel,
    b2bTournamentModel: B2bTournamentModel,
    b2bCaptainModel: B2bCaptainModel,
    b2bNoCaptainModel: B2bNoCaptainModel,
    collaborationModel: CollaborationModel
  ) {
    super(amfSocket, 'PairsController', gameInfo, gameScreenModel, userModel, shopModel, toolbarModel, b2bTournamentModel, b2bCaptainModel, b2bNoCaptainModel, collaborationModel);
    this.pairsModel = pairsModel;
  }

  protected getGameModel(): GameRiddleContentModel {
    return this.pairsModel;
  }

  public onMessage(message: ServerEvent) {
    super.onMessage(message);
    switch (message.getClass()) {
      case PairsGameQuestion.ClassName:
        this.onPairsGameQuestion(message as PairsGameQuestion);
        break;

      case SendPairsQuestion.ClassName:
        this.onSendPairsQuestion(message as SendPairsQuestion);
        break;

      case PairsResult.ClassName:
        this.onPairsResult(message as PairsResult);
        break;
      default:
        break;
    }
  }

  private onPairsGameQuestion(message: PairsGameQuestion) {
    this.hideCaptainDialog();
    this.pairsModel.setQuestion(message.question);
    this.gameInfo.disableInteraction();

    this.setStatusSpectacle(Local.getString('pairs.ready'), this.calculateClientTimeout(message), () => {}, GameController.NO_PLAYER, false);
  }

  private onSendPairsQuestion(message: SendPairsQuestion) {
    this.pairsModel.setCurrentQuestion(message.id, message.a, message.b);

    if (this.isForMyTeam(message)) {
      const statusMessage = this.isForMe(message) ? Local.getString('pairs.find') : Local.getString('pairs.find-captain');
      this.setStatusSpectacle(
        statusMessage,
        this.calculateClientTimeout(message),
        () => {
          this.onPairsRequestTimeout();
        },
        GameController.ME
      );
      if (this.amICaptain) {
        this.gameInfo.enableInteraction();
      } else {
        this.gameInfo.disableInteraction(true);
      }
    } else {
      this.setStatusSpectacle(Local.getString('pairs.opp_move'), this.calculateClientTimeout(message), () => {}, GameController.OPPONENT);
      this.gameInfo.disableInteraction(true);
    }
  }

  private onPairsResult(message: PairsResult) {
    this.gameInfo.stopTimer(false);

    const whoOpenned: number = this.isForMyTeam(message) ? this.getMyPosition() : this.getOpponentPosition();

    if (message.isCorrect) {
      const pointsWon: number = message.myLastPoints;

      if (this.isForMyTeam(message)) APP.SoundPlayer.playCorrectSound(DEFAULT_VOL);

      this.pairsModel.setCorrectAnswer(message.correctAnswerIndex, whoOpenned === BLUE_PLAYER);

      this.animationExecutor.schedule(this.pairsModel.getPointsAnimation(message.correctAnswerIndex, pointsWon, whoOpenned === BLUE_PLAYER));
      this.animationExecutor.schedule(whoOpenned === BLUE_PLAYER ? this.gameInfo.bluePlayer.addPoints(pointsWon) : this.gameInfo.redPlayer.addPoints(pointsWon));
    } else if (message.getQuestionId() >= 0) {
      // if less then 0 (-1), means player did not answer, or timeout expired
      if (this.isForMyTeam(message)) APP.SoundPlayer.playWrongSound(DEFAULT_VOL);
      this.pairsModel.resetBothAnswers(message.correctAnswerIndex, message.numOfTries);
    }

    if (message.isCorrect || message.numOfTries >= 2) {
      this.pairsModel.setDescriptions(message.valueA, message.valueB);
      this.animationExecutor.schedule(this.pairsModel.tooltipAnimations[this.pairsModel.currentIndex].build());
    }
  }

  private onPairsRequestTimeout(): void {
    this.gameInfo.disableInteraction(false);
    const pairsAnswer = new PairsAnswer();
    pairsAnswer.responseIndex = NO_ANSWER;
    // FIXME
    // pairsAnswer.questionIndex = currentIndex;
    this.sendMessage(pairsAnswer);
  }

  private sendResponse(answerIndex: number, questionIndex: number) {
    const pairsAnswer = new PairsAnswer();
    pairsAnswer.responseIndex = answerIndex;
    pairsAnswer.questionIndex = questionIndex;
    this.sendMessage(pairsAnswer);
    this.gameInfo.stopTimer(true);
    this.gameInfo.setStatus('');
  }

  protected onStartGame(): void {
    this.pairsModel.resetModel();
    this.pairsModel.setActions((questionIndex: number, answerIndex: number) => {
      this.sendResponse(answerIndex, questionIndex);
    });
  }

  public getGameName(): string {
    return Local.getString('game.pairs');
  }
}
