import { types } from 'mobx-state-tree';
import { SpecialOffer } from '../../../messages/player/message/SpecialOffer';
import { ItemDesc } from '../../../messages/player/store/ItemDesc';
import { Offer } from '../../../messages/player/store/Offer';
import { ActionMST } from '../../ActionModel';
import { DisplayMST } from '../../DisplayModel';
import { createShopItemModel, ShopItemModel, ShopItemMST } from '../shop/ShopItemModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

// function sortOffersByCount(input: Array<Offer>): Array<Offer> {
//   return input.sort((a: Offer, b: Offer) => a.count - b.count);
// }

export const ShopTokensModelMST = DisplayMST.named('ShopTokensModel')
  .props({
    tokens: types.number,
    selectedGroup: types.number,
    selectedTab: types.number,
    horizontalGroup: types.number,
    tokenItems: types.array(ShopItemMST),
    infoAction: ActionMST,
    otherSMSAction: ActionMST,
    offerSMSShortKey: types.string,
    offerSMSPhoneNum: types.string,
    offerSMSCountry: types.string,
    offerSMSTitle: types.string,
    provider: types.number,
    voucherShown: false
  })
  .views(self => ({
    items() {
      return self.tokenItems;
    },
    getProvider() {
      return self.provider;
    }
  }))
  .actions(self => ({
    setTokenItems(offers: Array<Offer>, groupId: number, itemAction: Function, currency: string) {
      self.tokenItems.clear();
      const allItems: Array<ShopItemModel> = [];
      offers.forEach((offer: Offer) => {
        const tokenItem = createShopItemModel(offer.id, groupId, offer.id, offer.cost, currency, offer.count, offer.id === 3, false, false, false);
        tokenItem.itemAction.setAction(itemAction);
        allItems.push(tokenItem);
      });
      allItems.sort((a: ShopItemModel, b: ShopItemModel) => b.valueForPrice - a.valueForPrice);
      allItems.forEach(element => {
        self.tokenItems.push(element);
      });
    },
    showItem(item: ItemDesc, shortMode: boolean = true, onCloseHandler: Function = () => {}): void {
      self.show(onCloseHandler);
    },
    setSpecialOffer(offer: SpecialOffer) {
      self.offerSMSShortKey = offer.shortKey ? offer.shortKey : '';
      self.offerSMSPhoneNum = offer.phoneNumber ? offer.phoneNumber : '';
      self.offerSMSCountry = offer.country ? offer.country : '';
      self.offerSMSTitle = offer.title ? offer.title : '';
    },
    setProvider(provider: number) {
      self.provider = provider;
    },
    setVoucherShown(voucherShown: boolean) {
      self.voucherShown = voucherShown;
    }
  }));

type ShopTokensModelType = typeof ShopTokensModelMST.Type;
export interface ShopTokensModel extends ShopTokensModelType {}
export const createShopTokensModel = () =>
  ShopTokensModelMST.create({
    key: DisplayModelKeys.ShopTokens,
    tokens: 0,
    selectedGroup: -1,
    selectedTab: 2,
    horizontalGroup: 0,
    tokenItems: [],
    infoAction: {},
    otherSMSAction: {},
    offerSMSShortKey: '',
    offerSMSPhoneNum: '',
    offerSMSCountry: '',
    offerSMSTitle: '',
    provider: 0,
    voucherShown: false
  });
