export class Romanize {
  static toRoman(num: number): string {
    const numerals: Array<string> = ['M', 'CM', 'D', 'CD', 'C', 'XC', 'L', 'XL', 'X', 'IX', 'V', 'IV', 'I'];
    const numbers: Array<number> = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];

    let roman = '';
    let i: number;

    for (i = 0; i < numbers.length; i++) {
      while (num >= numbers[i]) {
        roman += numerals[i];
        num -= numbers[i];
      }
    }
    return roman;
  }
}
