import { ServerEvent } from '../../communication/message/ServerEvent';
import { RatingListPosition } from './RatingListPosition';

const BINDING_NAME = 'rs.slagalica.player.message.RaitingListResponse';

export class RaitingListResponse extends ServerEvent {
  position?: RatingListPosition = undefined; // fieldType: RatingListPosition

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
