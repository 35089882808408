import { APP } from 'common/src/app/AppInstance';
import { SoundPlayer } from 'common/src/app/sound/SoundPlayer';
import { Sounds } from './SoundResources';

export class WebSoundPlayer implements SoundPlayer {
  private music = new Audio(Sounds.intro);

  private oneChanelledSound: HTMLAudioElement | undefined = undefined;

  private oneChanneledUrl: string | undefined = undefined;

  constructor() {
    // TODO
  }

  public playSound(url: string, vol: number = 1, onEndedCallback: Function | null = null) {
    if (APP.Settings.isSoundsOn()) {
      const audio = new Audio(url);
      audio.volume = vol;
      audio.play();
      if (onEndedCallback !== null) {
        audio.onended = () => onEndedCallback();
      }
    }
  }

  public playOneChanelledSound(url: string, vol: number = 1) {
    if (APP.Settings.isSoundsOn() && this.oneChanneledUrl !== url) {
      if (this.oneChanelledSound) {
        this.oneChanelledSound.pause();
        this.oneChanelledSound.currentTime = 0;
      }

      this.oneChanelledSound = new Audio(url);
      this.oneChanelledSound.volume = vol;
      this.oneChanelledSound.onended = () => {
        this.oneChanelledSound = undefined;
        this.oneChanneledUrl = undefined;
      };
      this.oneChanneledUrl = url;

      this.oneChanelledSound.play();
    }
  }

  public playIntroMusic() {
    if (APP.Settings.isMusicOn()) {
      this.stopBackgroundMusic();

      this.music = new Audio(Sounds.intro);
      this.music.volume = 0.5;
      this.music.play();
      this.music.onended = () => {
        // b2b never play music
        // this.playBackgroundMusic();
      };
    }
  }

  public playBackgroundMusic() {
    if (APP.Settings.isMusicOn()) {
      this.stopBackgroundMusic();

      this.music = new Audio(Sounds.theme);
      this.music.volume = 0.5;
      this.music.loop = true;
      this.music.play();
    }
  }

  public stopBackgroundMusic() {
    if (this.music) {
      this.music.pause();
      this.music.remove();
    }
  }

  playCashboxSound(vol: number): void {
    this.playSound(Sounds.cashbox, vol);
  }

  playClockringSound(vol: number): void {
    this.playSound(Sounds.clockring, vol);
  }

  playCorrectSound(vol: number): void {
    this.playSound(Sounds.correct, vol);
  }

  playMessageSound(vol: number): void {
    this.playSound(Sounds.message, vol);
  }

  playZeitnotSound(vol: number): void {
    this.playSound(Sounds.zeitnot, vol);
  }

  playWrongSound(vol: number): void {
    this.playSound(Sounds.wrong, vol);
  }

  playWinningSound(vol: number): void {
    this.playSound(Sounds.winning, vol);
  }

  playLoosingSound(vol: number): void {
    this.playSound(Sounds.loosing, vol);
  }

  playInComingCallSound(vol: number): void {
    this.playSound(Sounds.inComingCall, vol);
  }

  playStartupGameSound(vol: number): void {
    this.playSound(Sounds.startupGame, vol);
  }

  playStartupGame2Sound(vol: number): void {
    this.playSound(Sounds.startupGame2, vol);
  }

  playScaleCrashSound(vol: number): void {
    this.playSound(Sounds.scaleCrash, vol);
  }

  playScaleDownSound(vol: number): void {
    this.playSound(Sounds.scaleDown, vol);
  }

  playAchShowSound(vol: number): void {
    this.playSound(Sounds.achShow, vol);
  }

  playEffectSound(id: number, vol: number): void {
    this.playSound(Sounds.soundEffects[id], vol);
  }

  playOneChanelledSoundEffect(id: number, vol: number): void {
    this.playOneChanelledSound(Sounds.soundEffects[id], vol);
  }
}
