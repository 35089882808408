import BaseAnimation from './BaseAnimation';

export default class AnimationExecutor {
  private burstMode: Boolean = false;

  private currentAnimation: BaseAnimation | undefined;

  public schedule(animation: BaseAnimation) {
    if (this.currentAnimation !== undefined && this.currentAnimation.isPlaying()) {
      this.currentAnimation.addEndListener(() => {
        this.schedule(animation);
      });
    } else {
      this.currentAnimation = animation;
      this.currentAnimation.play();
      if (this.burstMode) {
        this.currentAnimation.end();
      }
    }
  }

  public setBurstMode(burst: Boolean): void {
    this.burstMode = burst;
  }

  public cancel(): void {
    if (this.currentAnimation !== undefined && this.currentAnimation.isPlaying()) {
      this.currentAnimation.end();
    }
  }
}
