import { PlayerAction } from '../../../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.games.association.message.SolutionProvided';

export class SolutionProvided extends PlayerAction {
  position: number = -1;

  input?: string = undefined;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
