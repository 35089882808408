import { B2bTeamModel } from 'common/src/models/b2b/B2bTeamModel';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import B2bTeamTooltip from './B2bTeamTooltip';

interface B2bTournamentRowProps {
  team?: B2bTeamModel;
  isHeader?: boolean;
}

@observer
export default class B2bTournamentRow extends Component<B2bTournamentRowProps> {
  getTooltipPositionY(): number {
    return 15 + this.props.team!.rank * 8;
  }

  render() {
    const { team } = this.props;

    return this.props.isHeader ? (
      <div className="b2b-ranklist__row">
        <div className="b2b-ranklist__row b2b-ranklist__row--header">
          <div className="b2b-ranklist__item-number b2b-ranklist__item-number" />
          <div className="b2b-ranklist__name b2b-ranklist__name--expanded">tim</div>
          <div className="b2b-ranklist__item-score b2b-ranklist__item-score--expanded">pobede</div>
          <div className="b2b-ranklist__item-score b2b-ranklist__item-score--expanded">razlika</div>
        </div>
      </div>
    ) : (
      <div className={`b2b-ranklist__row b2b-ranklist__row--${team!.rank % 2 !== 0 ? 'odd' : 'even'}`}>
        <div className="b2b-ranklist__row b2b-ranklist__row--player">
          <div className="b2b-ranklist__item-number b2b-ranklist__item-number">{`${team!.rank}.`}</div>
          <div className="b2b-ranklist__name b2b-ranklist__name">
            <B2bTeamTooltip team={this.props.team!} toolTipPositionX={10} toolTipPositionY={this.getTooltipPositionY()} />
            {team!.name}
            {team!.companyName ? ` (${team!.companyName})` : ''}
          </div>
          <div className={`b2b-ranklist__item-score-container b2b-ranklist__item-score-container--${team!.rank % 2 !== 0 ? 'odd' : 'even'}`}>
            <div className="b2b-ranklist__item-score b2b-ranklist__item-score">{team!.wins}</div>
            <div className="b2b-ranklist__item-score b2b-ranklist__item-score">{team!.diff}</div>
          </div>
        </div>
      </div>
    );
  }
}
