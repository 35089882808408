import { Promotion } from '../messages/player/message/Promotion';

const DEBUG: Boolean = false;
export const TypeNone = -1;

export abstract class AppSettings {
  // temporary saved setings
  private activePromotion: Promotion | undefined = undefined;

  abstract isStorageAvailable(): boolean;

  abstract getMachineId(): string;

  abstract isMusicOn(): boolean;

  abstract enableMusic(enable: Boolean): void;

  abstract isSoundsOn(): boolean;

  abstract enableSounds(enable: Boolean): void;

  abstract updateBoosterPromotionDate(date: Date): void;

  abstract resetGameGetsHarderInfo(): void;

  abstract showGameGetsHarderInfo(): boolean;

  abstract getGameUrl(): string;

  abstract friendsEnabled(): boolean;

  abstract getServerUrl(): string;

  abstract isNewAppInfoDialogShown(): boolean;

  abstract setNewAppInfoDialogShown(): void;

  abstract isNewPaymentInfoDialogShown(): boolean;

  abstract setNewPaymentInfoDialogShown(): void;

  abstract getCurrentAccountType(): number;

  abstract setCurrentAccountType(type: number): void;

  abstract getPlatformType(): number;

  abstract setB2bTournament(type: string): void;

  abstract getB2bTournament(): string;

  abstract setB2bTeam(type: string): void;

  abstract getB2bTeam(): string;

  abstract setB2bName(type: string): void;

  abstract getB2bName(): string;

  abstract setB2bId(type: string): void;

  abstract getB2bId(): string;

  abstract setB2bAutologin(type: Boolean): void;

  abstract getB2bAutologin(): boolean;

  constructor() {
    console.log('Constructor AppSetting');
  }

  public getAccessKey() {
    // FIXME should be https ???
    return '43404add2f4d674317a5d9d7e63ed14b';
  }

  public isDebug() {
    return DEBUG;
  }

  public setActivePromotion(promotion: any): void {
    this.activePromotion = promotion;
  }

  public getActivePromotion(): any {
    return this.activePromotion;
  }
}
