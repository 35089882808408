import { MessageSerializable } from '../../../MessageSerializable';

const BINDING_NAME = 'rs.slagalica.player.reward.message.Reward';

const TYPE_ITEM_FROM_STORE = 1; // fieldType: int
const TYPE_RATING_POINTS = 2; // fieldType: int
const TYPE_TOKENS = 3; // fieldType: int

export class Reward extends MessageSerializable {
  type: number = 0;

  // fieldType: int
  groupId: number = 0;

  // fieldType: int
  itemId: number = 0;

  // fieldType: int
  count: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get TYPE_ITEM_FROM_STORE() {
    return TYPE_ITEM_FROM_STORE;
  }

  static get TYPE_RATING_POINTS() {
    return TYPE_RATING_POINTS;
  }

  static get TYPE_TOKENS() {
    return TYPE_TOKENS;
  }
}
