/* eslint-disable camelcase */
import { AMF3, AMFDecoder } from 'amfjs';
import { B2bAdminCommandAction as b2b_admin_B2bAdminCommandAction } from './b2b/B2bAdminCommandAction';
import { B2bCaptainRequest as player_message_B2bCaptainRequest } from './b2b/B2bCaptainRequest';
import { B2bCaptainResponse as player_message_B2bCaptainResponse } from './b2b/B2bCaptainResponse';
import { B2bChangeTeamImage as b2b_admin_B2bChangeTeamImage } from './b2b/B2bChangeTeamImage';
import { B2bCreateTournamentAction as b2b_admin_B2bCreateTournamentAction } from './b2b/B2bCreateTournamentAction';
import { B2bDeclareCaptain as player_message_B2bDeclareCaptain } from './b2b/B2bDeclareCaptain';
import { B2bGameReady as player_message_B2bGameReady } from './b2b/B2bGameReady';
import { B2bGameSequenceStarted as player_message_B2bGameSequenceStarted } from './b2b/B2bGameSequenceStarted';
import { B2bNoCaptain as player_message_B2bNoCaptain } from './b2b/B2bNoCaptain';
import { B2bPerformDrawAction as b2b_admin_B2bPerformDrawAction } from './b2b/B2bPerformDrawAction';
import { B2bQuizAnswer as player_message_B2bQuizAnswer } from './b2b/B2bQuizAnswer';
import { B2bReadyEvent as b2b_admin_B2bReadyEvent } from './b2b/B2bReadyEvent';
import { B2bTournamentEvent as b2b_admin_B2bTournamentEvent } from './b2b/B2bTournamentEvent';
import { B2bTournamentInfo as b2b_admin_B2bTournamentInfo } from './b2b/B2bTournamentInfo';
import { B2bUpdateTournamentAction as b2b_admin_B2bUpdateTournamentAction } from './b2b/B2bUpdateTournamentAction';
import { LogoutFromChat as chat_message_LogoutFromChat } from './chat/message/LogoutFromChat';
import { MessageFromClient as chat_message_MessageFromClient } from './chat/message/MessageFromClient';
import { MessageFromServer as chat_message_MessageFromServer } from './chat/message/MessageFromServer';
import { OpponentLeftChat as chat_message_OpponentLeftChat } from './chat/message/OpponentLeftChat';
import { FinishGame as communication_message_FinishGame } from './communication/message/FinishGame';
import { GameAvailable as communication_message_GameAvailable } from './communication/message/GameAvailable';
import { GameFinished as communication_message_GameFinished } from './communication/message/GameFinished';
import { GameResumed as communication_message_GameResumed } from './communication/message/GameResumed';
import { GcmRegistrationInfo as communication_message_GcmRegistrationInfo } from './communication/message/GcmRegistrationInfo';
import { HelloMessage as communication_message_HelloMessage } from './communication/message/HelloMessage';
import { Message as communication_message_Message } from './communication/message/Message';
import { PlayerAction as communication_message_PlayerAction } from './communication/message/PlayerAction';
import { PointsUpdate as communication_message_PointsUpdate } from './communication/message/PointsUpdate';
import { ReadyEvent as communication_message_ReadyEvent } from './communication/message/ReadyEvent';
import { ResumeGame as communication_message_ResumeGame } from './communication/message/ResumeGame';
import { Score as communication_message_Score } from './communication/message/Score';
import { ServerEvent as communication_message_ServerEvent } from './communication/message/ServerEvent';
import { ServerEventGroup as communication_message_ServerEventGroup } from './communication/message/ServerEventGroup';
import { SignInCompleted as communication_message_SignInCompleted } from './communication/message/SignInCompleted';
import { StartControllerEvent as communication_message_StartControllerEvent } from './communication/message/StartControllerEvent';
import { StopControllerEvent as communication_message_StopControllerEvent } from './communication/message/StopControllerEvent';
import { SyncRequest as communication_message_SyncRequest } from './communication/message/SyncRequest';
import { RedeemResult as credits_message_RedeemResult } from './credits/message/RedeemResult';
import { RedeemVoucherCode as credits_message_RedeemVoucherCode } from './credits/message/RedeemVoucherCode';
import { AssociationRewardStrategy as games_association_AssociationRewardStrategy } from './games/association/AssociationRewardStrategy';
import { AssociationColumn as games_association_message_AssociationColumn } from './games/association/message/AssociationColumn';
import { OpenedFieldContent as games_association_message_OpenedFieldContent } from './games/association/message/OpenedFieldContent';
import { OpenField as games_association_message_OpenField } from './games/association/message/OpenField';
import { RequestOpenField as games_association_message_RequestOpenField } from './games/association/message/RequestOpenField';
import { RequestSolution as games_association_message_RequestSolution } from './games/association/message/RequestSolution';
import { SimpleField as games_association_message_SimpleField } from './games/association/message/SimpleField';
import { SimpleUpdateContent as games_association_message_SimpleUpdateContent } from './games/association/message/SimpleUpdateContent';
import { SolutionProvided as games_association_message_SolutionProvided } from './games/association/message/SolutionProvided';
import { SolutionResult as games_association_message_SolutionResult } from './games/association/message/SolutionResult';
import { CombinationResult as games_combinations_message_CombinationResult } from './games/combinations/message/CombinationResult';
import { CombinationsStatus as games_combinations_message_CombinationsStatus } from './games/combinations/message/CombinationsStatus';
import { PlayerCombination as games_combinations_message_PlayerCombination } from './games/combinations/message/PlayerCombination';
import { StartSolving as games_combinations_message_StartSolving } from './games/combinations/message/StartSolving';
import { MatchingContent as games_matching_message_MatchingContent } from './games/matching/message/MatchingContent';
import { MatchingFinal as games_matching_message_MatchingFinal } from './games/matching/message/MatchingFinal';
import { MatchingResponse as games_matching_message_MatchingResponse } from './games/matching/message/MatchingResponse';
import { MatchingResult as games_matching_message_MatchingResult } from './games/matching/message/MatchingResult';
import { MatchRequest as games_matching_message_MatchRequest } from './games/matching/message/MatchRequest';
import { ChooseNumbers as games_mynumber_message_ChooseNumbers } from './games/mynumber/message/ChooseNumbers';
import { MyNumberStatus as games_mynumber_message_MyNumberStatus } from './games/mynumber/message/MyNumberStatus';
import { SolutionAck as games_mynumber_message_SolutionAck } from './games/mynumber/message/SolutionAck';
import { SolutionProvided as games_mynumber_message_SolutionProvided } from './games/mynumber/message/SolutionProvided';
import { StartChoosingNumbers as games_mynumber_message_StartChoosingNumbers } from './games/mynumber/message/StartChoosingNumbers';
import { StartSolving as games_mynumber_message_StartSolving } from './games/mynumber/message/StartSolving';
import { PairsAnswer as games_pairs_message_PairsAnswer } from './games/pairs/message/PairsAnswer';
import { PairsGameQuestion as games_pairs_message_PairsGameQuestion } from './games/pairs/message/PairsGameQuestion';
import { PairsResult as games_pairs_message_PairsResult } from './games/pairs/message/PairsResult';
import { SendPairsQuestion as games_pairs_message_SendPairsQuestion } from './games/pairs/message/SendPairsQuestion';
import { AppealWord as games_puzzle_message_AppealWord } from './games/puzzle/message/AppealWord';
import { CheckWord as games_puzzle_message_CheckWord } from './games/puzzle/message/CheckWord';
import { ChooseLetters as games_puzzle_message_ChooseLetters } from './games/puzzle/message/ChooseLetters';
import { PuzzleStatus as games_puzzle_message_PuzzleStatus } from './games/puzzle/message/PuzzleStatus';
import { SolutionAck as games_puzzle_message_SolutionAck } from './games/puzzle/message/SolutionAck';
import { SolutionProvided as games_puzzle_message_SolutionProvided } from './games/puzzle/message/SolutionProvided';
import { SolutionResult as games_puzzle_message_SolutionResult } from './games/puzzle/message/SolutionResult';
import { StartChoosingLetters as games_puzzle_message_StartChoosingLetters } from './games/puzzle/message/StartChoosingLetters';
import { StartSolving as games_puzzle_message_StartSolving } from './games/puzzle/message/StartSolving';
import { WordStatus as games_puzzle_message_WordStatus } from './games/puzzle/message/WordStatus';
import { Puzzle as games_puzzle_Puzzle } from './games/puzzle/Puzzle';
import { Answer as games_quiz_message_Answer } from './games/quiz/message/Answer';
import { AnswerResult as games_quiz_message_AnswerResult } from './games/quiz/message/AnswerResult';
import { SendTheQuestion as games_quiz_message_SendTheQuestion } from './games/quiz/message/SendTheQuestion';
import { Achievement as player_achievements_message_Achievement } from './player/achievements/message/Achievement';
import { AchievementInfo as player_achievements_message_AchievementInfo } from './player/achievements/message/AchievementInfo';
import { AchievementReward as player_achievements_message_AchievementReward } from './player/achievements/message/AchievementReward';
import { AchievementRewardCollection as player_achievements_message_AchievementRewardCollection } from './player/achievements/message/AchievementRewardCollection';
import { AchievementTargetValue as player_achievements_message_AchievementTargetValue } from './player/achievements/message/AchievementTargetValue';
import { AchievementTargetValuesCollection as player_achievements_message_AchievementTargetValuesCollection } from './player/achievements/message/AchievementTargetValuesCollection';
import { ApplyAchievementReward as player_achievements_message_ApplyAchievementReward } from './player/achievements/message/ApplyAchievementReward';
import { GetAchievementReward as player_achievements_message_GetAchievementReward } from './player/achievements/message/GetAchievementReward';
import { CommercialSurvey as player_commercialsurvey_message_CommercialSurvey } from './player/commercialsurvey/message/CommercialSurvey';
import { LeagueHistory as player_history_message_LeagueHistory } from './player/history/message/LeagueHistory';
import { LeagueHistoryRequest as player_history_message_LeagueHistoryRequest } from './player/history/message/LeagueHistoryRequest';
import { LeagueHistoryResponse as player_history_message_LeagueHistoryResponse } from './player/history/message/LeagueHistoryResponse';
import { LeagueInfo as player_league_message_LeagueInfo } from './player/league/message/LeagueInfo';
import { LeagueTopPositioned as player_league_message_LeagueTopPositioned } from './player/league/message/LeagueTopPositioned';
import { ListPosition as player_league_message_ListPosition } from './player/league/message/ListPosition';
import { ListPositionMaximum as player_league_message_ListPositionMaximum } from './player/league/message/ListPositionMaximum';
import { PositionRewards as player_league_message_PositionRewards } from './player/league/message/PositionRewards';
import { PreviousLeagueInfo as player_league_message_PreviousLeagueInfo } from './player/league/message/PreviousLeagueInfo';
import { AcceptGame as player_message_AcceptGame } from './player/message/AcceptGame';
import { AllOffers as player_message_AllOffers } from './player/message/AllOffers';
import { AndroidPayment as player_message_AndroidPayment } from './player/message/AndroidPayment';
import { BuyListEntrance as player_message_BuyListEntrance } from './player/message/BuyListEntrance';
import { CancelPlayRandom as player_message_CancelPlayRandom } from './player/message/CancelPlayRandom';
import { CancelRequest as player_message_CancelRequest } from './player/message/CancelRequest';
import { CancelResumeGame as player_message_CancelResumeGame } from './player/message/CancelResumeGame';
import { ChallengeRequest as player_message_ChallengeRequest } from './player/message/ChallengeRequest';
import { ChallengeResponse as player_message_ChallengeResponse } from './player/message/ChallengeResponse';
import { Cheater as player_message_Cheater } from './player/message/Cheater';
import { DailyGame as player_message_DailyGame } from './player/message/DailyGame';
import { DailyReward as player_message_DailyReward } from './player/message/DailyReward';
import { Device as player_message_Device } from './player/message/Device';
import { DiscountResponse as player_message_DiscountResponse } from './player/message/DiscountResponse';
import { FeatureConfig as player_message_FeatureConfig } from './player/message/FeatureConfig';
import { FriendCancelRequest as player_message_FriendCancelRequest } from './player/message/FriendCancelRequest';
import { FriendChallengeRequest as player_message_FriendChallengeRequest } from './player/message/FriendChallengeRequest';
import { FriendChallengeResponse as player_message_FriendChallengeResponse } from './player/message/FriendChallengeResponse';
import { FriendsStatus as player_message_FriendsStatus } from './player/message/FriendsStatus';
import { FriendStatusUpdate as player_message_FriendStatusUpdate } from './player/message/FriendStatusUpdate';
import { Game as player_message_Game } from './player/message/Game';
import { GameReadyToStart as player_message_GameReadyToStart } from './player/message/GameReadyToStart';
import { GameResult as player_message_GameResult } from './player/message/GameResult';
import { GameSequenceFinished as player_message_GameSequenceFinished } from './player/message/GameSequenceFinished';
import { GameSequenceStarted as player_message_GameSequenceStarted } from './player/message/GameSequenceStarted';
import { HappyBirthday as player_message_HappyBirthday } from './player/message/HappyBirthday';
import { ListEntranceSuccessful as player_message_ListEntranceSuccessful } from './player/message/ListEntranceSuccessful';
import { ListPositionMaximum as player_message_ListPositionMaximum } from './player/message/ListPositionMaximum';
import { LoadFriends as player_message_LoadFriends } from './player/message/LoadFriends';
import { LoadRangList as player_message_LoadRangList } from './player/message/LoadRangList';
import { LogOffDone as player_message_LogOffDone } from './player/message/LogOffDone';
import { NewDayStarts as player_message_NewDayStarts } from './player/message/NewDayStarts';
import { NewPayment as player_message_NewPayment } from './player/message/NewPayment';
import { NewsRequest as player_message_NewsRequest } from './player/message/NewsRequest';
import { NewsResponse as player_message_NewsResponse } from './player/message/NewsResponse';
import { NotifDelete as player_message_NotifDelete } from './player/message/NotifDelete';
import { NotifNew as player_message_NotifNew } from './player/message/NotifNew';
import { NotifRead as player_message_NotifRead } from './player/message/NotifRead';
import { NotifRequest as player_message_NotifRequest } from './player/message/NotifRequest';
import { NotifStatus as player_message_NotifStatus } from './player/message/NotifStatus';
import { OpponentInfoUpdate as player_message_OpponentInfoUpdate } from './player/message/OpponentInfoUpdate';
import { OpponentLeftGame as player_message_OpponentLeftGame } from './player/message/OpponentLeftGame';
import { OpponentResumedGame as player_message_OpponentResumedGame } from './player/message/OpponentResumedGame';
import { OpponentStartGameResponse as player_message_OpponentStartGameResponse } from './player/message/OpponentStartGameResponse';
import { PaymentGateway as player_message_PaymentGateway } from './player/message/PaymentGateway';
import { PlayerInfo as player_message_PlayerInfo } from './player/message/PlayerInfo';
import { PlayerInfoBase as player_message_PlayerInfoBase } from './player/message/PlayerInfoBase';
import { PlayerPing as player_message_PlayerPing } from './player/message/PlayerPing';
import { PlayNowRandom as player_message_PlayNowRandom } from './player/message/PlayNowRandom';
import { PlayWithFriend as player_message_PlayWithFriend } from './player/message/PlayWithFriend';
import { PromotionAndroid as player_message_PromotionAndroid } from './player/message/PromotionAndroid';
import { PromotionAndroidList as player_message_PromotionAndroidList } from './player/message/PromotionAndroidList';
import { PromotionResponse as player_message_PromotionResponse } from './player/message/PromotionResponse';
import { RaitingListRequest as player_message_RaitingListRequest } from './player/message/RaitingListRequest';
import { RaitingListResponse as player_message_RaitingListResponse } from './player/message/RaitingListResponse';
import { RangList as player_message_RangList } from './player/message/RangList';
import { RatingListPosition as player_message_RatingListPosition } from './player/message/RatingListPosition';
import { RequestPlayerInfo as player_message_RequestPlayerInfo } from './player/message/RequestPlayerInfo';
import { RequestStorageUpdate as player_message_RequestStorageUpdate } from './player/message/RequestStorageUpdate';
import { SendAppeal as player_message_SendAppeal } from './player/message/SendAppeal';
import { SendCredits as player_message_SendCredits } from './player/message/SendCredits';
import { ServerStatus as player_message_ServerStatus } from './player/message/ServerStatus';
import { SignIn as player_message_SignIn } from './player/message/SignIn';
import { SignOut as player_message_SignOut } from './player/message/SignOut';
import { SingleGameResult as player_message_SingleGameResult } from './player/message/SingleGameResult';
import { SpecialOffer as player_message_SpecialOffer } from './player/message/SpecialOffer';
import { StartGameResponse as player_message_StartGameResponse } from './player/message/StartGameResponse';
import { SurveyCollection as player_message_SurveyCollection } from './player/message/SurveyCollection';
import { SurveyRequest as player_message_SurveyRequest } from './player/message/SurveyRequest';
import { SurveyResponse as player_message_SurveyResponse } from './player/message/SurveyResponse';
import { News as player_News } from './player/News';
import { RankChanged as player_reward_message_RankChanged } from './player/reward/message/RankChanged';
import { Reward as player_reward_message_Reward } from './player/reward/message/Reward';
import { Item as player_store_Item } from './player/store/Item';
import { ItemDesc as player_store_ItemDesc } from './player/store/ItemDesc';
import { ActivateItem as player_store_message_ActivateItem } from './player/store/message/ActivateItem';
import { BuyItem as player_store_message_BuyItem } from './player/store/message/BuyItem';
import { DeactivateItem as player_store_message_DeactivateItem } from './player/store/message/DeactivateItem';
import { LoadStore as player_store_message_LoadStore } from './player/store/message/LoadStore';
import { PlayGamblingGame as player_store_message_PlayGamblingGame } from './player/store/message/PlayGamblingGame';
import { PlaySound as player_store_message_PlaySound } from './player/store/message/PlaySound';
import { ReceiveGift as player_store_message_ReceiveGift } from './player/store/message/ReceiveGift';
import { SendGift as player_store_message_SendGift } from './player/store/message/SendGift';
import { SendSound as player_store_message_SendSound } from './player/store/message/SendSound';
import { StorageContent as player_store_message_StorageContent } from './player/store/message/StorageContent';
import { StorageUpdateStatus as player_store_message_StorageUpdateStatus } from './player/store/message/StorageUpdateStatus';
import { StoreContent as player_store_message_StoreContent } from './player/store/message/StoreContent';
import { Offer as player_store_Offer } from './player/store/Offer';

AMFDecoder.register(communication_message_ServerEvent.ClassName, communication_message_ServerEvent, AMF3);
AMFDecoder.register(communication_message_StopControllerEvent.ClassName, communication_message_StopControllerEvent, AMF3);
AMFDecoder.register(player_history_message_LeagueHistory.ClassName, player_history_message_LeagueHistory, AMF3);
AMFDecoder.register(player_store_message_StorageContent.ClassName, player_store_message_StorageContent, AMF3);
AMFDecoder.register(player_history_message_LeagueHistoryRequest.ClassName, player_history_message_LeagueHistoryRequest, AMF3);
AMFDecoder.register(player_message_AndroidPayment.ClassName, player_message_AndroidPayment, AMF3);
AMFDecoder.register(player_reward_message_Reward.ClassName, player_reward_message_Reward, AMF3);
AMFDecoder.register(player_message_CancelResumeGame.ClassName, player_message_CancelResumeGame, AMF3);
AMFDecoder.register(player_message_GameReadyToStart.ClassName, player_message_GameReadyToStart, AMF3);
AMFDecoder.register(games_puzzle_message_ChooseLetters.ClassName, games_puzzle_message_ChooseLetters, AMF3);
AMFDecoder.register(player_message_Cheater.ClassName, player_message_Cheater, AMF3);
AMFDecoder.register(player_message_PromotionAndroid.ClassName, player_message_PromotionAndroid, AMF3);
AMFDecoder.register(player_message_RequestPlayerInfo.ClassName, player_message_RequestPlayerInfo, AMF3);
AMFDecoder.register(player_message_PlayerInfoBase.ClassName, player_message_PlayerInfoBase, AMF3);
AMFDecoder.register(games_association_message_SolutionResult.ClassName, games_association_message_SolutionResult, AMF3);
AMFDecoder.register(communication_message_ResumeGame.ClassName, communication_message_ResumeGame, AMF3);
AMFDecoder.register(player_message_PlayerInfo.ClassName, player_message_PlayerInfo, AMF3);
AMFDecoder.register(player_message_SurveyResponse.ClassName, player_message_SurveyResponse, AMF3);
AMFDecoder.register(player_message_SurveyCollection.ClassName, player_message_SurveyCollection, AMF3);
AMFDecoder.register(player_message_PromotionResponse.ClassName, player_message_PromotionResponse, AMF3);
AMFDecoder.register(player_message_PlayWithFriend.ClassName, player_message_PlayWithFriend, AMF3);
AMFDecoder.register(communication_message_HelloMessage.ClassName, communication_message_HelloMessage, AMF3);
AMFDecoder.register(player_league_message_LeagueInfo.ClassName, player_league_message_LeagueInfo, AMF3);
AMFDecoder.register(games_puzzle_message_StartSolving.ClassName, games_puzzle_message_StartSolving, AMF3);
AMFDecoder.register(player_message_NotifNew.ClassName, player_message_NotifNew, AMF3);
AMFDecoder.register(games_association_message_SimpleField.ClassName, games_association_message_SimpleField, AMF3);
AMFDecoder.register(games_matching_message_MatchingResponse.ClassName, games_matching_message_MatchingResponse, AMF3);
AMFDecoder.register(player_store_Offer.ClassName, player_store_Offer, AMF3);
AMFDecoder.register(player_achievements_message_GetAchievementReward.ClassName, player_achievements_message_GetAchievementReward, AMF3);
AMFDecoder.register(player_message_RaitingListRequest.ClassName, player_message_RaitingListRequest, AMF3);
AMFDecoder.register(games_association_message_RequestSolution.ClassName, games_association_message_RequestSolution, AMF3);
AMFDecoder.register(player_store_message_SendGift.ClassName, player_store_message_SendGift, AMF3);
AMFDecoder.register(communication_message_ServerEventGroup.ClassName, communication_message_ServerEventGroup, AMF3);
AMFDecoder.register(communication_message_Score.ClassName, communication_message_Score, AMF3);
AMFDecoder.register(player_league_message_ListPosition.ClassName, player_league_message_ListPosition, AMF3);
AMFDecoder.register(player_message_LoadRangList.ClassName, player_message_LoadRangList, AMF3);
AMFDecoder.register(player_message_SignOut.ClassName, player_message_SignOut, AMF3);
AMFDecoder.register(communication_message_GameFinished.ClassName, communication_message_GameFinished, AMF3);
AMFDecoder.register(player_message_PlayerPing.ClassName, player_message_PlayerPing, AMF3);
AMFDecoder.register(games_association_message_RequestOpenField.ClassName, games_association_message_RequestOpenField, AMF3);
AMFDecoder.register(player_message_LogOffDone.ClassName, player_message_LogOffDone, AMF3);
AMFDecoder.register(player_message_NewPayment.ClassName, player_message_NewPayment, AMF3);
AMFDecoder.register(player_message_ServerStatus.ClassName, player_message_ServerStatus, AMF3);
AMFDecoder.register(chat_message_MessageFromClient.ClassName, chat_message_MessageFromClient, AMF3);
AMFDecoder.register(player_message_ListPositionMaximum.ClassName, player_message_ListPositionMaximum, AMF3);
AMFDecoder.register(player_league_message_ListPositionMaximum.ClassName, player_league_message_ListPositionMaximum, AMF3);
AMFDecoder.register(player_message_PromotionAndroidList.ClassName, player_message_PromotionAndroidList, AMF3);
AMFDecoder.register(player_message_OpponentLeftGame.ClassName, player_message_OpponentLeftGame, AMF3);
AMFDecoder.register(player_achievements_message_ApplyAchievementReward.ClassName, player_achievements_message_ApplyAchievementReward, AMF3);
AMFDecoder.register(games_puzzle_message_WordStatus.ClassName, games_puzzle_message_WordStatus, AMF3);
AMFDecoder.register(player_message_BuyListEntrance.ClassName, player_message_BuyListEntrance, AMF3);
AMFDecoder.register(player_message_SendCredits.ClassName, player_message_SendCredits, AMF3);
AMFDecoder.register(player_message_SurveyRequest.ClassName, player_message_SurveyRequest, AMF3);
AMFDecoder.register(games_matching_message_MatchRequest.ClassName, games_matching_message_MatchRequest, AMF3);
AMFDecoder.register(communication_message_GameResumed.ClassName, communication_message_GameResumed, AMF3);
AMFDecoder.register(player_message_OpponentResumedGame.ClassName, player_message_OpponentResumedGame, AMF3);
AMFDecoder.register(player_message_Game.ClassName, player_message_Game, AMF3);
AMFDecoder.register(player_store_message_DeactivateItem.ClassName, player_store_message_DeactivateItem, AMF3);
AMFDecoder.register(player_store_Item.ClassName, player_store_Item, AMF3);
AMFDecoder.register(games_puzzle_message_CheckWord.ClassName, games_puzzle_message_CheckWord, AMF3);
AMFDecoder.register(player_message_GameSequenceFinished.ClassName, player_message_GameSequenceFinished, AMF3);
AMFDecoder.register(player_message_CancelRequest.ClassName, player_message_CancelRequest, AMF3);
AMFDecoder.register(communication_message_GcmRegistrationInfo.ClassName, communication_message_GcmRegistrationInfo, AMF3);
AMFDecoder.register(player_message_NotifStatus.ClassName, player_message_NotifStatus, AMF3);
AMFDecoder.register(games_association_message_OpenField.ClassName, games_association_message_OpenField, AMF3);
AMFDecoder.register(player_message_RaitingListResponse.ClassName, player_message_RaitingListResponse, AMF3);
AMFDecoder.register(games_matching_message_MatchingFinal.ClassName, games_matching_message_MatchingFinal, AMF3);
AMFDecoder.register(communication_message_ReadyEvent.ClassName, communication_message_ReadyEvent, AMF3);
AMFDecoder.register(chat_message_MessageFromServer.ClassName, chat_message_MessageFromServer, AMF3);
AMFDecoder.register(communication_message_StartControllerEvent.ClassName, communication_message_StartControllerEvent, AMF3);
AMFDecoder.register(player_store_ItemDesc.ClassName, player_store_ItemDesc, AMF3);
AMFDecoder.register(communication_message_SignInCompleted.ClassName, communication_message_SignInCompleted, AMF3);
AMFDecoder.register(player_message_AcceptGame.ClassName, player_message_AcceptGame, AMF3);
AMFDecoder.register(player_store_message_SendSound.ClassName, player_store_message_SendSound, AMF3);
AMFDecoder.register(player_message_RatingListPosition.ClassName, player_message_RatingListPosition, AMF3);
AMFDecoder.register(player_message_Device.ClassName, player_message_Device, AMF3);
AMFDecoder.register(communication_message_PointsUpdate.ClassName, communication_message_PointsUpdate, AMF3);
AMFDecoder.register(player_store_message_StorageUpdateStatus.ClassName, player_store_message_StorageUpdateStatus, AMF3);
AMFDecoder.register(player_store_message_BuyItem.ClassName, player_store_message_BuyItem, AMF3);
AMFDecoder.register(player_message_NewsResponse.ClassName, player_message_NewsResponse, AMF3);
AMFDecoder.register(games_matching_message_MatchingResult.ClassName, games_matching_message_MatchingResult, AMF3);
AMFDecoder.register(player_store_message_ReceiveGift.ClassName, player_store_message_ReceiveGift, AMF3);
AMFDecoder.register(player_message_FriendStatusUpdate.ClassName, player_message_FriendStatusUpdate, AMF3);
AMFDecoder.register(player_league_message_PositionRewards.ClassName, player_league_message_PositionRewards, AMF3);
AMFDecoder.register(player_achievements_message_AchievementReward.ClassName, player_achievements_message_AchievementReward, AMF3);
AMFDecoder.register(player_message_FriendChallengeResponse.ClassName, player_message_FriendChallengeResponse, AMF3);
AMFDecoder.register(player_message_SingleGameResult.ClassName, player_message_SingleGameResult, AMF3);
AMFDecoder.register(player_store_message_LoadStore.ClassName, player_store_message_LoadStore, AMF3);
AMFDecoder.register(games_association_message_SimpleUpdateContent.ClassName, games_association_message_SimpleUpdateContent, AMF3);
AMFDecoder.register(player_message_StartGameResponse.ClassName, player_message_StartGameResponse, AMF3);
AMFDecoder.register(player_message_SignIn.ClassName, player_message_SignIn, AMF3);
AMFDecoder.register(games_puzzle_message_SolutionAck.ClassName, games_puzzle_message_SolutionAck, AMF3);
AMFDecoder.register(player_News.ClassName, player_News, AMF3);
AMFDecoder.register(player_message_ChallengeRequest.ClassName, player_message_ChallengeRequest, AMF3);
AMFDecoder.register(player_message_OpponentStartGameResponse.ClassName, player_message_OpponentStartGameResponse, AMF3);
AMFDecoder.register(games_puzzle_message_SolutionResult.ClassName, games_puzzle_message_SolutionResult, AMF3);
AMFDecoder.register(communication_message_GameAvailable.ClassName, communication_message_GameAvailable, AMF3);
AMFDecoder.register(player_message_RequestStorageUpdate.ClassName, player_message_RequestStorageUpdate, AMF3);
AMFDecoder.register(player_message_NewDayStarts.ClassName, player_message_NewDayStarts, AMF3);
AMFDecoder.register(player_store_message_ActivateItem.ClassName, player_store_message_ActivateItem, AMF3);
AMFDecoder.register(player_message_ListEntranceSuccessful.ClassName, player_message_ListEntranceSuccessful, AMF3);
AMFDecoder.register(games_quiz_message_AnswerResult.ClassName, games_quiz_message_AnswerResult, AMF3);
AMFDecoder.register(player_message_NewsRequest.ClassName, player_message_NewsRequest, AMF3);
AMFDecoder.register(player_message_GameResult.ClassName, player_message_GameResult, AMF3);
AMFDecoder.register(player_message_OpponentInfoUpdate.ClassName, player_message_OpponentInfoUpdate, AMF3);
AMFDecoder.register(player_store_message_PlaySound.ClassName, player_store_message_PlaySound, AMF3);
AMFDecoder.register(communication_message_FinishGame.ClassName, communication_message_FinishGame, AMF3);
AMFDecoder.register(chat_message_OpponentLeftChat.ClassName, chat_message_OpponentLeftChat, AMF3);
AMFDecoder.register(player_message_DailyGame.ClassName, player_message_DailyGame, AMF3);
AMFDecoder.register(games_puzzle_message_SolutionProvided.ClassName, games_puzzle_message_SolutionProvided, AMF3);
AMFDecoder.register(player_message_CancelPlayRandom.ClassName, player_message_CancelPlayRandom, AMF3);
AMFDecoder.register(player_message_LoadFriends.ClassName, player_message_LoadFriends, AMF3);
AMFDecoder.register(games_association_message_AssociationColumn.ClassName, games_association_message_AssociationColumn, AMF3);
AMFDecoder.register(communication_message_Message.ClassName, communication_message_Message, AMF3);
AMFDecoder.register(games_puzzle_message_StartChoosingLetters.ClassName, games_puzzle_message_StartChoosingLetters, AMF3);
AMFDecoder.register(player_achievements_message_AchievementInfo.ClassName, player_achievements_message_AchievementInfo, AMF3);
AMFDecoder.register(games_association_message_OpenedFieldContent.ClassName, games_association_message_OpenedFieldContent, AMF3);
AMFDecoder.register(player_achievements_message_Achievement.ClassName, player_achievements_message_Achievement, AMF3);
AMFDecoder.register(games_matching_message_MatchingContent.ClassName, games_matching_message_MatchingContent, AMF3);
AMFDecoder.register(player_message_SpecialOffer.ClassName, player_message_SpecialOffer, AMF3);
AMFDecoder.register(player_message_AllOffers.ClassName, player_message_AllOffers, AMF3);
AMFDecoder.register(communication_message_SyncRequest.ClassName, communication_message_SyncRequest, AMF3);
AMFDecoder.register(player_message_GameSequenceStarted.ClassName, player_message_GameSequenceStarted, AMF3);
AMFDecoder.register(player_message_B2bGameSequenceStarted.ClassName, player_message_B2bGameSequenceStarted, AMF3);
AMFDecoder.register(player_message_B2bCaptainRequest.ClassName, player_message_B2bCaptainRequest, AMF3);
AMFDecoder.register(player_message_B2bGameReady.ClassName, player_message_B2bGameReady, AMF3);
AMFDecoder.register(player_message_B2bCaptainResponse.ClassName, player_message_B2bCaptainResponse, AMF3);
AMFDecoder.register(player_message_B2bDeclareCaptain.ClassName, player_message_B2bDeclareCaptain, AMF3);
AMFDecoder.register(player_message_B2bNoCaptain.ClassName, player_message_B2bNoCaptain, AMF3);
AMFDecoder.register(player_message_B2bQuizAnswer.ClassName, player_message_B2bQuizAnswer, AMF3);
AMFDecoder.register(player_message_NotifRequest.ClassName, player_message_NotifRequest, AMF3);
AMFDecoder.register(player_message_NotifRead.ClassName, player_message_NotifRead, AMF3);
AMFDecoder.register(player_message_ChallengeResponse.ClassName, player_message_ChallengeResponse, AMF3);
AMFDecoder.register(player_history_message_LeagueHistoryResponse.ClassName, player_history_message_LeagueHistoryResponse, AMF3);
AMFDecoder.register(player_store_message_StoreContent.ClassName, player_store_message_StoreContent, AMF3);
AMFDecoder.register(games_association_message_SolutionProvided.ClassName, games_association_message_SolutionProvided, AMF3);
AMFDecoder.register(games_puzzle_Puzzle.ClassName, games_puzzle_Puzzle, AMF3);
AMFDecoder.register(player_message_SendAppeal.ClassName, player_message_SendAppeal, AMF3);
AMFDecoder.register(games_association_AssociationRewardStrategy.ClassName, games_association_AssociationRewardStrategy, AMF3);
AMFDecoder.register(player_league_message_LeagueTopPositioned.ClassName, player_league_message_LeagueTopPositioned, AMF3);
AMFDecoder.register(player_message_PlayNowRandom.ClassName, player_message_PlayNowRandom, AMF3);
AMFDecoder.register(player_store_message_PlayGamblingGame.ClassName, player_store_message_PlayGamblingGame, AMF3);
AMFDecoder.register(games_quiz_message_SendTheQuestion.ClassName, games_quiz_message_SendTheQuestion, AMF3);
AMFDecoder.register(player_message_RangList.ClassName, player_message_RangList, AMF3);
AMFDecoder.register(games_quiz_message_Answer.ClassName, games_quiz_message_Answer, AMF3);
AMFDecoder.register(chat_message_LogoutFromChat.ClassName, chat_message_LogoutFromChat, AMF3);
AMFDecoder.register(player_message_FriendCancelRequest.ClassName, player_message_FriendCancelRequest, AMF3);
AMFDecoder.register(communication_message_PlayerAction.ClassName, communication_message_PlayerAction, AMF3);
AMFDecoder.register(player_message_NotifDelete.ClassName, player_message_NotifDelete, AMF3);
AMFDecoder.register(player_message_FriendsStatus.ClassName, player_message_FriendsStatus, AMF3);
AMFDecoder.register(player_reward_message_RankChanged.ClassName, player_reward_message_RankChanged, AMF3);
AMFDecoder.register(player_message_FriendChallengeRequest.ClassName, player_message_FriendChallengeRequest, AMF3);
AMFDecoder.register(player_message_DiscountResponse.ClassName, player_message_DiscountResponse, AMF3);

AMFDecoder.register(games_combinations_message_CombinationResult.ClassName, games_combinations_message_CombinationResult, AMF3);

AMFDecoder.register(games_combinations_message_CombinationsStatus.ClassName, games_combinations_message_CombinationsStatus, AMF3);

AMFDecoder.register(games_combinations_message_PlayerCombination.ClassName, games_combinations_message_PlayerCombination, AMF3);

AMFDecoder.register(games_combinations_message_StartSolving.ClassName, games_combinations_message_StartSolving, AMF3);

AMFDecoder.register(games_mynumber_message_ChooseNumbers.ClassName, games_mynumber_message_ChooseNumbers, AMF3);

AMFDecoder.register(games_mynumber_message_MyNumberStatus.ClassName, games_mynumber_message_MyNumberStatus, AMF3);

AMFDecoder.register(games_mynumber_message_SolutionAck.ClassName, games_mynumber_message_SolutionAck, AMF3);

AMFDecoder.register(games_mynumber_message_SolutionProvided.ClassName, games_mynumber_message_SolutionProvided, AMF3);
AMFDecoder.register(games_mynumber_message_StartChoosingNumbers.ClassName, games_mynumber_message_StartChoosingNumbers, AMF3);

AMFDecoder.register(games_mynumber_message_StartSolving.ClassName, games_mynumber_message_StartSolving, AMF3);

AMFDecoder.register(games_pairs_message_PairsAnswer.ClassName, games_pairs_message_PairsAnswer, AMF3);

AMFDecoder.register(games_pairs_message_PairsGameQuestion.ClassName, games_pairs_message_PairsGameQuestion, AMF3);

AMFDecoder.register(games_pairs_message_PairsResult.ClassName, games_pairs_message_PairsResult, AMF3);
AMFDecoder.register(games_pairs_message_SendPairsQuestion.ClassName, games_pairs_message_SendPairsQuestion, AMF3);
AMFDecoder.register(games_puzzle_message_AppealWord.ClassName, games_puzzle_message_AppealWord, AMF3);

AMFDecoder.register(games_puzzle_message_PuzzleStatus.ClassName, games_puzzle_message_PuzzleStatus, AMF3);

AMFDecoder.register(player_message_DailyReward.ClassName, player_message_DailyReward, AMF3);
AMFDecoder.register(player_achievements_message_AchievementRewardCollection.ClassName, player_achievements_message_AchievementRewardCollection, AMF3);

AMFDecoder.register(player_league_message_PreviousLeagueInfo.ClassName, player_league_message_PreviousLeagueInfo, AMF3);
AMFDecoder.register(player_message_HappyBirthday.ClassName, player_message_HappyBirthday, AMF3);

AMFDecoder.register(credits_message_RedeemVoucherCode.ClassName, credits_message_RedeemVoucherCode, AMF3);
AMFDecoder.register(credits_message_RedeemResult.ClassName, credits_message_RedeemResult, AMF3);

AMFDecoder.register(player_message_PaymentGateway.ClassName, player_message_PaymentGateway, AMF3);
AMFDecoder.register(player_message_FeatureConfig.ClassName, player_message_FeatureConfig, AMF3);

AMFDecoder.register(player_commercialsurvey_message_CommercialSurvey.ClassName, player_commercialsurvey_message_CommercialSurvey, AMF3);

AMFDecoder.register(player_achievements_message_AchievementTargetValuesCollection.ClassName, player_achievements_message_AchievementTargetValuesCollection, AMF3);
AMFDecoder.register(player_achievements_message_AchievementTargetValue.ClassName, player_achievements_message_AchievementTargetValue, AMF3);

AMFDecoder.register(b2b_admin_B2bTournamentEvent.ClassName, b2b_admin_B2bTournamentEvent, AMF3);
AMFDecoder.register(b2b_admin_B2bChangeTeamImage.ClassName, b2b_admin_B2bChangeTeamImage, AMF3);
AMFDecoder.register(b2b_admin_B2bAdminCommandAction.ClassName, b2b_admin_B2bAdminCommandAction, AMF3);
AMFDecoder.register(b2b_admin_B2bCreateTournamentAction.ClassName, b2b_admin_B2bCreateTournamentAction, AMF3);
AMFDecoder.register(b2b_admin_B2bUpdateTournamentAction.ClassName, b2b_admin_B2bUpdateTournamentAction, AMF3);
AMFDecoder.register(b2b_admin_B2bPerformDrawAction.ClassName, b2b_admin_B2bPerformDrawAction, AMF3);
AMFDecoder.register(b2b_admin_B2bTournamentInfo.ClassName, b2b_admin_B2bTournamentInfo, AMF3);
AMFDecoder.register(b2b_admin_B2bReadyEvent.ClassName, b2b_admin_B2bReadyEvent, AMF3);
