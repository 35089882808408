import { ServerEvent } from '../../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.player.store.message.ReceiveGift';

export class ReceiveGift extends ServerEvent {
  itemId: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
