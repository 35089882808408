import { observer } from 'mobx-react';
import React from 'react';
import { Local } from 'common/src/lang/Local';
import { ServerInfoModel } from 'common/src/models/dialogs/info/ServerInfoModel';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';

interface ServerInfoProps extends DialogProps {
  serverInfo?: ServerInfoModel;
}
@observer
export default class ServerInfo extends BaseDialog<ServerInfoProps, {}> {
  isVisible(): boolean {
    if (this.props.serverInfo) return this.props.serverInfo.isVisible();
    return false;
  }

  renderVisible() {
    const { serverInfo } = this.props;
    const t = (message: string) => Local.getString(message);
    return (
      <div className="server-info">
        <div className="server-info__inner">
          <DialogHeader
            onClose={() => {
              serverInfo!.hide();
            }}
          >
            {Local.getString('common.information')}
          </DialogHeader>
          <div className="server-info__image" />
          <div className="server-info__description">{serverInfo!.info}</div>
        </div>
      </div>
    );
  }
}
