import { PlayerAction } from './PlayerAction';

const BINDING_NAME = 'rs.slagalica.communication.message.ReadyEvent';

export class ReadyEvent extends PlayerAction {
  type: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
