import React, { Component } from 'react';
import { ShopModel } from 'common/src/models/dialogs/shop/ShopModel';
import { DialogProps } from '../BaseDialog';

interface TabHeaderProps extends DialogProps {
  shop?: ShopModel;
}

export default class TabHeader extends Component<TabHeaderProps> {
  getTabClass(tab: number) {
    const { shop } = this.props;
    return `shop__tab-background${shop!.selectedTab === tab ? '-selected' : ''}`;
  }

  render() {
    const { shop } = this.props;
    return (
      <div className="shop__tabs">
        <div className={this.getTabClass(1)} onClick={() => shop!.onGroupSelected(1)}>
          <div className="shop__hat-icon" />
        </div>
        <div className={this.getTabClass(2)} onClick={() => shop!.onGroupSelected(2)}>
          <div className="shop__chat-icon" />
        </div>
        <div style={{ clear: 'both' }} />
      </div>
    );
  }
}
