import { PointsUpdate } from './PointsUpdate';

const BINDING_NAME = 'rs.slagalica.communication.message.Score';

export class Score extends PointsUpdate {
  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
