import { PlayerInfo } from './PlayerInfo';

const BINDING_NAME = 'rs.slagalica.player.message.OpponentInfoUpdate';

export class OpponentInfoUpdate extends PlayerInfo {
  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
