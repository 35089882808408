import { PointsUpdate } from '../../../communication/message/PointsUpdate';
import { AssociationColumn } from './AssociationColumn';

const BINDING_NAME = 'rs.slagalica.games.association.message.SolutionResult';

export class SolutionResult extends PointsUpdate {
  result: boolean = false;

  position: number = -2;

  column?: AssociationColumn = undefined;

  answer: string = '';

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
