import { MessageSerializable } from '../../../MessageSerializable';
import { Reward } from '../../reward/message/Reward';

const BINDING_NAME = 'rs.slagalica.player.league.message.PositionRewards';

export class PositionRewards extends MessageSerializable {
  position: number = 0;

  // fieldType: int
  rewards?: Array<Reward> = undefined; // fieldType: ArrayList

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
