import { ActionMST } from '../../ActionModel';
import { VisibleMST } from '../../VisableModel';

export const ChatMessageMST = VisibleMST.named('ChatMessageMST')
  .props({
    id: 0,
    type: -1,
    available: false,
    action: ActionMST
  })
  .actions(self => ({
    clearType() {
      self.type = -1;
    }
  }))
  .views(self => ({
    isAvailable() {
      return self.available;
    }
  }))
  .actions(self => ({
    setAvailable(isAvailable: boolean) {
      self.available = isAvailable;
    }
  }));

type ChatMessageModelType = typeof ChatMessageMST.Type;
export interface ChatMessageModel extends ChatMessageModelType {}

export const createChatMessageModel = (id: number, type: number) =>
  ChatMessageMST.create({
    id,
    type,
    action: {}
  });
