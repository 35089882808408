import { ServerEvent } from '../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.player.message.DailyReward';

export class DailyReward extends ServerEvent {
  loginsInRow: number = 0;

  requestMail: boolean = false;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
