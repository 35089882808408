import { PointsUpdate } from '../../../communication/message/PointsUpdate';

const BINDING_NAME = 'rs.slagalica.games.puzzle.message.SolutionResult';

export class SolutionResult extends PointsUpdate {
  word?: Array<number> = undefined;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
