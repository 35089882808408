import { Stream } from 'stream';
import { PlayerAction } from '../messages/communication/message/PlayerAction';
import { ServerEvent } from '../messages/communication/message/ServerEvent';
import '../messages/register';

const AMF: any = require('amfjs');

export class AMFSocket {
  private socket?: WebSocket;

  public onError: (() => void) | undefined;

  public onConnected: (() => void) | undefined;

  public onClose: (() => void) | undefined;

  public onMessage: ((message: ServerEvent) => void) | undefined;

  constructor() {
    // this.connect(url);
  }

  public connect(url: string) {
    this.socket = new WebSocket(url);

    this.socket.binaryType = 'arraybuffer';
    this.socket.onopen = () => {
      if (this.onConnected) this.onConnected();
      console.log('Connected');
    };
    this.socket.onerror = ev => {
      if (this.onError) this.onError();
      console.log('Connection error');
      console.log(ev);
    };

    this.socket.onclose = () => {
      if (this.onClose) this.onClose();
      console.log('Connection closed');
    };

    this.socket.onmessage = (event: MessageEvent) => {
      try {
        const data = this.decodeAMF(new Uint8Array(event.data));
        this.logMessage(data);
        if (data && this.onMessage) {
          if (data instanceof ServerEvent) this.onMessage(data);
          else {
            console.warn(`unknown message:${data}`);
            console.log(data);
          }
        } else {
          console.warn('unable to parse message');
        }
      } catch (err) {
        console.log(err);
      }
    };
  }

  public disconnect() {
    if (this.socket) {
      this.socket.onmessage = null;
      this.socket.onerror = null;
      this.socket.onclose = null;
      this.socket.close();
    }
  }

  sendMessage(message: PlayerAction) {
    if (this.isConnected()) {
      const sendData = this.encodeAMF(message);
      this.socket!.send(sendData);
      this.logMessage(message);
    } else {
      console.warn('Trying to send message, but socket connection is not opened');
    }
  }

  public isConnected(): boolean {
    return !!(this.socket && this.socket.readyState === this.socket.OPEN);
  }

  private logMessage(message: any) {
    // if (message instanceof PlayerPing || message instanceof ServerStatus) return;
    // console.log(message);
  }

  private encodeAMF(message: any) {
    const streamOut = new Stream.Writable();
    const data: any = [];
    streamOut._write = (chunk, len, cb) => {
      data.push(chunk);
      cb();
    };
    const encoder = new AMF.AMFEncoder(streamOut);
    encoder.writeObject(message, AMF.AMF3);
    return Buffer.concat(data).buffer;
  }

  private decodeAMF(chunk: Uint8Array) {
    const streamIn = new Stream.Readable();
    streamIn._read = () => {};
    streamIn.push(chunk);
    const decoder = new AMF.AMFDecoder(streamIn);

    try {
      // let r = streamIn.read()
      const read = decoder.decode(AMF.AMF3); // Decode an AMF3 object

      return read;
    } catch (err) {
      console.error(err);
      return '';
    }
  }
}
