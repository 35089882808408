import { Application } from 'common/src/app/Application';
import { FacebookDummy } from 'common/src/facebook/FacebookDummy';
import { B2bAccount } from 'common/src/login/B2bAccount';
import { RootStore } from 'common/src/RootStore';
import { TypeNone } from 'common/src/settings/AppSettings';
import { MultipleTracker } from 'common/src/tracking/MultipleTracker';
import { TweenLite } from 'gsap';
import GSAPAnimationAPI from '../animations/base/GSAPAnimationAPI';
import { WebSoundPlayer } from '../resources/WebSoundPlayer';
import { WebSettings } from '../settings/web/WebSettings';
import { B2bLoginManager } from './B2bLoginManager';
import { WebDisplayConfiguartor } from './WebDisplayConfigurator';
import { WebLocalActionResolver } from './WebLocalActionResolver';

export class B2bLocalApplication extends Application {
  constructor(url: string, rootStore: RootStore) {
    super(new FacebookDummy(), new GSAPAnimationAPI(), new WebLocalActionResolver(), new B2bLoginManager(rootStore.b2blogindata, new B2bAccount(rootStore.b2blogindata)), new WebSettings(url), new MultipleTracker([]), new WebSoundPlayer(), new WebDisplayConfiguartor(), rootStore);

    this.start(rootStore.logo, rootStore.loader);
    rootStore.loader.onAssetsLoaded.setAction(() => this.LoginManager.tryAutoLogin(TypeNone));

    TweenLite.ticker.useRAF(false);
    TweenLite.lagSmoothing(0, 33);
  }
}
