import { Local } from 'common/src/lang/Local';
import { FriendChallengeModel } from 'common/src/models/dialogs/friendchallenge/FriendChallenge';
import { observer } from 'mobx-react';
import React from 'react';
import ProfilePicture from '../../game/ProfilePicture';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';

interface FriendsChallengeDialogProps extends DialogProps {
  friendChallenge?: FriendChallengeModel;
}

@observer
export default class FriendsChallengeDialog extends BaseDialog<FriendsChallengeDialogProps, {}> {
  isVisible(): boolean {
    const { friendChallenge } = this.props;
    return friendChallenge ? friendChallenge!.isVisible() : false;
  }

  renderVisible() {
    const { friendChallenge } = this.props;
    return (
      <div className="friend-challenge">
        <div className="friend-challenge__inner">
          <DialogHeader
            onClose={() => {
              friendChallenge!.declineAction.performAction();
              friendChallenge!.hide();
            }}
          >
            {Local.getString('friend.challenge_title')}
          </DialogHeader>
          <ProfilePicture css="friend-challenge__image" fbId={friendChallenge!.friendFbId} size={200} />

          <div className="friend-challenge__button-box">
            <button className="friend-challenge__button" onClick={() => friendChallenge!.confirmAction!.performAction()}>
              {Local.getString('friend.accept')}
            </button>
            <button className="friend-challenge__button" onClick={() => friendChallenge!.declineAction!.performAction()}>
              {Local.getString('friend.cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
