import { ServerEvent } from '../../../communication/message/ServerEvent';
import { Reward } from '../../reward/message/Reward';
import { LeagueTopPositioned } from './LeagueTopPositioned';

const BINDING_NAME = 'rs.slagalica.player.league.message.PreviousLeagueInfo';

export class PreviousLeagueInfo extends ServerEvent {
  leagueId: number = 0;

  // fieldType: int
  position: number = 0;

  // fieldType: int
  feathers: number = 0;

  // fieldType: int
  rank: number = 0;

  // fieldType: int
  rewards?: Array<Reward> = undefined;

  // fieldType: ArrayList
  leagueTopPositions?: Array<LeagueTopPositioned> = undefined; // fieldType: ArrayList

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
