import { ServerEvent } from '../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.player.message.SurveyCollection';

export class SurveyCollection extends ServerEvent {
  // fieldType: ArrayList
  questions?: any | undefined;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
