import { types } from 'mobx-state-tree';
import { UserStats } from '../../../messages/player/stats/UserStats';
import { DisplayMST } from '../../DisplayModel';
import { GameName, StatisticGameModel, StatisticsGameMST, createPuzzleGameModel, createMyNumberGameModel, createCombinationsGameModel, createQuizGameModel, createAssoctioantGameModel, createPairGameModel } from './StatisticsGameModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const StatisticsMST = DisplayMST.named('StatisticsModel')
  .props({
    won: 0,
    lose: 0,
    gameStat: types.array(StatisticsGameMST)
  })
  .views(self => ({
    getWonPercentage() {
      if (self.won + self.lose === 0) return '0%';
      return `${((self.won / (self.won + self.lose)) * 100).toFixed(0)}%`;
    }
  }))
  .actions(self => ({
    setStatistics(stats: UserStats | undefined) {
      if (!stats) return;
      self.won = stats.won;
      self.lose = stats.lose;

      this.setGameStatistics(GameName.Puzzle, [stats.kpi1_1, stats.kpi1_2, stats.kpi1_3, stats.kpi1_4, stats.kpi1_5, stats.kpi1_6, stats.kpi1_7], stats.marks ? stats.marks[0] : 0);
      this.setGameStatistics(GameName.MyNumber, [stats.kpi2_1, stats.kpi2_2, stats.kpi2_3, stats.kpi2_4, stats.kpi2_5, stats.kpi2_6, stats.kpi2_7], stats.marks ? stats.marks[1] : 0);
      this.setGameStatistics(GameName.Combination, [stats.kpi3_1, stats.kpi3_2, stats.kpi3_3, stats.kpi3_4], stats.marks ? stats.marks[2] : 0);
      this.setGameStatistics(GameName.Quiz, [stats.kpi4_1, stats.kpi4_2, stats.kpi4_3], stats.marks ? stats.marks[3] : 0);
      this.setGameStatistics(GameName.Matching, [stats.kpi5_1, stats.kpi5_2], stats.marks ? stats.marks[4] : 0);
      this.setGameStatistics(GameName.Association, [stats.kpi6_1, stats.kpi6_2, stats.kpi6_3, stats.kpi6_4, stats.kpi6_5, stats.kpi6_6], stats.marks ? stats.marks[5] : 0);
      this.setGameStatistics(GameName.Pair, [stats.kpi7_1, stats.kpi7_2], stats.marks ? stats.marks[6] : 0);
    },
    setGameStatistics(gameType: number, stats: Array<number>, statMark: number) {
      self.gameStat.forEach((gameModel: StatisticGameModel) => {
        if (gameModel.type === gameType) {
          gameModel.setPercentagesGame(stats);
          gameModel.setGameMark(statMark);
        }
      });
    }
  }));

type StatisticsModelType = typeof StatisticsMST.Type;
export interface StatisticsModel extends StatisticsModelType {}

export const createDummyStatisticsModel = () =>
  StatisticsMST.create({
    key: DisplayModelKeys.Statistics,
    won: 0,
    lose: 0,
    gameStat: [createPuzzleGameModel(), createMyNumberGameModel(), createCombinationsGameModel(), createQuizGameModel(), createPairGameModel(), createAssoctioantGameModel()]
  });

export const createStatisticsModel = () =>
  StatisticsMST.create({
    key: DisplayModelKeys.Statistics,
    won: 0,
    lose: 0,
    gameStat: [createPuzzleGameModel(), createMyNumberGameModel(), createCombinationsGameModel(), createQuizGameModel(), createPairGameModel(), createAssoctioantGameModel()]
  });
