import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Local } from 'common/src/lang/Local';
import { AchievementItemModel } from 'common/src/models/dialogs/achievements/AchievementItemModel';
import { ACHIEVEMENTS } from 'web/src/resources/AchievementResorces';
import { Reward } from 'common/src/messages/player/reward/message/Reward';
import { REWARDS } from 'web/src/resources/RewardResources';
import { RewardItemModel } from 'common/src/models/common/RewardItemModel';
import { UserModel } from 'common/src/models/user/UserModel';

interface AchievementItemProps {
  item?: AchievementItemModel | undefined;
  scrollTransitY: number;
  row: number;
  column: number;
  wins: number;
  feathers: number;
  todaysWins: number;
}

@observer
export default class AchievementItem extends Component<AchievementItemProps> {
  getReward(rewards: Array<RewardItemModel>) {
    if (rewards === undefined) {
      return <div />;
    }
    return rewards.map(reward => (
      <div key={reward.id} className="achievements__tooltip__reward-icon-count">
        <img className="achievements__tooltip__reward-icon" src={REWARDS.getImg(reward.id)} />
        <div className="achievements__tooltip__reward-count">x{reward!.count}</div>
      </div>
    ));
  }

  getCurrentValue() {
    if (this.props.item!.completed) return '';

    if (this.props.item!.getRow() === 0) return `${Local.getString('ach.lvl0_current')} ${this.props.feathers}`;
    if (this.props.item!.getRow() === 1) return `${Local.getString('ach.lvl1_current')} ${this.props.wins}`;
    if (this.props.item!.getRow() === 3) return `${Local.getString('ach.lvl1_current')} ${this.props.todaysWins}`;

    return '';
  }

  getToolTip(item: AchievementItemModel, row: number, column: number) {
    const leftPosition = `${column > 2 ? '-175' : '75'}%`;
    let topPosition;
    switch (row % 5) {
      case 0:
        topPosition = '75%';
        break;
      case 1:
        topPosition = '75%';
        break;
      case 2:
        topPosition = '-25%';
        break;
      case 3:
        topPosition = '-180%';
        break;
      default:
        topPosition = '-240%';
    }

    return (
      <div className="achievements__tooltip" style={{ left: leftPosition, top: topPosition }}>
        <div className="achievements__tooltip__title-icon">
          <img className="achievements__tooltip__icon" src={ACHIEVEMENTS.getImg(item.getId())} />
          <div className="achievements__tooltip__title"> {ACHIEVEMENTS.getName(item.getId())}</div>
        </div>
        <div className="achievements__tooltip__separator-line" />
        <div className="achievements__tooltip__goal-description">
          <div className="achievements__tooltip__goal">{Local.getString('ach.info_target')}</div>
          <div className="achievements__tooltip__description">{ACHIEVEMENTS.getDesc(item.getId(), item.requiredValue)}</div>
          <div className="achievements__tooltip__value">{this.getCurrentValue()}</div>
        </div>
        <div className="achievements__tooltip__separator-line achievements__tooltip__separator-line--bottom" />
        <div className="achievements__tooltip__rewards">
          <div className="achievements__tooltip__reward">{Local.getString('ach.award_caption')}</div>
          <div className="achievements__tooltip__reward-content">{this.getReward(item.rewards)}</div>
        </div>
      </div>
    );
  }

  render() {
    const { item, scrollTransitY, row, column } = this.props;

    if (item === undefined) {
      return (
        <div
          className="achievements__box achievements_item"
          style={{
            top: `${`${scrollTransitY}rem`}`
          }}
        >
          <div className="achievements__item achievements__item--coming-soon" />
        </div>
      );
    }

    if (item !== undefined && item.completed) {
      return (
        <div
          key={item!.getId()}
          className="achievements__box"
          style={{
            top: `${`${scrollTransitY}rem`}`
          }}
        >
          <img className="achievements__item" src={ACHIEVEMENTS.getImg(item.getId())} />
          {this.getToolTip(item, row, column)}
        </div>
      );
    }
    if (item !== undefined && !item.completed) {
      return (
        <div
          className="achievements__box achievements__opacity"
          style={{
            top: `${`${scrollTransitY}rem`}`
          }}
        >
          <div className="achievements__item achievements__item--locked" />
          {this.getToolTip(item, row, column)}
        </div>
      );
    }
    return <div />;
  }
}
