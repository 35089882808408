import { ServerEvent } from '../../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.player.achievements.message.AchievementTargetValue';

export class AchievementTargetValue extends ServerEvent {
  id: number = 0;

  level: number = 0;

  value: number = 0;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
