import { types } from 'mobx-state-tree';
import { BaseMST } from '../BaseModel';
import { createTournamentFriendsModel, createTournamentTitlesModel, TournamentMST } from './TournamentModel';

export const TournamentsMST = BaseMST.named('TournamentsMST')
  .props({
    friends: TournamentMST,
    titles: TournamentMST
  })
  .actions(self => ({}))
  .views(self => ({}));

type TournamentsModelType = typeof TournamentsMST.Type;
export interface TournamentsModel extends TournamentsModelType {}
export const createTournamentsModel = () =>
  TournamentsMST.create({
    friends: createTournamentFriendsModel(),
    titles: createTournamentTitlesModel()
  });
