const NONE = 0;
const CLUB = 1;
const SPADE = 2;
const HEART = 3;
const DIAMOND = 4;
const STAR = 5;
const OWL = 6;

export class CombinationsSign {
  static get None() {
    return NONE;
  }

  static get Owl() {
    return OWL;
  }

  static get Club() {
    return CLUB;
  }

  static get Spade() {
    return SPADE;
  }

  static get Heart() {
    return HEART;
  }

  static get Diamond() {
    return DIAMOND;
  }

  static get Star() {
    return STAR;
  }
}
