import { PlayerAction } from '../../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.credits.message.RedeemVoucherCode';

export class RedeemVoucherCode extends PlayerAction {
  code?: string = undefined; // fieldType: String

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
