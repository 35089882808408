import { types } from 'mobx-state-tree';
import { BaseMST } from '../BaseModel';
import { TestItemMST, TestItemModel, createTestItemModel } from './TestItemModel';

export const TestMST = BaseMST.named('TestMST')
  .props({
    actions: types.array(TestItemMST)
  })
  .views(self => ({}))
  .actions(self => ({
    addAction(name: string, action: Function) {
      const itemTest: TestItemModel = createTestItemModel(name);
      itemTest.setAction(action);
      self.actions.push(itemTest);
    },
    deleteActions() {
      self.actions.clear();
    }
  }));

type TestModelType = typeof TestMST.Type;
export interface TestModel extends TestModelType {}
export const createTestModel = () =>
  TestMST.create({
    actions: []
  });
