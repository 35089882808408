import { MessageSerializable } from '../../../MessageSerializable';
import { PositionRewards } from './PositionRewards';

const BINDING_NAME = 'rs.slagalica.player.league.message.LeagueInfo';

export class LeagueInfo extends MessageSerializable {
  name?: string = undefined;

  // fieldType: String
  rank: number = 0;

  // fieldType: int
  positionRewards?: Array<PositionRewards> = undefined; // fieldType: ArrayList

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
