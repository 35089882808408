import { types } from 'mobx-state-tree';
import { DisplayMST } from '../../DisplayModel';
import { ShopItemMST } from '../shop/ShopItemModel';
import { ActionMST } from '../../ActionModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const ShopTokensMobileMST = DisplayMST.named('ShopTokensMobileModel')
  .props({
    tokenItems: types.array(ShopItemMST),
    closeAction: ActionMST
  })
  .views(self => ({}))
  .actions(self => ({
    setTokenItems() {}
  }));

type ShopTokensMobileModelType = typeof ShopTokensMobileMST.Type;
export interface ShopTokensMobileModel extends ShopTokensMobileModelType {}

export const createShopTokensMobileModel = () =>
  ShopTokensMobileMST.create({
    key: DisplayModelKeys.ShopTokensMobile,
    tokenItems: [],
    closeAction: { enabled: true }
  });
