import { ServerEvent } from '../../communication/message/ServerEvent';
import { UserStats } from '../stats/UserStats';

const BINDING_NAME = 'rs.slagalica.player.message.PlayerInfoBase';

const Offline = 0; // fieldType: int
const Online = 1; // fieldType: int
const Busy = 2; // fieldType: int
const PlatformDesktop = 0; // fieldType: int
const PlatformAndroid = 1; // fieldType: int
const TypeFacebook = 0; // fieldType: int
const TypeGuest = 1; // fieldType: int
const TypeApple = 4; // fieldType: int
const TypeB2b = 5; // fieldType: int

export class PlayerInfoBase extends ServerEvent {
  type: number = 0;

  // fieldType: int
  facebookId?: string = undefined;

  // fieldType: String
  guestId?: string = undefined;

  // fieldType: String
  id: number = 0;

  // fieldType: long
  name: string = '';

  // fieldType: String
  nameShort: string = '';

  // fieldType: String
  rankingPoints: number = 0;

  // fieldType: int
  rank: number = 0;

  // fieldType: int
  onlineStatus: number = 0;

  // fieldType: int
  platform: number = 0;

  // fieldType: int
  rankListTotalPoints: number = 0;

  // fieldType: int
  maxPrevRank: number = 0;

  // fieldType: int
  stat?: UserStats = undefined; // fieldType: UserStats

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get Offline() {
    return Offline;
  }

  static get Online() {
    return Online;
  }

  static get Busy() {
    return Busy;
  }

  static get PlatformDesktop() {
    return PlatformDesktop;
  }

  static get PlatformAndroid() {
    return PlatformAndroid;
  }

  static get TypeFacebook() {
    return TypeFacebook;
  }

  static get TypeGuest() {
    return TypeGuest;
  }

  static get TypeB2b() {
    return TypeB2b;
  }

  static get TypeApple() {
    return TypeApple;
  }

  getShortName(): string {
    const name = this.nameShort && this.nameShort.length > 0 ? this.nameShort : this.name;
    return name.split(' ')[0];
  }
}
