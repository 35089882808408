import { types } from 'mobx-state-tree';
import { VisibleMST } from '../../VisableModel';
import { AnimationBuilderMST } from '../../AnimationBuilder';

export const GameContentMST = VisibleMST.named('GameContent')
  .props({
    name: types.string,
    enteringAnimation: types.optional(AnimationBuilderMST, {}),
    exitingAnimation: types.optional(AnimationBuilderMST, {}),
    preparingAnimation: types.optional(AnimationBuilderMST, {})
  })
  .actions(self => ({
    resetModel() {}
  }));

type GameContentType = typeof GameContentMST.Type;
export interface GameContentModel extends GameContentType {}
