import { BaseMST } from './BaseModel';

const EMPTY_ACTION = () => {};

export const ActionMST = BaseMST.named('GameButtonMST')
  .props({
    enabled: true
  })
  .actions(self => {
    let onAction: Function = EMPTY_ACTION;

    function setAction(action: Function) {
      onAction = action;
    }

    function getAction(): Function {
      return onAction;
    }

    function setEnabled(value: boolean) {
      self.enabled = value;
    }

    function performAction() {
      if (onAction && self.enabled) onAction();
    }

    return {
      setAction,
      getAction,
      setEnabled,
      performAction
    };
  })
  .views(self => ({}));

type ActionModelType = typeof ActionMST.Type;
export interface ActionModel extends ActionModelType {}
