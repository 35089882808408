/* eslint-disable no-named-as-default */
import DailyIframe from '@daily-co/daily-js';
import { ActionMST } from 'common/src/models/ActionModel';
import { BaseMST } from 'common/src/models/BaseModel';
import { Instance, types } from 'mobx-state-tree';
import { createDeviceModel, DeviceMST } from './DeviceModel';

export const CollaborationMST = BaseMST.named('CollaborationMST')
  .props({
    url: types.string,
    teamChatId: types.string,
    globalChatId: types.string,
    isMyTeamChatActive: types.boolean,
    onRoomReceived: types.optional(ActionMST, {}),
    onLeaveRoom: types.optional(ActionMST, {}),
    onDeclareCaptain: types.optional(ActionMST, {}),
    captain: types.boolean,
    camera: DeviceMST,
    mic: DeviceMST,
    speaker: DeviceMST,
    allDevices: types.array(DeviceMST)
  })
  .volatile(self => ({
    callObject: DailyIframe.createCallObject()
  }))
  .views(self => ({
    isVisible(): boolean {
      return self.url !== '';
    },
    isInRoom(): boolean {
      return self.url !== '';
    },
    isGlobalChatSet(): boolean {
      return self.globalChatId !== '';
    },
    isMyTeamChatSet(): boolean {
      return self.teamChatId !== '';
    }
  }))
  .actions(self => ({
    setUrl(url: string) {
      self.url = url;
      if (url && url !== '') self.onRoomReceived.performAction();
      else self.onLeaveRoom.performAction();
    },
    setMyTeamChatActive(value: boolean) {
      self.isMyTeamChatActive = value;
    },
    setMyTeamChat(value: string) {
      self.teamChatId = value;
    },
    setGlobalChat(value: string) {
      self.globalChatId = value;
    },
    declareCaptain(captain: boolean){
      self.captain = captain;
      self.onDeclareCaptain.performAction();
    },
    setCamera(camera: any){
      self.camera = createDeviceModel(camera!.deviceId!, camera!.groupId!, camera!.kind!, camera!.label!);
    },
    setMic(mic: any){
      self.mic = createDeviceModel(mic!.deviceId!, mic!.groupId!, mic!.kind!, mic!.label!);
    },
    setSpeaker(speaker: any){
      self.speaker = createDeviceModel(speaker.deviceId, speaker.groupId, speaker.kind, speaker.label);
    },

    setAllDevices(devices: MediaDeviceInfo[]){
      self.allDevices.clear;
      devices.map((device) => {self.allDevices.push(createDeviceModel(device.deviceId, device.groupId, device.kind, device.label));})
    }
  }))

type CollaborationType = typeof CollaborationMST.Type;
export interface CollaborationModel extends Instance<typeof CollaborationMST> {}

export const createCollaborationModel = () =>
  CollaborationMST.create({
    url: '',
    teamChatId: '',
    globalChatId: '',
    isMyTeamChatActive: false,
    onRoomReceived: {},
    onLeaveRoom: {},
    onDeclareCaptain: {},
    captain: false,
    camera: createDeviceModel('','','',''),
    mic: createDeviceModel('','','',''),
    speaker: createDeviceModel('','','',''),
    allDevices: []
  });
