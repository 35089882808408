import { PointsUpdate } from '../../../communication/message/PointsUpdate';

const BINDING_NAME = 'rs.slagalica.games.mynumber.message.MyNumberStatus';

export class MyNumberStatus extends PointsUpdate {
  static NO_WINNERS: number = 0;

  static BLUE_WINS: number = 1;

  static RED_WINS: number = 2;

  targetNumber: number = 0;

  offer1: number = 0;

  offer2: number = 0;

  digit1: number = 0;

  digit2: number = 0;

  digit3: number = 0;

  digit4: number = 0;

  blueAnswer?: string;

  redAnswer?: string;

  blueResult: number = 0;

  redResult: number = 0;

  winResult: number = 0;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  public getMyResult(playingAsBlue: boolean): number {
    if (playingAsBlue) return this.blueResult;
    return this.redResult;
  }

  public getOpponentResult(playingAsBlue: boolean): number {
    if (playingAsBlue) return this.redResult;
    return this.blueResult;
  }
}
