import { ServerEvent } from '../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.b2b.game.message.B2bGameReady';

export class B2bGameReady extends ServerEvent {
  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
