import { MessageSerializable } from '../../MessageSerializable';

const BINDING_NAME = 'rs.slagalica.communication.message.Message';

export class Message extends MessageSerializable {
  controllerId?: string = undefined;

  // fieldType: String
  playerId?: number = undefined; // fieldType: long

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  getClass() {
    return this.__class;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
