import { ServerEvent } from '../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.player.message.FriendCancelRequest';

export class FriendCancelRequest extends ServerEvent {
  challengerId: number = 0; // fieldType: long

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
