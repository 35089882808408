import { CollaborationModel } from 'web/src/collaboration/model/CollaborationModel';
import BaseAnimation from '../../animations/BaseAnimation';
import GroupAnimation from '../../animations/GroupAnimation';
import { APP } from '../../app/AppInstance';
import { DEFAULT_VOL } from '../../app/sound/SoundPlayer';
import { Local } from '../../lang/Local';
import { B2bQuizAnswer } from '../../messages/b2b/B2bQuizAnswer';
import { ServerEvent } from '../../messages/communication/message/ServerEvent';
import { Answer } from '../../messages/games/quiz/message/Answer';
import { AnswerResult } from '../../messages/games/quiz/message/AnswerResult';
import { SendTheQuestion } from '../../messages/games/quiz/message/SendTheQuestion';
import { B2bTournamentModel } from '../../models/b2b/B2bTournamentModel';
import { B2bCaptainModel } from '../../models/dialogs/b2bgame/B2bCaptainModel';
import { B2bNoCaptainModel } from '../../models/dialogs/b2bgame/B2bNoCaptainModel';
import { ShopModel } from '../../models/dialogs/shop/ShopModel';
import { GameRiddleContentModel } from '../../models/game/content/GameRiddleContent';
import { QuestionModel } from '../../models/game/content/quiz/QuestionModel';
import { QuestionStatus } from '../../models/game/content/quiz/QuestionStatus';
import { QuizModel } from '../../models/game/content/quiz/QuizModel';
import { GameInfoModel } from '../../models/game/GameInfoModel';
import { GameModel } from '../../models/game/GameScreenModel';
import { ToolbarModel } from '../../models/toolbar/ToolbarModel';
import { UserModel } from '../../models/user/UserModel';
import { ConnectionAdapter } from '../../sockets/ConnectionAdapter';
import { GameController } from '../base/GameController';

const NO_ANSWER: number = -1;

export class QuizController extends GameController {
  private quizModel: QuizModel;

  private currentQuestionStartTimestamp: number = 0;

  constructor(
    amfSocket: ConnectionAdapter,
    quizModel: QuizModel,
    gameInfo: GameInfoModel,
    gameScreenModel: GameModel,
    userModel: UserModel,
    shopModel: ShopModel,
    toolbarModel: ToolbarModel,
    b2bTournamentModel: B2bTournamentModel,
    b2bCaptainModel: B2bCaptainModel,
    b2bNoCaptainModel: B2bNoCaptainModel,
    collaborationModel: CollaborationModel
  ) {
    super(amfSocket, 'QuizController', gameInfo, gameScreenModel, userModel, shopModel, toolbarModel, b2bTournamentModel, b2bCaptainModel, b2bNoCaptainModel, collaborationModel);
    this.quizModel = quizModel;
    for (let i = 0; i < 4; i++) {
      this.quizModel.answers[i].action.setAction(() => {
        const timeElapsed: number = new Date().getTime() - this.currentQuestionStartTimestamp;
        this.sendAnswerToServer(i, timeElapsed);
        this.markAnsweredButton(i, timeElapsed, this.userModel.info.b2bName!);
      });
    }
    this.quizModel.setSkipButton(() => {
      this.sendAnswerToServer(NO_ANSWER, -1);
    });
  }

  protected getGameModel(): GameRiddleContentModel {
    return this.quizModel;
  }

  public onControllerMessage(message: ServerEvent) {
    super.onControllerMessage(message);

    switch (message.getClass()) {
      case AnswerResult.ClassName:
        this.onAnswerResult(message as AnswerResult);
        break;
      case SendTheQuestion.ClassName:
        this.onSendTheQuestion(message as SendTheQuestion);
        break;
      case B2bQuizAnswer.ClassName:
        this.onB2bQuizAnswer(message as B2bQuizAnswer);
        break;
      default:
        break;
    }
  }

  protected onStartGame() {
    // this.animationExecutor.schedule(this.gameScreenModel.getShowGameContentAnimation(this.quizModel));
    this.quizModel.resetModel();
  }

  protected finalizeController() {
    super.finalizeController();
    setTimeout(() => {
      this.quizModel.resetModel();
    }, 2000);
  }

  private markAnsweredButton(i: number, timeElapsed: number, answeredName: string) {
    this.quizModel.answers[i].action.setEnabled(false);
    this.quizModel.disableUnusedButtons(i);
    if (this.gameInfo.isPlayingAsBlue) this.quizModel.setAnsweredByBlue(i, timeElapsed, answeredName);
    else this.quizModel.setAnsweredByRed(i, timeElapsed, answeredName);
  }

  private onB2bQuizAnswer(message: B2bQuizAnswer) {
    const myTeamMateName: string = message.b2bPlayerAnswered! <= 0 ? '' : this.getB2bMyPlayerName(message.b2bPlayerAnswered!);
    this.markAnsweredButton(message.index!, message.timeElapsed!, myTeamMateName);

    this.quizModel.disableUnusedButtons(message.index!);
    this.quizModel.restoreRemovedAnswers();
    this.gameInfo.stopTimer(true);
    this.gameInfo.disableInteraction();
    this.gameInfo.setStatus('');
  }

  private onAnswerResult(message: AnswerResult) {
    if (this.isForMyTeam(message)) {
      this.gameInfo.stopTimer(false);
      this.displayAnswer(message);
      // update history ?

      const question: QuestionModel = this.quizModel.questions[this.quizModel.currentQuestion];

      if (message.winner) {
        question.setStatus(this.gameInfo.isPlayingAsBlue ? QuestionStatus.BlueAnswered : QuestionStatus.RedAnswered);
      } else if (message.rightAnswerIndex === message.opponetAnswerIndex) {
        question.setStatus(!this.gameInfo.isPlayingAsBlue ? QuestionStatus.BlueAnswered : QuestionStatus.RedAnswered);
      } else {
        question.setStatus(QuestionStatus.NotAnswered);
      }
    }
  }

  private onSendTheQuestion(message: SendTheQuestion) {
    this.hideCaptainDialog();
    if (this.quizModel.currentQuestion >= 0) {
      this.animationExecutor.schedule(this.quizModel.questions[this.quizModel.currentQuestion].questionOutAnimation.build());
    }

    this.quizModel.skipButton.setEnabled(true);
    this.quizModel.setQuestion(message);

    this.animationExecutor.schedule(this.quizModel.questions[this.quizModel.currentQuestion].questionInAnimation.build());

    this.gameInfo.enableInteraction();
    this.setStatusSpectacle(
      Local.getString('quiz.choose_answer'),
      this.calculateClientTimeout(message),
      () => {
        // this.sendAnswerToServer(NO_ANSWER, -1);
      },
      GameController.BOTH_PLAYERS
    );
    this.currentQuestionStartTimestamp = new Date().getTime();
  }

  protected getGameName(): string {
    return Local.getString('game.quiz');
  }

  private displayAnswer(message: AnswerResult): void {
    const blueAnswerIndex: number = this.gameInfo.isPlayingAsBlue ? message.myAnswerIndex : message.opponetAnswerIndex;
    const redAnswerIndex: number = !this.gameInfo.isPlayingAsBlue ? message.myAnswerIndex : message.opponetAnswerIndex;
    const rightIndex: number = message.rightAnswerIndex;
    const blueTime: number = this.gameInfo.isPlayingAsBlue ? message.millisecondsElapsed : message.opponentMillisElapsed;
    const redTime: number = !this.gameInfo.isPlayingAsBlue ? message.millisecondsElapsed : message.opponentMillisElapsed;

    const myTeamMateName: string = message.b2bMyPlayerAnswered <= 0 ? '' : this.getB2bMyPlayerName(message.b2bMyPlayerAnswered);
    const oppTeamMateName: string = message.b2bOpponentPlayerAnswered <= 0 ? '' : this.getB2bOpponentPlayerName(message.b2bOpponentPlayerAnswered);

    const blueAnswered: string = this.gameInfo.isPlayingAsBlue ? myTeamMateName : oppTeamMateName;
    const redAnswered: string = this.gameInfo.isPlayingAsBlue ? oppTeamMateName : myTeamMateName;

    // both players gave same answer
    if (blueAnswerIndex >= 0 && blueAnswerIndex === redAnswerIndex) {
      this.quizModel.setAnsweredByBoth(blueAnswerIndex, blueTime, redTime, blueAnswered, redAnswered);
      this.animationExecutor.schedule(APP.AnimationAPI.createPause(0.3));
    }
    // players gave different answers
    else if (blueAnswerIndex !== redAnswerIndex) {
      if (blueAnswerIndex >= 0) this.quizModel.setAnsweredByBlue(blueAnswerIndex, blueTime, blueAnswered);
      if (redAnswerIndex >= 0) this.quizModel.setAnsweredByRed(redAnswerIndex, redTime, redAnswered);
    }

    // both players gave wrong answer
    if (blueAnswerIndex !== rightIndex && redAnswerIndex !== rightIndex) {
      this.quizModel.setAnsweredByEngine(rightIndex);
      this.animationExecutor.schedule(this.quizModel.answers[rightIndex].rightAnswerAnimation.build());
      APP.SoundPlayer.playWrongSound(DEFAULT_VOL);
    } else if (message.winner) {
      APP.SoundPlayer.playCorrectSound(DEFAULT_VOL);

      const parallel = APP.AnimationAPI.createParallel();
      parallel.add(this.quizModel.answers[rightIndex].rightAnswerAnimation.build());
      if (this.gameInfo.isPlayingAsBlue) {
        if (blueAnswerIndex === redAnswerIndex) {
          const blueOverflow = this.quizModel.answers[rightIndex].blueOverflowAnimation.build();
          blueOverflow.addEndListener(() => {
            this.quizModel.answers[rightIndex].setBlue();
          });
          parallel.add(blueOverflow);
        }
      } else if (blueAnswerIndex === redAnswerIndex) {
        const redOverflow = blueAnswerIndex === redAnswerIndex ? this.quizModel.answers[rightIndex].redOverflowAnimation.build() : this.quizModel.answers[rightIndex].rightAnswerAnimation.build();
        redOverflow.addEndListener(() => {
          this.quizModel.answers[rightIndex].setRed();
        });
        parallel.add(redOverflow);
      }
      this.animationExecutor.schedule(parallel);
    } else {
      APP.SoundPlayer.playWrongSound(DEFAULT_VOL);

      const parallel = APP.AnimationAPI.createParallel();
      parallel.add(this.quizModel.answers[rightIndex].rightAnswerAnimation.build());

      if (this.gameInfo.isPlayingAsBlue) {
        if (blueAnswerIndex === redAnswerIndex) {
          const redOverflow = this.quizModel.answers[rightIndex].redOverflowAnimation.build();
          redOverflow.addEndListener(() => {
            this.quizModel.answers[rightIndex].setRed();
          });
          parallel.add(redOverflow);
        }
      } else if (blueAnswerIndex === redAnswerIndex) {
        const blueOverflow = this.quizModel.answers[rightIndex].blueOverflowAnimation.build();
        blueOverflow.addEndListener(() => {
          this.quizModel.answers[rightIndex].setBlue();
        });
        parallel.add(blueOverflow);
      }
      this.animationExecutor.schedule(parallel);
    }

    this.animationExecutor.schedule(this.getPointsAnimation(blueAnswerIndex, redAnswerIndex, message));
  }

  private getPointsAnimation(blueAnswerIndex: number, redAnswerIndex: number, message: AnswerResult): BaseAnimation {
    const anim: GroupAnimation = APP.AnimationAPI.createParallel();

    const bluePoints = this.gameInfo.isPlayingAsBlue ? message.myLastPoints : message.opponentLastPoints;
    const redPoints = !this.gameInfo.isPlayingAsBlue ? message.myLastPoints : message.opponentLastPoints;

    if (blueAnswerIndex >= 0 && bluePoints !== 0) {
      this.quizModel.answers[blueAnswerIndex].setBluePoints(bluePoints);
      anim.add(this.quizModel.answers[blueAnswerIndex].bluePointsAnimation.build());

      const seqBlue = APP.AnimationAPI.createSequence();
      seqBlue.add(APP.AnimationAPI.createPause(0.8));
      seqBlue.add(this.gameInfo.bluePlayer.addPoints(bluePoints));
      anim.add(seqBlue);
    }

    if (redAnswerIndex >= 0 && redPoints !== 0) {
      this.quizModel.answers[redAnswerIndex].setRedPoints(redPoints);
      anim.add(this.quizModel.answers[redAnswerIndex].redPointsAnimation.build());

      const seqRed = APP.AnimationAPI.createSequence();
      seqRed.add(APP.AnimationAPI.createPause(0.8));
      seqRed.add(this.gameInfo.redPlayer.addPoints(redPoints));
      anim.add(seqRed);
    }

    return anim;
  }

  private sendAnswerToServer(answerIndex: number, timeElapsed: number): void {
    this.quizModel.disableUnusedButtons(answerIndex);
    this.quizModel.restoreRemovedAnswers();
    this.gameInfo.stopTimer(true);
    this.gameInfo.disableInteraction();
    this.gameInfo.setStatus('');

    const answer: Answer = new Answer();
    answer.index = answerIndex;
    answer.millisecondsElapsed = timeElapsed;
    this.sendMessage(answer);
  }
}
