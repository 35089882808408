import { types } from 'mobx-state-tree';
import { AchievementDescriptor } from 'web/src/resources/AchievementResorces';
import { Reward } from '../../../messages/player/reward/message/Reward';
import { BaseMST } from '../../BaseModel';
import { createRewardItemModel, RewardItemMST } from '../../common/RewardItemModel';

export const AchievementItemMST = BaseMST.named('AchievementItemModel')
  .props({
    completed: types.boolean,
    row: types.number,
    column: types.number,
    awarded: types.boolean,
    rewards: types.array(RewardItemMST),
    currentValue: types.number,
    requiredValue: types.number
  })
  .views(self => ({
    isCompleted() {
      return self.completed;
    },
    getRow() {
      return self.row;
    },
    getColumn() {
      return self.column;
    },
    getAwarded(): boolean {
      return self.awarded;
    },
    getId() {
      return self!.row * 10 + self!.column;
    }
  }))
  .actions(self => ({
    init(achievemetDesc: AchievementDescriptor) {
      // self.achievement = achievemetDesc;
      self.completed = false;
      self.row = achievemetDesc.getRow();
      self.column = achievemetDesc.getColumn();
    },
    setCompleted(completed: boolean) {
      self.completed = completed;
    },
    setAwarded(awarded: boolean) {
      self.awarded = awarded;
    },
    setRewards(rewards: Array<Reward> | undefined) {
      if (!rewards) return;

      rewards!.forEach((reward: Reward) => {
        self.rewards.push(createRewardItemModel(reward.type, reward.count));
      });
    },
    setRequiredValue(value: number) {
      self.requiredValue = value;
    }
  }));

type AchievementItemModelType = typeof AchievementItemMST.Type;
export interface AchievementItemModel extends AchievementItemModelType {}
export const createAchievementItemModel = (aRow: number, aColumn: number) =>
  AchievementItemMST.create({
    completed: false,
    row: aRow,
    column: aColumn,
    awarded: false,
    rewards: [],
    currentValue: 0,
    requiredValue: 0
  });
