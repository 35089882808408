import React, { Component } from 'react';
import { observable } from 'mobx';
import { AchievementsModel } from 'common/src/models/dialogs/achievements/AchievementsModel';
import { inject } from 'mobx-react';
import { UserModel } from 'common/src/models/user/UserModel';
import { userInfo } from 'os';
import { GameResult } from 'common/src/messages/player/message/GameResult';
import AchievementItem from './AchievementItem';

const GAME_STATUS_WON = 0;

interface AchievementItemsProps {
  achievements?: AchievementsModel | undefined;
  scrollTransitY: number;
  user?: UserModel | undefined;
}
@inject('user')
export default class AchievementItems extends Component<AchievementItemsProps> {
  public getDailyWinsCount(): number {
    let winsCount = 0;

    if (this.props.user && this.props.user.info.playedGameResults) {
      this.props.user.info.playedGameResults.forEach((game: GameResult) => {
        if (!game.deleted && game.status >= GAME_STATUS_WON) winsCount++;
      });
    }
    return winsCount++;
  }

  render() {
    const { achievements, scrollTransitY, user } = this.props;
    return (
      <div className="achievements__inner">
        {achievements!.achievements.map(item => (
          <AchievementItem key={item.getId()} item={item} scrollTransitY={scrollTransitY} row={item.row} column={item.column} wins={user!.info.winsInCycle} feathers={user!.info.rankListTotalPoints} todaysWins={this.getDailyWinsCount()} />
        ))}
      </div>
    );
  }
}
