import { ShopConst } from 'common/src/controllers/player/subcontrollers/StorageController';
import { ShopItemModel } from 'common/src/models/dialogs/shop/ShopItemModel';
import Images from 'web/src/resources/ImgResources';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { APP } from 'common/src/app/AppInstance';
import { DEFAULT_VOL } from 'common/src/app/sound/SoundPlayer';
import { PulseLoader } from 'react-spinners';
import { BackgroundMove } from '../../../animations/samples/BackgroundMove';
import { Fade } from '../../../animations/samples/Fade';
import { GSAPPause } from '../../../animations/base/GSAPPause';
import { TranslateTo } from '../../../animations/samples/Translate';
import GSAPParallelAnimation from '../../../animations/base/GSAPParallelAnimation';
import GSAPSequenceAnimation from '../../../animations/base/GSAPSequenceAnimator';

class CardDescription {
  private background: string;

  private backgroundSize: number;

  private over: string | undefined;

  private inner: string | undefined;

  private sound: string | undefined;

  constructor(background: string, backgroundSize: number, inner: string, over: string | undefined = undefined, sound: string | undefined = undefined) {
    this.background = background;
    this.backgroundSize = backgroundSize;
    this.sound = sound;
    this.over = over;
  }

  getBackground() {
    return this.background;
  }

  getBackgroundOver() {
    return this.over;
  }

  getBackgroundSize() {
    return this.backgroundSize;
  }
}

const TOKENS_PACKAGES: Array<CardDescription> = [];
const BOOSTER_PACKAGES: Array<CardDescription> = [];
const HATS_PACKAGES: Array<CardDescription> = [];
const CHAT_PACKAGES: Array<CardDescription> = [];
const CHAT_SOUNDS_PACKAGES: Array<CardDescription> = [];
const CHAT_TEXTS_PACKAGES: Array<CardDescription> = [];
const CHAT_EMOTIONS_PACKAGES: Array<CardDescription> = [];
const CHAT_STICKERS_PACKAGES: Array<CardDescription> = [];

TOKENS_PACKAGES[0] = new CardDescription(Images.package2Tokens, 40, 'shop-card-over');
TOKENS_PACKAGES[1] = new CardDescription(Images.package3Tokens, 60, 'shop-card-over');
TOKENS_PACKAGES[2] = new CardDescription(Images.package4Tokens, 70, 'shop-card-over');
TOKENS_PACKAGES[3] = new CardDescription(Images.package5Tokens, 80, 'shop-card-over');

BOOSTER_PACKAGES[0] = new CardDescription(Images.boosterCardImage, 40, 'shop-card-over');
BOOSTER_PACKAGES[1] = new CardDescription(Images.boosterCardImage, 50, 'shop-card-over');
BOOSTER_PACKAGES[2] = new CardDescription(Images.boosterCardImage, 60, 'shop-card-over');
BOOSTER_PACKAGES[3] = new CardDescription(Images.boosterCardImage, 70, 'shop-card-over');
BOOSTER_PACKAGES[4] = new CardDescription(Images.boosterFreeCardOver, 100, 'shop-card-over', Images.boosterFreeCard);

HATS_PACKAGES[0] = new CardDescription(Images.hat1, 70, 'shop-card-over');
HATS_PACKAGES[1] = new CardDescription(Images.hat2, 70, 'shop-card-over');
HATS_PACKAGES[2] = new CardDescription(Images.hat3, 70, 'shop-card-over');
HATS_PACKAGES[3] = new CardDescription(Images.hat4, 70, 'shop-card-over');
HATS_PACKAGES[4] = new CardDescription(Images.hat5, 70, 'shop-card-over');
HATS_PACKAGES[5] = new CardDescription(Images.hat6, 70, 'shop-card-over');
HATS_PACKAGES[6] = new CardDescription(Images.hat7, 70, 'shop-card-over');
HATS_PACKAGES[7] = new CardDescription(Images.hat8, 70, 'shop-card-over');
HATS_PACKAGES[8] = new CardDescription(Images.hat9, 70, 'shop-card-over');
HATS_PACKAGES[9] = new CardDescription(Images.hat10, 70, 'shop-card-over');
HATS_PACKAGES[10] = new CardDescription(Images.hat11, 70, 'shop-card-over');
HATS_PACKAGES[11] = new CardDescription(Images.hat12, 70, 'shop-card-over');
HATS_PACKAGES[12] = new CardDescription(Images.hat13, 70, 'shop-card-over');
HATS_PACKAGES[13] = new CardDescription(Images.hat14, 70, 'shop-card-over');
HATS_PACKAGES[14] = new CardDescription(Images.hat15, 70, 'shop-card-over');
HATS_PACKAGES[15] = new CardDescription(Images.hat16, 70, 'shop-card-over');
HATS_PACKAGES[16] = new CardDescription(Images.hat17, 70, 'shop-card-over');
HATS_PACKAGES[17] = new CardDescription(Images.hat18, 70, 'shop-card-over');
HATS_PACKAGES[18] = new CardDescription(Images.hat19, 70, 'shop-card-over');
HATS_PACKAGES[19] = new CardDescription(Images.hat20, 70, 'shop-card-over');
HATS_PACKAGES[20] = new CardDescription(Images.hat21, 70, 'shop-card-over');
HATS_PACKAGES[21] = new CardDescription(Images.hat22, 70, 'shop-card-over');
HATS_PACKAGES[22] = new CardDescription(Images.hat23, 70, 'shop-card-over');
HATS_PACKAGES[23] = new CardDescription(Images.hat24, 70, 'shop-card-over');
HATS_PACKAGES[24] = new CardDescription(Images.hat25, 70, 'shop-card-over');

// chat pakeges sadrze 4 razlicita chat paketa
// stavicu da su grupe jedinstvene na nivou CHAT_PACKAGES jer je to deo jednog taba
// CHAT_TEXTS_PACKAGES[0] = new CardDescription(Images.textSet1, 100, 'shop-card-over');
CHAT_TEXTS_PACKAGES[1] = new CardDescription(Images.textSet1, 100, 'shop-card-over');
CHAT_TEXTS_PACKAGES[2] = new CardDescription(Images.textSet2, 100, 'shop-card-over');

CHAT_EMOTIONS_PACKAGES[0] = new CardDescription(Images.emoticonsSet0, 100, 'shop-card-over');

CHAT_STICKERS_PACKAGES[0] = new CardDescription(Images.stickersSet0, 100, 'shop-card-over');
CHAT_STICKERS_PACKAGES[1] = new CardDescription(Images.stickersSet1, 100, 'shop-card-over');

CHAT_SOUNDS_PACKAGES[0] = new CardDescription(Images.dialogsShopSounds1, 100, 'shop-card-over');
CHAT_SOUNDS_PACKAGES[1] = new CardDescription(Images.dialogsShopSounds2, 100, 'shop-card-over');
CHAT_SOUNDS_PACKAGES[2] = new CardDescription(Images.dialogsShopSounds3, 100, 'shop-card-over');
CHAT_SOUNDS_PACKAGES[3] = new CardDescription(Images.dialogsShopSounds4, 100, 'shop-card-over');
CHAT_SOUNDS_PACKAGES[4] = new CardDescription(Images.dialogsShopSounds5, 100, 'shop-card-over');
CHAT_SOUNDS_PACKAGES[5] = new CardDescription(Images.dialogsShopSounds6, 100, 'shop-card-over');
CHAT_SOUNDS_PACKAGES[6] = new CardDescription(Images.dialogsShopSounds7, 100, 'shop-card-over');
CHAT_SOUNDS_PACKAGES[7] = new CardDescription(Images.dialogsShopSounds8, 100, 'shop-card-over');

CHAT_PACKAGES.push(CHAT_TEXTS_PACKAGES[0]);
CHAT_PACKAGES.push(CHAT_EMOTIONS_PACKAGES[0]);
CHAT_PACKAGES.push(CHAT_STICKERS_PACKAGES[0]);
CHAT_PACKAGES.push(CHAT_SOUNDS_PACKAGES[0]);
CHAT_PACKAGES.push(CHAT_TEXTS_PACKAGES[1]);
CHAT_PACKAGES.push(CHAT_STICKERS_PACKAGES[1]);
CHAT_PACKAGES.push(CHAT_SOUNDS_PACKAGES[1]);

function getTokensIndex(offer: number) {
  let index = 0;
  if (offer < 36) {
    index = offer % 4;
    if (index === 3) index = 4;
  } else {
    index = 3;
  }

  return index;
}

function getItemDesc(group: number, itemId: number, offer: number) {
  let desc = new CardDescription('', 0, '', '');

  if (group === ShopConst.Tokens) desc = TOKENS_PACKAGES[offer];
  else if (group === ShopConst.Hats) desc = HATS_PACKAGES[itemId];
  else if (group === ShopConst.Emoticons) desc = CHAT_EMOTIONS_PACKAGES[itemId];
  else if (group === ShopConst.TextMessages) desc = CHAT_TEXTS_PACKAGES[itemId];
  else if (group === ShopConst.Sounds) {
    desc = CHAT_SOUNDS_PACKAGES[itemId];
  } else if (group === ShopConst.Stickers) desc = CHAT_STICKERS_PACKAGES[itemId];

  if (desc) return desc;

  return new CardDescription('', 0, '', '');
}

interface ShopCardProps {
  item: ShopItemModel;
  selectedGroup: number;
  selectedTab: number;
  forceGroup?: number;
}
@observer
export default class Card extends Component<ShopCardProps, { opacity: number }> {
  private countDiv: HTMLDivElement | null = null;

  private animatedCheckedDiv: HTMLDivElement | null = null;

  private checkedDiv: HTMLDivElement | null = null;

  private buyDiv: HTMLDivElement | null = null;

  private currentSelection: number = -1;

  constructor(props: any) {
    super(props);
    this.state = {
      opacity: 1
    };
  }

  getCardClass(type: number) {
    return `package${type}`;
  }

  getCurrencySign(currency: string) {
    switch (currency) {
      case 'USD':
        return '$';
      case 'EUR':
        return '\u20AC';
      case 'CHF':
        return ' CHF';
      case 'RSD':
        return ' din';
      default:
        return '\u20AC';
    }
  }

  getCardPrice(price: number, currency: string, selectedGroup: number) {
    if (selectedGroup === 0) {
      return `${price}${this.getCurrencySign(currency)}`;
    }
    return `x${price}`;
  }

  createItemAnimation = () => {
    const parallel: GSAPParallelAnimation = new GSAPParallelAnimation();
    const seq1 = new GSAPSequenceAnimation();
    const seq2 = new GSAPSequenceAnimation();

    if (this.countDiv != null && this.props.item.groupId === ShopConst.Tokens) {
      seq1.add(new Fade(this.countDiv, 0.3, 0, 0));
      seq1.add(new Fade(this.countDiv, 0.4, 0, 1));
      seq1.add(new Fade(this.countDiv, 0.3, 1, 1));
      seq1.add(new TranslateTo(this.countDiv, 0.5, 0, -30, 0));
      seq1.add(new Fade(this.countDiv, 0.2, 1, 0));
      seq1.add(new TranslateTo(this.countDiv, 0, 0, 0, 0));
    }

    if (this.checkedDiv != null && (this.props.item.groupId === ShopConst.Hats || this.props.item.groupId === ShopConst.Emoticons || this.props.item.groupId === ShopConst.Stickers || this.props.item.groupId === ShopConst.TextMessages || this.props.item.groupId === ShopConst.Sounds)) {
      seq1.add(new Fade(this.checkedDiv, 0.6, 0, 0));
      seq1.add(new Fade(this.checkedDiv, 0.1, 0, 1));
    }

    if (this.buyDiv != null) {
      seq2.add(new Fade(this.buyDiv, 0.1, 1, 0));
    }

    if (this.animatedCheckedDiv != null) {
      seq2.add(new Fade(this.animatedCheckedDiv, 0, 0, 1));
      seq2.add(new BackgroundMove(this.animatedCheckedDiv, -594));
      seq2.add(new GSAPPause(0.25));
      seq2.add(new Fade(this.animatedCheckedDiv, 0.2, 1, 0));
      seq2.add(new BackgroundMove(this.animatedCheckedDiv, 0));
    }

    if (this.buyDiv != null) {
      seq2.add(new Fade(this.buyDiv, 0.2, 0, 1));
    }

    parallel.add(seq1);
    parallel.add(seq2);
    parallel.addStartListener(() => {
      APP.SoundPlayer.playCashboxSound(DEFAULT_VOL);
    });

    return parallel;
  };

  setOpacity = (value: number) => {
    const { item } = this.props;
    this.setState({ opacity: item.freeCard || item.purchased ? 1 : value });
  };

  createAnimations() {
    this.props.item.itemAnimation.set(this.createItemAnimation);
  }

  destroyAnimations() {
    this.props.item.itemAnimation.dispose();
  }

  render() {
    const { opacity } = this.state;
    const { item, forceGroup, selectedTab } = this.props;
    let { selectedGroup } = this.props;

    if (this.currentSelection !== selectedGroup) {
      this.currentSelection = selectedGroup;
      this.destroyAnimations();
      this.createAnimations();
    }

    if (typeof forceGroup === 'undefined') {
      selectedGroup = selectedTab;
    }

    const bgSize = getItemDesc(item.groupId, item.id, item.offerId).getBackgroundSize();

    const backgroundClass = item.purchased ? 'shop_card--background-purchased' : 'background';

    const ctop = 20;

    //   const cardClass = !item.freeCard ?'card' : 'freeCard' ; //item.selected ? 'askFrend' :
    return (
      <div
        className={item.purchased ? `shop_card--type${selectedGroup}--purchased` : `shop_card--type${selectedGroup}`}
        onMouseOver={() => item.onSelected()}
        onFocus={() => item.onSelected()}
        onMouseLeave={() => item.onUnselected()}
        onClick={() => (item.purchased ? {} : item.onItemAction())}
        style={{ position: 'relative', backgroundSize: 'contain' }}
      >
        {!item.freeCard && (
          <img
            className="card"
            onMouseEnter={() => this.setOpacity(0.5)}
            onMouseLeave={() => this.setOpacity(1)}
            src={getItemDesc(item.groupId, item.id, item.offerId).getBackground()}
            style={{
              width: `${bgSize / 10}rem`,
              position: 'absolute',
              zIndex: 100,
              left: `${(28 - bgSize / 10 + (70 - bgSize) / 3) / 10}rem`,
              top: `${(ctop - bgSize / 10 + (70 - bgSize) / 4) / 10 + (item.groupId === 100 ? bgSize / 40 : 0)}rem`,
              objectFit: 'contain'
            }}
          />
        )}
        {!item.freeCard && selectedGroup === 0 && (
          <div className={`counter--type${selectedGroup}`} onMouseEnter={() => this.setOpacity(0.5)} onMouseLeave={() => this.setOpacity(1)}>
            <div className="counter-text">
              <div className={`shop_card--token${selectedGroup}`} />
              <div style={{ display: 'inline-block', verticalAlign: 'bottom' }}>x{item.valueForPrice}</div>
            </div>
          </div>
        )}
        {item.bestValue && (
          <img
            src={Images.bestBuy}
            style={{
              position: 'absolute',
              width: '11rem',
              zIndex: 100,
              marginLeft: '-2.5rem',
              marginTop: '-2.5rem',
              objectFit: 'contain'
            }}
          />
        )}
        {item.newItem && (
          <img
            src={Images.stickerNew}
            style={{
              width: '6rem',
              position: 'absolute',
              zIndex: 100,
              marginLeft: '4.7rem',
              marginTop: '-0.3rem',
              objectFit: 'contain'
            }}
          />
        )}
        <div className="shop__animated-buy-done-frame">
          <div
            className="shop__animated-buy-done"
            ref={animatedCheckedDiv => {
              this.animatedCheckedDiv = animatedCheckedDiv;
            }}
          />
        </div>
        <div
          className="shop__bought-count shop__bought-count--booster"
          ref={countDiv => {
            this.countDiv = countDiv;
          }}
        >
          +{item.valueForPrice}
        </div>
        <div className={backgroundClass} style={{ opacity }} onMouseEnter={() => this.setOpacity(0.5)} onMouseLeave={() => this.setOpacity(1)}>
          {item.freeCard && <img src={item.selected ? getItemDesc(item.groupId, item.id, item.offerId).getBackgroundOver() : getItemDesc(item.groupId, item.id, item.offerId).getBackground()} className="freeCard" />}
        </div>
        {!item.freeCard && !item.selected && !item.purchased && !item.inProgress && (
          <div className="shop__item-coast">
            <div className={selectedGroup > 0 ? 'shop_card--token' : ''} />
            <div style={{ display: 'inline-block', position: 'relative' }}>{this.getCardPrice(item.price, item.currency, selectedGroup)} </div>
          </div>
        )}
        {!item.freeCard && item.selected && !item.purchased && !item.inProgress && (
          <div
            className="shop__buy-text"
            ref={buyDiv => {
              this.buyDiv = buyDiv;
            }}
          />
        )}
        {item.inProgress && (
          <div>
            <PulseLoader
              color="#a15a0c"
              sizeUnit="em"
              size={1}
              css="
                padding-left: 3rem;
                padding-top: 1.2rem;
                display: inline-block;
              "
            />
          </div>
        )}

        <div
          style={{ opacity: item.purchased ? 1 : 0, objectFit: 'contain' }}
          className="shop__buy-done"
          ref={checkedDiv => {
            this.checkedDiv = checkedDiv;
          }}
        />
      </div>
    );
  }
}
