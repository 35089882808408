import { ActiveDialogsModel } from '../../models/dialogs/ActiveDialogsModel';
import { DisplayModel } from '../../models/DisplayModel';

export class DialogManager {
  // dilaogs

  private scheduledDialogs: DisplayModel[] = [];

  private currents: ActiveDialogsModel;

  constructor(activeDialogsModel: ActiveDialogsModel) {
    this.currents = activeDialogsModel;
  }

  private get currentDialogs() {
    return this.currents.getDialogs();
  }

  getCurrentDialog(): DisplayModel | undefined {
    if (this.currentDialogs.length > 0) return this.currentDialogs[0];
    return undefined;
  }

  isVisible(dialog: DisplayModel): boolean {
    return this.currentDialogs.indexOf(dialog, 0) > -1;
  }

  onSchedule(dialog: DisplayModel) {
    if (this.currentDialogs.length > 0) {
      // check is already scheduled or displayed
      if (this.scheduledDialogs.indexOf(dialog, 0) < 0 && this.currentDialogs.indexOf(dialog, 0) < 0) {
        this.scheduledDialogs.push(dialog);
      }
    } else {
      dialog.show();
    }
  }

  onShow(dialog: DisplayModel) {
    this.currentDialogs.push(dialog);
    // TODO check!  this.currents.addDialog(dialog);
  }

  onHide(dialog: DisplayModel) {
    const indexToRemove: number = this.currentDialogs.indexOf(dialog, 0);
    if (indexToRemove > -1) {
      this.currentDialogs.splice(indexToRemove, 1);
    }

    if (this.currentDialogs.length === 0) {
      const scheduled: DisplayModel | undefined = this.scheduledDialogs.pop();
      if (scheduled) {
        scheduled.show();
      }
    }
  }
}
