import { ServerEvent } from '../../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.games.puzzle.message.PuzzleStatus';

const FOUND = 0;
const INVALID_LETTERS = 1;
const NOT_FOUND = 2;

const NO_WINNERS = 0;
const BLUE_WINS = 1;
const RED_WINS = 2;

const STATUS_NORMAL = 0;
export class PuzzleStatus extends ServerEvent {
  letters?: Array<number> = [];

  targetWord?: Array<number> = [];

  blueAnswer?: Array<number> = [];

  redAnswer?: Array<number> = [];

  blueLengthPoints: number = 0;

  redLengthPoints: number = 0;

  blueGameBonus: number = 0;

  redGameBonus: number = 0;

  blueExtraBonus: number = 0;

  redExtraBonus: number = 0;

  winResult: number = PuzzleStatus.NO_WINNERS;

  blueWordEvaluated: number = PuzzleStatus.INVALID_LETTERS;

  redWordEvaluated: number = PuzzleStatus.INVALID_LETTERS;

  status: number = STATUS_NORMAL;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get FOUND() {
    return FOUND;
  }

  static get INVALID_LETTERS() {
    return INVALID_LETTERS;
  }

  static get NOT_FOUND() {
    return NOT_FOUND;
  }

  static get NO_WINNERS() {
    return NO_WINNERS;
  }

  static get BLUE_WINS() {
    return BLUE_WINS;
  }

  static get RED_WINS() {
    return RED_WINS;
  }

  public getMyAnswer(playingAsBlue: Boolean): Array<number> | undefined {
    if (playingAsBlue) return this.blueAnswer;
    return this.redAnswer;
  }

  public getOpponentAnswer(playingAsBlue: boolean): Array<number> | undefined {
    if (playingAsBlue) return this.redAnswer;
    return this.blueAnswer;
  }

  public getMyWordFlag(playingAsBlue: boolean): number {
    if (playingAsBlue) return this.blueWordEvaluated;
    return this.redWordEvaluated;
  }

  public getOpponentWordFlag(playingAsBlue: boolean): number {
    if (playingAsBlue) return this.redWordEvaluated;
    return this.blueWordEvaluated;
  }
}
