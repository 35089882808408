import { Animation, Linear, TimelineLite, TweenMax } from 'gsap';
import GSAPAnimation from '../base/GSAPAnimation';

function createGlow(target: HTMLElement | null, duration: number, repeatCount: number = 0): Animation {
  const timeline = new TimelineLite({ paused: true });
  if (target)
    timeline.add(
      TweenMax.fromTo(
        target,
        duration / (repeatCount + 1),
        {
          boxShadow: '0px 0px 0px 0px rgba(255, 255, 255, 0)'
        },
        {
          boxShadow: '0px 0px 2px 3px rgba(255, 255, 255, 1)',
          repeat: repeatCount,
          yoyo: true,
          ease: Linear.easeNone
        }
      )
    );

  return timeline;
}
export class Glow extends GSAPAnimation {
  constructor(target: HTMLElement | null, duration: number, repeatCount: number = 0) {
    super(createGlow(target, duration, repeatCount));
  }
}
