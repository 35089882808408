import { types } from 'mobx-state-tree';
import { BaseMST } from '../BaseModel';
import { ShopItemModel } from '../dialogs/shop/ShopItemModel';
import { ActionMST } from '../ActionModel';
import { HatResources } from '../../../../web/src/resources/HatResorces';

export const HatsMST = BaseMST.named('HatsMST')
  .props({
    hatIds: types.array(types.number),
    currentHatIndex: types.number,
    opponentHatId: types.number,
    hatActivationAction: ActionMST
  })
  .views(self => ({
    hasHat(): boolean {
      return self.currentHatIndex >= 0;
    },
    hasOpponentHat(): boolean {
      return self.opponentHatId >= 0;
    },
    getHatId(): number {
      return self.hatIds[self.currentHatIndex];
    },
    getOpponentHatId(): number {
      return self.opponentHatId;
    }
  }))
  .actions(self => {
    let timeout: NodeJS.Timeout | undefined;

    function setCurrentHat() {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(self.hatActivationAction.getAction()(self.hatIds[self.currentHatIndex]), 3000);
    }

    return { setCurrentHat };
  })
  .actions(self => ({
    initHats(hats: Array<ShopItemModel>, hatActivationAction: Function) {
      hats.forEach((hatItem: ShopItemModel) => {
        if (hatItem.purchased) {
          self.hatIds.push(hatItem.id);
        }
      });
      self.hatActivationAction.setAction(hatActivationAction);
    },
    setMyHat(hatId: number) {
      let index = 0;
      self.hatIds.forEach((currentId: number) => {
        if (currentId === hatId) self.currentHatIndex = index;
        index++;
      });
    },
    setOpponentsHat(hatId: number) {
      self.opponentHatId = hatId;
    },
    setNextHat() {
      if (self.hatIds.length === 0) return;

      self.currentHatIndex = self.currentHatIndex + 1 < self.hatIds.length ? self.currentHatIndex + 1 : 0;
      self.setCurrentHat();
    },
    setPreviousHat() {
      if (self.hatIds.length === 0) return;

      self.currentHatIndex = self.currentHatIndex <= 0 ? self.hatIds.length - 1 : self.currentHatIndex - 1;
      self.setCurrentHat();
    }
  }));

type HatsModelType = typeof HatsMST.Type;
export interface HatsModel extends HatsModelType {}

export const createHatsModel = () =>
  HatsMST.create({
    hatIds: [],
    currentHatIndex: -1,
    opponentHatId: -1,
    hatActivationAction: {}
  });
