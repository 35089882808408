import { PlayerInfoBase } from './PlayerInfoBase';

const BINDING_NAME = 'rs.slagalica.player.message.FriendStatusUpdate';

export class FriendStatusUpdate extends PlayerInfoBase {
  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
