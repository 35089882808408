import { types } from 'mobx-state-tree';
import { CollaborationMST, createCollaborationModel } from 'web/src/collaboration/model/CollaborationModel';
import { CollaborationStatusMST, createCollaborationStatusModel } from 'web/src/collaboration/model/CollaborationStatusModel';
import { B2bTournamentMST, createB2bTournamentModel } from './models/b2b/B2bTournamentModel';
import { BoosterMST, createBoosterModel } from './models/booster/BoosterModel';
import { AchievementsMST, createAchievementsModel } from './models/dialogs/achievements/AchievementsModel';
import { ActiveDialogsMST, createActiveDialogsModel } from './models/dialogs/ActiveDialogsModel';
import { B2bCaptainMST, createB2bCaptainModel } from './models/dialogs/b2bgame/B2bCaptainModel';
import { B2bMyTeamMST, createB2bMyTeamModel } from './models/dialogs/b2bgame/B2bMyTeamModel';
import { B2bNoCaptainMST, createB2bNoCaptainModel } from './models/dialogs/b2bgame/B2bNoCaptainModel';
import { createDeclineGameModel, DeclineGameMST } from './models/dialogs/declinegame/DeclineGameModel';
import { createFAQModel, FAQMST } from './models/dialogs/faq/FAQModel';
import { createFeatherBoosterModel, FeatherBoosterMST } from './models/dialogs/featherbooster/FeatherBoosterModel';
import { createInboxDialogModel, InboxMST } from './models/dialogs/inbox/InboxModel';
import { B2bLoginMST, createB2bLoginModel } from './models/dialogs/login/B2bLoginModel';
import { createSearchOpponentModel, SearchOpponentMST } from './models/dialogs/searchopponent/SearchOpponentModel';
import { createShopModel, ShopModelMST } from './models/dialogs/shop/ShopModel';
import { createShopTokensMobileModel, ShopTokensMobileMST } from './models/dialogs/shoptokens/ShopTokensMobileModel';
import { createShopTokensModel, ShopTokensModelMST } from './models/dialogs/shoptokens/ShopTokensModel';
import { createTokensInfoModel, TokensInfoMST } from './models/dialogs/shoptokens/TokensInfoModel';
import { createDummyStatisticsModel, StatisticsMST } from './models/dialogs/statistics/StatisticsModel';
import { createSurveyDialogModel, SurveyDialogMST } from './models/dialogs/survey/SurveyDialogModel';
import { createVoucherModel, VoucherMST } from './models/dialogs/voucher/VoucherModel';
import { createDisplayModel, DisplayMST } from './models/DisplayModel';
import { DisplayModelKeys } from './models/DisplayModelKeys';
import { createFriendsModel, FriendsMST } from './models/friends/FriendsModel';
import { ChatMST, createChatModel } from './models/game/chat/ChatModel';
import { AssociationMST, createAssociationModel } from './models/game/content/association/AssociationModel';
import { CombinationsMST, createCombinationsModel } from './models/game/content/combinations/CombinationsModel';
import { createMatchingModel, MatchingMST } from './models/game/content/matching/MatchingModel';
import { createMyNumber, MyNumberMST } from './models/game/content/mynumber/MyNumberModel';
import { createPairs, PairsMST } from './models/game/content/pairs/PairsModel';
import { createPuzzleModel, PuzzleMST } from './models/game/content/puzzle/PuzzleModel';
import { createQuizModel, QuizMST } from './models/game/content/quiz/QuizModel';
import { createResultModel, ResultMST } from './models/game/content/ResultModel';
import { createGameInfoModel, GameInfoMST } from './models/game/GameInfoModel';
import { createGameModel, GameMST } from './models/game/GameScreenModel';
import { createHatsModel, HatsMST } from './models/game/HatsModel';
import { createLoaderModel, LoaderMST } from './models/loader/LoaderModel';
import { createLobbyModel, LobbyMST } from './models/lobby/LobbyModel';
import { createSettingsModel, SettingsMST } from './models/settings/SettingsModel';
import { createTestModel, TestMST } from './models/test/TestModel';
import { createToolbarModel, ToolbarMST } from './models/toolbar/ToolbarModel';
import { createTournamentsModel, TournamentsMST } from './models/tournament/TorunamentsModel';
import { createUserModel, UserMST } from './models/user/UserModel';

const RootStoreMST = types.model('RootStoreMST', {
  toolbar: ToolbarMST,
  tournaments: TournamentsMST,
  friends: FriendsMST,
  user: UserMST,
  b2blogindata: B2bLoginMST,
  b2bTournament: B2bTournamentMST,
  b2bMyTeam: B2bMyTeamMST,
  logo: DisplayMST,
  lobby: LobbyMST,
  game: GameMST,
  loader: LoaderMST,
  gameInfo: GameInfoMST,

  chat: ChatMST,
  puzzle: PuzzleMST,
  quiz: QuizMST,
  matching: MatchingMST,
  association: AssociationMST,
  myNumber: MyNumberMST,
  pairs: PairsMST,
  combinations: CombinationsMST,
  result: ResultMST,
  hats: HatsMST,
  shop: ShopModelMST,
  shopTokens: ShopTokensModelMST,
  inbox: InboxMST,
  achievements: AchievementsMST,
  statistics: StatisticsMST,
  faq: FAQMST,
  featherBooster: FeatherBoosterMST,
  survey: SurveyDialogMST,
  test: TestMST,
  shopTokensMobile: ShopTokensMobileMST,
  tokensInfo: TokensInfoMST,
  voucher: VoucherMST,
  booster: BoosterMST,
  settings: SettingsMST,
  activeDialogs: ActiveDialogsMST,
  searchOpponent: SearchOpponentMST,
  declineGameModel: DeclineGameMST,

  collaboration: CollaborationMST,
  collaborationStatus: CollaborationStatusMST,
  captain: B2bCaptainMST,
  noCaptain: B2bNoCaptainMST
});

export const createStore = (): RootStore => {
  const root = RootStoreMST.create({
    toolbar: createToolbarModel(),
    tournaments: createTournamentsModel(),
    friends: createFriendsModel(),
    user: createUserModel(),
    b2blogindata: createB2bLoginModel(),
    b2bTournament: createB2bTournamentModel(),
    b2bMyTeam: createB2bMyTeamModel(),
    logo: createDisplayModel(DisplayModelKeys.Logo),
    lobby: createLobbyModel(),
    game: createGameModel(),
    loader: createLoaderModel(),
    gameInfo: createGameInfoModel(),
    chat: createChatModel(),
    puzzle: createPuzzleModel(),
    quiz: createQuizModel(),
    matching: createMatchingModel(),
    association: createAssociationModel(),
    myNumber: createMyNumber(),
    pairs: createPairs(),
    combinations: createCombinationsModel(),
    result: createResultModel(),
    hats: createHatsModel(),
    shop: createShopModel(),
    shopTokens: createShopTokensModel(),
    inbox: createInboxDialogModel(),
    achievements: createAchievementsModel(),
    statistics: createDummyStatisticsModel(),
    faq: createFAQModel(),
    featherBooster: createFeatherBoosterModel(),
    survey: createSurveyDialogModel(),
    test: createTestModel(),
    shopTokensMobile: createShopTokensMobileModel(),
    tokensInfo: createTokensInfoModel(),
    voucher: createVoucherModel(),
    booster: createBoosterModel(),
    settings: createSettingsModel(),
    activeDialogs: createActiveDialogsModel(),
    searchOpponent: createSearchOpponentModel(),
    declineGameModel: createDeclineGameModel(),

    collaboration: createCollaborationModel(),
    collaborationStatus: createCollaborationStatusModel(),
    captain: createB2bCaptainModel(),
    noCaptain: createB2bNoCaptainModel()
  });
  return root;
};

type RootStoreType = typeof RootStoreMST.Type;
export interface RootStore extends RootStoreType {}
