import { ServerEvent } from '../../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.games.puzzle.message.WordStatus';

const FOUND = 0; // fieldType: int
const INVALID_LETTERS = 1; // fieldType: int
const NOT_FOUND = 2; // fieldType: int

export class WordStatus extends ServerEvent {
  word?: Array<number> = undefined;

  isCorrect: boolean = false;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get FOUND() {
    return FOUND;
  }

  static get INVALID_LETTERS() {
    return INVALID_LETTERS;
  }

  static get NOT_FOUND() {
    return NOT_FOUND;
  }
}
