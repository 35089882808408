import { B2bTeamModel } from 'common/src/models/b2b/B2bTeamModel';
import { B2bTournamentModel } from 'common/src/models/b2b/B2bTournamentModel';
import { LobbyModel } from 'common/src/models/lobby/LobbyModel';
import { UserModel } from 'common/src/models/user/UserModel';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { BaseScreen, BaseScreenProps } from '../BaseScreen';
import B2bCountdown from './B2bCountdown';
import B2bGameStatus from './B2bGameStatus';
import B2bMyTeam from './B2bMyTeam';
import B2bSchedule from './B2bSchedule';
import WeeklyTournament from './WeeklyTournament';
import { version } from '../../../package.json';

interface LobbyProps extends BaseScreenProps {
  lobby?: LobbyModel;
  b2bTournament?: B2bTournamentModel;
  user?: UserModel;
}
@inject('lobby', 'b2bTournament', 'user')
@observer
export default class LobbyScreen extends BaseScreen<LobbyProps, {}> {
  isVisible(): boolean {
    const { lobby } = this.props;
    return lobby ? lobby!.isVisible() : false;
  }

  renderVisible(): React.ReactNode {
    const { lobby, b2bTournament, user } = this.props;

    const myTeam: B2bTeamModel = b2bTournament!.getTeam(user!.info.b2bTeam!);

    return (
      <div className="lobby">
        <div className="lobby__b2b-toolbar" />
        <div className="lobby__b2b-version">Ver: {version}</div>
        <div className="lobby__content">
          <div className="lobby__left">
            <B2bMyTeam myTeam={myTeam} myId={user!.info.b2bId} />
            <B2bGameStatus active={b2bTournament!.active} />
            <B2bCountdown isVisible={this.isVisible()} />
          </div>
          <div className="lobby__right">
            {/* <B2bSound className="nav__toolbar-sound--lobby" /> */}
            <WeeklyTournament />
          </div>
        </div>
        {/* <div className="lobby__b2b-banner" style={{ backgroundImage: `url(${b2bTournament!.bannerUrl})` }} /> */}
        <div className="lobby__footer">
          <B2bSchedule />
        </div>
      </div>
    );
  }
}
