import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { ShopModel } from 'common/src/models/dialogs/shop/ShopModel';
import { DialogProps } from '../BaseDialog';
import Card from './Card';

interface TabProps extends DialogProps {
  title: string;
  desc: string;
  forceGroup?: number;
  shop?: ShopModel;
}

@inject('shop')
@observer
export default class Tab extends Component<TabProps> {
  increaseHorizontal = () => {
    this.props.shop!.increaseHorizontal();
  };

  decreaseHorizontal = () => {
    this.props.shop!.decreaseHorizontal();
  };

  render() {
    const { title, desc, shop, forceGroup } = this.props;
    const { selectedGroup, selectedTab } = shop!;
    const cards = shop!.items();

    let styleTabContent = {};
    // different tab content css
    styleTabContent = { width: '58.4rem' };

    let styleTabCards = {};
    // different tab content css
    styleTabCards = {
      marginLeft: `${shop!.horizontalMove()}rem`,
      paddingLeft: 0,
      paddingRight: 0
    };

    return (
      <div className={`shop__tab-content-background ${forceGroup === 0 && selectedGroup > 0 ? 'shop__up-animation' : ''}`}>
        <div className="shop__tab-title">{title}</div>
        <div className="shop__tab-description">{desc}</div>
        <div className="shop__tab-content" style={styleTabContent}>
          <div className="shop__tab-cards" style={styleTabCards}>
            {cards.map(c => (
              <Card item={c} selectedGroup={forceGroup || selectedGroup} selectedTab={selectedTab} forceGroup={forceGroup} />
            ))}
          </div>
        </div>
        {forceGroup !== 0 && shop!.horizontalGroup > 0 && <div className="shop__arrow-left" onClick={this.decreaseHorizontal} />}
        {forceGroup !== 0 && shop!.showRightArrow() && <div className="shop__arrow-right" onClick={this.increaseHorizontal} />}
      </div>
    );
  }
}
