import { ServerEvent } from '../../communication/message/ServerEvent';
import { Discount } from '../../credits/message/Discount';

const BINDING_NAME = 'rs.slagalica.player.message.DiscountResponse';

export class DiscountResponse extends ServerEvent {
  discount?: Discount = undefined; // fieldType: Discount

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
