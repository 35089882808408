import { ServerEvent } from './ServerEvent';

const BINDING_NAME = 'rs.slagalica.communication.message.ServerEventGroup';

export class ServerEventGroup extends ServerEvent {
  events: Array<ServerEvent> = []; // fieldType: List

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
