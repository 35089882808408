import { Component, ReactNode } from 'react';

export abstract class VisibleComponent<T, S> extends Component<T, S> {
  abstract renderVisible(): ReactNode;

  abstract isVisible(): boolean;

  render(): ReactNode {
    if (this.isVisible()) return this.renderVisible();

    return null;
  }
}
