import { PlayerInfo } from '../../../messages/player/message/PlayerInfo';
import { StartGameResponse } from '../../../messages/player/message/StartGameResponse';
import { ActionMST } from '../../ActionModel';
import { AnimationBuilderMST } from '../../AnimationBuilder';
import { DisplayMST } from '../../DisplayModel';
import { UserModel } from '../../user/UserModel';
import { StatisticsMST, createStatisticsModel } from '../statistics/StatisticsModel';
import { GameAcceptanceHolderMST } from './GameAcceptanceHolderModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';
import { APP } from '../../../app/AppInstance';

export const SearchOpponentMST = DisplayMST.named('SearchOpponentModel')
  .props({
    playerRating: 0,
    playerName: '',
    playerRank: 0,
    playerFbId: '',
    opponentRating: -1,
    opponentName: '',
    opponentRank: 0,
    opponentFbId: '',
    opponentFeathers: 0,
    opponentMaxPrevRank: 0,
    playerResponse: StartGameResponse.NOT_COMFIRMED,
    opponentResponse: StartGameResponse.NOT_COMFIRMED,
    opponentSearchAnimation: AnimationBuilderMST,
    opponentSelectAnimation: AnimationBuilderMST,
    friendlyGame: false,
    cancelMatching: ActionMST,
    gameAcceptanceHolder: GameAcceptanceHolderMST,
    timer: 0,
    timerMax: 1,
    opponentStats: StatisticsMST,
    openAnimation: AnimationBuilderMST,
    closeAnimation: AnimationBuilderMST
  })
  .views(self => ({}))
  .actions(self => ({
    setMyInfo(user: UserModel) {
      self.playerRating = user.info.rankingPoints;
      self.playerName = user.info.getShortName();
      self.playerRank = user.info.rank;
      self.playerFbId = user.info.facebookId || '';
    },
    setOpponentInfo(opponentInfo: PlayerInfo) {
      self.opponentRating = opponentInfo.rankingPoints;
      self.opponentName = opponentInfo.getShortName();
      self.opponentRank = opponentInfo!.rank;
      self.opponentFbId = opponentInfo.facebookId || '';
      self.opponentFeathers = opponentInfo.rankListTotalPoints;
      self.opponentStats.setStatistics(opponentInfo.stat);
      self.opponentMaxPrevRank = opponentInfo.maxPrevRank;
    },
    clearOpponentInfo() {
      self.opponentRating = -1;
      self.opponentName = '';
      self.opponentRank = 0;
      self.opponentFbId = '';
      self.opponentResponse = StartGameResponse.NOT_COMFIRMED;
      self.opponentMaxPrevRank = 0;
    },
    setOpponentName(name: string) {
      self.opponentName = name;
    },
    setFriendlyGame(friendlyGame: boolean) {
      self.friendlyGame = friendlyGame;
    },
    setActions(cancelMatching: Function, acceptMatching: Function, declineMatching: Function) {
      self.cancelMatching.setAction(cancelMatching);
      self.gameAcceptanceHolder.setVisible(false);
      self.gameAcceptanceHolder.acceptAction.setAction(acceptMatching);
      self.gameAcceptanceHolder.declineAction.setAction(declineMatching);
      this.setBtnsEnabled(true);
    },
    setBtnsEnabled(enabled: boolean) {
      self.gameAcceptanceHolder.acceptAction.setEnabled(enabled);
      self.gameAcceptanceHolder.declineAction.setEnabled(enabled);
    },
    onTick() {
      const currentTime = self.timer;
      if (currentTime > 0) {
        self.timer = currentTime - 1;
      }
    },
    setTimer(time: number) {
      self.timer = time;
      self.timerMax = time;
    },
    updateTimer(time: number) {
      self.timer = time;
    },
    setResponse(isMe: boolean, response: number) {
      if (isMe) {
        self.playerResponse = response;
      } else {
        self.opponentResponse = response;
      }
    }
  }))
  .actions(self => {
    let intervalId: NodeJS.Timeout | undefined;
    let timeoutId: number | undefined;

    function stopTimer() {
      if (timeoutId) clearTimeout(timeoutId);
      if (intervalId) clearInterval(intervalId);
    }

    function startTimer(callback: Function, timeout: number) {
      stopTimer();
      self.setTimer(timeout);
      timeoutId = setTimeout(callback, timeout * 1000);
      intervalId = setInterval(() => {
        self.onTick();
      }, 1000);
    }

    return { startTimer, stopTimer };
  });

type SearchOpponentModelType = typeof SearchOpponentMST.Type;
export interface SearchOpponentModel extends SearchOpponentModelType {}
export const createSearchOpponentModel = () =>
  SearchOpponentMST.create({
    key: DisplayModelKeys.SearchOpponent,
    playerRating: 0,
    playerName: '',
    playerRank: 0,
    playerFbId: '',
    opponentRating: -1,
    opponentName: '',
    opponentRank: 0,
    opponentFeathers: 0,
    opponentFbId: '',
    opponentSearchAnimation: {},
    opponentSelectAnimation: {},
    friendlyGame: false,
    cancelMatching: {},
    gameAcceptanceHolder: {},
    playerResponse: 0,
    opponentResponse: 0,
    opponentMaxPrevRank: 0,
    opponentStats: createStatisticsModel(),
    openAnimation: {},
    closeAnimation: {}
  });
