import { Sounds } from 'web/src/resources/SoundResources';
import { APP } from '../../../app/AppInstance';
import { ServerEvent } from '../../../messages/communication/message/ServerEvent';
import { CancelRequest } from '../../../messages/player/message/CancelRequest';
import { ChallengeRequest } from '../../../messages/player/message/ChallengeRequest';
import { ChallengeResponse } from '../../../messages/player/message/ChallengeResponse';
import { FriendCancelRequest } from '../../../messages/player/message/FriendCancelRequest';
import { FriendChallengeRequest } from '../../../messages/player/message/FriendChallengeRequest';
import { FriendChallengeResponse } from '../../../messages/player/message/FriendChallengeResponse';
import { FriendsStatus } from '../../../messages/player/message/FriendsStatus';
import { FriendStatusUpdate } from '../../../messages/player/message/FriendStatusUpdate';
import { LoadFriends } from '../../../messages/player/message/LoadFriends';
import { PlayerInfo } from '../../../messages/player/message/PlayerInfo';
import { PlayWithFriend } from '../../../messages/player/message/PlayWithFriend';
import { createFriendModel, FriendModel, FriendMST } from '../../../models/friends/FriendModel';
import { FriendsModel } from '../../../models/friends/FriendsModel';
import { UserModel } from '../../../models/user/UserModel';
import { PlayerController } from '../PlayerController';
import { PlayerSubController } from '../PlayerSubController';
import { ActionMST } from '../../../models/ActionModel';
import { PlayerInfoBase } from '../../../messages/player/message/PlayerInfoBase';
import { createFriendChallengeModel, FriendChallengeModel } from '../../../models/dialogs/friendchallenge/FriendChallenge';

export class FriendsController extends PlayerSubController {
  private friends: FriendsModel;

  private friendRequested: boolean = false;

  private user: UserModel;

  constructor(controller: PlayerController, friends: FriendsModel, user: UserModel) {
    super(controller);
    this.friends = friends;
    this.user = user;
  }

  public onMessage(message: ServerEvent) {
    if (!APP.FacebookAPI.friendsEnabled()) return;

    switch (message.getClass()) {
      case FriendsStatus.ClassName:
        this.onFriendsStatus(message as FriendsStatus);
        break;
      case FriendStatusUpdate.ClassName:
        this.onFriendStatusUpdate(message as FriendStatusUpdate);
        break;
      case FriendChallengeRequest.ClassName:
        this.onFriendChallengeRequest(message as FriendChallengeRequest);
        break;
      case FriendCancelRequest.ClassName:
        this.onFriendCancelRequest(message as FriendCancelRequest);
        break;
      case FriendChallengeResponse.ClassName:
        this.onFriendChallengeResponse(message as FriendChallengeResponse);
        break;
      default:
        break;
    }
  }

  private onFriendsStatus(message: FriendsStatus) {
    if (!this.friendRequested || !message.friends) return;

    // enableControl(false)
    let info: PlayerInfo;

    let i: number;
    for (i = 0; i < message.friends.length; i++) {
      info = message.friends[i] as PlayerInfo;

      if (!this.exists(info.id)) {
        this.addFriendBean(info, i);
      } else {
        this.performFriendStatusUpdate(info, false);
      }
    }
    this.addOrUpdateCurrentUser();

    this.friends!.sortFriends();
    // friendsHolder.animateMoveToStart();
    // enableControl(true);
    this.friendRequested = false;
  }

  private onFriendStatusUpdate(message: FriendStatusUpdate) {
    if (!this.exists(message.id)) {
      this.addToFirstPosition(message);
    } else {
      this.performFriendStatusUpdate(message, true);
    }
  }

  private onFriendChallengeRequest(message: FriendChallengeRequest) {
    if (!this.exists(message.challengerId)) return;

    const challengerFbId = this.getFriendFbId(message.challengerId);
    const dialog: FriendChallengeModel = createFriendChallengeModel(challengerFbId);
    dialog.confirmAction.setAction(() => {
      this.acceptRequest(message.challengerId);
      dialog.hide();
    });
    dialog.declineAction.setAction(() => {
      this.declineRequest(message.challengerId);
      dialog.hide();
    });
    dialog.show();
    APP.SoundPlayer.playInComingCallSound(0.15);
  }

  private onFriendCancelRequest(message: FriendCancelRequest) {
    if (!this.exists(message.challengerId)) return;

    const friend: FriendModel | undefined = this.getFriendById(message.challengerId);
    if (friend) {
      friend.onFriendCancelRequest();
    }
  }

  private onFriendChallengeResponse(message: FriendChallengeResponse) {
    if (!this.exists(message.challengedPlayerId)) return;

    const friend: FriendModel | undefined = this.getFriendById(message.challengedPlayerId);
    if (friend) {
      friend.onFriendChallengeResponse();
      this.cancelAllRequests();
      // friendsHolder.setEnabled(false);
    }

    if (message.response === ChallengeResponse.Accept) {
      const playWithFriend: PlayWithFriend = new PlayWithFriend();
      playWithFriend.friendToPlayId = message.challengedPlayerId;
      this.sendMessageToServer(playWithFriend);
    }
  }

  private getFriendFbId(challengerId: number): string {
    let fbId = '';
    this.friends.friends.forEach((friend: FriendModel) => {
      if (friend.id === challengerId) fbId = friend.fbid;
    });

    return fbId;
  }

  public requestLoadFriends() {
    if (!APP.FacebookAPI.friendsEnabled()) {
      // friendsHolder.animateMoveToStart();
      return;
    }

    if (APP.FacebookAPI.getPlayingFriendsCount() <= 0) {
      APP.FacebookAPI.loadFriendsPlaying((success: boolean) => {
        if (success) this.onFriendsLoadedFromFB();
      });
    } else {
      this.onFriendsLoadedFromFB();
    }
  }

  private onFriendsLoadedFromFB() {
    this.friendRequested = true;
    const fbId: string | undefined = this.user.info.facebookId;
    if (fbId) {
      const loadFriends = new LoadFriends();

      loadFriends.facebookIds = APP.FacebookAPI.getFriends(fbId);
      this.sendMessageToServer(loadFriends);
    }
  }

  private addFriendBean(info: PlayerInfoBase | undefined, position: number) {
    if (!info) return;

    const friend = createFriendModel(info.id, info.name, info.facebookId ? info.facebookId : '', position, info.rankingPoints, info.rank, info.rankListTotalPoints ? info.rankListTotalPoints : 0, info.id === this.user.info.id, info.onlineStatus, false, false, info.maxPrevRank);
    friend.stats.setStatistics(info.stat);
    friend.setActions(
      () => {
        const challenge = new ChallengeRequest();
        challenge.invitedPlayerId = info.id;
        this.sendMessageToServer(challenge);
      },
      () => {
        const cancel = new CancelRequest();
        cancel.invitedPlayerId = info.id;
        this.sendMessageToServer(cancel);
      }
    );

    this.friends!.addFriend(friend);
  }

  private performFriendStatusUpdate(info: PlayerInfoBase | undefined, refresh: boolean) {
    if (!info) return;

    const friend: FriendModel | undefined = this.getFriendById(info.id);
    if (!friend) return;

    friend.update(info);
    if (refresh) {
      this.friends.sortFriends();
    }
  }

  private addOrUpdateCurrentUser() {
    if (!this.exists(this.user.info.id)) {
      this.addFriendBean(this.user.info, 0);
    } else {
      this.performFriendStatusUpdate(this.user.info, false);
    }
  }

  private addToFirstPosition(friendInfo: PlayerInfoBase) {
    this.addFriendBean(friendInfo, 0);
    this.switchToFirst(friendInfo.id);
    // friendsHolder.animateRefresh();
  }

  private getFriendIndex(id: number): number {
    let iterator = 0;
    let position = -1;
    this.friends!.friends.forEach(friend => {
      if (friend.id === id) {
        position = iterator;
      }
      iterator += 1;
    });

    return position;
  }

  private switchToFirst(id: number) {
    const position = this.getFriendIndex(id);
    if (position < 0) return;

    const friend: FriendModel = createFriendModel(
      this.friends!.friends![position].id,
      this.friends!.friends![position].name,
      this.friends!.friends![position].fbid,
      this.friends!.friends![position].title,
      this.friends!.friends![position].rating,
      this.friends!.friends![position].rank,
      this.friends!.friends![position].feather,
      this.friends!.friends![position].me,
      this.friends!.friends![position].onlineStatus,
      this.friends!.friends![position].challangeReceived,
      this.friends!.friends![position].challangeSent,
      this.friends!.friends![position].maxPrevRank
    );

    friend.stats = this.friends!.friends![position].stats;
    friend.setActions(
      () => {
        const challenge = new ChallengeRequest();
        challenge.invitedPlayerId = id;
        this.sendMessageToServer(challenge);
      },
      () => {
        const cancel = new CancelRequest();
        cancel.invitedPlayerId = id;
        this.sendMessageToServer(cancel);
      }
    );

    this.friends!.switchToFirst(friend, position);
  }

  public cancelAllRequests() {
    this.friends!.friends.forEach((friend: FriendModel) => {
      if (friend.challangeSent) {
        this.cancelRequest(friend);
      }
    });
  }

  public declineAllRequests() {
    this.friends!.friends.forEach((friend: FriendModel) => {
      if (friend.challangeReceived) {
        this.declineRequest(friend.id);
      }
    });
  }

  private exists(id: number) {
    let found = false;
    this.friends!.friends.forEach((friend: FriendModel) => {
      if (friend.getId() === id) found = true;
    });

    return found;
  }

  private getFriendById(id: number): FriendModel | undefined {
    let friendWithId: FriendModel | undefined;
    this.friends!.friends.forEach((friend: FriendModel) => {
      if (friend.getId() === id) friendWithId = friend;
    });

    return friendWithId;
  }

  private requestChallenge(friend: FriendModel) {
    const challengeRequest: ChallengeRequest = new ChallengeRequest();
    challengeRequest.invitedPlayerId = friend.id;
    this.sendMessageToServer(challengeRequest);
    friend.onChallengeFriendRequest();
  }

  private cancelRequest(friend: FriendModel) {
    if (friend.challangeReceived) {
      const cancelRequest: CancelRequest = new CancelRequest();
      cancelRequest.invitedPlayerId = friend.id;
      this.sendMessageToServer(cancelRequest);
    }
  }

  public acceptRequest(friendId: number) {
    const challengeResponse: ChallengeResponse = new ChallengeResponse();
    challengeResponse.challengerId = friendId;
    challengeResponse.response = ChallengeResponse.Accept;
    this.sendMessageToServer(challengeResponse);
  }

  public declineRequest(friendId: number) {
    const challengeResponse: ChallengeResponse = new ChallengeResponse();
    challengeResponse.challengerId = friendId;
    challengeResponse.response = ChallengeResponse.Decline;
    this.sendMessageToServer(challengeResponse);
  }
}
