import { ActionResolver } from 'common/src/app/ActionResolver';

export class WebLocalActionResolver implements ActionResolver {
  // private user: UserModel;

  constructor() {
    // if (user) {
  }

  specificPermissionsGranted() {}

  setFBUserAccessToken(fbUserAccessTokenstring: string) {}

  setGeo(userGeo: string) {}

  setRequestIds(requestIds: string) {}

  setPageSource(pageSource: string) {}

  showtab(name: string) {}

  openFriendInviteDialog() {}

  openStoreAndShowTokens() {}

  showUserShortCode(code: any) {}

  // FB Analitycs
  logUserProperty(userId: any, property: any, value: any) {}

  logEventWithProperty(event: any, key: any, value: any) {}

  logEvent(event: any) {}

  // Promotions
  showPromotion(name: any) {}

  showPartPromotion(name: any) {}

  enableDiscounts(text: any, factor: any) {}

  showForceMob() {}

  // Payment
  paymentCallbackJS(name: any) {}

  buyTokens(q: any, cluster: any, qmin: any, qmax: any, callback: Function) {}

  promoPaymentCallbackJS(data: any) {}

  promoTokens() {}

  // FB Friend requests
  sendRequest(toList: any, message: any) {}

  requestCallback(data: any) {}

  payCallback(data: any) {}

  setDefaultLevel(newDefaultLevel: any) {}

  setCurrentPlayer(position: any, name: any, stars: any, rank: any, rankName: any) {}

  requestSpecificPermissions(perms: any) {}
}
