import { ServerEvent } from '../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.player.message.FriendChallengeResponse';

export class FriendChallengeResponse extends ServerEvent {
  challengedPlayerId: number = 0;

  // fieldType: long
  response: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
