import { types } from 'mobx-state-tree';
import GroupAnimation from '../../animations/GroupAnimation';
import { APP } from '../../app/AppInstance';
import { GameController } from '../../controllers/base/GameController';
import { GameSequenceFinished } from '../../messages/player/message/GameSequenceFinished';
import { OpponentInfoUpdate } from '../../messages/player/message/OpponentInfoUpdate';
import { ActionMST } from '../ActionModel';
import { AnimationBuilderMST } from '../AnimationBuilder';
import { createStatisticsModel } from '../dialogs/statistics/StatisticsModel';
import { VisibleMST } from '../VisableModel';
import { PlayerInfoSemaphoreMST } from './PlayerInfoSemaphoreModel';

const ZEITNOT = 5;

export const NO_TURN = 0;
export const TURN_BLUE = 1;
export const TURN_RED = 2;

export const GameInfoMST = VisibleMST.named('SemaphoreMST')
  .props({
    timer: 0,
    status: '-',
    bluePlayer: PlayerInfoSemaphoreMST,
    redPlayer: PlayerInfoSemaphoreMST,
    myTeam: types.string,
    opponentTeam: types.string,
    interactionDisabled: false,
    blurBackground: false,
    statusMoveOutAnimation: types.optional(AnimationBuilderMST, {}),
    statusMoveInAnimation: types.optional(AnimationBuilderMST, {}),
    currentlyActive: true,
    playSound: true,
    semaphorePopUpAnimation: types.optional(AnimationBuilderMST, {}),
    semaphorePushBackAnimation: types.optional(AnimationBuilderMST, {}),
    isPlayingAsBlue: false,
    appealAction: ActionMST,
    timerShown: true,
    timerMax: 1,
    onTurn: NO_TURN
  })
  .views(self => ({
    getMe() {
      return self.isPlayingAsBlue ? self.bluePlayer : self.redPlayer;
    },
    getOpponent() {
      return self.isPlayingAsBlue ? self.redPlayer : self.bluePlayer;
    }
  }))
  .actions(self => ({
    init() {},

    disableInteraction(blurBackground: boolean = false) {
      self.interactionDisabled = true;
      self.blurBackground = blurBackground;
    },
    enableInteraction() {
      self.interactionDisabled = false;
      self.blurBackground = false;
    },
    setStatus(newStatus: string) {
      self.status = newStatus;
    },
    setMyTeam(t: string) {
      self.myTeam = t;
    },
    setOpponentTeam(t: string) {
      self.opponentTeam = t;
    },

    getNewStatusAnimator(newStatus: string): GroupAnimation {
      const sequence = APP.AnimationAPI.createSequence();
      sequence.add(self.statusMoveOutAnimation.build());
      const moveIn = self.statusMoveInAnimation.build();
      moveIn.addStartListener(() => {
        this.setStatus(newStatus);
      });
      sequence.add(moveIn);

      return sequence;
    },
    onTick() {
      const currentTime = self.timer;
      if (currentTime > 0) {
        self.timer = currentTime - 1;

        if (!self.playSound || !self.currentlyActive) return;

        if (self.timer === 0) {
          APP.SoundPlayer.playClockringSound(1);
        } else if (self.timer <= ZEITNOT && self.timer > 0) {
          APP.SoundPlayer.playZeitnotSound(1 - self.timer / ZEITNOT);
        }
      }
    },
    setTimer(time: number, playSound: boolean) {
      self.timer = time;
      self.playSound = playSound;
      self.timerMax = time;
    },
    updateTimer(time: number) {
      self.timer = time;
    },
    onGameSequenceFinished(message: GameSequenceFinished) {
      this.enableInteraction();
      if (message.equalScore) {
        if (message.winner) {
          self.bluePlayer.addPoints(1);
        } else {
          self.redPlayer.addPoints(1);
        }
      }
    },
    updateOpponent(message: OpponentInfoUpdate) {
      self.getOpponent().init(message.name, message.getShortName(), message.rank, message.facebookId ? message.facebookId : '', false, message.rankingPoints, message.rankListTotalPoints, message.stat, message.maxPrevRank);
    },
    setActive(active: boolean) {
      self.currentlyActive = active;
    },
    setPlayingFirst(isPlayingFirst: boolean) {
      self.isPlayingAsBlue = isPlayingFirst;
    },
    setAppealAction(action: Function) {
      self.appealAction.setAction(action);
    },
    hideTimer() {
      self.timerShown = false;
    },
    showTimer() {
      self.timerShown = true;
    },
    setTurn(turn: number) {
      self.onTurn = turn;
    }
  }))
  .actions(self => {
    let intervalId: NodeJS.Timeout | undefined;
    let timeoutId: number | undefined;

    function stopTimer(keepOpponentTimerAlive: boolean) {
      if (timeoutId) clearTimeout(timeoutId);
      if (intervalId && !keepOpponentTimerAlive) clearInterval(intervalId);
      self.setActive(false);
    }

    // function onTick() {
    //   const currentTime = self.timer;
    //   if (currentTime > 0) {
    //     self.updateTimer(currentTime - 1);

    //     if (!self.playSound || !self.currentlyActive) return;

    //     if (self.timer === 0) {
    //       APP.SoundPlayer().playSound(Sounds.clockring, 1);
    //     } else if (self.timer <= ZEITNOT && self.timer > 0) {
    //       APP.SoundPlayer().playSound(Sounds.zeitnot, 1 - self.timer / ZEITNOT);
    //     }
    //   } else if (intervalId) {
    //     clearInterval(intervalId);
    //   }
    // }

    function startTimer(callback: Function, timeout: number, activePlayer: number, playSound: boolean) {
      stopTimer(false);
      self.setTimer(timeout, playSound);
      timeoutId = setTimeout(callback, timeout * 1000);
      intervalId = setInterval(() => {
        self.onTick();
      }, 1000);

      if (activePlayer === GameController.ME || activePlayer === GameController.BOTH_PLAYERS) self.setActive(true);
      else self.setActive(false);

      if (activePlayer === GameController.ME) {
        self.setTurn(self.isPlayingAsBlue ? TURN_BLUE : TURN_RED);
      } else if (activePlayer === GameController.OPPONENT) {
        self.setTurn(self.isPlayingAsBlue ? TURN_RED : TURN_BLUE);
      } else {
        self.setTurn(NO_TURN);
      }
    }

    return { startTimer, stopTimer }; // , onTick };
  });

type GameInfoModelType = typeof GameInfoMST.Type;
export interface GameInfoModel extends GameInfoModelType {}

export const createGameInfoModel = () =>
  GameInfoMST.create({
    timer: 0,
    status: '',
    myTeam: '',
    opponentTeam: '',
    bluePlayer: { name: 'Player Blue', points: 0, rank: 0, hatId: -1, rating: 0, feathers: 0, stats: createStatisticsModel(), offline: false },
    redPlayer: { name: 'Player Red', points: 0, rank: 0, hatId: -1, rating: 0, feathers: 0, stats: createStatisticsModel(), offline: false },
    appealAction: {}
  });
