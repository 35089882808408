import { ServerEvent } from '../../communication/message/ServerEvent';
import { SpecialOffer } from './SpecialOffer';

const BINDING_NAME = 'rs.slagalica.player.message.AllOffers';

export class AllOffers extends ServerEvent {
  offers?: Array<SpecialOffer> = undefined;

  // fieldType: ArrayList
  defaultOffer: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
