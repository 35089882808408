import React, { Component, Fragment } from 'react';
import { Local } from 'common/src/lang/Local';

interface DailyRewardsCardProps {
  cardType: string;
  day: number;
  multiplier: string;
}

export default class DailyRewardsCard extends Component<DailyRewardsCardProps> {
  rewardDiv: HTMLDivElement | null = null;

  rewardBackgroundDiv: HTMLDivElement | null = null;

  rewardTokensDiv: HTMLDivElement | null = null;

  rewardStatusDiv: HTMLDivElement | null = null;

  rewardStatusFrameDiv: HTMLDivElement | null = null;

  render() {
    const { cardType, day, multiplier } = this.props;

    return (
      <div
        className={`daily-rewards-card daily-rewards-card--${cardType}`}
        ref={div => {
          this.rewardDiv = div;
        }}
      >
        <div
          className={`daily-rewards-card__top daily-rewards-card__top--${cardType}`}
          ref={div => {
            this.rewardBackgroundDiv = div;
          }}
        >
          <div className={`daily-rewards-card__tokens daily-rewards-card__tokens--day${day}`}>&nbsp;</div>
          {cardType === 'previous' && (
            <div className="daily-rewards-card__tokens-multiply">
              <i className="daily-rewards-card__icon-token" />
              {multiplier}
            </div>
          )}
          {(cardType === 'today' || cardType === 'next') && (
            <div
              className="daily-rewards-card__tokens-multiply 
          daily-rewards-card__tokens-multiply--today-or-next"
              ref={div => {
                this.rewardTokensDiv = div;
              }}
            >
              <i className="daily-rewards-card__icon-token" />
              {multiplier}
            </div>
          )}
          {cardType === 'previous' && <div className="daily-rewards-card__status daily-rewards-card__status--done" />}
          {cardType === 'today' && (
            <Fragment>
              <div
                className="daily-rewards-card__status-today"
                ref={div => {
                  this.rewardStatusDiv = div;
                }}
              />
              <div className="daily-rewards-card__status-today--frame-box">
                <div
                  className="daily-rewards-card__status-today--frame"
                  ref={div => {
                    this.rewardStatusFrameDiv = div;
                  }}
                />
              </div>
            </Fragment>
          )}
          {cardType === 'next' && <div className="daily-rewards-card__status-next">{Local.getString('daily_rew.dlg_day', day)}</div>}
        </div>
      </div>
    );
  }
}
