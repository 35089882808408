import { types } from 'mobx-state-tree';
import { VisibleMST } from '../../../VisableModel';
import { AnimationBuilderMST } from '../../../AnimationBuilder';

export const MySolutionMST = VisibleMST.named('MySolutionMST')
  .props({
    number: 0,
    solution: '',
    points: 0,
    pointsAnimation: types.optional(AnimationBuilderMST, {}),
    resultBlinkAnimation: types.optional(AnimationBuilderMST, {})
  })
  .actions(self => ({
    setPoints(points: number) {
      self.points = points;
    },
    setResultNumber(result: number) {
      self.number = result;
    },
    reset() {
      self.visible = true;
      self.number = 0;
      self.solution = '';
      self.points = 0;
    }
  }))
  .views(self => ({}));

type MySolutionType = typeof MySolutionMST.Type;
export interface MySolutionModel extends MySolutionType {}

export const createMySolutionModel = () =>
  MySolutionMST.create({
    number: 0,
    solution: '',
    points: 0,
    pointsAnimation: {}
  });
