import { MessageSerializable } from '../../MessageSerializable';

const BINDING_NAME = 'rs.slagalica.player.message.PromotionAndroid';

export class PromotionAndroid extends MessageSerializable {
  id: number = 0;

  // fieldType: int
  type: number = 0;

  // fieldType: int
  name?: string = undefined;

  // fieldType: String
  description?: string = undefined;

  // fieldType: String
  imgPath?: string = undefined;

  // fieldType: String
  vidPath?: string = undefined;

  // fieldType: String
  linkURL?: string = undefined;

  // fieldType: String
  clusterId: number = 0;

  // fieldType: int
  dateStarted?: any = undefined;

  // fieldType: Date
  dateFinished?: any = undefined;

  // fieldType: Date
  frequency: number = 0.0;

  // fieldType: double
  position: number = 0;

  // fieldType: int
  showOnce: boolean = false;

  // fieldType: boolean
  shown: boolean = false; // fieldType: boolean

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
