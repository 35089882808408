import { ServerEvent } from '../../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.player.commercialsurvey.message.CommercialSurvey';

export class CommercialSurvey extends ServerEvent {
  tokensForReward: number = 0;

  // fieldType: int
  surveyPath?: string = undefined;

  // fieldType: String
  expectedTime: number = 0;

  // fieldType: int
  additionalText?: string = undefined; // fieldType: String

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
