import { DisplayConfig } from 'common/src/app/display/DisplayConfig';
import { DIALOG, SCREEN, VIEW } from 'common/src/app/display/DisplayType';
import { DisplayModelKeys } from 'common/src/models/DisplayModelKeys';

export class WebDisplayConfiguartor extends DisplayConfig {
  constructor() {
    super();
    this.createDisplayConfigModel(DisplayModelKeys.Game, SCREEN);
    this.createDisplayConfigModel(DisplayModelKeys.Lobby, SCREEN);
    this.createDisplayConfigModel(DisplayModelKeys.Loader, SCREEN);
    this.createDisplayConfigModel(DisplayModelKeys.Logo, SCREEN);
    this.createDisplayConfigModel(DisplayModelKeys.DailyReward, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.TournamentResult, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.AchievemntWonDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.Inbox, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.Shop, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.ShopTokens, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.ServerInfo, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.Achievements, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.Statistics, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.SearchOpponent, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.CongratulationDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.FeatherBoosterDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.TokensInfoDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.FAQDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.AppealOpponentDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.RangListHelpDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.ReconnectDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.ConnectionLostDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.EqualScoreDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.ResumeDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.TournamentHelp, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.NewDayDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.Chat, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.HappyBirthdayDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.DeclineGameDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.SurveyDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.NewAppInfoDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.NewPaymentInfoDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.FriendChallengeDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.B2bLogin, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.VoucherDialog, VIEW);
    this.createDisplayConfigModel(DisplayModelKeys.SettingsDialog, VIEW);
    this.createDisplayConfigModel(DisplayModelKeys.Tournaments, VIEW);
    this.createDisplayConfigModel(DisplayModelKeys.B2bTournament, VIEW);
    this.createDisplayConfigModel(DisplayModelKeys.B2bCaptainDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.B2bNoCaptainDialog, DIALOG);
    this.createDisplayConfigModel(DisplayModelKeys.B2bMyTeamDialog, DIALOG);
  }
}
