import { types } from 'mobx-state-tree';
import { APP } from '../../app/AppInstance';
import { PlayerInfoBase } from '../../messages/player/message/PlayerInfoBase';
import { ActionMST } from '../ActionModel';
import { BaseMST } from '../BaseModel';
import { createStatisticsModel, StatisticsMST } from '../dialogs/statistics/StatisticsModel';

export const FriendMST = BaseMST.named('FriendMST')
  .props({
    id: 0,
    name: 'Friend',
    fbid: '0',
    title: 0,
    rating: 0,
    rank: 0,
    feather: 0,
    me: true,
    onlineStatus: PlayerInfoBase.Offline,
    challangeReceived: false,
    challangeSent: false,
    challengeAction: types.optional(ActionMST, {}),
    cancelAction: types.optional(ActionMST, {}),
    stats: StatisticsMST,
    maxPrevRank: 0
  })
  .actions(self => ({
    init(id: number, name: string, fbid: string, title: number, rating: number, rank: number, feather: number, me: boolean, onlineStatus: number, challangeReceived: boolean, challangeSent: boolean) {
      self.id = id;
      self.name = name;
      self.fbid = fbid;
      self.title = title;
      self.rating = rating;
      self.rank = rank;
      self.feather = feather;
      self.me = me;
      self.onlineStatus = onlineStatus;
      self.challangeReceived = challangeReceived;
      self.challangeSent = challangeSent;
    },
    setActions(challengeFunction: Function, cancelFunction: Function) {
      self.challengeAction.setAction(challengeFunction);
      self.cancelAction.setAction(cancelFunction);
    },
    update(info: PlayerInfoBase) {
      self.rating = info.rankingPoints;
      self.rank = info.rank;
      self.feather = info.rankListTotalPoints;
      self.onlineStatus = info.onlineStatus;
    },
    onFriendChallengeRequest() {
      self.challangeReceived = true;
    },
    onFriendCancelRequest() {
      self.challangeReceived = false;
    },
    onFriendChallengeResponse() {
      self.challangeSent = false;
    },
    onChallengeFriendRequest() {
      self.challangeSent = true;
      self.challengeAction.performAction();
    },
    onCancelFriendRequest() {
      self.challangeSent = false;
      self.cancelAction.performAction();
    }
  }))
  .views(self => ({
    getFirstName(): string {
      const firstName = self.name.split(' ')[0];
      return firstName.length > 10 ? `${firstName.substring(0, 7)}...` : firstName;
    },
    getProfileIsMe() {
      return self.me;
    },
    getPosition() {
      return self.title;
    },
    getId() {
      return self.id;
    },
    getRating() {
      return self.rating;
    }
  }));

type FriendModelType = typeof FriendMST.Type;
export interface FriendModel extends FriendModelType {}

export const createFriendModel = (id: number, name: string, fbid: string, title: number, rating: number, rank: number, feather: number, me: boolean, onlineStatus: number, challangeReceived: boolean, challangeSent: boolean, maxPrevRank: number) =>
  FriendMST.create({
    id,
    name,
    fbid,
    title,
    rating,
    rank,
    feather,
    me,
    onlineStatus,
    challangeReceived,
    challangeSent,
    stats: createStatisticsModel(),
    maxPrevRank
  });
