export const DEFAULT_VOL = 1;

export interface SoundPlayer {
  playSound(url: string, vol: number): void;

  playOneChanelledSound(url: string, vol: number): void;

  playIntroMusic(vol: number): void;

  playBackgroundMusic(): void;

  stopBackgroundMusic(): void;

  playCashboxSound(vol: number): void;

  playClockringSound(vol: number): void;

  playCorrectSound(vol: number): void;

  playMessageSound(vol: number): void;

  playZeitnotSound(vol: number): void;

  playWrongSound(vol: number): void;

  playWinningSound(vol: number): void;

  playLoosingSound(vol: number): void;

  playInComingCallSound(vol: number): void;

  playStartupGameSound(vol: number): void;

  playStartupGame2Sound(vol: number): void;

  playScaleCrashSound(vol: number): void;

  playScaleDownSound(vol: number): void;

  playAchShowSound(vol: number): void;

  playEffectSound(id: number, vol: number): void;

  playOneChanelledSoundEffect(id: number, vol: number): void;
}
