import { Instance, types } from 'mobx-state-tree';
import { B2bGame } from '../../messages/b2b/B2bGame';
import { B2bTeam } from '../../messages/b2b/B2bTeam';
import { BaseMST } from '../BaseModel';
import { B2bGameMST, createB2bGameModel } from './B2bGameModel';
import { B2bTeamMST, B2bTeamModel, createB2bTeamModel } from './B2bTeamModel';

export const B2bTournamentMST = BaseMST.named('B2bTournamentMST')
  .props({
    bannerUrl: types.string,
    active: types.number,
    startTime: types.Date,
    games: types.array(B2bGameMST),
    teams: types.array(B2bTeamMST)
  })
  .views(self => ({
    getTeam(teamId: string): B2bTeamModel {
      return self.teams.filter((b: B2bTeamModel) => b.id === teamId)[0];
    }
  }))
  .actions(self => ({
    setBannerUrl(s: string) {
      self.bannerUrl = s || '';
    },
    setActive(a: number) {
      self.active = a;
    },
    setStartTime(a: Date) {
      self.startTime = a;
    },

    setGames(games: Array<B2bTeam>) {
      self.games.clear();
      games.forEach((g: B2bGame) => {
        const b2bGameModel = createB2bGameModel();
        b2bGameModel.setGameStatus(g.gameStatus!);
        b2bGameModel.setRound(g.round!);
        b2bGameModel.setBlueTeam(g.blueTeamId!);
        b2bGameModel.setRedTeam(g.redTeamId!);
        b2bGameModel.setStartTime(g.startTime!);
        b2bGameModel.setBluePoints(g.bluePoints!);
        b2bGameModel.setRedPoints(g.redPoints!);
        self.games.push(b2bGameModel);
      });
    },

    setTeams(teams: Array<B2bTeam>, onLogoChanged: Function) {
      self.teams.clear();
      teams.forEach((t: B2bTeam) => {
        const b2bTeamModel = createB2bTeamModel();
        b2bTeamModel.setId(t.id!);
        b2bTeamModel.setName(t.name!);
        if (t.logo && t.logo !== '') b2bTeamModel.setLogo(t.logo!);
        b2bTeamModel.setTeamMembers(t.teamMembers!);
        b2bTeamModel.setRank(t.rank!);
        b2bTeamModel.setWins(t.wins!);
        b2bTeamModel.setDiff(t.diff!);
        b2bTeamModel.setOnLogoChanged(onLogoChanged);
        b2bTeamModel.setRoom(t.room!);
        b2bTeamModel.setChat(t.chat!);
        b2bTeamModel.setCompany(t.companyName!);
        self.teams.push(b2bTeamModel);
      });
    }
  }));

type B2bTournamentModelType = typeof B2bTournamentMST.Type;
export interface B2bTournamentModel extends Instance<typeof B2bTournamentMST> {}

export const createB2bTournamentModel = () =>
  B2bTournamentMST.create({
    bannerUrl: '',
    active: 0,
    startTime: 0,
    games: [],
    teams: []
  });
