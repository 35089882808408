import { types } from 'mobx-state-tree';
import { BaseMST } from './BaseModel';
import { APP } from '../app/AppInstance';

export const DisplayMST = BaseMST.named('DisplayMST')
  .props({
    key: types.string,
    visible: false
  })
  .views(self => ({
    get ref() {
      return self;
    },
    isVisible(): boolean {
      return self.visible;
    }
  }))
  .actions(self => {
    let onCloseCallback: Function;
    let onShowCallback: Function;

    // this methode is overriden from base class
    function setVisible(visible: boolean) {
      if (visible === self.isVisible()) return;
      if (visible) {
        self.visible = true;
        APP.DisplayManeger.onShow(self.ref as DisplayModel);
      } else {
        if (onCloseCallback) onCloseCallback();
        self.visible = false;
        APP.DisplayManeger.onHide(self.ref as DisplayModel);
      }
    }

    function hide() {
      setVisible(false);
    }

    function show(onClose: Function = () => {}) {
      onCloseCallback = onClose;
      setVisible(true);
      if (onShowCallback) onShowCallback();
    }

    function setOnClose(onClose: Function) {
      onCloseCallback = onClose;
    }

    function schedule(onClose: Function = () => {}, onShow: Function = () => {}) {
      onCloseCallback = onClose;
      onShowCallback = onShow;
      APP.DisplayManeger.onSchedule(self.ref as DisplayModel);
    }

    return {
      hide,
      show,
      schedule,
      setOnClose
    };
  });

type DisplayModelType = typeof DisplayMST.Type;
export interface DisplayModel extends DisplayModelType {}

export const createDisplayModel = (key: string) =>
  DisplayMST.create({
    key
  });
