import { APP } from '../../../app/AppInstance';
import { ServerEvent } from '../../../messages/communication/message/ServerEvent';
import { Item } from '../../../messages/player/store/Item';
import { ItemDesc } from '../../../messages/player/store/ItemDesc';
import { ActivateItem } from '../../../messages/player/store/message/ActivateItem';
import { BuyItem } from '../../../messages/player/store/message/BuyItem';
import { DeactivateItem } from '../../../messages/player/store/message/DeactivateItem';
import { PlaySound } from '../../../messages/player/store/message/PlaySound';
import { StorageContent } from '../../../messages/player/store/message/StorageContent';
import { StoreContent } from '../../../messages/player/store/message/StoreContent';
import { Offer } from '../../../messages/player/store/Offer';
import { ShopGroups } from '../../../models/dialogs/shop/ShopGroups';
import { ShopItemModel } from '../../../models/dialogs/shop/ShopItemModel';
import { ShopModel } from '../../../models/dialogs/shop/ShopModel';
import { ChatModel } from '../../../models/game/chat/ChatModel';
import { HatsModel } from '../../../models/game/HatsModel';
import { ToolbarModel } from '../../../models/toolbar/ToolbarModel';
import { UserModel } from '../../../models/user/UserModel';
import { PaymentTrackingAction } from '../../../tracking/actions/PaymentTrackingAction';
import { PlayerController } from '../PlayerController';
import { PlayerSubController } from '../PlayerSubController';
import { ShopTokensModel } from '../../../models/dialogs/shoptokens/ShopTokensModel';
import { TokensPriceList } from '../../../models/dialogs/shoptokens/TokensPriceList';
import { LobbyModel } from '../../../models/lobby/LobbyModel';
import { PlayerInfo } from '../../../messages/player/message/PlayerInfo';

const Rating = 2; // fieldType: int
const Tokens = 100; // fieldType: int
// const Emoticons = 1000; // fieldType: int
const Hats = 2000; // fieldType: int
const Sounds = 4000; // fieldType: int
const Gifts = 5000; // fieldType: int
const TextMessages = 6000; // fieldType: int
const Stickers = 7000; // fieldType: int
const Emoticons = 8000; // fieldType: int
export const BoostJokers = 101000; // fieldType: int

export const BoosterItem = 0; // fieldType: int
const TokenItem = 0; // fieldType: int
const RatingItem = 0; // fieldType: int

export class ShopConst {
  static get Rating() {
    return Rating;
  }

  static get Tokens() {
    return Tokens;
  }

  static get Emoticons() {
    return Emoticons;
  }

  static get Hats() {
    return Hats;
  }

  static get Sounds() {
    return Sounds;
  }

  static get Gifts() {
    return Gifts;
  }

  static get TextMessages() {
    return TextMessages;
  }

  static get Stickers() {
    return Stickers;
  }

  static get BoostJokers() {
    return BoostJokers;
  }

  static get BoosterItem() {
    return BoosterItem;
  }

  static get TokenItem() {
    return TokenItem;
  }

  static get RatingItem() {
    return RatingItem;
  }
}

export class StorageController extends PlayerSubController {
  private store: Map<number, Array<ItemDesc>> = new Map<number, Array<ItemDesc>>();

  private storage: Map<number, Array<Item>> = new Map<number, Array<Item>>();

  private shop: ShopModel;

  private shopTokens: ShopTokensModel | undefined = undefined;

  private user: UserModel;

  private chat: ChatModel;

  private hats: HatsModel;

  private toolbar: ToolbarModel;

  constructor(controller: PlayerController, shop: ShopModel, shopTokens: ShopTokensModel, user: UserModel, chat: ChatModel, hats: HatsModel, toolbar: ToolbarModel) {
    super(controller);
    this.shop = shop;
    this.shopTokens = shopTokens;
    this.user = user;
    this.chat = chat;
    this.hats = hats;
    this.toolbar = toolbar;
  }

  public onMessage(message: ServerEvent) {
    switch (message.getClass()) {
      case StoreContent.ClassName:
        this.onStoreContent(message as StoreContent);
        break;
      case StorageContent.ClassName:
        this.onStorageContent(message as StorageContent);
        break;
      case PlaySound.ClassName:
        this.onPlaySound(message as PlaySound);
        break;
      default:
        break;
    }
  }

  onPlaySound(event: PlaySound): void {
    // throw new Error('Method not implemented.');
  }

  onStorageContent(event: StorageContent): void {
    this.storage.clear();

    this.user.info.storage = event;

    event.storage.forEach((item: Item) => {
      let items: Array<Item> | undefined = this.storage.get(item.description!.groupId);

      if (items === undefined) {
        items = [];
        this.storage.set(item.description!.groupId, items);
      }
      items.push(item);

      if (item.description) {
        if (item.description.groupId === BoostJokers) {
          // recheck nothing to do
        } else if (item.description.groupId === Hats) {
          this.shop.setHatItemInUse(item.description.itemId);
        } else if (item.description.groupId === TextMessages || item.description.groupId === Emoticons || item.description.groupId === Sounds || item.description.groupId === Stickers) {
          this.shop.setChatItemInUse(item.description.groupId, item.description.itemId);
          this.chat.setChatItemAvailable(item.description.groupId, item.description.itemId);
        }
      }
    });

    this.chat.setChatItemAvailable(TextMessages, 0);
    this.chat.setShopAction(() => {
      this.shop.onGroupSelected(ShopGroups.CHAT_GROUP);
      this.shop.schedule();
    });

    this.hats.initHats(this.shop.getHatItems(), (hatId: number) => {
      const hatItems: Item[] | undefined = this.storage.get(ShopConst.Hats);
      if (hatItems) {
        hatItems.forEach((item: Item) => {
          if (item.description && item.description!.itemId === hatId) this.activate(item);
        });
      }
    });

    if (this.getCurrentHat() && this.getCurrentHat()!.description) this.hats.setMyHat(this.getCurrentHat()!.description!.itemId);
  }

  onStoreContent(event: StoreContent): void {
    this.store.clear();

    event.store.forEach((desc: ItemDesc) => {
      let items: Array<ItemDesc> | undefined = this.store.get(desc.groupId);

      if (items === undefined) {
        items = [];
        this.store.set(desc.groupId, items);
      }
      if (desc.available) items.push(desc);
    });

    // APP.ActionResolver.buyTokens
    this.shopTokens!.setTokenItems(
      TokensPriceList.getInstance().getOffers(APP.FacebookAPI.getUserCurrency()),
      Tokens,
      (offerId: number, groupId: number, itemId: number) => {
        this.buyTokens(offerId);
      },
      APP.FacebookAPI.getUserCurrency()
    );

    this.shop.setChatItems(this.getChatsItemsDesc(), (itemId: number, groupId: number, offerId: number) => this.onShopAction(itemId, groupId, offerId));
    this.shop.setHatItems(this.getHatItemsDesc(), (itemId: number, groupId: number, offerId: number) => this.onShopAction(itemId, groupId, offerId));
  }

  public getItemDescGroup(groupId: number): Array<ItemDesc> {
    let items = this.store.get(groupId);
    if (!items) items = [];
    return items;
  }

  public getItemGroup(groupId: number): Array<Item> {
    let items = this.storage.get(groupId);
    if (!items) items = [];
    return items;
  }

  public getItem(itemdesc: ItemDesc): Item | undefined {
    if (itemdesc === undefined) return undefined;

    return this.getItemById(itemdesc.groupId, itemdesc.itemId);
  }

  public hasEnough(itemdesc: ItemDesc, count: number = 1): boolean {
    const item: Item | undefined = this.getItemById(itemdesc.groupId, itemdesc.itemId);
    return item !== undefined && item.count >= count;
  }

  public isActivated(groupId: number, itemId: number): boolean {
    const desc: ItemDesc | undefined = this.getItemDesc(groupId, itemId);
    const item: Item | undefined = desc ? this.getItem(desc) : undefined;

    return item !== undefined && item.isActivated();
  }

  public isAvailable(groupId: number, itemId: number): boolean {
    const item: Item | undefined = this.getItemById(groupId, itemId);

    return item !== undefined && item.available();
  }

  public getItemDesc(groupId: number, itemId: number): ItemDesc | undefined {
    let itemDescs: Array<ItemDesc> = this.getItemDescGroup(groupId);

    itemDescs = itemDescs.filter((item: ItemDesc, index: number, items: Array<ItemDesc>) => item.groupId === groupId && item.itemId === itemId);

    return itemDescs.length > 0 ? itemDescs[0] : undefined;
  }

  public getItemById(groupId: number, itemId: number): Item | undefined {
    let items: Array<Item> = this.getItemGroup(groupId);

    items = items.filter((item: Item, index: number, its: Array<Item>) => item.description!.groupId === groupId && item.description!.itemId === itemId);

    return items.length > 0 ? items[0] : undefined;
  }

  public activate(item: Item): void {
    if (item.isActivated()) return;

    const activateItem = new ActivateItem();
    activateItem.itemId = item.description!.itemId;
    activateItem.groupId = item.description!.groupId;
    this.sendMessageToServer(activateItem);
  }

  public deactivate(item: Item): void {
    if (!item.isActivated()) return;

    const deactivateItem = new DeactivateItem();
    deactivateItem.itemId = item.description!.itemId;
    deactivateItem.groupId = item.description!.groupId;
    this.sendMessageToServer(deactivateItem);
  }

  public onShopAction(itemId: number, groupId: number, offerId: number): boolean {
    const itemDesc: ItemDesc | undefined = this.getItemDesc(groupId, itemId);

    if (!itemDesc) return false;

    if (!itemDesc.offers) return false;

    let selectedOffer: Offer | undefined;
    itemDesc.offers.forEach((offer: Offer) => {
      if (offer.id === offerId) {
        selectedOffer = offer;
      }
    });

    if (!selectedOffer) {
      if (itemDesc.groupId === Hats || itemDesc.groupId === Emoticons || itemDesc.groupId === Stickers || itemDesc.groupId === Sounds || itemDesc.groupId === TextMessages) {
        selectedOffer = new Offer();
        selectedOffer.id = -1;
        selectedOffer.count = 1;
        // selectedOffer.disc = 0;
        selectedOffer.cost = itemDesc.cost;
        // selectedOffer.currency = 'T';
        // selectedOffer.bestBuy = false;
      } else {
        return false;
      }
    }

    if (this.buy(itemDesc, selectedOffer, true)) return true;
    return false;
  }

  public buy(item: ItemDesc, anOffer: Offer | null, activateNow: boolean): boolean {
    const offer: Offer = anOffer == null ? Offer.getDefaultOffer(item.cost) : anOffer;

    if ((item.purchaseType === ItemDesc.GoldenOnlyPurchase && offer.cost > this.user.tokens) || (item.purchaseType === ItemDesc.SilverPriemptivePurchase && offer.cost > this.user.allTokens)) {
      this.shopTokens!.show();
      return false;
    }

    const buyItem = new BuyItem();
    buyItem.itemId = item.itemId;
    buyItem.groupId = item.groupId;
    buyItem.offerId = offer.id;
    buyItem.activateNow = activateNow;
    this.sendMessageToServer(buyItem);
    return true;
  }

  public getTokenItemDesc(): ItemDesc | undefined {
    return this.getItemDesc(ShopConst.Tokens, ShopConst.TokenItem);
  }

  public getHatItemsDesc(): Array<ItemDesc> {
    return this.getItemDescGroup(ShopConst.Hats);
  }

  public getChatsItemsDesc(): Array<ItemDesc> {
    return this.getItemDescGroup(ShopConst.TextMessages)
      .concat(this.getItemDescGroup(ShopConst.Emoticons))
      .concat(this.getItemDescGroup(ShopConst.Sounds))
      .concat(this.getItemDescGroup(ShopConst.Stickers));
  }

  public buyTokens(offerId: number) {
    // TODO cluster?

    const offers: Offer[] = TokensPriceList.getInstance().getOffers(APP.FacebookAPI.getUserCurrency());
    if (!offers || offers.length < offerId) {
      console.log('error offers');
      return; // TODO track error
    }

    let selectedItem: ShopItemModel | undefined;
    this.shopTokens!.tokenItems.forEach((item: ShopItemModel) => {
      if (item.offerId === offers[offerId].id) {
        selectedItem = item;
      }
    });
    if (selectedItem) selectedItem.setBuyInProgress(true);

    APP.ActionResolver.buyTokens(offers[offerId].count, 0, offers[offerId].count / 2, offers[offerId].count * 2, (status: string, transactionId: string) => {
      if (selectedItem) {
        selectedItem.setBuyInProgress(false);
      }

      if (status === 'completed') {
        if (selectedItem) {
          selectedItem!.itemAnimation.build().play();
          APP.Tracker.trackEvent(new PaymentTrackingAction('tokens'));
        }
        console.log(`Tokens purchased, trasaction id: ${transactionId}`);
      } else {
        console.log(`Token purchase failed, transaction id: ${transactionId}`);
      }
    });
  }

  private getCurrentHat(): Item | undefined {
    if (this.storage == null) return undefined;

    if (!this.storage.get(ShopConst.Hats)) return undefined;

    let currentItem: Item | undefined;
    this.storage.get(ShopConst.Hats)!.forEach((item: Item) => {
      if (item.isActivated()) currentItem = item;
    });

    return currentItem;
  }

  public updateOpponentsHats(opponentInfo: PlayerInfo) {
    const currentHatItem: Item | undefined = this.getCurrentActivatedItem(opponentInfo.storage, ShopConst.Hats);
    if (currentHatItem && currentHatItem.description) this.hats.setOpponentsHat(currentHatItem.description.itemId);
  }

  public getCurrentActivatedItem(storage: StorageContent | undefined, itemGroupId: number): Item | undefined {
    if (!storage || !storage.storage) return undefined;

    let fItem: Item | undefined;

    storage.storage.forEach((item: Item) => {
      if (item && item.description && item!.description.groupId === itemGroupId && item.isActivated()) fItem = item;
    });

    return fItem;
  }
}
