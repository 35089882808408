import { TrackingActionIF } from '../TrackingActionIF';

export class TrackingAction implements TrackingActionIF {
  private category: string = 'app';

  private name: string = 'click';

  private propertyValue: string = '';

  private propertyName: string = '';

  private desc: string = '';

  constructor(category: string, name: string, dsc: string = '') {
    this.category = category;
    this.name = name;
    this.desc = dsc;
  }

  public addProperty(name: string, value: string): TrackingAction {
    this.propertyValue = value;
    this.propertyName = name;
    return this;
  }

  public getActionName(): string {
    return this.name;
  }

  public getCategory(): string {
    return this.category;
  }

  public getDescription(): string {
    return this.desc;
  }

  public getPropertyValue(): string {
    return this.propertyValue!;
  }

  public getPropertyName(): string {
    return this.propertyName;
  }

  public logToFBAnalytics(): boolean {
    return true;
  }
}
