import React, { Component } from 'react';
import DialogContainer from './components/dialogs/DialogContainer';
import GameScreen from './components/game/GameScreen';
import LoaderScreen from './components/loader/LoaderScreen';
import LogoScreen from './components/loader/LogoScreen';
import LobbyScreen from './components/lobby/LobbyScreen';

export class RootComponent extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="container">
        {/* <TestToolbar /> */}
        <LogoScreen />
        <LoaderScreen />
        <GameScreen />
        <LobbyScreen />
        <DialogContainer />
      </div>
    );
  }
}

export default RootComponent;
