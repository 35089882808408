import { VisibleMST } from '../../../VisableModel';

export const TYPE_NONE = 0;
export const TYPE_YELLOW = 1;
export const TYPE_RED = 2;

export const ResultHitMST = VisibleMST.named('ResultHitMST')
  .props({
    type: TYPE_NONE
  })
  .actions(self => ({
    setCorrectPlace() {
      self.type = TYPE_RED;
    },
    setCorrectSign() {
      self.type = TYPE_YELLOW;
    },
    reset() {
      self.type = TYPE_NONE;
    }
  }))
  .views(self => ({}));

type ResultHitType = typeof ResultHitMST.Type;
export interface ResultHitModel extends ResultHitType {}

export const createResultHitNoneModel = () =>
  ResultHitMST.create({
    type: TYPE_NONE
  });

export const createResultHitRedModel = () =>
  ResultHitMST.create({
    type: TYPE_RED
  });

export const createResultHitYellowModel = () =>
  ResultHitMST.create({
    type: TYPE_YELLOW
  });
