import { ServerEvent } from '../../../communication/message/ServerEvent';
import { Achievement } from './Achievement';

const BINDING_NAME = 'rs.slagalica.player.achievements.message.AchievementInfo';

export class AchievementInfo extends ServerEvent {
  achievements?: Array<Achievement> = undefined; // fieldType: ArrayList

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
