import firebase from 'firebase/app';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyB9WbRX1t2oPPGPfEpQ8aydBfftFOjmkOg',
  authDomain: 'slagalica-konfigurator.firebaseapp.com',
  projectId: 'slagalica-konfigurator',
  storageBucket: 'slagalica-konfigurator.appspot.com',
  messagingSenderId: '84524808987',
  appId: '1:84524808987:web:36181268f46e24d0d231be',
  measurementId: 'G-BC1J9RBDYQ'
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };
