import { ServerEvent } from './ServerEvent';

const BINDING_NAME = 'rs.slagalica.communication.message.SignInCompleted';

const Successful = 0; // fieldType: int
const TooManyUsers = 1; // fieldType: int
const ServerProblem = 2; // fieldType: int
const ServerMaintaining = 3; // fieldType: int
const Banned = 4; // fieldType: int
const WrongTPId = 5; // fieldType: int
const AuthenticationFailed = 6; // fieldType: int
const BannedAsFalseAccount = 7; // fieldType: int
const BannedAsCheater = 8; // fieldType: int
const UnBannedAsCheater = 9; // fieldType: int
const UnsupportedClientVersion = 10; // fieldType: int
const SessionExpired = 11; // fieldType: int
const B2bWrongHashAdmin = 12; // fieldType: int
const B2bWrongHash = 13; // fieldType: int
const B2bRejectedTournamentOver = -1;
const B2bRejectedTournamentUnknown = -2;
const B2bRejectedNickExists = -3;
const B2bRejectedTournamentTeamUnknown = -4;
const B2bRejectedTournamentMaxNumberExceeded = -5;

export class SignInCompleted extends ServerEvent {
  status: number = 0;

  // fieldType: int
  banUntilDate?: any = undefined;

  // fieldType: Date
  storeUrl?: string = undefined; // fieldType: String

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get Successful() {
    return Successful;
  }

  static get TooManyUsers() {
    return TooManyUsers;
  }

  static get ServerProblem() {
    return ServerProblem;
  }

  static get ServerMaintaining() {
    return ServerMaintaining;
  }

  static get Banned() {
    return Banned;
  }

  static get WrongTPId() {
    return WrongTPId;
  }

  static get AuthenticationFailed() {
    return AuthenticationFailed;
  }

  static get BannedAsFalseAccount() {
    return BannedAsFalseAccount;
  }

  static get BannedAsCheater() {
    return BannedAsCheater;
  }

  static get UnBannedAsCheater() {
    return UnBannedAsCheater;
  }

  static get UnsupportedClientVersion() {
    return UnsupportedClientVersion;
  }

  static get B2bWrongHashAdmin() {
    return B2bWrongHashAdmin;
  }

  static get B2bWrongHash() {
    return B2bWrongHash;
  }

  static get B2bRejectedTournamentOver() {
    return B2bRejectedTournamentOver;
  }

  static get B2bRejectedTournamentUnknown() {
    return B2bRejectedTournamentUnknown;
  }

  static get B2bRejectedNickExists() {
    return B2bRejectedNickExists;
  }

  static get B2bRejectedTournamentTeamUnknown() {
    return B2bRejectedTournamentTeamUnknown;
  }

  static get B2bRejectedTournamentMaxNumberExceeded() {
    return B2bRejectedTournamentMaxNumberExceeded;
  }
}
