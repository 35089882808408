import { TimelineLite, TweenMax, Elastic, TweenLite, Ease } from 'gsap';
import GSAPAnimation from '../base/GSAPAnimation';
import { RemPixelRatio } from '../RemPixelRatio';

export class MoveIn extends GSAPAnimation {
  constructor(target: HTMLElement | null, duration: number, fromX: number, fromY: number, toX: number, toY: number, ease: Ease) {
    const timeline = new TimelineLite({ paused: true });

    if (target) {
      timeline.add(TweenLite.fromTo(target, 0.01, { opacity: 0 }, { opacity: 1 })).add(
        TweenLite.fromTo(
          target,
          duration,
          {
            x: fromX * RemPixelRatio.getRatio(),
            y: fromY * RemPixelRatio.getRatio(),
            opacity: 0.9
          },
          {
            x: toX * RemPixelRatio.getRatio(),
            y: toY * RemPixelRatio.getRatio(),
            opacity: 1,
            ease
          }
        )
      );
    }

    super(timeline);
  }
}
