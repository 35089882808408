import { CollaborationModel } from 'web/src/collaboration/model/CollaborationModel';
import GroupAnimation from '../../animations/GroupAnimation';
import { APP } from '../../app/AppInstance';
import { DEFAULT_VOL } from '../../app/sound/SoundPlayer';
import { Local } from '../../lang/Local';
import { ServerEvent } from '../../messages/communication/message/ServerEvent';
import { OpenedFieldContent } from '../../messages/games/association/message/OpenedFieldContent';
import { OpenField } from '../../messages/games/association/message/OpenField';
import { RequestOpenField } from '../../messages/games/association/message/RequestOpenField';
import { RequestSolution } from '../../messages/games/association/message/RequestSolution';
import { SimpleUpdateContent } from '../../messages/games/association/message/SimpleUpdateContent';
import { SolutionProvided } from '../../messages/games/association/message/SolutionProvided';
import { SolutionResult } from '../../messages/games/association/message/SolutionResult';
import { B2bTournamentModel } from '../../models/b2b/B2bTournamentModel';
import { B2bCaptainModel } from '../../models/dialogs/b2bgame/B2bCaptainModel';
import { B2bNoCaptainModel } from '../../models/dialogs/b2bgame/B2bNoCaptainModel';
import { ShopModel } from '../../models/dialogs/shop/ShopModel';
import { AssociationModel } from '../../models/game/content/association/AssociationModel';
import { GameRiddleContentModel } from '../../models/game/content/GameRiddleContent';
import { GameInfoModel } from '../../models/game/GameInfoModel';
import { GameModel } from '../../models/game/GameScreenModel';
import { BLUE_PLAYER, RED_PLAYER } from '../../models/game/PlayerType';
import { ToolbarModel } from '../../models/toolbar/ToolbarModel';
import { UserModel } from '../../models/user/UserModel';
import { ConnectionAdapter } from '../../sockets/ConnectionAdapter';
import { GameController } from '../base/GameController';

export class AssociationController extends GameController {
  NO_RESULT: number = -2;

  FINAL: number = -1;

  A_COLUMN: number = 0;

  B_COLUMN: number = 1;

  C_COLUMN: number = 2;

  D_COLUMN: number = 3;

  OPENED_BY_ENGINE: number = 3;

  private associationModel: AssociationModel;

  private requestedOpenField: boolean = false;

  constructor(
    amfSocket: ConnectionAdapter,
    associationModel: AssociationModel,
    gameInfo: GameInfoModel,
    gameScreenModel: GameModel,
    userModel: UserModel,
    shopModel: ShopModel,
    toolbarModel: ToolbarModel,
    b2bTournamentModel: B2bTournamentModel,
    b2bCaptainModel: B2bCaptainModel,
    b2bNoCaptainModel: B2bNoCaptainModel,
    collaborationModel: CollaborationModel
  ) {
    super(amfSocket, 'AssociationController', gameInfo, gameScreenModel, userModel, shopModel, toolbarModel, b2bTournamentModel, b2bCaptainModel, b2bNoCaptainModel, collaborationModel);
    this.associationModel = associationModel;
    this.gameInfo.disableInteraction(true);
    this.associationModel.setHintFieldAction((column: number, field: number) => {
      this.sendOpenFieldAction(column, field);
    });
    this.associationModel.setSkipAction(() => {
      this.gameInfo.stopTimer(false);
      this.associationModel.skipButton.setEnabled(false);
      this.sendSkipMessage();
      this.setNewStatus('');
      this.gameInfo.disableInteraction();
    });
    this.associationModel.setResultFields(
      (selectedColumn: number) => {
        this.associationModel.setSelectedColumn(selectedColumn);
        // this.associationModel.getResultField(selectedColumn).clearInputLetters();
      },
      (position: number, word: string) => {
        this.sendResultMessage(position, word);
      }
    );
  }

  protected getGameModel(): GameRiddleContentModel {
    return this.associationModel;
  }

  public onControllerMessage(message: ServerEvent) {
    super.onControllerMessage(message);

    switch (message.getClass()) {
      case RequestOpenField.ClassName:
        this.onRequestOpenField(message as RequestOpenField);
        break;
      case OpenedFieldContent.ClassName:
        this.onOpenedFieldContent(message as OpenedFieldContent);
        break;
      case SolutionResult.ClassName:
        this.onSolutionResult(message as SolutionResult);
        break;
      case RequestSolution.ClassName:
        this.onRequestSolution(message as RequestSolution);
        break;
      case SimpleUpdateContent.ClassName:
        this.onSimpleUpdateContent(message as SimpleUpdateContent);
        break;
      default:
        break;
    }
  }

  protected onStartGame() {
    // this.animationExecutor.schedule(this.gameScreenModel.getShowGameContentAnimation(this.associationModel));
    this.associationModel.resetModel();
  }

  protected onRequestOpenField(message: RequestOpenField) {
    this.hideCaptainDialog();
    this.gameInfo.stopTimer(false);

    if (this.isForMyTeam(message)) {
      this.requestedOpenField = true;

      this.setStatusSpectacle(
        this.isForMe(message) ? Local.getString('association.open') : Local.getString('association.open_captain'),
        this.calculateClientTimeout(message),
        () => {
          if (this.isForMe(message)) this.onOpenFieldTimeout();
        },
        GameController.ME
      );
      if (this.isForMe(message)) {
        this.gameInfo.enableInteraction();
        this.associationModel.skipButton.setEnabled(false);

        this.associationModel.setHintFieldsEnabled(true);
        this.associationModel.setResultFieldsEnabled(false);
      }
    } else {
      this.setStatusSpectacle(Local.getString('association.opp_move'), this.calculateClientTimeout(message), () => {}, GameController.OPPONENT);
      this.gameInfo.disableInteraction(true);
    }

    this.associationModel.unMarkResultFields();
  }

  protected onSolutionResult(message: SolutionResult) {
    this.gameInfo.stopTimer(false);

    const whoOpened: number = this.isForMyTeam(message) ? this.getMyPosition() : this.getOpponentPosition();

    if (message.position === this.NO_RESULT) return;

    if (message.result) {
      if (this.isForMyTeam(message)) APP.SoundPlayer.playCorrectSound(DEFAULT_VOL);

      this.animationExecutor.schedule(
        this.getOpenColumnAnimation(whoOpened, message.position, message.answer, message.column!.columnResult, message.column!.field1, message.column!.field2, message.column!.field3, message.column!.field4, this.gameInfo.bluePlayer.points, this.gameInfo.redPlayer.points, true)
      );
    } else {
      if (this.isForMyTeam(message)) APP.SoundPlayer.playWrongSound(DEFAULT_VOL);
      this.associationModel.markFieldAsEngineResolved(message.position, message.answer);
    }

    this.setNewStatus('');
  }

  protected onOpenedFieldContent(message: OpenedFieldContent) {
    this.associationModel.onFieldOpened(message);

    if (this.isForMyTeam(message)) {
      if (this.isForMe(message)) this.setNewStatus(Local.getString('association.enter'));
      else this.setNewStatus(Local.getString('association.enter_captain'));
    }

    if (this.isForMe(message) && !message.randomOpened) {
      this.gameInfo.enableInteraction();
      this.associationModel.skipButton.setEnabled(true);
      this.associationModel.setHintFieldsEnabled(false);
      this.associationModel.setResultFieldsEnabled(true);
    }
  }

  protected onRequestSolution(message: RequestSolution) {
    this.gameInfo.stopTimer(false);

    if (this.isForMyTeam(message)) {
      this.setStatusSpectacle(
        this.isForMe(message) ? Local.getString('association.enter') : Local.getString('association.enter_captain'),
        this.calculateClientTimeout(message),
        () => {
          if (this.isForMe(message)) this.onRequestSolutionTimeout();
        },
        AssociationController.ME
      );

      if (this.isForMe(message)) {
        this.gameInfo.enableInteraction();
        this.associationModel.skipButton.setEnabled(true);
        this.associationModel.setHintFieldsEnabled(false);
        this.associationModel.setResultFieldsEnabled(true);
      }
    } else {
      this.setStatusSpectacle(Local.getString('association.opp_move'), this.calculateClientTimeout(message), () => {}, GameController.OPPONENT);
      this.gameInfo.disableInteraction(true);
      this.associationModel.unMarkResultFields();
    }
  }

  protected onSimpleUpdateContent(message: SimpleUpdateContent) {
    this.gameInfo.stopTimer(false);
    let whoOpened: number = this.OPENED_BY_ENGINE;

    this.gameInfo.disableInteraction();
    this.gameInfo.setStatus(Local.getString('association.finished'));

    const animParallel = APP.AnimationAPI.createParallel();

    if (message.resultFinal!.opened) {
      if (this.isForMyTeam(message)) APP.SoundPlayer.playCorrectSound(DEFAULT_VOL);

      whoOpened = this.isForMyTeam(message) ? this.getMyPosition() : this.getOpponentPosition();

      this.associationModel.resultFinal.setPoints(5);

      const pointsSeq = APP.AnimationAPI.createSequence();
      pointsSeq.add(APP.AnimationAPI.createPause(0.4));
      if (whoOpened === BLUE_PLAYER) {
        pointsSeq.add(this.associationModel.resultFinal.pointsAnimation.build(true));
        this.associationModel.resultFinal.setBlueAnswered(message.resultFinal!.word);
      } else {
        pointsSeq.add(this.associationModel.resultFinal.pointsAnimation.build(false));
        this.associationModel.resultFinal.setRedAnswered(message.resultFinal!.word);
      }
      animParallel.add(pointsSeq);
    } else {
      this.associationModel.resultFinal.setEngineAnswered(message.resultFinal!.word);
    }

    const aPoints = this.associationModel.getPrecalculatedPoints(this.A_COLUMN);
    const bPoints = this.associationModel.getPrecalculatedPoints(this.B_COLUMN);
    const cPoints = this.associationModel.getPrecalculatedPoints(this.C_COLUMN);
    const dPoints = this.associationModel.getPrecalculatedPoints(this.D_COLUMN);
    const points = aPoints + bPoints + cPoints + dPoints + 5;

    animParallel.add(this.getOpenColumnAnimation(whoOpened, this.A_COLUMN, message.resultA!.word, message.resultA!.word, message.columnA![0].word, message.columnA![1].word, message.columnA![2].word, message.columnA![3].word, 0, 0, false));
    animParallel.add(this.getOpenColumnAnimation(whoOpened, this.B_COLUMN, message.resultB!.word, message.resultB!.word, message.columnB![0].word, message.columnB![1].word, message.columnB![2].word, message.columnB![3].word, 0, 0, false));
    animParallel.add(this.getOpenColumnAnimation(whoOpened, this.C_COLUMN, message.resultC!.word, message.resultC!.word, message.columnC![0].word, message.columnC![1].word, message.columnC![2].word, message.columnC![3].word, 0, 0, false));
    animParallel.add(this.getOpenColumnAnimation(whoOpened, this.D_COLUMN, message.resultD!.word, message.resultD!.word, message.columnD![0].word, message.columnD![1].word, message.columnD![2].word, message.columnD![3].word, 0, 0, false));

    this.animationExecutor.schedule(animParallel);

    if (whoOpened === BLUE_PLAYER || whoOpened === RED_PLAYER) {
      const seq = APP.AnimationAPI.createSequence();
      seq.add(APP.AnimationAPI.createPause(0.8));
      seq.add(whoOpened === BLUE_PLAYER ? this.gameInfo.bluePlayer.addPointsAnimation.build(points) : this.gameInfo.redPlayer.addPointsAnimation.build(points));
      seq.addEndListener(() => {
        (whoOpened === BLUE_PLAYER ? this.gameInfo.bluePlayer : this.gameInfo.redPlayer).setPoints((whoOpened === BLUE_PLAYER ? this.gameInfo.bluePlayer.points : this.gameInfo.redPlayer.points) + points);
      });
      this.animationExecutor.schedule(seq);
    }
  }

  protected getGameName(): string {
    return Local.getString('game.association');
  }

  private onOpenFieldTimeout() {
    this.gameInfo.disableInteraction();
    if (this.requestedOpenField) {
      this.requestedOpenField = false;
      const openField = new OpenField();
      openField.column = -1;
      openField.field = -1;
      this.sendMessage(openField);
    }

    this.sendSkipMessage();
    if (this.associationModel.onSolutionTimeoutAction) {
      this.associationModel.onSolutionTimeoutAction.performAction();
    }
  }

  private onRequestSolutionTimeout() {
    this.gameInfo.disableInteraction();
    this.sendSkipMessage();
    if (this.associationModel.onSolutionTimeoutAction) {
      this.associationModel.onSolutionTimeoutAction.performAction();
    }
  }

  private sendSkipMessage() {
    const solutionProvided = new SolutionProvided();
    solutionProvided.position = this.NO_RESULT;
    solutionProvided.input = '';
    this.sendMessage(solutionProvided);
  }

  private sendResultMessage(position: number, word: string): void {
    const solutionProvided = new SolutionProvided();
    solutionProvided.position = position;
    solutionProvided.input = word;
    this.gameInfo.stopTimer(false);

    this.associationModel.skipButton.setEnabled(false);
    this.sendMessage(solutionProvided);
    this.setNewStatus('');
    this.gameInfo.disableInteraction();
  }

  private sendOpenFieldAction(column: number, field: number): void {
    const openField: OpenField = new OpenField();
    openField.column = column;
    openField.field = field;
    this.requestedOpenField = false;
    // this.setNewStatus('');
    this.sendMessage(openField);
    this.gameInfo.disableInteraction();
  }

  private getOpenColumnAnimation(whoOpened: number, position: number, answer: string, res: string, f1: string, f2: string, f3: string, f4: string, initialBluePoints: number, initialRedPoints: number, instantlyAnimateSemaphore: boolean) {
    const seq: GroupAnimation = APP.AnimationAPI.createSequence();

    if (this.associationModel.getResultField(position).isResolved()) return seq;

    switch (whoOpened) {
      case BLUE_PLAYER: {
        this.associationModel.openColumnByBlue(position, answer, res, f1, f2, f3, f4);
        seq.add(this.associationModel.getResultField(position).openAnimation.build());
        seq.add(this.getShowPointsAnimator(position, true, initialBluePoints, instantlyAnimateSemaphore));
        break;
      }
      case RED_PLAYER: {
        this.associationModel.openColumnByRed(position, answer, res, f1, f2, f3, f4);
        seq.add(this.associationModel.getResultField(position).openAnimation.build());
        seq.add(this.getShowPointsAnimator(position, false, initialRedPoints, instantlyAnimateSemaphore));
        break;
      }
      case this.OPENED_BY_ENGINE: {
        this.associationModel.openColumnByEngine(position, answer, res, f1, f2, f3, f4);
        seq.add(this.associationModel.getResultField(position).openAnimation.build());
        break;
      }
      default:
        break;
    }

    return seq;
  }

  private getShowPointsAnimator(position: number, isBlue: boolean, initialPoints: number, instantlyAnimateSemaphore: boolean): GroupAnimation {
    const anim = APP.AnimationAPI.createParallel();
    anim.add(this.associationModel.getResultField(position).pointsAnimation.build(isBlue));

    if (instantlyAnimateSemaphore) {
      const seq = APP.AnimationAPI.createSequence();
      seq.add(APP.AnimationAPI.createPause(0.8));
      seq.add(isBlue ? this.gameInfo.bluePlayer.addPointsWithInital(initialPoints, this.associationModel.getResultField(position).points) : this.gameInfo.redPlayer.addPointsWithInital(initialPoints, this.associationModel.getResultField(position).points));
      anim.add(seq);
    }

    return anim;
  }

  protected finalizeController() {
    super.finalizeController();
  }
}
