import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { PulseLoader } from 'react-spinners';
import { Local } from 'common/src/lang/Local';
import { WordCheckModel } from 'common/src/models/game/content/puzzle/WordCheckModel';
import Images from 'web/src/resources/ImgResources';

@observer
export default class WordChecker extends Component<{ wordCheck?: WordCheckModel }> {
  render() {
    const t = (message: string) => Local.getString(message);
    // https://www.react-spinners.com/ is the library used for spinner
    return (
      <div className="puzzle__word-status">
        {this.props.wordCheck!.isCheckingShown() && (
          <div>
            {
              // className="puzzle__word-status-text--checking">}
            }
            <PulseLoader
              color="#fede04"
              sizeUnit="em"
              size={0.6}
              css="
                padding-right: 0.8rem;
                padding-top: 0.2rem;
                display: inline-block;
              "
            />
            {t('puzzle.checking_word')}
          </div>
        )}
        {this.props.wordCheck!.isCorrectShown() && (
          <div className="puzzle__word-status-text--correct">
            <img className="puzzle__word-status-image" src={Images.puzzleWordCorrect} />
            {t('puzzle.word_ok')}
          </div>
        )}
        {this.props.wordCheck!.isWrongShown() && (
          <div className="puzzle__word-status-text--incorrect">
            <img className="puzzle__word-status-image" src={Images.puzzleWordWrong} />
            {t('puzzle.word_wrong')}
          </div>
        )}
      </div>
    );
  }
}
