import { GameInfoModel } from 'common/src/models/game/GameInfoModel';
import { Elastic } from 'gsap';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { GSAPPause } from '../../animations/base/GSAPPause';
import { MoveIn } from '../../animations/samples/MoveIn';
import { MoveOut } from '../../animations/samples/MoveOut';

@inject('gameInfo')
@observer
export class GameStatus extends Component<{ gameInfo?: GameInfoModel }> {
  statusRef: HTMLDivElement | null = null;

  componentDidMount() {
    this.createAnimations();
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  getClassName() {
    if (this.props.gameInfo && this.props.gameInfo.currentlyActive) {
      if (this.props.gameInfo.timer <= 2) return 'game-status__clock game-status__clock--red';
      if (this.props.gameInfo.timer <= 5) return 'game-status__clock game-status__clock--orange';
    }

    return 'game-status__clock game-status__clock--blue';
  }

  createMoveOut = () => {
    if (this.statusRef === null) return new GSAPPause(0.05);

    return new MoveOut(this.statusRef, 0.3, 0, 0, 300, 0);
  };

  createMoveIn = () => {
    if (this.statusRef === null) return new GSAPPause(0.05);

    return new MoveIn(this.statusRef, 1.2, -300, 0, 0, 0, Elastic.easeOut);
  };

  createAnimations() {
    this.props.gameInfo!.statusMoveOutAnimation.set(this.createMoveOut);
    this.props.gameInfo!.statusMoveInAnimation.set(this.createMoveIn);
  }

  destroyAnimations() {
    if (this.props.gameInfo!.statusMoveOutAnimation) this.props.gameInfo!.statusMoveOutAnimation.dispose();

    if (this.props.gameInfo!.statusMoveInAnimation) this.props.gameInfo!.statusMoveInAnimation.dispose();
  }

  render() {
    const { gameInfo } = this.props;
    if (!gameInfo) return null;

    return (
      <div className="game-status">
        {gameInfo.timerShown && <div className={this.getClassName()}>{gameInfo.timer}</div>}
        <div style={{ overflow: 'hidden' }}>
          <div
            className="game-status__text"
            ref={div => {
              this.statusRef = div;
            }}
          >
            {gameInfo.status}
          </div>
        </div>
      </div>
    );
  }
}
