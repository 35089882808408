import { observer } from 'mobx-react';
import React, { Fragment } from 'react';

import { Local } from 'common/src/lang/Local';
import { Reward } from 'common/src/messages/player/reward/message/Reward';
import { TournamentResultItem } from 'common/src/models/dialogs/tournamentresult/TournamentResultItemModel';
import { TournamentResultModel } from 'common/src/models/dialogs/tournamentresult/TournamentResultModel';
import { REWARDS } from 'web/src/resources/RewardResources';
import { Countdown } from '../../../animations/samples/Countdown';
import { Fade } from '../../../animations/samples/Fade';
import { Rotate } from '../../../animations/samples/Rotate';
import { CurveTranslateTo } from '../../../animations/samples/Translate';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';
import { GSAPPause } from '../../../animations/base/GSAPPause';
import GSAPSequenceAnimation from '../../../animations/base/GSAPSequenceAnimator';
import GSAPParallelAnimation from '../../../animations/base/GSAPParallelAnimation';
import ProfilePicture from '../../game/ProfilePicture';

interface TournamentResultProps extends DialogProps {
  tournamentResult?: TournamentResultModel;
}

@observer
export default class TournamentResult extends BaseDialog<TournamentResultProps, {}> {
  rewardsImageDivs: Map<Number, HTMLImageElement | null> = new Map<Number, HTMLImageElement | null>();

  rewardsImageFlyDivs: Map<Number, HTMLImageElement | null> = new Map<Number, HTMLImageElement | null>();

  rewardsPointsDivs: Map<Number, HTMLDivElement | null> = new Map<Number, HTMLDivElement | null>();

  createAnimations() {
    const { tournamentResult } = this.props;
    tournamentResult!.claimRewardAnimation.set(() => {
      const sequenceGlobal: GSAPSequenceAnimation = new GSAPSequenceAnimation();
      const parallel: GSAPParallelAnimation = new GSAPParallelAnimation();
      const sequenceFly: GSAPSequenceAnimation = new GSAPSequenceAnimation();
      const parallelFade: GSAPParallelAnimation = new GSAPParallelAnimation();
      const globalParallel: GSAPParallelAnimation = new GSAPParallelAnimation();

      this.rewardsImageDivs.forEach(image => {
        globalParallel.add(new Rotate(image, 0.5, 3));
      });
      this.rewardsImageFlyDivs.forEach(image => {
        globalParallel.add(new Rotate(image, 0.5, 2));
      });

      sequenceFly.add(new GSAPPause(1));

      let positionDistance = 0;
      this.rewardsImageFlyDivs.forEach((image, i) => {
        if (i === Reward.TYPE_TOKENS) {
          parallel.add(new CurveTranslateTo(image, 0.7, -335 - positionDistance, -456, 2));
        } else if (i === Reward.TYPE_RATING_POINTS) {
          parallel.add(new CurveTranslateTo(image, 0.7, -78 - positionDistance, -456, 2));
        }
        positionDistance += 40;
      });
      tournamentResult!.rewards.forEach(reward => {
        const pointDiv = this.rewardsPointsDivs.get(reward.id) ? this.rewardsPointsDivs.get(reward.id) : null;
        if (pointDiv != null) parallel.add(new Countdown(pointDiv!, reward.count, 0));
      });

      this.rewardsImageFlyDivs.forEach(image => {
        parallelFade.add(new Fade(image, 0.1, 1, 0));
      });
      sequenceFly.add(parallel);

      globalParallel.add(sequenceFly);
      sequenceGlobal.add(globalParallel);

      sequenceGlobal.add(parallelFade);
      return sequenceGlobal;
    });
  }

  destroyAnimations() {
    this.props.tournamentResult!.claimRewardAnimation.dispose();
  }

  componentDidMount() {
    this.createAnimations();
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  isVisible(): boolean {
    if (this.props.tournamentResult) return this.props.tournamentResult.isVisible();
    return false;
  }

  getTron(tournamentResults: Array<TournamentResultItem>) {
    return tournamentResults.map(tournamentResult => (
      <Fragment>
        <div className={`tournament-result__trone--feather tournament-result__trone--feather--${tournamentResult.position}`}>{tournamentResult.feathers}</div>
        <div className={`tournament-result__trone--icon-feather tournament-result__trone--icon-feather--${tournamentResult.position}`} />
        <div className={`tournament-result__trone tournament-result__trone--profile-picture tournament-result__trone--profile-picture--${tournamentResult.position}`}>
          <ProfilePicture fbId={tournamentResult.fbid} css="tournament-result__trone--profile-image" size={75} />
          <div className="tournament-result__trone--profile-name">{tournamentResult.name}</div>
        </div>
        <div className={`tournament-result__trone--order tournament-result__trone--order--${tournamentResult.position}`}>{tournamentResult.position}.</div>
      </Fragment>
    ));
  }

  renderVisible() {
    const { tournamentResult } = this.props;

    return (
      <div className="tournament-result">
        <div className="tournament-result__inner">
          <DialogHeader onClose={() => tournamentResult!.hide()}>{Local.getString('league.prev_result')}</DialogHeader>
          <div className="tournament-result__description">{Local.getString('league.won_place', tournamentResult!.myPosition)}</div>
          <div className="tournament-result__trone">
            <div className="tournament-result__trone tournament-result__trone--color-feathers">{this.getTron(tournamentResult!.tournamentResult)}</div>
          </div>
          <div className="tournament-result__rewarded">
            <div className="tournament-result__rewarded--open-gift-box" />
            <div className="tournament-result__rewarded--award-content">
              {tournamentResult!.rewards.map(reward => (
                <div className="tournament-result__rewarded--award-icon-count">
                  <img
                    className="tournament-result__rewarded--award-tooltip-icon"
                    src={REWARDS.getImg(reward!.id)}
                    ref={img => {
                      this.rewardsImageDivs.set(reward!.id, img);
                    }}
                  />
                  <img
                    className="tournament-result__rewarded--award-tooltip-icon tournament-result__rewarded--award-tooltip-icon--fly"
                    src={REWARDS.getImg(reward!.id)}
                    ref={img => {
                      this.rewardsImageFlyDivs.set(reward.id, img);
                    }}
                  />

                  <div
                    className="tournament-result__rewarded--award-tooltip-count"
                    ref={div => {
                      this.rewardsPointsDivs.set(reward.id, div);
                    }}
                  >
                    {reward!.count}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button className={tournamentResult!.claimButton.action.enabled ? 'tournament-result__close' : 'tournament-result__close_disabled'} onClick={tournamentResult!.claimButton.action.performAction}>
            {tournamentResult!.claimButton.getText()}
          </button>
        </div>
      </div>
    );
  }
}
