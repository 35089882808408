import { PlayerAction } from '../../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.player.message.LoadFriends';

export class LoadFriends extends PlayerAction {
  facebookIds?: Array<string> = []; // fieldType: String[]

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
