import { types } from 'mobx-state-tree';
import { NotificationController } from '../../../controllers/player/subcontrollers/NotificationController';
import { News } from '../../../messages/player/News';
import { Notification } from '../../../messages/player/Notification';
import { ActionMST } from '../../ActionModel';
import { DisplayMST } from '../../DisplayModel';
import { createInboxMessageModel, InboxMessageModel, InboxMessageMST } from './InboxMessageModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const InboxMST = DisplayMST.named('InboxModel')
  .props({
    messages: types.array(InboxMessageMST),
    hasUnreadMessages: false,
    acceptAction: types.optional(ActionMST, {})
  })
  .views(self => ({}))
  .actions(self => ({
    init(notifications: Array<Notification>, allNews: Array<News>, previousLoginDate: Date) {
      self.messages.clear();
      notifications.forEach(notification => {
        const message: InboxMessageModel = createInboxMessageModel(NotificationController.getNotificationSourceText(notification), notification.fText ? notification.fText : '', notification.fStatus === Notification.STATUS_READ, notification.fLinkCaption, notification.fLinkURL);

        self.messages.push(message);

        if (notification.fStatus === Notification.STATUS_NOT_READ) self.hasUnreadMessages = true;
      });
      allNews.forEach(news => {
        const message: InboxMessageModel = createInboxMessageModel(NotificationController.getNewsSourceText(news), news.info ? news.info : '', news.shown, news.linkCaption ? news.linkCaption : '', news.linkURL ? news.linkURL : '');
        if (!message.read && previousLoginDate && news.creationDate && previousLoginDate <= news.creationDate) {
          self.messages.unshift(message);
          self.hasUnreadMessages = true;
        } else {
          self.messages.push(message);
          message.read = true;
        }
      });
      self.setOnClose(() => {
        self.hasUnreadMessages = false;
      });
    },
    setHasUnreadMessages(value: boolean) {
      self.hasUnreadMessages = value;
    },
    markAllAsRead() {
      self.messages.forEach((message: InboxMessageModel) => {
        message.read = true;
      });
    }
  }));

type InboxModelType = typeof InboxMST.Type;
export interface InboxModel extends InboxModelType {}
export const createInboxDialogModel = () =>
  InboxMST.create({
    key: DisplayModelKeys.Inbox,
    messages: [],
    hasUnreadMessages: false,
    acceptAction: {}
  });
