import { observer } from 'mobx-react';
import React from 'react';
import { PulseLoader } from 'react-spinners';
import { Local } from 'common/src/lang/Local';
import { DisplayModel } from 'common/src/models/DisplayModel';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';

interface ReconnectProps extends DialogProps {
  reconnectDialog?: DisplayModel;
}
@observer
export default class Reconnect extends BaseDialog<ReconnectProps, {}> {
  isVisible(): boolean {
    if (this.props.reconnectDialog) return this.props.reconnectDialog.isVisible();
    return false;
  }

  renderVisible() {
    const { reconnectDialog } = this.props;
    const t = (message: string) => Local.getString(message);
    return (
      <div className="reconnect">
        <div className="reconnect__inner">
          <DialogHeader onClose={() => {}}>{Local.getString('conn_lost_dlg.title')}</DialogHeader>
          <div className="reconnect__image" />

          <div className="reconnect__description">{Local.getString('conn_lost_dlg.msg')}</div>
          {/* <div className="reconnect__recconnect-progres-bar"> */}
          <PulseLoader
            color="#fede04"
            sizeUnit="em"
            size={1}
            css="
                padding-left: 22rem;
                padding-top: 2rem;
                display: inline-block;
              "
          />
        </div>
      </div>
    );
  }
}
