import { inject, observer } from 'mobx-react';
import React from 'react';
import { ShopModel } from 'common/src/models/dialogs/shop/ShopModel';
import { Local } from 'common/src/lang/Local';
import { UserModel } from 'common/src/models/user/UserModel';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';
import Tab from './Tab';
import TabHeader from './TabHeader';
import Tokens from './Tokens';

const TITLE = ['store.tokens', 'store.hats_name', 'store.chat_name'];
const DESC = ['store.tokens_desc', 'store.hats_desc', 'store.chat_desc'];

interface ShopProps extends DialogProps {
  user?: UserModel;
  shop?: ShopModel;
}
@inject('user')
@observer
export default class Shop extends BaseDialog<ShopProps, {}> {
  isVisible(): boolean {
    if (this.props.shop) return this.props.shop.isVisible();
    return false;
  }

  renderVisible() {
    const { shop, user } = this.props;

    return (
      <div className="shop">
        <div className="shop__inner">
          <DialogHeader onClose={() => shop!.hide()} leftChild={<Tokens shop={shop} tokens={user!.tokens} />}>
            <div className="shop__title" />
          </DialogHeader>
          <div className="shop__container">
            <TabHeader shop={shop} />
            <Tab title={Local.getString(TITLE[shop!.selectedGroup])} desc={Local.getString(DESC[shop!.selectedGroup])} shop={shop} />
          </div>
        </div>
      </div>
    );
  }
}
