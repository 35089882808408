export class DisplayModelKeys {
  static get Game() {
    return 'Game';
  }

  static get Lobby() {
    return 'Lobby';
  }

  static get Loader() {
    return 'Loader';
  }

  static get Logo() {
    return 'Logo';
  }

  static get DailyReward() {
    return 'DailyReward';
  }

  static get TournamentResult() {
    return 'TournamentResult';
  }

  static get AchievemntWonDialog() {
    return 'AchievemntWonDialog';
  }

  static get Inbox() {
    return 'Inbox';
  }

  static get Shop() {
    return 'Shop';
  }

  static get ShopTokens() {
    return 'ShopTokens';
  }

  static get ServerInfo() {
    return 'ServerInfo';
  }

  static get Achievements() {
    return 'Achievements';
  }

  static get Statistics() {
    return 'Statistics';
  }

  static get SearchOpponent() {
    return 'SearchOpponent';
  }

  static get CongratulationDialog() {
    return 'CongratulationDialog';
  }

  static get FeatherBoosterDialog() {
    return 'FeatherBoosterDialog';
  }

  static get TokensInfoDialog() {
    return 'TokensInfoDialog';
  }

  static get FAQDialog() {
    return 'FAQDialog';
  }

  static get AppealOpponentDialog() {
    return 'AppealOpponentDialog';
  }

  static get RangListHelpDialog() {
    return 'RangListHelpDialog';
  }

  static get ReconnectDialog() {
    return 'ReconnectDialog';
  }

  static get ConnectionLostDialog() {
    return 'ConnectionLostDialog';
  }

  static get EqualScoreDialog() {
    return 'EqualScoreDialog';
  }

  static get ResumeDialog() {
    return 'ResumeDialog';
  }

  static get TournamentHelp() {
    return 'TournamentHelp';
  }

  static get NewDayDialog() {
    return 'NewDayDialog';
  }

  static get Chat() {
    return 'Chat';
  }

  static get HappyBirthdayDialog() {
    return 'HappyBirthday';
  }

  static get DeclineGameDialog() {
    return 'DeclineGameDialog';
  }

  static get SurveyDialog() {
    return 'SurveyDialog';
  }

  static get NewAppInfoDialog() {
    return 'NewAppInfoDialog';
  }

  static get NewPaymentInfoDialog() {
    return 'NewPaymentInfoDialog';
  }

  static get FriendChallengeDialog() {
    return 'FriendChallengeDialog';
  }

  static get VoucherDialog() {
    return 'VoucherDialog';
  }

  static get SettingsDialog() {
    return 'SettingsDialog';
  }

  static get Tournaments() {
    return 'Tournaments';
  }

  static get ShopTokensMobile() {
    return 'ShopTokensMobile';
  }

  static get B2bLogin() {
    return 'B2bLogin';
  }

  static get B2bTournament() {
    return 'B2bTournament';
  }

  static get B2bCaptainDialog() {
    return 'B2bCaptainDialog';
  }

  static get B2bNoCaptainDialog() {
    return 'B2bNoCaptainDialog';
  }

  static get B2bMyTeamDialog() {
    return 'B2bMyTeamDialog';
  }

  static get Collaboration() {
    return 'Collaboration';
  }
}
