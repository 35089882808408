import { PlayerAction } from '../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.b2b.game.message.B2bCaptainResponse';

export class B2bCaptainResponse extends PlayerAction {
  claimed?: boolean;

  constructor(claimed: boolean) {
    super();
    this.claimed = claimed;
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
