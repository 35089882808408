import { PlayerAction } from './PlayerAction';

const BINDING_NAME = 'rs.slagalica.communication.message.SyncRequest';

export class SyncRequest extends PlayerAction {
  fromMessageId: number = 0; // fieldType: long

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
