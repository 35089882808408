import { inject, observer } from 'mobx-react';
import React from 'react';
import { DisplayModel } from 'common/src/models/DisplayModel';
import { Local } from 'common/src/lang/Local';
import Images from 'web/src/resources/ImgResources';
import { ResumeGameModel } from 'common/src/models/dialogs/resumegame/ResumeGameModel';
import DialogHeader from '../DialogHeader';
import { BaseDialog, DialogProps } from '../BaseDialog';

interface ResumeProps extends DialogProps {
  resumeDialog?: ResumeGameModel;
}
@observer
export default class Resume extends BaseDialog<ResumeProps, {}> {
  isVisible(): boolean {
    if (this.props.resumeDialog) return this.props.resumeDialog.isVisible();
    return false;
  }

  renderVisible() {
    const { resumeDialog } = this.props;

    return (
      <div className="resume">
        <div className="resume__inner">
          <DialogHeader onClose={() => resumeDialog!.hide()}>{Local.getString('resume.game_title')}</DialogHeader>
          <div className="resume__description">{Local.getString('resume.game_msg')}</div>
          <button className="resume__resume-game resume__resume-game__button" onClick={() => resumeDialog!.confirmAction.performAction()}>
            <img className="resume__resume-game--label" src={Images.resumeGameLabel} />
          </button>
        </div>
      </div>
    );
  }
}
