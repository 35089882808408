import { Local } from 'common/src/lang/Local';
import { AchievementsModel } from 'common/src/models/dialogs/achievements/AchievementsModel';
import { InboxModel } from 'common/src/models/dialogs/inbox/InboxModel';
import { ShopGroups } from 'common/src/models/dialogs/shop/ShopGroups';
import { ShopModel } from 'common/src/models/dialogs/shop/ShopModel';
import { ShopTokensModel } from 'common/src/models/dialogs/shoptokens/ShopTokensModel';
import { StatisticsModel } from 'common/src/models/dialogs/statistics/StatisticsModel';
import { VoucherModel } from 'common/src/models/dialogs/voucher/VoucherModel';
import { SettingsModel } from 'common/src/models/settings/SettingsModel';
import { ToolbarModel } from 'common/src/models/toolbar/ToolbarModel';
import { UserModel } from 'common/src/models/user/UserModel';
import { RANK_VALUES } from 'common/src/resources/RankValues';
import { Power0, TimelineMax, TweenLite } from 'gsap';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RANK } from 'web/src/resources/RankResources';
import GSAPAnimation from '../../animations/base/GSAPAnimation';
import GSAPParallelAnimation from '../../animations/base/GSAPParallelAnimation';
import GSAPSequenceAnimation from '../../animations/base/GSAPSequenceAnimator';
import { CountDownWithCallback } from '../../animations/samples/Countdown';
import { CountUp, CountUpWithCallback } from '../../animations/samples/CountUp';
import { Fade } from '../../animations/samples/Fade';
import { MoveIn } from '../../animations/samples/MoveIn';

interface ToolbarProps {
  toolbar?: ToolbarModel;
  user?: UserModel;
  shop?: ShopModel;
  inbox?: InboxModel;
  shopTokens?: ShopTokensModel;
  achievements?: AchievementsModel;
  statistics?: StatisticsModel;
  voucher?: VoucherModel;
  settings?: SettingsModel;
}
@inject('toolbar', 'user', 'shop', 'inbox', 'achievements', 'statistics', 'voucher', 'shopTokens', 'settings')
@observer
export default class Toolbar extends Component<ToolbarProps, {}> {
  navProgressDiv: HTMLDivElement | null = null;

  oldRankingDiv: HTMLDivElement | null = null;

  newRankingDiv: HTMLDivElement | null = null;

  currentNewTitleDiv: HTMLDivElement | null = null;

  currentRankingDiv: HTMLDivElement | null = null;

  nextRankingDiv: HTMLDivElement | null = null;

  tokenDiv: HTMLDivElement | null = null;

  silverTokenDiv: HTMLDivElement | null = null;

  boosterDiv: HTMLLIElement | null = null;

  toolbarDiv: HTMLDivElement | null = null;

  tokenLabel: HTMLDivElement | null = null;

  tokenSilverLabel: HTMLDivElement | null = null;

  boosterLabel: HTMLDivElement | null = null;

  @observable showSetting: boolean = false;

  componentDidMount() {
    this.createAnimations();
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  getToolTip(text: string, bootom: string) {
    return (
      <div className={`nav__tooltip--title${bootom}`}>
        <div className="nav__tooltip--title__text">{text}</div>
      </div>
    );
  }

  setProperItem(name: string, div: HTMLDivElement | null) {
    if (name === 'tokens') {
      this.tokenDiv = div;
    } else if (name === 'tokens-silver') {
      this.silverTokenDiv = div;
    }
  }

  setProperLabelItem(name: string, div: HTMLDivElement | null) {
    if (name === 'tokens') {
      this.tokenLabel = div;
    } else if (name === 'tokens-silver') {
      this.tokenSilverLabel = div;
    }
  }

  renderNavItem = (name: string, count: number) => {
    const { toolbar } = this.props;
    const t = (message: string) => Local.getString(message);
    if (!toolbar) {
      return null;
    }
    return (
      <div
        className={`nav__toolbar nav__toolbar--${name}`}
        onClick={() => {
          this.props.voucher!.reset();
          this.props.shopTokens!.schedule();
        }}
        ref={div => {
          this.setProperItem(name, div);
        }}
      >
        <div
          className={`nav__toolbar--count nav__toolbar--count--${name}`}
          ref={div => {
            this.setProperLabelItem(name, div);
          }}
        >
          {count}
        </div>

        <div className={`nav__tooltip nav__tooltip--${name}`}>
          <div className="nav__tooltip--title__text">{t(`toolbar.tooltip_${name}.description`)}</div>
        </div>
      </div>
    );
  };

  createLevelUpAnimation = () => {
    const currentRank = this.props.toolbar!.rank;
    const currentTarget = this.props.toolbar!.rankingPointsTarget;
    const seq = new GSAPSequenceAnimation();
    const parallel = new GSAPParallelAnimation();
    const movePosition = this.navProgressDiv ? this.navProgressDiv.offsetWidth * 0.78 : 250;
    if (this.oldRankingDiv && this.currentNewTitleDiv && this.newRankingDiv) {
      parallel.add(new Fade(this.oldRankingDiv, 1, 1, 0));
      parallel.add(new Fade(this.newRankingDiv, 1, 0, 1));
      parallel.addEndListener(() => {
        this.props.toolbar!.setRankingInfo(currentRank, currentTarget, RANK_VALUES.getLevelValue(currentRank + 2));
      });
      seq.add(parallel);
      seq.add(new MoveIn(this.newRankingDiv, 0.5, 0, 0, -movePosition, 0, Power0.easeInOut));
      seq.add(new GSAPAnimation(new TimelineMax({ paused: true }).add(new TimelineMax().to(this.newRankingDiv, 0, { 'z-index': 2 }))));
    }
    return seq;
  };

  createRatingAnimation = () => {
    this.props.toolbar!.ratingAnimation.set((args: any[]) => (args[0] + args[1] >= this.props.toolbar!.rankingPointsTarget ? this.createComplexRatingAnimation(args) : this.createBasicRatingAnimation(args)));
  };

  createTokenCountUpAnimation = () => {
    this.props.toolbar!.tokenCountUpAnimation.set((args: any[]) => {
      const seq = new GSAPSequenceAnimation();
      seq.add(this.props.toolbar!.tokenGlowAnimation.build());
      seq.add(new CountUp(this.tokenLabel, args[0], args[1]));
      return seq;
    });
  };

  createTokenGlow = () => {
    // new Glow(this.tokenDiv, 0.3, 3);
    if (this.tokenDiv != null) {
      const timeline = new TimelineMax({ paused: true, repeat: 1, repeatDelay: 0.16 });
      timeline.add(
        TweenLite.to(this.tokenDiv, 0.15, {
          css: {
            '-webkit-filter': 'drop-shadow(0px 0px 6px rgba(255,255,255,1)',
            filter: 'drop-shadow(0px 0px 6px rgba(255,255,255,1))'
          }
        })
      );
      timeline.add(
        TweenLite.to(this.tokenDiv, 0, {
          css: {
            '-webkit-filter': 'drop-shadow(0px 0px 0px rgba(255,255,255,0)',
            filter: 'drop-shadow(0px 0px 0px rgba(255,255,255,0))'
          }
        }),
        0.15
      );

      const animation = new GSAPAnimation(timeline);
      animation.addEndListener(() => {
        timeline.pause(0);
      });
      return animation;
    }
    return new GSAPSequenceAnimation();
  };

  createPushBackAnimation = () => {
    if (this.toolbarDiv != null) return new GSAPAnimation(new TimelineMax({ paused: true }).add(new TimelineMax().to(this.toolbarDiv, 0, { 'z-index': 0 })));

    return new GSAPSequenceAnimation();
  };

  createPopUpAnimation = () => {
    if (this.toolbarDiv != null) return new GSAPAnimation(new TimelineMax({ paused: true }).add(new TimelineMax().to(this.toolbarDiv, 0, { 'z-index': 210 })));

    return new GSAPSequenceAnimation();
  };

  createBasicRatingAnimation(args: any[]) {
    if (args[1] > 0)
      return new CountUpWithCallback(this.currentRankingDiv, args[0], args[1], (value: number) => {
        this.props.toolbar!.setRankingPoints(value);
      });
    return new CountDownWithCallback(this.currentRankingDiv, args[0], args[1], (value: number) => {
      this.props.toolbar!.setRankingPoints(value);
    });
  }

  createComplexRatingAnimation(args: any[]) {
    const currentRank = this.props.toolbar!.rank;
    const currentTarget = this.props.toolbar!.rankingPointsTarget;

    const seq: GSAPSequenceAnimation = new GSAPSequenceAnimation();
    seq.add(
      new CountUpWithCallback(this.currentRankingDiv, args[0], this.props.toolbar!.rankingPointsTarget - args[0], (value: number) => {
        this.props.toolbar!.setRankingPoints(value);
      })
    );
    seq.add(this.createLevelUpAnimation());
    const anim = new CountUpWithCallback(this.nextRankingDiv, currentTarget, args[0] + args[1] - currentTarget, (value: number) => {
      this.props.toolbar!.setRankingPoints(value);
    });
    seq.add(anim);

    return seq;
  }

  toggleSettingVisibility() {
    this.showSetting = !this.showSetting;
  }

  createAnimations() {
    this.props.toolbar!.levelUpAnimation.set(this.createLevelUpAnimation);
    this.createRatingAnimation();
    this.createTokenCountUpAnimation();
    this.props.toolbar!.tokenGlowAnimation.set(this.createTokenGlow);
    this.props.toolbar!.toolbarPushBackAnimation.set(this.createPushBackAnimation);
    this.props.toolbar!.toolbarPopUpAnimation.set(this.createPopUpAnimation);
  }

  destroyAnimations() {
    this.props.toolbar!.levelUpAnimation.dispose();
    this.props.toolbar!.ratingAnimation.dispose();
    this.props.toolbar!.tokenCountUpAnimation.dispose();
    this.props.toolbar!.tokenGlowAnimation.dispose();
    this.props.toolbar!.toolbarPushBackAnimation.dispose();
    this.props.toolbar!.toolbarPopUpAnimation.dispose();
  }

  render() {
    const { toolbar, user, shop, inbox, achievements, statistics, settings } = this.props;
    const t = (message: string) => Local.getString(message);
    if (!toolbar || !user || !toolbar.visible) {
      return null;
    }
    return (
      <div
        className="nav"
        ref={ulDiv => {
          this.toolbarDiv = ulDiv;
        }}
      >
        {this.renderNavItem('tokens', user!.tokens)}
        {this.renderNavItem('tokens-silver', toolbar!.tokensSilver)}
        <div
          className="nav__progress"
          ref={div => {
            this.navProgressDiv = div;
          }}
        >
          <div
            className="nav__progress__current-box"
            ref={div => {
              this.oldRankingDiv = div;
            }}
          >
            <div className="nav__progress__current_title">
              <img src={RANK.getSmallImg(toolbar!.rank)} className="nav__progress__current_title_image" />
              {this.getToolTip(t('toolbar.curr_rank'), '--icons')}
            </div>
            <div className="nav__progress__bar">
              <div className="nav__progress__star" />
              <div
                className="nav__progress__percentage"
                style={{
                  width: `${toolbar!.getProgress()}%`
                }}
              />

              <div className="nav__tooltip__progress-number">
                <div className="nav__ranking-points">
                  <div
                    ref={ref => {
                      this.currentRankingDiv = ref;
                    }}
                  >
                    {toolbar!.rankingPoints}
                  </div>

                  {this.getToolTip(t('toolbar.curr_rating'), '')}
                </div>
                <div className="nav__delimiter">/</div>
                <div className="nav__ranking-points-target">
                  {toolbar!.rankingPointsTarget}
                  {this.getToolTip(t('toolbar.next_rating'), '')}
                </div>
              </div>
            </div>
            <div className="nav__progress__separator" />
            <div className="nav__progress__next_title">
              <img src={RANK.getSmallImg(toolbar!.rank + 1)} className="nav__progress__next_title_image" />
              {this.getToolTip(t('toolbar.next_rank'), '--icons nav__tooltip__next-title')}
            </div>
          </div>
          <div
            className="nav__progress__next-box"
            ref={div => {
              this.newRankingDiv = div;
            }}
          >
            <div
              className="nav__progress__current_title nav__progress__current_title--next"
              ref={div => {
                this.currentNewTitleDiv = div;
              }}
            >
              <img src={RANK.getSmallImg(toolbar!.rank + 1)} className="nav__progress__next_title_image" />
              {this.getToolTip(t('toolbar.curr_rank'), ' nav__tooltip__next-box-current')}
            </div>

            <div className="nav__progress__bar">
              <div className="nav__progress__star" />
              <div
                className="nav__progress__percentage"
                style={{
                  width: `${toolbar!.getProgress()}%`
                }}
              />
              <div className="nav__tooltip__progress-number">
                <div className="nav__ranking-points">
                  <div
                    ref={ref => {
                      this.nextRankingDiv = ref;
                    }}
                  >
                    {toolbar!.rankingPoints}
                  </div>
                  {this.getToolTip(t('toolbar.curr_rating'), '')}
                </div>
                <div className="nav__delimiter">/</div>
                <div className="nav__ranking-points-target">
                  {toolbar!.rankingPointsTarget}
                  {this.getToolTip(t('toolbar.next_rating'), '')}
                </div>
              </div>
            </div>
            <div className="nav__progress__separator" />
            <div className="nav__progress__next_title">
              <img src={RANK.getSmallImg(toolbar!.rank + 2)} className="nav__progress__next_title_image nav__progress__next_title_image--next" />
              {this.getToolTip(t('toolbar.next_rank'), ' nav__tooltip__next-box-next')}
            </div>
          </div>
        </div>
        <div className="nav__toolbar-box">
          <div
            className={statistics && statistics.isVisible() ? 'nav__toolbar-menu nav__toolbar-menu--statistics--active' : 'nav__toolbar-menu nav__toolbar-menu--statistics'}
            onClick={() => {
              statistics!.schedule();
            }}
          >
            {this.getToolTip(t('toolbar.stat'), '--bootom')}
          </div>
          <div
            className={achievements && achievements.isVisible() ? 'nav__toolbar-menu nav__toolbar-menu--achievements--active' : 'nav__toolbar-menu nav__toolbar-menu--achievements'}
            onClick={() => {
              achievements!.schedule();
            }}
          >
            {this.getToolTip(t('toolbar.ach'), '--bootom')}
          </div>
          <div
            className={inbox && inbox.isVisible() ? 'nav__toolbar-menu nav__toolbar-menu--inbox--active' : 'nav__toolbar-menu nav__toolbar-menu--inbox'}
            onClick={() => {
              inbox!.schedule();
            }}
          >
            {this.getToolTip(t('toolbar.inbox'), '--bootom')}
          </div>
          <div
            className={shop && shop.isVisible() ? 'nav__toolbar-menu nav__toolbar-menu--shop--active' : 'nav__toolbar-menu nav__toolbar-menu--shop'}
            onClick={() => {
              shop!.onGroupSelected(ShopGroups.HAT_GROUP);
              shop!.schedule();
            }}
          >
            {this.getToolTip(t('toolbar.store'), '--bootom')}
          </div>
          <div className={`nav__toolbar-menu nav__toolbar-menu--settings ${this.showSetting ? 'nav__toolbar-menu--settings--active' : ''}`} onClick={() => this.toggleSettingVisibility()}>
            {!this.showSetting ? this.getToolTip(t('toolbar.settings'), '--bootom') : <div />}
          </div>
          <div className={`nav__toolbar-sound-container  ${this.showSetting ? 'nav__toolbar-sound-container--active' : ''}`}>
            <div className={`nav__toolbar-sound nav__toolbar-sound--${settings!.sounds ? 'on' : 'off'}`} onClick={settings!.toggleSoundsOptionVisibility}>
              {this.getToolTip(t(settings!.sounds ? 'toolbar.sounds_off' : 'toolbar.sounds_on'), '--bootom')}
            </div>
            <div className={`nav__toolbar-music nav__toolbar-music--${settings!.music ? 'on' : 'off'}`} onClick={settings!.toggleMusicOptionVisibility}>
              {this.getToolTip(t(settings!.music ? 'toolbar.music_off' : 'toolbar.music_on'), '--bootom')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
