import { types } from 'mobx-state-tree';
import { GameRiddleContentMST } from '../GameRiddleContent';
import { createPairsA, PairsFieldMST, createPairsB, PairsFieldModel } from './PairsFieldModel';

import { AnimationBuilderMST } from '../../../AnimationBuilder';

export const PairsMST = GameRiddleContentMST.named('PairsMST')
  .props({
    pairsA: types.array(PairsFieldMST),
    pairsB: types.array(PairsFieldMST),
    currentIndex: -1,
    question: '',
    tooltipAnimations: types.array(AnimationBuilderMST)
  })
  .actions(self => ({
    setActions(btnAction: Function) {
      for (let i = 0; i < 8; i++) {
        self.pairsA[i].action.setAction(() => {
          self.pairsA[i].setEnabled(false);
          btnAction(i, 0);
        });
      }
      for (let i = 0; i < 8; i++) {
        self.pairsB[i].action.setAction(() => {
          self.pairsB[i].setEnabled(false);
          btnAction(i, 1);
        });
      }
    },
    nextCurrentIndex() {
      self.currentIndex++;
    },
    setQuestion(question: string | undefined) {
      self.question = question || '';
    },
    setCurrentQuestion(index: number, aAnswer: string | undefined, bAnswer: string | undefined) {
      if (index >= 0) {
        self.currentIndex = index;
        self.pairsA[index].setPairField(aAnswer || '');
        self.pairsB[index].setPairField(bAnswer || '');
      }
    },
    getPairs(colNum: number) {
      return colNum === 0 ? self.pairsA : self.pairsB;
    },
    getWrongColIndex(correctColIndex: number) {
      return 1 - correctColIndex;
    },
    setCorrectAnswer(answerIndex: number, isBlue: boolean) {
      this.getPairs(answerIndex)[self.currentIndex].setAsAnswered(isBlue);
      this.getPairs(this.getWrongColIndex(answerIndex))[self.currentIndex].setAsPassed();
    },
    resetBothAnswers(correctAnswerIndex: number, numOfTries: number) {
      if (numOfTries >= 2) {
        this.getPairs(correctAnswerIndex)[self.currentIndex].setAsUnanswered();
        this.getPairs(this.getWrongColIndex(correctAnswerIndex))[self.currentIndex].setAsPassed();
      } else {
        this.getPairs(correctAnswerIndex)[self.currentIndex].setAsUnanswered();
        this.getPairs(this.getWrongColIndex(correctAnswerIndex))[self.currentIndex].setAsUnanswered();
      }
    },
    getPointsAnimation(correctAnswerIndex: number, pointsWon: number, blueOpened: boolean) {
      // return blueOpened ? this.getPairs(correctAnswerIndex)[self.currentIndex].bluePointsAnimation.build(blueOpened, correctAnswerIndex) : this.getPairs(correctAnswerIndex)[self.currentIndex].redPointsAnimation.build();
      return this.getPairs(correctAnswerIndex)[self.currentIndex].pointsAnimation.build(blueOpened, correctAnswerIndex === 0);
      // [blueOpened, correctAnswerIndex === 0]);
    },
    setDescriptions(descA: string | undefined, descB: string | undefined) {
      self.pairsA[self.currentIndex].setDescription(descA || '');
      self.pairsB[self.currentIndex].setDescription(descB || '');
    },
    resetModel() {
      // self.pairsA.clear();
      // self.pairsB.clear();
      // for (let i = 0; i < 8; i++) {
      //   self.pairsA.push(createPairsA());
      //   self.pairsA.push(createPairsB());
      // }
      self.pairsA.forEach((pairsField: PairsFieldModel) => {
        pairsField.reset();
      });
      self.pairsB.forEach((pairsField: PairsFieldModel) => {
        pairsField.reset();
      });
      self.currentIndex = 1;
    }
  }))
  .views(self => ({}));

type PairsModelType = typeof PairsMST.Type;
export interface PairsModel extends PairsModelType {}

const dummy = {
  name: 'Pairs',
  visible: false,
  pairsA: [createPairsA(), createPairsA(), createPairsA(), createPairsA(), createPairsA(), createPairsA(), createPairsA(), createPairsA()],
  pairsB: [createPairsB(), createPairsB(), createPairsB(), createPairsB(), createPairsB(), createPairsB(), createPairsB(), createPairsB()],
  question: '',
  currentIndex: 1,
  tooltipAnimations: [{}, {}, {}, {}, {}, {}, {}, {}]
};

export const createPairs = () => PairsMST.create(dummy);
