import { Reward } from 'common/src/messages/player/reward/message/Reward';
import Images from 'web/src/resources/ImgResources';

class RewradResources {
  private rewards: Array<string> = [];

  constructor() {
    this.rewards[Reward.TYPE_RATING_POINTS] = Images.starIcon;
    this.rewards[Reward.TYPE_TOKENS] = Images.tokenIcon;
  }

  public getImg(id: number): string {
    if (this.rewards[id] === undefined) return 'Unknown';
    return this.rewards[id];

    // const level =Local.getString('level.categort_1');
  }
}

export const REWARDS = new RewradResources();
