import Images from 'web/src/resources/ImgResources';

export class HatResources {
  private hats: Array<string> = [];

  private hatsPreview: Array<string> = [];

  constructor() {
    this.hats[0] = Images.hat1;
    this.hats[1] = Images.hat2;
    this.hats[2] = Images.hat3;
    this.hats[3] = Images.hat4;
    this.hats[4] = Images.hat5;
    this.hats[5] = Images.hat6;
    this.hats[6] = Images.hat7;
    this.hats[7] = Images.hat8;
    this.hats[8] = Images.hat9;
    this.hats[9] = Images.hat10;
    this.hats[10] = Images.hat11;
    this.hats[11] = Images.hat12;
    this.hats[12] = Images.hat13;
    this.hats[13] = Images.hat14;
    this.hats[14] = Images.hat15;
    this.hats[15] = Images.hat16;
    this.hats[16] = Images.hat17;
    this.hats[17] = Images.hat18;
    this.hats[18] = Images.hat19;
    this.hats[19] = Images.hat20;
    this.hats[20] = Images.hat21;
    this.hats[21] = Images.hat22;
    this.hats[22] = Images.hat23;
    this.hats[23] = Images.hat24;
    this.hats[24] = Images.hat25;

    this.hatsPreview[0] = Images.hatPreview0;
    this.hatsPreview[1] = Images.hatPreview1;
    this.hatsPreview[2] = Images.hatPreview2;
    this.hatsPreview[3] = Images.hatPreview3;
    this.hatsPreview[4] = Images.hatPreview4;
    this.hatsPreview[5] = Images.hatPreview5;
    this.hatsPreview[6] = Images.hatPreview6;
    this.hatsPreview[7] = Images.hatPreview7;
    this.hatsPreview[8] = Images.hatPreview8;
    this.hatsPreview[9] = Images.hatPreview9;
    this.hatsPreview[10] = Images.hatPreview10;
    this.hatsPreview[11] = Images.hatPreview11;
    this.hatsPreview[12] = Images.hatPreview12;
    this.hatsPreview[13] = Images.hatPreview13;
    this.hatsPreview[14] = Images.hatPreview14;
    this.hatsPreview[15] = Images.hatPreview15;
    this.hatsPreview[16] = Images.hatPreview16;
    this.hatsPreview[17] = Images.hatPreview17;
    this.hatsPreview[18] = Images.hatPreview18;
    this.hatsPreview[19] = Images.hatPreview19;
    this.hatsPreview[20] = Images.hatPreview20;
    this.hatsPreview[21] = Images.hatPreview21;
    this.hatsPreview[22] = Images.hatPreview22;
    this.hatsPreview[23] = Images.hatPreview23;
    this.hatsPreview[24] = Images.hatPreview24;
  }

  public getHatImg(id: number): string {
    return this.hats[id];
  }

  public getHatPreviewImg(id: number): string {
    return this.hatsPreview[id];
  }
}

export const HATS = new HatResources();
