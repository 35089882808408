import { types } from 'mobx-state-tree';
import BaseAnimation from '../../../animations/BaseAnimation';
import GroupAnimation from '../../../animations/GroupAnimation';
import { APP } from '../../../app/AppInstance';
import { Local } from '../../../lang/Local';
import { GameSequenceFinished } from '../../../messages/player/message/GameSequenceFinished';
import { ActionMST } from '../../ActionModel';
import { AnimationBuilderMST } from '../../AnimationBuilder';
import { GameContentMST } from './GameContentModel';
import { createResultGameModelType, ResultGameModelMST } from './ResultGameModel';

export const REMATCH_DEFAULT = 0;
export const REMATCH_WAITING = 1;
export const REMATCH_ACCEPT = 2;
export const REMATCH_DISABLED = 3;

export const ResultMST = GameContentMST.named('ResultMST')
  .props({
    winner: true,
    backToLobbyAction: ActionMST,
    rematchAction: ActionMST,
    dailyHighscore: 0,
    boosterApplied: false,
    feathers: 0,
    feathersBonus: 0,
    rating: 0,
    resultGames: types.array(ResultGameModelMST),
    wonFeathersAnimation: AnimationBuilderMST,
    boosterCountDownAnimation: AnimationBuilderMST,
    boosterFlyAnimation: AnimationBuilderMST,
    newHighscoreAnimation: AnimationBuilderMST,
    starsWonCountDownAnimation: AnimationBuilderMST,
    starFlyAnimation: AnimationBuilderMST,
    showBackToLobbyAnimation: AnimationBuilderMST,
    friendlyGame: false,
    myName: '',
    myGender: -1,
    opponentsName: '',
    opponentsGender: -1,
    boosterChange: 0.3,
    rematchState: REMATCH_DEFAULT,
    appealAction: ActionMST,
    completeResultAnimation: AnimationBuilderMST,
    cupMoveAnimation: AnimationBuilderMST,
    spectacleCupAnimation: AnimationBuilderMST,
    spectacleHighScoreAnimation: AnimationBuilderMST,
    spectacleBoosterAnimation: AnimationBuilderMST,
    spectacleStarAnimation: AnimationBuilderMST
  })
  .actions(self => ({
    init(message: GameSequenceFinished) {
      self.winner = message.isWinner;
      self.boosterChange = message.boosterChange;
      self.feathers = message.winPoints - message.ranklistPointsBonus;
      self.feathersBonus = message.ranklistPointsBonus;
      self.rating = message.winRankingPoints;
      self.dailyHighscore = message.dailyHighscore;
      self.friendlyGame = message.friendltMatch;
      self.boosterChange = message.boosterChange;
      if (message.gamesResults) {
        for (let i = 0; i < message.gamesResults.length; i++) {
          if (i > self.resultGames.length) return;

          self.resultGames[i].setPoints(message.gamesResults[i].bluePoints, message.gamesResults[i].redPoints);
        }
      }
      self.rematchState = REMATCH_DEFAULT;
    },
    setWinner(winner: boolean) {
      self.winner = winner;
    },
    setRating(rating: number) {
      self.rating = rating;
    },
    setFriendlyGame(friendlyGame: boolean) {
      self.friendlyGame = friendlyGame;
    },
    setBackToLobbyAction(action: Function) {
      self.backToLobbyAction.setAction(action);
    },
    setRematchAction(action: Function) {
      self.rematchAction.setAction(action);
    },
    setMyInfo(gender: number, name: string) {
      self.myName = name;
      self.myGender = gender;
    },
    setOpponentsInfo(gender: number, name: string) {
      self.opponentsName = name;
      self.opponentsGender = gender;
    },
    setRematchState(state: number) {
      self.rematchState = state;
    },
    setBoosterChange(change: number) {
      self.boosterChange = change;
    }
  }))
  .views(self => ({
    getWinnerTitle() {
      return self.winner ? 'won' : 'lost';
    },
    getBoosterAplplied() {
      return self.boosterApplied ? 'on' : 'off';
    },
    getWonAnimationBeforeStarFly() {
      const sequence: GroupAnimation = APP.AnimationAPI.createSequence();
      const animWonFeather: BaseAnimation = self.wonFeathersAnimation.build();
      const animNewHighscore: BaseAnimation = self.newHighscoreAnimation.build();
      sequence.add(animWonFeather);
      sequence.add(animNewHighscore);

      return sequence;
    },
    getStarFlyAnimation(): BaseAnimation {
      return self.starFlyAnimation.build();
    },
    getBackToLobbyAnimation(): BaseAnimation {
      return self.showBackToLobbyAnimation.build();
    },
    getLostAnimationBeforeStarFly() {
      return APP.AnimationAPI.createPause(0.1);
    }
    // getWonAnimation(): BaseAnimation {
    //   const sequence: GroupAnimation = APP.AnimationAPI.createSequence();
    //   const animWonFeather: BaseAnimation = self.wonFeathersAnimation.build();
    //   const animWonBooster: BaseAnimation = self.wonBoosterAnimation.build();
    //   const animNewHighscore: BaseAnimation = self.newHighscoreAnimation.build();
    //   const animBonusFeather: BaseAnimation = self.starsWonCountDownAnimation.build();
    //   const animStarFly: BaseAnimation = self.starFlyAnimation.build();
    //   const animShowBackToLobby: BaseAnimation = self.showBackToLobbyAnimation.build();

    //   sequence.add(animWonFeather);
    //   sequence.add(animWonBooster);
    //   sequence.add(animNewHighscore);
    //   sequence.add(animBonusFeather);
    //   sequence.add(animStarFly);
    //   sequence.add(animShowBackToLobby);

    //   return sequence;
    // },
    // getLostAnimation() {
    //   const sequence: GroupAnimation = APP.AnimationAPI.createSequence();
    //   const animBonusFeather: BaseAnimation = self.starsWonCountDownAnimation.build();
    //   const animStarFly: BaseAnimation = self.starFlyAnimation.build();
    //   const animShowBackToLobby: BaseAnimation = self.showBackToLobbyAnimation.build();
    //   sequence.add(animBonusFeather);
    //   sequence.add(animStarFly);
    //   sequence.add(animShowBackToLobby);
    //   return sequence;
    // }
  }));

type ResultModelType = typeof ResultMST.Type;
export interface ResultModel extends ResultModelType {}
export const createResultModel = () =>
  ResultMST.create({
    visible: false,
    name: 'Result',
    winner: true,
    backToLobbyAction: {},
    rematchAction: {},
    feathers: 30,
    boosterApplied: true,
    dailyHighscore: 0,
    rating: 0,
    feathersBonus: 22,
    resultGames: [
      createResultGameModelType(0, 0, Local.getString('game.puzzle')),
      createResultGameModelType(0, 0, Local.getString('game.my_number')),
      createResultGameModelType(0, 0, Local.getString('game.combination')),
      createResultGameModelType(0, 0, Local.getString('game.quiz')),
      createResultGameModelType(0, 0, Local.getString('game.pairs')),
      createResultGameModelType(0, 0, Local.getString('game.association'))
    ],
    wonFeathersAnimation: {},
    boosterCountDownAnimation: {},
    boosterFlyAnimation: {},
    newHighscoreAnimation: {},
    starsWonCountDownAnimation: {},
    starFlyAnimation: {},
    showBackToLobbyAnimation: {},
    friendlyGame: false,
    boosterChange: 0.3,
    appealAction: {},
    completeResultAnimation: {},
    cupMoveAnimation: {},
    spectacleCupAnimation: {},
    spectacleHighScoreAnimation: {},
    spectacleBoosterAnimation: {},
    spectacleStarAnimation: {}
  });
