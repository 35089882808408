import { PlayerAction } from '../../../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.player.store.message.ActivateItem';

export class ActivateItem extends PlayerAction {
  groupId: number = 0;

  // fieldType: int
  itemId: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
