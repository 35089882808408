import { inject, observer } from 'mobx-react';
import React from 'react';
import { Local } from 'common/src/lang/Local';
import { ResultModel } from 'common/src/models/game/content/ResultModel';
import { GameContent, GameContentProps } from '../../GameContent';
import { GameStatus } from '../../GameStatus';

const COMMENTS_RES_M = ['publish.loser_said_m1_m', 'publish.loser_said_m2_m', 'publish.loser_said_m3_m', 'publish.loser_said_m4_m', 'publish.loser_said_m5_m', 'publish.loser_said_m6_m', 'publish.loser_said_m7_m', 'publish.loser_said_m8_m', 'publish.loser_said_m9_m', 'publish.loser_said_m10_m'];

const COMMENTS_RES_F = ['publish.loser_said_m1_f', 'publish.loser_said_m2_f', 'publish.loser_said_m3_f', 'publish.loser_said_m4_f', 'publish.loser_said_m5_f', 'publish.loser_said_m6_f', 'publish.loser_said_m7_f', 'publish.loser_said_m8_f', 'publish.loser_said_m9_f', 'publish.loser_said_m10_f'];
interface FriendGameResultProps extends GameContentProps {
  result?: ResultModel;
}

@inject('result')
@observer
export default class FriendGameResult extends GameContent<FriendGameResultProps, {}> {
  isVisible(): boolean {
    if (this.props.result && this.props.result.friendlyGame) return this.props.result.isVisible();
    return false;
  }

  getRandomComment(): string {
    let gender = 0;
    if (this.props.result) {
      gender = this.props.result.winner ? this.props.result.opponentsGender : this.props.result.myGender;
    }

    const rand = Math.floor(Math.random() * COMMENTS_RES_M.length);
    return gender === 1 ? COMMENTS_RES_M[rand] : COMMENTS_RES_F[rand];
  }

  getDefeatedPlayersName(): string {
    if (!this.props.result) return '';

    return this.props.result.winner ? this.props.result.opponentsName : this.props.result.myName;
  }

  renderVisible(): React.ReactNode {
    const { result } = this.props;

    return (
      <div>
        <GameStatus />
        <div className="game-result">
          <div className={`game-result__cup ${result && result.winner ? 'game-result__cup--won' : 'game-result__cup--lost'}`} />
          <div className="game-result__center game-result__center--friendly">
            <div className="game-result__friend-box">
              <div className="game-result__friend-box--text">{Local.getString(this.getRandomComment(), this.getDefeatedPlayersName())}</div>
              <div className="game-result__friend-box--image" />
            </div>
          </div>
          <div className={`game-result__title game-result__title--${result && result.winner ? 'won' : 'lost'}`} />

          <button className="game-result__button game-result__button--friend" onClick={() => result!.backToLobbyAction.performAction()}>
            <div className="game-result__button--text" />
          </button>
        </div>
      </div>
    );
  }
}
