import { APP } from '../../../app/AppInstance';
import { Local } from '../../../lang/Local';
import { ServerEvent } from '../../../messages/communication/message/ServerEvent';
import { Achievement } from '../../../messages/player/achievements/message/Achievement';
import { AchievementInfo } from '../../../messages/player/achievements/message/AchievementInfo';
import { AchievementReward } from '../../../messages/player/achievements/message/AchievementReward';
import { AchievementRewardCollection } from '../../../messages/player/achievements/message/AchievementRewardCollection';
import { ApplyAchievementReward } from '../../../messages/player/achievements/message/ApplyAchievementReward';
import { GetAchievementReward } from '../../../messages/player/achievements/message/GetAchievementReward';
import { Cycle } from '../../../messages/player/history/message/Cycle';
import { LeagueHistory } from '../../../messages/player/history/message/LeagueHistory';
import { LeagueHistoryResponse } from '../../../messages/player/history/message/LeagueHistoryResponse';
import { Reward } from '../../../messages/player/reward/message/Reward';
import { AchievementItemModel, createAchievementItemModel } from '../../../models/dialogs/achievements/AchievementItemModel';
import { AchievementsModel } from '../../../models/dialogs/achievements/AchievementsModel';
import { createAchievementWonModel } from '../../../models/dialogs/achievements/AchievementWonModel';
import { ToolbarModel } from '../../../models/toolbar/ToolbarModel';
import { PlayerController } from '../PlayerController';
import { PlayerSubController } from '../PlayerSubController';
import GroupAnimation from '../../../animations/GroupAnimation';
import { AchievementTargetValuesCollection } from '../../../messages/player/achievements/message/AchievementTargetValuesCollection';
import { AchievementTargetValue } from '../../../messages/player/achievements/message/AchievementTargetValue';

export class AchievementController extends PlayerSubController {
  private achievementsModel: AchievementsModel;

  private toolbar: ToolbarModel;

  private position: number = 0;

  private newAchievementIds: Array<number> = [];

  private cycle: Cycle | undefined = undefined;

  private achievementInfo: AchievementInfo | undefined = undefined;

  constructor(controller: PlayerController, achievementsView: AchievementsModel, toolbar: ToolbarModel) {
    super(controller);
    this.achievementsModel = achievementsView;
    this.toolbar = toolbar;
    this.initAchievements();
  }

  public onMessage(message: ServerEvent) {
    switch (message.getClass()) {
      case AchievementInfo.ClassName:
        this.onAchievementInfo(message as AchievementInfo);
        break;
      case AchievementReward.ClassName:
        this.onAchievementReward(message as AchievementReward);
        break;
      case LeagueHistoryResponse.ClassName:
        this.onLeagueHistoryResponse(message as LeagueHistoryResponse);
        break;
      case AchievementRewardCollection.ClassName:
        this.onAchievementRewardCollection(message as AchievementRewardCollection);
        break;
      case AchievementTargetValuesCollection.ClassName:
        this.onAchievementTargetValuesCollection(message as AchievementTargetValuesCollection);
        break;
      default:
        break;
    }
  }

  onLeagueHistoryResponse(event: LeagueHistoryResponse): void {
    let currentLeagueHistory: LeagueHistory | undefined;

    if (event.leagueHistory) {
      event.leagueHistory!.forEach((leagueHistory: LeagueHistory) => {
        if (leagueHistory != null && leagueHistory.cycle != null) {
          if (!currentLeagueHistory || !currentLeagueHistory.cycle || leagueHistory.cycle.cycleId > currentLeagueHistory!.cycle!.cycleId) currentLeagueHistory = leagueHistory;
        }
      });

      if (currentLeagueHistory) {
        this.cycle = currentLeagueHistory.cycle;
        this.achievementsModel.setCycle(currentLeagueHistory.cycle);
        this.achievementsModel.setPositions(currentLeagueHistory.position1, currentLeagueHistory.position2, currentLeagueHistory.position3);
      }
    }
    this.updateAchievements();
  }

  onAchievementReward(event: AchievementReward): void {
    const achievementWonView = createAchievementWonModel();
    achievementWonView.init((event.achievementId % 10) * 10 + event.level, event.awards, this.position++, this.achievementsModel.getTargetValue(event.achievementId, event.level));
    achievementWonView.claimButton.setText(Local.getString('league.claim_rewards'));
    achievementWonView.setClaimAction(() => {
      achievementWonView.claimButton.setEnabled(false);
      achievementWonView.claimButton.setText(Local.getString('common.close'));
      achievementWonView.claimButton.action.setAction(() => {
        achievementWonView.hide();
      });

      const parallel: GroupAnimation = APP.AnimationAPI.createParallel();
      parallel.add(achievementWonView.claimRewardAnimation.build());

      const seqCount: GroupAnimation = APP.AnimationAPI.createSequence();
      const counter: GroupAnimation = APP.AnimationAPI.createParallel();
      event.awards!.forEach((reward: Reward) => {
        const anim = this.toolbar.getItemCountUpAnimation(reward.type, reward.count);
        if (anim) counter.add(anim);
      });
      seqCount.add(APP.AnimationAPI.createPause(1.7));
      seqCount.add(counter);
      parallel.add(seqCount);

      parallel.play();

      setTimeout(() => {
        achievementWonView.hide();
        this.applyReward(event.achievementId, event.level);
      }, 5000);
      achievementWonView.claimButton.setEnabled(true);
    });
    achievementWonView.show();
  }

  onAchievementInfo(event: AchievementInfo) {
    this.achievementInfo = event;
    this.updateAchievements();
    this.position = 0;
  }

  onAchievementRewardCollection(event: AchievementRewardCollection) {
    if (!event.rewards) return;

    event.rewards!.forEach((reward: AchievementReward) => {
      this.achievementsModel.setRewards(reward);
    });
  }

  onAchievementTargetValuesCollection(event: AchievementTargetValuesCollection) {
    if (!event.values) {
      return;
    }

    event.values!.forEach((targetValue: AchievementTargetValue) => {
      this.achievementsModel.setTargetValues(targetValue);
    });
  }

  public updateAchievements() {
    if (!this.cycle || !this.achievementInfo) return;

    this.achievementInfo.achievements!.forEach((ach: Achievement) => {
      if (ach.id >= this.cycle!.cycleId * 100 && ach.id <= this.cycle!.cycleId * 100 + 4) {
        this.achievementsModel.updateAchievement(ach);
      }
    });
  }

  public requestRewards(): void {
    if (!this.cycle || !this.achievementInfo) return;

    this.achievementInfo.achievements!.forEach((ach: Achievement) => {
      if (ach.id >= this.cycle!.cycleId * 100 && ach.id <= this.cycle!.cycleId * 100 + 4) {
        this.achievementsModel.updateAchievement(ach);
        if (ach.completed && !ach.awarded) {
          const customId = (ach.id % 10) * 10 + ach.level;
          if (!this.newAchievementIds.includes(customId)) {
            this.newAchievementIds.push(customId);
            this.sendAwardRequest(ach.id, ach.level);
          }
        }
      }
    });
  }

  public getNotOpenedCount(): number {
    let toOpen = 0;

    this.achievementsModel.achievements.forEach((achievement: AchievementItemModel) => {
      if (achievement.completed && !achievement.awarded) toOpen++;
    });
    return toOpen;
  }

  private initAchievements() {
    this.achievementsModel.clearAchievements();
    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < 5; j++) {
        this.achievementsModel.addAchievement(createAchievementItemModel(i, j));
      }
    }
  }

  public sendAwardRequest(id: number, level: number) {
    const getAchievementReward = new GetAchievementReward();
    getAchievementReward.achievementId = id;
    getAchievementReward.level = level;
    this.sendMessageToServer(getAchievementReward);
  }

  public applyReward(id: number, level: number) {
    const applyAchievementReward = new ApplyAchievementReward();
    applyAchievementReward.achievementId = id;
    applyAchievementReward.level = level;
    this.sendMessageToServer(applyAchievementReward);
  }
}
