import { TimelineLite, TweenLite, TimelineMax, Power2, Ease, Power1 } from 'gsap';
import GSAPAnimation from '../base/GSAPAnimation';

export class MoveOut extends GSAPAnimation {
  constructor(target: {} | null, duration: number, fromX: number, fromY: number, toX: number, toY: number, repeat: number = 0) {
    const timeline = new TimelineLite({ paused: true });

    if (target) {
      timeline.add(new TimelineMax({ repeat }).add(TweenLite.fromTo(target, duration, { x: fromX, y: fromY, opacity: 1 }, { x: toX, y: toY, opacity: 0, ease: Power2.easeIn })).add(TweenLite.fromTo(target, 0.01, { x: toX, y: toY, opacity: 0 }, { x: fromX, y: fromY, opacity: 0 })));
    }

    super(timeline);
  }
}
