import { TimelineLite, TweenMax, SteppedEase } from 'gsap';
import GSAPAnimation from '../base/GSAPAnimation';
import { RemPixelRatio } from '../RemPixelRatio';

export class BackgroundMove extends GSAPAnimation {
  constructor(target: {} | null, ToX: number) {
    const timeline = new TimelineLite({ paused: true });

    if (target) {
      timeline.add(
        TweenMax.to(target, 0.25, {
          x: ToX * RemPixelRatio.getRatio(),
          ease: new SteppedEase(11)
        })
      );
    }

    super(timeline);
  }
}
