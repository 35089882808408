import { Instance, types } from 'mobx-state-tree';
import { GameContentMST } from '../../game/content/GameContentModel';

export const B2bNoCaptainMST = GameContentMST.named('B2bNoCaptainMST')
  .props({
    gameName: types.string
  })
  .views(self => ({
  }))
  .actions(self => ({
    setGameName(d: string) {
      self.gameName = d;
    }
  }));

export interface B2bNoCaptainModel extends Instance<typeof B2bNoCaptainMST> {}

export const createB2bNoCaptainModel = () =>
  B2bNoCaptainMST.create({
    gameName: '',
    visible: false,
    name: 'NoCaptain'
  });
