import { AchievementsModel } from 'common/src/models/dialogs/achievements/AchievementsModel';
import { AchievementWonModel } from 'common/src/models/dialogs/achievements/AchievementWonModel';
import { ActiveDialogsModel } from 'common/src/models/dialogs/ActiveDialogsModel';
import { AppealOpponentModel } from 'common/src/models/dialogs/appealopponent/AppealOpponentModel';
import { DailyRewardsModel } from 'common/src/models/dialogs/dailyreward/DailyRewardsModel';
import { DeclineGameModel } from 'common/src/models/dialogs/declinegame/DeclineGameModel';
import { FAQModel } from 'common/src/models/dialogs/faq/FAQModel';
import { FeatherBoosterModel } from 'common/src/models/dialogs/featherbooster/FeatherBoosterModel';
import { FriendChallengeModel } from 'common/src/models/dialogs/friendchallenge/FriendChallenge';
import { HappyBirthdayModel } from 'common/src/models/dialogs/happybirthday/HappyBirthday';
import { InboxModel } from 'common/src/models/dialogs/inbox/InboxModel';
import { ImportantInfoModel } from 'common/src/models/dialogs/info/ImportantInfoModel';
import { ServerInfoModel } from 'common/src/models/dialogs/info/ServerInfoModel';
import { B2bLoginModel } from 'common/src/models/dialogs/login/B2bLoginModel';
import { ResumeGameModel } from 'common/src/models/dialogs/resumegame/ResumeGameModel';
import { SearchOpponentModel } from 'common/src/models/dialogs/searchopponent/SearchOpponentModel';
import { ShopModel } from 'common/src/models/dialogs/shop/ShopModel';
import { ShopTokensModel } from 'common/src/models/dialogs/shoptokens/ShopTokensModel';
import { StatisticsModel } from 'common/src/models/dialogs/statistics/StatisticsModel';
import { SurveyDialogModel } from 'common/src/models/dialogs/survey/SurveyDialogModel';
import { TournamentResultModel } from 'common/src/models/dialogs/tournamentresult/TournamentResultModel';
import { DisplayModelKeys } from 'common/src/models/DisplayModelKeys';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import Achievements from './achievements/Achievements';
import WonAchievement from './achievements/WonAchievement';
import B2bLoginDialog from './b2b/B2bLoginDialog';
import DailyRewards from './dailyreward/DailyRewards';
import FriendsChallengeDialog from './friends/FriendsChallengeDialog';
import Inbox from './inbox/Inbox';
import AppealOpponent from './info/AppealOpponent';
import Congratulation from './info/Congratulation';
import ConnectionLost from './info/ConnectionLost';
import EqualScore from './info/EqualScore';
import FAQ from './info/FAQ';
import FeatherBooster from './info/FeatherBooster';
import HappyBirthday from './info/HappyBirthday';
import NewAppInfoDialog from './info/NewAppInfoDialog';
import NewDay from './info/NewDay';
import NewPaymentInfoDialog from './info/NewPaymentInfoDialog';
import RangListHelp from './info/RangListHelp';
import Reconnect from './info/Reconnect';
import Resume from './info/Resume';
import ServerInfo from './info/ServerInfo';
import SurveyDialog from './info/SurveyDialog';
import TokensInfo from './info/TokensInfo';
import DeclineGameDialog from './search-opponent/DeclineGameDialog';
import SearchOpponent from './search-opponent/SearchOpponent';
import Shop from './shop/Shop';
import ShopNew from './shoptokens/ShopTokens';
import Statistics from './statistics/Statistics';
import TournamentResult from './tournament/TournamentResult';
import WeeklyTournamentHelp from './tournament/WeeklyTournamentHelp';
import { consoleLog } from '../../util/utils';

interface DialogContainerProps {
  activeDialogs?: ActiveDialogsModel;
}

@inject('activeDialogs')
@observer
export default class DialogContainer extends Component<DialogContainerProps> {
  render() {
    const { activeDialogs } = this.props;

    if (!activeDialogs) return null;

    const dialogs = activeDialogs.getDialogs();

    return (
      <div>
        {dialogs.map(dialog => {
          consoleLog(`dialog to show: ${dialog.key}`);
          if (dialog.key === DisplayModelKeys.DailyReward) {
            return <DailyRewards key={dialog.key} dailyRewards={dialog as DailyRewardsModel} />;
          }
          if (dialog.key === DisplayModelKeys.ServerInfo) {
            return <ServerInfo key={dialog.key} serverInfo={dialog as ServerInfoModel} />;
          }
          if (dialog.key === DisplayModelKeys.SearchOpponent) {
            return <SearchOpponent key={dialog.key} searchOpponent={dialog as SearchOpponentModel} />;
          }
          if (dialog.key === DisplayModelKeys.Achievements) {
            return <Achievements key={dialog.key} achievements={dialog as AchievementsModel} />;
          }
          if (dialog.key === DisplayModelKeys.Statistics) {
            return <Statistics key={dialog.key} statistics={dialog as StatisticsModel} />;
          }
          if (dialog.key === DisplayModelKeys.Shop) {
            return <Shop key={dialog.key} shop={dialog as ShopModel} />;
          }
          if (dialog.key === DisplayModelKeys.ShopTokens) {
            return <ShopNew key={dialog.key} shop={dialog as ShopTokensModel} />;
          }

          if (dialog.key === DisplayModelKeys.Inbox) {
            return <Inbox key={dialog.key} inbox={dialog as InboxModel} />;
          }
          if (dialog.key === DisplayModelKeys.TournamentHelp) {
            return <WeeklyTournamentHelp key={dialog.key} tournamentHelp={dialog} />;
          }
          if (dialog.key === DisplayModelKeys.TournamentResult) {
            return <TournamentResult key={dialog.key} tournamentResult={dialog as TournamentResultModel} />;
          }
          if (dialog.key === DisplayModelKeys.EqualScoreDialog) {
            return <EqualScore key={dialog.key} equalScoreDialog={dialog} />;
          }
          if (dialog.key === DisplayModelKeys.CongratulationDialog) {
            return <Congratulation key={dialog.key} congratulationDialog={dialog} />;
          }
          if (dialog.key === DisplayModelKeys.FeatherBoosterDialog) {
            return <FeatherBooster key={dialog.key} featherBoosterDialog={dialog as FeatherBoosterModel} />;
          }
          if (dialog.key === DisplayModelKeys.FAQDialog) {
            return <FAQ key={dialog.key} faqDialog={dialog as FAQModel} />;
          }
          if (dialog.key === DisplayModelKeys.TokensInfoDialog) {
            return <TokensInfo key={dialog.key} tokensInfoDialog={dialog} />;
          }
          if (dialog.key === DisplayModelKeys.AppealOpponentDialog) {
            return <AppealOpponent key={dialog.key} appealOpponentDialog={dialog as AppealOpponentModel} />;
          }
          if (dialog.key === DisplayModelKeys.RangListHelpDialog) {
            return <RangListHelp key={dialog.key} rangListHelpDialog={dialog} />;
          }
          if (dialog.key === DisplayModelKeys.ConnectionLostDialog) {
            return <ConnectionLost key={dialog.key} connectionLostDialog={dialog as ServerInfoModel} />;
          }
          if (dialog.key === DisplayModelKeys.ResumeDialog) {
            return <Resume key={dialog.key} resumeDialog={dialog as ResumeGameModel} />;
          }
          if (dialog.key === DisplayModelKeys.ReconnectDialog) {
            return <Reconnect key={dialog.key} reconnectDialog={dialog} />;
          }
          if (dialog.key === DisplayModelKeys.AchievemntWonDialog) {
            return <WonAchievement key={dialog.key} achievemntWonDialog={dialog as AchievementWonModel} />;
          }
          if (dialog.key === DisplayModelKeys.NewDayDialog) {
            return <NewDay key={dialog.key} newDayDialog={dialog} />;
          }
          if (dialog.key === DisplayModelKeys.HappyBirthdayDialog) {
            return <HappyBirthday key={dialog.key} happyBirthdayDialog={dialog as HappyBirthdayModel} />;
          }
          if (dialog.key === DisplayModelKeys.DeclineGameDialog) {
            return <DeclineGameDialog key={dialog.key} declineGameDialog={dialog as DeclineGameModel} />;
          }
          if (dialog.key === DisplayModelKeys.SurveyDialog) {
            return <SurveyDialog key={dialog.key} surveyDialog={dialog as SurveyDialogModel} />;
          }
          if (dialog.key === DisplayModelKeys.NewAppInfoDialog) {
            return <NewAppInfoDialog key={dialog.key} newAppInfoDialog={dialog as ImportantInfoModel} />;
          }
          if (dialog.key === DisplayModelKeys.FriendChallengeDialog) {
            return <FriendsChallengeDialog key={dialog.key} friendChallenge={dialog as FriendChallengeModel} />;
          }
          if (dialog.key === DisplayModelKeys.NewPaymentInfoDialog) {
            return <NewPaymentInfoDialog key={dialog.key} newPaymentInfoDialog={dialog as ImportantInfoModel} />;
          }
          if (dialog.key === DisplayModelKeys.B2bLogin) {
            return <B2bLoginDialog key={dialog.key} b2bLoginDialog={dialog as B2bLoginModel} />;
          }
          return null;
        })}
      </div>
    );
  }
}
