import { PlayerAction } from '../../messages/communication/message/PlayerAction';
import { ServerEvent } from '../../messages/communication/message/ServerEvent';
import { Controller } from '../base/Controller';

export class PlayerSubController {
  private controller: Controller;

  constructor(controller: Controller) {
    this.controller = controller;
  }

  public onMessage(message: ServerEvent) {}

  protected sendMessageToServer(message: PlayerAction) {
    this.controller.sendMessage(message);
  }
}
