import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { InboxMessageModel } from 'common/src/models/dialogs/inbox/InboxMessageModel';
import { DialogProps } from '../BaseDialog';

interface InboxMessageProps extends DialogProps {
  message?: InboxMessageModel;
}
@observer
export default class InboxMessage extends Component<InboxMessageProps, {}> {
  render() {
    const { message } = this.props;
    return (
      <div className="inbox-message">
        <div className="inbox-message__icon" />
        <div className={`inbox-message__title ${message!.read ? 'inbox-message__title-read' : ''}`}>{message!.title}</div>
        <div className={`inbox-message__text ${message!.read ? 'inbox-message__text-read' : ''}`}>{message!.message}</div>
        {message!.linkUrl !== '' && message!.linkCaption !== '' && (
          <div
            className="inbox-message__link"
            onClick={() => {
              window.open(message!.linkUrl);
            }}
          >
            {message!.linkCaption}
          </div>
        )}
      </div>
    );
  }
}
