import { PlayerInfo } from './PlayerInfo';

const BINDING_NAME = 'rs.slagalica.player.message.AcceptGame';

export class AcceptGame extends PlayerInfo {
  bluePosition: boolean = false;

  // fieldType: boolean
  gameType: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
