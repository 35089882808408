import { Offer } from '../../../messages/player/store/Offer';

export class TokensPriceList {
  private static instance: TokensPriceList | undefined = undefined;

  private facebookOffers: Map<string, Array<Offer>> = new Map();

  public static getInstance(): TokensPriceList {
    if (!this.instance) {
      this.instance = new TokensPriceList();
    }

    return this.instance;
  }

  private constructor() {
    const dicUSD: Array<Offer> = [];
    dicUSD.push(new Offer(0, 100, 1.19));
    dicUSD.push(new Offer(1, 500, 5.99));
    dicUSD.push(new Offer(2, 1000, 11.99));
    dicUSD.push(new Offer(3, 3000, 29.99));
    this.facebookOffers.set('USD', dicUSD);

    const dicCHF: Array<Offer> = [];
    dicCHF.push(new Offer(0, 100, 0.99));
    dicCHF.push(new Offer(1, 500, 4.99));
    dicCHF.push(new Offer(2, 1000, 9.49));
    dicCHF.push(new Offer(3, 3000, 24.49));
    this.facebookOffers.set('CHF', dicCHF);

    const dicEUR: Array<Offer> = [];
    dicEUR.push(new Offer(0, 100, 0.99));
    dicEUR.push(new Offer(1, 500, 4.99));
    dicEUR.push(new Offer(2, 1000, 9.49));
    dicEUR.push(new Offer(3, 3000, 24.49));
    this.facebookOffers.set('EUR', dicEUR);

    const dicRSDQ: Array<Offer> = [];
    dicRSDQ.push(new Offer(0, 60.0, 60));
    dicRSDQ.push(new Offer(1, 120.0, 120));
    dicRSDQ.push(new Offer(2, 240.0, 300));
    dicRSDQ.push(new Offer(3, 1200.0, 1500));
    this.facebookOffers.set('RSD', dicRSDQ);
  }

  public getOffers(currency: string): Array<Offer> {
    const offers: Offer[] | undefined = this.facebookOffers.get(currency);
    if (offers) return offers;

    return [];
  }
}
