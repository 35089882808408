import { inject, observer } from 'mobx-react';
import React from 'react';
import { DisplayModel } from 'common/src/models/DisplayModel';
import { Local } from 'common/src/lang/Local';
import { ServerInfoModel } from 'common/src/models/dialogs/info/ServerInfoModel';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';
import ServerInfo from './ServerInfo';

interface ConnectionLostProps extends DialogProps {
  connectionLostDialog?: ServerInfoModel;
}
@observer
export default class ConnectionLost extends BaseDialog<ConnectionLostProps, {}> {
  isVisible(): boolean {
    if (this.props.connectionLostDialog) return this.props.connectionLostDialog.isVisible();
    return false;
  }

  renderVisible() {
    const { connectionLostDialog } = this.props;

    return (
      <div className="connection-lost">
        <div className="connection-lost__inner">
          <DialogHeader onClose={() => {}}>{Local.getString('conn_lost_dlg.title')}</DialogHeader>
          <div className="connection-lost__image" />

          <div className="connection-lost__description">{connectionLostDialog!.info}</div>
          <button
            className="connection-lost__confirm"
            onClick={() => {
              window.location.reload();
            }}
          >
            {Local.getString('common.confirm')}
          </button>
        </div>
      </div>
    );
  }
}
