import { types } from 'mobx-state-tree';
import { GameButtonMST } from '../GameButtonModel';
import { AnimationBuilderMST } from '../../../AnimationBuilder';

export const NONE = 0;
export const BLUE = 1;
export const RED = 2;

export const PairsFieldMST = GameButtonMST.named('PairsFieldMST')
  .props({
    id: 0,
    text: '',
    description: '',
    pointsAnimation: types.optional(AnimationBuilderMST, {}),
    answeredBy: NONE
  })
  .actions(self => ({
    setPairField(text: string) {
      self.setText(text);
      self.setRegular();
      self.setEnabled(true);
      self.setVisible(true);
    },
    setAsPassed() {
      self.setDarkBlue();
      self.setEnabled(false);
    },
    setAsAnswered(isBlue: boolean) {
      if (isBlue) {
        self.setBlue();
        self.answeredBy = BLUE;
      } else {
        self.setRed();
        self.answeredBy = RED;
      }
      self.setEnabled(false);
    },
    setAsUnanswered() {
      self.setYellow();
      self.setEnabled(false);
    },
    setDescription(desc: string) {
      self.description = desc;
    },
    reset() {
      self.text = '';
      self.description = '';
      self.answeredBy = NONE;
      self.visible = false;
    }
  }))
  .views(self => ({
    isAnsweredByBlue() {
      return self.answeredBy === BLUE;
    }
  }));

type PairsFieldModelType = typeof PairsFieldMST.Type;
export interface PairsFieldModel extends PairsFieldModelType {}

export const createPairsA = () =>
  PairsFieldMST.create({
    id: 0,
    text: '',
    description: '',
    pointsAnimation: {},
    answeredBy: NONE,
    visible: false
  });

export const createPairsB = () =>
  PairsFieldMST.create({
    id: 0,
    text: '',
    description: '',
    pointsAnimation: {},
    answeredBy: NONE,
    visible: false
  });
