import { PointsUpdate } from '../../../communication/message/PointsUpdate';

const BINDING_NAME = 'rs.slagalica.games.quiz.message.AnswerResult';

export class AnswerResult extends PointsUpdate {
  answerCorrect: boolean = false;

  winner: boolean = false;

  millisecondsElapsed: number = 0;

  rightAnswerIndex: number = 0;

  myAnswerIndex: number = 0;

  opponetAnswerIndex: number = 0;

  opponentMillisElapsed: number = 0;

  b2bMyPlayerAnswered: number = 0;

  b2bOpponentPlayerAnswered: number = 0;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
