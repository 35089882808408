import { TrackingAction } from './TrackingAction';

export class GameFlowTracking extends TrackingAction {
  constructor(name: string, dsc: string = '') {
    super('gameflow', name, dsc);
  }

  public /* override */ logToFBAnalytics(): boolean {
    return true;
  }
}
