import { UserPropertyIF } from './UserPropertyIF';

export class UserProperty implements UserPropertyIF {
  private userId: string;

  private propertyName: string;

  private propertyValue: string;

  constructor(userId: string, propertyName: string, propertyValue: string) {
    this.userId = userId;
    this.propertyName = propertyName;
    this.propertyValue = propertyValue;
  }

  public getUserId(): string {
    return this.userId;
  }

  public getPropertyName(): string {
    return this.propertyName;
  }

  public getPropertyValue(): string {
    return this.propertyValue;
  }
}
