import { MessageSerializable } from '../../MessageSerializable';

const BINDING_NAME = 'rs.slagalica.games.association.AssociationRewardStrategy';

export class AssociationRewardStrategy extends MessageSerializable {
  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
