import { Animation, Power1, TimelineLite, TweenMax } from 'gsap';
import GSAPAnimation from '../base/GSAPAnimation';

function createZoomOut(target: HTMLElement | null, duration: number = 0.2): Animation {
  const timeline = new TimelineLite({ paused: true });

  if (target)
    timeline.add(
      TweenMax.fromTo(
        target,
        duration,
        {
          scaleX: 1,
          scaleY: 1,
          opacity: 1,
          transformOrigin: 'center',
          ease: Power1.easeIn
        },
        { scaleX: 0, scaleY: 0, opacity: 0, transformOrigin: 'center' }
      )
    );

  return timeline;
}
export class ZoomOut extends GSAPAnimation {
  constructor(target: HTMLElement | null, duration: number = 0.2) {
    super(createZoomOut(target, duration));
  }
}
