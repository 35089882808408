import { types } from 'mobx-state-tree';
import { APP } from '../../../../app/AppInstance';
import { ALPHABET } from '../../../../util/Alphabet';
import { ActionMST } from '../../../ActionModel';
import { AnimationBuilderMST } from '../../../AnimationBuilder';
import { ButtonMST, createConfirmPuzzleButton, createStopPuzzleButton } from '../../../ButtonModel';
import { GameRiddleContentMST } from '../GameRiddleContent';
import { ComputerWordMST, createComputerWordModel } from './ComputerWordModel';
import { createMyLetter, createRegularLetter, LetterModel, LetterMST } from './LetterModel';
import { createMyWordModel, MyWordMST } from './MyWordModel';
import { createOpponentWordModel, OpponentWordMST } from './OpponentWordModel';
import { createWordChecker, WordCheckMST } from './WordCheckModel';

export const PuzzleMST = GameRiddleContentMST.named('PuzzleMST')
  .props({
    letters: types.array(LetterMST),
    checker: WordCheckMST,
    eraseLetter: types.optional(ButtonMST, { action: { enabled: false } }),
    eraseAllLetters: types.optional(ButtonMST, { action: { enabled: false } }),
    initalLettersAnimation: types.optional(AnimationBuilderMST, {}),
    hideBtnsOnConfirmAnimation: types.optional(AnimationBuilderMST, {}),
    showOpponentWordAnimation: types.optional(AnimationBuilderMST, {}),
    showComputerWordAnimation: types.optional(AnimationBuilderMST, {}),
    myWord: types.optional(MyWordMST, {}), // types.optional(MyWordMST, {}),
    opponentWord: types.optional(OpponentWordMST, {}),
    computerWord: types.optional(ComputerWordMST, {}),
    confirmBtn: types.optional(ButtonMST, {}),
    stopBtn: types.optional(ButtonMST, {}),
    appealWord: types.optional(ActionMST, {}),
    shufflingAnimation: types.optional(AnimationBuilderMST, {}),
    myUrl: types.string,
    opponentUrl: types.string
  })
  .views(self => ({
    getletter(code: number) {
      ALPHABET.getLetter(code);
    }
  }))
  .actions(self => ({
    setLetters(letters: Array<number>, isPlayingAsBlue: boolean) {
      letters.forEach((x, index) => {
        const letterModel = self.letters[index];
        letterModel.setCode(x);
        letterModel.action.setAction(() => {
          this.onSelectLetter(letterModel, isPlayingAsBlue);
        });
      });
    },
    setBtnActions(sendWord: Function, stopSearch: Function, appealWord: Function) {
      self.eraseLetter.action.setAction(() => {
        this.onClearLetter();
      });

      self.eraseAllLetters.action.setAction(() => {
        this.onClearAllLetters();
      });

      self.confirmBtn.action.setAction(sendWord);
      self.stopBtn.action.setAction(stopSearch);
      self.appealWord.setAction(appealWord);
    },
    onSelectLetter(letterModel: LetterModel, isPlayingAsBlue: boolean): void {
      const addedLetter = createMyLetter(letterModel.code, letterModel.refIndex, isPlayingAsBlue);
      addedLetter.setVisible(false);
      this.addMyLetter(addedLetter);
      self.checker.checkWordAction.performAction();

      letterModel.setEnabled(false);
      self.eraseLetter.setEnabled(true);
      self.eraseAllLetters.setEnabled(true);
      self.confirmBtn.setEnabled(true);
      const appear = addedLetter.appearAnimation.build();
      appear.addStartListener(() => {
        addedLetter.setVisible(true);
      });
      appear.play();
    },
    onClearLetter() {
      self.eraseLetter.setEnabled(false);
      const seq = APP.AnimationAPI.createSequence();
      if (self.myWord.letters.length > 0) {
        const lastLetter = self.myWord.letters[self.myWord.letters.length - 1];
        const disappear = lastLetter.disappearAnimation.build();
        disappear.addEndListener(() => {
          this.removeMyLetter();
        });
        seq.add(disappear);
        seq.addEndListener(() => {
          if (self.myWord.letters.length === 0) {
            self.checker.clear();
            self.confirmBtn.setEnabled(false);
          } else {
            self.eraseLetter.setEnabled(true);
            self.checker.checkWordAction.performAction();
          }
        });
        self.letters[lastLetter.refIndex].setEnabled(true);
      }
      seq.play();
    },
    onClearAllLetters() {
      self.eraseLetter.action.setEnabled(false);
      self.eraseAllLetters.action.setEnabled(false);
      self.confirmBtn.action.setEnabled(false);
      const seq = APP.AnimationAPI.createSequence();
      const paralelDisappear = APP.AnimationAPI.createParallel();
      if (self.myWord.letters.length > 0) {
        for (let i = 0; i < self.myWord.letters.length; i++) {
          const lastLetter = self.myWord.letters[self.myWord.letters.length - 1 - i];
          const disappear = lastLetter.disappearAnimation.build();
          self.letters[lastLetter.refIndex].setEnabled(true);
          paralelDisappear.add(disappear);
        }
      }
      paralelDisappear.addEndListener(() => {
        self.checker.clear();
        self.confirmBtn.setEnabled(false);

        this.removeAllLetters();
      });
      seq.add(paralelDisappear);
      seq.play();
    },
    addMyLetter(letter: LetterModel) {
      self.myWord.letters.push(letter);
    },
    removeMyLetter(): void {
      self.myWord.letters.pop();
    },
    removeAllLetters(): void {
      self.myWord.letters.clear();
    },
    setOpponentLetters(letters: Array<number>) {
      self.opponentWord.letters.replace(letters);
      self.opponentWord.solving = false;
      self.appealWord.setEnabled(true);
    },
    setComputerLetters(letters: Array<number>) {
      self.computerWord.letters.replace(letters);
    },
    setWordStatus(isCorrect: boolean) {
      self.checker.setStatus(isCorrect);
    },
    disableSolvingButtons() {
      self.letters.forEach((letter: LetterModel) => {
        letter.action.setEnabled(false);
      });
      self.myWord.letters.forEach((letter: LetterModel) => {
        letter.action.setEnabled(false);
      });
      self.eraseLetter.action.setEnabled(false);
      self.eraseAllLetters.action.setEnabled(false);
      self.confirmBtn.action.setEnabled(false);
    },
    enableSolvingButtons() {
      self.letters.forEach((letter: LetterModel) => {
        letter.action.setEnabled(true);
      });
      self.eraseLetter.action.setEnabled(true);
      self.eraseAllLetters.action.setEnabled(true);
    },
    resetModel() {
      self.setVisible(false);
      self.myWord.reset();
      self.opponentWord.reset();
      self.computerWord = createComputerWordModel();
      self.letters.clear();
      for (let i = 0; i < 12; i++) {
        self.letters.push(createRegularLetter(i));
      }
      self.eraseLetter.setVisible(true);
      self.eraseAllLetters.setVisible(false);
      self.checker.clear();
      self.confirmBtn = createConfirmPuzzleButton();
      self.confirmBtn.setEnabled(false);
    },
    setMyURL(url: string) {
      self.myUrl = url;
    },
    setOpponentURL(url: string) {
      self.opponentUrl = url;
    }
  }));

type PuzzleModelType = typeof PuzzleMST.Type;
export interface PuzzleModel extends PuzzleModelType {}

const dummy = {
  name: 'Puzzle',
  visible: false,
  letters: [
    createRegularLetter(0),
    createRegularLetter(1),
    createRegularLetter(2),
    createRegularLetter(3),
    createRegularLetter(4),
    createRegularLetter(5),
    createRegularLetter(6),
    createRegularLetter(7),
    createRegularLetter(8),
    createRegularLetter(9),
    createRegularLetter(10),
    createRegularLetter(11)
  ],
  checker: createWordChecker(),
  myWord: createMyWordModel(),
  opponentWord: createOpponentWordModel(),
  computerWord: createComputerWordModel(),
  confirmBtn: createConfirmPuzzleButton(),
  stopBtn: createStopPuzzleButton(),
  shufflingAnimation: {},
  myUrl: '',
  opponentUrl: ''
};

export const createPuzzleModel = () => PuzzleMST.create(dummy);

export const createPuzzleDummy = () => PuzzleMST.create(dummy);
