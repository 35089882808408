import { FacebookAPI } from './FacebookAPI';
import { FBUser } from './FBUser';

const NUM_FRIENDS = 32;

const NUM_RANG_LIST = 31;

export class FacebookDummy implements FacebookAPI {
  private facebookId: string = '0';

  private onLoaded: Function;

  protected friendsPlayingCount: number = -2;

  protected invitableFriendsCount: number = -2;

  constructor() {
    this.onLoaded = () => {};
  }

  isAPILoaded(): boolean {
    throw new Error('Method not implemented.');
  }

  init(onInitDone: (success: boolean) => void): void {
    throw new Error('Method not implemented.');
  }

  public setUserAccesToken(userAccessToken: string): void {}

  private setId(id: string): void {
    // FIXME it is related with loadUserData function
    this.facebookId = id;
    this.onLoaded(true);
  }

  public loadUserData(onLoaded: Function): void {}

  public loadFriendsPlaying(onLoaded: (success: boolean) => void): void {
    onLoaded(true);
  }

  public loadCustomUser(facebookId: string, onLoaded: Function): void {
    onLoaded(true);
  }

  public getFriends(facebookId: string): Array<string> {
    const friends: Array<string> = [];

    for (let i = 1; i <= NUM_FRIENDS; i++) {
      if (`${i}` !== facebookId) friends.push(`${i}`);
    }

    return friends;
  }

  public getSortedInviteFriends(): Array<string> {
    return this.getFriends(this.getFBID());
  }

  public getSortedGiftFriends(): Array<string> {
    return this.getFriends(this.getFBID());
  }

  public getFriendName(fid: string): string {
    let name: string = `Nepoznati ${fid}`;
    const fbId: number = Number(fid);
    const dummynames: Array<string> = [];

    dummynames[1] = 'Hale';
    dummynames[2] = 'Mesi';
    dummynames[3] = 'Dzoni';
    dummynames[4] = 'Lepa';
    dummynames[5] = 'Dzesikica';
    dummynames[6] = 'Entonijo';
    dummynames[7] = 'Bruka';
    dummynames[8] = 'Edvard';
    dummynames[9] = 'Snajka';
    dummynames[10] = 'Skarlet';
    dummynames[11] = 'Garava';
    dummynames[12] = 'Crvenokosa';
    dummynames[13] = 'Meksikanac';

    if (fbId > 0 && fbId < 14) name = dummynames[fbId];
    return name;
  }

  public getFullFriendName(facebookId: string): string {
    let name: string = `Nepoznati Nepoznatic ${facebookId}`;
    const fbId: number = Number(facebookId);
    const dummynames: Array<string> = [];

    dummynames[1] = 'Hale Beric';
    dummynames[2] = 'Mesi Mesic';
    dummynames[3] = 'Dzoni Depic';
    dummynames[4] = 'Lepa Lepic';
    dummynames[5] = 'Dzesikica Albic';
    dummynames[6] = 'Entonijo Hopkinic';
    dummynames[7] = 'Bruka Care';
    dummynames[8] = 'Edvard Nortic';
    dummynames[9] = 'Snajka Srpska';
    dummynames[10] = 'Skarlet Dzonsonic';
    dummynames[11] = 'Garava Gara';
    dummynames[12] = 'Crvenokosa Mala';
    dummynames[13] = 'Meksikanac Naki';

    if (fbId > 0 && fbId < 14) {
      name = dummynames[fbId];
    }

    return name;
  }

  public getPicturePath(facebookId: string, width: number = 50, height: number = 50): string {
    return '';
  }

  public getFBID(): string {
    return this.facebookId;
  }

  public getThirdPartyID(): string {
    return this.facebookId.toString();
  }

  public getName(): string {
    return this.getFriendName(this.facebookId);
  }

  public getFullName(): string {
    return this.getFullFriendName(this.facebookId);
  }

  public getUser(facebookId: string): FBUser {
    return new FBUser(facebookId, this.getFullFriendName(facebookId), this.getFriendName(facebookId), this.getFriendName(facebookId), '');
  }

  public getCurrentUser(): FBUser {
    return new FBUser(this.facebookId, this.getFullName(), this.getName(), this.getName(), '');
  }

  public sendRequest(toIds: Array<string>, message: string, onCompleted: Function = () => {}): void {
    // if (onCompleted !== undefined) onCompleted(Notification.RequestNotAvailable, toIds);
  }

  public loadTotalFriendsCount(onLoaded: Function): void {}

  public getGender(): number {
    return 1;
  }

  public getTotalFriendsCount(): number {
    return this.friendsPlayingCount + this.invitableFriendsCount;
  }

  public getPlayingFriendsCount(): number {
    return this.friendsPlayingCount;
  }

  public buyTokens(q: number, cluserId: number, qMin: number, qMax: number, callback: Function): boolean {
    return false;
  }

  public getUserCurrency(): string {
    return 'USD';
  }

  friendsEnabled(): boolean {
    return true;
  }
}
