import { MessageSerializable } from '../MessageSerializable';
import { B2bGame } from './B2bGame';
import { B2bTeam } from './B2bTeam';

const BINDING_NAME = 'rs.slagalica.b2b.message.B2bTournamentInfo';

const TOURNAMENT_NOT_STARTED = 0; // fieldType: int
const TOURNAMENT_STARTED = 1; // fieldType: int
const TOURNAMENT_FINISHED = 2; // fieldType: int

export class B2bTournamentInfo extends MessageSerializable {
  id?: string = undefined;

  active?: number;

  teamsNum?: number;

  gamesPerTeamNum?: number;

  startTime?: Date = undefined;

  minutesFromNow?: number;

  minutesBeetweenGamesStarts?: number;

  organizationId?: string = undefined;

  bannerUrl?: string = undefined;

  teams?: Array<B2bTeam> = undefined;

  games?: Array<B2bGame> = undefined;

  globalChat?: string = undefined;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get TOURNAMENT_NOT_STARTED() {
    return TOURNAMENT_NOT_STARTED;
  }

  static get TOURNAMENT_STARTED() {
    return TOURNAMENT_STARTED;
  }

  static get TOURNAMENT_FINISHED() {
    return TOURNAMENT_FINISHED;
  }
}
