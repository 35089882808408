import { RANK_VALUES } from 'common/src/resources/RankValues';
import { CollaborationModel } from 'web/src/collaboration/model/CollaborationModel';
import AnimationExecutor from '../../animations/AnimationExecutor';
import BaseAnimation from '../../animations/BaseAnimation';
import GroupAnimation from '../../animations/GroupAnimation';
import { APP } from '../../app/AppInstance';
import { Local } from '../../lang/Local';
import { B2bChangeTeamImage } from '../../messages/b2b/B2bChangeTeamImage';
import { B2bGameSequenceStarted } from '../../messages/b2b/B2bGameSequenceStarted';
import { B2bTournamentEvent } from '../../messages/b2b/B2bTournamentEvent';
import { B2bTournamentInfo } from '../../messages/b2b/B2bTournamentInfo';
import { GameAvailable } from '../../messages/communication/message/GameAvailable';
import { GameResumed } from '../../messages/communication/message/GameResumed';
import { ResumeGame } from '../../messages/communication/message/ResumeGame';
import { ServerEvent } from '../../messages/communication/message/ServerEvent';
import { SignInCompleted } from '../../messages/communication/message/SignInCompleted';
import { RedeemResult } from '../../messages/credits/message/RedeemResult';
import { RedeemVoucherCode } from '../../messages/credits/message/RedeemVoucherCode';
import { AppealWord } from '../../messages/games/puzzle/message/AppealWord';
import { CommercialSurvey } from '../../messages/player/commercialsurvey/message/CommercialSurvey';
import { LeagueHistoryRequest } from '../../messages/player/history/message/LeagueHistoryRequest';
import { PreviousLeagueInfo } from '../../messages/player/league/message/PreviousLeagueInfo';
import { AcceptGame } from '../../messages/player/message/AcceptGame';
import { AllOffers } from '../../messages/player/message/AllOffers';
import { CancelPlayRandom } from '../../messages/player/message/CancelPlayRandom';
import { CancelResumeGame } from '../../messages/player/message/CancelResumeGame';
import { ChallengeRequest } from '../../messages/player/message/ChallengeRequest';
import { ChallengeResponse } from '../../messages/player/message/ChallengeResponse';
import { FriendCancelRequest } from '../../messages/player/message/FriendCancelRequest';
import { FriendChallengeRequest } from '../../messages/player/message/FriendChallengeRequest';
import { FriendChallengeResponse } from '../../messages/player/message/FriendChallengeResponse';
import { GameReadyToStart } from '../../messages/player/message/GameReadyToStart';
import { GameSequenceFinished } from '../../messages/player/message/GameSequenceFinished';
import { GameSequenceStarted } from '../../messages/player/message/GameSequenceStarted';
import { NewDayStarts } from '../../messages/player/message/NewDayStarts';
import { NewsRequest } from '../../messages/player/message/NewsRequest';
import { OpponentInfoUpdate } from '../../messages/player/message/OpponentInfoUpdate';
import { OpponentStartGameResponse } from '../../messages/player/message/OpponentStartGameResponse';
import { PaymentGateway } from '../../messages/player/message/PaymentGateway';
import { PlayerInfo } from '../../messages/player/message/PlayerInfo';
import { PlayNowRandom } from '../../messages/player/message/PlayNowRandom';
import { PlayRemach } from '../../messages/player/message/PlayRemach';
import { RaitingListRequest } from '../../messages/player/message/RaitingListRequest';
import { RaitingListResponse } from '../../messages/player/message/RaitingListResponse';
import { RequestPlayerInfo } from '../../messages/player/message/RequestPlayerInfo';
import { RequestStorageUpdate } from '../../messages/player/message/RequestStorageUpdate';
import { SendAppeal } from '../../messages/player/message/SendAppeal';
import { ServerStatus } from '../../messages/player/message/ServerStatus';
import { SpecialOffer } from '../../messages/player/message/SpecialOffer';
import { StartGameResponse } from '../../messages/player/message/StartGameResponse';
import { SurveyCollection } from '../../messages/player/message/SurveyCollection';
import { SurveyRequest } from '../../messages/player/message/SurveyRequest';
import { RankChanged } from '../../messages/player/reward/message/RankChanged';
import { Item } from '../../messages/player/store/Item';
import { StorageContent } from '../../messages/player/store/message/StorageContent';
import { StorageUpdateStatus } from '../../messages/player/store/message/StorageUpdateStatus';
import { B2bTeamModel } from '../../models/b2b/B2bTeamModel';
import { B2bTournamentModel } from '../../models/b2b/B2bTournamentModel';
import { BoosterModel } from '../../models/booster/BoosterModel';
import { RewardItemModel } from '../../models/common/RewardItemModel';
import { AchievementsModel } from '../../models/dialogs/achievements/AchievementsModel';
import { B2bMyTeamModel } from '../../models/dialogs/b2bgame/B2bMyTeamModel';
import { DeclineGameModel } from '../../models/dialogs/declinegame/DeclineGameModel';
import { FAQModel } from '../../models/dialogs/faq/FAQModel';
import { FeatherBoosterModel } from '../../models/dialogs/featherbooster/FeatherBoosterModel';
import { InboxModel } from '../../models/dialogs/inbox/InboxModel';
import { createNewPaymentInfoModel } from '../../models/dialogs/info/ImportantInfoModel';
import { SearchOpponentModel } from '../../models/dialogs/searchopponent/SearchOpponentModel';
import { ShopModel } from '../../models/dialogs/shop/ShopModel';
import { ShopTokensMobileModel } from '../../models/dialogs/shoptokens/ShopTokensMobileModel';
import { ShopTokensModel } from '../../models/dialogs/shoptokens/ShopTokensModel';
import { TokensInfoModel } from '../../models/dialogs/shoptokens/TokensInfoModel';
import { StatisticsModel } from '../../models/dialogs/statistics/StatisticsModel';
import { SurveyDialogModel } from '../../models/dialogs/survey/SurveyDialogModel';
import { VoucherModel } from '../../models/dialogs/voucher/VoucherModel';
import { REMATCH_ACCEPT, REMATCH_DEFAULT, REMATCH_DISABLED, REMATCH_WAITING, ResultModel } from '../../models/game/content/ResultModel';
import { GameInfoModel } from '../../models/game/GameInfoModel';
import { GameModel } from '../../models/game/GameScreenModel';
import { HatsModel } from '../../models/game/HatsModel';
import { LoaderModel } from '../../models/loader/LoaderModel';
import { LobbyModel } from '../../models/lobby/LobbyModel';
import { ModelFactory } from '../../models/ModelFactory';
import { SettingsModel } from '../../models/settings/SettingsModel';
import { ToolbarModel } from '../../models/toolbar/ToolbarModel';
import { UserModel } from '../../models/user/UserModel';
import { RootStore } from '../../RootStore';
import { ConnectionAdapter } from '../../sockets/ConnectionAdapter';
import { ClasterTracking } from '../../tracking/actions/ClasterTracking';
import { GameFlowTracking } from '../../tracking/actions/GameFlowTracking';
import { MainRoomTracking } from '../../tracking/actions/MainRoomTracking';
import { Controller } from '../base/Controller';
import { AchievementController } from './subcontrollers/AchievementController';
import { DailyLoginsController } from './subcontrollers/DailyLoginsController';
import { FriendsController } from './subcontrollers/FriendsController';
import { NotificationController } from './subcontrollers/NotificationController';
import { BoosterItem, BoostJokers, StorageController } from './subcontrollers/StorageController';
import { TournamentController } from './subcontrollers/TournamentController';
import { Util } from '../../util/Util';
import { B2bGame } from '../../messages/b2b/B2bGame';

const GAMBLING_GAME_COST = 100;
const GAME_COST = 40;

class DisplayModels {
  loader: LoaderModel | undefined;

  lobby: LobbyModel | undefined;

  game: GameModel | undefined;

  inbox: InboxModel | undefined;

  shop: ShopModel | undefined;

  shopTokens: ShopTokensModel | undefined;

  b2bTournament: B2bTournamentModel | undefined;

  achievements: AchievementsModel | undefined;

  statistics: StatisticsModel | undefined;

  tournamentResult = ModelFactory.createTournamentResult();

  dailyRewards = ModelFactory.createDailyRewards();

  searchOpponent: SearchOpponentModel | undefined = undefined;

  // TODO remove if not used!
  congratulationDialog = ModelFactory.createCongratulationDialog();

  reconnectDialog = ModelFactory.createReconnectDialog();

  connectionLostDialog = ModelFactory.createConnectionLostDialog();

  equalScoreDialog = ModelFactory.createEqualScoreDialog();

  resumeDialog = ModelFactory.createResumeDialog();

  newDayDialog = ModelFactory.createNewDayDialog();

  info = ModelFactory.createServerInfo('');

  tokensInfo: TokensInfoModel | undefined;

  featherBooster: FeatherBoosterModel | undefined = undefined;

  faq: FAQModel | undefined;

  declineGameDialog: DeclineGameModel | undefined = undefined;

  surveyDialog: SurveyDialogModel | undefined;

  voucher: VoucherModel | undefined;

  settings: SettingsModel | undefined;

  booster: BoosterModel | undefined;

  shopTokensMobile: ShopTokensMobileModel | undefined;

  b2bMyTeam: B2bMyTeamModel | undefined;

  collaboration: CollaborationModel | undefined;
}

class Game {
  gameInfo: GameInfoModel;

  result: ResultModel;

  constructor(gameInfo: GameInfoModel, result: ResultModel) {
    this.gameInfo = gameInfo;
    this.result = result;
  }
}

export class PlayerController extends Controller {
  private toolbar: ToolbarModel;

  private user: UserModel;

  private notificationController: NotificationController;

  private dailyLoginsController: DailyLoginsController;

  private friendsController: FriendsController;

  private tournamentController: TournamentController;

  private storageController: StorageController;

  private achievementController: AchievementController;

  private gameAvailable?: GameSequenceStarted;

  private b2bGameAvailable?: B2bGameSequenceStarted;

  private showNewDay = false;

  private displays: DisplayModels;

  private currentGame: Game;

  private animationExecutor: AnimationExecutor;

  private firstLoginTracked: boolean = false;

  private hats: HatsModel;

  private isMaintaingPeriod: boolean = false;

  private gameSequenceFinished: GameSequenceFinished | undefined = undefined;

  private isWaitingForRematch = false;

  private showCollaboration = false;

  private intervalStreaming: NodeJS.Timeout | null = null;

  constructor(amfSocket: ConnectionAdapter, root: RootStore) {
    super(amfSocket, 'PlayerController');

    this.displays = new DisplayModels();
    this.displays.loader = root.loader;
    this.displays.lobby = root.lobby;
    this.displays.game = root.game;
    this.displays.inbox = root.inbox;
    this.displays.shop = root.shop;
    this.displays.shopTokens = root.shopTokens;
    this.displays.b2bTournament = root.b2bTournament;
    this.displays.achievements = root.achievements;
    this.displays.statistics = root.statistics;
    this.displays.faq = root.faq;
    this.displays.featherBooster = root.featherBooster;
    this.displays.surveyDialog = root.survey;
    this.displays.tokensInfo = root.tokensInfo;
    this.displays.voucher = root.voucher;
    this.displays.booster = root.booster;
    this.displays.settings = root.settings;
    this.displays.searchOpponent = root.searchOpponent;
    this.displays.declineGameDialog = root.declineGameModel;
    this.displays.shopTokensMobile = root.shopTokensMobile;
    this.displays.collaboration = root.collaboration;

    this.currentGame = new Game(root.gameInfo, root.result);
    this.toolbar = root.toolbar;
    this.user = root.user;
    this.hats = root.hats;
    this.displays.b2bMyTeam = root.b2bMyTeam;

    this.notificationController = new NotificationController(this, this.displays.inbox!, this.toolbar);
    this.dailyLoginsController = new DailyLoginsController(this, this.displays.dailyRewards, this.user);
    this.friendsController = new FriendsController(this, root.friends, this.user);
    this.tournamentController = new TournamentController(this, root.tournaments, this.user);
    this.storageController = new StorageController(this, this.displays.shop, this.displays.shopTokens, root.user, root.chat, root.hats, root.toolbar);
    this.achievementController = new AchievementController(this, this.displays.achievements, this.toolbar);

    this.setDisplaysActions();

    this.animationExecutor = new AnimationExecutor();

    root.game.setContents(root);
  }

  private setDisplaysActions() {
    this.displays.voucher!.voucherAction.setAction((code: string) => {
      this.displays.voucher!.voucherAction.setEnabled(false);

      const redeemVoucherCode = new RedeemVoucherCode();
      redeemVoucherCode.code = code;
      this.sendMessage(redeemVoucherCode);
    });

    this.displays.lobby!.startGame.setAction(() => {
      this.startRandomGame();
    });

    this.displays.lobby!.openMyTeam.setAction(() => {
      this.displays.b2bMyTeam!.show();
    });

    this.displays.featherBooster!.setBuyAction(() => {
      if (this.storageController.getItemDesc(BoostJokers, 0) && this.user.allTokens < this.storageController.getItemDesc(BoostJokers, 0)!.cost) {
        this.displays.featherBooster!.hide();
        return;
      }

      this.displays.featherBooster!.buyAction.setEnabled(false);

      const seq: GroupAnimation = APP.AnimationAPI.createSequence();
      seq.add(this.displays.featherBooster!.flyBooster.build());
      seq.add(this.displays.booster!.boosterAnimation.build(this.displays.booster!.progressBooster, this.displays.booster!.progressBooster + 1 > 4 ? 4 - this.displays.booster!.progressBooster : 1));
      seq.addEndListener(() => {
        this.displays.featherBooster!.hide();
        this.storageController.buy(this.storageController.getItemDesc(BoostJokers, BoosterItem)!, null, true);
      });
      this.animationExecutor.schedule(seq);
    });
    this.displays.booster!.boosterAction.setAction(() => {
      this.displays.featherBooster!.show();
    });
    this.displays.declineGameDialog!.confirmAction.setAction(() => {
      this.declineMatching();
    });
    this.displays.declineGameDialog!.cancelAction.setAction(() => {
      this.displays.declineGameDialog!.hide();
    });
  }

  public startRandomGame(): void {
    if (!this.isAllowededToPlay(GameSequenceStarted.RANDOM_GAME)) {
      APP.Tracker.trackEvent(new GameFlowTracking('start_game_rejected'));
      return;
    }

    this.isWaitingForRematch = false;
    this.friendsController.cancelAllRequests();

    this.displays.searchOpponent!.setMyInfo(this.user);
    this.displays.searchOpponent!.setResponse(true, StartGameResponse.NOT_COMFIRMED);
    this.displays.searchOpponent!.clearOpponentInfo();
    this.displays.searchOpponent!.setFriendlyGame(false);
    this.displays.searchOpponent!.updateTimer(0);
    this.displays.searchOpponent!.setActions(
      () => {
        this.displays.searchOpponent!.setBtnsEnabled(false);
        if (this.displays.searchOpponent!.timer > 0) {
          const response = new StartGameResponse();
          response.response = StartGameResponse.DECLINED;
          this.sendMessage(response);
        } else {
          this.sendMessage(new CancelPlayRandom());
        }
        this.displays.searchOpponent!.stopTimer();
        this.closeSearchOpponent();
      },
      () => {
        this.displays.searchOpponent!.setBtnsEnabled(false);
        const response = new StartGameResponse();
        response.response = StartGameResponse.ACCEPTED;
        this.sendMessage(response);
        this.displays.searchOpponent!.setResponse(true, StartGameResponse.ACCEPTED);

        if (this.displays.searchOpponent!.opponentResponse === StartGameResponse.ACCEPTED) {
          this.displays.searchOpponent!.stopTimer();
        }
      },
      () => {
        this.displays.declineGameDialog!.show();
      }
    );
    this.displays.searchOpponent!.show();

    setTimeout(() => {
      const sequence: GroupAnimation = APP.AnimationAPI.createSequence();
      if (this.displays.lobby!.closeAnimation) sequence.add(this.displays.lobby!.closeAnimation.build());
      if (this.displays.searchOpponent!.openAnimation) sequence.add(this.displays.searchOpponent!.openAnimation.build());
      const pause = APP.AnimationAPI.createPause(0.1);
      pause.addEndListener(() => {
        this.sendMessage(new PlayNowRandom());
      });
      sequence.add(pause);
      const animOpponentSearch: BaseAnimation | undefined = this.displays.searchOpponent!.opponentSearchAnimation.build();
      sequence.add(animOpponentSearch!);
      sequence.play();
    }, 200);
  }

  private closeSearchOpponent() {
    const seq = APP.AnimationAPI.createSequence();
    if (this.displays.searchOpponent!.closeAnimation) seq.add(this.displays.searchOpponent!.closeAnimation.build());
    if (this.displays.lobby!.openAnimation) seq.add(this.displays.lobby!.openAnimation.build());
    seq.addEndListener(() => {
      this.displays.searchOpponent!.hide();
    });
    seq.play();
  }

  private declineMatching() {
    this.displays.searchOpponent!.stopTimer();
    const response = new StartGameResponse();
    response.response = StartGameResponse.DECLINED;
    this.sendMessage(response);
    this.displays.declineGameDialog!.hide();
    this.closeSearchOpponent();
  }

  public onControllerMessage(message: ServerEvent) {
    this.notificationController.onMessage(message);
    this.dailyLoginsController.onMessage(message);
    this.friendsController.onMessage(message);
    this.tournamentController.onMessage(message);
    this.storageController.onMessage(message);
    this.achievementController.onMessage(message);

    switch (message.getClass()) {
      case PlayerInfo.ClassName:
        this.onPlayerInfo(message as PlayerInfo);
        break;
      case SignInCompleted.ClassName:
        this.onSignInCompleted(message as SignInCompleted);
        break;
      case ServerStatus.ClassName:
        this.onServerStatus(message as ServerStatus);
        break;
      case AcceptGame.ClassName:
        this.onAcceptGame(message as AcceptGame);
        break;
      case NewDayStarts.ClassName:
        this.onNewDayStarts();
        break;
      case OpponentStartGameResponse.ClassName:
        this.onOpponentStartGameResponse(message as OpponentStartGameResponse);
        break;
      case GameSequenceStarted.ClassName:
        this.onGameSequenceStarted(message as GameSequenceStarted);
        break;
      case B2bGameSequenceStarted.ClassName:
        this.onB2bGameSequenceStarted(message as B2bGameSequenceStarted);
        break;
      case GameAvailable.ClassName:
        this.onGameAvailable(message as GameAvailable);
        break;
      case GameSequenceFinished.ClassName:
        this.onGameSequenceFinished(message as GameSequenceFinished);
        break;
      case GameResumed.ClassName:
        this.onGameResumed(message as GameResumed);
        break;
      case OpponentInfoUpdate.ClassName:
        this.onOpponentInfoUpdate(message as OpponentInfoUpdate);
        break;
      case PreviousLeagueInfo.ClassName:
        this.onPreviousLeagueInfo(message as PreviousLeagueInfo);
        break;
      case StorageUpdateStatus.ClassName:
        this.onStorageUpdateStatus(message as StorageUpdateStatus);
        break;
      case RaitingListResponse.ClassName:
        this.onRaitingListResponse(message as RaitingListResponse);
        break;
      case RankChanged.ClassName:
        this.onRankChanged(message as RankChanged);
        break;
      case SurveyCollection.ClassName:
        this.onSurveyCollection(message as SurveyCollection);
        break;
      case AllOffers.ClassName:
        this.onAllOffers(message as AllOffers);
        break;
      case RedeemResult.ClassName:
        this.onRedeemResult(message as RedeemResult);
        break;
      case StorageContent.ClassName:
        this.onStorageContent();
        break;
      case FriendChallengeRequest.ClassName:
        this.onFriendChallengeRequest();
        break;
      case FriendCancelRequest.ClassName:
        this.onFriendCancelRequest();
        break;
      case FriendChallengeResponse.ClassName:
        this.onFriendChallengeResponse(message as FriendChallengeResponse);
        break;
      case CommercialSurvey.ClassName:
        this.onCommercialSurvey(message as CommercialSurvey);
        break;
      case PaymentGateway.ClassName:
        this.onPaymentGateway(message as PaymentGateway);
        break;
      case B2bTournamentEvent.ClassName:
        this.onB2bTournamentEvent(message as B2bTournamentEvent);
        break;
      default:
        break;
    }
  }

  private onPlayerInfo(message: PlayerInfo) {
    if (message.firstLoginEver < 1 && !this.firstLoginTracked) {
      APP.Tracker.trackEvent(new ClasterTracking('new_user'));
    }

    this.firstLoginTracked = true;
    this.setTodaysHighscore(message);
    this.user.setInfo(message);
    this.displays.booster!.setProgressBooster(message.boost);
    this.displays.statistics!.setStatistics(message.stat);
    this.displays.faq!.setThirdPartyId(message.thirdPartyId || '');
    this.displays.achievements!.setRank(message.rank);

    this.displays.settings!.setMusic(false);
    this.displays.settings!.setSounds(APP.Settings.isSoundsOn());
    this.toolbar.setRankingInfo(message.rank, message.rankingPoints, RANK_VALUES.getLevelValue(message.rank + 1));
    this.toolbar.setTokens(message.goldTokens);
    this.toolbar.setTokensSilver(message.silverTokens);
    this.notificationController.setPreviousLoginDate(message.previousLogInDate);
  }

  private onSignInCompleted(message: SignInCompleted) {
    if (message.status === SignInCompleted.Successful) {
      this.friendsController.requestLoadFriends();
      this.tournamentController.requestLoadRangList();
      this.sendMessage(new LeagueHistoryRequest());
      this.sendMessage(new SurveyRequest());
      this.sendMessage(new NewsRequest());
      this.sendMessage(new RaitingListRequest());

      this.displays.loader!.finish();
      if (!this.displays.game!.isVisible()) {
        this.displays.lobby!.show();
      }
    }
  }

  private onServerStatus(message: ServerStatus) {
    this.displays.lobby!.setPlayersOnline(message.playersOnline);
    this.isMaintaingPeriod = message.isMaintaingPeriod;
  }

  private onAcceptGame(message: AcceptGame) {
    // friendsManager.enableControl(true)

    this.displays.searchOpponent!.setFriendlyGame(this.isWaitingForRematch || message.gameType === GameSequenceStarted.FRIEND_GAME);
    if (message.gameType === GameSequenceStarted.FRIEND_GAME || this.isWaitingForRematch) {
      this.isWaitingForRematch = false;
      this.displays.searchOpponent!.setMyInfo(this.user);
      this.displays.searchOpponent!.setOpponentInfo(message);
      this.displays.searchOpponent!.gameAcceptanceHolder.setVisible(false);
      this.displays.searchOpponent!.show();

      const pause = APP.AnimationAPI.createPause(2);
      pause.addEndListener(() => {
        const response = new StartGameResponse();
        response.response = StartGameResponse.ACCEPTED;
        this.sendMessage(response);
      });
      this.animationExecutor.schedule(pause);
    } else {
      // stop searching animation
      this.displays.searchOpponent!.cancelMatching.setEnabled(false);
      const seq: GroupAnimation = APP.AnimationAPI.createSequence();
      const selectAnim = this.displays.searchOpponent!.opponentSelectAnimation.build();
      selectAnim.addStartListener(() => {
        APP.SoundPlayer.playStartupGame2Sound(0.15);
      });
      selectAnim.addEndListener(() => {
        this.displays.searchOpponent!.setOpponentInfo(message);
        this.displays.searchOpponent!.startTimer(() => {
          this.displays.searchOpponent!.stopTimer();
          this.displays.searchOpponent!.setBtnsEnabled(false);
          if (this.displays.searchOpponent!.playerResponse === StartGameResponse.NOT_COMFIRMED) {
            const response = new StartGameResponse();
            response.response = StartGameResponse.DECLINED;
            this.sendMessage(response);
            this.displays.searchOpponent!.setResponse(true, StartGameResponse.DECLINED);
            this.closeSearchOpponent();
          }
        }, Math.floor(message.timeoutForResponse / 1000));
        this.displays.searchOpponent!.gameAcceptanceHolder.setVisible(true);
      });
      seq.add(selectAnim);
      // seq.add(APP.AnimationAPI.createPause(1.5));
      // seq.addEndListener(() => {
      //   const response = new StartGameResponse();
      //   response.response = StartGameResponse.ACCEPTED;
      //   this.sendMessage(response);
      // });
      seq.play();
    }
  }

  private setTodaysHighscore(message: PlayerInfo) {
    let todaysMaximum = 0;
    const maximums = message.rankListPoints ? message.rankListPoints.maximums : [];
    maximums!.forEach((element: { isTodayMaximum: any; maximum: number }) => {
      if (element.isTodayMaximum) {
        todaysMaximum = element.maximum;
      }
    });

    this.displays.lobby!.setTodaysHigscore(todaysMaximum);
  }

  private onNewDayStarts() {
    if (this.displays.lobby!.isVisible()) {
      this.displays.newDayDialog.schedule();
    } else {
      this.showNewDay = true;
    }
  }

  private onOpponentStartGameResponse(message: OpponentStartGameResponse) {
    this.displays.searchOpponent!.setResponse(false, message.response);
    if (message.response === StartGameResponse.DECLINED) {
      this.displays.searchOpponent!.stopTimer();
      this.displays.searchOpponent!.setBtnsEnabled(false);
      this.displays.searchOpponent!.cancelMatching.setEnabled(true);
    } else if (message.response === StartGameResponse.ACCEPTED) {
      if (this.displays.searchOpponent!.playerResponse === StartGameResponse.ACCEPTED) {
        this.displays.searchOpponent!.stopTimer();
        this.displays.searchOpponent!.setBtnsEnabled(false);
      }
    }
  }

  private onGameSequenceStarted(message: GameSequenceStarted) {
    this.setAvailableGame(message);
    this.displays.searchOpponent!.hide();
    // TODO close all dialogs (PopUpMgr.removeAllPopUps())

    this.currentGame.gameInfo.bluePlayer.setPoints(0);
    this.currentGame.gameInfo.redPlayer.setPoints(0);
    this.currentGame.gameInfo.showTimer();

    this.displays.game!.resetGameModels();

    this.displays.game!.show();

    this.currentGame.result.setMyInfo(this.user.info.gender, this.user.info.name);
    this.currentGame.result.setOpponentsInfo(message.gender, message.name);

    this.sendMessage(new GameReadyToStart());
  }

  private onB2bGameSequenceStarted(message: B2bGameSequenceStarted) {
    this.setAvailableGame(message);
    this.displays.searchOpponent!.hide();
    // TODO close all dialogs (PopUpMgr.removeAllPopUps())

    this.currentGame.gameInfo.bluePlayer.setPoints(0);
    this.currentGame.gameInfo.redPlayer.setPoints(0);
    this.currentGame.gameInfo.showTimer();

    this.displays.game!.resetGameModels();

    this.displays.game!.show();

    this.currentGame.result.setMyInfo(this.user.info.gender, this.user.info.name);
    this.currentGame.result.setOpponentsInfo(message.gender, message.name);

    this.sendMessage(new GameReadyToStart());
  }

  private onGameResumed(message: GameResumed) {
    if (message.endStatus && this.hasAvailableGame())
      // game is resumed but it is finished meanwhile
      this.onGameFinished(message.endStatus);
    // continue with game just remove dialog

    this.displays.resumeDialog.hide();
    this.displays.reconnectDialog.hide();
  }

  private onGameAvailable(message: GameAvailable) {
    if (message.gameInfo && message.gameInfo != null) {
      if (this.isGameInProgress()) {
        this.requestResumeGame();
      } else {
        // close reconnect dialog if message received during reconnect;
        this.displays.reconnectDialog.hide();
        this.setAvailableGame(message.gameInfo);

        this.displays.resumeDialog!.confirmAction.setAction(() => {
          this.displays.resumeDialog.setOnClose(() => {});
          this.displays.resumeDialog.hide();
          this.requestResumeGame();
        });
        this.displays.resumeDialog.show(() => {
          this.sendMessage(new CancelResumeGame());
        });
      }
    } else {
      this.displays.lobby!.show();
      ModelFactory.createImportantInfo().show();
    }
  }

  private onGameSequenceFinished(message: GameSequenceFinished) {
    if (this.displays.game!.isVisible() && this.hasAvailableGame()) this.onGameFinished(message);
  }

  private onOpponentInfoUpdate(message: OpponentInfoUpdate) {
    if (this.displays.game!.isVisible()) {
      this.currentGame.gameInfo.updateOpponent(message);

      this.storageController.updateOpponentsHats(message);
    }
  }

  private onPreviousLeagueInfo(message: PreviousLeagueInfo) {
    this.displays.tournamentResult.setTournamentResult(message);
    this.displays.tournamentResult.claimButton.setText(Local.getString('league.claim_rewards'));
    this.displays.tournamentResult.setClaimAction(() => {
      this.displays.tournamentResult.claimButton.setEnabled(false);
      this.displays.tournamentResult.claimButton.setText(Local.getString('common.close'));
      this.displays.tournamentResult.claimButton.action.setAction(() => {
        this.displays.tournamentResult.hide();
      });

      const parallel: GroupAnimation = APP.AnimationAPI.createParallel();
      parallel.add(this.displays.tournamentResult.claimRewardAnimation.build());

      const seqCount: GroupAnimation = APP.AnimationAPI.createSequence();
      const counter: GroupAnimation = APP.AnimationAPI.createParallel();
      this.displays.tournamentResult.rewards.forEach((reward: RewardItemModel) => {
        const anim = this.toolbar.getItemCountUpAnimation(reward.id, reward.count);
        if (anim) counter.add(anim);
      });
      seqCount.add(APP.AnimationAPI.createPause(1.7));
      seqCount.add(counter);
      parallel.add(seqCount);

      parallel.play();

      setTimeout(() => {
        this.sendMessage(new RequestPlayerInfo());
        this.sendMessage(new RequestStorageUpdate());
        this.displays.tournamentResult.hide();
      }, 5000);
      this.displays.tournamentResult.claimButton.setEnabled(true);
    });
    this.displays.tournamentResult.schedule();
  }

  private onStorageUpdateStatus(message: StorageUpdateStatus) {
    if (message.updateReason === StorageUpdateStatus.GAME_DELETED) {
      this.updateRangLists();
    }
  }

  private onRaitingListResponse(message: RaitingListResponse) {
    if (!message || !message.position || message.position.position < 0) return;

    APP.ActionResolver.setCurrentPlayer(message.position.position, this.user.info.name, this.user.info.rankingPoints, this.user.info.rank, RANK_VALUES.getName(this.user.info.rank));
  }

  private onRankChanged(message: RankChanged) {
    if (this.user.info.facebookId) {
      // APP.FacebookAPI.publishNewLevel(
      //   this.user.info.facebookIdString,
      //   message.newRank,
      //   '',
      //   undefined
      // );
    }
  }

  private onSurveyCollection(message: SurveyCollection) {
    //
  }

  private onAllOffers(message: AllOffers) {
    if (!message || !message.offers) return;
    let index = 0;
    message.offers!.forEach((offer: SpecialOffer) => {
      if (offer.type === SpecialOffer.TypeTokens && message.defaultOffer === index) {
        this.displays!.shopTokens!.setSpecialOffer(offer);
        index++;
      }
    });
  }

  private onRedeemResult(message: RedeemResult) {
    this.displays.voucher!.setVoucherResult(message);
  }

  private onGameFinished(message: GameSequenceFinished) {
    const opponentId: number = this.gameAvailable ? this.gameAvailable!.id : 0;
    this.setAvailableGame(undefined);

    if (this.displays.game!.isVisible()) {
      this.currentGame.gameInfo.onGameSequenceFinished(message);
      this.currentGame.result.init(message);
      this.currentGame.result.setFriendlyGame(message.friendltMatch);
      this.currentGame.result.setBackToLobbyAction(() => {
        this.displays.game!.hide();
        this.displays.lobby!.show();
        ModelFactory.createImportantInfo().show();
        this.currentGame.result.setVisible(false);
        if (this.displays.lobby!.openAnimation) {
          const seq = APP.AnimationAPI.createSequence();
          seq.add(APP.AnimationAPI.createPause(0.2));
          seq.add(this.displays.lobby!.openAnimation.build());
          seq.play();
        }
        this.onBackToLobby();
      });
      this.currentGame.result.setRematchAction(() => {
        if (this.currentGame.result.rematchState === REMATCH_DEFAULT) {
          if (!this.isAllowededToPlay(GameSequenceStarted.RANDOM_GAME)) return;

          this.currentGame.result.rematchAction.setEnabled(false);
          const challengeRequest = new ChallengeRequest();
          challengeRequest.invitedPlayerId = opponentId;
          this.sendMessage(challengeRequest);
          this.currentGame.result.setRematchState(REMATCH_WAITING);
        } else if (this.currentGame.result.rematchState === REMATCH_ACCEPT) {
          if (!this.isAllowededToPlay(GameSequenceStarted.RANDOM_GAME)) return;

          this.currentGame.result.rematchAction.setEnabled(false);
          const challengeResponse = new ChallengeResponse();
          challengeResponse.challengerId = opponentId;
          challengeResponse.response = ChallengeResponse.Accept;
          this.sendMessage(challengeResponse);
          this.isWaitingForRematch = true;
        }
      });
      this.currentGame.result.rematchAction.setEnabled(true);
      this.currentGame.result.appealAction.setAction(() => {
        this.currentGame.result.appealAction.setEnabled(false);
        const appealDialog = ModelFactory.createAppealOpponentDialog();
        appealDialog.confirmAction.setAction(() => {
          this.sendMessage(new SendAppeal(opponentId));
          appealDialog.hide();
        });
        appealDialog.show();
      });
      this.currentGame.result.appealAction.setEnabled(true);

      this.currentGame.gameInfo.hideTimer();
      this.displays.game!.showGameContent(this.currentGame.result);
      this.gameSequenceFinished = message;

      if (message.equalScore) {
        this.displays.equalScoreDialog.schedule();
        this.displays.equalScoreDialog.setOnClose(() => {
          if (message.winner) this.currentGame.gameInfo.getMe().setPoints(this.currentGame.gameInfo.getMe().points + 1);
          else this.currentGame.gameInfo.getOpponent().setPoints(this.currentGame.gameInfo.getOpponent().points + 1);
          this.showResultAnimation();
        });
      } else {
        this.showResultAnimation();
      }
    }
  }

  private onStorageContent() {
    this.user.info.storage!.storage.forEach((item: Item) => {
      if (item.description && item.description!.groupId === BoostJokers) {
        this.displays.booster!.setPlayerBooster(item.inUse > 0);
        this.displays.featherBooster!.setStatus(item);
      }
    });
  }

  private onFriendChallengeRequest() {
    if (this.currentGame.result.isVisible()) {
      this.currentGame.result.setRematchState(REMATCH_ACCEPT);
    }
  }

  private onFriendCancelRequest() {
    if (this.currentGame.result.isVisible()) {
      this.currentGame.result.setRematchState(REMATCH_DEFAULT);
    }
  }

  private onFriendChallengeResponse(message: FriendChallengeResponse) {
    if (this.currentGame.result.isVisible()) {
      if (message.response === ChallengeResponse.Accept) {
        this.isWaitingForRematch = true;
        const playRematch = new PlayRemach();
        playRematch.friendToPlayId = message.challengedPlayerId;
        this.sendMessage(playRematch);
      } else {
        this.currentGame.result.setRematchState(REMATCH_DISABLED);
      }
    }
  }

  private onCommercialSurvey(message: CommercialSurvey) {
    this.displays.surveyDialog!.setInfo(message);
    this.displays.surveyDialog!.openSurveyAction.setAction(() => {
      this.displays.surveyDialog!.openSurveyAction.setEnabled(false);
      window.open(message.surveyPath);
      this.displays.surveyDialog!.hide();
    });
    this.displays.surveyDialog!.schedule();
  }

  private onPaymentGateway(message: PaymentGateway) {
    this.displays!.shopTokens!.setProvider(message.providerStart);
    this.displays!.shopTokens!.setVoucherShown(message.voucherEnabled);

    if (APP.Settings.isNewPaymentInfoDialogShown() || message.providerStart > 0) return;

    const newPaymentDialog = createNewPaymentInfoModel();
    newPaymentDialog.okButton.action.setAction(() => {
      newPaymentDialog.hide();
      this.displays!.shopTokens!.show();
      APP.Settings.setNewPaymentInfoDialogShown();
    });
    newPaymentDialog.okButton.action.setEnabled(false);
    newPaymentDialog!.schedule(
      () => {},
      () => {
        setTimeout(() => {
          newPaymentDialog!.showButtonAnimation.build().play();
        }, 10000);
      }
    );
  }

  private onB2bTournamentEvent({ tournamentinfo, showResult }: B2bTournamentEvent) {
    this.showCollaboration = false;
    if (this.intervalStreaming) {
      clearInterval(this.intervalStreaming);
    }

    this.displays!.b2bTournament!.setBannerUrl(tournamentinfo!.bannerUrl!);
    this.displays!.b2bTournament!.setActive(tournamentinfo!.active!);
    this.displays!.b2bTournament!.setStartTime(tournamentinfo!.startTime!);
    this.displays!.b2bTournament!.setGames(tournamentinfo!.games!);
    this.displays!.b2bTournament!.setTeams(tournamentinfo!.teams!, (teamId: string, url: string) => {
      this.sendChangeImage(teamId, url);
    });

    const b2bGames = tournamentinfo!
      .games!.filter(g => (g.blueTeamId === this.user.info.b2bTeam || g.redTeamId === this.user.info.b2bTeam) && Util.isDateToday(g.startTime))
      .sort((a: B2bGame, b: B2bGame) => {
        if (a!.startTime || b!.startTime) {
          return 0;
        }
        return a.startTime! - b.startTime!;
      });

    this.showStreamingPanel(b2bGames, tournamentinfo);

    this.intervalStreaming = setInterval(() => {
      if (this.showStreamingPanel(b2bGames, tournamentinfo)) {
        if (this.intervalStreaming) {
          clearInterval(this.intervalStreaming);
        }
      }
    }, 15000);

    if (showResult) {
      this.onB2bTournamentShowResult(tournamentinfo!);
    }
  }

  private setTournamentCollaborationSetting(tournamentinfo?: B2bTournamentInfo) {
    if (this.showCollaboration && process.env.REACT_APP_COLLABORATION_ON === 'true' && tournamentinfo!.active !== B2bTournamentInfo.TOURNAMENT_FINISHED && this.user.info.b2bTeam) {
      if (!this.displays.collaboration!.isInRoom()) {
        this.setMyCollaboration(this.displays!.b2bTournament!.getTeam(this.user.info.b2bTeam!));
      }
      this.displays.collaboration!.setGlobalChat(tournamentinfo!.globalChat!);
    } else {
      this.displays.collaboration!.setUrl('');
    }
  }

  private showStreamingPanel(b2bGames: B2bGame[], tournamentinfo?: B2bTournamentInfo) {
    if (b2bGames.length > 0) {
      const first = b2bGames[0];
      const last = b2bGames[b2bGames.length - 1];
      const fifteenMinutesBeforeStart = new Date(first.startTime.getTime() - 15 * 60 * 1000);
      const oneHourAfterStartDate = new Date(last.startTime.getTime() + 45 * 60 * 1000);
      const now = new Date().getTime();

      this.showCollaboration = now > fifteenMinutesBeforeStart.getTime() && oneHourAfterStartDate.getTime() > now;
      this.setTournamentCollaborationSetting(tournamentinfo);
      return !this.showCollaboration && now > oneHourAfterStartDate.getTime();
    }
    return false;
  }

  private setMyCollaboration(myTeam: B2bTeamModel) {
    this.displays.collaboration!.setUrl(myTeam.room);
    this.displays.collaboration!.setMyTeamChat(myTeam.chat!);
  }

  private onB2bTournamentShowResult(info: B2bTournamentInfo) {
    console.log('display b2b tournament result');
    // this.displays.tournamentResult.setTournamentResult(message);
    // this.displays.tournamentResult.claimButton.setText('OK');
    // this.displays.tournamentResult.setClaimAction(() => {
    //     this.displays.tournamentResult.hide();
    // });
    // this.displays.tournamentResult.schedule();
  }

  private sendChangeImage(teamId: string, url: string) {
    const changeImage: B2bChangeTeamImage = new B2bChangeTeamImage();
    changeImage.teamId = teamId;
    changeImage.url = url;
    this.sendMessage(changeImage);
  }

  private showResultAnimation() {
    if (this.gameSequenceFinished) {
      this.animationExecutor.schedule(this.currentGame.result.completeResultAnimation.build());
    }
  }

  private updateRangLists() {
    this.tournamentController.requestLoadRangList();
    this.tournamentController.requestLoadFriends(this.user.info.facebookId);
  }

  private isAllowededToPlay(gameType: number) {
    return this.checkMaintainingPeriod() && this.hasEnoughTokens(gameType);
  }

  private checkMaintainingPeriod(): boolean {
    if (this.isMaintaingPeriod) {
      ModelFactory.createServerInfo(Local.getString('server_inf.maintaining_dlg_msg'));
      return false;
    }

    return true;
  }

  private hasEnoughTokens(gameType: number): boolean {
    if (gameType === GameSequenceStarted.GAMBLING_GAME && this.user.allTokens < GAMBLING_GAME_COST) {
      this.displays.shopTokens!.schedule();
      return false;
    }

    if (this.user.allTokens < GAME_COST) {
      this.displays.shopTokens!.schedule();
      return false;
    }

    return true;
  }

  private requestResumeGame() {
    this.sendMessage(new ResumeGame());
    this.displays.game!.show();
  }

  private setAvailableGame(gameAvailable: GameSequenceStarted | undefined) {
    this.gameAvailable = gameAvailable;
    if (!gameAvailable) return;

    this.currentGame.gameInfo.setAppealAction((word: string) => {
      const appealWord = new AppealWord();
      appealWord.word = word;
      this.sendMessage(appealWord);
    });

    this.currentGame.gameInfo.setPlayingFirst(gameAvailable.isPlayingFirst || false);
    this.currentGame.gameInfo
      .getMe()
      .init(gameAvailable.myTeam!.companyName!, gameAvailable.myTeam!.name!, this.user.info.rank, this.user.info.facebookId ? this.user.info.facebookId : '', true, this.user.info.rankingPoints, this.user.info.rankListTotalPoints, this.user.info.stat, this.user.info.maxPrevRank);
    this.currentGame.gameInfo
      .getOpponent()
      .init(gameAvailable.opponentTeam!.companyName!, gameAvailable.opponentTeam!.name!, gameAvailable.rank, gameAvailable.facebookId ? gameAvailable.facebookId : '', false, gameAvailable.rankingPoints, gameAvailable.rankListTotalPoints, gameAvailable.stat, gameAvailable.maxPrevRank);
    this.currentGame.gameInfo.setMyTeam(gameAvailable.myTeam!.id!);
    this.currentGame.gameInfo.setOpponentTeam(gameAvailable.opponentTeam!.id!);

    this.currentGame.gameInfo.getMe().setB2bUrl(gameAvailable!.myTeam!.logo! || '');
    this.currentGame.gameInfo.getOpponent().setB2bUrl(gameAvailable!.opponentTeam!.logo! || '');

    this.storageController.updateOpponentsHats(gameAvailable);
  }

  private hasAvailableGame() {
    return this.gameAvailable !== undefined;
  }

  public getNotificationManager() {
    return this.notificationController;
  }

  private onBackToLobby() {
    APP.Tracker.trackEvent(new GameFlowTracking('back_to_lobby'));

    // FIXME just temp disabling request for application
    if (this.achievementController.getNotOpenedCount() > 0) {
      APP.Tracker.trackEvent(new MainRoomTracking('open_achievements', 'automatically'));
      this.achievementController.requestRewards();
      this.displays.achievements!.show();
    }

    // TODO Promotions
    // if (APP.Settings.getActivePromotion()) {
    // APP.Tracker.trackEvent(new PromotionsTracking('hatsPromotionShown', true));

    // PopUpMgr.displayModal(
    //   PromotionDialogFactory.createPromotionDialog(
    //     UserSettings.getInstance().getActivePromotion()
    //   ),
    //   0
    // );
    //   APP.Settings.setActivePromotion(undefined);
    // }

    this.sendMessage(new RequestPlayerInfo());
    this.friendsController.requestLoadFriends();
    this.updateRangLists();
  }

  private isGameInProgress(): boolean {
    return this.displays.game!.isVisible();
  }

  public onConnectionError(message: string, reconnectStarted: boolean): void {
    super.onConnectionError(message, reconnectStarted);
    if (reconnectStarted) this.displays.reconnectDialog.show();
    else {
      this.displays.reconnectDialog.hide();
      this.displays.connectionLostDialog.setInfo(message);
      this.displays.connectionLostDialog.show();
    }
  }

  public onConnected(reconnected: boolean): void {
    super.onConnected(reconnected);
    this.displays.reconnectDialog.hide();
    this.displays.connectionLostDialog.hide();
  }

  protected finalizeController(): void {}
}
