import { ServerEvent } from './ServerEvent';
import { GameSequenceFinished } from '../../player/message/GameSequenceFinished';

const BINDING_NAME = 'rs.slagalica.communication.message.GameResumed';

export class GameResumed extends ServerEvent {
  success: boolean = false;

  // fieldType: boolean
  endStatus?: GameSequenceFinished = undefined; // fieldType: GameSequenceFinished

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
