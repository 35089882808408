import { ImportantInfoModel } from 'common/src/models/dialogs/info/ImportantInfoModel';
import { observer } from 'mobx-react';
import { Local } from 'common/src/lang/Local';
import React from 'react';
import { DialogProps, BaseDialog } from '../BaseDialog';
import { Fade } from '../../../animations/samples/Fade';
import DialogHeader, { TYPE_IMPORTANT } from '../DialogHeader';

interface NewPaymentInfoProps extends DialogProps {
  newPaymentInfoDialog: ImportantInfoModel;
}

@observer
export default class NewPaymentInfoDialog extends BaseDialog<NewPaymentInfoProps, {}> {
  okButtonRef: HTMLButtonElement | null = null;

  isVisible(): boolean {
    if (this.props.newPaymentInfoDialog) return this.props.newPaymentInfoDialog.isVisible();
    return false;
  }

  createShowButtonAnimation = () => {
    const anim = new Fade(this.okButtonRef, 0.5, 0, 1);
    anim.addEndListener(() => {
      this.props.newPaymentInfoDialog.okButton.setEnabled(true);
    });
    return anim;
  };

  componentDidMount() {
    this.props.newPaymentInfoDialog!.showButtonAnimation.set(this.createShowButtonAnimation);
  }

  componentWillUnmount() {
    this.props.newPaymentInfoDialog!.showButtonAnimation.dispose();
  }

  renderVisible() {
    return (
      <div className="new-payment-info">
        <div className="new-payment-info__inner">
          <DialogHeader onClose={() => {}} type={TYPE_IMPORTANT}>
            {Local.getString('new_payment_info.dlg_title')}
          </DialogHeader>
          <div className="new-payment-info__image" />

          <div className="new-payment-info__description">
            <span>{Local.getString('new_payment_info.dlg_msg_1')}</span>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                const left = window.innerWidth / 2 - 500;
                const top = window.innerHeight / 2 - 325;
                window.open('https://play.google.com/store/apps/details?id=rs.aparteko.slagalica.android', '', `width=1000, height=650, left=${left}, top=${top}`);
              }}
            >
              {Local.getString('new_payment_info.dlg_msg_2')}
            </span>
            <span>{Local.getString('new_payment_info.dlg_msg_3')}</span>
          </div>
          <button
            className="new-payment-info__confirm"
            ref={buttonRef => {
              this.okButtonRef = buttonRef;
            }}
            onClick={() => {
              this.props.newPaymentInfoDialog.okButton.action.performAction();
            }}
          >
            {Local.getString('common.ok')}
          </button>

          <div className="new-payment-info__banner" />
          <div className="new-payment-info__single-token" />
        </div>
      </div>
    );
  }
}
