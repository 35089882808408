import { TimelineLite, TweenLite } from 'gsap';
import GSAPAnimation from '../base/GSAPAnimation';

export class BackgroundImage extends GSAPAnimation {
  constructor(target: {} | null, image: string) {
    const timeline = new TimelineLite({ paused: true });

    if (target) {
      timeline.add(
        TweenLite.set(target, {
          background: image
        })
      );
    }

    super(timeline);
  }
}
