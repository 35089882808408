import { MessageSerializable } from '../MessageSerializable';

const BINDING_NAME = 'rs.slagalica.player.News';

const StatusValid = 1; // fieldType: int
const StatusInvalid = 0; // fieldType: int
const PriorityUrgent = 1; // fieldType: int
const PriorityNormal = 0; // fieldType: int

export class News extends MessageSerializable {
  title?: string = undefined;

  // fieldType: String
  info?: string = undefined;

  // fieldType: String
  creationDate?: any = undefined;

  // fieldType: Date
  priority: number = 0;

  // fieldType: int
  clusterId: number = 0;

  // fieldType: long
  linkCaption: string = '';

  // fieldType: String
  linkURL: string = '';

  // fieldType: String
  platform: number = 0;

  // fieldType: int
  shown: boolean = false; // fieldType: boolean

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get StatusValid() {
    return StatusValid;
  }

  static get StatusInvalid() {
    return StatusInvalid;
  }

  static get PriorityUrgent() {
    return PriorityUrgent;
  }

  static get PriorityNormal() {
    return PriorityNormal;
  }
}
