import { APP } from '../app/AppInstance';
import { Local } from '../lang/Local';
import { PlayerAction } from '../messages/communication/message/PlayerAction';
import { ServerEvent } from '../messages/communication/message/ServerEvent';
import { ServerEventGroup } from '../messages/communication/message/ServerEventGroup';
import { SignInCompleted } from '../messages/communication/message/SignInCompleted';
import { StartControllerEvent } from '../messages/communication/message/StartControllerEvent';
import { LogOffDone } from '../messages/player/message/LogOffDone';
import { PlayerPing } from '../messages/player/message/PlayerPing';
import { ServerStatus } from '../messages/player/message/ServerStatus';
import { SignIn } from '../messages/player/message/SignIn';
import { InstallFlow } from '../tracking/actions/InstallFlow';
import { AMFSocket } from './AMFSocket';
import { ConnectionListener } from './ConnectionListener';

const MaxReconnectionCount = 5;

const PingTime = 10 * 1000;
const WaitTimeBetweenReconnection = 2 * 1000;
const AwaitServerStatus = 6 * 1000;

export class ConnectionAdapter {
  private awaitServerStatus?: NodeJS.Timeout = undefined;

  private pingSender?: NodeJS.Timeout = undefined;

  private waitBetweenReconnection?: NodeJS.Timeout = undefined;

  private tryReconnectionCnt: number = 0;

  private initialConnection: boolean = true;

  private userLogedOut: boolean = false;

  private reconnecting: boolean = false;

  private playerControllerId?: string;

  private playerId?: number = 0;

  private signInMessage?: SignIn;

  private socket: AMFSocket;

  private subscribers: Array<ConnectionListener>;

  constructor() {
    this.socket = new AMFSocket();
    this.subscribers = [];

    this.socket.onClose = () => {
      if (!this.userLogedOut) this.tryConnect('close');
    };
    this.socket.onError = () => {
      if (!this.userLogedOut) this.tryConnect('error');
    };
    this.socket.onConnected = () => {
      this.sendMessage(this.signInMessage!);
      APP.Tracker.trackEvent(new InstallFlow(InstallFlow.EV_CONNECT_TO_SERVER));
    };

    this.socket.onMessage = (message: ServerEvent) => {
      this.onMessage(message);
    };
  }

  public subscribe(eventListener: ConnectionListener) {
    this.subscribers.push(eventListener);
  }

  private onMessage(message: ServerEvent) {
    try {
      switch (message.getClass()) {
        case StartControllerEvent.ClassName:
          this.playerId = message.playerId;
          break;
        case SignInCompleted.ClassName:
          this.onSignInCompleted(message as SignInCompleted);
          break;
        case ServerStatus.ClassName:
          this.onServerStatus(message as ServerStatus);
          break;
        case LogOffDone.ClassName:
          this.onLogOffDone(message as LogOffDone);
          break;
        case ServerEventGroup.ClassName:
          this.onServerEventGroup(message as ServerEventGroup);
          break;
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }

    this.subscribers.forEach(subscriber => {
      try {
        subscriber.onMessage(message);
      } catch (err) {
        console.log(err);
      }
    });
  }

  private onSignInCompleted(signResponse: SignInCompleted): void {
    this.playerControllerId = signResponse.controllerId;
    this.playerId = signResponse.playerId;
    this.initialConnection = false;

    if (signResponse.status === SignInCompleted.Successful) {
      this.stopPing('sigin');
      this.startPing();
      this.onConnected(false);
    } else {
      this.userLogedOut = true;
      APP.Settings.setB2bAutologin(false);
      this.stopPing('signin fail');

      let message = 'Unknown';
      const code: string = ` (code: ${signResponse.status.toString()})`;

      if (signResponse.status === SignInCompleted.WrongTPId) message = Local.getString('login.auth_app_problem');
      else if (signResponse.status === SignInCompleted.ServerMaintaining) message = Local.getString('login.server_maintaining');
      else if (signResponse.status === SignInCompleted.TooManyUsers) message = Local.getString('login.too_many_users');
      else if (signResponse.status === SignInCompleted.Banned) message = Local.getString('login.baned_user');
      else if (signResponse.status === SignInCompleted.AuthenticationFailed) message = Local.getString('login.auth_user_problem');
      else if (signResponse.status === SignInCompleted.BannedAsFalseAccount) message = Local.getString('login.banned_as_false');
      else if (signResponse.status === SignInCompleted.BannedAsCheater) message = Local.getString('login.banned_as_cheater').replace('$0', signResponse.banUntilDate!.toString());
      else if (signResponse.status === SignInCompleted.B2bWrongHash || signResponse.status === SignInCompleted.B2bWrongHashAdmin) message = Local.getString('login.b2b_hash_wrong');
      else if (signResponse.status === SignInCompleted.B2bRejectedTournamentOver) message = Local.getString('login.b2b_tournament_over');
      else if (signResponse.status === SignInCompleted.B2bRejectedTournamentUnknown) message = Local.getString('login.b2b_tournament_unknown');
      else if (signResponse.status === SignInCompleted.B2bRejectedNickExists) message = Local.getString('login.b2b_tournament_nick_exists');
      else if (signResponse.status === SignInCompleted.B2bRejectedTournamentTeamUnknown) message = Local.getString('login.b2b_tournament_team_unknown');
      else if (signResponse.status === SignInCompleted.B2bRejectedTournamentMaxNumberExceeded) message = Local.getString('login.b2b_tournament_team_full');
      else {
        message = Local.getString('login.server_problem');
      }
      this.onConnectionError(message + code, false);
    }
  }

  private onServerEventGroup(message: ServerEventGroup) {
    // FIXME
    // this.activeControllers.forEach((controller: Controller) => controller.enterBurstMessageMode());

    const enabled: Boolean = APP.Settings.isSoundsOn();
    APP.Settings.enableSounds(false);
    this.startBurst();
    message.events.forEach((event: ServerEvent) => {
      // FIXME proveriti sta se desava ako u ovoj grupi stigne poruka za koju nema definicija na klijentu

      if (!(event instanceof ServerEvent)) {
        console.error('INFO: Received Unknown Message!');
        return;
      }

      const msg: ServerEvent = event as ServerEvent;
      const remainingTime: number = msg.timeoutForResponse - msg.delay;
      if (msg.timeoutForResponse > 0 && remainingTime > 0) msg.timeoutForResponse = remainingTime;
      else msg.timeoutForResponse = -1;

      this.onMessage(msg);
    });
    this.stopBurst();
    APP.Settings.enableSounds(enabled);
  }

  private onLogOffDone(message: LogOffDone): void {
    this.userLogedOut = true;
    this.stopPing('logoff');
  }

  private onServerStatus(message: ServerStatus): void {
    if (this.reconnecting) this.onConnected(true);
    if (this.awaitServerStatus) clearTimeout(this.awaitServerStatus);
  }

  private sendPing() {
    const ping = new PlayerPing();
    ping.controllerId = this.playerControllerId;
    this.sendMessage(ping);
    if (this.awaitServerStatus) clearTimeout(this.awaitServerStatus);

    this.awaitServerStatus = setTimeout(() => {
      this.tryConnect('timeout');
    }, AwaitServerStatus);
  }

  private startPing() {
    this.pingSender = setInterval(() => {
      this.sendPing();
    }, PingTime);

    this.sendPing();
  }

  private stopPing(message: string) {
    if (this.pingSender) clearInterval(this.pingSender);
    if (this.awaitServerStatus) clearInterval(this.awaitServerStatus);
  }

  private tryConnect(reconnectPoint: string): void {
    console.log(`Start reconnecting: ${reconnectPoint} reconnecting ${this.reconnecting} cnt: ${this.tryReconnectionCnt}`);
    if (this.tryReconnectionCnt <= MaxReconnectionCount) {
      if (!this.reconnecting) {
        this.reconnecting = true;
        this.stopPing('reconnect');
        this.onConnectionError(Local.getString('conn_lost_dlg.msg'), !this.initialConnection);
      }

      if (this.waitBetweenReconnection) clearTimeout(this.waitBetweenReconnection);

      this.waitBetweenReconnection = setTimeout(() => {
        this.tryReconnectionCnt++;
        this.socket.disconnect();
        this.socket.connect(APP.Settings.getServerUrl());
      }, WaitTimeBetweenReconnection);
    } else {
      this.onConnectionError(this.initialConnection ? Local.getString('server_inf.dlg_msg') : Local.getString('conn_lost_dlg.fail'), false);
    }
  }

  private onConnectionError(message: string, reconecting: boolean) {
    this.subscribers.forEach((listener: ConnectionListener) => listener.onConnectionError(message, reconecting));
  }

  private onConnected(reconnected: boolean) {
    this.reconnecting = false;
    this.tryReconnectionCnt = 0;
    this.subscribers.forEach((listener: ConnectionListener) => listener.onConnected(reconnected));
  }

  private startBurst() {
    this.subscribers.forEach((listener: ConnectionListener) => listener.onBurstMessigingStart());
  }

  private stopBurst() {
    this.subscribers.forEach((listener: ConnectionListener) => listener.onBurstMessigingStop());
  }

  public connect(signIn: SignIn): void {
    console.log('connect!');
    this.signInMessage = signIn;
    this.socket.disconnect();
    this.socket.connect(APP.Settings.getServerUrl());
  }

  public sendMessage(message: PlayerAction) {
    message.playerId = this.playerId;
    this.socket.sendMessage(message);
  }
}
