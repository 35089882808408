import { Local } from 'common/src/lang/Local';
import { CombinationsSign } from 'common/src/models/game/content/combinations/CombinationSign';
import { CombinationsModel } from 'common/src/models/game/content/combinations/CombinationsModel';
import { ResultHolderModel } from 'common/src/models/game/content/combinations/ResultHolderModel';
import { inject, observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { Glow } from '../../../../animations/samples/Glow';
import { PointsAnimation } from '../../../../animations/samples/PointsAnimation';
import GameButton from '../../GameButton';
import { GameContent, GameContentProps } from '../../GameContent';
import { GameStatus } from '../../GameStatus';
import Point from '../../Point';
import GSAPSequenceAnimation from '../../../../animations/base/GSAPSequenceAnimator';
import GSAPParallelAnimation from '../../../../animations/base/GSAPParallelAnimation';
import { Glow3D } from '../../../../animations/samples/Glow3D';

interface CombinationsProps extends GameContentProps {
  combinations?: CombinationsModel;
}

@inject('combinations')
@observer
export default class Combinations extends GameContent<CombinationsProps, {}> {
  buttonsCombinationRefs: Array<any | null> = [];

  buttonsRefs: Array<GameButton | null> = [];

  buttonsOpponentRefs: Array<GameButton | null> = [];

  myPoint: Array<Point | null> = [];

  opponentPoint: Point | null = null;

  getButtonImageClass(type: number) {
    switch (type) {
      case CombinationsSign.Owl:
        return 'owl';
      case CombinationsSign.Club:
        return 'club';
      case CombinationsSign.Spade:
        return 'spade';
      case CombinationsSign.Heart:
        return 'heart';
      case CombinationsSign.Diamond:
        return 'diamond';
      case CombinationsSign.Star:
        return 'star';
      default:
        return '';
    }
  }

  marginTopButton(index: number) {
    const { combinations } = this.props;
    let value = index * 5.1 + 0.5;

    if (combinations!.confirmBtns.length - 1 === index) {
      value += 1.5;
    }
    return `${value}rem`;
  }

  marginTopResult(index: number) {
    const value = index * 5.1 + 0.5;

    return `${value}rem`;
  }

  getHitTypeClass(type: number) {
    const TYPE_NONE = 0;
    const TYPE_YELLOW = 1;
    const TYPE_RED = 2;

    switch (type) {
      case TYPE_NONE:
        return 'no';
      case TYPE_YELLOW:
        return 'wrong';
      case TYPE_RED:
        return 'right';
      default:
        return 'no';
    }
  }

  createPointsWon = (args: any[]) => {
    const isBlue: boolean = args[0];
    const index: number = args[1];
    const seq = new GSAPSequenceAnimation();
    const divPoint = this.myPoint![index];
    if (divPoint) seq.add(PointsAnimation.create(isBlue, divPoint));
    return seq;
  };

  createOpponentsPointsWon = (args: any[]) => {
    const isBlue: boolean = args[0];
    const seq = new GSAPSequenceAnimation();
    const divPoint = this.opponentPoint;
    if (divPoint) {
      seq.add(PointsAnimation.create(isBlue, divPoint));
    }
    return seq;
  };

  createHitGlowAnimations() {
    const { combinations } = this.props;
    combinations!.combinationHolders.forEach((holder, i) => {
      holder!.hitGlowAnimation!.set(() => {
        const parallel = new GSAPParallelAnimation();
        holder!.signButtons.forEach((hold, y) => {
          if (this.buttonsRefs[i * 10 + y]) {
            parallel.add(new Glow3D(this.buttonsRefs[i * 10 + y]!.boxRef, 0.5, 3));
          }
        });
        return parallel;
      });
    });
    combinations!.combinationHolders[6].hitGlowAnimation.set(() => {
      const parallel = new GSAPParallelAnimation();
      combinations!.combinationHolders[6]!.signButtons.forEach((signBtn, index) => {
        if (this.buttonsOpponentRefs[index]) {
          parallel.add(new Glow3D(this.buttonsOpponentRefs[index]!.boxRef, 0.5, 3));
        }
      });
      return parallel;
    });
  }

  createAnimations() {
    this.createHitGlowAnimations();
    this.props.combinations!.resultHolders.forEach((result: ResultHolderModel) => {
      result!.pointsAnimation.set(this.createPointsWon);
    });
    this.props.combinations!.resultHolders[6].pointsAnimation.set(this.createOpponentsPointsWon);
  }

  destroyAnimations() {
    const { combinations } = this.props;
    combinations!.resultHolders.forEach(result => {
      result!.pointsAnimation!.dispose();
    });
    combinations!.combinationHolders.forEach(holder => {
      holder!.hitGlowAnimation!.dispose();
    });
    combinations!.resultHolders[6].pointsAnimation.dispose();
  }

  componentDidMount() {
    this.createAnimations();
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  isVisible(): boolean {
    const { combinations } = this.props;
    return combinations ? combinations.isVisible() : false;
  }

  renderVisible(): ReactNode {
    const { combinations } = this.props;
    const t = (message: string) => Local.getString(message);

    if (!combinations) {
      return null;
    }

    return (
      <div
        ref={contentRef => {
          this.contentRef = contentRef;
        }}
      >
        <GameStatus />
        <div className="combinations">
          <div className="combinations__buttons">
            {combinations!.signButtons.map((x, index) => (
              <GameButton key={x.text + index} model={combinations!.signButtons[index]} baseClass="combinations__buttons--game-button" baseImgClass={`combinations__button-image combinations__button-image--${this.getButtonImageClass(x.imgType)}`} />
            ))}
          </div>
          <div className="combinations__combinations-box">
            {combinations!.combinationHolders.map((combs, i) =>
              i === 6 ? (
                <div />
              ) : (
                <div className="combinations__combinations-holder">
                  {combs.signButtons.map((signButton, y) => (
                    <GameButton
                      key={signButton.text + y}
                      model={combs!.signButtons[y]}
                      baseClass={combs!.signButtons[y].isEnabled() && combs!.signButtons[y].getSignType() === CombinationsSign.None ? 'combinations__combinations-holder--game-button combinations__combinations-holder--game-button-empty' : 'combinations__combinations-holder--game-button'}
                      baseImgClass={`combinations__button-image combinations__button-image--small combinations__button-image--${this.getButtonImageClass(signButton.imgType)}`}
                      ref={number => {
                        this.buttonsRefs[i * 10 + y] = number;
                      }}
                    />
                  ))}
                </div>
              )
            )}
            <div className="combinations__combinations-holder combinations__combinations-holder--opponent">
              {combinations!.combinationHolders[6].signButtons.map((x, index) => (
                <GameButton
                  key={x.text + index}
                  model={combinations!.combinationHolders[6].signButtons[index]}
                  baseClass={
                    combinations!.combinationHolders[6].signButtons[index].isEnabled() && combinations!.combinationHolders[6].signButtons[index].getSignType() === CombinationsSign.None
                      ? 'combinations__combinations-holder--game-button combinations__combinations-holder--game-button-empty'
                      : 'combinations__combinations-holder--game-button'
                  }
                  baseImgClass={`combinations__button-image combinations__button-image--small combinations__button-image--${this.getButtonImageClass(x.imgType)}`}
                  ref={number => {
                    this.buttonsOpponentRefs[index] = number;
                  }}
                />
              ))}
            </div>
            <div className="combinations__combinations-holder">
              {combinations!.correctCombinationHolder.signButtons.map((x, index) => (
                <GameButton
                  key={x.text + index}
                  model={combinations!.correctCombinationHolder.signButtons[index]}
                  baseClass="combinations__combinations-holder--game-button"
                  baseImgClass={`combinations__button-image combinations__button-image--small combinations__button-image--${this.getButtonImageClass(x.imgType)}`}
                />
              ))}
            </div>
          </div>
          <div className="combinations__button-box">
            {combinations!.confirmBtns.map((x, index) => {
              if (!combinations.isConfirmShow(index)) return null;

              return (
                <button
                  key={x.text + index}
                  className="combinations__button-box--confirm-button"
                  style={{ marginTop: this.marginTopButton(index) }}
                  disabled={!x.isEnabled()}
                  hidden={!x.isVisible()}
                  onClick={() => {
                    x.action.performAction();
                  }}
                >
                  {Local.getString('common.confirm')}
                </button>
              );
            })}

            {combinations!.resultHolders.map((resultHolder, ind) =>
              ind === 6 ? (
                <div />
              ) : (
                <div key={ind} className="combinations__button-box__result-holder" style={{ marginTop: this.marginTopResult(ind) }}>
                  {resultHolder.hits.map((hit, index) => (
                    <div>
                      <div key={index} className={`combinations__button-box__hit combinations__button-box__hit--${this.getHitTypeClass(hit.type)}`} />
                    </div>
                  ))}
                  <Point
                    ref={point => {
                      this.myPoint[ind] = point;
                    }}
                    baseClass="combinations__button-box__point"
                    value={combinations.pointsWon}
                  />
                </div>
              )
            )}
            <div className="combinations__button-box__result-holder combinations__button-box__result-holder--opponent">
              {combinations!.resultHolders[6].hits.map((hit, index) => (
                <div key={index} className={`combinations__button-box__hit combinations__button-box__hit--${this.getHitTypeClass(hit.type)}`} />
              ))}
              <Point
                ref={point => {
                  this.opponentPoint = point;
                }}
                baseClass="combinations__button-box__point"
                value={10}
              />
            </div>
          </div>
        </div>
        <div className="combinations__text-box">
          <div className="combinations__hit-box">
            <div className="combinations__hit-box--hit combinations__hit-box--hit--right" />
            <div className="combinations__hit-box--hit combinations__hit-box--hit--wrong" />
          </div>
          <div className="combinations__text-box--text">
            {Local.getString('combination.right-answer')}
            <br />
            {Local.getString('combination.wrong-answer')}
          </div>
        </div>
      </div>
    );
  }
}
