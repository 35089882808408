import { GameInfoModel } from 'common/src/models/game/GameInfoModel';
import { HatsModel } from 'common/src/models/game/HatsModel';
import { TimelineMax } from 'gsap';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import GSAPAnimation from '../../../animations/base/GSAPAnimation';
import GSAPSequenceAnimation from '../../../animations/base/GSAPSequenceAnimator';
import SemaphoreBluePlayer from './SemaphoreBluePlayer';
import SemaphoreRedPlayer from './SemaphoreRedPlayer';

interface SemaphoreProps {
  gameInfo?: GameInfoModel;
  hats?: HatsModel;
}
@inject('gameInfo', 'hats')
@observer
export default class Semaphore extends Component<SemaphoreProps, {}> {
  semaphoreDiv: HTMLDivElement | null = null;

  componentDidMount() {
    this.createAnimations();
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  createAnimations() {
    this.props.gameInfo!.semaphorePopUpAnimation.set(() => {
      if (this.semaphoreDiv != null) return new GSAPAnimation(new TimelineMax({ paused: true }).add(new TimelineMax().to(this.semaphoreDiv, 0, { 'z-index': 10 })));

      return new GSAPSequenceAnimation();
    });

    this.props.gameInfo!.semaphorePushBackAnimation.set(() => {
      if (this.semaphoreDiv != null) return new GSAPAnimation(new TimelineMax({ paused: true }).add(new TimelineMax().to(this.semaphoreDiv, 0, { 'z-index': 0 })));

      return new GSAPSequenceAnimation();
    });
  }

  destroyAnimations() {
    this.props.gameInfo!.semaphorePopUpAnimation.dispose();
    this.props.gameInfo!.semaphorePushBackAnimation.dispose();
  }

  render() {
    return (
      <div>
        <div
          className="semaphore"
          ref={ref => {
            this.semaphoreDiv = ref;
          }}
        >
          <SemaphoreBluePlayer player={this.props.gameInfo!.bluePlayer} hats={this.props.hats!} />
          <SemaphoreRedPlayer player={this.props.gameInfo!.redPlayer} hats={this.props.hats!} />
          {/* <Chat isBlue={this.props.gameInfo!.isPlayingAsBlue} /> */}
        </div>
      </div>
    );
  }
}
