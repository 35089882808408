import { ServerEvent } from '../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.player.message.ServerStatus';

export class ServerStatus extends ServerEvent {
  playersOnline: number = 0;

  // fieldType: int
  playersPlaying: number = 0;

  // fieldType: int
  playersRegistered: number = 0;

  // fieldType: int
  isMaintaingPeriod: boolean = false; // fieldType: boolean

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
