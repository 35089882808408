import { LoaderModel } from 'common/src/models/loader/LoaderModel';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import Images from 'web/src/resources/ImgResources';
import { DisplayModel } from 'common/src/models/DisplayModel';
import { RemPixelRatio } from '../../animations/RemPixelRatio';
import { BaseScreen, BaseScreenProps } from '../BaseScreen';

interface LogoProps extends BaseScreenProps {
  logo?: DisplayModel;
  loader?: LoaderModel;
}
@inject('logo', 'loader')
@observer
export default class LogoScreen<P> extends BaseScreen<LogoProps, {}> {
  @observable loaded: boolean = true;

  private timeout: NodeJS.Timeout | undefined;

  private compRef: HTMLDivElement | null = null;

  constructor(props: Readonly<P>) {
    super(props);
  }

  componentDidMount = () => {
    this.props.logo!.hide();
    this.props.logo!.show();

    setTimeout(() => {
      RemPixelRatio.calculateRatio(this.compRef != null ? this.compRef.offsetWidth : RemPixelRatio.DEFAULT_WIDTH);
      this.props.loader!.show();
    }, 2000);
  };

  componentWillUnMount = () => {
    if (this.timeout) clearTimeout(this.timeout);
  };

  isVisible(): boolean {
    if (this.props.logo) return this.props.logo.isVisible();
    return false;
  }

  renderVisible(): React.ReactNode {
    return (
      <div
        ref={div => {
          this.compRef = div;
        }}
        className={`aparteko-logo-loader${this.loaded ? ' aparteko-logo-loader--active' : ''}`}
      >
        <img src={Images.logoAparteko} className="aparteko-logo-loader__logo-image" />
        <div className={`aparteko-logo-loader__loading-circle${this.loaded ? ' aparteko-logo-loader__loading-circle--active' : ''}`} />
      </div>
    );
  }
}
