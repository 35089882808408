import { Local } from 'common/src/lang/Local';
import { B2bLoginModel } from 'common/src/models/dialogs/login/B2bLoginModel';
import { observer } from 'mobx-react';
import React from 'react';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';

interface B2bLoginProps extends DialogProps {
  b2bLoginDialog?: B2bLoginModel;
}
@observer
export default class B2bLoginDialog extends BaseDialog<B2bLoginProps, {}> {
  isVisible(): boolean {
    const { b2bLoginDialog } = this.props;
    return b2bLoginDialog ? b2bLoginDialog.isVisible() : false;
  }

  renderVisible() {
    const { b2bLoginDialog } = this.props;
    return (
      <div className="b2b-login">
        <div className="b2b-login__inner">
          <DialogHeader onClose={() => {}}>{Local.getString('b2b-login-dialog.title')}</DialogHeader>
          <div className="b2b-login__button-box">
            <div className="b2b-login__container">
              <div className="b2b-login__description">Turnir:</div>
              <input type="text" id="tournament" className="b2b-login__input" disabled value={b2bLoginDialog!.tournament} />
            </div>
            <div className="b2b-login__container">
              <div className="b2b-login__description">Tim:</div>
              <input type="text" id="team" className="b2b-login__input" disabled value={b2bLoginDialog!.team} />
            </div>
            <div className="b2b-login__container">
              <div className="b2b-login__description">Ime*:</div>
              <input
                type="text"
                className="b2b-login__input"
                id="name"
                value={b2bLoginDialog!.name}
                onChange={e => {
                  b2bLoginDialog!.setName(e.target.value);
                }}
              />
            </div>
            <div className="b2b-login__container">
              <div className="b2b-login__description">Username*:</div>
              <input
                type="text"
                className="b2b-login__input"
                id="nickname"
                value={b2bLoginDialog!.id}
                onChange={e => {
                  b2bLoginDialog!.setId(e.target.value);
                }}
              />
            </div>
          </div>
          <button
            className="b2b-login__button"
            onClick={() => {
              if (b2bLoginDialog!.validate()) b2bLoginDialog!.submitAction.performAction();
            }}
          >
            {Local.getString('b2b-login-dialog.submit')}
          </button>
          <div className="b2b-login__description b2b-login__description--error">{b2bLoginDialog!.error}</div>
        </div>
      </div>
    );
  }
}
