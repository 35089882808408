import { types } from 'mobx-state-tree';
import { AnimationBuilderMST } from '../../../AnimationBuilder';
import { GameButtonMST, GameButtonType } from '../GameButtonModel';

export const AnswerOptionMST = GameButtonMST.named('AnswerMST')
  .props({
    id: 0,
    millisecondsElapsed: -1,
    opponentMillisElapsed: -1,
    bluePoints: 0,
    redPoints: 0,
    bluePlayerName: '',
    redPlayerName: '',
    rightAnswerAnimation: types.optional(AnimationBuilderMST, {}),
    bluePointsAnimation: types.optional(AnimationBuilderMST, {}),
    redPointsAnimation: types.optional(AnimationBuilderMST, {}),
    blueOverflowAnimation: types.optional(AnimationBuilderMST, {}),
    redOverflowAnimation: types.optional(AnimationBuilderMST, {})
  })
  .actions(self => ({
    setId(id: number) {
      self.id = id;
    },
    setAnswer(text: string) {
      self.setVisible(true);
      self.millisecondsElapsed = -1;
      self.opponentMillisElapsed = -1;
      self.type = GameButtonType.Regular;
      self.text = text;
    },
    setAnsweredByBoth(blueTime: number, redTime: number, b: string, r: string) {
      self.setBlueRed();
      self.millisecondsElapsed = blueTime;
      self.opponentMillisElapsed = redTime;
      self.bluePlayerName = b;
      self.redPlayerName = r;
    },
    setAnsweredByBlue(blueTime: number, b: string) {
      self.setBlue();
      self.millisecondsElapsed = blueTime;
      self.bluePlayerName = b;
    },
    setAnsweredByRed(redTime: number, r: string) {
      self.setRed();
      self.opponentMillisElapsed = redTime;
      self.redPlayerName = r;
    },
    setAnsweredByEngine() {
      self.setYellow();
    },
    setBluePoints(points: number) {
      self.bluePoints = points;
    },
    setRedPoints(points: number) {
      self.redPoints = points;
    }
  }));

type AnswerModelType = typeof AnswerOptionMST.Type;
export interface AnswerOptionModel extends AnswerModelType {}
export const createAnswerOption = (id: number) =>
  AnswerOptionMST.create({
    id,
    visible: true,
    text: '',
    type: GameButtonType.Regular
  });
