import AnimationAPI from 'common/src/animations/AnimationAPI';
import BaseAnimation from 'common/src/animations/BaseAnimation';
import GroupAnimation from 'common/src/animations/GroupAnimation';
import { TimelineLite, TweenLite } from 'gsap';
import GSAPAnimation from './GSAPAnimation';
import GSAPParallelAnimation from './GSAPParallelAnimation';
import GSAPSequenceAnimation from './GSAPSequenceAnimator';

export default class GSAPAnimationAPI implements AnimationAPI {
  createParallel(): GroupAnimation {
    return new GSAPParallelAnimation();
  }

  createSequence(): GroupAnimation {
    return new GSAPSequenceAnimation();
  }

  createPause(time: number): BaseAnimation {
    return new GSAPAnimation(new TweenLite({ dummy: 0 }, time, { dummy: 1 }));
  }
}
