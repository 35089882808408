import { observer } from 'mobx-react';
import React from 'react';
import { Local } from 'common/src/lang/Local';
import { DisplayModel } from 'common/src/models/DisplayModel';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';

interface TokensInfoProps extends DialogProps {
  tokensInfoDialog?: DisplayModel;
}
@observer
export default class TokensInfo extends BaseDialog<TokensInfoProps, {}> {
  isVisible(): boolean {
    const { tokensInfoDialog } = this.props;
    return tokensInfoDialog ? tokensInfoDialog.isVisible() : false;
  }

  renderVisible() {
    const { tokensInfoDialog } = this.props;

    return (
      <div className="tokens-info">
        <div className="tokens-info__inner">
          <DialogHeader onClose={() => tokensInfoDialog!.hide()}>{Local.getString('tokens_info.header')}</DialogHeader>
          <div className="tokens-info__description-box">
            <div className="tokens-info__description">{Local.getString('tokens_info.desc_1')}</div>
            <div className="tokens-info__description">{Local.getString('tokens_info.desc_2')}</div>
            <div className="tokens-info__description tokens-info__description--big">{Local.getString('tokens_info.desc_3')}</div>
            <div className="tokens-info__description tokens-info__description--email">{Local.getString('tokens_info.desc_4')}</div>
            <div className="tokens-info__description">{Local.getString('tokens_info.desc_5')}</div>
          </div>
        </div>
      </div>
    );
  }
}
