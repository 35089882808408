import { TimelineMax, TweenLite } from 'gsap';
import GSAPAnimation from '../../animations/base/GSAPAnimation';
import GSAPSequenceAnimation from '../../animations/base/GSAPSequenceAnimator';
import { TransitionIn } from '../../animations/samples/TransitionIn';
import { TransitionOut } from '../../animations/samples/TransitionOut';
import { VisibleComponent } from '../VisibleComponent';

export interface GameContentProps {}
export abstract class GameContent<T extends GameContentProps, S> extends VisibleComponent<T, S> {
  contentRef: HTMLDivElement | null = null;

  createEnteringAnimation = () => {
    const seq = new GSAPSequenceAnimation();
    if (this.contentRef !== null) {
      seq.add(new TransitionIn(this.contentRef, 0.3, 760, 0, 0, 0));
    }

    return seq;
  };

  createExitingAnimation = () => {
    const seq = new GSAPSequenceAnimation();
    if (this.contentRef !== null) {
      seq.add(new TransitionOut(this.contentRef, 0.3, 0, 0, -760, 0));
    }
    return seq;
  };

  createPreparingAnimation = () => {
    const seq = new GSAPSequenceAnimation();
    if (this.contentRef !== null) {
      seq.add(new GSAPAnimation(new TimelineMax().add(TweenLite.fromTo(this.contentRef, 0, { opacity: 1 }, { opacity: 0 }))));
    }
    return seq;
  };

  createPreparingAnimationNew() {
    const seq = new GSAPSequenceAnimation();
    if (this.contentRef !== null) {
      const anim = new TimelineMax({ paused: true }).fromTo(
        this.contentRef,
        0,
        {
          visibility: 'hidden'
        },
        {
          visibility: 'visible'
        }
      );

      seq.add(new GSAPAnimation(anim));
    }
    return seq;
  }
}
