import { Local } from '../lang/Local';

class Alphabet {
  private letterToCode: Map<String, Number> = new Map<String, Number>();

  private codeToLetter: Map<Number, string> = new Map<Number, string>();

  private size: number;

  constructor() {
    const letters: string[] = Local.getString('puzzle.alphabet').split(',');

    for (let i = 0; i < letters.length; i++) {
      this.letterToCode.set(letters[i], i);
      this.codeToLetter.set(i, letters[i]);
    }
    this.size = letters.length;
  }

  public getCode(letter: String): Number | undefined {
    return this.letterToCode.get(letter);
  }

  public getLetter(code: number): string {
    const letter = this.codeToLetter.get(code);
    return letter !== undefined ? letter : '?';
  }

  public getSize(): number {
    return this.size;
  }
}

export const ALPHABET = new Alphabet();
