import { types } from 'mobx-state-tree';
import { Sounds } from 'web/src/resources/SoundResources';
import { ShopConst } from '../../../controllers/player/subcontrollers/StorageController';
import { ActionMST } from '../../ActionModel';
import { AnimationBuilderMST } from '../../AnimationBuilder';
import { BaseMST } from '../../BaseModel';
import { APP } from '../../../app/AppInstance';
import { DEFAULT_VOL } from '../../../app/sound/SoundPlayer';

export const ShopItemMST = BaseMST.named('ShopItemModelMST')
  .props({
    id: types.number,
    groupId: types.number,
    offerId: types.number,
    price: types.number,
    currency: types.string,
    valueForPrice: 0,
    bestValue: false,
    newItem: false,
    selected: false,
    purchased: false,
    freeCard: false,
    itemAction: ActionMST,
    itemAnimation: AnimationBuilderMST,
    inProgress: false
  })
  .views(self => ({}))
  .actions(self => ({
    init(tokens: number) {},
    setBuyInProgress(value: boolean) {
      self.inProgress = value;
    },
    setPurchased() {
      self.purchased = true;
    },
    onSelected() {
      self.selected = true;
      if (self.groupId === ShopConst.Sounds && self.id >= 0 && self.id <= 7) {
        APP.SoundPlayer.playOneChanelledSoundEffect(self.id, DEFAULT_VOL);
      }
    },
    onUnselected() {
      self.selected = false;
    },
    onItemAction() {
      if (self.itemAction.getAction().call(self.itemAction.getAction, self.id, self.groupId, self.offerId)) {
        const anim = self.itemAnimation.build();
        anim.play();
      }
    }
  }));

type ShopItemModelType = typeof ShopItemMST.Type;
export interface ShopItemModel extends ShopItemModelType {}
export const createShopItemModel = (id: number, groupId: number, offerId: number, price: number, curr: string, valueForPrice: number, bestValue: boolean, newItem: boolean, purchased: boolean, freeCard: boolean) =>
  ShopItemMST.create({
    id,
    groupId,
    offerId,
    price,
    currency: curr,
    valueForPrice,
    bestValue,
    newItem,
    selected: false,
    purchased,
    freeCard,
    itemAction: {},
    itemAnimation: {}
  });
