import React, { Component, Fragment } from 'react';
import { SearchOpponentModel } from 'common/src/models/dialogs/searchopponent/SearchOpponentModel';
import Images from 'web/src/resources/ImgResources';
import { StartGameResponse } from 'common/src/messages/player/message/StartGameResponse';
import { observer, inject } from 'mobx-react';
import { RANK_VALUES } from 'common/src/resources/RankValues';
import { Local } from 'common/src/lang/Local';
import { RANK } from '../../../resources/RankResources';
import ProfilePicture from '../../game/ProfilePicture';

const OPPONENT_WAIT = 4;
const CURRENT_OPPONENT = 5;
const CURRENT_BOX = 6;

interface SearchOpponentCardProps {
  searchOpponent?: SearchOpponentModel;
  rating: number;
  rank: number;
  name: string;
  position: 'left' | 'right';
  div: Array<HTMLDivElement | null>;
  isMe: boolean;
}

@observer
export default class SearchOpponentCard extends Component<SearchOpponentCardProps> {
  divs: Array<HTMLDivElement | null> = [];

  wonColor = ['#CDF827', '#C8F523', '#C0F11F', '#B8ED19', '#AEE712', '#A3E00A', '#98D705', '#8FCC02', '#87C301', '#70A300'];

  componentDidMount() {}

  getResponseImage() {
    switch (this.props.isMe ? this.props.searchOpponent!.playerResponse : this.props.searchOpponent!.opponentResponse) {
      case StartGameResponse.ACCEPTED:
        return Images.acceptMatching;
      case StartGameResponse.DECLINED:
        return Images.declineMatching;
      default:
        return Images.notComfirmedMatching;
    }
  }

  getMarginLeftPercent(index: number) {
    return `${7 + 3 * index}rem`;
  }

  getToolTip() {
    if (this.props.isMe || this.props.searchOpponent!.opponentRating < 0) return <div />;

    return (
      <div className="search-opponent-card__tooltip" style={{ left: '4rem', top: '-6rem' }}>
        <h2 className="weekly-tournament__tooltip-name">{this.props.searchOpponent!.opponentName}</h2>
        <div className="weekly-tournament__content">
          <div>
            <img className="weekly-tournament__content-left" src={RANK.getBigImg(this.props.searchOpponent!.opponentRank)} />
          </div>
          <div className="weekly-tournament__content-right">
            <div className="weekly-tournament__egg-owl">{RANK_VALUES.getShortName(this.props.searchOpponent!.opponentRank)}</div>
            <div className="weekly-tournament__rating">{Local.getString('player_tooltip.rating', this.props.searchOpponent!.opponentRating)}</div>
          </div>
        </div>
        <div className="weekly-tournament__collected-feathers">{Local.getString('player_tooltip.feather', this.props.searchOpponent!.opponentFeathers)}</div>
        <h2 className="weekly-tournament__tooltip-name weekly-tournament__tooltip-name--small">{Local.getString('final_board.prev_rank')}</h2>
        <div className="weekly-tournament__content weekly-tournament__content--best-title">
          <div>
            <img className="weekly-tournament__content-left weekly-tournament__content-left--small" src={RANK.getBigImg(this.props.searchOpponent!.opponentMaxPrevRank)} />
          </div>
          <div className="weekly-tournament__content-right weekly-tournament__content-right--small">
            <div className="weekly-tournament__egg-owl">{RANK_VALUES.getShortName(this.props.searchOpponent!.opponentMaxPrevRank)}</div>
          </div>
        </div>
        <h2 className="weekly-tournament__tooltip-name weekly-tournament__tooltip-name--green">{Local.getString('player_tooltip.stat_title')}</h2>
        <div className="weekly-tournament__image">
          <div className="weekly-tournament__image--text">{this.props.searchOpponent!.opponentStats!.getWonPercentage()}</div>
        </div>
        {this.props.searchOpponent!.opponentStats!.gameStat.map((game, index) => (
          <div key={index} className="weekly-tournament__won-box" style={{ marginLeft: this.getMarginLeftPercent(index) }}>
            {[...Array(10)].map((number, j) => (
              <div key={j} className="weekly-tournament__won-box--game" style={{ backgroundColor: game.gameMark > j ? this.wonColor[j] : '' }} />
            ))}
          </div>
        ))}
      </div>
    );
  }

  opponentImageSelected(rating: number, position: string) {
    if (position === 'left') {
      return <ProfilePicture css="search-opponent-card__my-image" fbId={this.props.searchOpponent!.playerFbId} size={200} />;
    }
    if (this.props.searchOpponent && this.props.searchOpponent!.friendlyGame) return <ProfilePicture css="search-opponent-card__friendly-image" fbId={this.props.searchOpponent!.opponentFbId} size={200} />;
    return (
      <Fragment>
        <img src={Images.avatarP} className="search-opponent-card__scrolling_image" />
        <img src={Images.avatarL} className="search-opponent-card__scrolling_image" />
        <img src={Images.avatarM} className="search-opponent-card__scrolling_image" />
        <img src={Images.avatarO} className="search-opponent-card__scrolling_image" />
      </Fragment>
    );
  }

  render() {
    const { rating, rank, name, position } = this.props;

    return (
      <div className="search-opponent-card">
        {this.getToolTip()}

        <div
          className="search-opponent-card__inner"
          ref={div => {
            this.divs[CURRENT_BOX] = div;
          }}
        >
          <img className={`search-opponent-card__response_image search-opponent-card__response_image--${position}`} src={this.getResponseImage()} />

          <div className={`search-opponent-card__name search-opponent-card__name--${position}`}>{name}</div>
          <div
            className="search-opponent-card__current-image-holder"
            ref={img => {
              this.divs[CURRENT_OPPONENT] = img;
            }}
          >
            <ProfilePicture fbId={this.props.searchOpponent!.opponentFbId} css="search-opponent-card__current-image-holder--image" size={200} />
          </div>
          <div
            className={`search-opponent-card__player-image search-opponent-card__player-image--${position}`}
            ref={div => {
              this.divs[OPPONENT_WAIT] = div;
            }}
          >
            {this.opponentImageSelected(rating, position)}
          </div>
          {(this.props.isMe || this.props.searchOpponent!.opponentRating >= 0) && (
            <div className={`search-opponent-card__footer search-opponent-card__footer--${position}`}>
              <div className="search-opponent-card__title">
                <img src={RANK.getSmallImg(rank)} />
              </div>
              <div className={`search-opponent-card__rating search-opponent-card__rating--${position}`}>{rating}</div>
              <div className="search-opponent-card__star-image">&nbsp;</div>
            </div>
          )}
          <img className="search-opponent-card__hat" />
        </div>
      </div>
    );
  }
}
