import { APP } from '../../../app/AppInstance';
import { DEFAULT_VOL } from '../../../app/sound/SoundPlayer';
import { ServerEvent } from '../../../messages/communication/message/ServerEvent';
import { DailyReward } from '../../../messages/player/message/DailyReward';
import { DailyRewardsModel } from '../../../models/dialogs/dailyreward/DailyRewardsModel';
import { createNewAppInfoModel } from '../../../models/dialogs/info/ImportantInfoModel';
import { DisplayModelKeys } from '../../../models/DisplayModelKeys';
import { UserModel } from '../../../models/user/UserModel';
import { InstallFlow } from '../../../tracking/actions/InstallFlow';
import { DBReferralTracking } from '../../../tracking/userproperty/DBReferralTracking';
import { LoginTypeTracking } from '../../../tracking/userproperty/LoginTypeTracking';
import { Controller } from '../../base/Controller';
import { PlayerSubController } from '../PlayerSubController';

export class DailyLoginsController extends PlayerSubController {
  private dailyRewards: DailyRewardsModel;

  private userModel: UserModel;

  constructor(controller: Controller, dailyRewards: DailyRewardsModel, userModel: UserModel) {
    super(controller);
    this.dailyRewards = dailyRewards;
    this.userModel = userModel;
    this.dailyRewards.acceptRewards.setAction(() => {
      this.dailyRewards.acceptRewards.setEnabled(false);
      APP.SoundPlayer.playCashboxSound(DEFAULT_VOL);
    });
  }

  public onMessage(message: ServerEvent) {
    switch (message.getClass()) {
      case DailyReward.ClassName:
        this.onDailyReward(message as DailyReward);
        break;
      default:
        break;
    }
  }

  private onDailyReward(message: DailyReward) {
    this.dailyRewards!.setTodaysIndex(this.calculateDayForReward(this.userModel.info.loginInRows));
    APP.Tracker.trackEvent(new InstallFlow(InstallFlow.EV_DAILY_REWARD_DISPLAYED));
    this.trackUserProperties();

    this.dailyRewards!.schedule();
  }

  // TODO not used anymore, remove
  private scheduleNewAppInfoDialog() {
    if (!APP.Settings.isNewAppInfoDialogShown()) {
      const newAppDialog = createNewAppInfoModel();
      newAppDialog.okButton.action.setAction(() => {
        newAppDialog.hide();
      });
      newAppDialog.okButton.action.setEnabled(false);
      newAppDialog!.schedule(
        () => {},
        () => {
          setTimeout(() => {
            APP.Settings.setNewAppInfoDialogShown();
            newAppDialog!.showButtonAnimation.build().play();
          }, 5000);
        }
      );
    }
  }

  private calculateDayForReward(loginsInRow: number) {
    return (loginsInRow - 1) % 5;
  }

  private trackUserProperties() {
    const userId = this.userModel.info.id.toString();

    APP.Tracker.trackUserProperty(new DBReferralTracking(userId, this.userModel.info.referral));
    // APP.Tracker.trackUserProperty(
    //   new MonetizerTypeTracking(userId, this.userModel.info.monetizerType)
    // );
    // APP.Tracker.trackUserProperty(
    //   new PredictedMonetizerTypeTracking(userId, this.userModel.info.monetizerType)
    // );
    // APP.Tracker.trackUserProperty(
    //   new ReengagementTracking(userId, this.userModel.info.reengageIteration.toString())
    // );
    APP.Tracker.trackUserProperty(new LoginTypeTracking(userId));
  }
}
