import i18n from './i18n';

export class Local {
  public static getString(key: string, ...parameters: any[]): string {
    return this.injectParams(i18n.t(key), parameters);
  }

  private static injectParams(str: string, params: any[]): string {
    let i = 0;
    let ret = str;
    params.forEach((p: any) => {
      ret = ret.replace(`$${i}`, () => p);
      i++;
    });
    return ret;
  }
}
