import i18n from 'i18next';

const en = require('./en.json');
const sr = require('./sr.json');

i18n.init({
  debug: false,

  lng: 'sr',
  resources: {
    en: {
      translation: en
    },
    sr: {
      translation: sr
    }
  },
  react: {
    wait: true,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  }
});

export default i18n;
