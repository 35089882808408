import { MessageSerializable } from '../../../MessageSerializable';
import { ListPositionMaximum } from './ListPositionMaximum';

const BINDING_NAME = 'rs.slagalica.player.league.message.ListPosition';

export class ListPosition extends MessageSerializable {
  total: number = 0;

  // fieldType: int
  maximums?: Array<ListPositionMaximum> = undefined; // fieldType: ArrayList

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
