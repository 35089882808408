import { Local } from 'common/src/lang/Local';
import { B2bGame } from 'common/src/messages/b2b/B2bGame';
import { B2bGameModel } from 'common/src/models/b2b/B2bGameModel';
import { B2bTournamentModel } from 'common/src/models/b2b/B2bTournamentModel';
import { UserModel } from 'common/src/models/user/UserModel';
import { autorun, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

interface B2bCountdownProps {
  b2bTournament?: B2bTournamentModel;
  user?: UserModel;
  isVisible?: boolean;
}

@inject('b2bTournament', 'user')
@observer
export default class B2bCountdown extends Component<B2bCountdownProps> {
  private timer: any = null;

  @observable time = {
    h: 0,
    m: 0,
    s: 0
  };

  @observable timeToCountdown: number = 0;

  @observable title: string = '';

  constructor(props: any) {
    super(props);
    this.countDown = this.countDown.bind(this);

    autorun(() => {
      if (this.timer === null) {
        this.calculateTimeToCountdown();
        this.timer = setInterval(this.countDown, 1000);
      }
    });
  }

  getFirstGameTime(games: Array<B2bGameModel>): number {
    const { user } = this.props;
    const gameCurrent = games.find(game => game.gameStatus === B2bGame.GAME_NOT_STARTED && (user!.info.b2bTeam === game.blueTeamId || user!.info.b2bTeam === game.redTeamId));
    return gameCurrent ? gameCurrent.startTime.getTime() : 0;
  }

  getSecondsLeft() {
    return new Date().getTime() / 1000 - this.props.b2bTournament!.startTime.getTime() / 1000;
  }

  getTimeLeftLbl() {
    if (this.time.h === 0 && this.time.m === 0 && this.time.s === 0) return 'krećemo...';

    const hours = this.time.h >= 10 ? `${this.time.h}` : `0${this.time.h}`;
    let mins = this.time.m >= 10 ? `${this.time.m}` : `0${this.time.m}`;
    let secs = this.time.s >= 10 ? `${this.time.s}` : `0${this.time.s}`;

    if (mins === '60') mins = '00';
    if (secs === '60') secs = '00';

    return `${hours}:${mins}:${secs}`;
  }

  countDown() {
    if (!this.props.isVisible) {
      clearInterval(this.timer);
      return;
    }

    if (this.time.h === 0 && this.time.m === 0 && this.time.s === 0) this.calculateTimeToCountdown();
    const seconds = this.timeToCountdown / 1000 - new Date().getTime() / 1000;
    this.time = this.secondsToTime(seconds < 0 ? 0 : seconds);
  }

  secondsToTime(secs: number) {
    const hours = Math.floor(secs / (60 * 60));

    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    const obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  }

  calculateTimeToCountdown() {
    if (this.props.b2bTournament!.active === 0) {
      this.timeToCountdown = this.props.b2bTournament!.startTime.getTime();
      this.title = 'liga startuje za:';
    } else if (this.props.b2bTournament!.active === 1) {
      this.timeToCountdown = this.getFirstGameTime(this.props.b2bTournament!.games);
      if (this.timeToCountdown > 0) {
        this.title = 'Igra startuje za:';
      } else this.title = 'Gotove su sve igre';
    } else {
      this.timeToCountdown = 0;
      this.title = 'Liga je istekla';
    }
  }

  render() {
    return (
      <div className="b2b-countdown">
        <div className="b2b-countdown__header">{Local.getString('mainroom.b2b_next_game')}</div>
        <div className="b2b-countdown__title">{this.title}</div>
        {this.timeToCountdown > 0 && <div className="b2b-countdown__time">{this.getTimeLeftLbl()}</div>}
      </div>
    );
  }
}
