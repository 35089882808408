import { B2bAdminCommandAction } from './B2bAdminCommandAction';

const BINDING_NAME = 'rs.slagalica.b2b.message.B2bPerformDrawAction';

export class B2bPerformDrawAction extends B2bAdminCommandAction {
  tournamentId?: string;

  constructor(adminId: string) {
    super(adminId);
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
