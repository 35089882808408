import React from 'react';
import { Local } from 'common/src/lang/Local';
import { SurveyDialogModel } from 'common/src/models/dialogs/survey/SurveyDialogModel';
import { DialogProps, BaseDialog } from '../BaseDialog';
import DialogHeader from '../DialogHeader';

interface SurveyDialogProps extends DialogProps {
  surveyDialog: SurveyDialogModel;
}

export default class SurveyDialog extends BaseDialog<SurveyDialogProps, {}> {
  renderVisible() {
    const { surveyDialog } = this.props;

    return (
      <div className="survey">
        <div className="survey__inner">
          <DialogHeader onClose={() => surveyDialog!.hide()}>{Local.getString('survey_dlg.title')}</DialogHeader>
          <div className="survey__image" />

          <div className="survey__description">{Local.getString('survey_dlg.description', surveyDialog!.tokensForReward, surveyDialog!.expectedTime)}</div>
          <div className="survey__additional-text">{surveyDialog!.additionalText}</div>
          <button className="survey__accept" onClick={() => surveyDialog!.openSurveyAction.performAction()}>
            {Local.getString('survey_dlg.fill')}
          </button>
        </div>
      </div>
    );
  }

  isVisible(): boolean {
    if (this.props.surveyDialog) return this.props.surveyDialog!.isVisible();

    return false;
  }
}
