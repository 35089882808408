import { types } from 'mobx-state-tree';
import { AnimationBuilderMST } from '../../../AnimationBuilder';
import { ButtonMST, createConfirmPuzzleButton, createStopPuzzleButton } from '../../../ButtonModel';
import { createDarkButtonWithText, createRegularButton, GameButtonModel, GameButtonMST } from '../GameButtonModel';
import { GameRiddleContentMST } from '../GameRiddleContent';
import { MyNumberUtils } from './MyNumberUtils';
import { createMySolutionModel, MySolutionMST } from './MySolutionMST';
import { createOpponentsSolutionModel, OpponentsSolutionMST } from './OpponentsSolutionModel';

export const MyNumberMST = GameRiddleContentMST.named('MyNumberMST')
  .props({
    mySolution: types.optional(MySolutionMST, {}),
    opponentsSolution: types.optional(OpponentsSolutionMST, {}),
    targetNumber: 0,
    numberBtns: types.array(GameButtonMST),
    operationBtns: types.array(GameButtonMST),
    confirmBtn: types.optional(ButtonMST, {}),
    eraseBtn: types.optional(ButtonMST, { action: { enabled: false } }),
    stopBtn: types.optional(ButtonMST, {}),
    choosingNumbersAnimation: types.optional(AnimationBuilderMST, {}),
    showOpponentSolutionAnimation: types.optional(AnimationBuilderMST, {}),
    hideBtnsAnimation: types.optional(AnimationBuilderMST, {}),
    shufflingAnimation: types.optional(AnimationBuilderMST, {}),
    myUrl: types.string,
    opponentUrl: types.string
  })
  .actions(self => {
    const currentSolutionBtns: Array<GameButtonModel> = [];

    function addItemToSolution(btn: GameButtonModel) {
      currentSolutionBtns.push(btn);
    }

    function removeItemFromSolution(): GameButtonModel | undefined {
      return currentSolutionBtns.pop();
    }

    function getCurrentSolutionBtns(): Array<GameButtonModel> {
      return currentSolutionBtns;
    }

    function clearCurrentSolution() {
      while (currentSolutionBtns.length > 0) currentSolutionBtns.pop();
    }

    return { addItemToSolution, removeItemFromSolution, getCurrentSolutionBtns, clearCurrentSolution };
  })
  .actions(self => ({
    setBtnActions(confirmAction: Function, stopAction: Function) {
      self.confirmBtn.action.setAction(() => confirmAction(self.mySolution.solution));
      self.confirmBtn.action.setEnabled(false);
      self.confirmBtn.setVisible(false);
      self.stopBtn.action.setAction(() => stopAction());

      self.numberBtns.forEach((btn: GameButtonModel) => {
        btn.action.setAction(() => {
          if (!MyNumberUtils.isValueAcceptable(btn.getText(), self.mySolution.solution)) {
            // beep
            return;
          }
          btn.setEnabled(false);
          self.addItemToSolution(btn);
          this.refreshCurrentSolution();
        });
      });

      self.operationBtns.forEach((btn: GameButtonModel) => {
        btn.action.setAction(() => {
          let text: string = btn.getText();
          if (text === 'x') text = '*';

          if (!MyNumberUtils.isValueAcceptable(text, self.mySolution.solution)) {
            // beep
            return;
          }

          self.addItemToSolution(btn);
          this.refreshCurrentSolution();
        });
      });

      self.eraseBtn.action.setAction(() => {
        this.removeLastItem();
      });
    },
    removeLastItem() {
      if (self.getCurrentSolutionBtns().length < 1) return;

      const removedBtn = self.removeItemFromSolution();
      if (removedBtn) {
        removedBtn.setEnabled(true);
        this.refreshCurrentSolution();
      }
    },
    enableBtns() {
      self.numberBtns.forEach((btn: GameButtonModel) => {
        btn.setEnabled(true);
      });
      self.operationBtns.forEach((btn: GameButtonModel) => {
        btn.setEnabled(true);
      });
    },
    disableBtns() {
      self.numberBtns.forEach((btn: GameButtonModel) => {
        btn.setEnabled(false);
      });
      self.operationBtns.forEach((btn: GameButtonModel) => {
        btn.setEnabled(false);
      });
    },
    refreshCurrentSolution() {
      let textValue = '';

      for (let i = 0; i < self.getCurrentSolutionBtns().length; i++) {
        let btnText = self.getCurrentSolutionBtns()[i].getText();
        if (btnText === 'x') btnText = '*';
        textValue += btnText;
      }

      self.mySolution.solution = textValue;
      const enableButtons: boolean = textValue !== '';

      self.confirmBtn.setEnabled(enableButtons);
      self.eraseBtn.setEnabled(enableButtons);
    },
    setOperationBtns() {
      self.operationBtns[0].setText('+');
      self.operationBtns[1].setText('-');
      self.operationBtns[2].setText('x');
      self.operationBtns[3].setText('/');
      self.operationBtns[4].setText('(');
      self.operationBtns[5].setText(')');
    },
    setNumbers(digit1: number, digit2: number, digit3: number, digit4: number, offer1: number, offer2: number) {
      self.numberBtns[0].setText(`${digit1}`);
      self.numberBtns[1].setText(`${digit2}`);
      self.numberBtns[2].setText(`${digit3}`);
      self.numberBtns[3].setText(`${digit4}`);
      self.numberBtns[4].setText(`${offer1}`);
      self.numberBtns[5].setText(`${offer2}`);
    },
    setTargetNumber(target: number) {
      self.targetNumber = target;
    },
    setOpponentFinished() {
      self.opponentsSolution.setSolving(false);
    },
    setOpponentSolution(solution: string | undefined) {
      let correctedSolution = solution || '';
      correctedSolution = correctedSolution.replace('x', '*');
      self.opponentsSolution.solution = correctedSolution;
    },
    setMySolution(solution: string | undefined) {
      let correctedSolution = solution || '';
      correctedSolution = correctedSolution.replace('x', '*');
      self.mySolution.solution = correctedSolution;
    },
    resetModel() {
      self.setVisible(false);
      self.mySolution.reset();
      self.clearCurrentSolution();
      self.opponentsSolution.reset(); // = createOpponentsSolutionModel();
      self.targetNumber = 0;
      self.confirmBtn = createConfirmPuzzleButton();
      self.eraseBtn.setVisible(true);
      self.numberBtns.clear();
      for (let i = 0; i < 6; i++) {
        self.numberBtns.push(createRegularButton());
      }
    },
    setMyURL(url: string) {
      self.myUrl = url;
    },
    setOpponentURL(url: string) {
      self.opponentUrl = url;
    }
  }))
  .views(self => ({}));

type MyNumberModelType = typeof MyNumberMST.Type;
export interface MyNumberModel extends MyNumberModelType {}

const dummy = {
  name: 'MyNumber',
  visible: false,
  mySolution: createMySolutionModel(),
  opponentsSolution: createOpponentsSolutionModel(),
  targetNumber: 0,
  confirmBtn: createConfirmPuzzleButton(),
  stopBtn: createStopPuzzleButton(),
  numberBtns: [createRegularButton(), createRegularButton(), createRegularButton(), createRegularButton(), createRegularButton(), createRegularButton()],
  operationBtns: [createDarkButtonWithText('+'), createDarkButtonWithText('-'), createDarkButtonWithText('x'), createDarkButtonWithText('/'), createDarkButtonWithText('('), createDarkButtonWithText(')')],
  choosingNumbersAnimation: {},
  showOpponentNumberAnimation: {},
  showOpponentSolutionAnimation: {},
  hideBtnsAnimation: {},
  shufflingAnimation: {},
  myUrl: '',
  opponentUrl: ''
};

export const createMyNumber = () => MyNumberMST.create(dummy);

export const createMyNumberDummy = () => MyNumberMST.create(dummy);
