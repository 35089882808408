import { MessageSerializable } from '../../MessageSerializable';

const BINDING_NAME = 'rs.slagalica.games.puzzle.Puzzle';

export class Puzzle extends MessageSerializable {
  lotteredWordsString?: string = undefined;

  lotteredWord?: Array<number> = undefined;

  letters?: Array<number> = undefined;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
