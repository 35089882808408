import { PlayerAction } from '../../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.player.message.PlayNowRandom';

export class PlayNowRandom extends PlayerAction {
  fairPlay: boolean = true; // fieldType: boolean

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
