import { PlayerAction } from './PlayerAction';

const BINDING_NAME = 'rs.slagalica.communication.message.GcmRegistrationInfo';

export class GcmRegistrationInfo extends PlayerAction {
  regId?: string = undefined; // fieldType: String

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
