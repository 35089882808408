import { Local } from 'common/src/lang/Local';
import { B2bCaptainModel } from 'common/src/models/dialogs/b2bgame/B2bCaptainModel';
import { Power0 } from 'gsap';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { GSAPPause } from '../../../../animations/base/GSAPPause';
import GSAPSequenceAnimation from '../../../../animations/base/GSAPSequenceAnimator';
import { MoveIn } from '../../../../animations/samples/MoveIn';
import { MoveOut } from '../../../../animations/samples/MoveOut';
import { GameContent, GameContentProps } from '../../GameContent';
import { GameStatus } from '../../GameStatus';

interface B2bCaptainProps extends GameContentProps {
  captain?: B2bCaptainModel;
}
@inject('captain')
@observer
export default class B2bCaptain extends GameContent<B2bCaptainProps, {}> {
  signUpDiv: HTMLDivElement | null = null;

  captainChosen: HTMLDivElement | null = null;

  createSignUpAnimation = () => {
    const { captain } = this.props;
    const seq = new GSAPSequenceAnimation();
    if (this.signUpDiv === null) return new GSAPPause(0.05);
    seq.add(new MoveOut(this.signUpDiv, 0.2, 0, 0, -300, 0));
    seq.add(new MoveIn(this.captainChosen, 0.2, 0, 0, -450, 0, Power0.easeInOut));
    return seq;
  };

  createAnimations() {
    const { captain } = this.props;
    captain!.signUpCaptainAnimation.set(this.createSignUpAnimation);
    captain!.enteringAnimation.set(this.createEnteringAnimation);
    captain!.exitingAnimation.set(this.createExitingAnimation);
    captain!.preparingAnimation.set(this.createPreparingAnimation);
  }

  destroyAnimations() {
    const { captain } = this.props;
    captain!.signUpCaptainAnimation.dispose();
    captain!.enteringAnimation.dispose();
    captain!.exitingAnimation.dispose();
    captain!.preparingAnimation.dispose();
  }

  componentDidMount() {
    this.createAnimations();
  }

  isVisible(): boolean {
    const { captain } = this.props;
    return captain ? captain.isVisible() : false;
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  renderVisible(): React.ReactNode {
    const { captain } = this.props;
    const t = (message: string) => Local.getString(message);

    if (!captain) {
      return null;
    }

    return (
      <div
        ref={contentRef => {
          this.contentRef = contentRef;
        }}
      >
        <GameStatus />
        <div className="b2b-captain__background">
          <div
            ref={div => {
              this.signUpDiv = div;
            }}
          >
            <div className="b2b-captain__description">{t('game.captain_game') + captain!.gameName}</div>
            <div className="b2b-captain__description">{t('game.captain_desc1')}</div>
            <div className="b2b-captain__description">{t('game.captain_desc2')}</div>
            <button
              className="b2b-captain__sign-up"
              style={{
                visibility: captain!.claimCaptainBtn.isEnabled() ? 'visible' : 'hidden'
              }}
              onClick={() => {
                captain!.claimCaptainBtn.action.performAction();
                captain!.claimCaptainBtn.setEnabled(false);
              }}
            >
              {Local.getString('b2b-captain.YES')}
            </button>
          </div>
          <div
            className="b2b-captain__captain-chosen"
            ref={div => {
              this.captainChosen = div;
            }}
          >
            <div className="b2b-captain__description">{t('game.captain_desc3')}</div>
            <div className="b2b-captain__description">{captain.captainName || captain.captain}</div>
          </div>
        </div>
      </div>
    );
  }
}
