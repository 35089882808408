import { PointsUpdate } from '../../../communication/message/PointsUpdate';

const BINDING_NAME = 'rs.slagalica.games.combinations.message.CombinationResult';

export class CombinationResult extends PointsUpdate {
  userCombination?: Array<number>;

  index: number = 0;

  hitCount: number = 0;

  hitOnPlaceCount: number = 0;

  constructor() {
    super();

    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  public isResolved(): Boolean {
    return this.hitOnPlaceCount === 4;
  }

  public toString(): String {
    return `UserComb: ${this.userCombination} Index: ${this.index} hitcount: ${this.hitCount} hitOnPlaceCount: ${this.hitOnPlaceCount}`;
  }
}
