import { types } from 'mobx-state-tree';
import { AnimationBuilderMST } from '../../../AnimationBuilder';
import { GameRiddleContentMST } from '../GameRiddleContent';
import { createLeftMatchField, createRightMatchField, MatchingFieldMST } from './MatchingFieldModel';

export const MatchingMST = GameRiddleContentMST.named('MatchingMST')
  .props({
    current: 0,
    currentAnswer: 0,
    answersCnt: 0,
    opponentAnswersCnt: 0,
    columnA: types.array(MatchingFieldMST),
    columnB: types.array(MatchingFieldMST),
    mappings: types.array(types.number),
    resultAnimation: types.optional(AnimationBuilderMST, {}),
    resetResultAnimation: types.optional(AnimationBuilderMST, {}),
    question: ''
  })
  .actions(self => ({
    init(onAnswer: Function) {
      for (let i = 0; i < self.columnB.length; i++) {
        self.columnB[i].action.setAction(() => {
          self.columnB[i].setYellow();
          onAnswer(self.current, i);
        });
      }
    },
    setQuestion(question: string) {
      self.question = question;
    },
    setCurrentAnswer(answer: number) {
      self.currentAnswer = answer;
    },
    setMappings(mappings: Array<number>) {
      self.mappings.clear();
      mappings.forEach((value: number) => {
        self.mappings.push(value);
      });
    },
    resetAllTemporarlyMarkedBs() {
      self.columnB.forEach(element => {
        if (!element.isBlue() && !element.isRed()) element.setRegular();
      });
    },
    setCurrentQuestion(index: number) {
      this.clearPreviousQuestion();
      if (self.current >= 0 && !self.columnA[index].isBlue()) self.columnA[index].setYellow();
      self.current = index;
    },
    setAnswersCount(count: number) {
      self.answersCnt = count;
    },
    setOpponentAnswerCount(count: number) {
      self.opponentAnswersCnt = count;
    },
    markAsBlueMatch(indexA: number, indexB: number) {
      self.columnA[indexA].setBlue();
      self.columnB[indexB].setBlue();
      self.columnB[indexB].setEnabled(false);
    },
    markAsRedMatch(indexA: number, indexB: number) {
      self.columnA[indexA].setRed();
      self.columnB[indexB].setRed();
      self.columnB[indexB].setEnabled(false);
    },
    markAsYellow(indexB: number) {
      self.columnB[indexB].setYellow();
    },
    clearPreviousQuestion() {
      if (self.current >= 0 && !(self.columnA[self.current].isBlue() || self.columnA[self.current].isRed())) self.columnA[self.current].setDarkBlue();
    },
    resetModel() {
      self.setVisible(false);
      self.columnA.forEach(element => {
        element.setDarkBlue();
      });
      self.columnB.forEach(element => {
        element.setRegular();
        element.setEnabled(true);
      });
      self.mappings.clear();
    }
  }));

type MatchingModelType = typeof MatchingMST.Type;
export interface MatchingModel extends MatchingModelType {}

export const createMatchingModel = () =>
  MatchingMST.create({
    name: 'Matching',
    visible: false,
    columnA: [createLeftMatchField(0), createLeftMatchField(1), createLeftMatchField(2), createLeftMatchField(3), createLeftMatchField(4), createLeftMatchField(5), createLeftMatchField(6), createLeftMatchField(7)],
    columnB: [createRightMatchField(0), createRightMatchField(1), createRightMatchField(2), createRightMatchField(3), createRightMatchField(4), createRightMatchField(5), createRightMatchField(6), createRightMatchField(7)],
    mappings: [3, 2, 1, 0, 7, 6, 5, 4]
  });
