import { MessageSerializable } from '../../MessageSerializable';

const BINDING_NAME = 'rs.slagalica.player.store.Offer';

export class Offer extends MessageSerializable {
  id: number = 0;

  // fieldType: int
  count: number = 0;

  // fieldType: int
  cost: number = 0; // fieldType: int

  constructor(id: number = 0, count: number = 0, cost: number = 0) {
    super();
    this.__class = BINDING_NAME;
    this.id = id;
    this.count = count;
    this.cost = cost;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static getDefaultOffer(cost: number): Offer {
    return new Offer(-1, 1, cost);
  }
}
