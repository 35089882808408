import { observer } from 'mobx-react';
import React from 'react';
import ProfilePicture from '../ProfilePicture';
import SemaphorePlayer from './SemaphorePlayer';

const Img = require('react-image');

@observer
export default class SemaphoreBluePlayer extends SemaphorePlayer {
  render() {
    const { player, hats } = this.props;
    return (
      <div className="semaphore-blue-player">
        <div className="semaphore-blue-player__player--box">
          <ProfilePicture fbId={player.fbId} b2bUrl={player.b2bUrl} css="semaphore-blue-player__player" size={100} />
          {!player.isMe && player.offline && <div className="semaphore-blue-player__offline" />}
          {/* {this.getToolTip(0)} */}
        </div>

        <div
          className="semaphore-blue-player__score"
          ref={points => {
            this.pointsRef = points;
          }}
        >
          {player.points}
        </div>
        {player.name && <div className="semaphore-blue-player__name">{player.name}</div>}
        <div className="semaphore-blue-player__short-name">{player.nameShort}</div>
      </div>
    );
  }
}
