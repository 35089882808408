import { types } from 'mobx-state-tree';
import { VisibleMST } from '../../../VisableModel';
import { AnimationBuilderMST } from '../../../AnimationBuilder';

export const ComputerWordMST = VisibleMST.named('ComputerWordMST')
  .props({
    letters: types.array(types.number)
  })
  .views(() => ({}))
  .actions(self => ({
    reset() {
      self.visible = false;
      self.letters.clear();
    }
  }));

type ComputerWordModelType = typeof ComputerWordMST.Type;
export interface ComputerWordModel extends ComputerWordModelType {}

export const createComputerWordModel = () => ComputerWordMST.create({ visible: false, letters: [] });
