import { AnswerOptionModel } from 'common/src/models/game/content/quiz/AnswerOptionModel';
import { TimelineMax } from 'gsap';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import GSAPAnimation from '../../../../animations/base/GSAPAnimation';
import GSAPParallelAnimation from '../../../../animations/base/GSAPParallelAnimation';
import GSAPSequenceAnimation from '../../../../animations/base/GSAPSequenceAnimator';
import { Glow3D } from '../../../../animations/samples/Glow3D';
import { PointsAnimation } from '../../../../animations/samples/PointsAnimation';
import GameButton from '../../GameButton';
import Point from '../../Point';

function createOverflowAnimation(div: HTMLElement, directionRight: boolean) {
  const anim = new TimelineMax({ paused: true }).fromTo(
    div,
    0.6,
    {
      backgroundImage: `linear-gradient(90deg, #01a2f1, 50%, #f1483b 50%)`
    },
    {
      backgroundImage: `linear-gradient(90deg, #01a2f1, ${directionRight ? '100%' : '0%'}, #f1483b ${directionRight ? '100%' : '0%'})`
    }
  );
  anim.eventCallback('onComplete', () => {
    if (div) div.style.removeProperty('background-image');
  });
  return anim;
}

interface OptionQuestionButtonProps {
  answerOption: AnswerOptionModel;
}

@observer
export default class OptionQuestionButton extends Component<OptionQuestionButtonProps, {}> {
  gameButton: GameButton | null = null;

  div: HTMLDivElement | null = null;

  bluePoint: Point | null = null;

  redPoint: Point | null = null;

  componentDidMount() {
    this.createAnimations();
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  createRightAnswerAnimation = () => new GSAPParallelAnimation().add(new Glow3D(this.gameButton!.boxRef, 1, 3)).add(new Glow3D(this.div, 1, 3));

  createBluePointsAnimation = () => PointsAnimation.create(true, this.bluePoint);

  createRedPointsAnimation = () => PointsAnimation.create(false, this.redPoint);

  createRedOverflowAnimation = () => {
    if (this.gameButton && this.gameButton.buttonRef) return new GSAPAnimation(createOverflowAnimation(this.gameButton!.buttonRef!, false));

    return new GSAPSequenceAnimation();
  };

  createBlueOverflowAnimation = () => {
    if (this.gameButton && this.gameButton.buttonRef) return new GSAPAnimation(createOverflowAnimation(this.gameButton!.buttonRef!, true));

    return new GSAPSequenceAnimation();
  };

  createAnimations() {
    this.props.answerOption!.rightAnswerAnimation.set(this.createRightAnswerAnimation);
    this.props.answerOption!.bluePointsAnimation.set(this.createBluePointsAnimation);
    this.props.answerOption!.redPointsAnimation.set(this.createRedPointsAnimation);
    this.props.answerOption!.blueOverflowAnimation.set(this.createBlueOverflowAnimation);
    this.props.answerOption!.redOverflowAnimation.set(this.createRedOverflowAnimation);
  }

  destroyAnimations() {
    if (this.props.answerOption && this.props.answerOption.rightAnswerAnimation && this.props.answerOption.bluePointsAnimation && this.props.answerOption.redPointsAnimation && this.props.answerOption.redOverflowAnimation && this.props.answerOption.blueOverflowAnimation) {
      this.props.answerOption!.rightAnswerAnimation.dispose();
      this.props.answerOption!.bluePointsAnimation.dispose();
      this.props.answerOption!.redPointsAnimation.dispose();
      this.props.answerOption!.blueOverflowAnimation.dispose();
      this.props.answerOption!.redOverflowAnimation.dispose();
    }
  }

  isVisible(): boolean {
    if (this.props.answerOption) return this.props.answerOption.isVisible();
    return false;
  }

  render() {
    const { answerOption } = this.props;
    if (!answerOption) {
      return null;
    }

    return (
      <div className="quiz__answer-box">
        {answerOption!.millisecondsElapsed > -1 && (
          <div className="quiz__time-box quiz__time-box--my-time">
            <div
              className="quiz__time-text"
              ref={div => {
                this.div = div;
              }}
            >
              {`${(answerOption!.millisecondsElapsed / 1000).toFixed(2)}| ${answerOption!.bluePlayerName}`}
            </div>
          </div>
        )}
        {answerOption!.opponentMillisElapsed > -1 && (
          <div className="quiz__time-box quiz__time-box--opponent-time">
            <div className="quiz__time-text">{`${answerOption!.redPlayerName} |${(answerOption!.opponentMillisElapsed / 1000).toFixed(2)}`}</div>
          </div>
        )}
        <Point
          baseClass="quiz__point quiz__point--my-point"
          value={this.props.answerOption.bluePoints}
          ref={point => {
            this.bluePoint = point;
          }}
        />
        <GameButton
          key={answerOption.id}
          model={answerOption}
          boxClass="quiz__option-button-box"
          baseClass="quiz__option-button"
          ref={gameButton => {
            this.gameButton = gameButton;
          }}
        />
        <Point
          baseClass="quiz__point quiz__point--opponent-point"
          value={this.props.answerOption.redPoints}
          ref={point => {
            this.redPoint = point;
          }}
        />
      </div>
    );
  }
}
