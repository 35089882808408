import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import CallObjectContext from '../../../CallObjectContext';
import Tile from '../Tile/Tile';
import Tray from '../Tray/Tray';
import './Call.css';
import { callReducer, CAM_OR_MIC_ERROR, CLICK_ALLOW_TIMEOUT, FATAL_ERROR, initialCallState, isLocal, PARTICIPANTS_CHANGE } from './callState';
import { logDailyEvent } from './utils/logUtils';

export default function Call(props) {
  const callObject = useContext(CallObjectContext);
  const [callState, dispatch] = useReducer(callReducer, initialCallState);

  /**
   * Start listening for participant changes, when the callObject is set.
   */
  useEffect(() => {
    if (!callObject) return;

    const events = ['participant-joined', 'participant-updated', 'participant-left'];

    function handleNewParticipantsState(event) {
      event && logDailyEvent(event);
      dispatch({
        type: PARTICIPANTS_CHANGE,
        participants: callObject.participants()
      });
    }

    // Use initial state
    handleNewParticipantsState();

    // Listen for changes in state
    for (const event of events) {
      callObject.on(event, handleNewParticipantsState);
    }

    // Stop listening for changes in state
    return function cleanup() {
      for (const event of events) {
        callObject.off(event, handleNewParticipantsState);
      }
    };
  }, [callObject]);

  /**
   * Start listening for call errors, when the callObject is set.
   */
  useEffect(() => {
    if (!callObject) return;

    function handleCameraErrorEvent(event) {
      logDailyEvent(event);
      dispatch({
        type: CAM_OR_MIC_ERROR,
        message: (event && event.errorMsg && event.errorMsg.errorMsg) || 'Unknown'
      });
    }

    // We're making an assumption here: there is no camera error when callObject
    // is first assigned.

    callObject.on('camera-error', handleCameraErrorEvent);
    callObject.on('camera-error', props.onCamMicError);
    callObject.on('started-camera', props.onCameraOkEvent);

    return function cleanup() {
      callObject.off('camera-error', handleCameraErrorEvent);
      callObject.off('camera-error', props.onCamMicError);
      callObject.off('started-camera', props.onCameraOkEvent);
    };
  }, [callObject]);

  /**
   * Start listening for fatal errors, when the callObject is set.
   */
  useEffect(() => {
    if (!callObject) return;

    function handleErrorEvent(e) {
      logDailyEvent(e);
      dispatch({
        type: FATAL_ERROR,
        message: (e && e.errorMsg) || 'Unknown'
      });
    }

    // We're making an assumption here: there is no error when callObject is
    // first assigned.

    callObject.on('error', handleErrorEvent);

    return function cleanup() {
      callObject.off('error', handleErrorEvent);
    };
  }, [callObject]);

  /**
   * Start a timer to show the "click allow" message, when the component mounts.
   */
  useEffect(() => {
    const t = setTimeout(() => {
      dispatch({ type: CLICK_ALLOW_TIMEOUT });
    }, 2500);

    return function cleanup() {
      clearTimeout(t);
    };
  }, []);

  /**
   * Send an app message to the remote participant whose tile was clicked on.
   */
  const sendHello = useCallback(
    participantId => {
      callObject && callObject.sendAppMessage({ hello: 'world' }, participantId);
    },
    [callObject]
  );

  useEffect(() => {
    if (!callObject) return;

    const events = ['joined-meeting', 'left-meeting', 'error'];

    function handleNewMeetingState(event) {
      event && logDailyEvent(event);
      switch (callObject.meetingState()) {
        case 'joined-meeting':
          // props.onJoinedSuccessfully();
          break;
        default:
          break;
      }
    }

    // Use initial state
    handleNewMeetingState();

    // Listen for changes in state
    for (const event of events) {
      callObject.on(event, handleNewMeetingState);
    }

    // Stop listening for changes in state
    return function cleanup() {
      for (const event of events) {
        callObject.off(event, handleNewMeetingState);
      }
    };
  }, [callObject]);

  function getSmallTileClass(index) {
    return 'small-tile-holder pos-' + index;
  }

  function getTiles() {
    let smallTiles = [];
    Object.entries(callState.callItems).forEach(([id, callItem], index) => {
      const isLarge = false;
      const tile = (
        <div className={getSmallTileClass(index)}>
          <Tile
            key={id}
            videoTrack={callItem.videoTrack}
            audioTrack={callItem.audioTrack}
            isLocalPerson={isLocal(id)}
            isLarge={isLarge}
            isLoading={callItem.isLoading}
            userName={callItem.userName}
            onClick={
              isLocal(id)
                ? null
                : () => {
                    sendHello(id);
                  }
            }
          />
        </div>
      );

      smallTiles.push(tile);
    });
    return [largeTiles, smallTiles];
  }

  const [largeTiles, smallTiles] = getTiles();
  // const message = getMessage(callState);
  return (
    <div className="call">
      <Tray
        disabled={false}
        onClickLeaveCall={() => {
          console.log('start leaving call');
        }}
        devices={props.devices}
        camera={props.camera}
        mic={props.mic}
        speaker={props.speaker}
        onDevicesChanged={props.onDevicesChanged}
      />
      <div className="small-tiles">{smallTiles}</div>
      {/* {message &&  (
        <CallMessage
          header={message.header}
          detail={message.detail}
          isError={message.isError}
        />
      )} */}
    </div>
  );
}
