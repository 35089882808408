import { PointsUpdate } from '../../../communication/message/PointsUpdate';

const BINDING_NAME = 'rs.slagalica.games.pairs.message.PairsResult';

export class PairsResult extends PointsUpdate {
  isCorrect: boolean = false;

  id: number = 0;

  answerIndex: number = 0;

  numOfTries: number = 0;

  correctAnswerIndex: number = 0;

  valueA?: string;

  valueB?: string;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  getQuestionId(): Number {
    return this.id;
  }
}
