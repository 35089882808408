import Images from 'web/src/resources/ImgResources';
import { Local } from 'common/src/lang/Local';

class RankResources {
  private rankIconsSmall: Array<string> = [];

  private rankIconsBig: Array<string> = [];

  constructor() {
    this.rankIconsSmall[0] = Images.rating11;
    this.rankIconsSmall[1] = Images.rating12;
    this.rankIconsSmall[2] = Images.rating13;
    this.rankIconsSmall[3] = Images.rating21;
    this.rankIconsSmall[4] = Images.rating22;
    this.rankIconsSmall[5] = Images.rating23;
    this.rankIconsSmall[6] = Images.rating31;
    this.rankIconsSmall[7] = Images.rating32;
    this.rankIconsSmall[8] = Images.rating33;
    this.rankIconsSmall[9] = Images.rating41;
    this.rankIconsSmall[10] = Images.rating42;
    this.rankIconsSmall[11] = Images.rating43;
    this.rankIconsSmall[12] = Images.rating51;
    this.rankIconsSmall[13] = Images.rating52;
    this.rankIconsSmall[14] = Images.rating53;
    this.rankIconsSmall[15] = Images.rating61;
    this.rankIconsSmall[16] = Images.rating62;
    this.rankIconsSmall[17] = Images.rating63;
    this.rankIconsSmall[18] = Images.rating71;
    this.rankIconsSmall[19] = Images.rating72;
    this.rankIconsSmall[20] = Images.rating73;
    this.rankIconsSmall[21] = Images.rating81;
    this.rankIconsSmall[22] = Images.rating82;
    this.rankIconsSmall[23] = Images.rating83;
    this.rankIconsSmall[24] = Images.rating91;
    this.rankIconsSmall[25] = Images.rating92;
    this.rankIconsSmall[26] = Images.rating93;
    this.rankIconsSmall[27] = Images.rating101;
    this.rankIconsSmall[28] = Images.rating102;
    this.rankIconsSmall[29] = Images.rating103;
    this.rankIconsSmall[30] = Images.rating111;
    this.rankIconsSmall[31] = Images.rating112;
    this.rankIconsSmall[32] = Images.rating113;
    this.rankIconsSmall[33] = Images.rating121;
    this.rankIconsSmall[34] = Images.rating122;
    this.rankIconsSmall[35] = Images.rating123;
    this.rankIconsSmall[36] = Images.rating131;
    this.rankIconsSmall[37] = Images.rating132;
    this.rankIconsSmall[38] = Images.rating133;
    this.rankIconsSmall[39] = Images.rating141;
    this.rankIconsSmall[40] = Images.rating142;
    this.rankIconsSmall[41] = Images.rating143;
    this.rankIconsSmall[42] = Images.rating151;
    this.rankIconsSmall[43] = Images.rating152;
    this.rankIconsSmall[44] = Images.rating153;
    this.rankIconsSmall[45] = Images.rating161;
    this.rankIconsSmall[46] = Images.rating162;
    this.rankIconsSmall[47] = Images.rating163;
    this.rankIconsSmall[48] = Images.rating171;
    this.rankIconsSmall[49] = Images.rating172;
    this.rankIconsSmall[50] = Images.rating173;

    this.rankIconsBig[0] = Images.ratingLarge11;
    this.rankIconsBig[1] = Images.ratingLarge12;
    this.rankIconsBig[2] = Images.ratingLarge13;
    this.rankIconsBig[3] = Images.ratingLarge21;
    this.rankIconsBig[4] = Images.ratingLarge22;
    this.rankIconsBig[5] = Images.ratingLarge23;
    this.rankIconsBig[6] = Images.ratingLarge31;
    this.rankIconsBig[7] = Images.ratingLarge32;
    this.rankIconsBig[8] = Images.ratingLarge33;
    this.rankIconsBig[9] = Images.ratingLarge41;
    this.rankIconsBig[10] = Images.ratingLarge42;
    this.rankIconsBig[11] = Images.ratingLarge43;
    this.rankIconsBig[12] = Images.ratingLarge51;
    this.rankIconsBig[13] = Images.ratingLarge52;
    this.rankIconsBig[14] = Images.ratingLarge53;
    this.rankIconsBig[15] = Images.ratingLarge61;
    this.rankIconsBig[16] = Images.ratingLarge62;
    this.rankIconsBig[17] = Images.ratingLarge63;
    this.rankIconsBig[18] = Images.ratingLarge71;
    this.rankIconsBig[19] = Images.ratingLarge72;
    this.rankIconsBig[20] = Images.ratingLarge73;
    this.rankIconsBig[21] = Images.ratingLarge81;
    this.rankIconsBig[22] = Images.ratingLarge82;
    this.rankIconsBig[23] = Images.ratingLarge83;
    this.rankIconsBig[24] = Images.ratingLarge91;
    this.rankIconsBig[25] = Images.ratingLarge92;
    this.rankIconsBig[26] = Images.ratingLarge93;
    this.rankIconsBig[27] = Images.ratingLarge101;
    this.rankIconsBig[28] = Images.ratingLarge102;
    this.rankIconsBig[29] = Images.ratingLarge103;
    this.rankIconsBig[30] = Images.ratingLarge111;
    this.rankIconsBig[31] = Images.ratingLarge112;
    this.rankIconsBig[32] = Images.ratingLarge113;
    this.rankIconsBig[33] = Images.ratingLarge121;
    this.rankIconsBig[34] = Images.ratingLarge122;
    this.rankIconsBig[35] = Images.ratingLarge123;
    this.rankIconsBig[36] = Images.ratingLarge131;
    this.rankIconsBig[37] = Images.ratingLarge132;
    this.rankIconsBig[38] = Images.ratingLarge133;
    this.rankIconsBig[39] = Images.ratingLarge141;
    this.rankIconsBig[40] = Images.ratingLarge142;
    this.rankIconsBig[41] = Images.ratingLarge143;
    this.rankIconsBig[42] = Images.ratingLarge151;
    this.rankIconsBig[43] = Images.ratingLarge152;
    this.rankIconsBig[44] = Images.ratingLarge153;
    this.rankIconsBig[45] = Images.ratingLarge161;
    this.rankIconsBig[46] = Images.ratingLarge162;
    this.rankIconsBig[47] = Images.ratingLarge163;
    this.rankIconsBig[48] = Images.ratingLarge171;
    this.rankIconsBig[49] = Images.ratingLarge172;
    this.rankIconsBig[50] = Images.ratingLarge173;
  }

  public getSmallImg(rank: number): string {
    const index = rank < this.rankIconsSmall.length ? rank : this.rankIconsSmall.length - 1;
    return this.rankIconsSmall[index];

    // const level =Local.getString('level.categort_1');
  }

  public getBigImg(rank: number): string {
    const index = rank < this.rankIconsBig.length ? rank : this.rankIconsBig.length - 1;
    return this.rankIconsBig[index];
  }
}

export const RANK = new RankResources();
