import { types } from 'mobx-state-tree';
import { DisplayMST } from '../../DisplayModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const ServerInfoMST = DisplayMST.named('ServerInfoModel')
  .props({
    info: types.maybeNull(types.string)
  })
  .actions(self => ({
    accept() {
      // todo..
      self.hide();
    },
    setInfo(message: string) {
      self.info = message;
    }
  }));

type ServerInfoModelType = typeof ServerInfoMST.Type;
export interface ServerInfoModel extends ServerInfoModelType {}
export const createServerInfoModel = (inf: string) =>
  ServerInfoMST.create({
    key: DisplayModelKeys.ServerInfo,
    info: inf
  });

export const createConnectionLostModel = () =>
  ServerInfoMST.create({
    key: DisplayModelKeys.ConnectionLostDialog,
    info: ''
  });
