import { Animation } from 'gsap';
import BaseAnimation from 'common/src/animations/BaseAnimation';

export default class GSAPAnimation implements BaseAnimation {
  protected animation: Animation;

  constructor(animation: Animation) {
    this.animation = animation;
  }

  play(): void {
    this.animation.play();
  }

  end(): void {
    this.animation.progress(1);
  }

  isPlaying(): Boolean {
    return this.animation.isActive();
  }

  getEndListener(): (...args: any[]) => void {
    return this.animation.eventCallback('onComplete');
  }

  getStartListener(): (...args: any[]) => void {
    return this.animation.eventCallback('onStart');
  }

  addStartListener(callback: (...args: any[]) => void): void {
    const prevListener: Function = this.getStartListener();
    this.animation.eventCallback('onStart', () => {
      if (prevListener !== undefined) prevListener();
      callback();
    });
  }

  addEndListener(callback: (...args: any[]) => void): void {
    const prevListener: Function = this.getEndListener();
    this.animation.eventCallback('onComplete', () => {
      if (prevListener !== undefined) prevListener();
      callback();
    });
  }

  getAnimation(): Animation {
    return this.animation;
  }
}
