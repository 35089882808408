import { ServerEvent } from './ServerEvent';

const BINDING_NAME = 'rs.slagalica.communication.message.PointsUpdate';

export class PointsUpdate extends ServerEvent {
  myPoints: number = 0;

  // fieldType: int
  opponentPoints: number = 0;

  // fieldType: int
  myLastPoints: number = 0;

  // fieldType: int
  opponentLastPoints: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
