const imagesPrefix = 'assets/images';
const imageHash = '?15';

const preloadedImages: Array<string> = [];

function create(relativePath: string, preload: boolean = true) {
  const path = `${imagesPrefix}${relativePath}${imageHash}`;
  if (preload) preloadedImages.push(path);
  return path;
}

export default class Images {
  // achievements
  static avatar = create('/avatar/avatar.png');

  static achievementArowDownDisabled = create('/achievements/achievement-arow-down-disabled.png');

  static achievementArowDownOver = create('/achievements/achievement-arow-down-over.png');

  static achievementArowDown = create('/achievements/achievement-arow-down.png');

  static achievementArowUpDisabled = create('/achievements/achievement-arow-up-disabled.png');

  static achievementArowUpOver = create('/achievements/achievement-arow-up-over.png');

  static achievementArowUp = create('/achievements/achievement-arow-up.png');

  static achievementCardBackgroundOver = create('/achievements/achievement-card-background-over.png');

  static achievementCardBackground = create('/achievements/achievement-card-background.png');

  static achievementComingSoon = create('/achievements/achievement-coming-soon.png');

  static achievementLocked = create('/achievements/achievement-locked.png');

  static achievementProgress = create('/achievements/achievement-progress.png');

  static achievementsDialog = create('/achievements/achievements-dialog.png');

  static pilecePero = create('/achievements/pilece-pero.png');

  static petlovoPero = create('/achievements/petlovo-pero.png');

  static sokolovoPero = create('/achievements/sokolovo-pero.png');

  static orlovoPero = create('/achievements/orlovo-pero.png');

  static paunovoPero = create('/achievements/paunovo-pero.png');

  static drveniPehar = create('/achievements/drveni-pehar.png');

  static kameniPehar = create('/achievements/kameni-pehar.png');

  static bronzaniPehar = create('/achievements/bronzani-pehar.png');

  static srebrniPehar = create('/achievements/srebrni-pehar.png');

  static zlatniPehar = create('/achievements/zlatni-pehar.png');

  static zlatnoSlovo = create('/achievements/zlatno-slovo.png');

  static zlatniAs = create('/achievements/zlatni-as.png');

  static zlatnaSova = create('/achievements/zlatna-sova.png');

  static sijalicaZaNocno = create('/achievements/sijalica-za-nocno-strebanje.png');

  static zlatnoSito = create('/achievements/zlatno-sito.png');

  static dediniPehari = create('/achievements/dedini-pehari.png');

  static pradediniPehari = create('/achievements/pradedini-pehari.png');

  static cukundediniPehari = create('/achievements/cukundedini-pehari.png');

  static navrdediniPehari = create('/achievements/navrdedini-pehari.png');

  static askurdjeloviPehari = create('/achievements/askurdjelovi-pehari.png');

  static drvenaZnacka = create('/achievements/drvena-znacka.png');

  static kamenaZnacka = create('/achievements/kamena-znacka.png');

  static bronzanaZnacka = create('/achievements/bronzana-znacka.png');

  static srebrnaZnacka = create('/achievements/srebrna-znacka.png');

  static zlatnaZnacka = create('/achievements/zlatna-znacka.png');

  // buttons
  static dialogButtonAcceptOver = create('/buttons/dialog-button-accept-over.png');

  static dialogButtonAccept = create('/buttons/dialog-button-accept.png');

  static eraseButtonDisabled = create('/buttons/erase-button-disabled.png');

  static eraseButtonOver = create('/buttons/erase-button-over.png');

  static eraseButton = create('/buttons/erase-button.png');

  static eraseAllButton = create('/buttons/erase-all-button.png');

  static eraseAllButtonOver = create('/buttons/erase-all-button-over.png');

  static gameButtonSkipOver = create('/buttons/game-button-skip-over.png');

  static gameButtonSkip = create('/buttons/game-button-skip.png');

  static gameButtonSkipDisabled = create('/buttons/game-button-skip-disabled.png');

  static roundedEraseButtonOver = create('/buttons/rounded-erase-button-over.png');

  static roundedEraseButton = create('/buttons/rounded-erase-button.png');

  // game
  static helpkitButtonDisabled = create('/game/helpkit-button-disabled.png');

  static helpkitButtonOver = create('/game/helpkit-button-over.png');

  static helpkitButton = create('/game/helpkit-button.png');

  // game/associoation
  static letterEmpty = create('/game/association/letter-empty.png');

  // game/combinations
  static symClub = create('/game/combination/sym_club.png');

  static symDiamond = create('/game/combination/sym_diamond.png');

  static symHeart = create('/game/combination/sym_heart.png');

  static symOwl = create('/game/combination/sym_owl.png');

  static symSpade = create('/game/combination/sym_spade.png');

  static symStar = create('/game/combination/sym_star.png');

  // game/matching
  static blueSemaphore = create('/game/matching/blue-semaphore.png');

  static redSemaphore = create('/game/matching/red-semaphore.png');

  // game/puzzle
  static gameButtonDisabled = create('/game/puzzle/game-button-disabled.png');

  static gameButtonOver = create('/game/puzzle/game-button-over.png');

  static gameButton = create('/game/puzzle/game-button.png');

  static gameHolderBackground = create('/game/puzzle/game-holder-background.png');

  static gameTimerBlue = create('/game/puzzle/game-timer-blue.png');

  static gameTimerHighlight = create('/game/puzzle/game-timer-highlight.png');

  static gameTimerOrange = create('/game/puzzle/game-timer-orange.png');

  static gameTimerRed = create('/game/puzzle/game-timer-red.png');

  static longerWord = create('/game/puzzle/longer-word.png');

  static myGame = create('//game/puzzle/my-game.png');

  static longerAsCompWordBonus = create('//game/puzzle/bonus-same-as-computer.png');

  static longerThanCompWordBonus = create('//game/puzzle/bonus-longer-than-computer.png');

  static maxWord = create('/game/puzzle/max-word.png');

  static myPointsWon = create('/game/puzzle/my-points-won.png');

  static opponent12Points = create('/game/puzzle/opponent-12-points.png');

  static opponentWaitingProgress = create('/game/puzzle/opponent-waiting-progress.png');

  static puzzleAnswerBackground = create('/game/puzzle/puzzle-answer-background.png');

  static puzzleHelpkitTooltip = create('/game/puzzle/puzzle-helpkit-tooltip.png');

  static puzzleWordCheckProgress = create('/game/puzzle/puzzle-word-check-progress.png');

  static puzzleWordCorrect = create('/game/puzzle/puzzle-word-correct.png');

  static puzzleWordWrong = create('/game/puzzle/puzzle-word-wrong.png');

  static statusBar = create('/game/puzzle/status-bar.png');

  static reportWrongWord = create('/game/puzzle/report-wrong-word.png');

  static handStop = create('/game/puzzle/hand-stop.png');

  // game/quiz
  static blueAnsweredQuestion = create('/game/quiz/blue-answered-question.png');

  static blueTimeBackground = create('/game/quiz/blue-time-background.png');

  static currentBlueAnswerQuestion = create('/game/quiz/current-blue-answer-question.png');

  static currentNobodyAnswerQuestion = create('/game/quiz/current-nobody-answer-question.png');

  static currentQuestion = create('/game/quiz/current-question.png');

  static currentRedAnswerQuestion = create('/game/quiz/current-red-answer-question.png');

  static my6Points = create('/game/quiz/my-6-points.png');

  static nobodyAnsweredQuestion = create('/game/quiz/nobody-answered-question.png');

  static opponent6Points = create('/game/quiz/opponent-6-points.png');

  static questionProgressBackground = create('/game/quiz/question-progress-background.png');

  static redAnsweredQuestion = create('/game/quiz/red-answered-question.png');

  static redTimeBackground = create('/game/quiz/red-time-background.png');

  // game/semaphore
  static gameBackground = create('/game/semaphore/game-background.png');

  static gameStartingTimer = create('/game/semaphore/game-starting-timer.png');

  static gameTimer = create('/game/semaphore/game-timer.png');

  static semaphoreArrowLeftOver = create('/game/semaphore/semaphore-arrow-left-over.png');

  static semaphoreArrowLeft = create('/game/semaphore/semaphore-arrow-left.png');

  static semaphoreArrowRightOver = create('/game/semaphore/semaphore-arrow-right-over.png');

  static semaphoreArrowRight = create('/game/semaphore/semaphore-arrow-right.png');

  static semaphoreBlueBackground = create('/game/semaphore/semaphore-blue-background.png');

  static semaphoreChet = create('/game/semaphore/semaphore-chet.png');

  static semaphoreChetFlipped = create('/game/semaphore/semaphore-chet-flipped.png');

  static semaphoreRedBackground = create('/game/semaphore/semaphore-red-background.png');

  // game/result-page
  static backLobbyButtonOver = create('/game/result-page/back-lobby-button-over.png');

  static backLobbyButton = create('/game/result-page/back-lobby-button.png');

  static backLobby = create('/game/result-page/back-to-loby.png');

  static blackFeather = create('/game/result-page/black-feather.png');

  static blackStar = create('/game/result-page/black-star.png');

  static boosterAppliedOff = create('/game/result-page/booster-applied-off.png');

  static boosterAppliedOnDisabled = create('/game/result-page/booster-applied-on-disabled.png');

  static boosterAppliedOn = create('/game/result-page/booster-applied-on.png');

  static boosterX2 = create('/game/result-page/booster-x2.png');

  static cupShadow = create('/game/result-page/cup-shadow.png');

  static feather = create('/game/result-page/feather.png');

  static highScoreSticker = create('/game/result-page/high-score-sticker.png');

  static newHighScoreLabel = create('/game/result-page/new-high-score-label.png');

  static losseCup = create('/game/result-page/losse-cup.png');

  static resultArow = create('/game/result-page/result-arow.png');

  static resultBackground = create('/game/result-page/result-background.png');

  static resultHolder = create('/game/result-page/result-holder.png');

  static resultRaiting = create('/game/result-page/result-raiting.png');

  static friendlyResultHolder = create('/game/result-page/friendly-result-holder.png');

  static resultStatusYouLost = create('/game/result-page/result-status-you-lost.png');

  static resultStatusYouWon = create('/game/result-page/result-status-you-won.png');

  static rematch = create('/game/result-page/rematch.png');

  static rematchDefault = create('/game/result-page/rematch-default.png');

  static rematchDefaultOver = create('/game/result-page/rematch-default-over.png');

  static rematchAccept = create('/game/result-page/rematch-accept.png');

  static rematchAcceptOver = create('/game/result-page/rematch-accept-over.png');

  static rematchDisabled = create('/game/result-page/rematch-disabled.png');

  static rematchTextDefault = create('/game/result-page/rematch-text-default.png');

  static rematchTextDisabled = create('/game/result-page/rematch-text-disabled.png');

  static rematchTextAccept = create('/game/result-page/rematch-text-accept.png');

  static scoreHolder = create('/game/result-page/score-holder.png');

  static winerCup = create('/game/result-page/winer-cup.png');

  static friendGameIcon = create('/game/result-page/friend-game-icon.png');

  static trapezeBooster = create('/game/result-page/trapeze-booster.png');

  static trapezeBoosterBlack = create('/game/result-page/trapeze-booster-black.png');

  static gameResultWon = create('/game/result-page/game-result-won.png');

  static gameResultLose = create('/game/result-page/game-result-lose.png');

  static b2bGameResultWon = create('/b2b/won.png');

  static b2bGameResultLose = create('/b2b/lose.png');

  // game/transition
  static descriptionPanel = create('/game/transition/description-panel.png');

  static sequenceCompletedLine = create('/game/transition/sequence-completed-line.png');

  static sequenceCompletedStep = create('/game/transition/sequence-completed-step.png');

  static sequencePointer = create('/game/transition/sequence-pointer.png');

  // loading
  static apartekoLoader = create('/loading/aparteko-loader.png');

  static loadingBackground = create('/loading/loading-background.png');

  static loadingProgressBackground = create('/loading/loading-progress-background.png');

  static loadingProgressFrame = create('/loading/loading-progress-frame.png');

  static loadingProgressLine = create('/loading/loading-progress-line.png');

  static logoAparteko = create('/loading/logo-aparteko.png');

  // lobby
  static backgroundLobby = create('/lobby/background-lobby.png');

  static playNowBoosterOffOver = create('/lobby/play-now-booster-off-over.png');

  static playNowBoosterOff = create('/lobby/play-now-booster-off.png');

  static playNowBoosterOnOver = create('/lobby/play-now-booster-on-over.png');

  static playNowBoosterOn = create('/lobby/play-now-booster-on.png');

  static playNowOver = create('/lobby/play-now-over.png');

  static playNowText = create('/lobby/play-now-text.png');

  static playNow = create('/lobby/play-now.png');

  static stickersBuyTokens = create('/lobby/stickers-buy tokens.png');

  static stickersBuyTokensOver = create('/lobby/stickers-buy-tokens-over.png');

  static stickersEarnRewards = create('/lobby/stickers-earn rewards.png');

  static stickersEarnRewardsOver = create('/lobby/stickers-earn-rewards-over.png');

  static todaysHighscoreBck = create('/lobby/todays-highscore-bck.png');

  static boosterFlameIcon = create('/lobby/booster-flame-icon.png');

  static boosterAdd = create('/lobby/booster-add.png');

  static boosterAddDisabled = create('/lobby/booster-add-disabled.png');

  static boosterAddOver = create('/lobby/booster-add-over.png');

  static boosterWhiteFlash = create('/lobby/booster-white-flash.png');

  // lobby/friends
  static friendArrowLeftOver = create('/lobby/friends/friend-arrow-left-over.png');

  static friendArrowLeft = create('/lobby/friends/friend-arrow-left.png');

  static friendArrowsLeftOver = create('/lobby/friends/friend-arrows-left-over.png');

  static friendArrowsLeft = create('/lobby/friends/friend-arrows-left.png');

  static friendButtonAcceptOver = create('/lobby/friends/friend-button-accept-over.png');

  static friendButtonAccept = create('/lobby/friends/friend-button-accept.png');

  static friendButtonCallOver = create('/lobby/friends/friend-button-call-over.png');

  static friendButtonCall = create('/lobby/friends/friend-button-call.png');

  static friendButtonCancelOver = create('/lobby/friends/friend-button-cancel-over.png');

  static friendButtonCancel = create('/lobby/friends/friend-button-cancel.png');

  static friendButtonsDeclineOver = create('/lobby/friends/friend-buttons-decline-over.png');

  static friendButtonsDecline = create('/lobby/friends/friend-buttons-decline.png');

  static friendCardDarkBackground = create('/lobby/friends/friend-card-dark-background.png');

  static friendCardLightBackground = create('/lobby/friends/friend-card-light-background.png');

  static friendInviteBackgroundOver = create('/lobby/friends/friend-invite-background-over.png');

  static friendInviteBackground = create('/lobby/friends/friend-invite-background.png');

  static friendSatusBusy = create('/lobby/friends/friend-satus-busy.png');

  static friendSatusOffline = create('/lobby/friends/friend-satus-offline.png');

  static friendSatusOnline = create('/lobby/friends/friend-satus-online.png');

  static friendsBackground = create('/lobby/friends/friends-background.png');

  static pwfButtonsInviteFriendOver = create('/lobby/friends/pwf-buttons-invite-friend-over.png');

  static pwfButtonsInviteFriend = create('/lobby/friends/pwf-buttons-invite-friend.png');

  static pwfbArrowRightOver = create('/lobby/friends/pwfb-arrow-right-over.png');

  static pwfbArrowRight = create('/lobby/friends/pwfb-arrow-right.png');

  static pwfbArrowsRightOver = create('/lobby/friends/pwfb-arrows-right-over.png');

  static pwfbArrowsRight = create('/lobby/friends/pwfb-arrows-right.png');

  static friendsTabAll = create('/lobby/friends/friends-tab-all.png');

  static friendsTabAvailable = create('/lobby/friends/friends-tab-available.png');

  static friendCard = create('/lobby/b2b/friend-card.png');

  static clock = create('/lobby/b2b/clock.png');

  // lobby/matching-players
  static matchingDialogArrows = create('/lobby/matching-players/matching-dialog-arrows.png');

  static matchingDialogCard = create('/lobby/matching-players/matching-dialog-card.png');

  static matchingVs = create('/lobby/matching-players/matching-vs.png');

  static acceptMatching = create('/lobby/matching-players/accept.png');

  static declineMatching = create('/lobby/matching-players/decline.png');

  static notComfirmedMatching = create('/lobby/matching-players/not_comfirmed.png');

  static timerMatching = create('/lobby/matching-players/timer-matching.png');

  static avatarI = create('/lobby/matching-players/avatar-i.png');

  static avatarL = create('/lobby/matching-players/avatar-l.png');

  static avatarM = create('/lobby/matching-players/avatar-m.png');

  static avatarO = create('/lobby/matching-players/avatar-o.png');

  static avatarP = create('/lobby/matching-players/avatar-p.png');

  static avatarPP = create('/lobby/matching-players/avatar-pp.png');

  static avatarV = create('/lobby/matching-players/avatar-v.png');

  static b2bTeamBlue = create('/lobby/b2b/b2b-team-blue.png');

  static b2bTeamRed = create('/lobby/b2b/b2b-team-red.png');

  // lobby/toolbar
  static musicOff = create('/toolbar/music-off.png');

  static musicOn = create('/toolbar/music-on.png');

  static progressBarBackground = create('/toolbar/progress-bar-background.png');

  static progressBarContent = create('/toolbar/progress-bar-content.png');

  static progressBarShadow = create('/toolbar/progress-bar-shadow.png');

  static progressBarStar = create('/toolbar/progress-bar-star.png');

  static soundOff = create('/lobby/sound_off.png');

  static soundOffOver = create('/lobby/sound-off-over.png');

  static soundOn = create('/lobby/sound_on.png');

  static soundOnOver = create('/lobby/sound-on-over.png');

  static soundsBackground = create('/toolbar/sounds-background.png');

  static toolbarAchievementsOver = create('/toolbar/toolbar-achievements-over.png');

  static toolbarAchievementsSelectedOver = create('/toolbar/toolbar-achievements-selected-over.png');

  static toolbarAchievementsSelected = create('/toolbar/toolbar-achievements-selected.png');

  static toolbarAchievements = create('/toolbar/toolbar-achievements.png');

  static toolbarBackground = create('/toolbar/toolbar-background.png');

  static toolbarBooster = create('/toolbar/toolbar-booster.png');

  static toolbarBoosterOver = create('/toolbar/toolbar-booster-over.png');

  static toolbarHelpkit = create('/toolbar/toolbar-helpkit.png');

  static toolbarHelpkitOver = create('/toolbar/toolbar-helpkit-over.png');

  static toolbarInboxOver = create('/toolbar/toolbar-inbox-over.png');

  static toolbarInboxSelectedOver = create('/toolbar/toolbar-inbox-selected-over.png');

  static toolbarInboxSelected = create('/toolbar/toolbar-inbox-selected.png');

  static toolbarInbox = create('/toolbar/toolbar-inbox.png');

  static toolbarNextTitleShadow = create('/toolbar/toolbar-next-title-shadow.png');

  static toolbarSeparator = create('/toolbar/toolbar-separator.png');

  static toolbarSettingsOver = create('/toolbar/toolbar-settings-over.png');

  static toolbarSettingsSelectedOver = create('/toolbar/toolbar-settings-selected-over.png');

  static toolbarSettingsSelected = create('/toolbar/toolbar-settings-selected.png');

  static toolbarSettings = create('/toolbar/toolbar-settings.png');

  static toolbarShopOver = create('/toolbar/toolbar-shop-over.png');

  static toolbarShopSelectedOver = create('/toolbar/toolbar-shop-selected-over.png');

  static toolbarShopSelected = create('/toolbar/toolbar-shop-selected.png');

  static toolbarShop = create('/toolbar/toolbar-shop.png');

  static toolbarToken = create('/toolbar/toolbar-token.png');

  static toolbarTokenOver = create('/toolbar/toolbar-token-over.png');

  static tooltipBackground = create('/toolbar/tooltip-background.png');

  static tooltipTokensImage = create('/toolbar/tooltip-tokens-image.png');

  // lobby/tournament
  static dailyHigescoreBackground = create('/lobby/tournament/daily-higescore-background.png.png');

  static dayButtonOver = create('/lobby/tournament/day-button-over.png');

  static dayButtonSelected = create('/lobby/tournament/day-button-selected.png');

  static dayButton = create('/lobby/tournament/day-button.png');

  static expandArow = create('/lobby/tournament/expand-arow.png');

  static myPositionBackground = create('/lobby/tournament/my-position-background.png');

  static tournamentAward1 = create('/lobby/tournament/tournament-award-1.png');

  static tournamentAward2 = create('/lobby/tournament/tournament-award-2.png');

  static tournamentAward3 = create('/lobby/tournament/tournament-award-3.png');

  static tournamentAward4 = create('/lobby/tournament/tournament-award-4.png');

  static tournamentAward5 = create('/lobby/tournament/tournament-award-5.png');

  static tournamentBlackBackground = create('/lobby/tournament/tournament-black-background.png');

  static tournamentHelpOver = create('/lobby/tournament/tournament-help-over.png');

  static tournamentHelp = create('/lobby/tournament/tournament-help.png');

  static tournamentTab = create('/lobby/tournament/tournament-tab.png');

  static tournamentTabOver = create('/lobby/tournament/tournament-tab-over.png');

  // lobby/friends/invite
  static boosterAskFriend = create('/lobby/friends/invite/booster-ask-friend.png');

  static checkboxSelected = create('/lobby/friends/invite/checkbox-selected.png');

  static closedGift = create('/lobby/friends/invite/closed-gift.png');

  static dialogBlackBackground = create('/lobby/friends/invite/dialog-black-background.png');

  static friends = create('/lobby/friends/invite/friends.png');

  static helpkitAskFriend = create('/lobby/friends/invite/helpkit-ask-friend.png');

  static inviteTabBackground = create('/lobby/friends/invite/invite-tab-background.png');

  // rating
  static rating11 = create('/rating/1-1.png');

  static rating12 = create('/rating/1-2.png');

  static rating13 = create('/rating/1-3.png');

  static rating101 = create('/rating/10-1.png');

  static rating102 = create('/rating/10-2.png');

  static rating103 = create('/rating/10-3.png');

  static rating111 = create('/rating/11-1.png');

  static rating112 = create('/rating/11-2.png');

  static rating113 = create('/rating/11-3.png');

  static rating121 = create('/rating/12-1.png');

  static rating122 = create('/rating/12-2.png');

  static rating123 = create('/rating/12-3.png');

  static rating131 = create('/rating/13-1.png');

  static rating132 = create('/rating/13-2.png');

  static rating133 = create('/rating/13-3.png');

  static rating141 = create('/rating/14-1.png');

  static rating142 = create('/rating/14-2.png');

  static rating143 = create('/rating/14-3.png');

  static rating151 = create('/rating/15-1.png');

  static rating152 = create('/rating/15-2.png');

  static rating153 = create('/rating/15-3.png');

  static rating161 = create('/rating/16-1.png');

  static rating162 = create('/rating/16-2.png');

  static rating163 = create('/rating/16-3.png');

  static rating171 = create('/rating/17-1.png');

  static rating172 = create('/rating/17-2.png');

  static rating173 = create('/rating/17-3.png');

  static rating21 = create('/rating/2-1.png');

  static rating22 = create('/rating/2-2.png');

  static rating23 = create('/rating/2-3.png');

  static rating31 = create('/rating/3-1.png');

  static rating32 = create('/rating/3-2.png');

  static rating33 = create('/rating/3-3.png');

  static rating41 = create('/rating/4-1.png');

  static rating42 = create('/rating/4-2.png');

  static rating43 = create('/rating/4-3.png');

  static rating51 = create('/rating/5-1.png');

  static rating52 = create('/rating/5-2.png');

  static rating53 = create('/rating/5-3.png');

  static rating61 = create('/rating/6-1.png');

  static rating62 = create('/rating/6-2.png');

  static rating63 = create('/rating/6-3.png');

  static rating71 = create('/rating/7-1.png');

  static rating72 = create('/rating/7-2.png');

  static rating73 = create('/rating/7-3.png');

  static rating81 = create('/rating/8-1.png');

  static rating82 = create('/rating/8-2.png');

  static rating83 = create('/rating/8-3.png');

  static rating91 = create('/rating/9-1.png');

  static rating92 = create('/rating/9-2.png');

  static rating93 = create('/rating/9-3.png');

  static ratingUnknown = create('/rating/rating-unknown.png');

  // rating/large
  static ratingLarge11 = create('/rating/large/1-1.png');

  static ratingLarge12 = create('/rating/large/1-2.png');

  static ratingLarge13 = create('/rating/large/1-3.png');

  static ratingLarge101 = create('/rating/large/10-1.png');

  static ratingLarge102 = create('/rating/large/10-2.png');

  static ratingLarge103 = create('/rating/large/10-3.png');

  static ratingLarge111 = create('/rating/large/11-1.png');

  static ratingLarge112 = create('/rating/large/11-2.png');

  static ratingLarge113 = create('/rating/large/11-3.png');

  static ratingLarge121 = create('/rating/large/12-1.png');

  static ratingLarge122 = create('/rating/large/12-2.png');

  static ratingLarge123 = create('/rating/large/12-3.png');

  static ratingLarge131 = create('/rating/large/13-1.png');

  static ratingLarge132 = create('/rating/large/13-2.png');

  static ratingLarge133 = create('/rating/large/13-3.png');

  static ratingLarge141 = create('/rating/large/14-1.png');

  static ratingLarge142 = create('/rating/large/14-2.png');

  static ratingLarge143 = create('/rating/large/14-3.png');

  static ratingLarge151 = create('/rating/large/15-1.png');

  static ratingLarge152 = create('/rating/large/15-2.png');

  static ratingLarge153 = create('/rating/large/15-3.png');

  static ratingLarge161 = create('/rating/large/16-1.png');

  static ratingLarge162 = create('/rating/large/16-2.png');

  static ratingLarge163 = create('/rating/large/16-3.png');

  static ratingLarge171 = create('/rating/large/17-1.png');

  static ratingLarge172 = create('/rating/large/17-2.png');

  static ratingLarge173 = create('/rating/large/17-3.png');

  static ratingLarge21 = create('/rating/large/2-1.png');

  static ratingLarge22 = create('/rating/large/2-2.png');

  static ratingLarge23 = create('/rating/large/2-3.png');

  static ratingLarge31 = create('/rating/large/3-1.png');

  static ratingLarge32 = create('/rating/large/3-2.png');

  static ratingLarge33 = create('/rating/large/3-3.png');

  static ratingLarge41 = create('/rating/large/4-1.png');

  static ratingLarge42 = create('/rating/large/4-2.png');

  static ratingLarge43 = create('/rating/large/4-3.png');

  static ratingLarge51 = create('/rating/large/5-1.png');

  static ratingLarge52 = create('/rating/large/5-2.png');

  static ratingLarge53 = create('/rating/large/5-3.png');

  static ratingLarge61 = create('/rating/large/6-1.png');

  static ratingLarge62 = create('/rating/large/6-2.png');

  static ratingLarge63 = create('/rating/large/6-3.png');

  static ratingLarge71 = create('/rating/large/7-1.png');

  static ratingLarge72 = create('/rating/large/7-2.png');

  static ratingLarge73 = create('/rating/large/7-3.png');

  static ratingLarge81 = create('/rating/large/8-1.png');

  static ratingLarge82 = create('/rating/large/8-2.png');

  static ratingLarge83 = create('/rating/large/8-3.png');

  static ratingLarge91 = create('/rating/large/9-1.png');

  static ratingLarge92 = create('/rating/large/9-2.png');

  static ratingLarge93 = create('/rating/large/9-3.png');

  static unknown = create('/rating/large/rating-unknown.png');

  // dialogs/daily-tokens
  static cardsHolder = create('/dialogs/daily-tokens/cards-holder.png');

  static day1Tokens = create('/dialogs/daily-tokens/day1-tokens.png');

  static day2Tokens = create('/dialogs/daily-tokens/day2-tokens.png');

  static day3Tokens = create('/dialogs/daily-tokens/day3-tokens.png');

  static day4Tokens = create('/dialogs/daily-tokens/day4-tokens.png');

  static day5Tokens = create('/dialogs/daily-tokens/day5-tokens.png');

  static day1TokensSilver = create('/dialogs/daily-tokens/silver-tokens-1.png');

  static day2TokensSilver = create('/dialogs/daily-tokens/silver-tokens-2.png');

  static day3TokensSilver = create('/dialogs/daily-tokens/silver-tokens-3.png');

  static day4TokensSilver = create('/dialogs/daily-tokens/silver-tokens-4.png');

  static day5TokensSilver = create('/dialogs/daily-tokens/silver-tokens-5.png');

  static nextDayBackgroundTop = create('/dialogs/daily-tokens/next-day-background-top.png');

  static nextDayTokenHolder = create('/dialogs/daily-tokens/next-day-token-holder.png');

  static peviousDayBackgroundTop = create('/dialogs/daily-tokens/pevious-day-background-top.png');

  static peviousDayDone = create('/dialogs/daily-tokens/pevious-day-done.png');

  static peviousDayDoneFrame = create('/dialogs/daily-tokens/pevious-day-done-frame.png');

  static peviousDayTokenHolder = create('/dialogs/daily-tokens/pevious-day-token-holder.png');

  static todayTokenHolder = create('/dialogs/daily-tokens/today-token-holder.png');

  static today = create('/dialogs/daily-tokens/today.png');

  static silverTokenIcon = create('/dialogs/daily-tokens/silver-token-icon.png');

  // dialogs/dialog-info
  static owlInfo = create('/dialogs/dialog-info/owl-info.png');

  static owlGift = create('/dialogs/dialog-info/gift.png');

  // dialogs/earn-rewards
  static referralAdd = create('/dialogs/earn-rewards/referral-add.png');

  static referralIcon = create('/dialogs/earn-rewards/referral-icon.png');

  // dialogs/inbox
  static inboxGiftIcon = create('/dialogs/inbox/inbox-gift-icon.png');

  static inboxMessageBackground = create('/dialogs/inbox/inbox-message-background.png');

  static owlSystemNotification = create('/dialogs/inbox/owl-system-notification.png');

  static scrollArrowDownOver = create('/dialogs/inbox/scroll-arrow-down-over.png');

  static scrollArrowDown = create('/dialogs/inbox/scroll-arrow-down.png');

  static scrollArrowOver = create('/dialogs/inbox/scroll-arrow-over.png');

  static scrollArrow = create('/dialogs/inbox/scroll-arrow.png');

  static scrollButton = create('/dialogs/inbox/scroll-button.png');

  // dialogs/reconnect
  static owlError = create('/dialogs/reconnect/owl-error.png');

  // dialogs/resume-game
  static resumeGameButtonOver = create('/dialogs/resume-game/resume-game-button-over.png');

  static resumeGameButton = create('/dialogs/resume-game/resume-game-button.png');

  static resumeGameLabel = create('/dialogs/resume-game/resume-game-label.png');

  // dialogs/tournament-result
  static boosterIcon = create('/lobby/booster-icon.png');

  static colorFeathersBackground = create('/dialogs/tournament-result/color-feathers-background.png');

  static helpkitIcon = create('/dialogs/tournament-result/helpkit-icon.png');

  static openedGiftbox = create('/dialogs/tournament-result/opened-giftbox.png');

  static profilePictureFrame = create('/dialogs/tournament-result/profile-picture-frame.png');

  static starIcon = create('/dialogs/tournament-result/star-icon.png');

  static tokenIcon = create('/dialogs/tournament-result/token-icon.png');

  static trone = create('/dialogs/tournament-result/trone.png');

  static unknownProfilePicture = create('/dialogs/tournament-result/unknown-profile-picture.png');

  // dialogs/equal-score
  static owlScale = create('/dialogs/equal-score/owl-scale.png');

  // dialogs
  static dialogCloseButtonOver = create('/dialogs/dialog-close-button-over.png');

  static dialogCloseButton = create('/dialogs/dialog-close-button.png');

  // dialogs/out-of-helpkit
  static outOfHelpkitImage = create('/dialogs/out-of-helpkit/help-kit-offer.png');

  // dialogs/survey
  static earnTokens = create('/dialogs/survey/earn-tokens.png');

  // dialogs/shop
  static addTokensBackground = create('/dialogs/shop/add-tokens-background.png');

  static addTokensButton = create('/dialogs/shop/add-tokens-button.png');

  static bestBuy = create('/dialogs/shop/best-buy.png');

  static buyDone = create('/dialogs/shop/buy-done.png');

  static buyText = create('/dialogs/shop/buy-text.png');

  static dialogBackground = create('/dialogs/shop/dialog-background.png');

  static dialogHeaderLeft = create('/dialogs/shop/dialog-header-left.png');

  static dialogHeaderMiddle = create('/dialogs/shop/dialog-header-middle.png');

  static dialogHeaderRight = create('/dialogs/shop/dialog-header-right.png');

  static shopCardBackground = create('/dialogs/shop/shop-card-background.png');

  static shopCardInnerBackgroundOver = create('/dialogs/shop/shop-card-inner-background-over.png');

  static shopArrowLeft = create('/dialogs/shop/shop-arrow-left.png');

  static shopArrowRightOver = create('/dialogs/shop/shop-arrow-right-over.png');

  static shopArrowRight = create('/dialogs/shop/shop-arrow-right.png');

  static shopBoosterIcon = create('/dialogs/shop/shop-booster-icon.png');

  static shopEmotionIcon = create('/dialogs/shop/shop-emotion-icon.png');

  static shopHatIcon = create('/dialogs/shop/shop-hat-icon.png');

  static shopHelpkitIcon = create('/dialogs/shop/shop-helpkit-icon.png');

  static shopHelpKitSmallIcon = create('/dialogs/shop/helpkits/icon-help-kit.png');

  static shopBoosterSmallIcon = create('/dialogs/shop/booster/icon-booster.png');

  static shopTabButtonSelected = create('/dialogs/shop/shop-tab-button-selected.png');

  static shopTabButton = create('/dialogs/shop/shop-tab-button.png');

  static shopTitle = create('/dialogs/shop/shop-title.png');

  static stickerNew = create('/dialogs/shop/sticker-new.png');

  static tabContentBackground = create('/dialogs/shop/tab-content-background.png');

  // dialogs/shop/booster
  static boosterCardImage = create('/dialogs/shop/booster/booster-card-image.png');

  static boosterCardInnerBackground = create('/dialogs/shop/booster/booster-card-inner-background.png');

  static boosterCountHolder = create('/dialogs/shop/booster/booster-count-holder.png');

  static boosterFreeCardOver = create('/dialogs/shop/booster/booster-free-card-over.png');

  static boosterFreeCard = create('/dialogs/shop/booster/booster-free-card.png');

  // dialogs/shop/emotions
  static dialogsShopEmotions1 = create('/dialogs/shop/emotions/1.png');

  static dialogsShopEmotions10 = create('/dialogs/shop/emotions/10.png');

  static dialogsShopEmotions11 = create('/dialogs/shop/emotions/11.png');

  static dialogsShopEmotions12 = create('/dialogs/shop/emotions/12.png');

  static dialogsShopEmotions2 = create('/dialogs/shop/emotions/2.png');

  static dialogsShopEmotions3 = create('/dialogs/shop/emotions/3.png');

  static dialogsShopEmotions4 = create('/dialogs/shop/emotions/4.png');

  static dialogsShopEmotions5 = create('/dialogs/shop/emotions/5.png');

  static dialogsShopEmotions6 = create('/dialogs/shop/emotions/6.png');

  static dialogsShopEmotions7 = create('/dialogs/shop/emotions/7.png');

  static dialogsShopEmotions8 = create('/dialogs/shop/emotions/8.png');

  static dialogsShopEmotions9 = create('/dialogs/shop/emotions/9.png');

  // dialogs/shop/hats
  static blueBackground = create('/dialogs/shop/hats/blue-background.png');

  static hat1 = create('/dialogs/shop/hats/hat-1.png');

  static hat10 = create('/dialogs/shop/hats/hat-10.png');

  static hat11 = create('/dialogs/shop/hats/hat-11.png');

  static hat12 = create('/dialogs/shop/hats/hat-12.png');

  static hat13 = create('/dialogs/shop/hats/hat-13.png');

  static hat14 = create('/dialogs/shop/hats/hat-14.png');

  static hat15 = create('/dialogs/shop/hats/hat-15.png');

  static hat16 = create('/dialogs/shop/hats/hat-16.png');

  static hat17 = create('/dialogs/shop/hats/hat-17.png');

  static hat18 = create('/dialogs/shop/hats/hat-18.png');

  static hat19 = create('/dialogs/shop/hats/hat-19.png');

  static hat2 = create('/dialogs/shop/hats/hat-2.png');

  static hat20 = create('/dialogs/shop/hats/hat-20.png');

  static hat21 = create('/dialogs/shop/hats/hat-21.png');

  static hat22 = create('/dialogs/shop/hats/hat-22.png');

  static hat23 = create('/dialogs/shop/hats/hat-23.png');

  static hat24 = create('/dialogs/shop/hats/hat-24.png');

  static hat25 = create('/dialogs/shop/hats/hat-25.png');

  static hat3 = create('/dialogs/shop/hats/hat-3.png');

  static hat4 = create('/dialogs/shop/hats/hat-4.png');

  static hat5 = create('/dialogs/shop/hats/hat-5.png');

  static hat6 = create('/dialogs/shop/hats/hat-6.png');

  static hat7 = create('/dialogs/shop/hats/hat-7.png');

  static hat8 = create('/dialogs/shop/hats/hat-8.png');

  static hat9 = create('/dialogs/shop/hats/hat-9.png');

  // dialogs/shop/sets
  static emoticonsSet0 = create('/dialogs/shop/sets/emoticons-set-0.png');

  static stickersSet0 = create('/dialogs/shop/sets/stickers-set-0.png');

  static stickersSet1 = create('/dialogs/shop/sets/stickers-set-1.png');

  static textSet0 = create('/dialogs/shop/sets/text-set-0.png');

  static textSet1 = create('/dialogs/shop/sets/text-set-1.png');

  static textSet2 = create('/dialogs/shop/sets/text-set-2.png');

  // dialogs/shop/helpkits
  static countHolder = create('/dialogs/shop/helpkits/count-holder.png');

  static helpkitCardImage = create('/dialogs/shop/helpkits/helpkit-card-image.png');

  static helpkitCardInnerBackground = create('/dialogs/shop/helpkits/helpkit-card-inner-background.png');

  static helpkitFreeCardOver = create('/dialogs/shop/helpkits/helpkit-free-card-over.png');

  static helpkitFreeCard = create('/dialogs/shop/helpkits/helpkit-free-card.png');

  // dialogs/shop/sounds
  static dialogsShopSounds1 = create('/dialogs/shop/sounds/1.png');

  static dialogsShopSounds2 = create('/dialogs/shop/sounds/2.png');

  static dialogsShopSounds3 = create('/dialogs/shop/sounds/3.png');

  static dialogsShopSounds4 = create('/dialogs/shop/sounds/4.png');

  static dialogsShopSounds5 = create('/dialogs/shop/sounds/5.png');

  static dialogsShopSounds6 = create('/dialogs/shop/sounds/6.png');

  static dialogsShopSounds7 = create('/dialogs/shop/sounds/7.png');

  static dialogsShopSounds8 = create('/dialogs/shop/sounds/8.png');

  // dialogs/shop/stickers
  static dialogsShopStickers1 = create('/dialogs/shop/stickers/1.png');

  static dialogsShopStickers10 = create('/dialogs/shop/stickers/10.png');

  static dialogsShopStickers11 = create('/dialogs/shop/stickers/11.png');

  static dialogsShopStickers12 = create('/dialogs/shop/stickers/12.png');

  static dialogsShopStickers2 = create('/dialogs/shop/stickers/2.png');

  static dialogsShopStickers3 = create('/dialogs/shop/stickers/3.png');

  static dialogsShopStickers4 = create('/dialogs/shop/stickers/4.png');

  static dialogsShopStickers5 = create('/dialogs/shop/stickers/5.png');

  static dialogsShopStickers6 = create('/dialogs/shop/stickers/6.png');

  static dialogsShopStickers7 = create('/dialogs/shop/stickers/7.png');

  static dialogsShopStickers8 = create('/dialogs/shop/stickers/8.png');

  static dialogsShopStickers9 = create('/dialogs/shop/stickers/9.png');

  static lock = create('/dialogs/shop/stickers/lock.png');

  // dialogs/shop/tokens

  static package1Tokens = create('/dialogs/shop/tokens/package1-tokens.png');

  static package2Tokens = create('/dialogs/shop/tokens/package2-tokens.png');

  static package3Tokens = create('/dialogs/shop/tokens/package3-tokens.png');

  static package4Tokens = create('/dialogs/shop/tokens/package4-tokens.png');

  static package5Tokens = create('/dialogs/shop/tokens/package5-tokens.png');

  static dialogsShopTokensTabMiddle = create('/dialogs/shop/tokens/tokens-shop-middle.png');

  static dialogsShopTokensTabBackground = create('/dialogs/shop/tokens/tokens-shop-tab-background.png');

  static dialogsShopTokensLock = create('/dialogs/shop/tokens/lock.png');

  // dialogs/shop/hats/preview
  static hatPreview0 = create('/dialogs/shop/hats/preview/0.png');

  static hatPreview1 = create('/dialogs/shop/hats/preview/1.png');

  static hatPreview10 = create('/dialogs/shop/hats/preview/10.png');

  static hatPreview11 = create('/dialogs/shop/hats/preview/11.png');

  static hatPreview12 = create('/dialogs/shop/hats/preview/12.png');

  static hatPreview13 = create('/dialogs/shop/hats/preview/13.png');

  static hatPreview14 = create('/dialogs/shop/hats/preview/14.png');

  static hatPreview15 = create('/dialogs/shop/hats/preview/15.png');

  static hatPreview16 = create('/dialogs/shop/hats/preview/16.png');

  static hatPreview17 = create('/dialogs/shop/hats/preview/17.png');

  static hatPreview18 = create('/dialogs/shop/hats/preview/18.png');

  static hatPreview19 = create('/dialogs/shop/hats/preview/19.png');

  static hatPreview2 = create('/dialogs/shop/hats/preview/2.png');

  static hatPreview20 = create('/dialogs/shop/hats/preview/20.png');

  static hatPreview21 = create('/dialogs/shop/hats/preview/21.png');

  static hatPreview22 = create('/dialogs/shop/hats/preview/22.png');

  static hatPreview23 = create('/dialogs/shop/hats/preview/23.png');

  static hatPreview24 = create('/dialogs/shop/hats/preview/24.png');

  static hatPreview3 = create('/dialogs/shop/hats/preview/3.png');

  static hatPreview4 = create('/dialogs/shop/hats/preview/4.png');

  static hatPreview5 = create('/dialogs/shop/hats/preview/5.png');

  static hatPreview6 = create('/dialogs/shop/hats/preview/6.png');

  static hatPreview7 = create('/dialogs/shop/hats/preview/7.png');

  static hatPreview8 = create('/dialogs/shop/hats/preview/8.png');

  static hatPreview9 = create('/dialogs/shop/hats/preview/9.png');

  static semaphore = create('/dialogs/shop/hats/preview/semaphore.png');

  // dialogs/shop/sounds/small
  static dialogsShopSoundsSmall1 = create('/dialogs/shop/sounds/small/1.png');

  static dialogsShopSoundsSmall2 = create('/dialogs/shop/sounds/small/2.png');

  static dialogsShopSoundsSmall3 = create('/dialogs/shop/sounds/small/3.png');

  static dialogsShopSoundsSmall4 = create('/dialogs/shop/sounds/small/4.png');

  static dialogsShopSoundsSmall5 = create('/dialogs/shop/sounds/small/5.png');

  static dialogsShopSoundsSmall6 = create('/dialogs/shop/sounds/small/6.png');

  static dialogsShopSoundsSmall7 = create('/dialogs/shop/sounds/small/7.png');

  static dialogsShopSoundsSmall8 = create('/dialogs/shop/sounds/small/8.png');

  // game/chat
  static chatEmojiBackground = create('/game/chat/chat-emoji-background.png');

  static chatEmotionIcons = create('/game/chat/chat-emotion-icons.png');

  static chatScrollBackground = create('/game/chat/chat-scroll-background.png');

  static chatSoundIconLockDark = create('/game/chat/chat-sound-icon-lock-dark.png');

  static chatSoundIconLockLight = create('/game/chat/chat-sound-icon-lock-light.png');

  static chatSoundIconNoteDark = create('/game/chat/chat-sound-icon-note-dark.png');

  static chatSoundIconNoteLight = create('/game/chat/chat-sound-icon-note-light.png');

  static chatSoundsBacground = create('/game/chat/chat-sounds-bacground.png');

  static chatStickerIconsLock = create('/game/chat/chat-sticker-icons-lock.png');

  static chatStickerIcons = create('/game/chat/chat-sticker-icons.png');

  static chatTextBackground = create('/game/chat/chat-text-background.png');

  static chatTextBackgroundFlipped = create('/game/chat/chat-text-background-flipped.png');

  static chatTabButtons = create('/game/chat/chat-tab-buttons.png');

  static chatTabIcons = create('/game/chat/chat-tab-icons.png');

  static chatShop = create('/game/chat/shop.png');

  static chatShopOver = create('/game/chat/shop-over.png');

  static chatTabButton = create('/game/chat/tab-button.png');

  static chatTabButtonOver = create('/game/chat/tab-button-over.png');

  static chatTabButtonSelected = create('/game/chat/tab-button-selected.png');

  static chatLock1 = create('/game/chat/lock-1.png');

  static chatIconText = create('/game/chat/chat-tab-icon-text.png');

  static chatIconEmoticon = create('/game/chat/chat-tab-icon-emoticon.png');

  static chatIconSticker = create('/game/chat/chat-tab-icon-sticker.png');

  static chatIconSound = create('/game/chat/chat-tab-icon-sound.png');

  static appealButton = create('/game/result-page/appeal-button.png');

  static appealButtonOver = create('/game/result-page/appeal-button-over.png');

  static disconnected = create('/game/semaphore/disconnected.png');

  static owlTokensIcon = create('/dialogs/dialog-info/owl-tokens-icon.png');

  static tokensBanner = create('/dialogs/dialog-info/tokens-banner.png');

  static tokenBannerSingle = create('/dialogs/dialog-info/token-banner-single.png');

  static b2bCollaboration = create('/b2b/collaboration-background.png');

  static getPreloadedImages(): Array<string> {
    return preloadedImages;
  }
}
