import { ServerEvent } from '../../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.games.puzzle.message.SolutionAck';

export class SolutionAck extends ServerEvent {
  word?: Array<number> = undefined;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
