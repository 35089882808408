export class Util {
  public static getShortCurrency(curr: string): string {
    switch (curr) {
      case 'USD':
        return '$';
      case 'EUR':
        return '\u20AC';
      case 'CHF':
        return ' CHF';
      case 'RSD':
        return ' din';
      default:
        return '\u20AC';
    }
  }

  public static isDateToday(date: Date | undefined) {
    if (!date) {
      return false;
    }

    const today = new Date();
    return date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate();
  }
}
