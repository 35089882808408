import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import React from 'react';
import { Collapse } from 'react-collapse';
import { Local } from 'common/src/lang/Local';
import { DisplayModel } from 'common/src/models/DisplayModel';
import { FAQModel } from 'common/src/models/dialogs/faq/FAQModel';
import { ShopTokensModel } from 'common/src/models/dialogs/shoptokens/ShopTokensModel';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';

interface FAQProps extends DialogProps {
  faqDialog?: FAQModel;
  shopTokens?: ShopTokensModel;
}

@inject('shopTokens')
@observer
export default class FAQ extends BaseDialog<FAQProps, {}> {
  QUESTION_1 = 1;

  QUESTION_2 = 2;

  QUESTION_3 = 3;

  QUESTION_4 = 4;

  QUESTION_5 = 5;

  @observable selected: number = -1;

  getCollapseDiv(title: string, text: string, position: number) {
    return (
      <div>
        <div
          className="faq__question"
          onClick={() => {
            this.selected = this.selected === position ? -1 : position;
          }}
        >
          {title}
        </div>
        <Collapse isOpened={this.selected === position}>
          <div className="faq__question faq__question--answer">{text}</div>
        </Collapse>
      </div>
    );
  }

  isVisible(): boolean {
    const { faqDialog } = this.props;
    return faqDialog ? faqDialog.isVisible() : false;
  }

  renderVisible() {
    const { faqDialog, shopTokens } = this.props;

    return (
      <div className="faq">
        <div className="faq__inner">
          <DialogHeader onClose={() => faqDialog!.hide()}>{Local.getString('faq.dlg_title')}</DialogHeader>
          {shopTokens!.provider > 0 ? (
            <div className="faq__description-box">
              {this.getCollapseDiv(Local.getString('faq.q1'), Local.getString('faq.a1'), this.QUESTION_1)}
              {this.getCollapseDiv(Local.getString('faq.q2'), Local.getString('faq.a2'), this.QUESTION_2)}
              {this.getCollapseDiv(Local.getString('faq.q3'), Local.getString('faq.a3'), this.QUESTION_3)}
              {this.getCollapseDiv(Local.getString('faq.q4'), Local.getString('faq.a4', faqDialog!.thirdPartyId), this.QUESTION_4)}
              {this.getCollapseDiv(Local.getString('faq.q5'), Local.getString('faq.a5'), this.QUESTION_5)}
            </div>
          ) : (
            <div className="faq__description-box">
              {this.getCollapseDiv(Local.getString('faq.q1'), Local.getString('faq.a1'), this.QUESTION_1)}
              {this.getCollapseDiv(Local.getString('faq.q2'), Local.getString('faq.a2b'), this.QUESTION_2)}
              {this.getCollapseDiv(Local.getString('faq.q3'), Local.getString('faq.a3b'), this.QUESTION_3)}
              {this.getCollapseDiv(Local.getString('faq.q4'), Local.getString('faq.a4', faqDialog!.thirdPartyId), this.QUESTION_4)}
            </div>
          )}
        </div>
      </div>
    );
  }
}
