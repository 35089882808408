import { DisplayMST } from '../../DisplayModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const FAQMST = DisplayMST.named('FAQMST')
  .props({
    thirdPartyId: ''
  })
  .views(self => ({}))
  .actions(self => ({
    setThirdPartyId(id: string) {
      self.thirdPartyId = id;
    }
  }));

type FAQModelType = typeof FAQMST.Type;
export interface FAQModel extends FAQModelType {}

export const createFAQModel = () =>
  FAQMST.create({
    key: DisplayModelKeys.FAQDialog,
    thirdPartyId: ''
  });
