import { GameButtonMST, GameButtonType } from '../GameButtonModel';
import { Local } from '../../../../lang/Local';

export const AssociationFieldMST = GameButtonMST.named('AssociationFieldMST')
  .props({
    id: 0,
    word: '',
    isOpened: false
  })
  .actions(self => ({
    setId(id: number) {
      self.id = id;
    },
    markAsBlueAnswered(text: string) {
      self.setEnabled(false);
      self.setBlue();
      self.setText(text);
      self.isOpened = true;
    },
    markAsRedAnswered(text: string) {
      self.setEnabled(false);
      self.setRed();
      self.setText(text);
      self.isOpened = true;
    },
    markAsEngineAnswered(text: string) {
      self.setEnabled(false);
      self.setYellow();
      self.setText(text);
      // self.isOpened = true;
    },
    setEnabled(enabled: boolean) {
      if (self.isOpened) return;
      self.action.setEnabled(enabled);
    },
    openHint(text: string) {
      self.setText(text);
      self.setYellow();
      self.isOpened = true;
      self.action.setEnabled(false);
    },
    resetModel(text: string) {
      self.setText(text);
      self.setRegular();
      self.isOpened = false;
      self.setEnabled(false);
    }
  }));

type AssociationFieldModelType = typeof AssociationFieldMST.Type;
export interface AssociationFieldModel extends AssociationFieldModelType {}
export const createHintField = (col: string, id: number) =>
  AssociationFieldMST.create({
    id,
    visible: true,
    text: `${col}${id + 1}`,
    type: GameButtonType.Regular
  });
