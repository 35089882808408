import { TimelineLite, TweenLite, TimelineMax } from 'gsap';
import GSAPAnimation from '../base/GSAPAnimation';

export class Fade extends GSAPAnimation {
  constructor(target: {} | null, duration: number, fromAlpha: number, toAlpha: number, repeat: number = 0) {
    const timeline = new TimelineMax({ repeat });

    if (target) {
      timeline.add(TweenLite.fromTo(target, duration, { opacity: fromAlpha }, { opacity: toAlpha }));
    }

    super(timeline);
  }
}
