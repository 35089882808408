import { PlayerAction } from '../../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.player.message.SendAppeal';

export class SendAppeal extends PlayerAction {
  gameType: number = 0;

  // fieldType: int
  gameOrder: number = 0;

  // fieldType: int
  description?: string = undefined;

  // fieldType: String
  violatorId: number = 0; // fieldType: long

  constructor(violatorId: number = 0) {
    super();
    this.__class = BINDING_NAME;
    this.violatorId = violatorId;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
