import React, { useContext, useEffect, useState } from 'react';
import CallObjectContext from '../../../CallObjectContext';
import { logDailyEvent } from '../Call/utils/logUtils';
import { TYPE_SETTINGS } from '../Icon/Icon';
import Settings from '../Settings/Settings';
import TrayButton, {
  TYPE_MUTE_CAMERA,
  TYPE_MUTE_MIC
} from '../TrayButton/TrayButton';
import './Tray.css';

/**
 * Gets [isCameraMuted, isMicMuted].
 * This function is declared outside Tray() so it's not recreated every render
 * (which would require us to declare it as a useEffect dependency).
 */
function getStreamStates(callObject) {
  let isCameraMuted,
    isMicMuted
  if (
    callObject &&
    callObject.participants() &&
    callObject.participants().local
  ) {
    const localParticipant = callObject.participants().local;
    isCameraMuted = !localParticipant.video;
    isMicMuted = !localParticipant.audio;
  }
  return [isCameraMuted, isMicMuted];
}

function getLocalParticipant(callObject) {
  if (
    callObject &&
    callObject.participants() &&
    callObject.participants().local
  ) 
    return callObject.participants().local;

  return null;
}

/**
 * Props:
 * - onClickLeaveCall: () => ()
 * - disabled: boolean
 */
export default function Tray(props) {
  const callObject = useContext(CallObjectContext);
  const [isCameraMuted, setCameraMuted] = useState(false);
  const [isMicMuted, setMicMuted] = useState(false);

  const [displaySettings, setSettingsDisplay] = useState(false);
  const [isHighlightedSettings, setSettingsHighlight] = useState(false);

  function toggleCamera() {
    callObject.setLocalVideo(isCameraMuted);
  }

  function toggleMic() {
    callObject.setLocalAudio(isMicMuted);
  }

  function openSettings(){
    setSettingsDisplay(!displaySettings);

    if (isHighlightedSettings) {
      setSettingsHighlight(!isHighlightedSettings);
    }
  }

  /**
   * Start listening for participant changes when callObject is set (i.e. when the component mounts).
   * This event will capture any changes to your audio/video mute state.
   */
  useEffect(() => {
    if (!callObject) return;

    function handleNewParticipantsState(event) {
      event && logDailyEvent(event);
      const [isCameraMuted, isMicMuted] = getStreamStates(
        callObject
      );
      setCameraMuted(isCameraMuted);
      setMicMuted(isMicMuted);
    }

    // Use initial state
    handleNewParticipantsState();

    // Listen for changes in state
    callObject.on('participant-updated', handleNewParticipantsState);

    // Stop listening for changes in state
    return function cleanup() {
      callObject.off('participant-updated', handleNewParticipantsState);
    };
  }, [callObject]);

  // setInputDevices(devices: {
  //   audioDeviceId?: string | false;
  //   audioSource?: MediaStreamTrack | false;
  //   videoDeviceId?: string | false;
  //   videoSource?: MediaStreamTrack | false;
  // }): DailyCall;

  function onCamChanged(event){
    
    console.log(event.target.value);
    const local = getLocalParticipant(callObject)
    console.log(local);
    
    callObject.setInputDevicesAsync(
      {
        videoDeviceId: event.target.value,
      }
    )
    .then((res) => {
      console.log(res);
      props.onDevicesChanged(res);
    });
  }

  function onAudioInputChanged(event){
    console.log(event.target.value);
    const local = getLocalParticipant(callObject)
    console.log(local);
    callObject.setInputDevicesAsync(
      {
        audioDeviceId: event.target.value,
      }
    )    
    .then((res) => {
      console.log(res);
      props.onDevicesChanged(res);
    });
  }

  function onAudioOutputChanged(event){
    console.log(event.target.value)
    callObject.setOutputDevice({outputDeviceId: event.target.value}).getInputDevices()
    .then((res) => {
      console.log(res);
      props.onDevicesChanged(res);
    });
  }
  
  return (
    <div className="tray">
      <div className="tray-container">
        <TrayButton
          type={TYPE_MUTE_CAMERA}
          disabled={props.disabled}
          highlighted={isCameraMuted}
          onClick={toggleCamera}
        />
        <TrayButton
          type={TYPE_MUTE_MIC}
          disabled={props.disabled}
          highlighted={isMicMuted}
          onClick={toggleMic}
        />
        <Settings onClickDisplay={displaySettings} 
            devices={props.devices} 
            camera={props.camera}
            mic={props.mic}
            speaker={props.speaker}
            onCamChanged={onCamChanged}
            onAudioInputChanged={onAudioInputChanged}
            onAudioOutputChanged={onAudioOutputChanged} />
        <TrayButton
          type={TYPE_SETTINGS}
          disabled={props.disabled}
          highlighted={displaySettings}
          onClick={openSettings}
        />
      </div>
    </div>
  );
}
