import { Serializable } from 'amfjs';

const BINDING_NAME = 'rs.slagalica.communication.message.Message';

export class MessageSerializable extends Serializable {
  constructor() {
    super('');
    this.__class = BINDING_NAME;
  }

  getClass() {
    return this.__class;
  }
}
