import React, { Component } from 'react';

export const TYPE_DEFAULT = 0;
export const TYPE_IMPORTANT = 1;
export const TYPE_NO_CLOSE = 2;

interface DialogHeaderProps {
  type?: number | undefined;
  onClose: Function;
  leftChild?: JSX.Element;
}

export default class DialogHeader extends Component<DialogHeaderProps> {
  render() {
    const { leftChild, children, onClose, type } = this.props;

    return (
      <div className={`dialog-header${type === TYPE_IMPORTANT ? ' dialog-header__important' : ''}`}>
        <div className={`dialog-header__left${type === TYPE_IMPORTANT ? ' dialog-header__important' : ''}`}>{leftChild}</div>
        <div className={`dialog-header__middle${type === TYPE_IMPORTANT ? ' dialog-header__important' : ''}`}>{children}</div>
        <div className={`dialog-header__right${type === TYPE_IMPORTANT ? ' dialog-header__important' : ''}`}>&nbsp;</div>
        {type !== TYPE_IMPORTANT && type !== TYPE_NO_CLOSE && (
          <button className="dialog-header__close" onClick={() => onClose()}>
            &nbsp;
          </button>
        )}
      </div>
    );
  }
}
