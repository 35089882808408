import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { AchievementsModel } from 'common/src/models/dialogs/achievements/AchievementsModel';
import { Local } from 'common/src/lang/Local';
import { RANK } from 'web/src/resources/RankResources';
import { RANK_VALUES } from 'common/src/resources/RankValues';
import { BaseDialog, DialogProps } from '../BaseDialog';
import AchievementItems from './AchievementItems';

interface AchievementsProps extends DialogProps {
  achievements?: AchievementsModel;
}

// @inject('achievements')
@observer
export default class Achievements extends BaseDialog<AchievementsProps, { test: boolean }> {
  @observable scrollTransitY: number = 0;

  callTransitY(value: number) {
    this.scrollTransitY += value;
  }

  isVisible(): boolean {
    if (this.props.achievements) return this.props.achievements.isVisible();
    return false;
  }

  renderVisible() {
    const { achievements } = this.props;
    const row = 1;
    const column = 0;
    const coomingSoon = 60 - achievements!.achievements.length;
    return (
      <div className="achievements">
        <div className="achievements__container">
          <button className="achievements__container__close" onClick={achievements!.hide} />
          <div className="achievements__season">{Local.getString('ach.cycle_cap', achievements!.season)}</div>
          <div className="achievements__season achievements__season--date">{achievements!.seasonDate}</div>
          <div className="achievements__season achievements__season--day">{Local.getString('ach.days_remaining', achievements!.seasonDayExpired)}</div>
          <div className="achievements__title">
            <img className="achievements__title-image" src={RANK.getBigImg(achievements!.rank)} />
            <h3 className="achievements__title-header"> {RANK_VALUES.getName(achievements!.rank)}</h3>
          </div>
          <span className="achievements__place-count achievements__place-count--first">x{achievements!.firstPlaceCount}</span>
          <span className="achievements__place-count achievements__place-count--second">x{achievements!.secondPlaceCount}</span>
          <span className="achievements__place-count achievements__place-count--third">x{achievements!.thirdPlaceCount}</span>
          <AchievementItems achievements={achievements} scrollTransitY={this.scrollTransitY} />
        </div>
      </div>
    );
  }
}
