import { types } from 'mobx-state-tree';
import { GameButtonMST, GameButtonType } from '../GameButtonModel';
import { AnimationBuilderMST } from '../../../AnimationBuilder';

export const MatchingFieldMST = GameButtonMST.named('MatchingFieldMST')
  .props({
    id: 0,
    pointsAnimation: types.optional(AnimationBuilderMST, {})
  })
  .actions(self => ({
    setId(id: number) {
      self.id = id;
    }
  }));

type MatchingFieldModelType = typeof MatchingFieldMST.Type;
export interface MatchingFieldModel extends MatchingFieldModelType {}
export const createLeftMatchField = (id: number) =>
  MatchingFieldMST.create({
    id,
    visible: true,
    text: '',
    type: GameButtonType.DarkBlue
  });

export const createRightMatchField = (id: number) =>
  MatchingFieldMST.create({
    id,
    visible: true,
    text: '',
    type: GameButtonType.Regular
  });
