import { APP } from '../app/AppInstance';
import { TrackerBase } from './TrackerBase';
import { TrackingActionIF } from './TrackingActionIF';
import { UserPropertyIF } from './userproperty/UserPropertyIF';

export class MultipleTracker implements TrackerBase {
  public trackers: Array<TrackerBase> = [];

  constructor(trackers: Array<TrackerBase>) {
    this.trackers = trackers;
  }

  public trackEvent(action: TrackingActionIF): void {
    if (!APP.Settings.isDebug()) {
      try {
        this.trackers.forEach((tracker: TrackerBase) => {
          tracker.trackEvent(action);
        });
      } catch (e) {
        console.log(`Error during tracking${action.getActionName()}`);
      }
    } else {
      console.log(`TRACKING ACTION: ${action.getCategory()} ${action.getPropertyName()}: ${action.getPropertyValue()}`);
    }
  }

  public trackUserProperty(property: UserPropertyIF): void {
    if (!APP.Settings.isDebug()) {
      try {
        this.trackers.forEach((tracker: TrackerBase) => {
          tracker.trackUserProperty(property);
        });
      } catch (e) {
        console.log(`Error during tracking${property.getPropertyName()}`);
      }
    } else {
      console.log(`TRACKING USER PRROPERTY: ${property.getPropertyName()}: ${property.getPropertyValue()}`);
    }
  }

  public trackError(errorName: string, errorDesc: string): void {
    if (!APP.Settings.isDebug()) {
      try {
        this.trackers.forEach((tracker: TrackerBase) => {
          tracker.trackError(errorName, errorDesc);
        });
      } catch (e) {
        console.log(`Error during tracking ${errorDesc}`);
      }
    } else {
      console.log(`TRACKING ERROR: ${errorDesc}`);
    }
  }
}
