import { TweenLite } from 'gsap';
import React, { Component } from 'react';
import { pageVisibilityApi } from '../../util/web/pageVisibilityUtils';

const { hidden, visibilityChange } = pageVisibilityApi();

export default class PageVisibilityObserver extends Component {
  componentDidMount() {
    if (visibilityChange) document.addEventListener(visibilityChange, this.handleVisibilityChange, false);

    document.addEventListener('focus', this.forceVisibilityTrue, false);
    document.addEventListener('blur', this.forceVisibilityFalse, false);

    window.addEventListener('focus', this.forceVisibilityTrue, false);
    window.addEventListener('blur', this.forceVisibilityFalse, false);
  }

  componentWillUnmount() {
    if (visibilityChange) document.removeEventListener(visibilityChange, this.handleVisibilityChange, false);

    document.removeEventListener('focus', this.forceVisibilityTrue, false);
    document.removeEventListener('blur', this.forceVisibilityFalse, false);

    window.removeEventListener('focus', this.forceVisibilityTrue, false);
    window.removeEventListener('blur', this.forceVisibilityFalse, false);
  }

  updateVisibility = (isVisible: boolean) => {
    // console.log('--------------- setVisibility ----------------' + isVisible);

    // When visibility event occurres we change animation settings to provide
    // animation execution when page is not visible or set default settings
    // for best animation experience
    TweenLite.ticker.useRAF(isVisible);
    TweenLite.lagSmoothing(isVisible ? 500 : 0, 33);
  };

  forceVisibilityTrue = () => {
    this.handleVisibilityChange(true);
  };

  forceVisibilityFalse = () => {
    this.handleVisibilityChange(false);
  };

  handleVisibilityChange = (forcedFlag: any) => {
    // this part handles when it's triggered by the focus and blur events
    if (typeof forcedFlag === 'boolean') {
      if (forcedFlag) {
        return this.updateVisibility(true);
      }
      return this.updateVisibility(false);
    }

    // this part handles when it's triggered by the page visibility change events
    if (document.hidden) {
      return this.updateVisibility(false);
    }

    return this.updateVisibility(true);
  };

  render(): React.ReactNode {
    return <div />;
  }
}
