import { ServerEvent } from '../../communication/message/ServerEvent';
import { PlayerInfoBase } from './PlayerInfoBase';

const BINDING_NAME = 'rs.slagalica.player.message.FriendsStatus';

export class FriendsStatus extends ServerEvent {
  friends?: Array<PlayerInfoBase> = [];

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
