import { TimelineLite } from 'gsap';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { QuestionModel } from 'common/src/models/game/content/quiz/QuestionModel';

import { QuestionStatus } from 'common/src/models/game/content/quiz/QuestionStatus';
import GSAPAnimation from '../../../../animations/base/GSAPAnimation';
import GSAPSequenceAnimation from '../../../../animations/base/GSAPSequenceAnimator';

interface QuestionProps {
  question?: QuestionModel;
  currentQuestion: number;
}

@observer
export default class Question extends Component<QuestionProps, {}> {
  divCurrentQuestion: HTMLDivElement | null = null;

  divCurrentNumber: HTMLDivElement | null = null;

  componentDidMount() {
    this.createAnimations();
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  getQuestionClass(status: number) {
    if (status === QuestionStatus.NotAnswered) {
      return 'not-answered';
    }
    if (status === QuestionStatus.RedAnswered) {
      return 'red-answered';
    }
    if (status === QuestionStatus.BlueAnswered) {
      return 'blue-answered';
    }
    return '';
  }

  createCurrentZoomInAnimation = () => {
    const seq = new GSAPSequenceAnimation();
    if (this.divCurrentNumber && this.divCurrentQuestion) {
      seq.add(new GSAPAnimation(new TimelineLite({ paused: true }).to(this.divCurrentNumber, 0.5, { opacity: 1 }, 0).to(this.divCurrentQuestion, 0.5, { scaleX: 1.25, scaleY: 1.25, opacity: 1, transformOrigin: 'center' }, 0)));
    }
    return seq;
  };

  createCurrentZoomOutAnimation = () => {
    const seq = new GSAPSequenceAnimation();
    if (this.divCurrentNumber && this.divCurrentQuestion) {
      seq.add(new GSAPAnimation(new TimelineLite({ paused: true }).to(this.divCurrentNumber, 0.5, { opacity: 0 }, 0).to(this.divCurrentQuestion, 0.5, { scaleX: 1, scaleY: 1, opacity: 1, transformOrigin: 'center' }, 0)));
    }
    return seq;
  };

  destroyAnimations() {
    if (this.props.question && this.props.question.questionInAnimation && this.props.question.questionOutAnimation) {
      this.props.question!.questionOutAnimation.dispose();
      this.props.question!.questionInAnimation.dispose();
    }
  }

  createAnimations() {
    this.props.question!.questionInAnimation.set(this.createCurrentZoomInAnimation);
    this.props.question!.questionOutAnimation.set(this.createCurrentZoomOutAnimation);
  }

  render() {
    const { question, currentQuestion } = this.props;
    if (!question) {
      return null;
    }

    if (currentQuestion === question.id) {
      return (
        <div
          className="question question__current}"
          ref={div => {
            this.divCurrentQuestion = div;
          }}
        >
          <div
            className="question__text"
            ref={div => {
              this.divCurrentNumber = div;
            }}
          >
            {question.id + 1}
          </div>
        </div>
      );
    }

    return <div className={`question question__${this.getQuestionClass(question!.status)}`} />;
  }
}
