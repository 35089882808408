import { inject, observer } from 'mobx-react';
import React from 'react';
import { InboxModel } from 'common/src/models/dialogs/inbox/InboxModel';
import { Local } from 'common/src/lang/Local';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';
import InboxMessage from './InboxMessage';

interface InboxProps extends DialogProps {
  inbox?: InboxModel;
}
@observer
export default class Inbox extends BaseDialog<InboxProps, {}> {
  isVisible(): boolean {
    if (this.props.inbox) return this.props.inbox.isVisible();
    return false;
  }

  getDescription(exist: boolean) {
    if (exist) {
      return <div className="inbox__description">{Local.getString('inbox.no_notifs')}</div>;
    }
    return <div />;
  }

  renderVisible() {
    const { inbox } = this.props;
    return (
      <div className="inbox">
        <div className="inbox__inner">
          <DialogHeader onClose={() => inbox!.acceptAction.performAction()}>{Local.getString('toolbar.inbox')}</DialogHeader>
          {this.getDescription(inbox!.messages === undefined || inbox!.messages.length === 0)}
          <div className="inbox__content_background" />
          <div className="inbox__content_holder">
            {inbox!.messages.map(message => (
              <InboxMessage message={message} />
            ))}
          </div>
          <button className="inbox__accept" onClick={() => inbox!.acceptAction.performAction()}>
            {Local.getString('common.close')}
          </button>
        </div>
      </div>
    );
  }
}
