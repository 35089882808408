import { types } from 'mobx-state-tree';
import { Reward } from '../../../messages/player/reward/message/Reward';
import { ActionMST } from '../../ActionModel';
import { AnimationBuilderMST } from '../../AnimationBuilder';
import { ButtonMST } from '../../ButtonModel';
import { createRewardItemModel, RewardItemMST } from '../../common/RewardItemModel';
import { DisplayMST } from '../../DisplayModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const HappyBirthdayMST = DisplayMST.named('HappyBirthdayMST')
  .props({
    reward: RewardItemMST,
    acceptReward: types.optional(ActionMST, {}),
    claimRewardAnimation: AnimationBuilderMST,
    claimButton: ButtonMST
  })
  .views(self => ({}))
  .actions(self => ({
    init(reward: Reward | undefined) {
      if (reward) {
        self.reward = createRewardItemModel(reward.type, reward.count);
      }
    },
    setClaimAction(action: Function) {
      self.claimButton.action.setAction(action);
    },
    claimReward() {
      self.claimRewardAnimation.build().play();
    }
  }));

type HappyBirthdayModelType = typeof HappyBirthdayMST.Type;
export interface HappyBirthdayModel extends HappyBirthdayModelType {}

export const createHappyBirthdayModel = () =>
  HappyBirthdayMST.create({
    key: DisplayModelKeys.HappyBirthdayDialog,
    reward: createRewardItemModel(0, 0),
    claimRewardAnimation: {},
    claimButton: {}
  });
