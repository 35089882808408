import React from 'react';
import VideoOffIcon from 'mdi-react/VideoOffIcon';
import VideoIcon from 'mdi-react/VideoIcon';
import MicrophoneOffIcon from 'mdi-react/MicrophoneOffIcon';
import MicrophoneIcon from 'mdi-react/MicrophoneIcon';

const TYPE_MUTE_CAMERA = 'camera';
const TYPE_MUTE_MIC = 'mute-mic';
const TYPE_SCREEN = 'screen';
const TYPE_LEAVE = 'leave';
const TYPE_CHAT = 'chat';
const TYPE_SETTINGS = 'settings';

interface IconProps {
  type: string;
  highlighted: boolean;
  className: string;
}

/**
 * Props:
 * - type: string
 * - highlighted: boolean
 */
export default function Icon(props: IconProps) {
  function getFillColor() {
    return props.highlighted ? '#fb5554' : '#000000';
  }
  function getPath() {
    switch (props.type) {
      case TYPE_MUTE_CAMERA: // --> OFF
        return props.highlighted ? <VideoOffIcon /> : <VideoIcon />;
      case TYPE_MUTE_MIC:
        return props.highlighted ? <MicrophoneOffIcon /> : <MicrophoneIcon />;
      case TYPE_SCREEN:
        return (
          <path
            fill={getFillColor()}
            fillRule="evenodd"
            d="M2 5.006C2 3.898 2.898 3 3.99 3h16.02C21.108 3 22 3.897 22 5.006v9.988A2.003 2.003 0 0 1 20.01 17H3.99C2.892 17 2 16.103 2 14.994V5.006zm2 .99v8.009c0 .54.448.995 1 .995h14c.555 0 1-.446 1-.995v-8.01c0-.54-.448-.995-1-.995H5c-.555 0-1 .446-1 .995zM7 20c0-.552.456-1 .995-1h8.01c.55 0 .995.444.995 1 0 .552-.456 1-.995 1h-8.01A.995.995 0 0 1 7 20z"
          />
        );
      case TYPE_LEAVE:
        return (
          <path
            d="M19.347 11l-1.71-1.638a.832.832 0 0 1 0-1.222l.03-.03a.922.922 0 0 1 1.27-.003l3.34 3.2a.95.95 0 0 1 0 1.386l-3.34 3.2a.918.918 0 0 1-1.27-.003l-.03-.03a.842.842 0 0 1 0-1.221L19.348 13h-7.352A.995.995 0 0 1 11 12c0-.552.456-1 .995-1h7.352zM16 10h-2V7.995c0-.54-.446-.995-.997-.995H6.997A1 1 0 0 0 6 7.995v8.01c0 .54.446.995.997.995h6.006a1 1 0 0 0 .997-.995V14h2v2.994A2.009 2.009 0 0 1 13.991 19H6.01A2.007 2.007 0 0 1 4 16.994V7.006C4 5.898 4.902 5 6.009 5h7.982C15.101 5 16 5.897 16 7.006V10z"
            fill={getFillColor()}
            fillRule="evenodd"
          />
        );
      case TYPE_CHAT:
        return <path d="M8.984 17.522c.93.308 1.949.478 3.016.478 4.418 0 8-2.91 8-6.5S16.418 5 12 5s-8 2.91-8 6.5c0 1.65.756 3.156 2.003 4.302a8.751 8.751 0 0 1-.71 2.187c-.317.606-.457 1.011 0 1.011 1.727 0 1.99-1.236 3.691-1.478z" fill={getFillColor()} fillRule="evenodd" />;
      case TYPE_SETTINGS:
        return (
          <path
            d="
            M21.3,10.2l-1.8-0.4c-0.2-0.6-0.4-1.2-0.7-1.8l1-1.5c0.3-0.5,0.3-1,0-1.4l-1-1c-0.4-0.4-0.9-0.3-1.4,0l-1.5,1
	c-0.6-0.3-1.2-0.6-1.8-0.7l-0.4-1.8c-0.1-0.5-0.5-0.9-1-0.9h-1.4c-0.5,0-0.9,0.4-1,0.9L9.9,4.4C9.2,4.6,8.7,4.8,8.1,5.1l-1.5-1
	c-0.5-0.3-1-0.3-1.4,0l-1,1c-0.4,0.4-0.3,0.9,0,1.4l1,1.5C4.8,8.7,4.6,9.3,4.4,9.9l-1.8,0.4c-0.5,0.1-0.9,0.5-0.9,1v1.4
	c0,0.5,0.4,0.9,0.9,1L4.4,14c0.2,0.6,0.4,1.2,0.7,1.8l-1,1.5c-0.3,0.5-0.3,1,0,1.4l1,1c0.4,0.4,0.9,0.3,1.4,0l1.5-1
	c0.6,0.3,1.2,0.6,1.8,0.7l0.4,1.8c0.1,0.5,0.5,0.9,1,0.9h1.4c0.5,0,0.9-0.4,1-0.9l0.4-1.8c0.6-0.2,1.2-0.4,1.8-0.7l1.5,1
	c0.5,0.3,1,0.3,1.4,0l1-1c0.4-0.4,0.3-0.9,0-1.4l-1-1.5c0.3-0.6,0.6-1.2,0.7-1.8l1.8-0.4c0.5-0.1,0.9-0.5,0.9-1v-1.4
	C22.2,10.7,21.8,10.4,21.3,10.2z M9.3,9.1c1.6-1.5,4.1-1.4,5.6,0.2c1.5,1.6,1.4,4.1-0.2,5.6c-1.6,1.5-4.1,1.4-5.6-0.2
	C7.6,13,7.7,10.5,9.3,9.1z"
            fill={getFillColor()}
            fillRule="evenodd"
          />
        );
      default:
        throw new Error();
    }
  }

  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" className={props.className} xmlns="http://www.w3.org/2000/svg">
      {getPath()}
    </svg>
  );
}

export { TYPE_MUTE_CAMERA, TYPE_MUTE_MIC, TYPE_SCREEN, TYPE_LEAVE, TYPE_CHAT, TYPE_SETTINGS };
