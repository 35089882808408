import { PlayerAction } from '../../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.player.message.StartGameResponse';

const NOT_COMFIRMED = 0; // fieldType: int
const ACCEPTED = 1; // fieldType: int
const DECLINED = 2; // fieldType: int

export class StartGameResponse extends PlayerAction {
  response: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get NOT_COMFIRMED() {
    return NOT_COMFIRMED;
  }

  static get ACCEPTED() {
    return ACCEPTED;
  }

  static get DECLINED() {
    return DECLINED;
  }
}
