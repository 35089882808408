import { MessageSerializable } from '../../../MessageSerializable';
import { Reward } from '../../reward/message/Reward';

const BINDING_NAME = 'rs.slagalica.player.achievements.message.Achievement';

export class Achievement extends MessageSerializable {
  id: number = 0;

  // fieldType: int
  userId: number = 0;

  // fieldType: long
  level: number = 0;

  // fieldType: int
  count: number = 0;

  // fieldType: int
  value: number = 0;

  // fieldType: int
  completed: boolean = false;

  // fieldType: boolean
  awarded: boolean = false;

  // fieldType: boolean
  awards?: Array<Reward> = undefined;

  // fieldType: ArrayList
  creationDate?: any = undefined;

  // fieldType: Calendar
  cyclesActive?: Array<number> = undefined; // fieldType: ArrayList

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
