import { observer } from 'mobx-react';
import React from 'react';
import { Local } from 'common/src/lang/Local';
import { DisplayModel } from 'common/src/models/DisplayModel';
import { DeclineGameModel } from 'common/src/models/dialogs/declinegame/DeclineGameModel';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';

interface DeclineGameProps extends DialogProps {
  declineGameDialog?: DeclineGameModel;
}
@observer
export default class DeclineGameDialog extends BaseDialog<DeclineGameProps, {}> {
  isVisible(): boolean {
    const { declineGameDialog } = this.props;
    return declineGameDialog ? declineGameDialog.isVisible() : false;
  }

  renderVisible() {
    const { declineGameDialog } = this.props;
    return (
      <div className="decline-game">
        <div className="decline-game__inner">
          <DialogHeader onClose={() => declineGameDialog!.hide()} />
          <div className="decline-game__description">{Local.getString('start.game_dlg_opp_cancel')}</div>
          <div className="decline-game__button-box">
            <button className="decline-game__button" onClick={() => declineGameDialog!.confirmAction.performAction()}>
              {Local.getString('common.yes')}
            </button>
            <button className="decline-game__button" onClick={() => declineGameDialog!.cancelAction.performAction()}>
              {Local.getString('common.no')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
