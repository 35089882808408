import { types } from 'mobx-state-tree';
import { BaseMST } from '../../../BaseModel';
import { AnimationBuilderMST } from '../../../AnimationBuilder';
import { QuestionStatus } from './QuestionStatus';

export const QuestionMST = BaseMST.named('QuestionMST')
  .props({
    id: 0,
    text: 'Question',
    status: QuestionStatus.NotAnswered,
    questionOutAnimation: types.optional(AnimationBuilderMST, {}),
    questionInAnimation: types.optional(AnimationBuilderMST, {})
  })
  .actions(self => ({
    setId(id: number) {
      self.id = id;
    },
    setText(text: string) {
      self.text = text;
    },
    setStatus(status: number) {
      self.status = status;
    }
  }));

type QuestionModelType = typeof QuestionMST.Type;
export interface QuestionModel extends QuestionModelType {}
export const createQuestion = (id: number) =>
  QuestionMST.create({
    id,
    text: `question:${id}`,
    status: QuestionStatus.NotAnswered,
    questionOutAnimation: {},
    questionInAnimation: {}
  });
