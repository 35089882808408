import { PlayerAction } from '../../communication/message/PlayerAction';

const BINDING_NAME = 'rs.slagalica.player.message.AndroidPayment';

const AndroidPaymentId = 103; // fieldType: int

export class AndroidPayment extends PlayerAction {
  playerTpId?: string = undefined;

  // fieldType: String
  transactionId?: string = undefined;

  // fieldType: String
  providerId: number = 0;

  // fieldType: int
  tokens: number = 0;

  // fieldType: int
  currency?: string = undefined;

  // fieldType: String
  amount: number = 0.0; // fieldType: double

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get AndroidPayment() {
    return AndroidPayment;
  }
}
