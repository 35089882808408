import { Device } from '../messages/player/message/Device';

export class FBUser {
  private _id: string;

  private _name: string;

  private _firstName: string;

  private _lastName: string;

  private _gender: string;

  private _isUser: boolean;

  private _locale: string;

  private _currency: string;

  private _isEligiblePromo: number = 0;

  public _userQualityFactor: number = 0;

  private _devices: Array<Device> = [];

  // my friends constructor
  constructor(id: string, name: string, firstName: string, lastName: string, pictureUrl: string = '', gender: string = '', currency: string = 'USD', locale: string = 'en_US') {
    this._id = id;
    this._name = name;
    this._firstName = firstName;
    this._lastName = lastName;
    this._isUser = true;
    this._locale = locale === undefined ? '' : locale;
    this._currency = currency;

    if (gender === 'female') this._gender = 'f';
    else if (gender === 'male') this._gender = 'm';
    else this._gender = 'unknown';
  }

  public getId(): string {
    return this._id;
  }

  public getName(): string {
    return this._name;
  }

  public getFirstName(): string {
    return this._firstName;
  }

  public getLastName(): string {
    return this._lastName;
  }

  public getFullName(): string {
    return `${this._firstName} ${this._lastName}`;
  }

  public getGender(): string {
    return this._gender;
  }

  public getCurrency(): string {
    return this._currency;
  }

  public isEligiblePromo(): boolean {
    return this._isEligiblePromo === 1;
  }

  public isAppUser(): boolean {
    return this._isUser;
  }

  public getLocale(): string {
    return this._locale;
  }

  public setQuality(q: number): void {
    this._userQualityFactor = q;
  }

  public setEligiblePromo(q: number): void {
    this._isEligiblePromo = q;
  }

  public addDevice(d: Device): void {
    this._devices.push(d);
  }

  public getDevices(): Array<Device> {
    return this._devices;
  }

  public getQuality(): number {
    return this._userQualityFactor;
  }
}
