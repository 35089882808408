import { ServerEvent } from '../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.player.message.FeatureConfig';

export class FeatureConfig extends ServerEvent {
  cheatingOn: boolean = true; // fieldType: boolean

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
