import { Local } from 'common/src/lang/Local';
import { Reward } from 'common/src/messages/player/reward/message/Reward';
import { AchievementWonModel } from 'common/src/models/dialogs/achievements/AchievementWonModel';
import { ACHIEVEMENTS } from 'web/src/resources/AchievementResorces';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { REWARDS } from 'web/src/resources/RewardResources';
import { Countdown } from '../../../animations/samples/Countdown';
import { Fade } from '../../../animations/samples/Fade';
import { GSAPPause } from '../../../animations/base/GSAPPause';
import { Rotate } from '../../../animations/samples/Rotate';
import { CurveTranslateTo } from '../../../animations/samples/Translate';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader, { TYPE_NO_CLOSE } from '../DialogHeader';
import GSAPSequenceAnimation from '../../../animations/base/GSAPSequenceAnimator';
import GSAPParallelAnimation from '../../../animations/base/GSAPParallelAnimation';

interface WonAchievementProps extends DialogProps {
  achievemntWonDialog?: AchievementWonModel;
}

@observer
export default class WonAchievement extends BaseDialog<WonAchievementProps, {}> {
  rewardsImageDivs: Map<Number, HTMLImageElement | null> = new Map<Number, HTMLImageElement | null>();

  rewardsImageFlyDivs: Map<Number, HTMLImageElement | null> = new Map<Number, HTMLImageElement | null>();

  rewardsPointsDivs: Map<Number, HTMLDivElement | null> = new Map<Number, HTMLDivElement | null>();

  createAnimations() {
    const { achievemntWonDialog } = this.props;
    achievemntWonDialog!.claimRewardAnimation.set(() => {
      const sequenceGlobal: GSAPSequenceAnimation = new GSAPSequenceAnimation();
      const parallel: GSAPParallelAnimation = new GSAPParallelAnimation();
      const sequenceFly: GSAPSequenceAnimation = new GSAPSequenceAnimation();
      const parallelFade: GSAPParallelAnimation = new GSAPParallelAnimation();
      const globalParallel: GSAPParallelAnimation = new GSAPParallelAnimation();

      this.rewardsImageDivs.forEach(image => {
        globalParallel.add(new Rotate(image, 0.5, 3));
      });
      this.rewardsImageFlyDivs.forEach(image => {
        globalParallel.add(new Rotate(image, 0.5, 2));
      });
      sequenceFly.add(new GSAPPause(1));

      let positionDistance = 0;
      this.rewardsImageFlyDivs.forEach((image, i) => {
        if (i === Reward.TYPE_TOKENS) {
          parallel.add(new CurveTranslateTo(image, 0.7, -328 - positionDistance, -416 - achievemntWonDialog!.position * 10, 2));
        } else if (i === Reward.TYPE_RATING_POINTS) {
          parallel.add(new CurveTranslateTo(image, 0.7, -66 - positionDistance, -416 - achievemntWonDialog!.position * 10, 2));
        }

        positionDistance += 40;
      });
      achievemntWonDialog!.rewards.forEach(reward => {
        const pointDiv = this.rewardsPointsDivs.get(reward.id) ? this.rewardsPointsDivs.get(reward.id) : null;
        if (pointDiv != null) parallel.add(new Countdown(pointDiv!, reward.count, 0));
      });

      this.rewardsImageFlyDivs.forEach(image => {
        parallelFade.add(new Fade(image, 0.1, 1, 0));
      });
      sequenceFly.add(parallel);

      globalParallel.add(sequenceFly);
      sequenceGlobal.add(globalParallel);

      sequenceGlobal.add(parallelFade);
      return sequenceGlobal;
    });
  }

  destroyAnimations() {
    this.props.achievemntWonDialog!.claimRewardAnimation.dispose();
  }

  componentDidMount() {
    this.createAnimations();
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  isVisible(): boolean {
    if (this.props.achievemntWonDialog) return this.props.achievemntWonDialog.isVisible();
    return false;
  }

  renderVisible() {
    const { achievemntWonDialog } = this.props;
    return (
      <div className="won-achievement">
        <div className="won-achievement__inner" style={{ marginTop: `${achievemntWonDialog!.position * 10}px` }}>
          <DialogHeader type={TYPE_NO_CLOSE} onClose={() => achievemntWonDialog!.hide()}>
            {Local.getString('ach.dlg_msg')}
          </DialogHeader>
          <div className="won-achievement__description">{ACHIEVEMENTS.getName(achievemntWonDialog!.id)}</div>
          <div className="won-achievement__description won-achievement__description--title">{ACHIEVEMENTS.getDesc(achievemntWonDialog!.id, achievemntWonDialog!.reqValue)}</div>
          <img src={ACHIEVEMENTS.getImg(achievemntWonDialog!.id)} className="won-achievement__icon" />
          <div className="won-achievement__rewarded">
            <div className="won-achievement__rewarded--open-gift-box" />
            <div className="won-achievement__rewarded--award-content">
              {achievemntWonDialog!.rewards.map(reward => (
                <div className="won-achievement__rewarded--award-icon-count">
                  <img
                    className="won-achievement__rewarded--award-tooltip-icon"
                    src={REWARDS.getImg(reward!.id)}
                    ref={img => {
                      this.rewardsImageDivs.set(reward!.id, img);
                    }}
                  />
                  <img
                    className="won-achievement__rewarded--award-tooltip-icon won-achievement__rewarded--award-tooltip-icon--fly"
                    src={REWARDS.getImg(reward!.id)}
                    ref={img => {
                      this.rewardsImageFlyDivs.set(reward!.id, img);
                    }}
                  />

                  <div
                    className="won-achievement__rewarded--award-tooltip-count"
                    ref={div => {
                      this.rewardsPointsDivs.set(reward!.id, div);
                    }}
                  >
                    {reward!.count}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button className="won-achievement__buy-now" onClick={achievemntWonDialog!.claimButton.action.performAction}>
            {achievemntWonDialog!.claimButton.getText()}
          </button>
        </div>
      </div>
    );
  }
}
