import { PlayerInfoBase } from '../messages/player/message/PlayerInfoBase';
import { SignIn } from '../messages/player/message/SignIn';
import { ModelFactory } from '../models/ModelFactory';
import { B2bLoginModel } from '../models/dialogs/login/B2bLoginModel';
import { LoginAccount } from './LoginAccount';

export class B2bAccount extends LoginAccount {
  private b2bLoginModel: B2bLoginModel;

  constructor(b2bLoginModel: B2bLoginModel) {
    super();
    this.b2bLoginModel = b2bLoginModel;
  }

  login(): void {
    if (this.onLoginSuccess) {
      const signIn = new SignIn();
      signIn.guestId = undefined;
      signIn.userType = PlayerInfoBase.TypeB2b;
      signIn.b2bTournament = this.b2bLoginModel.tournament;
      signIn.b2bTeam = this.b2bLoginModel.team;
      signIn.b2bName = this.b2bLoginModel.name;
      signIn.b2bId = this.b2bLoginModel.id;
      signIn.b2bMd5 = this.b2bLoginModel.md5;
      signIn.b2bAdmin = this.b2bLoginModel.admin;
      signIn.b2bSuperAdmin = this.b2bLoginModel.superAdmin;
      signIn.playerFacebookId = '-1';
      this.onLoginSuccess(signIn, PlayerInfoBase.TypeB2b);
    }
  }

  logout(): void {
    if (this.onLogout) this.onLogout();
  }

  private createShortName(shortenedName: string): string {
    return shortenedName!.split(' ')[0];
  }
}
