import { ServerEvent } from '../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.credits.message.RedeemResult';

const StatusSuccesfull = 0; // fieldType: int
const StatusWrongCode = 1; // fieldType: int

export class RedeemResult extends ServerEvent {
  status: number = 0;

  // fieldType: int
  tokens: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get StatusSuccesfull() {
    return StatusSuccesfull;
  }

  static get StatusWrongCode() {
    return StatusWrongCode;
  }
}
