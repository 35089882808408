import { Instance, types } from 'mobx-state-tree';
import { BaseMST } from '../BaseModel';

export const B2bGameMST = BaseMST.named('B2bGameMST')
  .props({
    startTime: types.Date,
    blueTeamId: types.string,
    redTeamId: types.string,
    gameStatus: 0,
    bluePoints: 0,
    redPoints: 0,
    round: 0
  })
  .views(self => ({}))
  .actions(self => ({
    setStartTime(st: Date) {
      self.startTime = st;
    },
    setBlueTeam(id: string) {
      self.blueTeamId = id;
    },
    setRedTeam(id: string) {
      self.redTeamId = id;
    },
    setGameStatus(gs: number) {
      self.gameStatus = gs;
    },
    setRound(r: number) {
      self.round = r;
    },
    setBluePoints(p: number) {
      self.bluePoints = p;
    },
    setRedPoints(p: number) {
      self.redPoints = p;
    }
  }));

type B2bGameModelType = typeof B2bGameMST.Type;
export interface B2bGameModel extends Instance<typeof B2bGameMST> {}

export const createB2bGameModel = () =>
  B2bGameMST.create({
    startTime: 0,
    blueTeamId: '',
    redTeamId: '',
    gameStatus: 0,
    bluePoints: 0,
    redPoints: 0,
    round: 0
  });
