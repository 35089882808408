import { CheaterStats } from '../ban/CheaterStats';
import { PlayerHistory } from '../history/message/PlayerHistory';
import { ListPosition } from '../league/message/ListPosition';
import { StorageContent } from '../store/message/StorageContent';
import { GameResult } from './GameResult';
import { PlayerInfoBase } from './PlayerInfoBase';

const BINDING_NAME = 'rs.slagalica.player.message.PlayerInfo';

const NOT_QUALIFIED = 0; // fieldType: int
const PAID_TO_QUALIFIED = 2; // fieldType: int
const QUALIFIED_BY_RESULT = 1; // fieldType: int
const GameStatusLose = -1; // fieldType: int
const GameStatusWon = 0; // fieldType: int
const GameStatusFriendly = -2; // fieldType: int
const NoBan = 0; // fieldType: int
const BanWarning = 1; // fieldType: int
const Banned = 2; // fieldType: int
const BannedAsFalseAccount = 7; // fieldType: int
const BannedAsCheater = 8; // fieldType: int
const UnBannedAsCheater = 9; // fieldType: int
const SourceFacebook = 0; // fieldType: int
const SourceExternal = 1; // fieldType: int
const StartDayDelay = -6; // fieldType: int

export class PlayerInfo extends PlayerInfoBase {
  thirdPartyId: string = '';

  // fieldType: String
  thirdPartyIdExternal: string = '';

  // fieldType: String
  goldTokens: number = 0;

  // fieldType: long
  silverTokens: number = 0;

  // fieldType: int
  fictitiousPoints: number = 1000;

  // fieldType: int
  winsInRowTotal: number = 0;

  // fieldType: int
  winsInRowTotalCurrent: number = 0;

  // fieldType: int
  rankListPoints?: ListPosition = undefined;

  // fieldType: ListPosition
  qualifiedForList: number = 0;

  // fieldType: int
  qualifiedHistory: number = 0;

  // fieldType: int
  playedGameResults?: Array<GameResult> = undefined;

  // fieldType: ArrayList
  repeatingAllowed: boolean = false;

  // fieldType: boolean
  maintain: boolean = false;

  // fieldType: boolean
  // lastLogInDate?: Calendar = undefined;

  // fieldType: Calendar
  banned: number = 0;

  // fieldType: int
  league: number = 0;

  // fieldType: int
  loginInRows: number = 0;

  // fieldType: int
  gender: number = -1;

  // fieldType: int
  totalFriendsCount: number = -1;

  // fieldType: int
  playingFriendsCount: number = -1;

  // fieldType: int
  timeZoneOffset: number = 0;

  // fieldType: int
  country?: string = undefined;

  // fieldType: String
  cheater: number = -1;

  // fieldType: int
  previousLogInDate?: any = undefined;

  // fieldType: Date
  storage?: StorageContent = undefined;

  // fieldType: StorageContent
  playerHistory?: PlayerHistory = undefined;

  // fieldType: PlayerHistory
  editMutex?: any = undefined;

  // fieldType: ReentrantLock
  firstLoginEver: number = 1;

  // fieldType: int
  winsInCycle: number = 0;

  // fieldType: int
  mail: string = '';

  // fieldType: String
  birthday?: any = undefined;

  // fieldType: Date
  boost: number = 1.0;

  // fieldType: double
  location: number = -1;

  // fieldType: int
  referral: number = 0;

  // fieldType: int
  shortcode?: string = undefined;

  // fieldType: String
  daysBetweenLogin: number = 0;

  // fieldType: int
  chetatStat?: CheaterStats = undefined;

  // fieldType: CheaterStats
  tournamentsCount: number = 0;

  // fieldType: int
  cal?: any = undefined;

  // fieldType: Calendar
  curr: number = 0; // fieldType: long

  // b2b string fields
  b2bId?: string = undefined;

  b2bName?: string = undefined;

  b2bOrganization?: string = undefined;

  b2bTeam?: string = undefined;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get NOT_QUALIFIED() {
    return NOT_QUALIFIED;
  }

  static get PAID_TO_QUALIFIED() {
    return PAID_TO_QUALIFIED;
  }

  static get QUALIFIED_BY_RESULT() {
    return QUALIFIED_BY_RESULT;
  }

  static get GameStatusLose() {
    return GameStatusLose;
  }

  static get GameStatusWon() {
    return GameStatusWon;
  }

  static get GameStatusFriendly() {
    return GameStatusFriendly;
  }

  static get NoBan() {
    return NoBan;
  }

  static get BanWarning() {
    return BanWarning;
  }

  static get Banned() {
    return Banned;
  }

  static get BannedAsFalseAccount() {
    return BannedAsFalseAccount;
  }

  static get BannedAsCheater() {
    return BannedAsCheater;
  }

  static get UnBannedAsCheater() {
    return UnBannedAsCheater;
  }

  static get SourceFacebook() {
    return SourceFacebook;
  }

  static get SourceExternal() {
    return SourceExternal;
  }

  static get StartDayDelay() {
    return StartDayDelay;
  }
}
