import AppealOpponent from 'web/src/components/dialogs/info/AppealOpponent';
import { DisplayMST } from '../../DisplayModel';
import { ActionMST } from '../../ActionModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const AppealOpponentMST = DisplayMST.named('AppealOpponentMST')
  .props({
    confirmAction: ActionMST
  })
  .views(() => ({}))
  .actions(() => ({}));

type AppealOpponentType = typeof AppealOpponentMST.Type;
export interface AppealOpponentModel extends AppealOpponentType {}

export const createAppealOpponent = () =>
  AppealOpponentMST.create({
    key: DisplayModelKeys.AppealOpponentDialog,
    confirmAction: {}
  });
