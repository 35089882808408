import { BaseMST } from '../../BaseModel';

export const StatisticsPercentageModelMST = BaseMST.named('StatisticsPercentageModel')
  .props({
    number: 0,
    title: ''
  })
  .views(self => ({}))
  .actions(self => ({}));

type StatisticsPercentageModelType = typeof StatisticsPercentageModelMST.Type;
export interface StatisticsPercentageModel extends StatisticsPercentageModelType {}

export const createStatisticsPercentageModel = (number: number, title: string) =>
  StatisticsPercentageModelMST.create({
    number,
    title
  });
