import { MessageSerializable } from '../../MessageSerializable';

const BINDING_NAME = 'rs.slagalica.player.message.Game';

const Lose = 0; // fieldType: int
const Won = 1; // fieldType: int
const Empty = 2; // fieldType: int

export class Game extends MessageSerializable {
  status: number = 0;

  // fieldType: int
  time?: any = undefined; // fieldType: Date

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get Lose() {
    return Lose;
  }

  static get Won() {
    return Won;
  }

  static get Empty() {
    return Empty;
  }
}
