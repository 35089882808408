import { PointsUpdate } from '../../../communication/message/PointsUpdate';

const BINDING_NAME = 'rs.slagalica.games.matching.message.MatchingResult';

export class MatchingResult extends PointsUpdate {
  questionIndex: number = 0;

  answerIndex: number = 0;

  correct: boolean = false;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
