import { Instance, types } from 'mobx-state-tree';
import { AnimationBuilderMST } from '../../AnimationBuilder';
import { ButtonMST, createClaimCaptainButton, createDeclineCaptainButton } from '../../ButtonModel';
import { GameContentMST } from '../../game/content/GameContentModel';

export const B2bCaptainMST = GameContentMST.named('B2bCaptainMST')
  .props({
    claimCaptainBtn: types.optional(ButtonMST, {}),
    declineCaptainBtn: types.optional(ButtonMST, {}),
    captain: types.string,
    captainName: types.string,
    gameName: types.string,
    opponentsCaptain: types.string,
    waitForCaptain: types.boolean,
    signUpCaptainAnimation: types.optional(AnimationBuilderMST, {})
  })
  .views(self => ({}))
  .actions(self => ({
    setCaptain(c: string) {
      self.captain = c;
    },
    setCaptainName(c: string) {
      self.captainName = c;
    },
    setOpponentsCaptain(c: string) {
      self.opponentsCaptain = c;
    },
    setWaitForCaptain(w: boolean) {
      self.waitForCaptain = w;
      self.claimCaptainBtn.setEnabled(true);
      self.declineCaptainBtn.setEnabled(true);
    },
    setBtnActions(claimCaptain: Function, declineCaptain: Function) {
      self.claimCaptainBtn.action.setAction(() => claimCaptain());
      self.declineCaptainBtn.action.setAction(() => declineCaptain());
    },
    setEnabled(d: boolean) {
      self.claimCaptainBtn.setEnabled(d);
      self.declineCaptainBtn.setEnabled(d);
    },
    setGameName(d: string) {
      self.gameName = d;
    }
  }));

export interface B2bCaptainModel extends Instance<typeof B2bCaptainMST> {}

export const createB2bCaptainModel = () =>
  B2bCaptainMST.create({
    visible: false,
    name: 'Captain',
    claimCaptainBtn: createClaimCaptainButton(),
    declineCaptainBtn: createDeclineCaptainButton(),
    captain: '',
    captainName: '',
    gameName: '',
    opponentsCaptain: '',
    waitForCaptain: false,
    signUpCaptainAnimation: {}
  });
