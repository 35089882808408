import { types } from 'mobx-state-tree';
import { Local } from '../../../lang/Local';
import { RedeemResult } from '../../../messages/credits/message/RedeemResult';
import { ActionMST } from '../../ActionModel';
import { DisplayMST } from '../../DisplayModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const VoucherMST = DisplayMST.named('VoucherMST')
  .props({
    voucherAction: types.optional(ActionMST, {}),
    voucherMessage: ''
  })
  .actions(self => ({
    reset() {
      self.voucherMessage = '';
    },
    setVoucherResult(result: RedeemResult) {
      self.voucherAction.setEnabled(true);
      if (result.status === RedeemResult.StatusSuccesfull) {
        self.voucherMessage = Local.getString('payment.dlg_voucher_success', result.tokens);
      } else {
        self.voucherMessage = Local.getString('payment.dlg_voucher_failed');
      }
    }
  }))
  .views(self => ({}));

type VoucherModelType = typeof VoucherMST.Type;
export interface VoucherModel extends VoucherModelType {}

export const createVoucherModel = () =>
  VoucherMST.create({
    key: DisplayModelKeys.VoucherDialog,
    voucherAction: {},
    voucherMessage: ''
  });
