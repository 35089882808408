import { types } from 'mobx-state-tree';
import { ItemDesc } from '../../../messages/player/store/ItemDesc';
import { DisplayMST } from '../../DisplayModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';
import { createShopItemModel, ShopItemModel, ShopItemMST } from './ShopItemModel';

const fiveCardsWidth = 582.4;

// function sortOffersByCount(input: Array<Offer>): Array<Offer> {
//   return input.sort((a: Offer, b: Offer) => a.count - b.count);
// }

export const ShopModelMST = DisplayMST.named('ShopModel')
  .props({
    tokens: types.number,
    selectedGroup: types.number,
    selectedTab: types.number,
    horizontalGroup: types.number,
    hatItems: types.array(ShopItemMST),
    chatItems: types.array(ShopItemMST)
  })
  .views(self => ({
    items() {
      switch (self.selectedGroup) {
        case 1:
          return self.hatItems;
        case 2:
          return self.chatItems;
        default:
          const defEmptyArray: Array<ShopItemModel> = [];
          return defEmptyArray;
      }
    },
    horizontalMove() {
      // number of cards
      let numberOfCards = 0;
      switch (self.selectedGroup) {
        case 1:
          numberOfCards = self.hatItems.length;
          break;
        case 2:
          numberOfCards = self.chatItems.length;
          break;
        default:
          numberOfCards = 0;
      }
      if (self.horizontalGroup === 0) return 0;
      // how many cards to move
      let restOfCards = numberOfCards - self.horizontalGroup * 5;
      if (restOfCards > 5) restOfCards = 5;
      if (restOfCards < 0) restOfCards = 0;
      return (-(self.horizontalGroup - 1) * fiveCardsWidth - (fiveCardsWidth * restOfCards) / 5) / 10;
    },
    showRightArrow() {
      return (self.horizontalGroup + 1) * 5 < this.items().length;
    },
    getHatItems(): Array<ShopItemModel> {
      return self.hatItems;
    }
  }))
  .actions(self => ({
    setHatItems(items: Array<ItemDesc>, itemAction: Function) {
      self.hatItems.clear();
      const sortedItems = items.slice().sort((a: ItemDesc, b: ItemDesc) => a.itemId - b.itemId);
      sortedItems.forEach((itemDesc: ItemDesc) => {
        const hatItem = createShopItemModel(itemDesc.itemId, itemDesc.groupId, 0, itemDesc.cost, '', itemDesc.countMax, false, itemDesc.available > 1, false, false);
        hatItem.itemAction.setAction(itemAction);
        self.hatItems.push(hatItem);
      });
    },
    setChatItems(items: Array<ItemDesc>, itemAction: Function) {
      self.chatItems.clear();
      items.forEach((itemDesc: ItemDesc) => {
        const chatItem = createShopItemModel(itemDesc.itemId, itemDesc.groupId, 0, itemDesc.cost, '', itemDesc.countMax, false, false, false, false);
        chatItem.itemAction.setAction(itemAction);
        self.chatItems.push(chatItem);
      });
    },
    onGroupSelected(group: number) {
      self.selectedGroup = group;
      self.selectedTab = group;
      self.horizontalGroup = 0;
    },
    increaseHorizontal() {
      self.horizontalGroup++;
    },
    decreaseHorizontal() {
      self.horizontalGroup--;
    },
    showItem(item: ItemDesc, shortMode: boolean = true, onCloseHandler: Function = () => {}): void {
      self.show(onCloseHandler);
    },
    setChatItemInUse(groupId: number, itemId: number) {
      self.chatItems.forEach((chatItem: ShopItemModel) => {
        if (chatItem.groupId === groupId && chatItem.id === itemId) chatItem.setPurchased();
      });
    },
    setHatItemInUse(itemId: number) {
      self.hatItems.forEach((hatItem: ShopItemModel) => {
        if (hatItem.id === itemId) hatItem.setPurchased();
      });
    }
  }));

type ShopModelType = typeof ShopModelMST.Type;
export interface ShopModel extends ShopModelType {}
export const createShopModel = () =>
  ShopModelMST.create({
    key: DisplayModelKeys.Shop,
    tokens: 0,
    selectedGroup: 1,
    selectedTab: 0,
    horizontalGroup: 0,
    hatItems: [],
    chatItems: []
  });
