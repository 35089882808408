import { MessageSerializable } from '../../../MessageSerializable';

const BINDING_NAME = 'rs.slagalica.player.league.message.ListPositionMaximum';

export class ListPositionMaximum extends MessageSerializable {
  maximum: number = 0;

  // fieldType: int
  date?: any = undefined;

  // fieldType: Date
  isTodayMaximum: boolean = false;

  // fieldType: boolean
  dayOfWeek: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
