import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { PulseLoader } from 'react-spinners';
import { Local } from '../../../../../../common/src/lang/Local';
import { MyNumberModel } from '../../../../../../common/src/models/game/content/mynumber/MyNumberModel';
import { GameInfoModel } from '../../../../../../common/src/models/game/GameInfoModel';
import GSAPParallelAnimation from '../../../../animations/base/GSAPParallelAnimation';
import { GSAPPause } from '../../../../animations/base/GSAPPause';
import GSAPSequenceAnimation from '../../../../animations/base/GSAPSequenceAnimator';
import { Fade } from '../../../../animations/samples/Fade';
import { Glow } from '../../../../animations/samples/Glow';
import { PointsAnimation } from '../../../../animations/samples/PointsAnimation';
import { ZoomIn } from '../../../../animations/samples/ZoomIn';
import { ZoomOut } from '../../../../animations/samples/ZoomOut';
import Images from '../../../../resources/ImgResources';
import GameButton from '../../GameButton';
import { GameContent, GameContentProps } from '../../GameContent';
import { GameStatus } from '../../GameStatus';
import Point from '../../Point';
import ProfilePicture from '../../ProfilePicture';

interface MyNumberProps extends GameContentProps {
  myNumber?: MyNumberModel;
  gameInfo?: GameInfoModel;
}

@inject('myNumber', 'gameInfo')
@observer
export default class MyNumber extends GameContent<MyNumberProps, {}> {
  blueResultNumberRef: HTMLDivElement | null = null;

  redResultNumberRef: HTMLDivElement | null = null;

  opponentContainer: HTMLDivElement | null = null;

  confirmButtonRef: HTMLButtonElement | null = null;

  stopButtonRef: HTMLButtonElement | null = null;

  eraseButtonRef: HTMLButtonElement | null = null;

  numbersContainerRef: HTMLDivElement | null = null;

  numberRefs: Array<GameButton | null> = [];

  numbeMiddleRefs: GameButton | null = null;

  numbeBigRefs: GameButton | null = null;

  operationsRefs: Array<GameButton | null> = [];

  myPoint: Point | null = null;

  opponentPoint: Point | null = null;

  @observable
  isShuffling = false;

  shufflingTimeoutId: NodeJS.Timeout | undefined = undefined;

  @observable
  animatedNumbers: Array<number> = [0, 0, 0, 0, 0, 0, 0];

  createMyResultBlinkAnimation = () => new Glow(this.props.gameInfo!.isPlayingAsBlue ? this.blueResultNumberRef : this.redResultNumberRef, 1, 5);

  createOpponentsResultBlinkAnimation = () => new Glow(!this.props.gameInfo!.isPlayingAsBlue ? this.blueResultNumberRef : this.redResultNumberRef, 1, 5);

  createMyPointsAnimation = () => PointsAnimation.create(this.props.gameInfo!.isPlayingAsBlue, this.myPoint);
  // {
  //   const seq = new GSAPSequenceAnimation();
  //   seq.add(new Glow(this.props.gameInfo!.isPlayingAsBlue ? this.blueResultNumberRef : this.redResultNumberRef, 1, 5));
  //   seq.add(PointsAnimation.create(this.props.gameInfo!.isPlayingAsBlue, this.myPoint));
  //   return seq;
  // };

  createOpponentPointsAnimation = () => PointsAnimation.create(!this.props.gameInfo!.isPlayingAsBlue, this.opponentPoint);
  // {
  //   const seq = new GSAPSequenceAnimation();
  //   seq.add(new Glow(!this.props.gameInfo!.isPlayingAsBlue ? this.blueResultNumberRef : this.redResultNumberRef, 1, 5));
  //   if (this.opponentPoint) seq.add(PointsAnimation.create(!this.props.gameInfo!.isPlayingAsBlue, this.opponentPoint));
  //   else console.log('opponent div null');
  //   return seq;
  // };

  createChoosingNumberAnimation = () => {
    const seq = new GSAPSequenceAnimation();
    if (this.stopButtonRef) {
      seq.add(new Fade(this.stopButtonRef, 0.5, 1, 0));
    }
    if (this.confirmButtonRef) {
      seq.add(new Fade(this.confirmButtonRef, 0.5, 0, 1));
    }
    return seq;
  };

  createShowOpponentSolutionAnimation = () => {
    if (this.opponentContainer === null) return new GSAPPause(0.05);

    const zoomIn = new ZoomIn(this.opponentContainer, 0.3);
    zoomIn.addStartListener(() => {
      this.props.myNumber!.opponentsSolution.setVisible(true);
    });
    zoomIn.addEndListener(() => {
      this.props.myNumber!.opponentsSolution.setOpponentProgressShown();
    });

    return zoomIn;
  };

  createHideBtnsAnimation = () => {
    const parallel: GSAPParallelAnimation = new GSAPParallelAnimation();
    if (this.confirmButtonRef !== null) parallel.add(new ZoomOut(this.confirmButtonRef, 0.3));
    if (this.eraseButtonRef !== null) parallel.add(new ZoomOut(this.eraseButtonRef, 0.3));
    parallel.addEndListener(() => {
      this.props.myNumber!.confirmBtn.setVisible(false);
      this.props.myNumber!.eraseBtn.setVisible(false);
    });

    return parallel;
  };

  createShufflingAnimation = () => {
    const pause = new GSAPPause(10000);
    pause.addStartListener(() => {
      this.isShuffling = true;
      this.shuffle();
    });
    pause.addEndListener(() => {
      this.isShuffling = false;
    });
    return pause;
  };

  shuffle() {
    this.shufflingTimeoutId = setTimeout(() => {
      if (this.isShuffling === false) {
        if (this.shufflingTimeoutId) clearTimeout(this.shufflingTimeoutId);
      } else {
        this.updateRandomNumbers();
        this.shuffle();
      }
    }, 40);
  }

  updateRandomNumbers() {
    this.animatedNumbers[0] = Math.round(Math.random() * 9);
    this.animatedNumbers[1] = Math.round(Math.random() * 9);
    this.animatedNumbers[2] = Math.round(Math.random() * 9);
    this.animatedNumbers[3] = Math.round(Math.random() * 9);

    this.animatedNumbers[4] = 10 + 5 * Math.round(Math.random() * 2);
    this.animatedNumbers[5] = 25 + 25 * Math.round(Math.random() * 3);

    this.animatedNumbers[6] = Math.round(Math.random() * 999);
  }

  createAnimations() {
    this.props.myNumber!.choosingNumbersAnimation.set(this.createChoosingNumberAnimation);
    this.props.myNumber!.mySolution!.resultBlinkAnimation.set(this.createMyResultBlinkAnimation);
    this.props.myNumber!.opponentsSolution!.resultBlinkAnimation.set(this.createOpponentsResultBlinkAnimation);
    this.props.myNumber!.mySolution!.pointsAnimation.set(this.createMyPointsAnimation);
    this.props.myNumber!.opponentsSolution!.pointsAnimation.set(this.createOpponentPointsAnimation);
    this.props.myNumber!.showOpponentSolutionAnimation.set(this.createShowOpponentSolutionAnimation);
    this.props.myNumber!.hideBtnsAnimation.set(this.createHideBtnsAnimation);
    this.props.myNumber!.shufflingAnimation.set(this.createShufflingAnimation);
  }

  destroyAnimations() {
    this.props.myNumber!.choosingNumbersAnimation.dispose();
    this.props.myNumber!.mySolution!.pointsAnimation.dispose();
    this.props.myNumber!.opponentsSolution!.pointsAnimation.dispose();
    this.props.myNumber!.showOpponentSolutionAnimation.dispose();
    this.props.myNumber!.hideBtnsAnimation.dispose();
    this.props.myNumber!.shufflingAnimation.dispose();
  }

  componentDidMount() {
    this.createAnimations();
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  isVisible(): boolean {
    const { myNumber } = this.props;
    return myNumber ? myNumber.isVisible() : false;
  }

  getBtnClassName(index: number) {
    let className = 'my-number__number-button';
    if (index === 4) {
      className += ' my-number__number-button--middle';
    } else if (index === 5) {
      className += ' my-number__number-button--big';
    }
    return className;
  }

  getBlueNumberResult() {
    if (this.props.gameInfo!.isPlayingAsBlue) {
      return this.props.myNumber!.mySolution!.number ? this.props.myNumber!.mySolution!.number : '???';
    }
    return this.props.myNumber!.opponentsSolution!.number ? this.props.myNumber!.opponentsSolution!.number : '???';
  }

  getRedNumberResult() {
    if (!this.props.gameInfo!.isPlayingAsBlue) {
      return this.props.myNumber!.mySolution!.number ? this.props.myNumber!.mySolution!.number : '???';
    }
    return this.props.myNumber!.opponentsSolution!.number ? this.props.myNumber!.opponentsSolution!.number : '???';
  }

  getTargetNumber() {
    if (this.isShuffling) return this.animatedNumbers[6];
    return this.props.myNumber!.targetNumber ? this.props.myNumber!.targetNumber : '???';
  }

  getShuffledNumberClassName(index: number) {
    let className = 'my-number__number-button game-button';
    if (index === 4) {
      className = 'my-number__number-button my-number__number-button--middle game-button';
    } else if (index === 5) {
      className = 'my-number__number-button my-number__number-button--big game-button';
    }
    return className;
  }

  renderVisible(): ReactNode {
    const { myNumber, gameInfo } = this.props;
    const t = (message: string) => Local.getString(message);
    if (!myNumber) {
      return null;
    }

    return (
      <div
        ref={contentRef => {
          this.contentRef = contentRef;
        }}
      >
        <GameStatus />
        <div className="my-number">
          <div className="my-number__progress">
            <div className="my-number__progress__final-number">
              <div className="my-number__progress__number">{this.getTargetNumber()}</div>
            </div>
            <div
              className="my-number__progress__number-box my-number__progress__number-box--blue"
              ref={div => {
                this.blueResultNumberRef = div;
              }}
            >
              <div className="my-number__progress__number my-number__progress__number--white">{this.getBlueNumberResult()}</div>
            </div>
            <div
              className="my-number__progress__number-box my-number__progress__number-box--red"
              ref={div => {
                this.redResultNumberRef = div;
              }}
            >
              <div className="my-number__progress__number my-number__progress__number--white">{this.getRedNumberResult()}</div>
            </div>
          </div>
          <div className="my-number__choosen_numbers_container">
            <ProfilePicture fbId={gameInfo!.getMe().fbId} b2bUrl={this.props.myNumber!.myUrl} css="my-number__player_image" size={50} />
            <div className="my-number__choosen_numbers_container--selected">{myNumber!.mySolution!.solution}</div>
            <button
              className="my-number__erase-button"
              disabled={!myNumber.eraseBtn.isEnabled()}
              hidden={!myNumber.eraseBtn.isVisible()}
              onClick={() => {
                myNumber.eraseBtn.action.performAction();
              }}
              ref={buttRef => {
                this.eraseButtonRef = buttRef;
              }}
            />
            <Point
              ref={point => {
                this.myPoint = point;
              }}
              baseClass="my-number__point"
              value={myNumber.mySolution.points}
            />
          </div>
          <div
            className="my-number__choosen_numbers_container my-number__choosen_numbers_container--opponent"
            ref={div => {
              this.opponentContainer = div;
            }}
            style={myNumber.opponentsSolution.isVisible() ? { visibility: 'visible' } : { visibility: 'hidden' }}
          >
            <ProfilePicture fbId={gameInfo!.getOpponent().fbId} b2bUrl={this.props.myNumber!.opponentUrl} css="my-number__player_image" size={50} />
            <div className="my-number__choosen_numbers_container--selected">{myNumber!.opponentsSolution!.solution}</div>
            <Point
              ref={point => {
                this.opponentPoint = point;
              }}
              baseClass="my-number__point"
              value={myNumber.opponentsSolution.points}
            />
          </div>
          <button
            className="my-number__submit_word_button"
            onClick={myNumber!.confirmBtn.action.performAction}
            disabled={!myNumber!.confirmBtn.isEnabled()}
            style={{ visibility: !myNumber.confirmBtn.isVisible() ? 'hidden' : 'visible' }}
            ref={buttRef => {
              this.confirmButtonRef = buttRef;
            }}
          >
            {Local.getString('common.confirm')}
          </button>
          <button
            className="my-number__stop_button"
            onClick={myNumber.stopBtn.action.performAction}
            disabled={!myNumber.stopBtn.isEnabled()}
            style={{ visibility: !myNumber.stopBtn.isVisible() ? 'hidden' : 'visible' }}
            ref={buttRef => {
              this.stopButtonRef = buttRef;
            }}
          >
            {Local.getString('my_number.stop')}
            <img className="my-number__stop_button-icon" src={Images.handStop} />
          </button>
          <div
            ref={number => {
              this.numbersContainerRef = number;
            }}
          >
            <div className="my-number__numbers-button-container">
              {myNumber!.numberBtns.map((x, index) =>
                this.isShuffling ? (
                  <div style={{ borderRadius: '0.4rem', boxShadow: 'rgba(255, 255, 255, 0) 0px 0px 0px 0px' }}>
                    <div className={this.getShuffledNumberClassName(index)}>{this.animatedNumbers[index]}</div>
                  </div>
                ) : (
                  <GameButton
                    key={x.text + index}
                    model={myNumber!.numberBtns[index]}
                    baseClass={this.getBtnClassName(index)}
                    ref={number => {
                      this.numberRefs[index] = number;
                    }}
                  />
                )
              )}
            </div>
            <div className="my-number__numbers-button-container">
              {myNumber!.operationBtns.map((x, index) => (
                <GameButton
                  key={x.text + index}
                  model={myNumber!.operationBtns[index]}
                  baseClass="my-number__number-button my-number__number-button--operation"
                  ref={operation => {
                    this.operationsRefs[index] = operation;
                  }}
                />
              ))}
            </div>
          </div>
          <div className="my-number__opponent_number_solving_progress">
            {myNumber!.opponentsSolution!.isSolving && myNumber!.opponentsSolution!.opponentProgressShown && (
              <div className="my-number__opponent_number_solving_text">
                <PulseLoader
                  color="#fede04"
                  sizeUnit="em"
                  size={0.6}
                  css="
                                    padding-right: 0.8rem;
                                    padding-left: 6rem;
                                    padding-top: 0.2rem;
                                    display: inline-block;
                                    transform: scale(1, 1);
                                    "
                />
                {t('common.opp_solving')}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
