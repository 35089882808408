import { Local } from 'common/src/lang/Local';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { BaseScreen, BaseScreenProps } from '../../components/BaseScreen';
import { CollaborationStatusModel } from '../model/CollaborationStatusModel';

interface StatusProps extends BaseScreenProps {
  collaborationStatus?: CollaborationStatusModel | undefined;
}

@inject('collaborationStatus')
@observer
export default class Status<P> extends BaseScreen<StatusProps, {}> {
  isVisible(): boolean {
    return this.props.collaborationStatus!.isVisible();
  }

  renderVisible() {
    return (
      <h1 className="status__section-title">
        {Local.getString('collaboration.cam-mic-error')}

        <button className="status__button" onClick={this.props.collaborationStatus!.retryButton.action.performAction}>
          {Local.getString('collaboration.retry-caption')}
        </button>
      </h1>
    );
  }
}
