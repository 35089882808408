import { ServerEvent } from '../../communication/message/ServerEvent';
import { PromotionAndroid } from './PromotionAndroid';

const BINDING_NAME = 'rs.slagalica.player.message.PromotionAndroidList';

export class PromotionAndroidList extends ServerEvent {
  allAndroidPromotions?: Array<PromotionAndroid> = undefined; // fieldType: ArrayList

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
