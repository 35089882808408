import { GameButtonMST, GameButtonType } from '../GameButtonModel';
import { ALPHABET } from '../../../../util/Alphabet';

export const LetterMST = GameButtonMST.named('LetterMST')
  .props({
    code: 0,
    refIndex: -1
  })
  .views(self => ({
    getTextFromCode(): string {
      return ALPHABET.getLetter(self.code);
    },
    getText(): string {
      return ALPHABET.getLetter(self.code);
    }
  }))
  .actions(self => ({
    setCode(code: number) {
      self.code = code;
      self.text = ALPHABET.getLetter(self.code);
    }
  }));

type LetterModelType = typeof LetterMST.Type;
export interface LetterModel extends LetterModelType {}
export const createRegularLetter = (refIndex: number) => LetterMST.create({ code: 0, refIndex, visible: true, type: GameButtonType.Regular });

export const createMyLetter = (code: number, refIndex: number, isBlue: boolean) => LetterMST.create({ code, refIndex, visible: true, type: isBlue ? GameButtonType.Blue : GameButtonType.Red });

export const createOpponentLetter = (code: number, isBlue: boolean) => LetterMST.create({ code, visible: true, type: isBlue ? GameButtonType.Red : GameButtonType.Blue, action: { enabled: false } });

export const createComputerLetter = (code: number) => LetterMST.create({ code, visible: true, type: GameButtonType.DarkBlue, action: { enabled: false } });
