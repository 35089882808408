export class MyNumberUtils {
  public static isValueAcceptable(value: string, expression: string): boolean {
    if (value == null || value.length === 0) return false;

    //  in case that there are some blank characters at the end of expression trim them
    const text = expression.trim();
    // StringUtil.trim(myAnswer.text);

    if (text.length === 0) {
      // empty expression
      // expression can't start with the following values
      if (value === '+' || value === '-' || value === '*' || value === '/' || value === ')') return false;
    } // expression already contains some values
    else {
      let lastChar: String = text.charAt(text.length - 1);
      if (lastChar === 'x') lastChar = '*';

      if (value === '+' || value === '-' || value === '*' || value === '/') {
        if (lastChar === '+' || lastChar === '-' || lastChar === '*' || lastChar === '/' || lastChar === '(') return false;
      } else if (value === ')') {
        if (lastChar === '+' || lastChar === '-' || lastChar === '*' || lastChar === '/' || lastChar === '(') return false;
        // count number of left brackets, must be greater than right brackets
        if (this.countCharacterInWord('(', text) <= this.countCharacterInWord(value, text)) return false;
      } else if (!(lastChar === '+' || lastChar === '-' || lastChar === '*' || lastChar === '/' || lastChar === '(')) return false;
    }

    return true;
  }

  public static countCharacterInWord(character: string, word: string): number {
    let count = 0;

    if (character == null || word == null || character.length !== 1 || word.length === 0) return count;

    for (let i = 0; i < word.length; i++) {
      if (word.charAt(i) === character) count++;
    }

    return count;
  }
}
