import { Local } from 'common/src/lang/Local';
import { BoosterModel } from 'common/src/models/booster/BoosterModel';
import { REMATCH_ACCEPT, REMATCH_DISABLED, REMATCH_WAITING, ResultModel } from 'common/src/models/game/content/ResultModel';
import { GameInfoModel } from 'common/src/models/game/GameInfoModel';
import { ToolbarModel } from 'common/src/models/toolbar/ToolbarModel';
import { TimelineMax } from 'gsap';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import GSAPAnimation from '../../../../animations/base/GSAPAnimation';
import { GameContent, GameContentProps } from '../../GameContent';
import { GameStatus } from '../../GameStatus';

const WON_FEATHER = 1;
const BOOSTER = 2;
const BOOSTER_FLY = 3;
const BOOSTER_SCORE = 4;
const DAILY_HIGHSCORE = 5;
const NEW_HIGHSCORE = 6;
const NEW_HIGHSCORE_IMAGE = 7;
const RATING_STAR = 8;
const RATING_STAR_FLY = 9;
const SCORE = 10;
const BACK_TO_LOBBY = 11;
// const REMATCH = 12;
const TODAY_BOOSTER = 13;

const NUMBER_OF_MOTO_MSG = 6;

interface GameResultProps extends GameContentProps {
  result?: ResultModel;
  booster?: BoosterModel;
  toolbar?: ToolbarModel;
  gameInfo?: GameInfoModel;
}
@inject('result', 'booster', 'toolbar', 'gameInfo')
@observer
export default class GameResult extends GameContent<GameResultProps, {}> {
  @observable scrollTransitY: number = 0;

  divs: Array<HTMLDivElement | null> = [];

  showResultBox() {
    this.scrollTransitY = this.scrollTransitY === 0 ? 18.5 : 0;
  }

  isVisible(): boolean {
    const { result } = this.props;
    return result ? result.isVisible() && !result.friendlyGame : false;
  }

  createAnimations() {
    const { result } = this.props;

    if (!result) return;

    result!.showBackToLobbyAnimation.set(() => {
      const divBackToLoby: any = this.divs[BACK_TO_LOBBY];
      return new GSAPAnimation(new TimelineMax({ paused: true }).to(divBackToLoby, 0.2, { opacity: 1 }));
    });

    this.scrollTransitY = 18.5;
  }

  destroyAnimations() {
    const { result } = this.props;

    if (!result) return;

    result!.wonFeathersAnimation.dispose();
    result!.boosterFlyAnimation.dispose();
    result!.newHighscoreAnimation.dispose();
    result!.starsWonCountDownAnimation.dispose();
    result!.boosterCountDownAnimation.dispose();
    result!.starFlyAnimation.dispose();
    result!.showBackToLobbyAnimation.dispose();
  }

  componentDidMount() {
    this.createAnimations();
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  getOffset(el: HTMLDivElement) {
    const rect = el.getBoundingClientRect();

    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  getRematchClassName() {
    switch (this.props.result!.rematchState) {
      case REMATCH_ACCEPT:
        return 'accept';
      case REMATCH_WAITING:
        return 'waiting';
      case REMATCH_DISABLED:
        return 'disabled';
      default:
        return 'default';
    }
  }

  getMotoMsg(winner: boolean): string {
    const numb: number = 1 + Math.floor(Math.random() * Math.floor(NUMBER_OF_MOTO_MSG)); // 1, 2, 3, ..., NUMBER_OF_MOTO_MSG
    return Local.getString(`b2b-results.${winner ? 'won-' : 'lose-'}${numb}`);
  }

  renderVisible(): React.ReactNode {
    const { winner, rating, dailyHighscore, feathers, boosterApplied } = this.props.result!;
    const { result, booster } = this.props;
    return (
      <div>
        <GameStatus />
        <div className="game-result">
          <div className="game-result__progress_holder">
            <div
              className="game-result__progress"
              style={{
                top: `${`${this.scrollTransitY}rem`}`
              }}
            >
              {result!.resultGames.map((game, index) => (
                <div key={index} className="game-result__progress__box">
                  <div className="game-result__progress__point">{game.bluePoints}</div>
                  <div className="game-result__progress__text">{game.name}</div>
                  <div className="game-result__progress__point game-result__progress__point--opponent">{game.redPoints}</div>
                </div>
              ))}
            </div>
          </div>
          <div className={`game-result__icon game-result__icon--${result!.winner ? 'won' : 'lose'}`}>
            <div className="game-result__moto">
              <p>{this.getMotoMsg(result!.winner)}</p>
            </div>
          </div>
          {/* <div className={this.scrollTransitY > 0 ? 'disabled-blurred-div' : ''}> */}
          <div>
            <div className="game-result__align-center">
              <button className="game-result__button" onClick={() => result!.backToLobbyAction.performAction()}>
                <div
                  className="game-result__button--text"
                  ref={div => {
                    this.divs[BACK_TO_LOBBY] = div;
                  }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
