import { types } from 'mobx-state-tree';
import { AnimationBuilderMST } from '../../../AnimationBuilder';
import { VisibleMST } from '../../../VisableModel';
import { LetterMST, LetterModel } from './LetterModel';

export const MyWordMST = VisibleMST.named('MyWordMST')
  .props({
    letters: types.array(LetterMST),
    points: 0,
    bonusPoints: 0,
    bonusAnimation: types.optional(AnimationBuilderMST, {}),
    extraBonusPoints: 0,
    extraBonusAnimation: types.optional(AnimationBuilderMST, {}),
    pointsAnimation: types.optional(AnimationBuilderMST, {})
  })
  .views(self => ({
    getDecodedWord(): string {
      let decodedWord = '';
      self.letters.forEach((letter: LetterModel) => {
        decodedWord += letter.getText();
      });
      return decodedWord;
    },
    getCodedWord(): Array<number> {
      const codedWord: Array<number> = [];
      self.letters.forEach((letter: LetterModel) => {
        codedWord.push(letter.code);
      });
      return codedWord;
    }
  }))
  .actions(self => ({
    removeLetter() {
      return self.letters.pop();
    },
    setPoints(points: number) {
      self.points = points;
    },
    setBonus(bonus: number) {
      self.bonusPoints = bonus;
    },
    setExtraBonusPoints(extraBonus: number) {
      self.extraBonusPoints = extraBonus;
    },
    reset() {
      self.visible = true;
      self.letters.clear();
      self.points = 0;
      self.bonusPoints = 0;
      self.extraBonusPoints = 0;
    }
  }));

type MyWordModelType = typeof MyWordMST.Type;
export interface WordModel extends MyWordModelType {}

export const createMyWordModel = () =>
  MyWordMST.create({
    visible: true,
    letters: [],
    points: 0,
    bonusPoints: 0,
    extraBonusPoints: 0
  });
