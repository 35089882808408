import { ServerEvent } from '../../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.player.store.message.StorageUpdateStatus';

const ITEM_BOUGHT = 0; // fieldType: int
const GAME_DELETED = 1; // fieldType: int

export class StorageUpdateStatus extends ServerEvent {
  updateReason: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get ITEM_BOUGHT() {
    return ITEM_BOUGHT;
  }

  static get GAME_DELETED() {
    return GAME_DELETED;
  }
}
