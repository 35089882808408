import { PlayerAction } from '../../messages/communication/message/PlayerAction';
import { ReadyEvent } from '../../messages/communication/message/ReadyEvent';
import { ServerEvent } from '../../messages/communication/message/ServerEvent';
import { StartControllerEvent } from '../../messages/communication/message/StartControllerEvent';
import { StopControllerEvent } from '../../messages/communication/message/StopControllerEvent';
import { ConnectionAdapter } from '../../sockets/ConnectionAdapter';
import { ConnectionListener } from '../../sockets/ConnectionListener';

export abstract class Controller implements ConnectionListener {
  protected controllerName: string;

  protected controllerId: string | undefined = undefined;

  protected connection: ConnectionAdapter;

  constructor(connection: ConnectionAdapter, name: string) {
    this.connection = connection;
    this.controllerName = name;
  }

  protected abstract finalizeController(): void;

  public abstract onControllerMessage(message: ServerEvent): void;

  public onMessage(message: ServerEvent): void {
    switch (message.getClass()) {
      case StartControllerEvent.ClassName:
        this.onStartController(message as StartControllerEvent);
        break;
      case StopControllerEvent.ClassName:
        this.onStopController(message as StopControllerEvent);
        break;
      default:
        break;
    }
    if (message.controllerId && this.controllerId === message.controllerId) this.onControllerMessage(message);
  }

  private onStartController(message: StartControllerEvent) {
    if (message.controllerName === this.controllerName) {
      this.controllerId = message.controllerId;
      this.sendMessage(new ReadyEvent());
    }
  }

  private onStopController(message: StartControllerEvent) {
    if (this.controllerId && message.controllerId === this.controllerId) {
      this.controllerId = undefined;
      this.stopController();
    }
  }

  public setControllerId(id: string) {
    this.controllerId = id;
  }

  public sendMessage(message: PlayerAction) {
    if (this.controllerId) {
      message.controllerId = this.controllerId;
      this.connection.sendMessage(message);
    }
  }

  public stopController() {
    this.controllerId = undefined;
    this.finalizeController();
  }

  public onBurstMessigingStart(): void {}

  public onBurstMessigingStop(): void {}

  public onConnected(reconnected: boolean): void {}

  public onConnectionError(message: string, reconnecting: boolean): void {}
}
