import { observer } from 'mobx-react';
import React from 'react';
import { Local } from 'common/src/lang/Local';
import { DisplayModel } from 'common/src/models/DisplayModel';
import { FeatherBoosterModel, MAX, BOUGHT } from 'common/src/models/dialogs/featherbooster/FeatherBoosterModel';
import Images from 'web/src/resources/ImgResources';
import { LobbyModel } from 'common/src/models/lobby/LobbyModel';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';
import GSAPSequenceAnimation from '../../../animations/base/GSAPSequenceAnimator';
import GSAPParallelAnimation from '../../../animations/base/GSAPParallelAnimation';
import { Rotate } from '../../../animations/samples/Rotate';
import { GSAPPause } from '../../../animations/base/GSAPPause';
import { Fade } from '../../../animations/samples/Fade';
import { CurveTranslateTo } from '../../../animations/samples/Translate';

interface FeatherBoosterProps extends DialogProps {
  featherBoosterDialog?: FeatherBoosterModel;
}
@observer
export default class FeatherBooster extends BaseDialog<FeatherBoosterProps, {}> {
  imageDiv: HTMLDivElement | null = null;

  imageFlyDiv: HTMLImageElement | null = null;

  isVisible(): boolean {
    const { featherBoosterDialog } = this.props;
    return featherBoosterDialog ? featherBoosterDialog.isVisible() : false;
  }

  createAnimations() {
    const { featherBoosterDialog } = this.props;
    featherBoosterDialog!.flyBooster.set(() => {
      const sequenceGlobal: GSAPSequenceAnimation = new GSAPSequenceAnimation();
      const parallel: GSAPParallelAnimation = new GSAPParallelAnimation();
      const sequenceFly: GSAPSequenceAnimation = new GSAPSequenceAnimation();
      const parallelFade: GSAPParallelAnimation = new GSAPParallelAnimation();
      const globalParallel: GSAPParallelAnimation = new GSAPParallelAnimation();

      globalParallel.add(new Rotate(this.imageDiv, 0.5, 2));
      globalParallel.add(new Rotate(this.imageFlyDiv, 0.5, 2));
      sequenceFly.add(new GSAPPause(1));

      parallel.add(new CurveTranslateTo(this.imageFlyDiv, 0.25, -130, -110, 4));

      parallelFade.add(new Fade(this.imageFlyDiv, 0.1, 1, 0));
      sequenceFly.add(parallel);

      globalParallel.add(sequenceFly);
      sequenceGlobal.add(globalParallel);

      sequenceGlobal.add(parallelFade);
      return sequenceGlobal;
    });
  }

  destroyAnimations() {
    this.props.featherBoosterDialog!.flyBooster.dispose();
  }

  componentDidMount() {
    this.createAnimations();
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  getStatusText(): string {
    switch (this.props.featherBoosterDialog!.status) {
      case MAX:
        return Local.getString('feather_booster.max_warning');
      case BOUGHT:
        return Local.getString('feather_booster.buy_warning');
      default:
        return '';
    }
  }

  renderVisible() {
    const { featherBoosterDialog } = this.props;

    return (
      <div className="feather-booster">
        <div className="feather-booster__inner">
          <DialogHeader onClose={() => featherBoosterDialog!.hide()}>{Local.getString('feather_booster.header')}</DialogHeader>
          <div className="feather-booster__description">{Local.getString('feather_booster.description')}</div>
          <div className="feather-booster__holder">
            <div className="feather-booster__holder__booster-icon">
              <img
                className="feather-booster__holder__booster-icon--fly-image"
                ref={div => {
                  this.imageDiv = div;
                }}
                src={Images.boosterIcon}
              />
              <img
                className="feather-booster__holder__booster-icon--fly-image"
                ref={img => {
                  this.imageFlyDiv = img;
                }}
                src={Images.boosterIcon}
              />
            </div>
            <div className="feather-booster__holder__title"> {Local.getString('feather_booster.holder_text')}</div>
            <div className="feather-booster__holder__tokens-silver-icon" />
          </div>
          <button className="feather-booster__accept" onClick={this.props.featherBoosterDialog!.buyAction.performAction}>
            {Local.getString('feather_booster.buy')}
          </button>
          <div className="feather-booster__status">{this.getStatusText()}</div>
        </div>
      </div>
    );
  }
}
