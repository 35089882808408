import { ServerEvent } from '../../../communication/message/ServerEvent';
import { LeagueHistory } from './LeagueHistory';

const BINDING_NAME = 'rs.slagalica.player.history.message.LeagueHistoryResponse';

export class LeagueHistoryResponse extends ServerEvent {
  leagueHistory?: Array<LeagueHistory> = undefined; // fieldType: ArrayList

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
