import { MessageSerializable } from '../../MessageSerializable';
import { ItemDesc } from './ItemDesc';

const BINDING_NAME = 'rs.slagalica.player.store.Item';

export class Item extends MessageSerializable {
  description?: ItemDesc = undefined;

  // fieldType: ItemDesc
  count: number = 0;

  // fieldType: int
  inUse: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  public isActivated(): boolean {
    return this.inUse > 0;
  }

  public available(): boolean {
    return this.count > 0;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
