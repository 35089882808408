import { Animation, Linear, TimelineLite, TweenMax } from 'gsap';
import GSAPAnimation from '../base/GSAPAnimation';

function createTextGlow(target: HTMLElement | null, duration: number, color: string, repeatCount: number = 1): Animation {
  const timeline = new TimelineLite({ paused: true });

  const textShadow = `-2px -2px 0 ${color},-2px 2px 0 ${color},2px -2px 0 ${color},2px 2px 0 ${color}`;
  if (target)
    timeline.add(
      TweenMax.fromTo(
        target,
        duration / repeatCount,
        {
          textShadow,
          opacity: 1
        },
        {
          textShadow,
          opacity: 0,
          repeat: repeatCount,
          yoyo: true,
          ease: Linear.easeNone
        }
      )
    );

  return timeline;
}

export class GlowText extends GSAPAnimation {
  constructor(target: HTMLElement | null, duration: number, color: string, repeatCount: number = 1) {
    super(createTextGlow(target, duration, color, repeatCount));
  }
}
