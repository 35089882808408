import { Message } from './Message';

const BINDING_NAME = 'rs.slagalica.communication.message.PlayerAction';

export class PlayerAction extends Message {
  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
