import AnimationAPI from '../animations/AnimationAPI';
import { AssociationController } from '../controllers/association/AssociationController';
import { ChatController } from '../controllers/chat/ChatController';
import { CombinationsController } from '../controllers/combinations/CombinationsController';
import { MatchingController } from '../controllers/matching/MatchingController';
import { MyNumberController } from '../controllers/mynumber/MyNumberController';
import { PairsController } from '../controllers/pairs/PairsController';
import { PlayerController } from '../controllers/player/PlayerController';
import { PuzzleController } from '../controllers/puzzle/PuzzleController';
import { QuizController } from '../controllers/quiz/QuizController';
import { FacebookAPI } from '../facebook/FacebookAPI';
import { LoginManager } from '../login/LoginManager';
import { SignIn } from '../messages/player/message/SignIn';
import { SignOut } from '../messages/player/message/SignOut';
import { DisplayModel } from '../models/DisplayModel';
import { LoaderModel } from '../models/loader/LoaderModel';
import { ModelFactory } from '../models/ModelFactory';
import { RootStore } from '../RootStore';
import { AppSettings, TypeNone } from '../settings/AppSettings';
import { ConnectionAdapter } from '../sockets/ConnectionAdapter';
import { TrackerBase } from '../tracking/TrackerBase';
import { ActionResolver } from './ActionResolver';
import { DialogManager } from './display/DialogManager';
import { DisplayConfig } from './display/DisplayConfig';
import { DisplayManager } from './display/DisplayManager';
import { ScreenManager } from './display/ScreenManager';
import { SoundPlayer } from './sound/SoundPlayer';

export class Application {
  private facebookAPI: FacebookAPI;

  private animationAPI: AnimationAPI;

  private actionResolver: ActionResolver;

  private settings: AppSettings;

  private tracker: TrackerBase;

  private soundPlayer: SoundPlayer;

  private connection: ConnectionAdapter;

  private loginManager: LoginManager;

  private displayManager: DisplayManager;

  constructor(facebookAPI: FacebookAPI, animationAPi: AnimationAPI, actionResolver: ActionResolver, loginManager: LoginManager, settings: AppSettings, tracker: TrackerBase, soundPlayer: SoundPlayer, displayConfig: DisplayConfig, rootStore: RootStore) {
    this.actionResolver = actionResolver;
    this.animationAPI = animationAPi;
    this.facebookAPI = facebookAPI;
    this.settings = settings;
    this.tracker = tracker;
    this.soundPlayer = soundPlayer;
    this.displayManager = new DisplayManager(displayConfig, new DialogManager(rootStore.activeDialogs), new ScreenManager());
    this.connection = new ConnectionAdapter();
    this.loginManager = loginManager;
    this.setUpControllers(rootStore);
  }

  public start(logo: DisplayModel, loader: LoaderModel) {
    this.loginManager.setLoginCallbacks(
      (signIn: SignIn, accountType: number) => {
        this.settings.setCurrentAccountType(accountType);
        loader.setLoginType(accountType);
        this.connection.connect(signIn);
      },
      (reason: string) => {
        ModelFactory.createServerInfo(reason).show();
      },
      () => {
        this.settings.setCurrentAccountType(TypeNone);
        this.connection.sendMessage(new SignOut());
        loader.setLoginType(TypeNone);
        loader.show();
      }
    );
  }

  private setUpControllers(rootStore: RootStore) {
    this.connection.subscribe(new PlayerController(this.connection, rootStore));
    this.connection.subscribe(new PuzzleController(this.connection, rootStore.puzzle, rootStore.gameInfo, rootStore.game, rootStore.user, rootStore.shop, rootStore.toolbar, rootStore.b2bTournament, rootStore.captain, rootStore.noCaptain, rootStore.collaboration));
    this.connection.subscribe(new MyNumberController(this.connection, rootStore.myNumber, rootStore.gameInfo, rootStore.game, rootStore.user, rootStore.shop, rootStore.toolbar, rootStore.b2bTournament, rootStore.captain, rootStore.noCaptain, rootStore.collaboration));
    this.connection.subscribe(new CombinationsController(this.connection, rootStore.combinations, rootStore.gameInfo, rootStore.game, rootStore.user, rootStore.shop, rootStore.toolbar, rootStore.b2bTournament, rootStore.captain, rootStore.noCaptain, rootStore.collaboration));
    this.connection.subscribe(new QuizController(this.connection, rootStore.quiz, rootStore.gameInfo, rootStore.game, rootStore.user, rootStore.shop, rootStore.toolbar, rootStore.b2bTournament, rootStore.captain, rootStore.noCaptain, rootStore.collaboration));
    this.connection.subscribe(new PairsController(this.connection, rootStore.pairs, rootStore.gameInfo, rootStore.game, rootStore.user, rootStore.shop, rootStore.toolbar, rootStore.b2bTournament, rootStore.captain, rootStore.noCaptain, rootStore.collaboration));
    this.connection.subscribe(new MatchingController(this.connection, rootStore.matching, rootStore.gameInfo, rootStore.game, rootStore.user, rootStore.shop, rootStore.toolbar, rootStore.b2bTournament, rootStore.captain, rootStore.noCaptain, rootStore.collaboration));
    this.connection.subscribe(new AssociationController(this.connection, rootStore.association, rootStore.gameInfo, rootStore.game, rootStore.user, rootStore.shop, rootStore.toolbar, rootStore.b2bTournament, rootStore.captain, rootStore.noCaptain, rootStore.collaboration));
    this.connection.subscribe(new ChatController(this.connection, rootStore.chat));
  }

  get AnimationAPI(): AnimationAPI {
    return this.animationAPI;
  }

  get FacebookAPI(): FacebookAPI {
    return this.facebookAPI;
  }

  get ActionResolver(): ActionResolver {
    return this.actionResolver;
  }

  get Settings(): AppSettings {
    return this.settings;
  }

  get Tracker(): TrackerBase {
    return this.tracker;
  }

  get SoundPlayer(): SoundPlayer {
    return this.soundPlayer;
  }

  get LoginManager(): LoginManager {
    return this.loginManager;
  }

  get DisplayManeger(): DisplayManager {
    return this.displayManager;
  }
}
