import { Local } from 'common/src/lang/Local';
import { SettingsModel } from 'common/src/models/settings/SettingsModel';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

interface B2bSoundProps {
  settings?: SettingsModel;
  className?: string;
}

@inject('settings')
@observer
export default class B2bSound extends Component<B2bSoundProps, {}> {
  getToolTip(text: string, bootom: string) {
    return (
      <div className={`nav__tooltip--title${bootom}`}>
        <div className="nav__tooltip--title__text">{text}</div>
      </div>
    );
  }

  render(): React.ReactNode {
    const { settings, className } = this.props;
    const t = (message: string) => Local.getString(message);
    return (
      <div className={`nav__toolbar-sound nav__toolbar-sound--${settings!.sounds ? 'on' : 'off'} ${className}`} style={{ position: 'absolute', left: '0.5rem', top: '0.5rem' }} onClick={settings!.toggleSoundsOptionVisibility}>
        {this.getToolTip(t(settings!.sounds ? 'toolbar.sounds_off' : 'toolbar.sounds_on'), '--bootom')}
      </div>
    );
  }
}
