import { TimelineLite } from 'gsap';
import Point from '../../components/game/Point';
import { Fade } from './Fade';
import { GlowText } from './GlowText';
import { GSAPPause } from '../base/GSAPPause';
import GSAPSequenceAnimation from '../base/GSAPSequenceAnimator';
import { TranslateTo } from './Translate';
import { ZoomInAndOut } from './ZoomInAndOut';
import GSAPParallelAnimation from '../base/GSAPParallelAnimation';

// DEFAULT koristiti na svim mestima gde je potrebna point animacija
export class PointsAnimation extends GSAPSequenceAnimation {
  constructor(colorRes: string, point: Point | null) {
    super();

    if (point !== null) {
      this.add(new GlowText(point.div, 0, colorRes, 0));
      this.add(new ZoomInAndOut(point.div, 0.2, 1));
      this.add(new GSAPPause(0.5));

      const parallel: GSAPParallelAnimation = new GSAPParallelAnimation();
      parallel.add(new TranslateTo(point.div, 0.5, 0, -50, 0));
      parallel.add(new Fade(point.div, 0.5, 1, 0));
      this.add(parallel);
      this.add(new TranslateTo(point.div, 0, 0, 0, 0));
    }
  }

  static create(isBlue: boolean, point: Point | null): GSAPPause {
    const pause: GSAPPause = new GSAPPause(1.2);
    pause.addStartListener(() => {
      new PointsAnimation(isBlue ? '#01a2f1' : '#f1493c', point).play();
    });
    return pause;
  }
}
