import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { GameButtonModel, GameButtonType } from 'common/src/models/game/content/GameButtonModel';
import { ZoomIn } from '../../animations/samples/ZoomIn';
import { ZoomOut } from '../../animations/samples/ZoomOut';

export const buttonsClassMap: { [id: number]: { active: string; disabled: string } } = {};
buttonsClassMap[GameButtonType.Regular] = {
  active: 'game-button',
  disabled: 'game-button-disabled'
};

buttonsClassMap[GameButtonType.Red] = {
  active: 'game-button-red',
  disabled: 'game-button-red'
};

buttonsClassMap[GameButtonType.Blue] = {
  active: 'game-button-blue',
  disabled: 'game-button-blue'
};

buttonsClassMap[GameButtonType.DarkBlue] = {
  active: 'game-button-darkblue',
  disabled: 'game-button-darkblue'
};

buttonsClassMap[GameButtonType.Yellow] = {
  active: 'game-button-yellow',
  disabled: 'game-button-yellow'
};

buttonsClassMap[GameButtonType.BlueRed] = {
  active: 'game-button-blue-red',
  disabled: 'game-button-blue-red'
};

export const hiddenClass = 'game-button-hidden';

@observer
export default class GameButton extends Component<{
  model?: GameButtonModel | null;
  boxClass?: string;
  baseClass: string;
  baseImgClass?: string;
}> {
  boxRef: HTMLDivElement | null = null;

  buttonRef: HTMLButtonElement | null = null;

  componentDidMount() {
    this.createAnimations();
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  getCurrentClass() {
    const activeClass = this.props.model!.isEnabled() ? buttonsClassMap[this.props.model!.type].active : buttonsClassMap[this.props.model!.type].disabled;
    return this.props.model!.visible ? activeClass : hiddenClass;
  }

  createAnimations() {
    this.props.model!.appearAnimation.set(() => new ZoomIn(this.buttonRef, 0.1));
    this.props.model!.disappearAnimation.set(() => new ZoomOut(this.buttonRef, 0.1));
  }

  destroyAnimations() {
    if (this.props.model!.appearAnimation) this.props.model!.appearAnimation.dispose();
    if (this.props.model!.disappearAnimation) this.props.model!.disappearAnimation.dispose();
  }

  render() {
    const { model, boxClass, baseClass, baseImgClass } = this.props;
    if (!model) {
      return null;
    }

    return (
      <div
        className={boxClass}
        style={{ borderRadius: '0.4rem' }}
        ref={boxRef => {
          this.boxRef = boxRef;
        }}
      >
        <button
          onClick={() => {
            model!.action.performAction();
          }}
          className={[baseClass, this.getCurrentClass()].join(' ')}
          disabled={!model.isEnabled()}
          style={model!.visible ? { visibility: 'visible' } : { visibility: 'hidden' }}
          ref={buttRef => {
            this.buttonRef = buttRef;
          }}
        >
          {model.visible ? model.getText() : ''}
          <div className={baseImgClass} />
        </button>
      </div>
    );
  }
}
