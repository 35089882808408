import { inject, observer } from 'mobx-react';
import React from 'react';
import axios from 'axios';
import { LoaderModel } from 'common/src/models/loader/LoaderModel';
import Images from 'web/src/resources/ImgResources';
import { APP } from 'common/src/app/AppInstance';
import { BaseScreen, BaseScreenProps } from '../BaseScreen';

interface LoaderProps extends BaseScreenProps {
  loader?: LoaderModel;
}
@inject('loader')
@observer
export default class LoaderScreen extends BaseScreen<LoaderProps, {}> {
  progress: number = 0;

  componentDidMount = () => {
    const { loader } = this.props;

    // wait 3 sec for aparteko splash screen
    setTimeout(() => {
      this.preloadImages();
    }, 3000);

    const up = setInterval(() => {
      if (loader!.loadingProgress >= 99) {
        clearInterval(up);
        setTimeout(() => {
          loader!.onAssetsLoaded.performAction();
        }, 1000);
      }
    }, 1000);
  };

  preloadImages = () => {
    const { loader } = this.props;

    const size = Images.getPreloadedImages().length;
    if (size > 0) {
      Images.getPreloadedImages().forEach(image => {
        axios
          .get(image)
          .then(() => {
            if (loader) {
              loader.setLoadingProgress(loader.loadingProgress + 100 / size);
            }
          })
          .catch(() => {
            if (loader) {
              loader.setLoadingProgress(loader.loadingProgress + 100 / size);
            }
          });
      });
    } else {
      loader!.setLoadingProgress(100);
    }
  };

  getProgress() {
    const { loader } = this.props;
    if (!loader) {
      return '0%';
    }
    return `${loader.loadingProgress}%`;
  }

  isVisible(): boolean {
    const { loader } = this.props;
    if (loader !== undefined) {
      return loader.isVisible();
    }
    return false;
  }

  renderVisible() {
    const { loader } = this.props;
    return (
      <div className={`aparteko-progress-loader${loader && loader.isAssetLoaded() ? ' aparteko-progress-loader--loaded' : ''}`}>
        <div className="aparteko-progress-loader__loading-progress-frame">
          <div className="aparteko-progress-loader__loading-progress-background">
            <div className="aparteko-progress-loader__loading-progress-indicator" style={{ width: this.getProgress() }} />
          </div>
        </div>
      </div>
    );
  }
}
