import { ServerEvent } from '../../communication/message/ServerEvent';
import { UserPromotion } from './UserPromotion';

const BINDING_NAME = 'rs.slagalica.player.message.PromotionResponse';

export class PromotionResponse extends ServerEvent {
  allUserPromotions?: Array<UserPromotion> | undefined;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
