import { AssociationFieldModel } from 'common/src/models/game/content/association/AssociationFieldModel';
import { AssociationResultModel } from 'common/src/models/game/content/association/AssociationResultModel';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Glow } from '../../../../animations/samples/Glow';
import { PointsAnimation } from '../../../../animations/samples/PointsAnimation';
import GameButton from '../../GameButton';
import Point from '../../Point';
import AssociationResultButton from './AssociationResultButton';
import GSAPSequenceAnimation from '../../../../animations/base/GSAPSequenceAnimator';
import { Glow3D } from '../../../../animations/samples/Glow3D';

interface AssociationColumnProps {
  model?: AssociationResultModel;
  hint?: Array<AssociationFieldModel>;
  typeColumn?: string;
  reverse?: boolean;
}

@observer
export default class AssociationColumn extends Component<AssociationColumnProps> {
  MARGIN_LEFT = 3.5;

  columnButtons: Array<GameButton | null> = [];

  columnFinalButton: AssociationResultButton | null = null;

  point: Point | null = null;

  componentDidMount() {
    this.createAnimations();
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  getMarginLeft(column: string, field: AssociationFieldModel) {
    const { hint } = this.props;
    let move = 0;
    if (column === 'a') {
      move =
        hint!
          .slice()
          .reverse()
          .indexOf(field) * this.MARGIN_LEFT;
    } else if (column === 'b') {
      move =
        hint!
          .slice()
          .reverse()
          .indexOf(field) * -this.MARGIN_LEFT;
    } else if (column === 'c') {
      move = hint!.indexOf(field) * -this.MARGIN_LEFT;
    } else if (column === 'd') {
      move = hint!.indexOf(field) * this.MARGIN_LEFT;
    }
    return `${move}rem`;
  }

  createPointAnimation = (args: any[]) => {
    const isBlue: boolean = args[0];
    if (this.point) return PointsAnimation.create(isBlue, this.point);
    return new GSAPSequenceAnimation();
  };

  createOpenColumnAnimation = () => {
    const seq = new GSAPSequenceAnimation();
    this.columnButtons.forEach(x => {
      seq.add(new Glow3D(x!.boxRef, 0.1, 1));
    });
    seq.add(new Glow3D(this.columnFinalButton!.boxRef!, 0.1, 1));
    return seq;
  };

  createAnimations() {
    this.props.model!.openAnimation.set(this.createOpenColumnAnimation);
    this.props.model!.pointsAnimation.set(this.createPointAnimation);
  }

  destroyAnimations() {
    this.props.model!.openAnimation.dispose();
    this.props.model!.pointsAnimation.dispose();
  }

  render() {
    const { model, hint, typeColumn, reverse } = this.props;
    const hintArray = reverse ? hint!.slice().reverse() : hint;
    return (
      <div className={`association__column association__column--${typeColumn}`}>
        {!reverse && (
          <div>
            <AssociationResultButton
              key={model!.id}
              model={model!}
              baseClass={`association__hint association__hint__result association__hint__result--${typeColumn}`}
              ref={game => {
                this.columnFinalButton = game;
              }}
            />
            <Point
              baseClass={`association__point association__point--${typeColumn}`}
              value={model ? model.points : 0}
              ref={point => {
                this.point = point;
              }}
            />
          </div>
        )}
        {hintArray!.map(x => (
          <div style={{ marginLeft: this.getMarginLeft(typeColumn || '', x) }} key={x.id}>
            <GameButton
              key={x.id}
              model={x}
              boxClass="association__hint-box"
              baseClass="association__hint"
              ref={game => {
                this.columnButtons[x.id] = game;
              }}
            />
          </div>
        ))}
        {reverse && (
          <div>
            <AssociationResultButton
              key={model!.id}
              model={model!}
              baseClass={`association__hint association__hint__result association__hint__result--${typeColumn}`}
              ref={game => {
                this.columnFinalButton = game;
              }}
            />
            <Point
              baseClass={`association__point association__point--${typeColumn}`}
              value={model ? model.points : 0}
              ref={point => {
                this.point = point;
              }}
            />
          </div>
        )}
      </div>
    );
  }
}
