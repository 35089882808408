import { inject, observer } from 'mobx-react';
import React from 'react';
import { BaseScreen, BaseScreenProps } from '../../../components/BaseScreen';
import { CollaborationModel } from '../../model/CollaborationModel';

declare global {
  interface Window {
    arenaim: any;
  }
}

interface ArenaChatProps extends BaseScreenProps {
  collaboration?: CollaborationModel;
  global: boolean;
}

@inject('collaboration')
@observer
export default class ArenaChat<P> extends BaseScreen<ArenaChatProps, {}> {
  isVisible() {
    // return (this.props.collaboration!.globalChatId != '' && this.props.global && !this.props.collaboration!.isMyTeamChatActive) || (this.props.collaboration!.teamChatId != '' && !this.props.global && this.props.collaboration!.isMyTeamChatActive);
    return true;
  }

  // getHTML(global: boolean): string {
  //   return global
  //     ? `<div class="arena-chat" data-publisher="${process.env.REACT_APP_ARENA_IM_API_KEY}" data-chatroom="${this.props.collaboration!.globalChatId}" data-position="in-page"></div>
  //       <script async src="https://go.arena.im/public/js/arenachatlib.js?p=${process.env.REACT_APP_ARENA_IM_API_KEY}&e=${this.props.collaboration!.globalChatId}"></script>
  //       `
  //     : `<div class="arena-chat" data-publisher="${process.env.REACT_APP_ARENA_IM_API_KEY}" data-chatroom="${this.props.collaboration!.teamChatId}" data-position="in-page"></div>
  //       <script async src="https://go.arena.im/public/js/arenachatlib.js?p=${process.env.REACT_APP_ARENA_IM_API_KEY}&e=${this.props.collaboration!.teamChatId}"></script>
  //       `;
  // }

  componentDidMount() {}

  getRoomId(): string | undefined {
    return this.props.collaboration!.isMyTeamChatActive! ? this.props.collaboration!.teamChatId : this.props.collaboration!.globalChatId;
  }

  getRoomPath(): string | undefined {
    return `https://go.arena.im/embed/chat/${process.env.REACT_APP_ARENA_IM_API_KEY}/${this.props.collaboration!.isMyTeamChatActive! ? this.props.collaboration!.teamChatId : this.props.collaboration!.globalChatId}`;
  }

  getMyTeamRoomPath(): string | undefined {
    return `https://go.arena.im/embed/chat/${process.env.REACT_APP_ARENA_IM_API_KEY}/${this.props.collaboration!.teamChatId}`;
  }

  getGlobalRoomPath(): string | undefined {
    return `https://go.arena.im/embed/chat/${process.env.REACT_APP_ARENA_IM_API_KEY}/${this.props.collaboration!.globalChatId}`;
  }

  reloadScript() {
    const existingScript = document.getElementById('arena-chat-js');
    if (!existingScript) {
      this.loadScript();
    } else {
      document.body.removeChild(existingScript);
      // window.arenaim(null)
      // window.arenaim = null;
      console.log('Remove arena script');
      this.loadScript();
    }
  }

  loadScript() {
    const existingScript = document.getElementById('arena-chat-js');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `https://go.arena.im/public/js/arenachatlib.js?p=${process.env.REACT_APP_ARENA_IM_API_KEY}&e=${this.getRoomId()}`;
      script.id = 'arena-chat-js';
      document.body.appendChild(script);
      script.onload = () => {
        // if (callback) callback();
        console.log('Arena loaded');
      };
    }
  }

  areCookiesEnabled() {
    try {
      document.cookie = 'cookietest=1';
      var cookiesEnabled = document.cookie.indexOf('cookietest=') !== -1;
      document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
      console.log(cookiesEnabled);
      return cookiesEnabled;
    } catch (e) {
      return false;
    }
}
  
  renderVisible() {
    if (!this.areCookiesEnabled())
      return (
          <ul className="collaboration__tp-cookies">
            <p>Onemućen je sadržaj za chat.</p>
            <p>Omogućite na sledeći način: </p>
            <li>
              <p>Otvorite Settings u Chrome-u</p>
            </li>
            <li>
              <p>Otvorite karticu Privacy and security</p>
            </li>
            <li>
              <p>Izaberite opciju Cookies and other site data</p>
            </li>
            <li>
              <p>Odčekirajte "Block third-party cookies"</p>
            </li>
            <li>
              <p>Ponovo učitajte ovu stranu.</p>
            </li>
          </ul>

      );
    else
      return (
        <div className="collaboration__chat">
          <div className="arena-chat-component">
            <iframe title="Timski chat" src={this.getMyTeamRoomPath()} style={{ visibility: this.props.collaboration!.isMyTeamChatActive ? 'visible' : 'hidden', border: '0', borderRadius: '4px', width: '26rem',  height: '44.3rem', position: 'absolute' }} />
            <iframe title="Globalni chat" src={this.getGlobalRoomPath()} style={{ visibility: this.props.collaboration!.isMyTeamChatActive ? 'hidden' : 'visible', border: '0', borderRadius: '4px', width: '26rem',   height: '44.3rem', position: 'absolute' }} />
          </div>
        </div>
      );

    // renderVisible() {
    //   return (
    //     <div className="collaboration__chat">
    //       <div className="arena-chat-component1">

    //         {this.props.collaboration!.isMyTeamChatActive ? <div className="arena-chat" data-publisher={process.env.REACT_APP_ARENA_IM_API_KEY} data-chatroom={this.getRoomId()} data-position="in-page"></div>
    //           : <div className="arena-chat" data-publisher={process.env.REACT_APP_ARENA_IM_API_KEY} data-chatroom={this.getRoomId()} data-position="in-page"></div>}
    //       </div>
    //     </div >
    //   );
  }
}
