import { types } from 'mobx-state-tree';
import { CommercialSurvey } from '../../../messages/player/commercialsurvey/message/CommercialSurvey';
import { ActionMST } from '../../ActionModel';
import { DisplayMST } from '../../DisplayModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const SurveyDialogMST = DisplayMST.named('SurveyDialogMST')
  .props({
    additionalText: '',
    tokensForReward: 0,
    expectedTime: 0,
    surveyPath: '',
    openSurveyAction: types.optional(ActionMST, {})
  })
  .views(self => ({}))
  .actions(self => ({
    setInfo(message: CommercialSurvey) {
      self.tokensForReward = message.tokensForReward;
      self.expectedTime = message.expectedTime;
      self.additionalText = message.additionalText || '';
      self.surveyPath = message.surveyPath || '';
    }
  }));

type SurveyDialogType = typeof SurveyDialogMST.Type;
export interface SurveyDialogModel extends SurveyDialogType {}

export const createSurveyDialogModel = () =>
  SurveyDialogMST.create({
    key: DisplayModelKeys.SurveyDialog,
    additionalText: '',
    tokensForReward: 0,
    expectedTime: 0,
    surveyPath: '',
    openSurveyAction: {}
  });
