import { ServerEvent } from '../../../communication/message/ServerEvent';

const BINDING_NAME = 'rs.slagalica.games.matching.message.MatchingContent';

export class MatchingContent extends ServerEvent {
  // TODO remove category A & B fields
  categoryA: string = '';

  columnA: Array<string> = [];

  categoryB: string = '';

  columnB: Array<string> = [];

  question: string = '';

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
