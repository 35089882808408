import { MessageSerializable } from '../MessageSerializable';

const BINDING_NAME = 'rs.slagalica.b2b.message.B2bGame';

const GAME_NOT_STARTED = 0;
const GAME_IN_PROGRESS = 1;
const GAME_FINISHED = 2;

export class B2bGame extends MessageSerializable {
  startTime: Date;

  blueTeamId?: string = '';

  redTeamId?: string = '';

  gameStatus?: number;

  bluePoints?: number;

  redPoints?: number;

  round?: number;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get GAME_NOT_STARTED() {
    return GAME_NOT_STARTED;
  }

  static get GAME_IN_PROGRESS() {
    return GAME_IN_PROGRESS;
  }

  static get GAME_FINISHED() {
    return GAME_FINISHED;
  }
}
