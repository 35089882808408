import { PointsUpdate } from '../../../communication/message/PointsUpdate';
import { SimpleField } from './SimpleField';

const BINDING_NAME = 'rs.slagalica.games.association.message.SimpleUpdateContent';

const NO_RESULT = -2; // fieldType: int
const FINAL = -1; // fieldType: int
const A_COLUMN = 0; // fieldType: int
const B_COLUMN = 1; // fieldType: int
const C_COLUMN = 2; // fieldType: int
const D_COLUMN = 3; // fieldType: int

export class SimpleUpdateContent extends PointsUpdate {
  resultFinal?: SimpleField = undefined;

  resultA?: SimpleField = undefined;

  resultB?: SimpleField = undefined;

  resultC?: SimpleField = undefined;

  resultD?: SimpleField = undefined;

  columnA?: Array<SimpleField> = undefined;

  columnB?: Array<SimpleField> = undefined;

  columnC?: Array<SimpleField> = undefined;

  columnD?: Array<SimpleField> = undefined;

  associationId: number = 0;

  level: number = 0;

  wizard: number = 0;

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }

  static get NO_RESULT() {
    return NO_RESULT;
  }

  static get FINAL() {
    return FINAL;
  }

  static get A_COLUMN() {
    return A_COLUMN;
  }

  static get B_COLUMN() {
    return B_COLUMN;
  }

  static get C_COLUMN() {
    return C_COLUMN;
  }

  static get D_COLUMN() {
    return D_COLUMN;
  }
}
