import { types } from 'mobx-state-tree';
import { Item } from '../../../messages/player/store/Item';
import { ActionMST } from '../../ActionModel';
import { AnimationBuilderMST } from '../../AnimationBuilder';
import { DisplayMST } from '../../DisplayModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const MAX = 0;
export const BOUGHT = 1;
export const AVAILABLE = 2;

export const FeatherBoosterMST = DisplayMST.named('FeatherBoosterMST')
  .props({
    flyBooster: AnimationBuilderMST,
    buyAction: types.optional(ActionMST, {}),
    status: AVAILABLE
  })
  .views(self => ({}))
  .actions(self => ({
    setBuyAction(action: Function) {
      self.buyAction.setAction(action);
    },
    setStatus(item: Item) {
      if (item && item.inUse > 0) {
        self.status = BOUGHT;
        self.buyAction.setEnabled(false);
      } else if (item.count === 4) {
        self.status = MAX;
        self.buyAction.setEnabled(false);
      } else {
        self.status = AVAILABLE;
        self.buyAction.setEnabled(true);
      }
    }
  }));

type FeatherBoosterModelType = typeof FeatherBoosterMST.Type;
export interface FeatherBoosterModel extends FeatherBoosterModelType {}

export const createFeatherBoosterModel = () =>
  FeatherBoosterMST.create({
    key: DisplayModelKeys.FeatherBoosterDialog,
    flyBooster: {},
    buyAction: {}
  });
