import { ActionMST } from '../../ActionModel';
import { DisplayMST } from '../../DisplayModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const FriendChallengeMST = DisplayMST.named('FriendChallengeMST')
  .props({
    friendFbId: '',
    confirmAction: ActionMST,
    declineAction: ActionMST
  })
  .actions(self => ({}))
  .views(self => ({}));

type FriendChallengeModelType = typeof FriendChallengeMST.Type;
export interface FriendChallengeModel extends FriendChallengeModelType {}

export const createFriendChallengeModel = (fbId: string) =>
  FriendChallengeMST.create({
    key: DisplayModelKeys.FriendChallengeDialog,
    friendFbId: fbId,
    confirmAction: {},
    declineAction: {}
  });
