import { BaseMST } from '../../BaseModel';

export const ResultGameModelMST = BaseMST.named('ResultGameModel')
  .props({
    bluePoints: 0,
    redPoints: 0,
    name: ''
  })
  .views(self => ({}))
  .actions(self => ({
    setPoints(blue: number, red: number) {
      self.bluePoints = blue;
      self.redPoints = red;
    }
  }));

type ResultGameModelType = typeof ResultGameModelMST.Type;
export interface ResultGameModel extends ResultGameModelType {}

export const createResultGameModelType = (bluePoints: number, redPoints: number, name: string) =>
  ResultGameModelMST.create({
    bluePoints,
    redPoints,
    name
  });
