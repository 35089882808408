import { Local } from 'common/src/lang/Local';
import { B2bTeamModel } from 'common/src/models/b2b/B2bTeamModel';
import { B2bTournamentModel } from 'common/src/models/b2b/B2bTournamentModel';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import B2bTournamentRow from './B2bTournamentRow';

interface WeeklyTournamentProps {
  b2bTournament?: B2bTournamentModel;
}
@inject('b2bTournament')
@observer
export default class WeeklyTournament extends Component<WeeklyTournamentProps> {
  render() {
    const { b2bTournament } = this.props;
    return (
      <div className="weekly-tournament">
        <div className="weekly-tournament__header">{Local.getString('mainroom.b2b_teams_table')}</div>
        <div className="weekly-tournament__container">
          <B2bTournamentRow key={0} isHeader />
          {b2bTournament!.teams.map((team: B2bTeamModel) => (
            <B2bTournamentRow key={team.rank} team={team} />
          ))}
        </div>
        <div className="weekly-tournament__footer" />
      </div>
    );
  }
}
