import { Animation, TimelineLite, TimelineMax } from 'gsap';
import GSAPAnimation from '../base/GSAPAnimation';

function getValue(counter: number, prefix: string, decimalPlaces: number): string {
  if (counter === 0) return `${prefix} 0`;

  return `${prefix} ${decimalPlaces > 0 ? counter.toFixed(decimalPlaces) : Math.ceil(counter)}`;
}

function createCountdown(target: HTMLElement | null, start: number, end: number, update: Function, decimalPlaces: number, prefix: string): Animation {
  const timeline = new TimelineLite({ paused: true });
  const counter = { var: start };

  if (target)
    timeline.add(
      new TimelineMax().to(counter, 2, {
        var: end,
        onUpdate() {
          target.innerHTML = getValue(counter.var, prefix, decimalPlaces);
          update(counter.var);
        }
      }),
      0
    );

  return timeline;
}

export class Countdown extends GSAPAnimation {
  constructor(target: HTMLElement | null, start: number, end: number, decimalPlaces: number = 0, prefix: string = '') {
    super(createCountdown(target, start, end, () => {}, decimalPlaces, prefix));
  }
}

export class CountDownWithCallback extends GSAPAnimation {
  constructor(target: HTMLElement | null, start: number, add: number, update: Function, decimalPlaces: number = 0, prefix: string = '') {
    super(createCountdown(target, start, start + add, update, decimalPlaces, prefix));
  }
}
