import { APP } from 'common/src/app/AppInstance';

export class ImageUtils {
  public static getFacebookImg(fbId: string | undefined, size: number = 100, defaultResource: string) {
    if (!fbId || fbId.length === 0 || parseInt(fbId, 10) <= 0) return defaultResource;
    const picture = APP.FacebookAPI.getPicturePath(fbId, size, size);
    return picture || defaultResource;
  }

  public static isFbId(fbId: string) {
    return fbId && fbId.length >= 9 && !fbId.startsWith('google') && !fbId.startsWith('guest') && !fbId.startsWith('apple');
  }
}
