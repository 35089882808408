import React, { Component } from 'react';

interface PointProps {
  baseClass: string;
  value: number;
}

export default class Point extends Component<PointProps, {}> {
  div: HTMLDivElement | null = null;

  getValue(): string {
    const { value } = this.props;
    if (value === 0) return '';

    return value > 0 ? `+${value}` : `${value}`;
  }

  render() {
    const { baseClass } = this.props;

    return (
      <div
        className={baseClass}
        ref={div => {
          this.div = div;
        }}
      >
        {this.getValue()}
      </div>
    );
  }
}
