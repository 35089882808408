import { observer } from 'mobx-react';
import React from 'react';
import { Local } from 'common/src/lang/Local';
import { DisplayModel } from 'common/src/models/DisplayModel';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';

interface RangListHelpProps extends DialogProps {
  rangListHelpDialog?: DisplayModel;
}
@observer
export default class RangListHelp extends BaseDialog<RangListHelpProps, {}> {
  isVisible(): boolean {
    const { rangListHelpDialog } = this.props;
    return rangListHelpDialog ? rangListHelpDialog.isVisible() : false;
  }

  renderVisible() {
    const { rangListHelpDialog } = this.props;

    return (
      <div className="rang-list-help">
        <div className="rang-list-help__inner">
          <DialogHeader onClose={() => rangListHelpDialog!.hide()}>{Local.getString('rang_list_help.header')}</DialogHeader>

          <div className="rang-list-help__description">{Local.getString('rang_list_help.desc_1')}</div>
          <div className="rang-list-help__description">{Local.getString('rang_list_help.desc_2')}</div>
          <div className="rang-list-help__description">{Local.getString('rang_list_help.desc_3')}</div>
          <button className="rang-list-help__accept" onClick={() => rangListHelpDialog!.hide()}>
            {Local.getString('common.close')}
          </button>
        </div>
      </div>
    );
  }
}
