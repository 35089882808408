import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { DailyRewardsModel, TOKENS_REWARD_COUNT } from 'common/src/models/dialogs/dailyreward/DailyRewardsModel';
import Images from 'web/src/resources/ImgResources';
import { BackgroundColor } from '../../../animations/samples/BackgroundColor';
import { BackgroundImage } from '../../../animations/samples/BackgroundImage';
import { BackgroundMove } from '../../../animations/samples/BackgroundMove';
import { Fade } from '../../../animations/samples/Fade';
import { Glow } from '../../../animations/samples/Glow';
import DailyRewardsCard from './DailyRewardsCard';
import GSAPSequenceAnimation from '../../../animations/base/GSAPSequenceAnimator';

interface DailyRewardsCardsProps {
  dailyRewards: DailyRewardsModel;
}
@observer
export default class DailyRewardsCards extends Component<DailyRewardsCardsProps> {
  dailyRewardsCard: Array<DailyRewardsCard | null> = [];

  componentDidMount() {
    this.createAnimations();
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  getCardType(index: number, todayIndex: number): string {
    if (index < todayIndex) {
      return 'previous';
    }
    if (index > todayIndex) {
      return 'next';
    }
    return 'today';
  }

  createGlowRewardAnimation = () => {
    const { dailyRewards } = this.props;
    const seq = new GSAPSequenceAnimation();
    TOKENS_REWARD_COUNT.forEach((multiplier: number, index: number) => {
      const dailyDiv = this.dailyRewardsCard[index]!.rewardDiv;
      if (index === dailyRewards.todayIndex && dailyDiv) {
        seq.add(new Glow(dailyDiv, 50, 50));
      }
    });
    return seq;
  };

  createRewardAcceptAnimation = () => {
    const { dailyRewards } = this.props;
    const seq = new GSAPSequenceAnimation();
    TOKENS_REWARD_COUNT.forEach((multiplier: number, index: number) => {
      const dailyDiv = this.dailyRewardsCard[index]!.rewardBackgroundDiv;
      const dailyStatus = this.dailyRewardsCard[index]!.rewardStatusDiv;
      const dailyStatusFrame = this.dailyRewardsCard[index]!.rewardStatusFrameDiv;
      const dailyToken = this.dailyRewardsCard[index]!.rewardTokensDiv;

      const backgroundDiv = `url(${Images.peviousDayBackgroundTop}) center no-repeat`;
      const backgroundToken = `url(${Images.peviousDayTokenHolder}) center no-repeat`;

      if (index === dailyRewards.todayIndex && dailyDiv && dailyStatus && dailyStatusFrame) {
        seq.add(new BackgroundImage(dailyDiv, backgroundDiv));
        seq.add(new BackgroundImage(dailyStatus, 'none'));
        seq.add(new BackgroundColor(dailyStatus, '#bbdaff'));
        seq.add(new Fade(dailyStatusFrame, 0.1, 0, 1));
        seq.add(new BackgroundMove(dailyStatusFrame, -594));
        seq.add(new BackgroundImage(dailyToken, backgroundToken));
      }
    });

    return seq;
  };

  destroyAnimations() {
    this.props.dailyRewards!.glowRewardAnimation.dispose();
    this.props.dailyRewards!.acceptRewardsAnimation.dispose();
  }

  createAnimations() {
    this.props.dailyRewards!.glowRewardAnimation.set(this.createGlowRewardAnimation);
    this.props.dailyRewards!.acceptRewardsAnimation.set(this.createRewardAcceptAnimation);
  }

  render() {
    const { dailyRewards } = this.props;
    if (!dailyRewards) {
      return null;
    }

    return (
      <div className="daily-rewards-cards">
        {TOKENS_REWARD_COUNT.map((multiplier: number, index: number) => (
          <DailyRewardsCard
            key={multiplier}
            cardType={this.getCardType(index, dailyRewards.todayIndex)}
            day={index + 1}
            multiplier={`x${multiplier}`}
            ref={dailyRewardsCard => {
              this.dailyRewardsCard[index] = dailyRewardsCard;
            }}
          />
        ))}
      </div>
    );
  }
}
