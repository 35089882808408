import { types } from 'mobx-state-tree';
import { BaseMST } from '../../BaseModel';

export const InboxMessageMST = BaseMST.named('InboxMessageModel')
  .props({
    title: types.string,
    message: types.string,
    read: false,
    linkCaption: types.string,
    linkUrl: types.string
  })
  .views(self => ({}))
  .actions(self => ({
    init(title: string, message: string, read: boolean) {
      self.message = message;
      self.read = read;
      self.title = title;
    }
  }));

type InboxMessageModelType = typeof InboxMessageMST.Type;
export interface InboxMessageModel extends InboxMessageModelType {}
export const createInboxMessageModel = (title: string, message: string, read: boolean, linkCaption: string, linkUrl: string) => InboxMessageMST.create({ title, message, read, linkCaption, linkUrl });
