import { ActionMST } from '../../ActionModel';
import { DisplayMST } from '../../DisplayModel';
import { DisplayModelKeys } from '../../DisplayModelKeys';

export const ResumeGameMST = DisplayMST.named('ResumeGameMST')
  .props({
    confirmAction: ActionMST
  })
  .views(() => ({}))
  .actions(() => ({}));

type ResumeGameModelType = typeof ResumeGameMST.Type;
export interface ResumeGameModel extends ResumeGameModelType {}

export const createResumeGameModel = () =>
  ResumeGameMST.create({
    key: DisplayModelKeys.ResumeDialog,
    confirmAction: {}
  });
