import { TimelineLite } from 'gsap';
import BaseAnimation from 'common/src/animations/BaseAnimation';
import GroupAnimation from 'common/src/animations/GroupAnimation';
import GSAPAnimation from './GSAPAnimation';

export default abstract class GSAPGroupAnimation extends GSAPAnimation {
  constructor(timeLine: TimelineLite) {
    super(timeLine);
  }

  add(animation: BaseAnimation): GroupAnimation {
    if (animation !== undefined) {
      if (animation instanceof GSAPAnimation) {
        this.addGsapAnimation(animation as GSAPAnimation);
      } else throw new Error('Cound not add NOT GSAP animation into GSAP Sequence animator!');
    }
    return this;
  }

  protected abstract addGsapAnimation(animation: GSAPAnimation): void;

  getWrappedTimeline(): TimelineLite {
    return this.animation as TimelineLite;
  }
}
