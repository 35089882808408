import { types } from 'mobx-state-tree';
import { PlayerInfo } from '../../messages/player/message/PlayerInfo';
import { BaseMST } from '../BaseModel';

export const UserMST = BaseMST.named('UserMST')
  .props({
    referral: '',
    userGeo: '',
    gameSequence: 0
  })
  .volatile(self => ({
    info: new PlayerInfo()
  }))
  .views(self => ({
    get tokens() {
      return self.info.goldTokens;
    },
    get allTokens() {
      return self.info.goldTokens + self.info.silverTokens;
    }
  }))
  .actions(self => ({
    setGeo(geo: string) {
      self.userGeo = geo;
    },
    setInfo(pInfo: PlayerInfo) {
      self.info = pInfo;
    },
    setReferral(referral: string) {
      self.referral = referral;
    },
    setGameSequence(sequence: number) {
      self.gameSequence = sequence;
    }
  }));

type UserModelType = typeof UserMST.Type;
export interface UserModel extends UserModelType {}
export const createUserModel = () => UserMST.create({});
