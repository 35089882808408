import { MessageSerializable } from '../../MessageSerializable';

const BINDING_NAME = 'rs.slagalica.player.message.DailyGame';

export class DailyGame extends MessageSerializable {
  status: number = 0;

  // fieldType: int
  points: number = 0; // fieldType: int

  constructor() {
    super();
    this.__class = BINDING_NAME;
  }

  static get ClassName() {
    return BINDING_NAME;
  }
}
