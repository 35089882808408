const NOT_ANSWERED = 0;
const RED_ANSWERED = 1;
const BLUE_ANSWERED = 2;

export class QuestionStatus {
  static get NotAnswered() {
    return NOT_ANSWERED;
  }

  static get BlueAnswered() {
    return BLUE_ANSWERED;
  }

  static get RedAnswered() {
    return RED_ANSWERED;
  }
}
