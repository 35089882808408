import React, { Component } from 'react';
import { PlayerInfoSemaphorModel } from 'common/src/models/game/PlayerInfoSemaphoreModel';
import { HatsModel } from 'common/src/models/game/HatsModel';
import { FriendModel } from 'common/src/models/friends/FriendModel';
import { RANK_VALUES } from 'common/src/resources/RankValues';
import { Local } from 'common/src/lang/Local';
import { RANK } from '../../../resources/RankResources';
import { CountUp } from '../../../animations/samples/CountUp';

interface SemaphorePlayerProps {
  player: PlayerInfoSemaphorModel;
  hats: HatsModel;
}

export default class SemaphorePlayer extends Component<SemaphorePlayerProps, {}> {
  pointsRef: HTMLDivElement | null = null;

  wonColor = ['#CDF827', '#C8F523', '#C0F11F', '#B8ED19', '#AEE712', '#A3E00A', '#98D705', '#8FCC02', '#87C301', '#70A300'];

  componentDidMount() {
    this.createAnimations();
  }

  componentWillUnmount() {
    this.destroyAnimations();
  }

  getToolTip(toolTipPosition: number) {
    return (
      <div className={`semaphore-${toolTipPosition === 0 ? 'blue' : 'red'}-player__tooltip`} style={{ left: toolTipPosition === 0 ? `6rem` : '-25rem' }}>
        <h2 className="weekly-tournament__tooltip-name">{this.props.player!.name}</h2>
        <div className="weekly-tournament__content">
          <div>
            <img className="weekly-tournament__content-left" src={RANK.getBigImg(this.props.player!.rank)} />
          </div>
          <div className="weekly-tournament__content-right">
            <div className="weekly-tournament__egg-owl">{RANK_VALUES.getShortName(this.props.player!.rank)}</div>
            <div className="weekly-tournament__rating">{Local.getString('player_tooltip.rating', this.props.player!.rating)}</div>
          </div>
        </div>
        <div className="weekly-tournament__collected-feathers">{Local.getString('player_tooltip.feather', this.props.player!.feathers)}</div>
        <h2 className="weekly-tournament__tooltip-name weekly-tournament__tooltip-name--small">{Local.getString('final_board.prev_rank')}</h2>
        <div className="weekly-tournament__content weekly-tournament__content--best-title">
          <div>
            <img className="weekly-tournament__content-left weekly-tournament__content-left--small" src={RANK.getBigImg(this.props.player!.maxPrevRank)} />
          </div>
          <div className="weekly-tournament__content-right weekly-tournament__content-right--small">
            <div className="weekly-tournament__egg-owl">{RANK_VALUES.getShortName(this.props.player!.maxPrevRank)}</div>
          </div>
        </div>
        <h2 className="weekly-tournament__tooltip-name weekly-tournament__tooltip-name--green">{Local.getString('player_tooltip.stat_title')}</h2>
        <div className="weekly-tournament__image">
          <div className="weekly-tournament__image--text">{this.props.player.stats!.getWonPercentage()}</div>
        </div>
        {this.props.player!.stats!.gameStat.map((game, index) => (
          <div key={index} className="weekly-tournament__won-box" style={{ marginLeft: this.getMarginLeftPercent(index) }}>
            {[...Array(10)].map((number, j) => (
              <div key={j} className="weekly-tournament__won-box--game" style={{ backgroundColor: game.gameMark > j ? this.wonColor[j] : '' }} />
            ))}
          </div>
        ))}
      </div>
    );
  }

  getMarginLeftPercent(index: number) {
    return `${7 + 3 * index}rem`;
  }

  createAnimations() {
    if (!this.props.player) return;

    this.props.player!.addPointsAnimation.set((args: any[]) => new CountUp(this.pointsRef, this.props.player!.points, args[0]));

    this.props.player!.addPointsWithInitalAnimation.set((args: any[]) => new CountUp(this.pointsRef, args[0], args[1]));
  }

  destroyAnimations() {
    if (!this.props.player) return;
    if (this.props.player!.addPointsAnimation) this.props.player!.addPointsAnimation.dispose();
    if (this.props.player!.addPointsWithInitalAnimation) this.props.player!.addPointsWithInitalAnimation.dispose();
  }
}
