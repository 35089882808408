import GroupAnimation from 'common/src/animations/GroupAnimation';
import { TimelineLite } from 'gsap';
import GSAPAnimation from './GSAPAnimation';
import GSAPGroupAnimation from './GSAPGroupAnimation';

export default class GSAPSequenceAnimation extends GSAPGroupAnimation implements GroupAnimation {
  constructor() {
    super(new TimelineLite({ paused: true }));
  }

  protected addGsapAnimation(gsapAnimation: GSAPAnimation): void {
    this.getWrappedTimeline().add(gsapAnimation.getAnimation());
    gsapAnimation.getAnimation().paused(false);
  }
}
