import { TimelineLite, TimelineMax, Power1, Power2, Expo } from 'gsap';
import GSAPAnimation from '../base/GSAPAnimation';
import { RemPixelRatio } from '../RemPixelRatio';

export class TranslateTo extends GSAPAnimation {
  constructor(target: {} | null, duration: number, toX: number, toY: number, repeat: number) {
    const timeline = new TimelineLite({ paused: true });

    if (target) {
      timeline.add(
        new TimelineMax({ repeat }).to(target, duration, {
          x: toX * RemPixelRatio.getRatio(),
          y: toY * RemPixelRatio.getRatio()
        })
      );
    }

    super(timeline);
  }
}

export class CurveTranslateTo extends GSAPAnimation {
  constructor(target: {} | null, duration: number, toX: number, toY: number, repeat: number) {
    const timeline = new TimelineLite({ paused: true });

    if (target) {
      timeline.add(new TimelineMax({ repeat }).to(target, duration, { x: toX * RemPixelRatio.getRatio(), ease: Power2.easeIn }, 0).to(target, duration, { y: toY * RemPixelRatio.getRatio(), ease: Expo.easeIn }, 0));
    }

    super(timeline);
  }
}

export class TranslateFrom extends GSAPAnimation {
  constructor(target: {} | null, duration: number, fromX: number, fromY: number, repeat: number) {
    const timeline = new TimelineLite({ paused: true });

    if (target) {
      timeline.add(
        new TimelineMax({ repeat }).from(target, duration, {
          x: fromX * RemPixelRatio.getRatio(),
          y: fromY * RemPixelRatio.getRatio()
        })
      );
    }

    super(timeline);
  }
}
