import { types } from 'mobx-state-tree';
import { BaseMST } from '../../../BaseModel';
import { ActionMST } from '../../../ActionModel';

export const WordCheckMST = BaseMST.named('LetterMST')
  .props({
    checking: false,
    correct: false,
    wrong: false,
    checkWordAction: types.optional(ActionMST, {})
  })
  .actions(self => ({
    setStatus(isCorrect: boolean) {
      if (isCorrect) {
        this.setCorrect();
      } else {
        this.setWrong();
      }
    },
    setCorrect() {
      self.checking = false;
      self.wrong = false;
      self.correct = true;
    },
    setChecking() {
      self.checking = true;
      self.wrong = false;
      self.correct = false;
    },
    setWrong() {
      self.checking = false;
      self.wrong = true;
      self.correct = false;
    },
    clear() {
      self.checking = false;
      self.wrong = false;
      self.correct = false;
    }
  }))
  .views(self => ({
    isCheckingShown(): boolean {
      return self.checking;
    },
    isCorrectShown(): boolean {
      return self.correct;
    },
    isWrongShown(): boolean {
      return self.wrong;
    }
  }));

type WordCheckModelType = typeof WordCheckMST.Type;
export interface WordCheckModel extends WordCheckModelType {}
export const createWordChecker = () => WordCheckMST.create({ checking: false, correct: false, wrong: false });
