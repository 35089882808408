import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Fragment } from 'react';
import PieChart from 'react-minimal-pie-chart';
import { Local } from 'common/src/lang/Local';
import { GameName } from 'common/src/models/dialogs/statistics/StatisticsGameModel';
import { StatisticsModel } from 'common/src/models/dialogs/statistics/StatisticsModel';
import { StatisticsPercentageModel } from 'common/src/models/dialogs/statistics/StatisticsPercentageModel';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';

interface StatisticsProps extends DialogProps {
  statistics?: StatisticsModel;
}

@observer
export default class Statistics extends BaseDialog<StatisticsProps, { test: boolean }> {
  @observable selectedGame: number = GameName.Puzzle;

  wonColor = ['#CDF827', '#C8F523', '#C0F11F', '#B8ED19', '#AEE712', '#A3E00A', '#98D705', '#8FCC02', '#87C301', '#70A300'];

  percentColor7 = ['#DE3730', '#F74343', '#FE7108', '#D5D800', '#83C700', '#00C100', '#0E8A33'];

  percentColor6 = ['#DE3730', '#F74343', '#FE7108', '#83C700', '#00C100', '#0E8A33'];

  percentColor4 = ['#DE3730', '#FE7108', '#D5D800', '#00C100'];

  percentColor3 = ['#DE3730', '#D5D800', '#00C100'];

  percentColor2 = ['#DE3730', '#00C100'];

  getMarginLeftPercent(index: number) {
    return `${12 + 6 * index}rem`;
  }

  getPercentColor(size: number, index: number) {
    switch (size) {
      case 7:
        return this.percentColor7[index];
      case 6:
        return this.percentColor6[index];
      case 4:
        return this.percentColor4[index];
      case 3:
        return this.percentColor3[index];
      case 2:
        return this.percentColor2[index];
      default:
        return '';
    }
  }

  getPieData(percents: Array<any>) {
    const sum = this.getTotal(percents);
    return percents.map((percent, i) => ({ value: sum === 0 ? 0 : Math.round((percent!.number * 100) / sum), color: this.getPercentColor(percents.length, i) }));
  }

  getTotal(percents: Array<any>) {
    let sum = 0;
    percents.forEach((percent: StatisticsPercentageModel) => {
      sum += percent.number;
    });

    return sum;
  }

  getPercentByGame(one: number, total: number) {
    if (total === 0) return '0%';
    return `${((one / total) * 100).toFixed(0)}%`;
  }

  isVisible(): boolean {
    const { statistics } = this.props;
    return statistics ? statistics.isVisible() : false;
  }

  renderVisible() {
    const { statistics } = this.props;
    return (
      <div className="statistics">
        <div className="statistics__inner">
          <DialogHeader onClose={() => statistics!.hide()}>{Local.getString('statistics.title')}</DialogHeader>
          <div className="statistics__percentage_title">{Local.getString('statistics.desc_1')}</div>
          <div className="statistics__image">
            <div className="statistics__image--text">{statistics!.getWonPercentage()}</div>
            <div className="statistics__image--tootlip">
              {Local.getString('statistics.wins')} {statistics!.won}
              <br />
              {Local.getString('statistics.loses')} {statistics!.lose}
            </div>
          </div>
          <div className="statistics__percentage_title statistics__percentage_title--game">{Local.getString('statistics.desc_2')}</div>
          <div className="statistics__percent-box">
            <div className="statistics__won-divider" />
            {statistics!.gameStat.map((game, index) => (
              <Fragment>
                <div
                  className="statistics__won-box"
                  style={{ marginLeft: this.getMarginLeftPercent(index) }}
                  onMouseEnter={() => {
                    this.selectedGame = game.type;
                  }}
                >
                  <div className="statistics__won-box--text" style={{ color: this.selectedGame === game!.type ? 'white' : '' }}>
                    {game.name}
                  </div>
                  {[...Array(10)].map((number, j) => (
                    <div className="statistics__won-box--game" style={{ backgroundColor: game.gameMark > j ? this.wonColor[j] : '' }} />
                  ))}
                </div>
                {game.type === this.selectedGame && (
                  <div>
                    <div className="statistics__percent-box--text-letter">{game.title}</div>
                    <div className="statistics__percent-box--text-game">{game.name}</div>
                    <div className="statistics__percent-box--box">
                      <PieChart className="statistics__chart" data={this.getPieData(game!.percentagesGame)} />
                      {game!.percentagesGame.map((percentage, k) => (
                        <div className="statistics__percent-box--text-percent" style={{ color: this.getPercentColor(game!.percentagesGame.length, k) }}>
                          {percentage!.title} {this.getPercentByGame(percentage!.number, this.getTotal(game!.percentagesGame))}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
