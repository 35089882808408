import { Instance, types } from 'mobx-state-tree';
import { PlayerInfo } from '../../messages/player/message/PlayerInfo';
import { ActionMST } from '../ActionModel';
import { BaseMST } from '../BaseModel';
import { createUserModel, UserModel, UserMST } from '../user/UserModel';

export const B2bTeamMST = BaseMST.named('B2bTeamMST')
  .props({
    id: types.string,
    name: types.string,
    logo: types.string,
    wins: types.number,
    diff: types.number,
    rank: types.number,
    room: types.string,
    chat: types.string,
    companyName: types.string,
    teamMembers: types.array(UserMST),
    onLogoChanged: types.optional(ActionMST, {})
  })
  .views(self => ({
    getTeamMember(id: string): UserModel {
      return self.teamMembers.find((u: UserModel) => u.info.b2bId === id)!;
    },
    getTeamMemberByPlayerId(id: number): UserModel {
      return self.teamMembers.find((u: UserModel) => u.info.playerId === id)!;
    }
  }))
  .actions(self => ({
    setId(id: string) {
      self.id = id;
    },
    setName(name: string) {
      self.name = name;
    },
    setOnLogoChanged(f: Function) {
      self.onLogoChanged.setAction(f);
    },
    setLogo(l: string) {
      self.logo = l;
      self.onLogoChanged.getAction()(self.id, self.logo);
    },
    setWins(w: number) {
      self.wins = w;
    },
    setDiff(d: number) {
      self.diff = d;
    },
    setRank(r: number) {
      self.rank = r;
    },
    setRoom(r: string) {
      self.room = r;
    },
    setChat(r: string) {
      self.chat = r;
    },
    setCompany(r: string) {
      self.companyName = r;
    },
    setTeamMembers(tm: Array<PlayerInfo>) {
      tm.forEach((p: PlayerInfo) => {
        const user = createUserModel();
        user.setInfo(p);
        self.teamMembers.push(user);
      });
    }
  }));

type B2bTeamModelType = typeof B2bTeamMST.Type;
export interface B2bTeamModel extends Instance<typeof B2bTeamMST> {}

export const createB2bTeamModel = () =>
  B2bTeamMST.create({
    id: '',
    name: '',
    logo: '',
    wins: 0,
    diff: 0,
    rank: 0,
    room: '',
    chat: '',
    companyName: '',
    teamMembers: []
  });
