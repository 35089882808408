import { Local } from 'common/src/lang/Local';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

interface B2bGameStatusProps {
  active?: number;
}

@observer
export default class B2bGameStatus extends Component<B2bGameStatusProps> {
  getActiveLbl(): string {
    const { active } = this.props;
    if (active === 0) return Local.getString('mainroom.b2b_tournament_not_started');
    if (active === 1) return Local.getString('mainroom.b2b_tournament_in_progress');
    return Local.getString('mainroom.b2b_tournament_over');
  }

  render() {
    return (
      <div className="b2b-game-status">
        <div className="b2b-game-status__header">{Local.getString('mainroom.b2b_game_status')}</div>
        <div className="b2b-game-status__title">{this.getActiveLbl()}</div>
      </div>
    );
  }
}
