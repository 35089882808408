import { inject, observer } from 'mobx-react';
import React from 'react';
import { Local } from 'common/src/lang/Local';
import { DailyRewardsModel } from 'common/src/models/dialogs/dailyreward/DailyRewardsModel';
import { BaseDialog, DialogProps } from '../BaseDialog';
import DialogHeader from '../DialogHeader';
import DailyRewardsCards from './DailyRewardsCards';

interface DailyRewardsProps extends DialogProps {
  dailyRewards: DailyRewardsModel;
}
@observer
export default class DailyRewards extends BaseDialog<DailyRewardsProps, {}> {
  isVisible(): boolean {
    if (this.props.dailyRewards) return this.props.dailyRewards.isVisible();
    return false;
  }

  renderVisible() {
    const { dailyRewards } = this.props;
    const t = (message: string) => Local.getString(message);
    return (
      <div className="daily-rewards">
        <div className="daily-rewards__inner">
          <DialogHeader
            onClose={() => {
              // dailyRewards!.getReward()!.init
              dailyRewards!.hide();
              dailyRewards!.acceptRewards.performAction();
            }}
          >
            {t('daily_rewards.header')}
          </DialogHeader>
          <div className="daily-rewards__description">{t('daily_rewards.login_tomorrow')}</div>
          <DailyRewardsCards dailyRewards={dailyRewards} />
          <button
            className={dailyRewards.acceptRewards.enabled ? 'daily-rewards__accept' : 'daily-rewards__accept_disabled'}
            onClick={() => {
              dailyRewards!.acceptRewards.performAction();
              dailyRewards!.acceptRewardsAnimation.build().play();
              setTimeout(() => {
                dailyRewards!.hide();
              }, 1500);
              // dailyRewards!.close();
            }}
          >
            {t('daily_rewards.accept')}
          </button>
        </div>
      </div>
    );
  }
}
